import React, { useEffect, useState } from 'react';
import { ROUTES } from '@constants';
import { Avatar, IconButton } from '@ui-kit';
import { useMe, useTranslation } from '@hooks';
import { useFlyoutMutation } from '@ui-modules/flyout/hooks/useFlyoutMutation';
import { useFlyoutQuery } from '@ui-modules/flyout/hooks/useFlyoutQuery';
import { useFlyoutSequence } from '@ui-modules/flyout/hooks/useFlyoutSequence';
import ProfileHeaderTooltip from '@ui-modules/assetAllocation/components/ProfileHeaderTooltip';
import styles from './ProfileHeaderButton.module.css';

const ProfileHeaderButton: React.FC = () => {
	const { t } = useTranslation();
	const { user, fullName } = useMe();
	const { data: flyoutMessages = [], isFetched } = useFlyoutQuery();
	const { mutateAsync } = useFlyoutMutation({}, true);

	const { currentMessage } = useFlyoutSequence(flyoutMessages);

	const [showTooltip, setShowTooltip] = useState<boolean>(false);

	useEffect(() => {
		if (currentMessage) {
			setShowTooltip(true);
		}
	}, [currentMessage]);

	const handleIconButtonClick = async () => {
		setShowTooltip(false);
	};

	return (
		<>
			{currentMessage && isFetched && (
				<ProfileHeaderTooltip
					description={currentMessage.text}
					setShowTooltip={(showTooltip: boolean) => {
						mutateAsync(currentMessage.id);
						setShowTooltip(showTooltip);
					}}
					showTooltip={showTooltip}
					title={currentMessage.title}
				/>
			)}
			<div className={styles.profileBadgeWrapper} data-tooltip-id="my-tooltip">
				<IconButton
					icon={
						<Avatar
							imageUri={user.avatar?.contentUrl}
							outline={user.roles.includes('ROLE_CHAIR')}
							size={26}
							title={fullName}
						/>
					}
					label={t('Account')}
					linkTo={ROUTES.profile()}
					showBadge={!!flyoutMessages?.length}
					onClick={handleIconButtonClick}
				/>
			</div>
		</>
	);
};

export default ProfileHeaderButton;
