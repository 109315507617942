import { ActivityIndicator, UserListItem } from '@ui-kit';
import { useCallback, useTranslation, useEffect, useNavigate, useState, useMemo } from '@hooks';
import { useGetDealMemberRelationsQuery } from '@ui-modules/deals/hooks/useDealMemberRelationsQuery';
import { adaptDealMemberToUserProfileRecord } from '@ui-modules/deals/utils/adaptDealMemberToUserProfileRecord';
import { formatUserSubTitle } from '@utils';
import { ROUTES } from '../../../../app/routes';
import type { TDealMember, TUserProfileRecord, TDealMemberRelationsStatusForRequestQuery } from '@typings';
import styles from './DealBlocks.module.css';

const DealMemberBlock = ({ dealId, members, status, total }: IDealMemberBlock) => {
	const { t } = useTranslation();
	const setExpandableStatusToStorage = (def: string) => {
		setExpanded(!expanded);
		window.sessionStorage.setItem(`expandable:${def}`, 'true');
	};
	useEffect(() => {
		window.sessionStorage.removeItem(`expandable:${status}`);
	}, []);

	const [expanded, setExpanded] = useState(false);
	const { data: dealMembers, isFetching: isFetchingRestOfDealMembers } = useGetDealMemberRelationsQuery(
		dealId,
		expanded,
		status,
	);

	const filteredMembersWithoutDuplicates = useMemo(() => {
		return dealMembers?.filter((dealMember) => {
			const duplicateMember = members.find((member) => member.id === dealMember.id);
			if (!duplicateMember) return dealMember;
		});
	}, [dealMembers, members]);

	const getSeeAllButton = useCallback(
		(def: string) => {
			return (
				<div>
					<div
						className={styles.expandableBlock__showAllWrapper}
						onClick={() => setExpandableStatusToStorage(def)}
						onKeyPress={() => setExpandableStatusToStorage(def)}
					>
						{isFetchingRestOfDealMembers ? (
							<ActivityIndicator />
						) : (
							<span>
								{!expanded
									? `${t('Show all {{ status }} members', { status })}`
									: `${t('Show fewer {{ status }} members', { status })}`}
							</span>
						)}
					</div>
				</div>
			);
		},
		[expanded, isFetchingRestOfDealMembers],
	);
	const navigate = useNavigate();

	const handleClick = (member: TUserProfileRecord) => {
		navigate(ROUTES.memberProfile(member.profile.id));
	};

	const memberBlock = (member: TDealMember) => (
		<UserListItem
			avatarSize={40}
			className={styles.collapsible__memberWrapper}
			renderUserSubTitle={formatUserSubTitle(t)}
			size="unset"
			userProfileRecord={adaptDealMemberToUserProfileRecord(member)}
			withSeparator={false}
			onClick={handleClick}
		/>
	);

	return (
		<div className={styles.collapsible__member_interested}>
			<div className={styles.collapsible__memberStatus}>{`${status} (${total})`}</div>
			{members.map((member) => memberBlock(member))}
			{expanded &&
				!isFetchingRestOfDealMembers &&
				filteredMembersWithoutDuplicates &&
				filteredMembersWithoutDuplicates.map((member) => memberBlock(member))}
			{total > 5 && getSeeAllButton(status)}
		</div>
	);
};

export default DealMemberBlock;

interface IDealMemberBlock {
	dealId: string;
	members: TDealMember[];
	status: TDealMemberRelationsStatusForRequestQuery;
	total: number;
}
