import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { useRemoveActivityFromFeedsCache } from './useInvalidateFeedCacheIfActivityIncluded';
import type { MutateOptions } from '@tanstack/react-query';
import type { TActivity } from '@typings';

export const useDeleteActivityMutation = (options?: MutateOptions<void, Error, TActivity>) => {
	const { t } = useTranslation();
	const { showSuccess, showUnknownError } = useNotification();

	const feedStream = useService('FeedService');
	const feed = useFeedContext();
	const removeActivityFromFeedsCache = useRemoveActivityFromFeedsCache();

	return useMutation<void, Error, TActivity>(
		['feedStream.deletePost'],
		async (feedActivity) => await feedStream.deletePost(feedActivity.foreign_id, feedActivity.settings.postType),
		{
			...options,
			async onSuccess(data, feedActivity, context) {
				showSuccess({ title: t('Your post was deleted') });
				await removeActivityFromFeedsCache(feedActivity.id);
				feed.onRemoveActivity(feedActivity.id); // standard function is mocked on Feed component but internal logic of reorganize feed is called.
				options?.onSuccess?.(data, feedActivity, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
