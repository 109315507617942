import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDealSetting } from '@typings';
import type { AxiosError } from 'axios';
import { useSolicitationWaiver } from './useSolicitationWaiver';

export const useGetDealSettings = (enabled = true) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();

	return useQuery<TDealSetting[], AxiosError>(
		reactQuery.queryKeys.getDealSettings(),
		async () => api.invest.getDealSettings(),
		{
			enabled: enabled && !isWaiverLoading && !isMessageShown,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
		},
	);
};
