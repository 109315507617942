import { Gap } from '@ui-kit';
import { useCallback, useRef, useState, useMemo, useMeasure, useLayoutEffect } from '@hooks';
import { clsx, produce, once } from '@utils';
import styles from './useFloatingHeader.module.css';
import type { HTMLAttributes, UIEventHandler } from 'react';

const Y_SCROLL_UP_THRESHOLD = 5;
const Y_SCROLL_DOWN_THRESHOLD = 0;
const INITIAL_HEADER_APPEARANCE_TIMEOUT = 500;

export function useFloatingHeader<T>(
	data: T[],
	initialShown: boolean,
	placeholderHeight: number | undefined,
	headerStyles?: HTMLAttributes<HTMLDivElement>['className'],
) {
	const [floatingHeaderRef, { height: floatingHeaderHeight }] = useMeasure<HTMLDivElement>();
	const [headerShown, setHeaderShown] = useState<boolean>(initialShown);
	useLayoutEffect(() => {
		setHeaderShown(initialShown); // sync if initialShown changed after changing list key.
	}, [initialShown]);
	const floatingHeaderClassName = useMemo(
		() =>
			clsx(
				styles.floatingHeader,
				headerShown ? styles.floatingHeader_visible : styles.floatingHeader_hidden,
				headerStyles,
			),
		[headerShown],
	);

	const Placeholder = useCallback(
		() => <Gap gap={Math.max(placeholderHeight ?? floatingHeaderHeight, 1)} />,
		[floatingHeaderHeight, placeholderHeight],
	);

	// Postpone header visibility until items are rendered to prevent appearing header when list restores scroll position via initialTopMostItemIndex. Fixes T21C-4800 [@dmitriy.nikolenko]
	const [headerActive, setHeaderActive] = useState(false);
	const itemsRendered = useRef(
		once(() => {
			if (!headerActive) {
				setTimeout(() => setHeaderActive(true), INITIAL_HEADER_APPEARANCE_TIMEOUT);
			}
		}),
	).current;

	const lastScrollYRef = useRef(window.pageYOffset);

	const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
		if (!headerActive) return;
		const element = event.target as HTMLDivElement;
		const scrollY = element.scrollTop;
		const newHeaderShown = scrollY <= lastScrollYRef.current;
		const scrollYShift = scrollY - lastScrollYRef.current;
		if (
			newHeaderShown !== headerShown &&
			(scrollYShift > Y_SCROLL_UP_THRESHOLD || scrollYShift < Y_SCROLL_DOWN_THRESHOLD)
		) {
			setHeaderShown(newHeaderShown);
		}

		lastScrollYRef.current = Math.max(scrollY, 0);
	};

	const dataWithPlaceholders = useMemo(
		function addFloatingHeaderPlaceholderData() {
			return produce(data, (draft: (T | null)[]) => {
				draft?.unshift?.(null, null); // add two placeholders for react-virtuoso: 1) floating header 2) gap.
			});
		},
		[data],
	);

	return {
		floatingHeaderRef,
		floatingHeaderClassName,
		Placeholder,
		onScroll,
		dataWithPlaceholders,
		headerShown,
		itemsRendered,
		floatingHeaderHeight,
	} as const;
}
