import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type IUserPreferencesState = {
	/** If true for Tablet devices app is shown bypassing "desktop only" rule. */
	shouldPersistShowingAppOnTablet: boolean;
	/** If asset allocation is passed this boolean should choose whether to show or hide the introduction modal | ... */
	isAssetAllocationIntroduced: boolean;
};

const initialSignInState: IUserPreferencesState = {
	shouldPersistShowingAppOnTablet: false,
	isAssetAllocationIntroduced: false,
};

export const userPreferencesSlice = createSlice({
	name: 'userPreferences',
	initialState: initialSignInState,
	reducers: {
		setPersistShowingAppOnTablet: (state, payload: PayloadAction<void>) => {
			state.shouldPersistShowingAppOnTablet = true;
		},
		setAssetAllocationIntroduced: (state, { payload }: PayloadAction<boolean>) => {
			state.isAssetAllocationIntroduced = payload;
		},
	},
});
