import { useService, useQuery } from '@hooks';
import { useMe } from './useMe';
import { ProfileEntity } from '@ui-modules/profile//utils/ProfileEntity';
import type { THidableProfileProperties, TProfile, TProfileWithUser } from '@typings';
import type { TProfileSection } from '@ui-modules/profile//utils/ProfileEntity';

export const useGetProfileQuery = (profileId: TProfile['id'] | undefined) => {
	const api = useService('ApiService');

	// Me profile.
	const { profile: myProfile, user: myUser } = useMe();
	const isMe = !profileId || myProfile.id === profileId;

	const myProfileWithUser: TProfileWithUser | undefined = isMe
		? {
				...myProfile,
				user: myUser,
		  }
		: undefined;
	const queryResult = useQuery(
		['profile.getProfile', profileId],
		async () => api.profile.getProfile(profileId as string),
		{
			enabled: !isMe,
			placeholderData: myProfileWithUser,
		},
	);

	const fullName = queryResult.data ? `${queryResult.data.user.firstName} ${queryResult.data.user.lastName}` : '';
	const isPropertyHidden = (section: THidableProfileProperties) =>
		ProfileEntity.isProfileSectionHiddenByUser(section, queryResult.data?.hideProperties);
	const isSectionInvisible = (section: TProfileSection) => {
		if (!queryResult.data) return true;
		return !ProfileEntity.canShowProfileSection(section, queryResult.data['@type'], queryResult.data.user.roles, isMe);
	};

	return {
		isMe,
		fullName,
		isPropertyHidden,
		isSectionInvisible,
		...queryResult,
	};
};
