import { useTranslation } from '@hooks';
import { Modal } from '@ui-kit';
import type { IModalProps } from '@ui-kit';
import type { TFile } from '@typings';
import type { IFilesWidgetApiCallbacks } from '../FilesWidgetApiCallbacks.interface';

/** Confirms deleting of an activity and performs it. */
const DeleteFileModal = ({ visible, fileId, onClose, onDeleteFile }: IDeleteFileModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			subTitle={t('This file will be permanently deleted.')}
			title={t('Are you sure you want to delete?')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				onDeleteFile(fileId);
				onClose();
			}}
		/>
	);
};

export interface IDeleteFileModalProps
	extends Pick<IModalProps, 'visible'>,
		Pick<IFilesWidgetApiCallbacks, 'onDeleteFile'> {
	/** Target File ID. */
	fileId: TFile['id'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default DeleteFileModal;
