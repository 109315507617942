import { useMutation, useService } from '@hooks';
import type { TNetworkConnection } from '@typings';

export const useEditNetworkConnectionMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useMutation<TNetworkConnection, Error, any>(
		reactQuery.queryKeys.editNetworkConnection(),
		async (data: TNetworkConnection) => await api.networkConnection.updateNetworkConnection(data),
	);
};
