import styles from './LogoHeader.module.css';
import { AppLogoImage, AppNameImage } from '@ui-kit';

const LogoHeader = () => {
	return (
		<header className={styles.header}>
			<div className={styles.header__logoWrapper}>
				<AppLogoImage className={styles.header__logoImage} height={44} width={46} />
				<AppNameImage className={styles.header__logoTitle} height={24} width={190} />
			</div>
		</header>
	);
};

export default LogoHeader;
