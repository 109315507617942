import { ErrorBoundary } from '../ErrorBoundary';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '@constants';
import { useEffect, useLayoutEffect, useLocation } from '@hooks';
import { useTabBarContext } from '../TabBar';
import type { ReactNode } from 'react';
import type { TAppTab } from '@typings';

/** Top level wrapper for page components. */
const Page = ({ children, title, scrollDisabled, tabName }: IPageProps) => {
	const { forceSetSelectedTabName } = useTabBarContext();
	const location = useLocation();

	useEffect(() => {
		if (scrollDisabled) {
			document.body.style.overflow = 'hidden';
			return () => {
				document.body.style.overflow = 'auto';
			};
		}
	}, [scrollDisabled]);

	useLayoutEffect(
		function setTabNameState() {
			if (tabName) {
				forceSetSelectedTabName(tabName); // It is used to forceActive tab in TabBar component.
			}
		},
		[tabName, location],
	);

	return (
		<>
			<Helmet defaultTitle={APP_TITLE} titleTemplate={`%s | ${APP_TITLE}`}>
				<title>{title}</title>
			</Helmet>

			<ErrorBoundary>{children}</ErrorBoundary>
		</>
	);
};

export interface IPageProps {
	children: ReactNode;
	title: string;
	scrollDisabled?: boolean;
	/** Persist setting active tabName on the app TabBar for current page. */
	tabName?: TAppTab;
}

export default Page;
