import { useInfiniteQuery, useMemo } from '@hooks';
import { noop } from '@utils';
import type { TPaginatedList } from '@typings';
import type { QueryFunction, QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';

/** Wrapper around useInfiniteQuery to handle page data normalization & provide fetchMore function */
export const usePaginatedInfiniteQuery = <
	T = unknown,
	TQueryFnData extends TPaginatedList<T> = TPaginatedList<T>,
	TError = Error,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
	const infiniteQuery = useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn, {
		...options,
		getNextPageParam: (lastPage) => {
			if (lastPage.meta.hasNext) return lastPage.page + 1;
			return undefined;
		},
	});
	const data = useMemo<T[]>(
		function normalizeData() {
			return infiniteQuery.data ? infiniteQuery.data.pages.flatMap((page: any) => page.data) : [];
		},
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		...infiniteQuery,
		data,
		fetchMore,
	};
};
