import { useService, useQuery, useMemo, useTranslation, useMe } from '@hooks';
import { dayjs, toString } from '@utils';
import type { TEvent } from '@typings';
import { useCalendarState } from './useCalendarState';

export const useCalendarItemsQuery = (startDate: string, searchQuery?: string) => {
	const api = useService('ApiService');
	const { t } = useTranslation();
	const { selectedCalendarItems: events } = useCalendarState(dayjs(startDate, 'YYYY-MM-DD').toISOString());
	const { user } = useMe();

	const isAdminOrStaff = user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_STAFF');

	const { data: promotedEvents = [], isLoading } = useQuery<TEvent[], Error>(
		['calendarItem.getPromotedCalendarItems'],
		async () => api.calendarItem.getPromotedCalendarItems({ startDate: dayjs().format('YYYY-MM-DD') }),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);

	const allEvents = useMemo(() => {
		return filterEvents(isAdminOrStaff ? events : events.concat(promotedEvents), searchQuery);
	}, [searchQuery, promotedEvents, events]);

	const eventSections = useMemo(() => {
		const sections = [];

		if (promotedEvents?.length) {
			const filteredPromotedEvents = filterEvents(promotedEvents, searchQuery);
			if (filteredPromotedEvents.length)
				sections.push({
					title: t('Promoted'),
					data: filteredPromotedEvents,
				});
		}
		const filteredEvents = filterEvents(events, searchQuery);
		if (filteredEvents.length) {
			sections.push({
				title: t('All Events and meetings'),
				data: filteredEvents,
			});
		}

		return sections;
	}, [promotedEvents, searchQuery, t, events]);

	return {
		eventSections,
		allEventsLoading: isLoading,
		allEvents: allEvents,
	};
};

function filterEvents(events: TEvent[] | undefined, query = '') {
	if (!events) return [];
	const preparedQuery = query.trim();
	if (!preparedQuery.length) return events;

	return events.filter(
		(event) =>
			event.title.toLowerCase().includes(preparedQuery.toLowerCase()) ||
			toString(event.description).toLowerCase().includes(preparedQuery.toLowerCase()) ||
			toString(event.venueCity).toLowerCase().includes(preparedQuery.toLowerCase()) ||
			toString(event.venueName).toLowerCase().includes(preparedQuery.toLowerCase()) ||
			toString(dayjs(event.startDate).format('MMMM')).toLowerCase().includes(preparedQuery.toLowerCase()),
	);
}
