import { memo } from 'react';
import {
	AddressBooksIcon,
	BlockLink,
	ChevronRightIcon,
	MemberGuideBookIcon,
	PartnerBenefitsIcon,
	ReferAnAssoicateIcon,
} from '@ui-kit';
import { useTranslation, useRef } from '@hooks';
import { ROUTES } from '@constants';
import styles from './QuickLinksWidget.module.css';
import type { ReactNode } from 'react';

const QuickLinksWidget = () => {
	const { t } = useTranslation();

	const quickLinks = useRef<TQuickLink[]>([
		{
			title: t('Nominate a New Member'),
			Icon: <ReferAnAssoicateIcon className={styles.quickLinksWidget__icon} />,
			link: ROUTES.referAssociate(),
		},
		{
			title: t('Member Guidebook'),
			Icon: <MemberGuideBookIcon className={styles.quickLinksWidget__icon} />,
			link: ROUTES.memberGuideBook(),
		},
		{
			title: t('Member Benefits'),
			Icon: <PartnerBenefitsIcon className={styles.quickLinksWidget__icon} />,
			link: ROUTES.partnerBenefits(),
		},
		{
			title: t('Address Book'),
			Icon: <AddressBooksIcon className={styles.quickLinksWidget__icon} />,
			link: ROUTES.addressBook(),
		},
	]).current;

	return (
		<div className={styles.quickLinksWidget}>
			{quickLinks.map((quickLink, index) => (
				<BlockLink className={styles.quickLinksWidget__item} key={quickLink.link + index} to={quickLink.link}>
					<div className={styles.quickLinksWidget__iconBox}>{quickLink.Icon}</div>
					<div className={styles.quickLinksWidget__title}>
						<h4>{quickLink.title}</h4>
					</div>
					<div className={styles.quickLinksWidget__chevronIconBox}>
						<ChevronRightIcon className={styles.quickLinksWidget__chevronIcon} width={16} />
					</div>
				</BlockLink>
			))}
		</div>
	);
};

export type TQuickLink = {
	title: string;
	Icon: ReactNode;
	link: string;
};

export default memo(QuickLinksWidget);
