import { useMutation, useNotification } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import type { TActivity } from '@typings';
import type { Activity } from 'getstream';

export const useToggleActivityBookmarkMutation = () => {
	const { showUnknownError } = useNotification();
	const { onToggleReaction } = useFeedContext();
	const updateActivityInFeedCache = useUpdateActivityInFeedCache();

	return useMutation<void, Error, TActivity>(
		['toggleBookmarkReaction'],
		async (activity) => onToggleReaction('bookmark', activity as any as Activity),
		{
			async onSuccess(response, activity, context) {
				updateActivityInFeedCache(activity.id);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
