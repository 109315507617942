/** Extract data required to mount blackthorn url using "https://events.blackthorn.io/embed.js" script. */
export const parseBlackthornUrl = (blackthornUrl: string): TBlackthornEventData => {
	const [, orgId = '', eventPath = ''] = blackthornUrl.match(/https:\/\/events.blackthorn.io\/(.*?)\/(.*)/) ?? [];

	return { orgId, eventPath };
};

/** Data required to mount blackthorn url using "https://events.blackthorn.io/embed.js" script. */
export type TBlackthornEventData = {
	orgId: string;
	eventPath: string;
};
