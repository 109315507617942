import { useCallback } from 'react';
import { useNotification, useService, useTranslation } from '@hooks';
import { useLatestWaiverQuery } from './useLatestWaiverQuery';
import { useSendUserWaiverAgreement } from './useSendUserWaiverAgreementMutation';
import { useWaiverSignedInfo } from './useWaiverSignedInfoQuery';

export const useSolicitationWaiver = (communityId = '') => {
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess } = useNotification();
	const { data: latestWaiver, isLoading: latestWaiverLoading, refetch: refetchLatestWaiver } = useLatestWaiverQuery();
	const { mutate: sendAgreement, isLoading: isSending } = useSendUserWaiverAgreement();
	const {
		data: signedInfo,
		isLoading: isSignedInfoLoading,
		refetch: refetchSignedInfo,
		isFetching: isSignedInfoFetching,
	} = useWaiverSignedInfo();

	const onSubmit = useCallback(() => {
		sendAgreement(undefined, {
			onSuccess: () => {
				if (!signedInfo?.isAnySigned) {
					showSuccess({ title: t('Invest enabled'), subtitle: t('Disable Invest in Settings') });
				}
				refetchSignedInfo();
				refetchLatestWaiver();
				reactQuery.queryClient.refetchQueries({ queryKey: reactQuery.queryKeys.getDeal(), stale: true });
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getMyDeals());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getAllDeals());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getNotInterestedDeals());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getNetworkDeals());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealSettings());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getAllDealFilterConfig());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getProfileDeal());
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getMyInvestNetworks());
				reactQuery.queryClient.refetchQueries(['community.getForeignInvestNetworks']);
				reactQuery.queryClient.refetchQueries(['invest.getAllDealFilters']);
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getAllDealFilterConfig());
				reactQuery.queryClient.refetchQueries(['dealFilter.viaCommunityId']);
				reactQuery.refetchDealsQueries(String(communityId));
			},
			onError: (error) => {
				showUnknownError(error as Error);
			},
		});
	}, [showSuccess, showUnknownError, refetchSignedInfo, refetchLatestWaiver, sendAgreement, t, signedInfo]);

	const isSolicitationWaiverMessageShown = !signedInfo?.isAnySigned || !signedInfo?.isLatestSigned;
	const isLoading = latestWaiverLoading || isSignedInfoLoading || isSending || isSignedInfoFetching;
	const isSolicitationWaiverSigned = signedInfo?.isLatestSigned;

	return {
		isLoading,
		isMessageShown: isSolicitationWaiverMessageShown,
		isFirstTimeRequest: !signedInfo?.isAnySigned,
		latestWaiver,
		onSubmit,
		isSolicitationWaiverSigned,
	};
};
