import { useNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationsSettings';
import { useCreateNotificationSetting } from '@ui-modules/settings/hooks/useCreateNotificationSettings';
import { useUpdateNotificationSettings } from '@ui-modules/settings/hooks/useUpdateNotificationSettings';
import { useEffect, useMe, useMemo, useService } from '@hooks';
import { map, without, filter } from '@utils';
import { useState } from 'reinspect';
import { EMPTY_NOTIFICATION_CHANNELS, NOTIFICATION_CHANNELS } from '@constants';
import type { TCommunity } from '@typings';

export const useConnectionsNotification = (communityId: TCommunity['id'], networkConnectionName: string) => {
	const { user } = useMe();
	const { notificationsSettings = [] } = useNotificationsSettings();
	const analytics = useService('AnalyticsService');

	const { mutate: createNotificationSetting } = useCreateNotificationSetting('Networks');

	const { mutate: updateNotificationSetting } = useUpdateNotificationSettings('Networks');

	const networkConnectionNotificationSettings = useMemo(
		() =>
			map(
				filter(
					filter(notificationsSettings, (item) => item.sourceId === communityId),
					(item) => {
						return (
							item.notificationId.includes('network_connection') &&
							item.notificationId !== 'network_connection_reply_created'
						);
					},
				),
				(item) => ({
					...item,
					channels: without(item.channels, 'chat/getStream'),
				}),
			),
		[notificationsSettings.length],
	);

	const isMutedInitially = useMemo(() => {
		if (networkConnectionNotificationSettings.length) {
			return networkConnectionNotificationSettings.every((setting) => setting.channels.length < 1);
		} else {
			return false;
		}
	}, [networkConnectionNotificationSettings.length]);

	const [muted, setMuted] = useState<boolean>(isMutedInitially);

	useEffect(() => {
		setMuted(isMutedInitially);
	}, [isMutedInitially]);

	const handleMuteToggle = () => {
		setMuted(!muted);

		const notificationIds = ['network_connection_created'];

		notificationIds.forEach((notificationId) => {
			const existingSetting = networkConnectionNotificationSettings.find(
				(setting) => setting.notificationId === notificationId,
			);
			if (existingSetting) {
				updateNotificationSetting({
					id: existingSetting.id,
					notificationSetting: {
						...existingSetting,
						channels: muted ? NOTIFICATION_CHANNELS : EMPTY_NOTIFICATION_CHANNELS,
					},
				});
			} else {
				createNotificationSetting({
					notificationId,
					owner: user['@id'],
					channels: [],
					constraint: 'community',
					sourceId: communityId,
				});
			}
		});

		analytics.trackGenericEvent(
			'{{connectionName}}AlertsChanged',
			{ '{{connectionName}}_alerts_preference': muted },
			{
				connectionName: networkConnectionName,
			},
		);
	};

	return { muted, handleMuteToggle };
};
