import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDeal, TFile } from '@typings';

export const useGetDealPreviewFilesQuery = (dealId?: string, isPreview?: boolean) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery(
		reactQuery.queryKeys.getDealPreviewFiles(dealId as TDeal['id'], null),
		async () => await api.dealFile.getDealPreviewFiles({ dealId: dealId as TDeal['id'], folderId: null }),
		{
			enabled: !!dealId && isPreview,
			select: (files) => {
				return files.map(({ mediaObjectOutputDto = {}, ...file }) => {
					return {
						...file,
						mediaObject: { ...mediaObjectOutputDto, openInViewerOnly: file.openInViewerOnly },
					} as never as TFile & { dealFileId?: string; status: string };
				});
			},
		},
	);
};
