import { useMe, useMemo, useTranslation } from '@hooks';
import { compact } from '@utils';
import { useAssetAllocationCommunitiesQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationCommunitiesQuery';
import { isMyAssetAllocationAllowed } from '@ui-modules/assetAllocation/utils/isMyAssetAllocationAllowed';
import type { TAssetAllocationTab, TAssetAllocationStaticTabSlug, TCommunityCompact } from '@typings';

export const useAssetAllocationTabsQuery = () => {
	const { t } = useTranslation();
	const { user } = useMe();

	const query = useAssetAllocationCommunitiesQuery({
		select: (assetAllocationSet) => {
			if (!assetAllocationSet) return [];

			const communityTabs = assetAllocationSet.communities
				.filter(
					(assetAllocationCommunity) =>
						isGroupCommunity(assetAllocationCommunity.community) ||
						isChapterCommunity(assetAllocationCommunity.community),
				)
				.map<TAssetAllocationTab>((assetAllocationCommunity) => ({
					communityId: assetAllocationCommunity.community.id,
					title: assetAllocationCommunity.community.shortName,
					slug: assetAllocationCommunity.community.id,
				}))
				.sort((previous, next) => previous.title.localeCompare(next.title));

			return communityTabs;
		},
	});

	const tabs = useMemo(() => {
		if (query.isLoading) return [];
		const communityTabs = query.data || [];
		const myAssetAllocationAllowed = isMyAssetAllocationAllowed(user);
		const staticTabs: TAssetAllocationTab<TAssetAllocationStaticTabSlug>[] = compact([
			{
				title: t('Networks'),
				slug: 'networks',
			},
			{
				title: t('Tiger 21').toUpperCase(),
				slug: 'tiger21',
			},
			myAssetAllocationAllowed && {
				title: t('Me'),
				slug: 'me',
			},
		]);
		const allTabs = communityTabs.concat(staticTabs);
		return allTabs;
	}, [query.data, query.isLoading]);

	return { ...query, data: tabs };
};

function isGroupCommunity(community: TCommunityCompact) {
	return community.definition === 'group';
}

function isChapterCommunity(community: TCommunityCompact) {
	return community.definition === 'chapter';
}
