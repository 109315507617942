import { Page } from '../../components';
import { useParams, useTranslation } from '@hooks';
import ConnectionForm from '@ui-modules/connections/components/ConnectionForm';

const CreateNetworkConnectionPage = () => {
	const { t } = useTranslation();
	const { communityId } = useParams();

	if (!communityId) return null;

	return (
		<Page scrollDisabled tabName={'networks'} title={t('New Connection Page')}>
			<ConnectionForm communityId={communityId} />
		</Page>
	);
};

export default CreateNetworkConnectionPage;
