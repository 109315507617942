import { useTranslation, useNavigate } from '@hooks';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { ResetIcon, RecentIcon } from '@ui-kit';
import { ROUTES } from '@constants';
import styles from './RecentSearch.module.css';
import { noop } from 'lodash';

export interface IRecentSearchesProps {
	debouncedSearchQuery: string;
}

const RecentSearches = ({ debouncedSearchQuery }: IRecentSearchesProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { searchQuery, setIsSearchActive } = useGlobalSearchState();
	const { clearRecentSearches, recentSearches, removeRecentSearch } = useGlobalSearchHistory(
		searchQuery,
		debouncedSearchQuery,
	);

	return (
		<div className={styles.recentSearch__wrapper}>
			<div className={styles.recentSearch__header}>
				<span className={styles.recentSearch__title}>{t('Your searches')}</span>
				<span
					className={styles.recentSearch__clear}
					onClick={() => clearRecentSearches()}
					onKeyPress={() => clearRecentSearches()}
				>
					{t('Clear')}
				</span>
			</div>
			{recentSearches.map((recentSearch) => (
				<div
					className={styles.recentSearch__searchLine}
					key={recentSearch}
					onClick={() => {
						navigate(ROUTES.searchResults(recentSearch));
						setIsSearchActive(false);
					}}
					onKeyPress={noop}
				>
					<RecentIcon height={14} width={14} />
					<span className={styles.recentSearch__option}>{recentSearch}</span>
					<ResetIcon
						height={10}
						width={14}
						onClick={(e) => {
							e.stopPropagation();
							removeRecentSearch(recentSearch);
						}}
					/>
				</div>
			))}
		</div>
	);
};

export default RecentSearches;
