import { Page, PageLayout, Outlet } from '../../components';
import { useCallback, useParams, useTranslation, useNavigate, useEffect, useService } from '@hooks';
import { SearchableScreenHeader } from '@ui-kit';
import { useMemo, useState, useEvent } from '@hooks';
import { Backdrop, Calendar } from '@ui-kit/components';
import { dayjs, clsx } from '@utils';
import styles from './CalendarPage.module.css';
import EventSectionList from '@ui-modules/events/components/EventSectionList';
import CalendarItemsQuery from '@ui-modules/events/components/CalendarItemsQuery/CalendarItemsQuery';
import { useCalendarMarkedDates } from '@ui-modules/events/hooks/useCalendarMarkedDays';
import { useUploadCalendarItems } from '@ui-modules/events/hooks/useUploadCalendarItems';
import type { TEvent, TUid } from '@typings';
import { ROUTES } from '@constants';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const isMeeting = (event: TEvent) => event['@type'] === 'Meeting';

const SIDE_BAR_CONTENT_ID = 'sidebar-content-id';
const CALENDAR_SEARCH_HEADER_HEIGHT = 80;

const CalendarPage = () => {
	const date = useSearchParams()[0].get('startDate') || dayjs().format('YYYY-MM-DD');
	const { t } = useTranslation();
	const markedDates = useCalendarMarkedDates();
	const [searchQuery, setSearchQuery] = useState('');
	const { uploadCalendarItems } = useUploadCalendarItems();
	const [isCalendarShown, setIsCalendarShown] = useState(true);
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [isCalendarHidden, setIsCalendarHidden] = useState(false);
	const [innerHeight, setInnerHeight] = useState(window.innerHeight);
	const selectedEventId = useParams<ICalendarPageParams>().eventId;
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const onDateChanged = useCallback(
		(date: string) => {
			navigate({ search: `?${createSearchParams({ startDate: date })}` }, { replace: true });
		},
		[navigate],
	);

	const onDateRangeChanged = useCallback(
		(start: string) => {
			const dateWithOffset = dayjs(start).subtract(3, 'months');
			const dateFormatted = dateWithOffset.format('YYYY-MM-DD');
			uploadCalendarItems(dateFormatted);
		},
		[uploadCalendarItems],
	);

	const handleSelectEvent = useEvent((event: TEvent) => {
		const search = `?${createSearchParams({ startDate: date })}`;
		if (isMeeting(event)) {
			navigate({ pathname: ROUTES.calendarMeeting(event.id), search });
		} else {
			navigate({ pathname: ROUTES.calendarEvent(event.id), search });
		}
		analytics.trackEvent('EventInteractions', { event_name: event.title });
	});

	const handleFirstEventLoaded = useEvent((firstLoadedEvent?: TEvent) => {
		if (!!firstLoadedEvent && !selectedEventId) {
			setTimeout(
				() =>
					isMeeting(firstLoadedEvent)
						? navigate(ROUTES.calendarMeeting(firstLoadedEvent.id), { replace: true })
						: navigate(ROUTES.calendarEvent(firstLoadedEvent.id), { replace: true }),
				0,
			); // To resolve "You should call navigate() in a React.useEffect(), not when your component is first rendered." [@dmitriy.nikolenko]
		}
	});

	const onScroll = useCallback(
		(position: number, scrollHeight: number) => {
			const sideBarContent = document.getElementById(SIDE_BAR_CONTENT_ID);
			const allowHide = sideBarContent && sideBarContent.clientHeight - CALENDAR_SEARCH_HEADER_HEIGHT < scrollHeight;
			if (allowHide) {
				setIsCalendarHidden(position !== 0);
			}
		},
		[setIsCalendarHidden],
	);

	const onResize = useCallback(() => {
		if (window.innerHeight > innerHeight) {
			setIsCalendarHidden(false);
		}
		setInnerHeight(window.innerHeight);
	}, [setInnerHeight, innerHeight]);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, [onResize]);

	const calendarSideBar = useMemo(() => {
		return (
			<CalendarItemsQuery searchQuery={searchQuery} selectedDate={date} onFirstEventLoaded={handleFirstEventLoaded}>
				{({ eventSections, isLoading }) => {
					return (
						<div className={styles.calendarPage__sideBarContent} id={SIDE_BAR_CONTENT_ID}>
							<div className={styles.calendarPage__header}>
								<SearchableScreenHeader
									placeholder={t('Search events')}
									searchQuery={searchQuery}
									searchTitle={t('Search')}
									title={t('Calendar')}
									onBlur={() => setIsSearchActive(false)}
									onChangeSearchQuery={setSearchQuery}
									onFocus={() => setIsSearchActive(true)}
									onSearchOpened={(open) => setIsCalendarShown(!open)}
								/>
								{isCalendarShown && (
									<div
										className={clsx(
											styles.calendarPage__calendar,
											isCalendarHidden ? styles.calendarPage__calendar_hidden : styles.calendarPage__calendar_visible,
										)}
									>
										<Calendar
											daysLabels={[t('SUN'), t('MON'), t('TUE'), t('WED'), t('THU'), t('FRI'), t('SAT')]}
											getCellProps={(date) => ({ mode: markedDates[date] })}
											value={date}
											onChange={onDateChanged}
											onRangeChanged={onDateRangeChanged}
										/>
									</div>
								)}
							</div>
							<EventSectionList
								eventSections={eventSections}
								isLoading={isLoading}
								ListFooterComponent={<div className={styles.calendarPage__sectionListFooter} />}
								selectedEventId={selectedEventId}
								onScroll={onScroll}
								onSelectEvent={handleSelectEvent}
							/>
						</div>
					);
				}}
			</CalendarItemsQuery>
		);
	}, [
		selectedEventId,
		searchQuery,
		date,
		onDateChanged,
		markedDates,
		onDateRangeChanged,
		setIsCalendarShown,
		isCalendarShown,
		t,
		handleFirstEventLoaded,
		handleSelectEvent,
		isCalendarHidden,
		onScroll,
	]);

	return (
		<Page title={t('Calendar')}>
			<PageLayout
				containerMode={false}
				sideBarClassName={styles.calendarPage__sidebar}
				sidebarContent={calendarSideBar}
			>
				<Outlet /> {/* GroupPage is rendered here */}
				<Backdrop hidden={!isSearchActive} />
			</PageLayout>
		</Page>
	);
};

interface ICalendarPageParams extends Record<string, string | undefined> {
	groupId: TUid | undefined;
}

export default CalendarPage;
