import { usePaginatedInfiniteQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity, TPaginatedList, TChapterMember } from '@typings';
import type { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';

export const useChapterMembersQuery = (
	communityId: TCommunity['id'] | undefined,
	options?: Omit<UseInfiniteQueryOptions<TData, Error, TData, TData, QueryKey>, 'queryKey' | 'queryFn'>,
) => {
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TChapterMember>(
		['community.getChapterMembers', communityId],
		async ({ pageParam: page }) => await api.membership.getChapterMembers(communityId!, page),
		{
			enabled: !!communityId,
			staleTime: dayjs.duration(10, 'minutes').asMilliseconds(),
			...options,
		},
	);
};

export function prepareDataForSectionFlashList<T extends Record<string, unknown>>(
	sortedData: T[],
	headerField: keyof T,
): readonly [Array<T | string>, number[]] {
	if (!sortedData?.length) return [[], []];
	const headersMap = new Map();
	const preparedData: Array<T | string> = [];

	for (let index = 0; index < sortedData.length; index++) {
		const item = sortedData[index];
		const header = item[headerField];
		if (!headersMap.has(header)) {
			const lettersIndexShift = headersMap.size;
			headersMap.set(header, index + lettersIndexShift);
			preparedData.push(String(header));
		}
		preparedData.push(item);
	}

	const stickyHeaderIndices = Array.from(headersMap.values());

	return [preparedData, stickyHeaderIndices] as const;
}

type TData = TPaginatedList<TChapterMember>;
