import { AccountMenu, Page, PageLayout } from '../../components';
import { useNavigate, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import NavigationCard from '@ui-modules/settings/components/NavigationCard';
import styles from './SettingsPage.module.css';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';

const SettingsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isSettingsAvailable } = useDealsEnabled();

	return (
		<Page title={t('Settings')}>
			<PageLayout sidebarContent={<AccountMenu />}>
				<div className={styles.settings}>
					<h1 className={styles.settings__title}>{t('Settings')}</h1>
					<div className={styles.settings__items}>
						<NavigationCard
							description={t('Manage who you’ve blocked')}
							title={t('Blocked Users')}
							onClick={() => navigate(ROUTES.blockedUsersSettings())}
						/>
						{isSettingsAvailable && (
							<>
								<div className={styles.settings__divider} />
								<NavigationCard
									description={t('Manage invest preferences')}
									title={t('Invest')}
									onClick={() => navigate(ROUTES.dealSettings())}
								/>
							</>
						)}
						<div className={styles.settings__divider} />
						<NavigationCard
							description={t('Customize your notifications')}
							title={t('Notifications')}
							onClick={() => navigate(ROUTES.notificationSettings())}
						/>
					</div>
				</div>
			</PageLayout>
		</Page>
	);
};

export default SettingsPage;
