import type { TDealType, TDealSubClass } from '@tiger21-llc/connect-shared/src/typings';

/** Helper function to combine filer title for NetworkDeals Page
 *  We use /for-settings API request, cause in this scenario we need only 1 request for getting everything that we need
 */
export const getNetworkDealsFilterTitle = (dealTypes: TDealType[], activeSubClasses: TDealSubClass[]) => {
	const capitalizeWithDashes = (s: string) =>
		s
			.split(' ')
			.map((word) =>
				word
					.split('-')
					.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
					.join('-'),
			)
			.join(' ');

	const subClasses = activeSubClasses?.map((item) => capitalizeWithDashes(item.name)).join(', ');

	const types = dealTypes?.map((item) => capitalizeWithDashes(item.name)).join(', ');

	let title = subClasses;
	if (types && types.length > 0) {
		title += ' of type ' + types;
	}

	return title;
};
