import { AbstractWebRiskApiService } from './AbstractWebRiskApiService';
import type { IMixPanelAnalyticsService } from '../../interfaces/AnalyticsService.MixPanel.interface';
import type { IBugTracker } from '../../interfaces/BugTracker.interface';
import type { IDevLogger } from '../../interfaces/DevLogger.interface';
import type { IEnvironmentConfig, IGooglePlaceConfig } from '../../interfaces/AppConfig.interface';
import type { TWebRiskThread } from '@typings';
import type { TWebRiskThreadsResponse } from '../../interfaces/WebRisksApiService.interface';

export class WebRiskApiService extends AbstractWebRiskApiService {
	static inject = ['AppConfigService', 'logger', 'SentryService', 'AnalyticsService'];
	constructor(
		appConfig: IEnvironmentConfig & IGooglePlaceConfig,
		logger: IDevLogger,
		bugTracker: IBugTracker,
		analytics: IMixPanelAnalyticsService,
	) {
		super(appConfig, logger, bugTracker, analytics);
	}

	async findThreats(urls: string[]): Promise<TWebRiskThread[] | undefined> {
		const response = await this.axiosClient.post<unknown, TWebRiskThreadsResponse>('/threatMatches:find', {
			client: {
				clientId: 'Tiger21Connect',
				clientVersion: '1.0.0',
			},
			threatInfo: {
				threatTypes: ['MALWARE', 'SOCIAL_ENGINEERING', 'UNWANTED_SOFTWARE'],
				platformTypes: ['ANY_PLATFORM'],
				threatEntryTypes: ['URL'],
				threatEntries: urls.map((url) => ({ url })),
			},
		});
		const threats = response?.matches?.map((threat) => ({
			url: threat.threat.url,
			threatType: threat.threatType,
		}));

		return threats;
	}
}
