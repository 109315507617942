import { useService } from '@services';

export const useCollapsedNotificationsSettings = () => {
	const { settings, useBindActionCreators, useAppSelector } = useService('ReduxService');

	const collapsedNotificationsSettings = useAppSelector((store) => store.settings.collapsedNotificationsSettings);
	const { addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } = useBindActionCreators(settings);

	return { collapsedNotificationsSettings, addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings };
};
