import { Page, BackLinkPageWrapper } from '../../components';
import { ActivityIndicator, Gap } from '@ui-kit';
import { SinglePost } from 'react-activity-feed';
import FeedActivity from '@ui-modules/feed/components/FeedActivity';
import FeedNotifier from '@ui-modules/feed/components/FeedNotifier';
import FeedCommentList from '@ui-modules/feed/components/FeedCommentList';
import CommentControls from '@ui-modules/feed/components/CommentControls';
import {
	useCallback,
	useHandleBackButton,
	useMe,
	useNavigate,
	useNotification,
	useParams,
	useRestoreScroll,
	useSearchParams,
	useService,
	useState,
	useTrackAlgoliaEventEffect,
	useTranslation,
} from '@hooks';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import { useCrossPromotionsAnalytics } from '@ui-modules/feed/hooks/useCrossPromotionsAnalytics';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useValidateDisplayingActivity } from '@ui-modules/feed/hooks/useValidateDisplayingActivity';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import { scrollToComment } from '@ui-modules/feed/utils/scrollToComment';
import { useDoReactionsFilterRequest } from '@ui-modules/feed/hooks/useDoReactionsFilterRequest';
import { useSyncActivityReactionsWithParentFeedsEffect } from '@ui-modules/feed/hooks/useSyncActivityReactionsWithParentFeedsEffect';
import { ActivityEntity, clsx, downloadFile, extractFileExtensionFromUrl, extractIdFromIri } from '@utils';
import { ROUTES } from '@constants';
import { ActivityNoLongerAvailableError } from '@errors';
import styles from './PostPage.module.css';
import type { TActivity, TComment, TEvent } from '@typings';
import type { ActivityProps, NewActivitiesNotificationProps } from 'react-activity-feed';

const PostPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { onBackButtonPress } = useHandleBackButton();
	const { user } = useMe();
	const { showInfo, showUnknownError } = useNotification();
	const analytics = useService('AnalyticsService');

	const activityId = useParams().activityId as IPostPageParams['activityId'];
	const streamFeedId = useSearchParams()[0].get('streamFeedId');
	const pdfViewer = usePdfViewer();
	const { scrollerRef, restoreScroll } = useRestoreScroll(`singleFeed-${activityId}`);

	const doReactionsFilterRequest = useDoReactionsFilterRequest({
		activityId: activityId,
		onLoadedFromCache: restoreScroll,
	});

	const { trackActivityView } = useCrossPromotionsAnalytics();
	const { data: activity } = useFeedActivityQuery(activityId, streamFeedId, {
		onSuccess: (activity) => activity && trackActivityView(activity),
		onError: (error) => {
			navigate(ROUTES.home(), { replace: true });
			if (error instanceof ActivityNoLongerAvailableError) {
				showInfo(ActivityNoLongerAvailableError.getNotificationMessage(t));
			} else {
				showUnknownError(error);
			}
		},
	});
	useValidateDisplayingActivity(activity, () => navigate(ROUTES.home(), { replace: true }));
	const communityId = extractIdFromIri(
		String(activity?.collectionCommunityReference?.data?.id || activity?.community?.id),
	); // migration to use "collectionCommunityReference" (T21C-2485)
	const { data: community } = useCommunityQuery(communityId);
	const communitySlug = activity?.collectionCommunityReference?.data?.slug || activity?.community?.slug; // migration to use "collectionCommunityReference" (T21C-2485)
	const finalFeedId = streamFeedId || communitySlug || user.slug;
	const [editingReaction, setEditingReaction] = useState<{ comment?: TComment; reply?: boolean } | null>(null);
	// User action handlers.
	const openPost = (activityId: TActivity['id']) => navigate(ROUTES.post(activityId));
	const openEvent = (event: TEvent) =>
		navigate(event['@type'] === 'Event' ? ROUTES.viewEvent(event.id) : ROUTES.viewMeeting(event.id));
	const pressPostButton = useHandleActivityLink();
	const handleReply = (comment?: TComment) => {
		setEditingReaction(comment ? { comment: comment, reply: true } : null);
	};
	const goBack = () => onBackButtonPress();
	const openFileUrl = (fileUrl: string) => {
		const fileExtension = extractFileExtensionFromUrl(fileUrl);
		if (fileExtension === 'pdf') pdfViewer.open(fileUrl);
		else downloadFile(fileUrl);
	};

	// Menu
	const renderActivity = useCallback(
		({ activity: streamActivity }: ActivityProps) => {
			const activity = streamActivity as unknown as TActivity; // can't reuse stream typescript definitions ;-( .
			const activityEntity = new ActivityEntity(activity as unknown as TActivity);
			const isLearnActivity = activityEntity.isFromLearn() || finalFeedId.startsWith?.('L--');
			return (
				<>
					<FeedActivity
						activity={activity}
						isAlwaysExpanded
						isCard={true}
						isSourceHidden={false}
						key={activity.id}
						showBookmark={finalFeedId.startsWith('L--') || activityEntity.shouldShowBookmark()}
						withSeparator={false}
						onActivityDeleted={goBack}
						onClickComment={openPost}
						onClickEvent={openEvent}
						onClickReply={() => handleReply()}
						onFileClick={openFileUrl}
						onImageOpened={() => {
							if (isLearnActivity)
								analytics.trackEvent('LearnInteractions', {
									interaction_type: 'image opened',
								});
						}}
						onInteracted={(interactionSource) => {
							analytics.trackEvent('PostInteractions', {
								post_owner: activity.actor.data.roles.includes('ROLE_STAFF') ? 'staff' : 'member',
								post_source: user.id,
								interaction_source: interactionSource,
							});
						}}
						onLinkClicked={() => {
							if (isLearnActivity)
								analytics.trackEvent('LearnInteractions', {
									interaction_type: 'link clicked',
								});
						}}
						onPressActivityLink={pressPostButton}
						onVideoPlayed={() => {
							if (isLearnActivity)
								analytics.trackEvent('LearnInteractions', {
									interaction_type: 'video played',
								});
						}}
					/>
					<Gap gap={12} />
					<CommentControls.EditingContextProvider>
						<FeedCommentList
							activity={activity as unknown as TActivity}
							className={styles.postPage__commentList}
							feedSlug={finalFeedId}
							openFileUrl={openFileUrl}
						/>
						<div className={styles.commentControls}>
							<CommentControls
								activity={activity as unknown as TActivity} // can't reuse stream typescript definitions ;-( .
								community={community}
								customClass={styles.commentControls__input}
								onCommentSent={(isEditing, commentId) => {
									scrollToComment(scrollerRef, isEditing, commentId);
								}}
							/>
						</div>
					</CommentControls.EditingContextProvider>
				</>
			);
		},
		[activityId, activity, streamFeedId, community, finalFeedId],
	);
	const renderNotifier = useCallback(
		(props: NewActivitiesNotificationProps) => (
			<FeedNotifier
				{...props}
				activityId={activityId}
				title={t('Comments updated')}
				userId={finalFeedId}
				onClick={() => {
					scrollerRef.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
					(props.onClick as () => void)?.();
				}}
			/>
		),
		[activityId],
	);

	const renderActivityIndicator = useCallback(() => <ActivityIndicator key="loader" size="medium" type="fit" />, []);

	useTrackAlgoliaEventEffect('Discussion Viewed', { discussionId: String(activity?.foreign_id) }, [activity]);
	useSyncActivityReactionsWithParentFeedsEffect(activity);

	return (
		<Page title={t('Post Page')}>
			<BackLinkPageWrapper>
				<div className={clsx(styles.container, !!editingReaction && styles.container__edit)}>
					<div className={styles.postPage__wrapper} ref={scrollerRef}>
						{activity ? (
							<div className={styles.postPage}>
								<SinglePost
									Activity={renderActivity}
									activityId={activityId}
									doFeedRequest={async (client, feedGroup, userId, options) =>
										/* 	Immediately return the activity just fetched via useFeedActivityQuery.
											GetStream makes own call to API to get the same activity.
											This code helps prevent the second internal call and avoid issue with inability to display data for Learn old posts (@see T21C-7586)
										 	[@dmitriy.nikolenko]
										*/
										({
											next: '',
											results: [activity],
										} as any)
									}
									doReactionsFilterRequest={doReactionsFilterRequest}
									feedGroup="user"
									key={activity?.id} // Force rerender to avoid issue when the post is opened via notifications and the previous opened is shown (@see T21C-7144) [@DmitriyNikolenko]
									LoadingIndicator={renderActivityIndicator}
									Notifier={renderNotifier}
									notify
									options={{ withReactionCounts: true, withOwnChildren: true }} // withReactionCounts & withOwnChildren should be enabled to make comment likes work properly. Fix (T21C-2869). [@dmitriy.nikolenko]
									userId={finalFeedId}
								/>
							</div>
						) : (
							renderActivityIndicator()
						)}
					</div>
				</div>
			</BackLinkPageWrapper>
		</Page>
	);
};

interface IPostPageParams {
	activityId: string;
}

export default PostPage;
