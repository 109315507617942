import type { TBlockedUser } from '@typings';
import type { IAutocompleteSuggestionListProps, TFullUserForAutocomplete } from './AutocompleteSuggestionList';

// Filter the user suggestions based on the criteria (roles, blocked users, etc.)
export const filterSuggestions = ({ values, blockedAndBlockingUsers, myUserId }: TFilterSuggestionParams) =>
	values?.filter((value) => {
		const user: TFullUserForAutocomplete = value as unknown as TFullUserForAutocomplete;
		const isBlockedByMe = blockedAndBlockingUsers.some((relation) => relation.id === user.userId);
		const hasBlockedMe = blockedAndBlockingUsers.some((relation) => relation.id === user.userId);

		// Filter out blocked users, users with specific roles, and the current user
		return (
			!hasBlockedMe &&
			!isBlockedByMe &&
			!user.roles?.includes('ROLE_FORMER') &&
			!user.roles?.includes('ROLE_INACTIVE') &&
			user.userId !== myUserId
		);
	});

export const extractSearchQuery = (searchFilter: string | undefined, cursorPosition?: number) => {
	if (!searchFilter) return undefined;

	// Remove all mentions that are formatted as @[Name](ID)
	const sanitizedSearchFilter = searchFilter.replace(/@\[[^\]]+\]\([^)]+\)/g, '');

	// Get substring up to the cursor position
	const uptoCursor = sanitizedSearchFilter.substring(0, cursorPosition);
	const lastAtIndex = uptoCursor.lastIndexOf('@');

	if (lastAtIndex === -1) return undefined; // No `@` symbol before cursor

	// Check if there's a space before the last `@` and no characters after it
	const beforeAt = uptoCursor.substring(lastAtIndex - 1, lastAtIndex);
	const afterAt = uptoCursor.substring(lastAtIndex + 1);

	// New logic to reset query when the last `@` follows a space and no characters after it
	if ((beforeAt === ' ' && afterAt === '') || sanitizedSearchFilter.charAt(Number(cursorPosition) - 1) === ' ') {
		return ''; // Return empty to trigger a new suggestions list
	}

	// Extract the text after the last `@` symbol
	return uptoCursor.substring(lastAtIndex + 1).trim();
};

type TFilterSuggestionParams = {
	values: IAutocompleteSuggestionListProps['values'];
	blockedAndBlockingUsers: TBlockedUser[];
	myUserId: string;
};
