import type { TAttachments } from '@typings';

export const filterNestedArrs = <T>(arr: (T | T[] | undefined)[]) => {
	return arr
		.flat(Infinity)
		.filter((item) => !Array.isArray(item) || !item.includes(undefined as T))
		.filter((item) => item);
};

export const filterAttachments = (arr: TAttachments[]) => {
	if (arr.length) {
		const uploadedAttachments = arr.filter((item) => !item.url?.includes('blob'));
		const newAttachments = arr.filter((item) => item.url?.includes('blob'));
		return {
			uploadedAttachments,
			newAttachments,
		};
	}
	return {};
};
