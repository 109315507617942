import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TWaiverSignedInfo } from '@typings';

export const useWaiverSignedInfo = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TWaiverSignedInfo, Error>(reactQuery.queryKeys.getWaiversSignedInfo(), async () =>
		api.waiver.getWaiverSignedInfo(),
	);
};
