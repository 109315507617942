import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import type { TNotificationSetting } from '@typings';
import { without } from 'lodash';
import { useMemo } from 'react';
import type { TDefaultNotificationSetting } from './NotificationSettingsGroupPage.types';

export const useUserInitialSettings = (
	defaultsSettings: TDefaultNotificationSetting[],
	notificationsSettings: TNotificationSetting[],
) => {
	return useMemo(() => {
		const userSettings = notificationsSettings?.length
			? notificationsSettings.map(({ id, notificationId, channels }) => ({
					id,
					notificationId,
					options: without(channels, NOTIFICATION_SETTING_CHANNEL.chat),
			  }))
			: [];
		return defaultsSettings.map((defaultsSetting, index) => {
			const initialSetting = userSettings.find(
				(settings) => settings.notificationId === defaultsSetting.notificationId,
			) || {
				id: null,
				notificationId: defaultsSettings[index].notificationId,
				options: without(defaultsSettings[index].options, NOTIFICATION_SETTING_CHANNEL.chat),
			};
			return initialSetting;
		});
	}, [defaultsSettings, notificationsSettings]);
};
