import { ROUTES } from '@constants';
import { FullScreenPageLayout, Page } from '../../components';
import RequestNetworkForm from '@ui-modules/networks/components/RequestNetworkForm';
import { useRequestNetworkMutation } from '@ui-modules/networks/hooks/useRequestNetworkMutation';
import { useNavigate, useTranslation } from '@hooks';
import styles from './RequestNetworkPage.module.css';
import { FixedWidthContainer } from '@ui-kit';

const RequestNetworkPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Form handling.
	const { mutateAsync: requestNetwork } = useRequestNetworkMutation({
		onSuccess(networkRequest) {
			navigate(ROUTES.requestNetworkConfirmation(networkRequest.id), { replace: true });
		},
	});

	return (
		<Page title={t('Request a network')}>
			<FullScreenPageLayout headerTitle={t('Request a network')} hideBackButton>
				<div className={styles.requestNetworkPage}>
					<FixedWidthContainer>
						<RequestNetworkForm onSubmit={requestNetwork} />
					</FixedWidthContainer>
				</div>
			</FullScreenPageLayout>
		</Page>
	);
};

export default RequestNetworkPage;
