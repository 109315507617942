import { useEffect, useMe, useService } from '@hooks';
import { extractReactionsFormActivity, produce, set } from '@utils';
import type { FeedAPIResponse } from 'getstream';
import type { TActivity } from '@typings';

/**	Changes on the activity comments/likes reflects on the parent feeds cache. */
export const useSyncActivityReactionsWithParentFeedsEffect = (activity: TActivity | undefined) => {
	const { user } = useMe();
	const reactQuery = useService('ReactQueryService');

	const reactions = extractReactionsFormActivity(activity);

	const syncActivityReactionsWithParentFeeds = (activity: TActivity) => {
		const homeFeedSlug = user.slug;
		const communityFeedSlug =
			activity?.collectionCommunityReference?.data?.slug || activity?.community?.slug || 'LEARNING';
		const updatingFeedSlugs = [homeFeedSlug, communityFeedSlug];

		updatingFeedSlugs.forEach((feedSlug) => {
			reactQuery.queryClient.setQueriesData(
				reactQuery.queryKeys.getUserFeed(feedSlug),
				(cache: FeedAPIResponse | undefined) => {
					if (!cache) return cache;
					return produce(cache, (draft) => {
						draft.results.forEach((entity, index) => {
							if (entity.id === activity.id) {
								set(draft.results[index], ['reaction_counts', 'comment'], reactions.commentsCount);
								set(draft.results[index], ['reaction_counts', 'like'], reactions.likesCount);
								set(draft.results[index], ['latest_reactions', 'like'], reactions.latestLikes);
								set(draft.results[index], ['own_reactions', 'like'], reactions.ownLikes);
							}
						});
					});
				},
			);
		});
	};

	useEffect(
		function syncActivityReactions() {
			if (activity) {
				const callback = () => syncActivityReactionsWithParentFeeds(activity);
				const requestIdleCallbackSupported = typeof window.requestIdleCallback === 'function';
				if (requestIdleCallbackSupported) {
					requestIdleCallback(callback);
				} else {
					setTimeout(callback, 500);
				}
			}
		},
		[activity, reactions.likesCount, reactions.commentsCount],
	);
};
