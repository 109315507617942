import { useMutation, useNotification, useTranslation, useMe, useService, useToggle, useNavigate } from '@hooks';
import { useInitAlgoliaSearchMutation } from '@ui-modules/globalSearch/hooks/useInitAlgoliaSearchMutation';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import type { TCommunity, TMembership, TMembershipRequest } from '@typings';
import type { AxiosError } from 'axios';
import { Modal } from '@ui-kit';
import { CommunityEntity } from '@utils';
import { useCreateNetworkConnectionUserFilterMutation } from '@ui-modules/connections/hooks/useCreateNetworkConnectionUserFilterMutation';
import { useDeleteNetworkConnectionUserFilterMutation } from '@ui-modules/connections/hooks/useDeleteNetworkConnectionUserFilterMutation';

/** Performs changing of the user attendance within a network.
 * 	Includes methods joinNetwork, requestAccess, leaveNetwork.
 *  "isLoading" returns any state changing request loading state.
 */
export const useNetworkMutations = (options?: TUseNetworkMutations) => {
	const { showSuccess, showUnknownError, showInfo } = useNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { user, profile } = useMe();
	const { mutate: reInitAlgoliaSearch } = useInitAlgoliaSearchMutation();
	const [isForbidLeaveNetworkDialogVisible, toggleForbidLeaveNetworkDialogVisible] = useToggle(false);
	const { mutateAsync: initConnectionUserFilter } = useCreateNetworkConnectionUserFilterMutation();
	const { mutateAsync: deleteNetworkConnectionUserFilter } = useDeleteNetworkConnectionUserFilterMutation();

	const mutateConnectionUserFilters = async (network: TCommunity) => {
		const connectionExists = CommunityEntity.networkConnectionExists(network);
		if (connectionExists) {
			await initConnectionUserFilter({
				community: `/api/communities/${network.id}`,
				owner: user['@id'],
				filters: { categories: [] },
			});
		}
	};

	const mergeCommunityQueryData = (communityId: TCommunity['id'], partialCommunity: Partial<TCommunity>) => {
		queryClient.setQueryData<TCommunity | undefined>(queryKeys.getCommunity(communityId), (community) =>
			community ? { ...community, ...partialCommunity } : community,
		);
		queryClient.invalidateQueries(queryKeys.getCommunity(communityId));
		queryClient.invalidateQueries(queryKeys.getCommunityRegularMemberList(communityId));
	};

	const refetchNetworkListsCache = (tab?: string) => {
		if (tab) {
			queryClient.refetchQueries(queryKeys.getMyLifestyleNetworks(tab));
			queryClient.refetchQueries(queryKeys.getForeignLifestyleNetworksList(tab));
		} else {
			queryClient.refetchQueries(queryKeys.getMyInvestNetworks());
			queryClient.refetchQueries(queryKeys.getForeignInvestNetworksList());
		}
	};

	const refetchConnectedQueries = (network: TCommunity) => {
		queryClient.invalidateQueries(queryKeys.getProfileCommunities(profile.id));
		queryClient.invalidateQueries(queryKeys.getAllDealFilterConfig());
		queryClient.invalidateQueries(queryKeys.getAllDeals());
		queryClient.invalidateQueries(queryKeys.getMyDeals());
		queryClient.invalidateQueries(queryKeys.getNetworkDeals(network.id));
		queryClient.refetchQueries(queryKeys.getNetworkConnectionUserFilters(network.id));
		queryClient.refetchQueries(queryKeys.getCommunitiesNotificationSettings());
		queryClient.refetchQueries(queryKeys.getAssetAllocationsRecommendationCollections());
		queryClient.refetchQueries(queryKeys.getAssetAllocationCommunities());
		queryClient.refetchQueries(queryKeys.getMemberRelation());
	};

	const joinNetworkMutation = useMutation<TMembership, Error, TCommunity>(
		queryKeys.createMembership(),
		async (network) => await api.membership.createMembership({ communityId: network.id, userId: user.id }),
		{
			onSuccess: async (addedMembership, network) => {
				await mutateConnectionUserFilters(network);
				mergeCommunityQueryData(network.id, { membership: addedMembership });
				refetchNetworkListsCache(network.tab);
				showInfo({
					title:
						options?.joinSuccessMessage ?? t('You joined the {{networkName}} network', { networkName: network.name }),
				});
				refetchConnectedQueries(network);
				reInitAlgoliaSearch();

				options?.onJoinSuccess?.(network.id);

				analytics.trackEvent('NetworkJoined', {
					network_name: network.name,
					network_type: network.features.includes('invest') ? INVEST_NETWORKS_TAB_SLUG : String(network.tab),
				});
			},
		},
	);

	const requestAccessMutation = useMutation<TMembershipRequest, Error, TCommunity>(
		queryKeys.createMembershipRequest(),
		async (network) =>
			api.membership.createMembershipRequest({
				communityId: network.id,
				userId: user.id,
			}),
		{
			onSuccess: (_, network) => {
				mergeCommunityQueryData(network.id, { membershipRequestStatus: 'new' });
				const networkListQueryKey = network.tab
					? queryKeys.getForeignLifestyleNetworksList(network.tab)
					: queryKeys.getForeignInvestNetworksList();
				queryClient.setQueryData<TCommunity[] | undefined>(networkListQueryKey, (communities) => {
					if (!communities) return communities;
					return communities.map((community) =>
						community.id === network.id ? { ...community, membershipRequestStatus: 'new' } : community,
					);
				});

				showSuccess({
					title:
						options?.requestSuccessMessage ?? t('Your request to access the network has been submitted successfully'),
				});
				options?.onRequestSuccess?.(network.id);
			},
		},
	);

	const leaveNetworkMutation = useMutation<void, AxiosError, TCommunity>(
		queryKeys.removeMembership(),
		async (network) => await api.membership.removeMembership(network.membership?.id as TMembership['id']),
		{
			onSuccess: (_, network) => {
				mergeCommunityQueryData(network.id, { membership: null });
				deleteNetworkConnectionUserFilter(network.id);
				refetchNetworkListsCache(network.tab);
				showSuccess({
					title: t('You have left the network'),
				});
				refetchConnectedQueries(network);
				mutateConnectionUserFilters(network);
				reInitAlgoliaSearch();

				analytics.trackEvent('NetworkLeft', {
					network_name: network.name,
					network_type: network.features.includes('invest') ? INVEST_NETWORKS_TAB_SLUG : String(network.tab),
				});
			},
			onError(error) {
				if (error.response?.status === 403 || error.response?.status === 422) {
					toggleForbidLeaveNetworkDialogVisible();
				} else {
					showUnknownError(error);
				}
			},
		},
	);

	const Modals = (
		<>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('My Deals')}
				subTitle={t(
					'Deals you’ve posted to this Network are still open. To leave the Network please close them first.',
				)}
				title={t('Deals are still open')}
				variant="medium"
				visible={isForbidLeaveNetworkDialogVisible}
				onCancel={() => toggleForbidLeaveNetworkDialogVisible()}
				onConfirm={() => {
					navigate(ROUTES.dealsTabs('my-deals'));
					toggleForbidLeaveNetworkDialogVisible();
				}}
			/>
		</>
	);

	return {
		joinNetwork: joinNetworkMutation.mutate,
		requestAccess: requestAccessMutation.mutate,
		leaveNetwork: leaveNetworkMutation.mutate,
		isLoading: leaveNetworkMutation.isLoading || joinNetworkMutation.isLoading || requestAccessMutation.isLoading,
		isLeavingNetwork: leaveNetworkMutation.isLoading,
		isJoiningNetwork: joinNetworkMutation.isLoading,
		isRequestingAccess: requestAccessMutation.isLoading,
		Modals,
	};
};

export type TUseNetworkMutations = {
	onJoinSuccess?: (networkId: TCommunity['id']) => void;
	onNetworkAddedToMy?: (networkId: TCommunity['id']) => void;
	onRequestSuccess?: (networkId: TCommunity['id']) => void;
	joinSuccessMessage?: string;
	requestSuccessMessage?: string;
};
