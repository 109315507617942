import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TNetworkConnection } from '@typings';

export const useNetworkConnectionQuery = (
	id: string,
	queryOptions?: UseQueryOptions<TNetworkConnection, Error, TNetworkConnection>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TNetworkConnection, Error, TNetworkConnection>(
		reactQuery.queryKeys.getNetworkConnection(id),
		async () => await api.networkConnection.getNetworkConnection(id),
		{
			// staleTime: dayjs.duration(15, 'minutes').asMilliseconds(), // T21C-6808 Need to refech as data is out of date.
			enabled: !!id,
			...queryOptions,
		},
	);
};
