import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TAuthData } from '@typings';

export interface IAuth extends Partial<TAuthData> {}

const initialState: IAuth = {
	accessToken: undefined,
	refreshToken: undefined,
	expiresAt: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		saveTokensResponse: (state, { payload: tokenResponse }: PayloadAction<TAuthData>) => {
			state.accessToken = tokenResponse.accessToken;
			state.refreshToken = tokenResponse.refreshToken;
			state.expiresAt = tokenResponse.expiresAt;
		},
		resetTokensResponse: (state) => {
			state.accessToken = undefined;
			state.refreshToken = undefined;
			state.expiresAt = undefined;
		},
	},
});
