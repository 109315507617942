import { useMutation, useQueryClient, useNotification, useService } from '@hooks';
import type { TNotificationSetting } from '@typings';

export const useUpdateNotificationSettings = (groupName?: string) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const queryClient = useQueryClient();
	const { showUnknownError } = useNotification();
	return useMutation<
		string | undefined | void,
		Error,
		{ id: string; notificationSetting: Partial<TNotificationSetting> }
	>(
		['notifications.patchNotificationSetting'],
		async ({ id, notificationSetting }) => {
			return await api.notificationSettings.patchNotificationsSettings(id, notificationSetting);
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(
					groupName === 'Networks'
						? reactQuery.queryKeys.getCommunitiesNotificationSettings()
						: ['notifications.getSettings'],
				);
				await queryClient.invalidateQueries(reactQuery.queryKeys.getNotificationSettings());
				await queryClient.invalidateQueries(reactQuery.queryKeys.getDefaultNotificationSettings());
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
