import { Attachment } from 'stream-chat-react';
import { ImageGridGallery, FileDownloadCard } from '@ui-kit';
import type { Attachment as TAttachment } from 'stream-chat';
import { useMemo } from '@hooks';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import ChatPreviewUrl from '../ChatPreviewUrl';
import { STREAM_CHAT_BASE_URL } from '@constants';
import { uniqBy, sortBySequence, extractFileExtensionFromUrl } from '@utils';
import { parseChatMessageUrls } from '@ui-modules/chat/utils/parseChatMessageUrls';

const ChatAttachment = ({ attachments, text = '' }: IChatAttachmentProps) => {
	const pdfViewer = usePdfViewer();
	const openUrl = (url: string) => window.open(url, 'blank');

	const images = useMemo(() => {
		return attachments
			?.filter((item) => item.type === 'image' && item.image_url?.includes(STREAM_CHAT_BASE_URL))
			.map((item) => {
				return `${item.image_url}`;
			});
	}, [attachments]);

	const limitedAttachments = useMemo(() => {
		const otherAttachments = attachments.filter((item) => !item.og_scrape_url);

		const onlyUrlAttachments = attachments.filter((item) => item.og_scrape_url);
		const links = parseChatMessageUrls(text);
		const sortedUrls = sortBySequence(onlyUrlAttachments, 'og_scrape_url', links); // we reduce and sort links before editing but Stream can add url preview images it self and we have to sort the again to ensure [@dmitriy.nikolenko].
		const urlAttachments = uniqBy(sortedUrls, 'og_scrape_url').slice(0, 3);

		return [...otherAttachments, ...urlAttachments];
	}, [attachments, text]);

	return (
		<div>
			{attachments && (
				<Attachment
					attachments={limitedAttachments}
					Card={() => {
						return null; // T21C-5973 Hide native link preview and show <ChatLinksPreview /> instead;
					}}
					File={(file: TChatFile) => {
						const fileUrl = String(file?.attachment?.asset_url);
						return (
							<FileDownloadCard
								file={file}
								key={file?.attachment?.asset_url}
								onClick={() => {
									const fileExtension = extractFileExtensionFromUrl(fileUrl);
									if (fileExtension === 'pdf') pdfViewer.open(fileUrl);
									else openUrl(fileUrl);
								}}
							/>
						);
					}}
					/* handles files in chat*/
					Gallery={() => {
						/* handles multiple image in chat*/
						return <>{<ImageGridGallery images={images} />}</>;
					}}
					Image={() => {
						/* handles single images in chat*/
						return <>{<ImageGridGallery images={images} />}</>;
					}}
				/>
			)}
		</div>
	);
};

export default ChatAttachment;

export interface IChatAttachmentProps {
	/** Target Community ID. */
	attachments: TAttachment[];
	text?: string | undefined;
}
export type TChatAttachment = {
	asset_url?: string;
	thumb_url?: string;
	file_size?: string | number | undefined;
	mime_type?: string;
	title?: string;
};
export type TChatFile = {
	attachment?: TChatAttachment;
};
