import { Button } from '@ui-kit';
import { PieChartFilledIcon } from '@ui-kit/icons';

import AssetAllocationEmptyState from '../AssetAllocationEmptyState';
import { useTranslation } from '@hooks';

const AssetAllocationStaticEmptyState = ({ onOpenUserAllocation }: IAssetAllocationStaticEmptyStateProps) => {
	const { t } = useTranslation();

	return (
		<AssetAllocationEmptyState
			description={t('To begin using Asset Allocation, please first enter your current allocation.')}
			IconComponent={PieChartFilledIcon}
			title={t('Enter Your Data')}
		>
			<Button title={t('Enter Asset Allocation')} type="dark" variant="medium" onClick={onOpenUserAllocation} />
		</AssetAllocationEmptyState>
	);
};

export interface IAssetAllocationStaticEmptyStateProps {
	onOpenUserAllocation: () => void;
}

export default AssetAllocationStaticEmptyState;
