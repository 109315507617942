import axios from 'axios';
import { MICROSITE_API } from '@constants';
import { keyBy } from 'lodash';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { IMixPanelAnalyticsService } from '../interfaces/AnalyticsService.MixPanel.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { TMicrositeWebCarouselImageResponse, TNominationSectionResponse, TNominationSections } from '@typings';
import type { IEnvironmentConfig } from '../interfaces/AppConfig.interface';

export class MicrositeApiService {
	static inject = ['AppConfigService', 'logger', 'SentryService', 'AnalyticsService'];
	constructor(
		appConfig: IEnvironmentConfig,
		logger: IDevLogger,
		private readonly bugTracker: IBugTracker,
		private readonly analytics: IMixPanelAnalyticsService,
	) {
		this.logger = logger.child('MicrositeApiService');

		this.#axiosClient = axios.create({
			baseURL: MICROSITE_API.baseUrl,
			auth: {
				username: MICROSITE_API.username,
				password: MICROSITE_API.password,
			},
			responseEncoding: 'utf8',
		});
		this.#axiosClient.interceptors.response.use(
			(response) => {
				this.onRequestFinished(response.config, response);
				return this.transformResponse(response);
			},
			(error) => {
				// If we doesn't wrap error handling in setTimeout, react-query's hooks does not obtain AxiosError but CancelledError.
				setTimeout(() => {
					this.onError(error);
				}, 0);

				return Promise.reject(error);
			},
		);
	}

	private logger: IDevLogger;
	#axiosClient: AxiosInstance;

	// Configuration.

	protected transformResponse(response: AxiosResponse<unknown, unknown>) {
		return response.data;
	}
	protected onError(error: AxiosError): void {
		this.bugTracker.captureException(error);
		this.logger.error(error);

		const request = error?.response?.config as AxiosRequestConfig | undefined;
		const response = error?.response as AxiosResponse;
		this.analytics.trackEvent('http_request_failed', {
			'#url': String(request?.url),
			'#method': String(request?.method),
			'#responseCode': response?.status ?? 0,
			'#accessToken': request?.headers?.Authorization?.toString?.(),
			'#responseData': response?.data,
		});
	}

	protected onRequestFinished(request: AxiosRequestConfig, response?: AxiosResponse): void {
		this.logger.debug('request finished', request?.url, response?.status);

		this.analytics.trackEvent('http_request_completed', {
			'#url': String(request?.url),
			'#method': String(request?.method),
			'#responseCode': response?.status ?? 0,
		});
	}

	// Requests.

	async getWebGalleryImages() {
		return await this.#axiosClient.get<unknown, TMicrositeWebCarouselImageResponse[]>(
			'/appcarousel?status=publish&per_page=20',
		);
	}

	async getNominationSections(): Promise<TNominationSections> {
		const response = await this.#axiosClient.get<unknown, TNominationSectionResponse[]>('/nominationsection');
		const sections = keyBy(response, 'slug');
		return sections;
	}
}
