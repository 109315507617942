import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDeal, TDealPreviewStatus } from '@typings';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetDealPreviewStatusQuery = (dealId: TDeal['id'], options?: UseQueryOptions<TDeal, AxiosError>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TDealPreviewStatus, AxiosError>(
		reactQuery.queryKeys.getDealPreviewStatus(dealId),
		async () => await api.invest.getDealPreviewStatus(dealId),
		{
			enabled: options?.enabled,
		},
	);
};
