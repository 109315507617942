import { ArrowRightIcon, RoundedRemoveIcon, EntityInformationCard, SideActionButton } from '@ui-kit';

import { ROUTES } from '@constants';
import { NetworkConnectionEntity } from '@utils';
import { useTranslation, useNavigate, useService } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useNetworkConnectionBookmarkMutation } from '@ui-modules/connections/hooks/useNetworkConnectionBookmarkMutation';

import styles from './BookmarkItem.module.css';

import type { TNetworkConnection, TTNetworkConnectionBookmark } from '@typings';

const BookmarkItem = ({ bookmark, communityId, setInitialTopMostItemIndex, bookmarks }: IBookmarkItemProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const networkConnectionId = bookmark.id;
	const analytics = useService('AnalyticsService');

	const { data: networkConnection } = useNetworkConnectionQuery(networkConnectionId);
	const { data: network } = useCommunityQuery(communityId);

	const onBookmarkClick = () => {
		const clickedBookmarkIndex = bookmarks?.indexOf(bookmark) || 0;
		setInitialTopMostItemIndex(clickedBookmarkIndex);
		navigate(ROUTES.networkConnection(bookmark.id, 'details'), {
			state: { restoreScrollRoute: '' },
		});
	};

	const { mutate: toggle, isLoading: isMutating } = useNetworkConnectionBookmarkMutation(
		networkConnectionId,
		communityId,
		{
			onSuccess: (response, isBookmarked) => {
				analytics.trackGenericEvent(
					'{{connectionName}}Interactions',
					{
						interaction_type: 'bookmark',
						interaction_action: !isBookmarked ? 'added' : 'removed',
						'{{connectionName}}_id': bookmark.id,
						'{{connectionName}}_name': bookmark.title,
					},
					{
						connectionName: network?.name || '',
					},
				);
			},
		},
	);

	return (
		<div className={styles.cardWrapper} key={bookmark.id}>
			<EntityInformationCard
				avatarUrl={bookmark.owner.avatarUrl}
				className={styles.card}
				communitiesForBadge={bookmark.owner.communitiesForBadge?.shortNames}
				createdAt={bookmark.createdAt}
				creatorName={bookmark.owner.fullName}
				key={bookmark.id}
				marginMode={false}
				profileLink={ROUTES.memberProfile(bookmark.owner.profileId)}
				radiusMode={false}
				shadowMode={false}
				subtitleAdditionalInfo={NetworkConnectionEntity.getParentCategoryNamesString(
					bookmark as unknown as TNetworkConnection,
				)}
				subtitleLinkDisabled
				title={bookmark.title}
				withArrow={false}
				onClick={onBookmarkClick}
				trailingActions={() => (
					<div className={styles.listCard__trailingActionsWrapper}>
						<SideActionButton
							Icon={<RoundedRemoveIcon height={20} width={15} />}
							loading={isMutating}
							position="trailing"
							title={t('Remove Bookmark')}
							onClick={() => toggle(networkConnection?.isBookmarked)}
						/>
						<SideActionButton
							Icon={<ArrowRightIcon height={20} width={18} />}
							position="trailing"
							title={t('View')}
							onClick={onBookmarkClick}
						/>
					</div>
				)}
			/>
		</div>
	);
};

export interface IBookmarkItemProps {
	bookmark: TTNetworkConnectionBookmark;
	communityId: string;
	setInitialTopMostItemIndex: (index: number) => void;
	bookmarks: TTNetworkConnectionBookmark[];
}

export default BookmarkItem;
