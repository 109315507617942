import { Suspense } from 'react';
import CheckAuthErrorBoundary from './CheckAuthErrorBoundary';
import CheckAuthWrapper from './CheckAuthWrapper';
import type { PropsWithChildren, ReactNode } from 'react';

/** Wrapper to prevent access to routes without having authorization.
 *  On mount tries to refresh token (if exists). If it fails, redirects to the sign-in page.
 *  Should be wrapper to Suspense And ErrorBoundary.
 */
const SecureRoutesWrapper = ({ children, signingInFallback, loadingFallback }: ISecureRoutesWrapperProps) => (
	<CheckAuthErrorBoundary>
		<Suspense fallback={signingInFallback}>
			<CheckAuthWrapper>
				<Suspense fallback={loadingFallback}>{children}</Suspense>
			</CheckAuthWrapper>
		</Suspense>
	</CheckAuthErrorBoundary>
);

export interface ISecureRoutesWrapperProps extends PropsWithChildren {
	signingInFallback?: ReactNode;
	loadingFallback?: ReactNode;
}

export default SecureRoutesWrapper;
