import { PostCommentIcon } from '@ui-kit/icons';
import type { MouseEventHandler, KeyboardEventHandler } from 'react';
import styles from './CommentsButton.module.css';

/** Button with counter of comments.  */
const CommentsButton = ({ comments, onClickComment }: ICommentsButtonProps) => {
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onClickComment && onClickComment();
	};

	return (
		<div className={styles.commentsButtonWrapper}>
			<button className={styles.commentsButton} onClick={handleClick}>
				<PostCommentIcon className={styles.commentsButton__icon} height={14} width={14} />
				<span>{comments}</span>
			</button>
		</div>
	);
};

export interface ICommentsButtonProps {
	/** Number of comments. */
	comments: number;
	/** Calls when button pressed. */
	onClickComment?: () => void;
}

export default CommentsButton;
