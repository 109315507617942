import { useService } from '@services';
import { useQuery } from '@hooks';
import { useDealsEnabled } from './useDealsEnabled';

export const useGetSubClassesWithIdsQuery = (subClassIds: string[]) => {
	const { enabled } = useDealsEnabled();
	const api = useService('ApiService');
	const { data, isLoading, refetch, isFetched } = useQuery(
		['getSubClassesWithIdsQuery', subClassIds],
		async () => await Promise.all(subClassIds.map((item) => api.dealSubClass.getSubClass(item))),
		{
			enabled,
		},
	);

	return { data, isLoading, refetch, isFetched };
};
