import CollapsibleText from '../CollapsibleText';
import { useRef } from '@hooks';
import styles from './CollapsibleTextSection.module.css';
import { makeHtmlContent } from '@utils';
import type { ICollapsibleTextProps } from '../CollapsibleText';

const CollapsibleTextSection = ({
	text,
	title,
	collapsedTitle,
	expandedTitle,
	textHighlighted,
}: ICollapsibleTextSectionProps) => {
	const collapseRef = useRef<HTMLSpanElement>(null);

	return (
		<div className={styles.collapsibleTextSection}>
			<span className={styles.collapsibleTextSection__title} ref={collapseRef}>
				{title}
			</span>
			<CollapsibleText
				collapsedNumberOfLines={3}
				collapsedTitle={collapsedTitle}
				expandedTitle={expandedTitle}
				textHighlighted={textHighlighted}
				onCollapse={() => collapseRef?.current?.scrollIntoView({ behavior: 'smooth' })}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: makeHtmlContent(text),
					}}
				/>
			</CollapsibleText>
		</div>
	);
};

export interface ICollapsibleTextSectionProps
	extends Pick<ICollapsibleTextProps, 'collapsedTitle' | 'expandedTitle' | 'textHighlighted'> {
	text: string;
	title: string;
}

export default CollapsibleTextSection;
