import { useCallback, useMe, useService, useNavigate } from '@hooks';
import { useOpenNotification } from '../../hooks/useOpenNotification';
import type { LoadMorePaginatorProps, NewActivitiesNotificationProps, NotificationProps } from 'react-activity-feed';
import { NotificationFeed } from 'react-activity-feed';
import NotificationsListItem from '../NotificationListItem';
import { NotificationListPaginator } from './NotificationListPaginator';
import type { INotificationFeedActivityGroup, INotificationFeedActivity } from '@typings';
import styles from './NotificationsList.module.css';
import NotificationListEmptyState from './NotificationListEmptyState';
import type { RefObject, LegacyRef, FC } from 'react';

const NotificationsList = ({ onNotificationClick, visible, Notifier, innerRef }: INotificationsListProps) => {
	const feed = useService('FeedService');
	const analytics = useService('AnalyticsService');
	const { user } = useMe();
	const navigate = useNavigate();
	const openNotification = useOpenNotification();

	const onClickNotification = useCallback(
		(notification: INotificationFeedActivity) => {
			openNotification(notification.data, onNotificationClick);
			analytics.trackEvent('NotificationInteractions', {
				action_taken: 'notification message clicked',
				notification_id: notification.id,
				notification_source: notification.actor?.data?.userId,
				notification_content: notification.data.formattedContent,
			});
		},
		[navigate, onNotificationClick],
	);

	// Render callbacks (memoizing prevents re-mounting/reloading every time)
	const renderGroup = useCallback(({ activityGroup }: NotificationProps) => {
		return (
			<NotificationsListItem
				activityGroup={activityGroup as unknown as INotificationFeedActivityGroup}
				onClick={onClickNotification}
			/>
		);
	}, []);
	const renderPaginator = useCallback((props: LoadMorePaginatorProps) => <NotificationListPaginator {...props} />, []);

	return (
		<div className={styles.notificationsList} ref={innerRef as LegacyRef<HTMLDivElement>}>
			<NotificationFeed
				feedGroup={feed.notificationFeedGroup}
				Group={renderGroup}
				Notifier={Notifier ?? null}
				notify
				options={OPTIONS}
				Paginator={renderPaginator}
				Placeholder={NotificationListEmptyState}
				userId={user.slug}
			/>
		</div>
	);
};

const OPTIONS = { limit: 15 };

interface INotificationsListProps {
	onNotificationClick: () => void;
	visible: boolean;
	innerRef: RefObject<HTMLElement>;
	Notifier?: FC<NewActivitiesNotificationProps>;
}

export default NotificationsList;
