import { useMutation, useService } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import type { TActivity } from '@typings';
import type { Activity } from 'getstream';

/** Executes toggle of your like on the certain activity.
 *  The hook should be placed within FeedContext!
 */
export const useToggleActivityLikeMutation = () => {
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { onToggleReaction } = useFeedContext();
	const updateActivityInFeedCache = useUpdateActivityInFeedCache();

	return useMutation(async (activity: TActivity) => onToggleReaction('like', activity as any as Activity), {
		onSuccess(data, activity) {
			algoliaAnalytics.trackEvent('User Interacted', { discussionOwnerId: activity.actor.data.userId });
			algoliaAnalytics.trackEvent('Discussion Liked', { discussionId: activity.foreign_id });
			updateActivityInFeedCache(activity.id);
		},
	});
};
