import { useService, useQuery } from '@hooks';
import type { TEvent } from '@typings';

export const useGetEventQuery = (
	eventId: TEvent['id'] | undefined,
	callback?: (data: TEvent | undefined, error: Error | null) => void,
) => {
	const api = useService('ApiService');

	return useQuery<TEvent, Error>(
		['calendarItem.getCalendarItem', eventId],
		async () => await api.calendarItem.getCalendarItem(eventId as TEvent['id']),
		{
			enabled: !!eventId,
			onSettled: callback,
		},
	);
};
