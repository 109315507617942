import { useQuery, useService } from '@hooks';
import { dealTemplateSectionSlug } from '@typings';
import type { TDealTemplate, TDealTemplateSection } from '@typings';

export type TDealTemplateQueryResult = {
	dealDetails?: TDealTemplateSection;
	financialDetails?: TDealTemplateSection;
} | null;

export const useGetDealTemplateQuery = (
	onSuccess: (data: TDealTemplateQueryResult) => void,
	dealAssetClassId?: string,
	dealSubClassId?: string,
	dealTypeId?: string,
) => {
	const isEnabled = !!(dealAssetClassId && dealSubClassId && dealTypeId);
	const api = useService('ApiService');
	return useQuery<TDealTemplateQueryResult, Error>(
		['dealTemplate.getTemplate', dealAssetClassId, dealSubClassId, dealTypeId],
		async () => {
			if (isEnabled) {
				const response: TDealTemplate = await api.dealTemplate.getTemplate(
					dealAssetClassId,
					dealSubClassId,
					dealTypeId,
				);
				return {
					dealDetails: response.template.sections.find(
						({ section_slug }) => section_slug === dealTemplateSectionSlug.DEAL_DETAILS,
					),
					financialDetails: response.template.sections.find(
						({ section_slug }) => section_slug === dealTemplateSectionSlug.FINANCIAL_DETAILS,
					),
				};
			}
			return null;
		},
		{ onSuccess, enabled: isEnabled },
	);
};
