import { IdxStatus } from '@okta/okta-auth-js';
import { SUPPORT_LINK } from '@constants';
import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';
import type { TOktaVerificationFlow } from '../../../interfaces/OktaService.interface';

export class OktaInvalidPassCodeError extends OktaError {
	constructor(private readonly flow: TOktaVerificationFlow | undefined) {
		super();
	}

	status = 'INVALID_PASSCODE';
	getMessage(t: TFunction) {
		return {
			title: t('Invalid passcode'),
			subtitle:
				this.flow === 'reset-password'
					? t(
							// FIXME Have no idea why this key is considered to be missing in the i18n file
							// eslint-disable-next-line react-i18n/no-missing-interpolation-keys
							"The number you entered doesn't match your code. Please try again. Email {{supportEmail}} for help signing in.",
							{
								supportEmail: SUPPORT_LINK,
							},
					  )
					: t('Please try again. You can also reset your password, or email {{supportEmail}} for help signing in.', {
							supportEmail: SUPPORT_LINK,
					  }),
		};
	}

	static shouldThrow(transaction: IdxTransaction) {
		return (
			transaction.status === IdxStatus.PENDING &&
			transaction.nextStep?.name === 'challenge-authenticator' &&
			transaction.messages?.[0].class === 'ERROR'
		);
	}
}
