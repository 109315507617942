import type { ReactNode } from 'react';
import styles from './ContactCard.module.css';

const ContactCard = ({ title, children, secondaryTitle, actionTitle, actionDisabled, onAction }: IContactCardProps) => {
	return (
		<div className={styles.card}>
			<div className={styles.card__header}>
				<span className={styles.card__title}>{title}</span>
				{secondaryTitle ? <span className={styles.card__subTitle}>{secondaryTitle}</span> : null}
				{actionTitle ? (
					<button className={styles.card__button} disabled={actionDisabled} onClick={onAction}>
						<span className={styles.card__buttonText}>{actionTitle}</span>
					</button>
				) : null}
			</div>
			<div>{children}</div>
		</div>
	);
};

export interface IContactCardProps {
	title: string;
	children: ReactNode;
	secondaryTitle?: string;
	actionTitle?: string;
	onAction?: () => void;
	actionDisabled?: boolean;
}

export default ContactCard;
