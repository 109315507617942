import { memo } from 'react';
import { Avatar } from '@ui-kit';
import { CancelButtonIcon } from '@ui-kit/icons';
import styles from './UsersSelectionBar.module.css';
import type { TUserProfileRecord } from '@typings';

/** Displays horizontal scrollable bar with user avatar widgets. */
const UsersSelectionBar = ({ selectedProfiles, onRemove }: IUsersSelectionBarProps) => {
	const hidden = !selectedProfiles.length;

	return hidden ? null : (
		<div className={styles.usersSelectionBar__scrollWrapper}>
			<div className={styles.usersSelectionBar}>
				{selectedProfiles.map((userProfileRecord) => {
					const userName = userProfileRecord.firstName + ' ' + userProfileRecord.lastName;
					const isChair = userProfileRecord.profile['@type'] === 'ProfileChair';

					return (
						<div className={styles.usersSelectionBar__item} key={userProfileRecord.id}>
							<div>
								<Avatar imageUri={userProfileRecord.avatar?.contentUrl} outline={isChair} size={40} title={userName} />
								<button className={styles.usersSelectionBar__removeButton} onClick={() => onRemove(userProfileRecord)}>
									<CancelButtonIcon height={12} width={12} />
								</button>
							</div>
							<div className={styles.usersSelectionBar__userNameBox}>
								<span className={styles.usersSelectionBar__userName}>{userName}</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export interface IUsersSelectionBarProps {
	/** Array of profiles to render (see TUserProfileRecord interface). */
	selectedProfiles: TUserProfileRecord[];
	/** Called when the remove button of an item clicked. */
	onRemove: (userProfileRecord: TUserProfileRecord) => void;
}

export default memo(UsersSelectionBar);
