import { Modal } from '@ui-kit';
import { useTranslation, useService, useMe, useNotification, useNavigate, useChat } from '@hooks';
import { noop } from '@utils';
import { ROUTES } from '@constants';
import type { IModalProps } from '@ui-kit';
import type { Channel } from 'stream-chat';

/** Confirms whether user wants to leave a chat. */
const LeaveChatModal = ({ visible, channel, onClose, onSuccess = noop }: ILeaveChatModalProps) => {
	const { t } = useTranslation();
	const chat = useService('ChatService');
	const { user, fullName } = useMe();
	const navigate = useNavigate();
	const { showSuccess } = useNotification();
	const isGroup = chat.isGroupChat(channel);

	const { setShouldUpdateChannel } = useChat();

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Leave Chat')}
			subTitle={t('Are you sure you want to leave this chat?')}
			title={t('Leave Chat')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				chat
					.leaveChannel(user.slug, channel, isGroup, fullName)
					.then(() => {
						setShouldUpdateChannel(false);
						navigate(ROUTES.chat(), { replace: true });
					})
					.then(() => {
						onSuccess();
						showSuccess({
							title: t('You have left the chat'),
							subtitle: t('Sorry to see you go!'),
						});
					});
			}}
		/>
	);
};

export interface ILeaveChatModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Activity to delete. */
	channel: Channel | null | undefined;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default LeaveChatModal;
