import { AppLogoImage } from '@ui-kit/icons';
import { useTranslation } from '@hooks';
import styles from './AppActivityIndicator.module.css';

/** Fullscreen loader which can be used as a spinner during authentication process. */
const AppActivityIndicator = ({ title }: IAppActivityIndicatorProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.appActivityIndicator__wrapper}>
			<div aria-busy="true" className={styles.appActivityIndicator} role="progressbar">
				<AppLogoImage height={80} width={80} />
				<div className={styles.appActivityIndicator__header}>
					<span className={styles.appActivityIndicator__title}>{title ?? t('Signing in')}</span>
				</div>
				<div className={styles.appActivityIndicator__dotFlashing}></div>
			</div>
		</div>
	);
};

interface IAppActivityIndicatorProps {
	/** 'Signing in' by default */
	title?: string;
}

export default AppActivityIndicator;
