import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutateOptions } from '@tanstack/react-query';
import type { TPinnedBanner } from '@typings';

export const useDeletePinnedBannerMutation = (options?: MutateOptions<unknown, Error, TPinnedBanner>) => {
	const feedService = useService('FeedService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<unknown, Error, TPinnedBanner>(
		['feedService.removePinnedBanner'],
		async (pinnedBanner) => {
			const bannerId = pinnedBanner.id;
			return await feedService.removePinnedBanner(bannerId);
		},
		{
			async onSuccess(data, variables, context) {
				showSuccess({ title: t('The pinned banner was deleted') });
				await queryClient.refetchQueries(queryKeys.getPinnedBanners());
				options?.onSuccess?.(data, variables, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
