import { useService, useQuery, useMemo, useMe } from '@hooks';

export const useGetUserGroupsModeratorsQuery = () => {
	const api = useService('ApiService');
	const { user } = useMe();
	const isManageRole =
		user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_STAFF') || user.roles.includes('ROLE_CHAIR');
	const isMemberUser = user.roles.includes('ROLE_MEMBER') && !isManageRole;
	const { data: moderators = [], isLoading: isModeratorsLoading } = useQuery(
		['community.moderators'],
		async () => await api.community.getCommunitiesModerators(),
	);

	const { data: memberAdvisor, isFetching: isMemberAdvisorLoading } = useQuery(
		['users.memberAdvisor'],
		async () => await api.user.getUserMemberAdvisor(),
		{
			enabled: isMemberUser,
		},
	);

	const staffProfiles = useMemo(() => {
		const filteredStaffUsers = moderators.filter((el) => el.id !== memberAdvisor?.profileId);
		const staffUsers = filteredStaffUsers
			?.sort((a, b) => (a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0))
			.sort((a, b) => {
				return a?.roles.includes('ROLE_CHAIR') === b?.roles.includes('ROLE_CHAIR')
					? 0
					: a?.roles.includes('ROLE_CHAIR')
					? -1
					: 1;
			});
		return staffUsers;
	}, [moderators, memberAdvisor]);

	return {
		staffProfiles,
		isLoading: isModeratorsLoading || isMemberAdvisorLoading,
		memberAdvisor,
		memberAdvisorHidden: !isMemberUser,
	};
};
