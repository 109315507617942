import { Post } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { CommentEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TComment } from '@typings';

/** The minimum post card to show within other UI features. */
const FeedCommentPreview = ({ comment }: IFeedCommentPreviewProps) => {
	const { t } = useTranslation();

	const commentEntity = new CommentEntity(comment);
	const profileLink = commentEntity.isInactive ? undefined : ROUTES.memberProfile(commentEntity.profileId);
	const openLink = useHandleActivityLink();

	return (
		<Post
			headerElement={
				<Post.Header
					avatarProps={{
						imageUri: commentEntity.avatarUri,
						outline: false,
						size: 40,
					}}
					profileLink={profileLink}
					userId={comment.user?.data?.userId}
					userName={commentEntity.getDisplayingUserName(t)}
				/>
			}
			isCard
		>
			{commentEntity.content ? (
				<Post.Content collapsedTitle={t('Show more')} expandedTitle={t('Show less')} onClickLink={openLink}>
					{commentEntity.htmlContent}
				</Post.Content>
			) : null}
		</Post>
	);
};

export interface IFeedCommentPreviewProps {
	comment: TComment;
}

export default FeedCommentPreview;
