import styles from './Backdrop.module.css';
import { clsx } from '@utils';

/** Covers a container with animated black blurred foreground. */
const Backdrop = ({ hidden = false }: IBackdropProps) => {
	return <div className={clsx(styles.backdrop, !hidden && styles.backdrop_active)} data-testid="Backdrop" />;
};

export interface IBackdropProps {
	/** Should the component be invisible. Default false. */
	hidden?: boolean;
}

export default Backdrop;
