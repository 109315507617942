import type { TDealFilter, TDealSubClass, TDealType } from '@typings';

import { useGetDealTypesWithIdsQuery } from '@ui-modules/deals/hooks/useGetDealTypesWithIdsQuery';
import { useGetSubClassesWithIdsQuery } from '@ui-modules/deals/hooks/useGetSubClassesWithIdsQuery';
import { useMemo } from 'react';
import useAllDealFilterConfig from '@ui-modules/deals/hooks/useAllDealFilterConfigQuery';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useGetDealTypesQuery } from '@ui-modules/deals/hooks/useGetDealTypesQuery';
import { map } from '@utils';
import { getNetworkDealsFilterTitle } from '../utils/getNetworkDealsFilterTitle';

export const useDealFilterName = ({ networkId, exactDealFilter }: IUseDealFilterName) => {
	const { enabled } = useDealsEnabled();
	const { data: allDealConfigs } = useAllDealFilterConfig(enabled);

	const appliedSubClasses = exactDealFilter?.dealSubClasses;
	const appliedDealTypes = exactDealFilter?.dealTypes;

	const { data: dealTypes, isFetched: isDealTypesFetched } = useGetDealTypesWithIdsQuery(
		appliedDealTypes?.map((item) => item.replace('/api/deal_types/', '')) as string[],
	);

	const { data: dealSubClasses, isFetched: isDealSubClassesFetched } = useGetSubClassesWithIdsQuery(
		appliedSubClasses?.map((item) => item.replace('/api/deal_sub_classes/', '')) as string[],
	);

	const myAssetClass = useMemo(() => {
		return allDealConfigs?.assetClasses?.find((item) => item.community.includes(networkId));
	}, [allDealConfigs?.assetClasses, networkId]);

	//
	const activeSubClasses = useMemo(() => {
		return !exactDealFilter?.dealSubClasses?.length
			? (myAssetClass?.dealSubClasses as TDealSubClass[])
			: (dealSubClasses as TDealSubClass[]);
	}, [exactDealFilter, dealSubClasses, myAssetClass?.dealSubClasses]);

	const { data: activeSubClassDealTypes, isFetched: isActiveSubClassDealTypesFetched } = useGetDealTypesQuery(
		map(activeSubClasses, (item) => item?.id),
	);

	const readyToRender = isDealTypesFetched && isDealSubClassesFetched && isActiveSubClassDealTypesFetched;

	const filterTitle = readyToRender
		? getNetworkDealsFilterTitle(
				dealTypes?.length ? dealTypes : (activeSubClassDealTypes as TDealType[]),
				activeSubClasses as TDealSubClass[],
		  )
		: '';

	return { filterTitle, readyToRender };
};

export interface IUseDealFilterName {
	networkId: string;
	exactDealFilter: TDealFilter;
}
