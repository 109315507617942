import { MobileScreenWrapper } from './components/MobileScreenWrapper';
import { SmallScreenOverlay } from './components/SmallScreenOverlay';
import { useEffect, useState } from '@hooks';
import { StateInspector } from 'reinspect';
import { ServicesContainer } from '../services/setup/ServicesContainer';
import AppContextsProvider from './AppContextsProvider';
import { appRouter } from './appRouter';
import './styles';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
//For React-PDF to work, PDF.js worker needs to be provided. https://www.npmjs.com/package/react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

function App() {
	const [servicesContainer] = useState(() => new ServicesContainer());
	useEffect(() => {
		servicesContainer.resolve('SentryService').initialize();
	}, []);

	return (
		<AppContextsProvider servicesContainer={servicesContainer}>
			<MobileScreenWrapper>
				<StateInspector name="App">
					<RouterProvider router={appRouter} />
				</StateInspector>
				<SmallScreenOverlay />
			</MobileScreenWrapper>
			<ReactQueryDevtools initialIsOpen={false} />
		</AppContextsProvider>
	);
}

export default App;
