import { useContext } from '@hooks';
import { UikitContext } from '@ui-kit/contexts/UikitContext';
import type { AnchorHTMLAttributes, DetailedHTMLProps, KeyboardEventHandler, MouseEventHandler } from 'react';
import type { TMixpanelEvent } from '@typings';

const Anchor = ({ trackingName, onClick, onKeyPress, children, ...anchorProps }: IAnchorProps) => {
	const { trackClickEvent } = useContext(UikitContext);

	const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
		if (trackingName) {
			trackClickEvent(trackingName);
		}
		onClick?.(event);
	};

	const handlePress: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
		if (trackingName) {
			trackClickEvent(trackingName);
		}
		onKeyPress?.(event);
	};

	return (
		<a {...anchorProps} onClick={handleClick} onKeyPress={handlePress}>
			{children}
		</a>
	);
};

export interface IAnchorProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
	trackingName?: TMixpanelEvent;
}

export default Anchor;
