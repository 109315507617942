import styles from './CollapsibleText.module.css';
import type { MouseEventHandler, KeyboardEventHandler } from 'react';

/** Text block which is collapsed to collapsedNumberOfLines and can be expanded via link-button. */
const CollapsibleTextButton = ({ onToggle, title }: ICollapsibleTextProps) => {
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onToggle();
	};

	return (
		<span
			className={styles.collapsibleText__button}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			{title}
		</span>
	);
};

export interface ICollapsibleTextProps {
	/** Text content. */
	title: string;
	/** Action callback. */
	onToggle: () => void;
}

export default CollapsibleTextButton;
