import { combineRegexps } from '@tiger21-llc/connect-shared/src/utils/combineRegexps';
import { parseEmailRegexp, parseSingleEmailRegexp } from '@tiger21-llc/connect-shared/src/utils/parseEmailRegexp';
import { parseUrlRegexp } from '@tiger21-llc/connect-shared/src/utils/parseUrlRegexp';
import { protocolifyUrl } from '@tiger21-llc/connect-shared/src/utils/protocolifyUrl';
import { toString } from 'lodash';

export const makeHtmlContent = (content: string | undefined, mentions: string[] = []) => {
	// Dynamic create pattern to check mentioned users.
	const mentionsPattern = mentions.length ? new RegExp(`(${mentions.join('|')})`, 'g') : undefined;

	return toString(content).replaceAll(
		combineRegexps(
			mentionsPattern ? [parseEmailRegexp, parseUrlRegexp, mentionsPattern] : [parseEmailRegexp, parseUrlRegexp],
		),
		(urlOrMention: string) => {
			if (mentionsPattern && mentions.includes(urlOrMention)) {
				return `<span class="str-chat__message_mention">${urlOrMention}</span>`;
			} else {
				const isEmail = parseSingleEmailRegexp.test(urlOrMention);
				const finalUrl = isEmail ? `mailto:${urlOrMention}` : protocolifyUrl(urlOrMention);
				return `<a href="${finalUrl}" class="str-chat__message_link" target="_blank" onclick="event.stopPropagation()">${urlOrMention}</a>`;
			}
		},
	);
};
