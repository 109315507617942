import { memo } from 'react';
import { FieldArray, Formik, Button, Form, FractionInput } from '@ui-kit';
import { TickIcon, DisketteIcon, EditIcon, ForbiddenIcon } from '@ui-kit/icons';
import { useState, useMemo } from '@hooks';
import styles from './FractionForm.module.css';

const FractionForm = ({
	title,
	editButtonTitle,
	cancelButtonTitle,
	submitButtonTitle,
	targetTotal,
	translateShouldTotalTitle,
	translateCurrentTotalTitle,
	items,
	onSubmit,
}: IFractionFormProps) => {
	const [editing, setEditing] = useState(false);
	const initialValues = useMemo(() => ({ items }), [items]);

	return (
		<Formik<{ items: TFractionFormItem[] }>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={async ({ items }) => {
				await onSubmit(items).then(() => setEditing(false));
			}}
		>
			{({ values, isSubmitting, dirty, submitForm, setFieldValue, resetForm }) => {
				const currentTotal = sumFractionFormItemValues(values.items);
				const isValid = currentTotal === targetTotal;
				const setItemValue = (index: number) => (newValue: number) => setFieldValue(`items[${index}].value`, newValue);

				return (
					<Form className={styles.fractionForm}>
						<div className={styles.fractionForm__header}>
							<div>
								<h3>{title}</h3>
							</div>
							{editing ? (
								<div className={styles.fractionForm__totalsBar}>
									<div className={styles.fractionForm__targetTotalBar}>
										<span className={styles.fractionForm__targetTotal}>{translateShouldTotalTitle(targetTotal)}</span>
									</div>
									<div className={styles.fractionForm__currentTotalBar}>
										<span className={styles.fractionForm__currentTotal} data-valid={isValid}>
											{isValid ? (
												<TickIcon className={styles.fractionForm__validityIcon} height={16} width={16} />
											) : (
												<ForbiddenIcon className={styles.fractionForm__validityIcon} height={16} width={16} />
											)}{' '}
											{translateCurrentTotalTitle(currentTotal)}
										</span>
									</div>
								</div>
							) : null}
						</div>

						<div className={styles.fractionForm__aside}>
							{editing ? (
								<>
									<Button
										disabled={isSubmitting}
										title={cancelButtonTitle}
										type="clear"
										onClick={() => {
											setEditing(false);
											resetForm();
										}}
									/>
									<Button
										disabled={!isValid || !dirty || isSubmitting}
										icon={<DisketteIcon height={14} width={13} />}
										iconPosition="left"
										loading={isSubmitting}
										title={submitButtonTitle}
										type="outline"
										onClick={submitForm}
									/>
								</>
							) : (
								<Button
									disabled={isSubmitting}
									icon={<EditIcon height={16} width={16} />}
									iconPosition="left"
									title={editButtonTitle}
									type="outline"
									onClick={() => setEditing(true)}
								/>
							)}
						</div>

						<div className={styles.fractionForm__body}>
							<FieldArray
								name="items"
								render={(arrayHelpers) => (
									<>
										{values.items.map((item, index) => (
											<FractionInput
												description={item.description}
												disabled={!editing || isSubmitting}
												key={item.id}
												title={item.title}
												value={item.value}
												onChange={setItemValue(index)}
											/>
										))}
									</>
								)}
							/>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

function sumFractionFormItemValues(items: TFractionFormItem[]): number {
	return items.reduce((acc, item) => acc + item.value, 0);
}

type TFractionFormItem = {
	id: string;
	title: string;
	description?: string;
	value: number;
};

export interface IFractionFormProps {
	title: string;
	editButtonTitle: string;
	cancelButtonTitle: string;
	submitButtonTitle: string;
	targetTotal: number;
	items: TFractionFormItem[];
	translateShouldTotalTitle: (targetTotal: number) => string;
	translateCurrentTotalTitle: (currentTotal: number) => string;
	onSubmit: (values: TFractionFormItem[]) => Promise<void>;
}

export default memo(FractionForm);
