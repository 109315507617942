import { useService } from '@services';
import { useMutation, useNotification } from '@hooks';
import type { TDealFilter } from '@typings';
import { useDealsEnabled } from './useDealsEnabled';

const useDealFiltersSettingsMutation = (refetchOnMutate = true) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { enabled } = useDealsEnabled();

	const { mutateAsync: patchDealFiltersSettings, isLoading: isPatchingDealFiltersSettingsLoading } = useMutation<
		void,
		Error,
		{ id: string; data: Partial<TDealFilter> }
	>(
		['deal.patchDealFilter'],
		({ id, data }) => {
			return api.invest.patchDealFilter(id, data);
		},
		{
			onSuccess: () => {
				if (enabled && refetchOnMutate) queryClient.invalidateQueries(queryKeys.getDealSettings());
			},
			onError: (error: Error) => {
				showUnknownError(error);
			},
		},
	);

	return {
		patchDealFiltersSettings,
		isPatchingDealFiltersSettingsLoading,
	};
};

export default useDealFiltersSettingsMutation;
