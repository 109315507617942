import { clsx } from '@utils';
import ActivityIndicator from '../ActivityIndicator';
import styles from './SwitchButton.module.css';

const SwitchButton = ({
	value: checked,
	size = 'medium',
	disabled = false,
	loading = false,
	onChange,
}: ISwitchButtonProps) => {
	const handleChange = () => onChange(!checked);
	return (
		<label className={clsx(styles.switch, disabled && styles.switch_disabled)} data-size={size}>
			{loading ? (
				<ActivityIndicator className={styles.switch__activityIndicator} type="fit" />
			) : (
				<>
					<input
						checked={checked}
						className={styles.switch__input}
						disabled={disabled}
						type="checkbox"
						onChange={handleChange}
						onKeyPress={handleChange}
					/>
					<span className={styles.switch__slider}></span>
				</>
			)}
		</label>
	);
};

export type TSwitchButtonSize = 'small' | 'medium' | 'large';
export interface ISwitchButtonProps {
	/** If true the button is checked, otherwise unchecked. */
	value: boolean;
	/** Can be 'large', 'small' or  'medium' (by default). When 'large' the height is 40px, when 'medium' - 32px, , when 'small' - 24px */
	size?: TSwitchButtonSize;
	/** Should it be able to be clicked. Default=false. */
	disabled?: boolean;
	/** Should show ActivityIndicator. Default=false. */
	loading?: boolean;
	/** Returns new checked value when button pressed via mouse or keyboard. */
	onChange: (newChecked: boolean) => void;
}

export default SwitchButton;
