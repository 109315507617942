import { Formik, Form, FormikPersistValues, Input, TextArea, Button, SegmentedControl, Gap } from '@ui-kit';
import UserSearchAutocomplete from '../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import DiscardFormChangesModal from '@ui-modules/forms/components/DiscardFormChangesModal';
import { useMe, useRef, useTranslation, useState } from '@hooks';
import { requestNetworkSchema } from '@schemas';
import { extractIdFromIri } from '@utils';
import { ROUTES } from '@constants';
import styles from './RequestNetworkForm.module.css';
import type { TRequestNetworkForm } from '@schemas';
import type { IPersistFormikValuesApi } from '@ui-kit';
import type { IAutocompleteSearchUser } from '../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';

const RequestNetworkForm = ({ onSubmit }: IRequestNetworkFormProps) => {
	const { t } = useTranslation();
	const { user } = useMe();

	const formikPersistRef = useRef<IPersistFormikValuesApi>(null);
	const formInitials = requestNetworkSchema(t).getDefault() as TRequestNetworkForm;

	const discardChanges = () => {
		formikPersistRef.current?.clear();
	};

	const [leaderUser, setLeaderUser] = useState<IAutocompleteSearchUser | null>();

	return (
		<Formik<TRequestNetworkForm>
			initialValues={formInitials}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={requestNetworkSchema(t)}
			onSubmit={(values) => onSubmit(values).then(() => discardChanges())}
		>
			{({ values, errors, isSubmitting, handleChange, submitForm, setFieldValue, getFieldMeta, setFieldTouched }) => {
				return (
					<Form className={styles.requestNetworkForm__form}>
						<FormikPersistValues
							name="RequestNetworkForm"
							persistInvalid
							ref={formikPersistRef}
							storage={window.sessionStorage}
						/>

						<div className={styles.requestNetworkForm__formContent}>
							<div className={styles.requestNetworkForm__helpText}>
								<i>{t('All fields are required unless otherwise indicated')}</i>
							</div>
							<Input
								autoCapitalize="sentences"
								errorMessage={errors.name}
								keepMarginBottom
								label={t('Network Topic')}
								name="name"
								value={values.name}
								onChange={handleChange('name')}
							/>
							<TextArea
								errorMessage={errors.purpose}
								keepMarginBottom
								label={t('Purpose of the Network')}
								maxRows={7}
								minRows={3}
								name="purpose"
								value={values.purpose}
								onChange={handleChange('purpose')}
							/>

							{/* Are you willing to be the Network Leader? */}
							<SegmentedControl
								fit
								isLeftOptionSelected={
									getFieldMeta('leaderUserId').touched ? user.id !== values.leaderUserId : undefined
								}
								label={t('Are you willing to be the Network Leader?')}
								leftOptionTitle={t('No')}
								rightOptionTitle={t('Yes')}
								size="large"
								onLeftOptionClick={() => {
									setFieldValue('leaderUserId', undefined);
									setFieldTouched('leaderUserId', true);
								}}
								onRightOptionClick={() => {
									setFieldValue('leaderUserId', user.id);
									setLeaderUser({
										id: user['@id'],
										fullName: `${user.firstName} ${user.lastName}`,
										avatar: user.avatar?.contentUrl,
										role: user.roles[0],
										enabled: true,
									});
									setFieldTouched('leaderUserId', true);
								}}
							/>
							<Gap gap={16} />

							<UserSearchAutocomplete
								defaultValue={values.leaderUserId ? leaderUser : null}
								disabled={values.leaderUserId === user.id}
								isClearable
								label={t('Recommended Network Leader (optional)')}
								placeholder={!values.leaderUserId ? t('Search for a user') : ''}
								onSelect={(foundUser) => {
									const mewUserOrUndefined = foundUser ? extractIdFromIri(foundUser.id) : undefined;
									setFieldValue('leaderUserId', mewUserOrUndefined);
									setLeaderUser(foundUser);
									setFieldTouched('leaderUserId', true);
								}}
							/>
							<Gap gap={16} />

							<TextArea
								errorMessage={errors.background}
								keepMarginBottom
								label={t('Describe your background or expertise in relation to this topic')}
								maxRows={7}
								minRows={3}
								name="background"
								value={values.background}
								onChange={handleChange('background')}
							/>

							<Gap gap={16} />
							<div className={styles.requestNetworkForm__helpText}>
								<i>
									{t(
										'TIGER 21 reviews all Network Requests on a quarterly basis. We will contact you regarding next steps.',
									)}
								</i>
							</div>
							<Gap gap={16} />
						</div>
						<Button
							block="fit"
							loading={isSubmitting}
							title={t('Submit Request')}
							type="outline"
							variant="medium"
							onClick={submitForm}
						/>
						<DiscardFormChangesModal
							allowedNextPaths={[ROUTES.requestNetworkConfirmation(''), ROUTES.requestNetwork()]}
							onDiscard={discardChanges}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export interface IRequestNetworkFormProps {
	onSubmit: (values: TRequestNetworkForm) => Promise<unknown>;
}

export default RequestNetworkForm;
