import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { MessageResponse, Channel } from 'stream-chat';
import type { TEvent } from '@typings';

export interface IChatKeyboardInput {
	editMessage: MessageResponse | null;
	quotedMessageId: string;
	selectedChannel: Channel | null;
	shouldUpdateChannel: boolean;
	selectedUsersIds: string[] | null;
	removedChannelIds: string[] | null;
	channelHasPauseMembers: boolean;
	chatName: string;
	selectedEvent: TEvent | null;
}

const initialState: IChatKeyboardInput = {
	editMessage: null,
	quotedMessageId: '',
	selectedChannel: null,
	shouldUpdateChannel: true,
	selectedUsersIds: null,
	removedChannelIds: null,
	channelHasPauseMembers: false,
	chatName: '',
	selectedEvent: null,
};

export const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setChatEvent: (state, { payload: value }: PayloadAction<MessageResponse | null>) => {
			state.editMessage = value;
		},
		setQuotedMessageId: (state, { payload: value }: PayloadAction<string>) => {
			state.quotedMessageId = value;
		},
		setSelectedChannel: (state, { payload: value }: PayloadAction<Channel | null>) => {
			state.selectedChannel = value;
		},
		setShouldUpdateChannel: (state, { payload: value }: PayloadAction<boolean>) => {
			state.shouldUpdateChannel = value;
		},
		setChatName: (state, { payload: value }: PayloadAction<string>) => {
			state.chatName = value;
		},
		setSelectedUsersIds: (state, { payload: value }: PayloadAction<string[] | null>) => {
			state.selectedUsersIds = value;
		},
		setChannelHasPausedMembers: (state, { payload: value }: PayloadAction<boolean>) => {
			state.channelHasPauseMembers = value;
		},
		setRemovedChannelsId: (state, { payload: value }: PayloadAction<string[] | null>) => {
			state.removedChannelIds = value;
		},
		setSelectedEvent: (state, { payload: value }: PayloadAction<TEvent | null>) => {
			state.selectedEvent = value;
		},
		resetState: (state) => {
			state.editMessage = null;
			state.quotedMessageId = '';
			state.shouldUpdateChannel = true;
			state.selectedUsersIds = null;
			state.channelHasPauseMembers = false;
			state.chatName = '';
			state.selectedEvent = null;
		},
	},
});
