import { Avatar, RightArrowIcon, RelativeTime, BlockLink, Link } from '@ui-kit';
import styles from './EntityInformationCard.module.css';
import { clsx } from '@utils';

const EntityInformationCard = ({
	creatorName,
	createdAt,
	avatarUrl,
	communitiesForBadge = [],
	subtitleAdditionalInfo,
	title,
	profileLink,
	className,
	subtitleLink,
	withArrow = true,
	avatarSize = 46,
	shadowMode,
	marginMode = true,
	radiusMode = true,
	FooterComponent,
	subtitleLinkDisabled,
	onClick,
	trailingActions,
	leadingActions,
}: IEntityInformationCardProps) => {
	return (
		<div className={styles.card__container}>
			{leadingActions?.()}
			<div
				className={clsx(
					styles.card__wrapper,
					shadowMode && styles.card__shadow,
					marginMode && styles.card__wrapper_margin,
					radiusMode && styles.card__wrapper_borderRadius,
					className,
				)}
			>
				<div className={styles.card__body} onClick={onClick} onKeyDown={onClick}>
					<BlockLink className={styles.card__avatar} to={profileLink}>
						<Avatar imageUri={avatarUrl} outline={false} size={avatarSize} title={creatorName} />
					</BlockLink>
					<div className={styles.card__content}>
						<div className={styles.card__textWrapper}>
							<Link className={styles.card__creator} to={profileLink} onClick={(event) => event.stopPropagation()}>
								{creatorName}
							</Link>
							<div className={styles.card__communities}>{communitiesForBadge.join(' • ')}</div>
							<div className={styles.card__assetSubTitle}>
								<RelativeTime className={styles.card__dateSubTitle} dateString={createdAt} />
								<span className={styles.card__assetName}>&nbsp;•&nbsp;</span>
								{subtitleLinkDisabled ? (
									<div className={styles.card__subtitle}>{subtitleAdditionalInfo}</div>
								) : (
									<Link
										className={clsx(styles.card__subtitle, subtitleLink && styles.card__subtitle_link)}
										to={subtitleLink || '#'}
										onClick={(event) => event.stopPropagation()}
									>
										{subtitleAdditionalInfo}
									</Link>
								)}
							</div>
						</div>
						<div className={styles.card__nameWrapper}>
							<p className={styles.card__name}>{title}</p>
						</div>
						{FooterComponent ? <div className={styles.card__footer}>{FooterComponent}</div> : null}
					</div>
					{withArrow && (
						<div className={styles.card__arrow} onClick={() => null} onKeyPress={() => null}>
							<RightArrowIcon height={16} width={14} />
						</div>
					)}
				</div>
				{trailingActions?.()}
			</div>
		</div>
	);
};

interface IEntityInformationCardProps {
	avatarUrl?: string;
	communitiesForBadge?: string[];
	createdAt: string;
	subtitleAdditionalInfo: string;
	profileLink: string;
	className?: string;
	subtitleLink?: string;
	withArrow?: boolean;
	avatarSize?: number;
	onClick?: () => void;
	creatorName: string;
	shadowMode?: boolean;
	marginMode?: boolean;
	radiusMode?: boolean;
	title: string;
	FooterComponent?: JSX.Element;
	subtitleLinkDisabled?: boolean;
	trailingActions?: () => React.ReactNode;
	leadingActions?: () => React.ReactNode;
}

export default EntityInformationCard;
