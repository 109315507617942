export function adjustLabelsRenderDataToAvoidOverflows<T extends { y: number }>(
	sortedLabels: T[],
	maximumY: number,
	minimumDistanceBetweenAdjacentLabels: number,
): T[] {
	// The first cycle to adjust from the top to the bottom.
	const labelsAdjustedFromTop = [...sortedLabels];
	for (let index = 0; index < labelsAdjustedFromTop.length; index++) {
		const currentLabel = labelsAdjustedFromTop[index];
		if (index === 0) {
			continue; // The first label can't be placed in the wrong position, only next ones.
		}

		const previousLabel = labelsAdjustedFromTop[index - 1];
		const distanceBetweenAdjacentLabels = currentLabel.y - previousLabel.y;
		if (distanceBetweenAdjacentLabels >= minimumDistanceBetweenAdjacentLabels) {
			continue; // The label is already in the correct position.
		}

		const yShiftToAvoidOverlapping = minimumDistanceBetweenAdjacentLabels - distanceBetweenAdjacentLabels;
		const shiftedY = currentLabel.y + yShiftToAvoidOverlapping;
		labelsAdjustedFromTop[index] = { ...currentLabel, y: shiftedY };
	}

	// The second cycle to adjust from the bottom to the top considering the 'maximumY' height limit.
	const labelsAdjustedFromTopAndBottom = [...labelsAdjustedFromTop];
	for (let index = labelsAdjustedFromTopAndBottom.length - 1; index >= 0; index--) {
		const currentLabel = labelsAdjustedFromTopAndBottom[index];
		const currentLabelY = currentLabel.y;
		const nextLabelY = labelsAdjustedFromTopAndBottom[index + 1]?.y || maximumY; // take a maximumY if there is no next label as a limit by height. If some label become places out of the height after the previous iteration, it will be adjusted back.

		if (currentLabelY < nextLabelY) continue; // The label is already in the correct position.

		const shiftedY = nextLabelY - minimumDistanceBetweenAdjacentLabels;
		labelsAdjustedFromTopAndBottom[index] = { ...currentLabel, y: shiftedY };
	}

	return labelsAdjustedFromTopAndBottom;
}
