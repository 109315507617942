import { useTranslation } from '@hooks';
import NotificationListHeader from './NotificationListHeader';

import styles from './NotificationsList.module.css';

const NotificationListEmptyState = () => {
	const { t } = useTranslation();
	return (
		<>
			<NotificationListHeader isMarkAsReadDisabled />
			<div className={styles.notificationsList__content}>
				<p className={styles.notificationsList__emptyMessage}>{t(`You don't have any notifications`)}</p>
			</div>
		</>
	);
};

export default NotificationListEmptyState;
