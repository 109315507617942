import { useFormikContext } from 'formik';
import { Checkbox, SpacedGroup } from '@ui-kit';
import useDealFiltersSettingsMutation from '@ui-modules/deals/hooks/useDealFiltersSettingsMutation';
import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import { addOrRemoveArrayElement } from '@utils';
import styles from '../OptionsBlock/OptionsBlock.module.css';
import { getDealFilterStatus } from '@ui-modules/networks/utils/getDealFilterStatus';
import { useService, useTranslation } from '@hooks';

const DealFiltersOptionsBlock = ({ id, title, isOpened, subTitle, status }: IDealFiltersOptionsBlock) => {
	const analytics = useService('AnalyticsService');
	const { values, setFieldValue } = useFormikContext<{
		options: string[];
		id: string;
	}>();

	const { t } = useTranslation();

	const { push, email } = NOTIFICATION_SETTING_CHANNEL;

	const { patchDealFiltersSettings, isPatchingDealFiltersSettingsLoading } = useDealFiltersSettingsMutation();

	const onOption = (id: string, value: typeof email | typeof push) => {
		const newOptions = addOrRemoveArrayElement(values.options, value);
		setFieldValue('id', id);
		setFieldValue('options', newOptions);
		patchDealFiltersSettings({
			id,
			data: {
				notificationChannels: newOptions,
				alerts: newOptions.includes('push/expo') || newOptions.includes('email'),
			},
		});
		analytics.trackEvent('AccountInteractions', {
			interaction_type: 'Invest',
			action: `settings changed to ${JSON.stringify(newOptions)}`,
		});
	};

	const dealStatus = getDealFilterStatus(t, status);

	return isOpened ? (
		<div className={styles.optionsBlock}>
			<div className={styles.optionInfo}>
				<span className={styles.optionTitle}>{title}</span>
				<span className={styles.optionSubTitle}>{`${t('{{status}} Deals in ', {
					status: dealStatus,
				})}
				${subTitle}
				`}</span>
			</div>
			<SpacedGroup gap={25}>
				<div>
					<Checkbox
						disabled={isPatchingDealFiltersSettingsLoading}
						value={!!values?.options.find((opt) => opt === email)}
						onChange={() => onOption(id, email)}
					/>
				</div>
				<div>
					<Checkbox
						disabled={isPatchingDealFiltersSettingsLoading}
						value={!!values?.options.find((opt) => opt === push)}
						onChange={() => onOption(id, push)}
					/>
				</div>
			</SpacedGroup>
		</div>
	) : null;
};

interface IDealFiltersOptionsBlock {
	title: string;
	isOpened: boolean;
	id: string;
	subTitle: string;
	status: string | null;
}
export default DealFiltersOptionsBlock;
