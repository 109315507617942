import { useMemo, useTranslation } from '@hooks';
import useAllDealFilterConfig from '@ui-modules/deals/hooks/useAllDealFilterConfigQuery';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useGetDealSettings } from '@ui-modules/deals/hooks/useGetDealSettings';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import { capitalize, sortBy } from '@utils';

export const useDealFiltersSettings = ({ enabledRequest }: { enabledRequest: boolean }) => {
	const { t } = useTranslation();
	const { enabled } = useDealsEnabled();
	const {
		data: dealSettings,
		isRefetching: isDealSettingsRefetching,
		isLoading: isDealSettingsLoading,
	} = useGetDealSettings(enabled && enabledRequest);

	const { collapsedNotificationsSettings } = useCollapsedNotificationsSettings();
	// list off ALL asset classes available for user
	const { data: allDealFilterConfig, isLoading } = useAllDealFilterConfig(enabled && enabledRequest);
	const allAssetClassesTitle = allDealFilterConfig?.assetClasses?.map((item) => capitalize(item.name)).join(', ') || '';
	return useMemo(() => {
		const userDealFiltersSettings = dealSettings?.map((setting) => {
			// backend provide empty array in case if all AssetClasses or SubClasses selected so we need to find list of all items.
			const getFilterForSpecificAssetClassTitle = () => {
				if (setting?.dealSubClasses?.length) {
					return setting.dealSubClasses.map((item) => capitalize(item.name)).join(', ');
				} else {
					const assetClassData = allDealFilterConfig?.assetClasses?.find(
						(el) => el['@id'] === setting.dealAssetClasses?.[0]?.id,
					);
					return (
						assetClassData?.dealSubClasses
							?.map((item) => capitalize(typeof item === 'string' ? item : item?.name))
							?.join(', ') || ''
					);
				}
			};
			const getAllDealSubtitle = () => {
				return setting.dealAssetClasses.length
					? setting.dealAssetClasses.map((item) => capitalize(item.name)).join(', ')
					: allAssetClassesTitle;
			};

			return {
				id: setting.id,
				// if field 'isFilterForSpecificAssetClass === false' (can be only for one entity from res) --- we have 'All Deals' filter setting
				name: setting.isFilterForSpecificAssetClass ? setting.assetClassCommunityName : t('All Deals'),
				options: setting.notificationChannels,
				subtitle: setting.isFilterForSpecificAssetClass ? getFilterForSpecificAssetClassTitle() : getAllDealSubtitle(),
				status: setting.status || null,
			};
		});

		const sortedUserDealFiltersSettings = sortBy(userDealFiltersSettings, 'name');

		const eachDealSettingIsOff = !!userDealFiltersSettings?.map((setting) => setting.options).flat().length;

		const collapsedIncludesInvest = collapsedNotificationsSettings.includes('Invest');

		return {
			sortedUserDealFiltersSettings,
			eachDealSettingIsOff: eachDealSettingIsOff || collapsedIncludesInvest,
			isLoading,
			isDealSettingsLoading,
		};
	}, [
		collapsedNotificationsSettings,
		dealSettings,
		allDealFilterConfig,
		isLoading,
		isDealSettingsRefetching,
		isDealSettingsLoading,
	]);
};
