import { useMutation, useService, resetRestoreVirtuosoInitialIndex, useMe } from '@hooks';
import type { TDeal } from '@typings';

export const useCreateDealMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	// TODO: Update when back-end is finished
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return useMutation<TDeal, Error, any>(['deal.createDeal'], (data: any) => api.invest.createDeal(data), {
		onSuccess: (deal) => {
			resetRestoreVirtuosoInitialIndex([
				`myDeals-${user.slug}`,
				`$allDeals-${user.slug}`,
				`notInterestedDeals-${user.slug}`,
				`networkDeals-${deal.community.id}-${user.slug}`,
			]);
			reactQuery.refetchDealsQueries(deal.community.id);
		},
	});
};
