import { useState } from '@hooks';
import type { TResultsAddresses } from '@typings';
import { Portal, CloseIcon, ActivityIndicator } from '@ui-kit';
import type { ChangeEvent } from 'react';
import { useRef } from 'react';
import styles from './SuggestionPlaceModal.module.css';
import { useAutocompletePlace } from '@ui-modules/contacts/hooks/useAutocompletePlace';

const SuggestionPlaceModal = ({
	value,
	onChange,
	placeholder,
	suggestDetails,
	onClear,
	searchOptions,
}: ISuggestionPlaceModalProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [focused, setFocused] = useState<boolean>(false);
	const { placeOptions, loading, getPlaceOptions, onPlaceSelect } = useAutocompletePlace(searchOptions);
	const [coords, setCoords] = useState<{ left?: number; top?: number; width?: number }>({ left: 0, top: 0 });

	const handleChange = (place_id: string) => {
		onPlaceSelect(place_id, (address) => suggestDetails(address));
		setFocused(false);
		inputRef.current?.blur();
	};

	return (
		<>
			<div className={styles.modal__inputContainer}>
				<input
					autoComplete="address-line1"
					className={styles.modal__input}
					placeholder={placeholder}
					ref={inputRef}
					value={value}
					onBlur={() => setFocused(false)}
					onChange={(e) => {
						onChange(e);
						getPlaceOptions(e.target.value);
					}}
					onFocus={(e) => {
						setFocused(true);
						const rect = e?.currentTarget?.getBoundingClientRect();
						setCoords({ left: rect.x, width: rect.width, top: rect.y + window.scrollY + 42 });
					}}
				/>
				{focused && value.length ? (
					<button className={styles.modal__inputButton} onClick={onClear} onMouseDown={(e) => e.preventDefault()}>
						<CloseIcon className={styles.modal__inputSvg} height={10} width={10}/>
					</button>
				) : null}
			</div>
			{(placeOptions?.length || loading) && focused ? (
				<Portal>
					<div className={styles.modal__optionsContainer} style={{ ...coords }}>
						{loading ? (
							<div className={styles.modal__optionsLoading}>
								<ActivityIndicator />
							</div>
						) : null}
						{placeOptions.map((item: TPlace) => {
							return (
								<button
									className={styles.modal__option}
									key={item.place_id}
									onClick={() => handleChange(item.place_id)}
									onMouseDown={(e) => e.preventDefault()}
								>
									<span className={styles.modal__optionTitle}>{item.structured_formatting.main_text}</span>
									<span className={styles.modal__optionSubtitle}>{item.structured_formatting.secondary_text}</span>
								</button>
							);
						})}
					</div>
				</Portal>
			) : null}
		</>
	);
};

export type TPlace = {
	place_id: string;
	terms: { offset: number; value: string }[];
	structured_formatting: {
		main_text: string;
		secondary_text: string;
	};
};
export interface ISuggestionPlaceModalProps {
	value: string;
	placeholder?: string;
	suggestDetails: (data: TResultsAddresses) => void;
	onChange: (inputChange: ChangeEvent<HTMLInputElement>) => void;
	onClear: () => void;
	searchOptions?: {
		componentRestrictions: {
			country: string | null;
		};
	};
}

export default SuggestionPlaceModal;
