import axios from 'axios';
import type { IAxiosService } from '../interfaces/AxiosService.interface';

// TODO MOVE TO SHARED WHEN GET REAL DATA

export class ThirdPartyDealApiService {
	constructor(private readonly axios: IAxiosService) {}

	async getThirdPartyToken(clientId?: string, clientSecret?: string): Promise<{ token: string }> {
		const baseUrl = this.axios.baseUrl;
		const formData = new FormData();
		if (clientId) {
			formData.append('Client-ID', clientId);
		}
		if (clientSecret) {
			formData.append('Client-Secret', clientSecret);
		}

		const res = await axios.post(`/api_client/auth`, formData, {
			baseURL: baseUrl,
			headers: {
				'X-Client': 'integration',
				'Content-Type': `multipart/form-data`,
			},
		});
		return res.data;
	}

	async updateAuthorization(token: string): Promise<void> {
		return await this.axios.setAuthorizationToken(token);
	}
}
