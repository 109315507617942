import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TNetworkConnection, TFolder } from '@typings';
import type { TEditFolderForm } from '@tiger21-llc/connect-shared/src/schemas/EditFolderForm.schema';
import type { MutationOptions } from '@tanstack/react-query';

/* Perform mutations with networkConnection files folders.
 * Includes get query & create, rename, delete mutations.
 */
export const useCreateNetworkConnectionFolderMutation = (
	networkConnectionId: TNetworkConnection['id'],
	folderId: TFolder['id'] | null,
	networkConnectionTitle: TNetworkConnection['title'],
	networkConnectionName: string,
	options?: MutationOptions<TFolder, Error, TEditFolderForm>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	return useMutation<TFolder, Error, TEditFolderForm>(
		['networkConnectionFolder.postNetworkConnectionFolder'],
		async ({ name }) =>
			await api.networkConnectionFolder.postNetworkConnectionFolder({
				folderId: folderId ?? undefined,
				name,
				networkConnectionId,
				ownerIri: user['@id'],
			}),
		{
			...options,
			async onSuccess(folder, variables, context) {
				await reactQuery.queryClient.setQueryData(
					reactQuery.queryKeys.getNetworkConnectionFolder(networkConnectionId, folder.id),
					folder,
				);
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getNetworkConnectionFolders(networkConnectionId));
				analytics.trackGenericEvent(
					'{{connectionName}}Interactions',
					{
						'{{connectionName}}_id': networkConnectionId,
						'{{connectionName}}_name': networkConnectionTitle,
						interaction_type: 'investor_files',
						interaction_action: 'folder created',
					},
					{
						connectionName: networkConnectionName,
					},
				);
				options?.onSuccess?.(folder, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
