import { useTranslation } from '@hooks';
import styles from './SearchNoMatch.module.css';

const SearchNoMatch = ({ searchQuery }: ISearchNoMatch) => {
	const { t } = useTranslation();

	return (
		<div className={styles.noMatch__wrapper}>
			<span className={styles.noMatch__text}>{t(`Sorry, we couldn’t find any matching results`)}</span>
			<span className={styles.noMatch__bold}>{searchQuery}</span>
			<span>{t('Try')}:</span>
			<span>• {t('Searching again using more general terms')}</span>
			<span>• {t('Exploring the community by discussion')}</span>
		</div>
	);
};

export default SearchNoMatch;

interface ISearchNoMatch {
	searchQuery: string;
}
