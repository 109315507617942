import type { TDeal } from '@typings';
import { useGetDealQuery } from './useGetDealQuery';
import { useGetDealPreviewQuery } from './useGetDealPreviewQuery';
import { useGetDealPreviewStatusQuery } from './useGetDealPreviewStatusQuery';

export const useGetThirdPartyDeal = (dealId: TDeal['id']) => {
	const { data: deal, isLoading: isDealLoading, error: dealError } = useGetDealQuery(dealId);
	const { data: dealPreview, isFetching: isDealPreviewLoading } = useGetDealPreviewQuery(dealId, {
		enabled: !!deal?.id && deal?.status === 'published',
	});
	const { data: previewDealStatus, isFetching: isPreviewStatusLoading } = useGetDealPreviewStatusQuery(dealId, {
		enabled: !!deal?.id && deal?.status === 'published' && deal?.hasPreview === false,
	});

	return {
		deal:
			(deal?.hasPreview || previewDealStatus?.status === 'rejected') && deal?.status === 'published'
				? dealPreview
				: deal,
		isLoading: (deal?.hasPreview ? isDealLoading || isDealPreviewLoading : isDealLoading) || isPreviewStatusLoading,
		dealError: dealError,
		previewDealStatus,
		isPreviewRequests: (deal?.hasPreview || previewDealStatus?.status === 'rejected') && deal?.status === 'published',
	};
};
