import { Tabs as TabHost } from 'react-tabs';
import TabsList from './TabsList';
import TabsTab from './TabsTab';
import TabsPanel from './TabsPanel';
import styles from './Tabs.module.css';
import type { TabsProps } from 'react-tabs';

const Tabs = ({ selectedIndex = 0, onSelect, children }: ITabsProps) => {
	return (
		<TabHost className={styles.tabs} defaultFocus selectedIndex={selectedIndex} onSelect={onSelect}>
			{children}
		</TabHost>
	);
};

export interface ITabsProps {
	/** Set the currently selected tab. This is a zero-based index, so first tab is 0, second tab is 1, ... */
	selectedIndex: TabsProps['selectedIndex'];
	/** This event handler is called every time a tab is about to change. It will be called with the index that it will be changed to, the lastIndex which was selected before and the underlying event which is usually either a keydown or click event. When index and lastIndex are equal it means the user clicked on the currently active tab. The callback can optionally return false to cancel the change to the new tab. */
	onSelect: TabsProps['onSelect'];
	/** Content of Tabs. Put at least <TabList><Tab>{title}</Tab></TabList><TabPanel>{content}</TabPanel> */
	children: TabsProps['children'];
}

Tabs.List = TabsList;
Tabs.Tab = TabsTab;
Tabs.Panel = TabsPanel;

export default Tabs;
