import When from '../When';
import Button from '../Button';
import { RightArrowIcon } from '../../icons';
import { clsx } from '@utils';
import styles from './SectionListRow.module.css';

/** Header of section within SectionList. */
const SectionListRow = ({ hidden = false, title, subtitle, link, padded, headerStyles }: ISectionListRowProps) => {
	return hidden ? (
		<ItemPlaceholder />
	) : (
		<div className={clsx(styles.sectionListRow__header, padded && styles.sectionListRow__header_padded, headerStyles)}>
			<div className={styles.sectionListRow__titleBox}>
				{title && <span className={styles.sectionListRow__title}>{title}</span>}
				{link ? (
					<div className={styles.sectionListRow__link}>
						<Button
							className={styles.sectionListRow__linkButton}
							icon={<RightArrowIcon className={styles.sectionListRow__linkIcon} height={12} width={12} />}
							iconPosition="right"
							title={link.title}
							type="clear"
							variant="small"
							onClick={link.onClick}
						/>
					</div>
				) : null}
			</div>
			<When condition={!!subtitle}>
				<div className={styles.sectionListRow__subtitleBox}>
					<span className={styles.sectionListRow__subtitle}>{subtitle}</span>
				</div>
			</When>
		</div>
	);
};

// Used a height 1px element to resolve Virtuose list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko]
const ItemPlaceholder = () => <div className={styles.sectionListRow__header_placeholder} />;

export interface ISectionListRowProps {
	hidden?: boolean;
	title?: string;
	subtitle?: string;
	link?: { title: string; onClick: () => void };
	padded?: boolean;
	headerStyles?: string;
}

export default SectionListRow;
