import { useService, useQuery } from '@hooks';
import type { TEventAttendee, TUser } from '@typings';

export const useUserEventAttendancesQuery = (userId: TUser['id'] | undefined) => {
	const api = useService('ApiService');

	return useQuery<TEventAttendee[], Error>(
		['userEventAttendances', userId],
		() => api.attendance.getUserEventAttendances(userId as TUser['id']),
		{
			enabled: !!userId,
		},
	);
};
