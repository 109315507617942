import FitText from '../FitText';
import { clsx, compact } from '@utils';
import styles from './CommunityCard.module.css';
import { DEFAULT_GROUP_BACKGROUND_IMAGE_PATH, DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH } from '@constants';
import type { TCommunity, TCommunityCompact } from '@typings';
import type { ReactNode } from 'react';

/** Card of group or network. Shows default background image if is absent within a community data. */
const CommunityCard = ({
	community,
	isSelected = false,
	nonSelectable = false,
	children,
	style,
	formatMembersCount = (membersCount) => membersCount,
	formatGroupsCount = (membersCount) => membersCount,
	onClick,
}: ICommunityCardProps) => {
	const communityBackgroundUrl =
		typeof community?.backgroundUrl === 'string' ? community?.backgroundUrl : community?.backgroundUrl?.contentUrl;
	const backgroundUrl =
		(communityBackgroundUrl || community?.background?.contentUrl) ?? defaultBackgroundsMap[community.definition];
	const handleClick = () => !isSelected && onClick(community);

	const groupsCount =
		'childCommunitiesCount' in community && community.childCommunitiesCount
			? formatGroupsCount(community.childCommunitiesCount)
			: undefined;
	const membersCount = community.membersCount ? formatMembersCount(community.membersCount) : undefined;
	const additionalInformation = compact([groupsCount, membersCount]).join(', ');

	return (
		<div
			className={clsx(
				styles.communityCard__wrapper,
				isSelected && styles.communityCard__wrapper_selected,
				nonSelectable && styles.communityCard__wrapper_nonSelectable,
				style,
			)}
		>
			<div
				aria-selected={isSelected}
				className={clsx(styles.communityCard, isSelected && styles.communityCard_selected)}
				role="option"
				style={{ backgroundImage: `url("${backgroundUrl}")` }}
				tabIndex={0}
				onClick={handleClick}
				onKeyPress={handleClick}
			>
				<div className={styles.communityCard__actionBox}>{children}</div>
				<div className={styles.communityCard__body}>
					<h3 className={styles.communityCard__title}>
						<FitText max={21} min={16} mode="single">
							{community.name}
						</FitText>
					</h3>
					{community.membersCount ? (
						<small className={styles.communityCard__subtitle}>{additionalInformation}</small>
					) : null}
				</div>
			</div>
		</div>
	);
};

const defaultBackgroundsMap: Record<TCommunityCompact['definition'], string> = {
	group: DEFAULT_GROUP_BACKGROUND_IMAGE_PATH,
	chapter: DEFAULT_GROUP_BACKGROUND_IMAGE_PATH,
	network: DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH,
};

export interface ICommunityCardProps {
	/** Community data to render. */
	community: TCommunityCompact | TCommunity;
	/** Should show blue border. Default false. */
	isSelected?: boolean;
	/** Hide border which can highlight a card as selected or not. */
	nonSelectable?: boolean;
	/** Slot for component to render within the Card. For example for an action button. */
	children?: ReactNode;
	/** Function to alter members count. */
	formatMembersCount?: (membersCount: number) => string | number;
	/** Function to alter groups count. */
	formatGroupsCount?: (membersCount: number) => string | number;
	/** Callback called when a card is selected. Does not called when card is already selected. */
	onClick: (community: TCommunityCompact | TCommunity) => void;
	style?: string;
}

export default CommunityCard;
