import { useService, useMutation, useTranslation, useNotification, useMe } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TReportChatContent, TReportChatMessageResponse } from '@typings';
import { useChannelFlaggedMessages } from '@ui-modules/chat/hooks/useChannelFlaggedMessages';
import { useGetChannelQuery } from './useGetChannelQuery';

/* Api used to report chat message.
 * TReportChatMessageResponse this is the response from api when i am passing this as third
 */
export const useReportChatMessageMutation = (
	options?: MutationOptions<TReportChatMessageResponse, Error, TReportChatContent>,
	channelId?: string,
) => {
	const api = useService('ApiService');
	const { t } = useTranslation();
	const { showUnknownError, showSuccess } = useNotification();
	const chatService = useService('ChatService');
	const { user } = useMe();
	const { flagMessageIds, setFlaggedMessageIds } = useChannelFlaggedMessages();
	const { data: channel } = useGetChannelQuery(channelId || '');

	return useMutation<TReportChatMessageResponse, Error, TReportChatContent>(
		['streamChat.postChatReportMessage'],
		async (report) => {
			const result = await api.streamChat.postChatReportMessege(report);

			// Flag message to get stream
			await chatService.chatClient.flagMessage(report.messageId);
			const flagsMap = flagMessageIds ? { ...flagMessageIds, [report.messageId]: true } : { [report.messageId]: true };
			setFlaggedMessageIds(flagsMap);
			if (channel) {
				await chatService.setLastReportedMessageId(channel, report.messageId, user?.id);
			}
			return result;
		},
		{
			...options,
			onSuccess: (data, variables, context) => {
				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
