import { useService, useMutation, useTranslation, useNotification } from '@hooks';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import type { MutationOptions } from '@tanstack/react-query';
import type { TReportFeedPostDto } from '@typings';

export const useReportFeedPostMutation = (options?: MutationOptions<TReportFeedPostDto, Error, TReportFeedPostDto>) => {
	const api = useService('ApiService');
	const { t } = useTranslation();
	const { showUnknownError, showSuccess } = useNotification();
	const feedService = useService('FeedService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const updateActivityInFeedCache = useUpdateActivityInFeedCache();

	return useMutation<TReportFeedPostDto, Error, TReportFeedPostDto>(
		['streamFeeds.postReportFeedPost'],
		async (report) => {
			const reportData = { reason: report.reason, explanation: report.explanation };
			await feedService.addActivityReportReaction(report.streamActivityId, reportData);
			return await api.streamFeeds.postReportFeedPost(report);
		},
		{
			...options,
			async onSuccess(data, reportDto, context) {
				await updateActivityInFeedCache(reportDto.streamActivityId);
				queryClient.refetchQueries(queryKeys.getSingleActivity(reportDto.streamActivityId));

				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(data, reportDto, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
