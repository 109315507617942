import { EntityPageHeader } from '@ui-kit';
import { useNavigate } from '@hooks';
import { useNetworkConnectionMeatballMenu } from '@ui-modules/connections/hooks/useNetworkConnectionMeatballMenu';
import { useCommunityModeratorMembersQuery } from '@ui-modules/communities/hooks/useCommunityModeratorMembersQuery';

import { dayjs } from '@utils';
import { ROUTES } from '@constants';
import type { TNetworkConnection, TProfile } from '@typings';

const NetworkConnectionPageHeader = ({
	networkConnection,
	networkConnectionName,
}: INetworkConnectionPageHeaderProps) => {
	const navigate = useNavigate();

	const { data: communityModerators = [] } = useCommunityModeratorMembersQuery(networkConnection.community.id);
	const [getMeatballMenuOptions, MeatballMenuDialogs] = useNetworkConnectionMeatballMenu({
		networkConnectionName: networkConnectionName,
		onDeleted: (networkConnection) =>
			navigate(ROUTES.networkTab(networkConnection.community.id, 'connections'), { replace: true }),
	});

	const goToProfile = () => {
		if (networkConnection.owner.roles.includes('ROLE_INACTIVE')) return;
		navigate(ROUTES.memberProfile(networkConnection.owner.profileId));
	};

	return (
		<>
			<EntityPageHeader
				avatarUri={networkConnection.owner.avatarUrl}
				date={dayjs(networkConnection.createdAt).fromNow()}
				fullName={networkConnection.owner.fullName}
				isChair={networkConnection.owner.profileType === 'ProfileChair'}
				location={networkConnection.owner.communitiesForBadge?.shortNames.join(' • ')}
				meatballMenuOptions={getMeatballMenuOptions(networkConnection, communityModerators)}
				network={networkConnection.community.shortName}
				networkLink={ROUTES.networkTab(networkConnection.community.id, 'connections')}
				theme="light"
				onProfilePress={goToProfile}
			/>
			{MeatballMenuDialogs}
		</>
	);
};

export interface INetworkConnectionPageHeaderProps {
	networkConnection: TNetworkConnection;
	networkConnectionName: string;
}

export default NetworkConnectionPageHeader;
