import { ExternalLinkIcon, Modal, Formik, Form, CheckboxInput, AmountInput, Gap } from '@ui-kit';
import { useTranslation } from '@hooks';
import { toString } from '@utils';
import { networkConnectionDonationFormSchema } from '@schemas';
import type { TNetworkConnection, TNetworkConnectionAction } from '@typings';
import type { TNetworkConnectionDonationForm } from '@schemas';
import type { IModalProps } from '@ui-kit';
import styles from './NetworkConnectionDonationModal.module.css';

const NetworkConnectionDonationModal = ({
	visible,
	networkConnection,
	networkConnectionAction,
	onSubmit,
	onCancel,
}: INetworkConnectionDonationModalProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TNetworkConnectionDonationForm>
			initialValues={networkConnectionDonationFormSchema.getDefault() as TNetworkConnectionDonationForm}
			validateOnBlur={true}
			validateOnChange={false}
			validationSchema={networkConnectionDonationFormSchema}
			onSubmit={(formValues) => {
				const castedValues = networkConnectionDonationFormSchema.cast(formValues) as TNetworkConnectionDonationForm;
				return onSubmit(castedValues);
			}}
		>
			{({ values, errors, isValid, isSubmitting, setFieldValue, handleBlur, submitForm }) => {
				return (
					<Form>
						<Modal
							cancelTitle={t('Cancel')}
							confirmButtonClassName={styles.confirmButtonClassName}
							confirmIcon={<ExternalLinkIcon />}
							confirmTitle={networkConnectionAction.buttonLabel}
							isConfirmDisabled={!isValid}
							isConfirmLoading={isSubmitting}
							key={networkConnection.id}
							modalContent={
								<>
									<Gap gap={16} />
									<CheckboxInput
										description={networkConnectionAction.checkboxHint}
										descriptionTheme="secondary"
										errorMessage={errors.showDonarStatus}
										label={networkConnectionAction.checkboxLabel}
										value={values.showDonarStatus}
										onChange={(value) => setFieldValue('showDonarStatus', value)}
									/>
									<Gap gap={16} />
									<AmountInput
										errorMessage={errors.amount}
										label={networkConnectionAction.amountLabel}
										placeholder={networkConnectionAction.amountPlaceholder}
										type="number"
										value={toString(values.amount)}
										onBlur={handleBlur('amount')}
										onChange={(event) => {
											const newValue = String(event.target.value).replaceAll(/\D/g, '') || undefined;
											setFieldValue('amount', newValue);
										}}
									/>
									<Gap gap={16} />
								</>
							}
							title={networkConnectionAction.popupTitle}
							titleCentered
							visible={visible}
							onCancel={onCancel}
							onConfirm={submitForm}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export interface INetworkConnectionDonationModalProps extends Pick<IModalProps, 'visible'> {
	networkConnection: TNetworkConnection;
	networkConnectionAction: TNetworkConnectionAction;
	onCancel: () => void;
	onSubmit: (formValues: TNetworkConnectionDonationForm) => Promise<void>;
}

export default NetworkConnectionDonationModal;
