import styles from './AvatarGroup.module.css';
import type { Channel } from 'stream-chat';
import { Avatar } from '@ui-kit';
import { useQuery } from '@hooks';
import { useService } from '@services';
import { dayjs } from '@utils';

const AvatarGroup = ({ channel }: IAvatarGroupProps) => {
	const chat = useService('ChatService');
	const reactQuery = useService('ReactQueryService');

	const { data: avatars = [] } = useQuery(
		reactQuery.queryKeys.getChatMemberAvatar(String(channel.id)),
		async () => await chat.getMemberAvatar(channel),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);

	return (
		<div>
			{avatars.length < 2 ? (
				<Avatar imageUri={null} outline={false} size={40} title={channel.data?.name} />
			) : avatars.length === 2 ? (
				<div className={styles.avatar__container}>
					<div>
						<div className={styles.avatar__twoImagesContainer}>
							<img alt="" className={styles.avatar__twoImages} src={avatars[0]}></img>
						</div>
					</div>
					<div>
						<div className={styles.avatar__twoImagesContainer}>
							<img alt="" className={styles.avatar__twoImages} src={avatars[1]}></img>
						</div>
					</div>
				</div>
			) : avatars.length === 3 ? (
				<div className={styles.avatar__container}>
					<div>
						<div className={styles.avatar__twoImagesContainer}>
							<img alt="" className={styles.avatar__twoImages} src={avatars[0]}></img>
						</div>
					</div>
					<div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__twoImages} src={avatars[1]}></img>
						</div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__fourImages} src={avatars[2]}></img>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.avatar__container}>
					<div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__fourImages} src={avatars[0]}></img>
						</div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__fourImages} src={avatars[1]}></img>
						</div>
					</div>
					<div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__fourImages} src={avatars[2]}></img>
						</div>
						<div className={styles.avatar__fourImagesContainer}>
							<img alt="" className={styles.avatar__fourImages} src={avatars[3]}></img>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AvatarGroup;

export interface IAvatarGroupProps {
	channel: Channel;
}
