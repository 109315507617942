import { useNavigate } from '@hooks';
import { useService } from '@services';
import type { TTagsType } from '@typings';
import { ROUTES } from '../../../app/routes';

export const useProfileTags = (tagType?: TTagsType) => {
	const { useAppDispatch, profileTags, useAppSelector } = useService('ReduxService');
	const dispatch = useAppDispatch();
	const selectedTags = useAppSelector((store) => store.profileTags.tags);
	const navigate = useNavigate();

	const saveSelectedTags = (tags: string[]) => {
		dispatch(profileTags.saveTags(tags));
	};

	const clearSelectedTags = () => {
		dispatch(profileTags.removeTags());
	};

	const openTagsSelector = (sectionTags: string[] = []) => {
		navigate(ROUTES.editProfileTags(tagType), {
			state: {
				tagsType: tagType,
				selectedTags: sectionTags,
			},
		});
	};

	return {
		selectedTags,
		saveSelectedTags,
		clearSelectedTags,
		openTagsSelector,
	};
};
