import { memo } from 'react';
import {
	GroupedVirtuoso,
	ActivityIndicator,
	EmptyStateMessage,
	FixedWidthContainer,
	SectionListRow,
	UserListItem,
} from '@ui-kit';
import { useCommunityModeratorMembersQuery } from '@ui-modules/communities/hooks/useCommunityModeratorMembersQuery';
import { useChapterMembersQuery } from '@ui-modules/groups/hooks/useChapterMembersQuery';
import { useCallback, useMemo, useTranslation } from '@hooks';
import { formatUserSubTitle } from '@utils';
import { formatParticipantsForVirtuosoSectionList } from './ChapterMemberSectionList.utils';
import styles from './ChapterMemberSectionList.module.css';
import type { ISectionListProps } from '@ui-kit';
import type { TCommunity, TCommunityMember, TUserProfileRecord, TChapterMember } from '@typings';

const ChapterMemberSectionList = ({ chapterId, onClick }: ICommunityMemberSectionListProps) => {
	const { t } = useTranslation();

	// Get data.
	const { data: moderators = [], isLoading: isModeratorsLoading } = useCommunityModeratorMembersQuery(chapterId, {
		suspense: true,
	});
	const {
		data: chapterMembers = [],
		isLoading: isMembersLoading,
		isFetching,
		fetchMore,
	} = useChapterMembersQuery(chapterId, {
		suspense: true,
	});
	const isLoading = isModeratorsLoading || isMembersLoading;
	const shouldShowEmptyMembersMessage = !chapterMembers.length;
	const { userProfileRecords, groupCounts, groups } = useMemo(() => {
		if (isLoading) return { userProfileRecords: [], groupCounts: [], groups: [] };
		else return formatParticipantsForVirtuosoSectionList(t)(moderators, chapterMembers);
	}, [moderators, chapterMembers, isLoading]);

	// Render list helpers.
	const renderGroupContent = useCallback(
		(index: number) => {
			return (
				<FixedWidthContainer>
					<SectionListRow headerStyles={styles.sectionListRow} title={groups[index]} />
				</FixedWidthContainer>
			);
		},
		[groups],
	);
	const renderItem = useCallback(
		(index: number) => {
			const userProfileRecord = userProfileRecords[index];
			return (
				<FixedWidthContainer>
					<UserListItem
						className={styles.communityMemberSectionList__userListItem}
						renderUserSubTitle={formatUserSubTitle(t)}
						size="medium"
						userProfileRecord={userProfileRecord}
						onClick={onClick}
					/>
				</FixedWidthContainer>
			);
		},
		[userProfileRecords],
	);

	return (
		<GroupedVirtuoso<TChapterMember>
			components={{
				EmptyPlaceholder: () =>
					isLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : shouldShowEmptyMembersMessage ? (
						<FixedWidthContainer>
							<EmptyStateMessage text={t('Sorry, no chapter members were found.')} />
						</FixedWidthContainer>
					) : null,
				Footer: () =>
					isFetching ? <ActivityIndicator size="medium" type="fit" /> : <div style={{ height: '32px' }} />,
			}}
			defaultItemHeight={80}
			endReached={fetchMore}
			groupContent={renderGroupContent}
			groupCounts={groupCounts}
			itemContent={renderItem}
			size={userProfileRecords.length}
		/>
	);
};

export interface ICommunityMemberSectionListProps
	extends Pick<ISectionListProps<TCommunityMember>, 'ListHeaderComponent' | 'ListFooterComponent'> {
	chapterId: TCommunity['id'];
	className?: string;
	onClick: (userProfile: TUserProfileRecord) => void;
}

export default memo(ChapterMemberSectionList);
