import { useService, useEffect, useLogger } from '@hooks';
import type { TAuthData } from '@tiger21-llc/connect-shared/src/typings/Auth.types';

/** Connects to the shared worker that is responsible for refreshing the access token on background.
 * 	It should be placed on the top of a wrapper component around private routes.
 *  The reasons of using shared worker instead on direct interval-based requests are:
 * 	 - It is shared between all tabs of the same origin. As a result:
 * 		- does not create multiple requests by every tab causes hitting rate limits
 * 		- prevents race conditions between tabs causes getting 403 error and sign out
 * 	 - It is not freezing by the browser when the tab is inactive. As a result:
 * 		- does not cause the token expiration
 * @author DmitriyNikolenko
 * @see T21C-7080
 * @see T21C-7167
 */
export const useRefreshTokenRefetchingWorker = () => {
	const redux = useService('ReduxService');
	const axios = useService('AxiosService');
	const logger = useLogger('useRefreshTokenRefetchingWorker');

	const onError = (event: ErrorEvent) => {
		logger.debug('error', event);
	};

	const onMessage = (event: TWorkerMessageEvent) => {
		switch (event.data.type) {
			case 'refresh-success': {
				const authData = event.data.payload;
				redux.store.dispatch(redux.auth.saveTokensResponse(authData));
				logger.debug('refresh token success', authData);
				break;
			}
			case 'refresh-error': {
				const error = event.data.payload;
				logger.debug('refresh token error', error);
				// no sign out here, it is delegated to ReactQuery as a last layer.
				break;
			}
			default: {
				logger.warn('unsupported message type');
			}
		}
	};

	useEffect(function setupWorker() {
		if (!('SharedWorker' in window)) return logger.warn('SharedWorker is not supported');

		const worker = new SharedWorker(new URL('./refreshTokenService.sharedWorker.js', import.meta.url), {
			name: 't21c--shared-worker--refresh-token',
			type: 'module',
		});
		worker.port.start();
		worker.addEventListener('error', onError);
		worker.port.addEventListener('message', onMessage);

		worker.port.postMessage({
			type: 'start',
			payload: {
				url: `${axios.baseUrl}/auth/refresh`,
				deviceId: redux.store.getState().me.deviceId,
				accessToken: redux.store.getState().auth.accessToken,
				refreshToken: redux.store.getState().auth.refreshToken,
			},
		});

		return () => {
			worker.port.postMessage({ type: 'stop' });
			worker.removeEventListener('error', onError);
			worker.port.removeEventListener('message', onMessage);
			worker.port.close();
		};
	}, []);
};

type TWorkerMessageEvent = MessageEvent<
	{ type: 'refresh-success'; payload: TAuthData } | { type: 'refresh-error'; payload: Error }
>;
