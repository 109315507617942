import { capitalize } from 'lodash';

/** Displays blank space to make gaps between components. */
const Gap = ({ hidden = false, gap = 16, horizontal = false }: IGapProps) => {
	const styleAttribute = horizontal ? 'width' : 'height';

	return hidden ? null : (
		<div
			style={{
				[styleAttribute]: gap,
				[`min${capitalize(styleAttribute)}`]: gap,
				[`max${capitalize(styleAttribute)}`]: gap,
				flexBasis: gap,
			}}
		/>
	);
};

export interface IGapProps {
	/** Gap size. Default 16px. */
	gap?: number;
	/** If true makes a horizontal gap. By default a gap is vertical. */
	horizontal?: boolean;
	/** Should it be hidden from DOM. Default false, */
	hidden?: boolean;
}

export default Gap;
