import React, { useCallback, useMemo } from 'react';
import { translateMembers } from '@utils';
import { CommunityCard } from '@ui-kit';
import { ROUTES } from '@constants';
import { useTranslation, useNavigate, useUserSessionStorageValue, useToggle, useService } from '@hooks';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import NetworkAttendanceButton from '@ui-modules/networks/components/NetworkAttendanceButton';
import type { TCommunity } from '@typings';
import styles from './AssetAllocationRecommendations.module.css';

const NETWORKS_DISPLAY_AMOUNT = 3;

const RecommendationNetworksBlock = ({ networks, networkName }: IRecommendationNetworksBlockProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const { joinNetwork, requestAccess } = useNetworkMutations({
		onJoinSuccess(networkId) {
			navigate(ROUTES.networkTab(networkId));
			analytics.trackEvent('AssetAllocationInteractions', {
				interaction_type: 'recommendation_interaction',
				action: 'join_network',
			});
		},
	});

	const { storageSavedValue: isNetworkSectionExpanded, setStorageSavedValue: setIsNetworkSectionExpanded } =
		useUserSessionStorageValue(`assetAllocationNetworks_networksToggle_${networkName}`);

	const [expanded, setExpanded] = useToggle(
		isNetworkSectionExpanded ? Boolean(JSON.parse(isNetworkSectionExpanded)) : false,
	);

	const onToggleClick = () => {
		setIsNetworkSectionExpanded(JSON.stringify(!expanded));
		setExpanded(!expanded);
	};

	const getSeeAllButton = useCallback(() => {
		return (
			<div>
				<div className={styles.expandableBlock__showAllWrapper} onClick={onToggleClick} onKeyDown={onToggleClick}>
					<span>{!expanded ? t('Load more') : t('Show less')}</span>
				</div>
			</div>
		);
	}, [expanded]);

	const renderNetworks = useMemo(() => {
		const initialNetworks = networks.slice(0, NETWORKS_DISPLAY_AMOUNT);
		const hiddenNetworks = networks.slice(NETWORKS_DISPLAY_AMOUNT);
		return { initialNetworks, hiddenNetworks };
	}, [networks]);

	const renderNetworkCard = useCallback(
		(community: TCommunity) => (
			<React.Fragment key={community.id}>
				<CommunityCard
					community={community}
					formatMembersCount={translateMembers(t)}
					style={styles.recommendations__communityCard}
					onClick={() => {
						navigate(ROUTES.networkTab(community.id));
						analytics.trackEvent('AssetAllocationInteractions', {
							interaction_type: 'recommendation_interaction',
							action: 'view_network',
						});
					}}
				>
					<NetworkAttendanceButton
						network={community}
						onJoin={() => joinNetwork(community)}
						onRequestAccess={() => requestAccess(community)}
					/>
				</CommunityCard>
			</React.Fragment>
		),
		[],
	);

	return (
		<>
			{renderNetworks.initialNetworks.map((network) => renderNetworkCard(network))}
			{expanded &&
				renderNetworks.hiddenNetworks &&
				renderNetworks.hiddenNetworks.map((network) => renderNetworkCard(network))}
			{networks.length > NETWORKS_DISPLAY_AMOUNT && getSeeAllButton()}
		</>
	);
};

interface IRecommendationNetworksBlockProps {
	networks: TCommunity[];
	networkName: string;
}
export default RecommendationNetworksBlock;
