import { useMe } from '@hooks';
import { Outlet, Navigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import type { TProfile } from '@typings';

const RestrictedRoutesWrapper = ({ profileType, allowForAdmin }: IRestrictedRoutesWrapperProps) => {
	const { profile, user } = useMe();

	const isAllowedForAdmin = allowForAdmin ? user.roles.includes('ROLE_ADMIN') : false;
	const isAllowedForProfileType = profile['@type'] === profileType;
	const isAllowed = isAllowedForAdmin || isAllowedForProfileType;

	return isAllowed ? <Outlet /> : <Navigate replace to={ROUTES.home()} />;
};

interface IRestrictedRoutesWrapperProps {
	profileType?: TProfile['@type'];
	allowForAdmin?: boolean;
}

export default RestrictedRoutesWrapper;
