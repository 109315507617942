import Tooltip from '../Tooltip';
import { QuestionRoundedIcon } from '../../icons';
import { useId } from 'react';
import { clsx } from '@utils';
import styles from './QuestionTooltip.module.css';
import type { ITooltipProps } from '../Tooltip';

const QuestionTooltip = ({ className, content, place = 'top' }: IQuestionTooltipProps) => {
	const id = useId();
	return (
		<>
			<QuestionRoundedIcon
				className={clsx(styles.questionTooltip, className)}
				data-tooltip-content={content}
				data-tooltip-id={id}
				data-tooltip-place={place}
			/>
			<Tooltip className={styles.tooltip__container} id={id} place={place} />
		</>
	);
};
interface IQuestionTooltipProps extends ITooltipProps {
	className: string;
}
export default QuestionTooltip;
