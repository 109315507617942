import ImageGridGallery from '../../ImageGridGallery';
import Avatar from '../../Avatar';
import styles from './PostImageGallery.module.css';
import { useMyTimezone } from '@hooks';
import { formateDateAgo, dayjs } from '@utils';
import type { IImageGridGalleryProps } from '@ui-kit/components/ImageGridGallery';
import type { TIsoDate } from '@typings';
import { useGetUserGroups } from '../../../../common/hooks/useGetUserGroups';

const PostImageGallery = ({
	userName,
	userId,
	createdAt,
	sourceName,
	imageUri,
	avatarOutline,
	...imageGridGalleryProps
}: IPostImageGalleryProps) => {
	const { userGroups } = useGetUserGroups(userId);
	const { timezone } = useMyTimezone();

	const timeZonedCreatedAt = dayjs.utc(createdAt).tz(timezone).toISOString();
	const relativeTime = formateDateAgo(timeZonedCreatedAt);

	return (
		<div className={styles.postImageGallery}>
			<ImageGridGallery
				{...imageGridGalleryProps}
				imageTitle={
					<div className={styles.imageTitle}>
						<div>
							<Avatar imageUri={imageUri} outline={avatarOutline} size={40} title={userName} />
						</div>
						<div className={styles.imageTitle__body}>
							<div>
								<span className={styles.imageTitle__userName}>{userName}</span>
							</div>
							{userGroups ? <div className={styles.imageTitle__groups}>{userGroups}</div> : null}
							<div className={styles.imageTitle__subTitle}>
								<span>{relativeTime}</span>
								<span>{sourceName}</span>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export interface IPostImageGalleryProps extends IImageGridGalleryProps {
	imageUri: string | null;
	avatarOutline: boolean;
	userName: string;
	userId: string | undefined;
	createdAt: TIsoDate;
	sourceName: string | undefined;
}

export default PostImageGallery;
