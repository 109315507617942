// We used Stream's default `MessageStatus` component and customized it for our requirements and codebase.
// When making changes it is recommended to look at the diff of the default component and our customized component.
// SEE: https://github.com/GetStream/stream-chat-react/blob/v10.5.0/src/components/Message/MessageStatus.tsx

import React from 'react';
import { useMessageContext, useChannelStateContext, MessageTimestamp } from 'stream-chat-react';
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { useMe, useTranslation } from '@hooks';
import { useService } from '@services';
import styles from './MessageStatus.module.css';
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MessageStatus = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>({
	messageType = 'simple',
}: IMessageStatusProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const chat = useService('ChatService');

	const { isMyMessage, lastReceivedId, message, readBy, threadList } =
		useMessageContext<StreamChatGenerics>('MessageStatus');
	const { channel } = useChannelStateContext();
	const isOneToOneChat = chat.isOneToOne(channel);

	// We want to show "delivered" and "read" status indicators only when: 1. The current user is the sender of the message. 2. And we are inside a 1:1 chat. (not group chats).
	const getMessageStatus = () => {
		const shouldShowDeliveryStatus = isMyMessage() && message.type !== 'error' && isOneToOneChat;
		const justReadByMe = readBy?.length === 1 && readBy[0].id === user.slug;
		const isSending = message.status === 'sending';
		const isLatestMessage = message.id === lastReceivedId;
		const isDelivered = message.status === 'received' && isLatestMessage && !threadList;
		const isRead = !!(readBy?.length && !threadList && !justReadByMe); // We know others have read the message if: 1. There are actual values in the `readBy` list; 2. The concerned messsage is not part of a thread; 3. `readBy` list does not just have the current user (who sent the message)

		if (!shouldShowDeliveryStatus) return null;
		else if (isSending) return t('Sending');
		else if (isRead) return t('Read');
		else if (isDelivered) return t('Delivered');
	};
	const messageStatus = getMessageStatus();

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			{/* Use of CSS class `str-chat__message-metadata` ensures that content is only displayed for the last message in a group of messages. */}
			<div
				className={clsx(
					'str-chat__message-data str-chat__message-simple-data str-chat__message-metadata',
					messageStatus && styles.messageStatusForceShow, // override Stream display:none to show timestamp if message status exists (T21C-4637) [@DmitriyNikolenko]. */}
				)}
			>
				<MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
				{messageStatus ? <span>|&nbsp;</span> : null}
			</div>
			{/* Message status rendered out of `str-chat__message-metadata` to ensure it always displayed (fixes T21C-4637) [@DmitriyNikolenko]. */}
			{messageStatus ? (
				<span className={`str-chat__message-${messageType}-status str-chat__message-status`}>{messageStatus}</span>
			) : null}
		</div>
	);
};

export type IMessageStatusProps = {
	/* Message type string to be added to CSS class names. */
	messageType?: string;
};

export default React.memo(MessageStatus);
