import React from 'react';
import { RadioButton } from '@ui-kit';
import { useTranslation } from '@hooks';

import styles from './RadioButtonsFilters.module.css';

const RadioButtonsFilters = ({ optionIndex, onShowAllChange, onLimitSelectionChange }: IRadioButtons) => {
	const { t } = useTranslation();

	return (
		<div className={styles.radioButtonWrapper}>
			<RadioButton
				customLabelWrapper={styles.radioButtonLabel}
				disabled={optionIndex === 0}
				value={optionIndex === 0}
				onChange={onShowAllChange}
			>
				{t('Show all')}
			</RadioButton>
			<RadioButton
				customLabelWrapper={styles.radioButtonLabel}
				disabled={optionIndex === 1}
				value={optionIndex === 1}
				onChange={onLimitSelectionChange}
			>
				{t('Limit to selections')}
			</RadioButton>
		</div>
	);
};

export interface IRadioButtons {
	optionIndex: number;
	onShowAllChange: () => void;
	onLimitSelectionChange: () => void;
}

export default RadioButtonsFilters;
