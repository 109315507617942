import { useMemo, useService, useTranslation } from '@hooks';
import type { TEmailContact, TPhoneContact } from '@typings';
import { AddUserIcon, ChatIcon, EmailIcon, PhoneIcon, RemoveContactIcon } from '@ui-kit';
import ContactActionButton from '../ContactActionButton';
import styles from './ContactActionButtonSet.module.css';
import { mapEmailTypeToTitle, mapPhoneTypeToTitle } from './ContactActionButtonSet.utils';

const ContactActionButtonSet = ({
	isInAddressBook,
	phones = [],
	emails = [],
	chatDisabled = false,
	callDisabled = false,
	emailDisabled = false,
	onOpenChat,
	onCall,
	onEmail,
	onAddToAddressBook,
	onRemoveContact,
	isFetching,
}: IContactActionButtonSetProps) => {
	const { t, i18n } = useTranslation();
	const analytics = useService('AnalyticsService');

	const translatedPhones = useMemo(() => mapPhoneTypeToTitle(phones, t), [phones, i18n.resolvedLanguage]);
	const translatedEmails = useMemo(() => mapEmailTypeToTitle(emails, t), [emails, i18n.resolvedLanguage]);

	const handleCall = (phoneNumber: string, label?: string) => {
		analytics.trackEvent('cta_pressed', { '#cta_name': 'contacts--phone-call' });
		onCall?.(phoneNumber, label);
	};
	const handleEmail = (email: string, label?: string) => {
		analytics.trackEvent('cta_pressed', { '#cta_name': 'contacts--send-email' });
		onEmail?.(email, label);
	};

	return (
		<div className={styles.buttons__container}>
			<ContactActionButton
				disabled={chatDisabled}
				icon={<ChatIcon className={styles.button__icon} height={20} width={20} />}
				title={t('Chat')}
				onPress={onOpenChat}
			/>
			<ContactActionButton
				disabled={callDisabled || !translatedPhones.length}
				icon={<PhoneIcon className={styles.button__icon} height={20} width={20} />}
				options={translatedPhones}
				title={t('Call')}
				onPress={handleCall}
			/>

			<ContactActionButton
				disabled={emailDisabled || !translatedEmails.length}
				icon={<EmailIcon className={styles.button__icon} height={20} width={20} />}
				options={translatedEmails}
				title={t('Email')}
				onPress={handleEmail}
			/>
			{isInAddressBook ? (
				<ContactActionButton
					disabled={isFetching}
					icon={<RemoveContactIcon className={styles.button__icon} height={20} width={20} />}
					title={t('Remove')}
					onPress={onRemoveContact}
				/>
			) : (
				<ContactActionButton
					disabled={isFetching}
					icon={<AddUserIcon className={styles.button__icon} height={20} width={20} />}
					title={t('Add')}
					onPress={onAddToAddressBook}
				/>
			)}
		</div>
	);
};

export interface IContactActionButtonSetProps {
	isInAddressBook: boolean;
	phones: TPhoneContact[];
	emails: TEmailContact[];
	chatDisabled?: boolean;
	callDisabled?: boolean;
	emailDisabled?: boolean;
	onOpenChat: () => void;
	onCall?: (phoneNumber: string, label?: string) => void;
	onEmail?: (emailAddress: string, label?: string) => void;
	onAddToAddressBook: () => void;
	onRemoveContact: () => void;
	isFetching?: boolean;
}

export default ContactActionButtonSet;
