import { useService, useMutation, useNotification, useMe } from '@hooks';
import { useUploadMediaObjectMutation } from './useUploadMediaObjectMutation';
import type { TDeal, TMediaObject, TFile, TFolder } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useUploadDealFileMutation = (
	deal: TDeal,
	folderId: TFolder['id'] | null,
	options?: MutationOptions<TFile, Error, TMediaObject>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	const uploadFileMutation = useMutation<TFile, Error, TMediaObject>(
		['dealFile.postDealFile'],
		async (mediaObject) => {
			return await api.dealFile.postDealFile({
				dealId: deal.id,
				folderId: folderId ?? undefined,
				mediaObjectId: mediaObject.id,
				name: mediaObject.originalName,
				ownerIri: user['@id'],
				isDealInformationFile: deal?.creator?.id === user.id && !folderId,
			});
		},
		{
			...options,
			async onSuccess(data, variables, context) {
				await reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getDealFiles(deal.id, folderId));
				options?.onSuccess?.(data, variables, context);
			},
			async onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);

	const uploadMediaObjectMutation = useUploadMediaObjectMutation({
		async onSuccess(mediaObject) {
			await uploadFileMutation.mutateAsync(mediaObject);
		},
	});

	return {
		uploadFile: uploadMediaObjectMutation.mutateAsync,
		isLoading: uploadMediaObjectMutation.isLoading || uploadFileMutation.isLoading,
	};
};
