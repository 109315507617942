import { useQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity } from '@typings';

export const useCommunityQuery = (communityId: TCommunity['id'] | undefined) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery(
		queryKeys.getCommunity(String(communityId)),
		async () => api.community.getCommunity(communityId as TCommunity['id']),
		{
			enabled: !!communityId,
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);
};
