import { ROUTES } from '@constants';
import { useCallback, useLocation, useMatch, useMemo, useParams, useSearchParams } from '@hooks';
import type { TPostPaths } from '@typings';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';

export const useRouteState = () => {
	const location = useLocation();
	const { activityId } = useParams();
	const [searchParams] = useSearchParams();
	const streamFeedId = searchParams.get('streamFeedId') ?? undefined;

	const { data: activity } = useFeedActivityQuery(activityId, streamFeedId, {});
	const postRouteMatch = useMatch(ROUTES.post(activityId));
	const editPostRouteMatch = useMatch(ROUTES.editPost(activityId));

	const getPostRouteName = useCallback((): TPostPaths => {
		const communityName = activity?.collectionCommunityReference?.data?.name || activity?.community?.name; // migration to use "collectionCommunityId" (T21C-2485)
		if (communityName) {
			return 'Networks';
		} else if (activity?.origin === 'user:GLOBAL') {
			return 'Home';
		} else return 'Learn';
	}, [activity]);

	const postTab = useMemo(() => {
		if (!activityId || !activity || (!editPostRouteMatch && !postRouteMatch)) {
			return undefined;
		}
		return getPostRouteName();
	}, [activity, location]);

	return { postTab };
};
