import { useMe, useMemo, useDebouncedCallback, useEvent } from '@hooks';
import { camelCase } from 'lodash';

/** Saves a scrollTop of a some div in a session storage and restores scroll position by request. */
export const useRestoreVirtuosoInitialIndex = (id: string, initialIndexShift = 0) => {
	const { user } = useMe();
	const individualId = `${id}-${user.slug}`;

	const initialTopMostItemIndex = useMemo(() => {
		const initialIndex = getScrollTop(individualId);
		return initialIndex <= initialIndexShift ? 0 : initialIndex;
	}, [individualId]);
	const setInitialTopMostItemIndex = useEvent((index: number) => setScrollTop(individualId, index));
	const onRangeChanged = useDebouncedCallback(
		({ startIndex }: { startIndex: number }) => setScrollTop(individualId, startIndex),
		300,
	);

	return { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged };
};

// Session storage helpers.

const prepareStorageKey = (id: string): string => `useRestoreDealsScroll__${camelCase(id)}`;

const getScrollTop = (id: string): number => {
	const persistKey = prepareStorageKey(id);
	const persistedValue = sessionStorage.getItem(persistKey);
	const scrollTop = Number.parseInt(String(persistedValue)) || 0;

	return scrollTop;
};

const setScrollTop = (id: string, itemIndex: number) => {
	const persistingValue = String(itemIndex);
	const persistKey = prepareStorageKey(id);

	sessionStorage.setItem(persistKey, persistingValue);
};

export const resetRestoreVirtuosoInitialIndex = (ids: string[]) => {
	ids.forEach((idItem) => setScrollTop(idItem, 0));
};
