import { useEvent } from '@hooks';
import { useMyLifestyleNetworksQuery } from '@ui-modules/networks/hooks/useMyLifestyleNetworksQuery';
import { useForeignLifestyleNetworksQuery } from '@ui-modules/networks/hooks/useForeignLifestyleNetworksQuery';
import type { TCommunity } from '@typings';

export const useNetworkLifestyle = (networkTab: string) => {
	// queries
	const myNetworksQuery = useMyLifestyleNetworksQuery(networkTab);
	const foreignNetworksQuery = useForeignLifestyleNetworksQuery(networkTab);

	// data
	const myNetworks = myNetworksQuery.data ?? EMPTY_DATA;
	const foreignNetworks = foreignNetworksQuery.data ?? EMPTY_DATA;

	// states
	const isLoading = foreignNetworksQuery.isLoading || myNetworksQuery.isLoading;
	const isSuccess = foreignNetworksQuery.isSuccess && myNetworksQuery.isSuccess;

	const refetch = useEvent(() => {
		foreignNetworksQuery.refetch();
		myNetworksQuery.refetch();
	});

	return {
		myNetworks,
		foreignNetworks,
		isLoading,
		isSuccess,
		refetch,
	};
};

const EMPTY_DATA: TCommunity[] = [];
