import { useService, useMutation, useQueryClient, useNotification, useTranslation } from '@hooks';
import { cloneDeep } from '@utils';
import type { TBlockedUser, TBlockedUserRelation } from '@typings';

export const useUnblockUserMutation = () => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<unknown, Error, TBlockedUserRelation['id'], TBlockedUser | undefined>(
		['unblockUser'],
		async (blockedUserRelationId) => await api.userBlocking.unblockUser(blockedUserRelationId),
		{
			onMutate(blockedUserRelationId) {
				let blockedUser: undefined | TBlockedUser;

				// Optimistic update.
				queryClient.setQueryData(['userBlocking.getBlockedUsers'], (cache: TBlockedUserRelation[] | undefined) => {
					if (!cache) return cache;
					return cache.filter((item) => {
						if (item.id === blockedUserRelationId) {
							blockedUser = cloneDeep(item.blockedUser);
							return false;
						}
						return true;
					});
				});

				return blockedUser;
			},
			onSuccess: (response, blockedUserRelationId, blockedUser) => {
				const userName = blockedUser ? `${blockedUser.firstName} ${blockedUser.lastName}` : t('User');
				showSuccess({ title: t('{{userName}} unblocked', { userName }) });
			},
			onError: (error) => {
				showUnknownError(error);
			},
			async onSettled() {
				await queryClient.refetchQueries(['userBlocking.getBlockedUsers']);
			},
		},
	);
};
