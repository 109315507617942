import { Button, ExternalLinkIcon } from '@ui-kit';
import NetworkConnectionDonationModal from '@ui-modules/connections/components/NetworkConnectionDonationModal';
import { useMemo, useToggle } from '@hooks';
import { useDonateToNetworkConnectionMutation } from '@ui-modules/connections/hooks/useDonateToNetworkConnectionMutation';
import { useNetworkConnectionActionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionActionQuery';
import { formatNetworkConnectionDonationAction } from '@utils';
import type { TNetworkConnection } from '@typings';

const NetworkConnectionDonateButton = ({
	networkConnection,
	networkConnectionsName,
}: INetworkConnectionDonateButtonProps) => {
	const [isDonationModalVisible, toggleDonationModalVisible] = useToggle(false);

	const { data: networkConnectionAction } = useNetworkConnectionActionQuery(networkConnection);
	const networkConnectionFormattedAction = useMemo(
		function formatNetworkConnectionAction() {
			if (!networkConnectionAction) return null;
			return formatNetworkConnectionDonationAction(networkConnectionAction, networkConnection as TNetworkConnection);
		},
		[networkConnectionAction, networkConnection],
	);
	const { mutateAsync: submitDonation, isLoading } = useDonateToNetworkConnectionMutation(
		networkConnection,
		networkConnectionAction?.networkConnectionStatusId,
		networkConnectionsName,
		{
			onSuccess: () => {
				const donationLink = networkConnectionAction?.buttonLink;
				if (donationLink) window.open(donationLink, '_blank');
				toggleDonationModalVisible(false);
			},
		},
	);

	if (!networkConnectionFormattedAction) return null; // For now we have only one action "Donation" that's why existing of some action is a trigger that we can donate.
	return (
		<>
			<Button
				disabled={isLoading}
				icon={<ExternalLinkIcon fill="#000000" height={12} width={12} />}
				iconPosition="right"
				title={networkConnectionFormattedAction.name}
				type="outline"
				variant="small"
				onClick={() => toggleDonationModalVisible()}
			/>

			{networkConnection && isDonationModalVisible ? (
				<NetworkConnectionDonationModal
					key={networkConnection.id}
					networkConnection={networkConnection}
					networkConnectionAction={networkConnectionFormattedAction}
					visible={isDonationModalVisible}
					onCancel={toggleDonationModalVisible}
					onSubmit={(formValues) => submitDonation(formValues)}
				/>
			) : null}
		</>
	);
};

export interface INetworkConnectionDonateButtonProps {
	networkConnection: TNetworkConnection;
	networkConnectionsName: string;
}

export default NetworkConnectionDonateButton;
