import { memo } from 'react';
import type { TRadioGroupInputOption } from '@ui-kit';
import { CheckboxInput, Formik, RadioGroupInput, When, TextArea, Gap } from '@ui-kit';
import { useTranslation, useRef, useState } from '@hooks';
import { toString } from '@utils';
import { getDietaryRestrictionsFormSchema } from '@schemas';
import styles from './EditDietaryRestrictionsForm.module.css';
import type { TDietaryRestrictions } from '@typings';
import type { FormikProps } from 'formik';
import type { PropsWithChildren, FC } from 'react';

const DEFAULT_WRAPPER: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

const EditDietaryRestrictionsForm = ({
	initialValues,
	dietaryRestrictionTypes = [],
	Wrapper = DEFAULT_WRAPPER,
	onSubmit,
}: IEditDietaryRestrictionsFormProps) => {
	const { t } = useTranslation();

	const existsOptions = useRef<TDietaryRestrictionsExistingOptions[]>([
		{ label: t('None'), value: '' /* falsy string */ },
		{ label: t('I have dietary restrictions'), value: 'exists' /* truthy string */ },
	]).current;

	const [customExpanded, setCustomExpanded] = useState(!!initialValues?.dietaryRestrictionsCustom?.length);
	const [typesExpanded, setTypesExpanded] = useState(customExpanded || !!initialValues?.dietaryRestrictions?.length);

	return (
		<Formik<TDietaryRestrictions>
			initialValues={getDietaryRestrictionsFormSchema(t).cast(initialValues) as TDietaryRestrictions}
			validationSchema={getDietaryRestrictionsFormSchema(t)}
			onSubmit={(values) => {
				// The 'dietaryRestrictionsCustom' should be casted to the empty string, otherwise BE will not return 'dietaryRestrictionsCustom' field in patched profile and data will not be updated in UI [@DmitriyNikolenko]
				onSubmit(getDietaryRestrictionsFormSchema(t).cast(values) as TDietaryRestrictions);
			}}
		>
			{(formProps) => {
				const existingFieldValue: TDietaryRestrictionsExistingOptions['value'] = typesExpanded ? 'exists' : '';

				const addTypeToExisting = (types: string[], addingType: string) => [...types, addingType].sort();
				const extractTypeFromExisting = (types: string[], deletingType: string) =>
					types.filter((type) => type !== deletingType);

				return (
					<Wrapper formProps={formProps}>
						<div className={styles.editDietaryRestrictionsForm}>
							<div>
								<span>
									<h4 className={styles.editDietaryRestrictionsForm__title}>{t('Dietary Restrictions')}</h4>
									<span> </span>
									<span>{t('(select all that apply)')}</span>
								</span>
							</div>
							<div>
								<RadioGroupInput
									options={existsOptions}
									value={existingFieldValue}
									onChange={(selectedExistValue) => {
										if (selectedExistValue) {
											setTypesExpanded(true);
										} else {
											formProps.setValues({
												dietaryRestrictions: [],
												dietaryRestrictionsCustom: null,
											});
											setCustomExpanded(false);
											setTypesExpanded(false);
										}
									}}
								/>
								<Gap gap={8} />
								<When condition={typesExpanded}>
									<div className={styles.editDietaryRestrictionsForm__typeList}>
										{dietaryRestrictionTypes.map((restriction) => {
											const restrictionSelected =
												formProps.values.dietaryRestrictions?.includes(restriction.value) ?? false;

											return (
												<CheckboxInput
													key={restriction.value}
													label={restriction.label}
													value={restrictionSelected}
													onChange={(newValue) => {
														const dietaryRestrictions = formProps.values.dietaryRestrictions || [];
														formProps.setFieldValue(
															'dietaryRestrictions',
															newValue
																? addTypeToExisting(dietaryRestrictions, restriction.value)
																: extractTypeFromExisting(dietaryRestrictions, restriction.value),
														);
													}}
												/>
											);
										})}
										<CheckboxInput
											label={t('Other (please specify)')}
											value={customExpanded}
											onChange={(newValue) => {
												setCustomExpanded(newValue);
												if (!newValue) formProps.setFieldValue('dietaryRestrictionsCustom', null);
											}}
										/>
									</div>
									<When condition={customExpanded}>
										<div className={styles.editDietaryRestrictionsForm__other}>
											<Gap gap={10} />
											<TextArea
												charactersLimit={250}
												errorMessage={formProps.errors.dietaryRestrictionsCustom}
												placeholder={t('Describe your other restriction(s)')}
												value={toString(formProps.values.dietaryRestrictionsCustom)}
												onBlur={formProps.handleBlur('dietaryRestrictionsCustom')}
												onChange={formProps.handleChange('dietaryRestrictionsCustom')}
											/>
										</div>
									</When>
								</When>
							</div>
						</div>
					</Wrapper>
				);
			}}
		</Formik>
	);
};

type TDietaryRestrictionsExistingOptions = TRadioGroupInputOption<'exists' | ''>; // as a booleans here we use stings: 'exists' - truthy value, '' - falsy value

export interface IEditDietaryRestrictionsFormProps {
	dietaryRestrictionTypes?: Array<{ label: string; value: string }>;
	initialValues?: Partial<TDietaryRestrictions>;
	Wrapper?: FC<PropsWithChildren<{ formProps: FormikProps<TDietaryRestrictions> }>>;
	onSubmit: (values: TDietaryRestrictions) => void;
}

export default memo(EditDietaryRestrictionsForm);
