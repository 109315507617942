// PostContent.tsx
import React from 'react';
import CollapsibleText from '@ui-kit/components/CollapsibleText';
import { convertHighlightingTextToHtml } from '../Post.utils';
import { clsx, noop } from '@utils';
import styles from './PostContent.module.css';
import type { ICollapsibleTextProps } from '@ui-kit/components/CollapsibleText';
import type { TUser } from '@typings';

const PostContent = ({
	children,
	collapsedNumberOfLines = 5,
	highlightingText,
	className,
	onClickLink = (url: string) => window.open(url, '_blank', 'noopener,noreferrer'),
	onLinkClicked,
	onMentionClick,
	...collapsibleTextProps
}: IPostContentProps) => {
	const handleClick = async (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		const dataId = target.getAttribute('data-id');

		if (dataId) {
			onMentionClick?.(dataId);
			event.stopPropagation();
			return;
		}

		if (target.tagName === 'A') {
			const url = (target as HTMLAnchorElement).href;
			onClickLink(url);
			onLinkClicked?.(url);
			event.stopPropagation();
		}
	};

	return (
		<CollapsibleText {...collapsibleTextProps} collapsedNumberOfLines={collapsedNumberOfLines}>
			<div
				className={clsx(styles.postContentWrapper, className)}
				dangerouslySetInnerHTML={{
					__html: convertHighlightingTextToHtml(highlightingText, children),
				}}
				onClick={handleClick}
				onKeyPress={noop}
			/>
		</CollapsibleText>
	);
};

export interface IPostContentProps
	extends Pick<
		ICollapsibleTextProps,
		| 'children'
		| 'collapsedTitle'
		| 'expandedTitle'
		| 'collapsedNumberOfLines'
		| 'onCollapse'
		| 'onExpand'
		| 'toggleHidden'
	> {
	children: string;
	className?: string;
	highlightingText?: string;
	onClickLink?: (url: string) => void;
	onLinkClicked?: (url: string) => void;
	onMentionClick?: (userId: TUser['id']) => void;
}

export default PostContent;
