import { yup, isUndefined, toString } from '@utils';
import type { SchemaOf } from '@utils';
import { DEAL_DESCRIPTION_CHARACTER_LIMIT, DEAL_FORM_PHONE_REGEXP } from '@constants';
import type { TDealTemplateField, TMediaObject } from '@typings';
import { yupToFormErrors, validateYupSchema } from 'formik';
import { phoneNumberValidator } from '@schemas';

export const dealFormFields = {
	CREATOR: 'creator',
	COMMUNITY: 'community',
	CREATOR_TYPE: 'creatorType',
	ASSET_CLASS: 'dealAssetClass',
	SUB_CLASS: 'dealSubClass',
	TYPE: 'dealType',
	CONTACT_NAME: 'contactName',
	CONTACT_PHONE: 'contactPhone',
	CONTACT_EMAIL: 'contactEmail',
	TITLE: 'title',
	DESCRIPTION: 'description',
	CLOSING_DATE: 'closingDate',
	DYNAMIC_DETAILS: 'detailsTemplate',
	CURRENCY: 'currency',
	MIN_INVESTMENT: 'minInvestment',
	DYNAMIC_FINANCIAL_DETAILS: 'financialDetailsTemplate',
	WEB_LINK_1: 'webLink1',
	WEB_LINK_2: 'webLink2',
	WEB_LINK_3: 'webLink3',
	FILES: 'files',
	VIDEO_URL: 'videoUrl',
	CREATOR_OPTION: 'creatorOption',
	SUBMIT_ACTION: 'submitAction',

	// TODO describe comment
	DELETED_FILES: 'deletedFiles',
	READ_ONLY_FILES_ACTIONS: 'makeFileReadOnlyActions',
	MAKE_FILE_DOWNLOADABLE_ACTIONS: 'makeFileDownloadableActions',
};

export const orderedFieldsList = Object.values(dealFormFields);

export const getDynamicDetailsFieldPath = (slug: string) => `detailsTemplate.${slug}`;
export const getDynamicDetailsFieldValuePath = (slug: string) => `${getDynamicDetailsFieldPath(slug)}.value`;

export const getDynamicFinancialDetailsFieldPath = (slug: string) => `financialDetailsTemplate.${slug}`;
export const getDynamicFinancialDetailsFieldValuePath = (slug: string) =>
	`${getDynamicFinancialDetailsFieldPath(slug)}.value`;

type TTranslateFunction = (value: string, params?: object) => string;

type TFieldWithValue = TDealTemplateField & { value: string };

export type TDynamicValues = {
	[fieldSlug: string]: TFieldWithValue;
};

type TDealFormRequiredValues = {
	creator: { iriId: string; name: string };
	creatorType: string;
	contactName: string;
	contactPhone: string;
	contactEmail: string;
	title: string;
};

export type TDealThirdPartyFormValues = TDealFormRequiredValues & {
	description: string;
	closingDate?: string | null;
	currency: string;
	minInvestment: string;
	webLink1?: string;
	webLink2?: string;
	webLink3?: string;
	files?: TMediaObject[];
	videoUrl?: string;
	detailsTemplate: TDynamicValues;
	financialDetailsTemplate: TDynamicValues;
	submitAction?: 'save' | 'submit';
	// Service fields which are not displayed in the form but are used for performing actions during the form submission
	deletedFiles?: string[];
	makeFileReadOnlyActions?: TMediaObject['id'][];
	makeFileDownloadableActions?: TMediaObject['id'][];
};

export type TDealOptionalThirdPartyFormValues = TDealFormRequiredValues & {
	description?: string;
	closingDate?: string | null;
	currency?: string;
	minInvestment?: string;
	webLink1?: string;
	webLink2?: string;
	webLink3?: string;
	files?: TMediaObject[];
	videoUrl?: string;
	detailsTemplate?: TDynamicValues;
	financialDetailsTemplate?: TDynamicValues;
	deletedFiles?: string[];
};

// ALL fields required for submit
export const getSubmitDealFormSchema: (t: TTranslateFunction) => SchemaOf<TDealThirdPartyFormValues> = (t) =>
	yup.object({
		creator: yup.object(),
		creatorType: yup.string().required(t('Please indicate your relationship to this deal')),
		contactName: yup
			.string()
			.trim()
			.required(t('Name is required'))
			.min(3, t('Name must be at least 3 characters'))
			.max(255, t('Name must be max 255 characters')),
		contactPhone: phoneNumberValidator(true, t('Phone number is not valid')).trim().required(t('Phone is required')),
		contactEmail: yup.string().email(t('Email is not valid')).required(t('Email is required')),
		title: yup
			.string()
			.trim()
			.max(255, t('Deal title must be max 255 characters'))
			.required(t('Please enter a title for this deal')),
		description: yup
			.string()
			.trim()
			.required('Please enter a description for this deal')
			.max(DEAL_DESCRIPTION_CHARACTER_LIMIT),
		closingDate: yup.string().nullable().optional(),
		currency: yup.string().required(t('Please select a currency for this deal')),
		minInvestment: yup
			.string()
			.trim()
			.test('maxValue', t('Maximum investment amount exceeded'), (value) => {
				if (!value) return true; // Skip the test if value is not provided
				const parsedValue = parseFloat(value.replace(/,/g, '')); // Parse value removing commas
				return parsedValue <= 2000000000; // Return true if value is less than or equal to 2b
			})
			.required(t('Minimum investment is required')),
		webLink1: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink2: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink3: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		files: yup.array().optional(),
		videoUrl: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		detailsTemplate: yup.object(),
		financialDetailsTemplate: yup.object(),
		deletedFiles: yup.array().optional(),
		creatorOption: yup.mixed().oneOf(['me', 'third-party']),
		submitAction: yup.mixed().oneOf(['save', 'submit']).optional(),
		makeFileReadOnlyActions: yup.array().optional(),
		makeFileDownloadableActions: yup.array().optional(),
	});

// A lot of optional fields!
export const getSaveDealFormSchema: (t: TTranslateFunction) => SchemaOf<TDealOptionalThirdPartyFormValues> = (t) =>
	yup.object({
		creator: yup.object(),
		creatorType: yup.string().required(t('Please indicate your relationship to this deal')),
		contactName: yup
			.string()
			.trim()
			.required(t('Name is required'))
			.min(3, t('Name must be at least 3 characters'))
			.max(255, t('Name must be max 255 characters')),
		contactPhone: phoneNumberValidator(true, t('Phone number is not valid')).trim().required(t('Phone is required')),
		contactEmail: yup.string().trim().email(t('Email is not valid')).required(t('Email is required')),
		title: yup
			.string()
			.trim()
			.max(255, t('Deal title must be max 255 characters'))
			.required(t('Please enter a title for this deal')),
		description: yup.string().trim().optional().max(DEAL_DESCRIPTION_CHARACTER_LIMIT),
		closingDate: yup.string().trim().nullable().optional(),
		currency: yup.string().optional(),
		minInvestment: yup
			.string()
			.trim()
			.test('maxValue', t('Maximum investment amount exceeded'), (value) => {
				if (!value) return true; // Skip the test if value is not provided
				const parsedValue = parseFloat(value.replace(/,/g, '')); // Parse value removing commas
				return parsedValue <= 2000000000; // Return true if value is less than or equal to 2b
			})
			.optional(),
		webLink1: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink2: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		webLink3: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		files: yup.array().optional(),
		videoUrl: yup.string().url(t('Not a valid URL. Enter a valid URL starting with “http”')).optional(),
		detailsTemplate: yup.object(),
		financialDetailsTemplate: yup.object(),
		deletedFiles: yup.array().optional(),
		creatorOption: yup.mixed().oneOf(['me', 'third-party']),
	});

const validateField = (field: TFieldWithValue, t: TTranslateFunction) => {
	const isRequired = isUndefined(field.required) || field.required;
	if (isRequired && !toString(field.value).trim()) return t('{{value}} is required', { value: field.label });
	if (field.type === 'money' && field.value && Number(field.value) < 1) {
		return t('Input value cannot be lower than 0.');
	}
};

const mapErrors = (values: TDynamicValues, t: TTranslateFunction) => {
	return Object.keys(values).reduce((acc, fieldSlug) => {
		const field: TFieldWithValue = values[fieldSlug];
		const error = validateField(field, t);
		if (error) {
			return {
				...acc,
				[fieldSlug]: error,
			};
		} else {
			return acc;
		}
	}, {});
};

export const getValidateHandler =
	(t: TTranslateFunction, isPublished: boolean) => async (values: TDealThirdPartyFormValues) => {
		const validationSchema = isPublished
			? getSubmitDealFormSchema(t)
			: values.submitAction === 'save'
			? getSaveDealFormSchema(t)
			: getSubmitDealFormSchema(t);
		let errors = {};
		try {
			validateYupSchema(values, validationSchema, true, values);
		} catch (err) {
			errors = { ...yupToFormErrors(err) };
		}

		if (values.submitAction === 'save' && !isPublished) {
			return errors;
		}
		const detailsTemplate = mapErrors(values.detailsTemplate || {}, t);
		const financialDetailsTemplate = mapErrors(values.financialDetailsTemplate || {}, t);

		if (Object.keys(detailsTemplate).length) {
			errors = { ...errors, detailsTemplate };
		}

		if (Object.keys(financialDetailsTemplate).length) {
			errors = { ...errors, financialDetailsTemplate };
		}

		return errors;
	};
