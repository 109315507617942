import { memo } from 'react';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import EventItem from '../EventItem';
import { useEvent, useTranslation } from '@hooks';
import { useNextGroupMeetingsQuery } from '@ui-modules/events/hooks/useNextGroupMeetingsQuery';
import { ROUTES } from '@constants';
import styles from './NextGroupMeetingsWidget.module.css';
import type { TEvent } from '@typings';

const NextGroupMeetingsWidget = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: nextGroupMeetings = [], isLoading } = useNextGroupMeetingsQuery();

	const openGroupMeeting = useEvent((event: TEvent) => navigate(ROUTES.calendarMeeting(event.id)));

	return (
		<div className={styles.nextGroupMeetingsWidget}>
			<div className={styles.nextGroupMeetingsWidget__header}>
				<h4>{t('Next Group Meeting')}</h4>
				<Link to={ROUTES.calendar()}>{t('View All')}</Link>
			</div>
			<div className={styles.nextGroupMeetingsWidget__body}>
				{isLoading ? (
					<ActivityIndicator size="small" type="fit" />
				) : nextGroupMeetings.length ? (
					nextGroupMeetings.map((groupMeeting) => (
						<EventItem event={groupMeeting} key={groupMeeting.id} onClick={() => openGroupMeeting(groupMeeting)} />
					))
				) : (
					<EmptyStateMessage text={t('There are no meetings')} />
				)}
			</div>
		</div>
	);
};

export default memo(NextGroupMeetingsWidget);
