import { NetworkConnectionMadeByBlockedUserError } from '@errors';
import { useBlockedAndBlockingUsers, useEffect, useNotification, useTranslation } from '@hooks';
import type { TNetworkConnection } from '@typings';

/** Validates whether certain NetworkConnection can be viewed and calling 'onError' if not.
 *  Checks if NetworkConnection is reported, made by blocked user, etc.
 *  Note; didn't manage to put it into useFeedActivityQuery because of significant delay in displaying error message and calling onError callback.
 *  @author dmitriy.nikolenko
 */
export const useValidateDisplayingNetworkConnection = (
	networkConnection: TNetworkConnection | undefined,
	networkConnectionName: string,
	onError: () => void,
) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	useEffect(() => {
		if (!networkConnection || !networkConnectionName) return;

		try {
			NetworkConnectionMadeByBlockedUserError.throwUnlessValid(networkConnection, blockedAndBlockingUsers);
		} catch (error) {
			if (error instanceof NetworkConnectionMadeByBlockedUserError) {
				showInfo(NetworkConnectionMadeByBlockedUserError.getNotificationMessage(t, networkConnectionName));
			}
			onError();
		}
	}, [networkConnection, networkConnectionName, blockedAndBlockingUsers]);
};
