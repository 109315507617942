import { AccountMenu, Page, PageLayout } from '../../components';
import { useTranslation } from '@hooks';
import { ActivityIndicator } from '@ui-kit';
import SupportMenu from '@ui-modules/account/components/SupportMenu';
import styles from './StaffAndSupportPage.module.css';
import StaffList from '@ui-modules/account/components/StaffList';
import MemberAdvisorSection from '@ui-modules/account/components/MemberAdvisorSection';
import { useGetUserGroupsModeratorsQuery } from '@ui-modules/account/hooks/useUserGroupsModeratorsQuery';

const StaffAndSupportPage = () => {
	const { t } = useTranslation();
	const { staffProfiles, memberAdvisor,memberAdvisorHidden, isLoading } = useGetUserGroupsModeratorsQuery();

	return (
		<Page title={t('Staff And Support')}>
			<PageLayout containerMode={false} sidebarContent={<AccountMenu />}>
				{isLoading ? (
					<ActivityIndicator type="fit" />
				) : (
					<>
						<MemberAdvisorSection 
							profile={memberAdvisor}
							hidden={memberAdvisorHidden}
						/>
						<div className={styles.support__container}>
							<SupportMenu />
							<StaffList staffProfiles={staffProfiles} />
						</div>
					</>
				)}
			</PageLayout>
		</Page>
	);
};

export interface IChatPageParams {
	id: string;
}

export default StaffAndSupportPage;
