import styles from './DealTabsPage.module.css';
import { Toast } from '@ui-kit';
import { useTranslation, useMemo, useMe } from '@hooks';
import type { TDeal } from '@typings';
import {
	DEAL_FIELD_TITLES,
	transformDealFieldName,
	getChangedDealFields,
} from '@ui-modules/deals/utils/reviewDealsChanges';
import { DealEntity } from '@tiger21-llc/connect-shared/src/utils/DealEntity';

const DealReviewInfoMessage = ({
	isReviewMode,
	deal,
	dealPreview,
	isFilesChanged,
	onReviewChangesPress,
}: IDealReviewInfoMessageProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const dealFieldTitles = DEAL_FIELD_TITLES(t);
	const changedDealFields = useMemo(() => {
		if (!deal || !dealPreview) return [];
		const changedFields = getChangedDealFields(deal, dealPreview);
		if (isFilesChanged) {
			changedFields.push(t('File Attachments'));
		}
		return changedFields;
	}, [deal, dealPreview]);

	if (!deal.hasPreview) return null;
	else if (isReviewMode)
		return (
			<Toast
				className={styles.toastMessage}
				ContentComponent={
					<ul className={styles.list}>
						{changedDealFields.map((fieldTitle) => (
							<li className={styles.list__item} key={fieldTitle}>
								<span>
									{dealFieldTitles[fieldTitle as keyof typeof dealFieldTitles] || transformDealFieldName(fieldTitle)}
								</span>
							</li>
						))}
					</ul>
				}
				shouldShowClose={false}
				title={t('Changes for the following fields are highlighted in yellow:')}
				type="info"
			/>
		);
	else if (DealEntity.canSeeReviewUpdates(deal, user))
		return (
			<Toast
				className={styles.toastMessage}
				shouldShowClose={false}
				subtitle={t('Your deal has changes available for review.')}
				title={t('Click here to review changes')}
				type="info"
				onPress={onReviewChangesPress}
			/>
		);
	else return null;
};

interface IDealReviewInfoMessageProps {
	isReviewMode?: boolean;
	onReviewChangesPress: () => void;
	deal: TDeal;
	dealPreview?: TDeal;
	isFilesChanged: boolean;
}

export default DealReviewInfoMessage;
