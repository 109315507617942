import styles from './ColoredItemNumber.module.css';

const ColoredItemNumber = ({ index, colors }: IColoredItemNumberProps) => {
	const backgroundColor = colors[index];

	return (
		<div className={styles.coloredItem} style={{ backgroundColor }}>
			{colors[index] ? <span className={styles.coloredItem__title}>{index + 1}</span> : null}
		</div>
	);
};

export interface IColoredItemNumberProps {
	index: number;
	colors: string[];
}

export default ColoredItemNumber;
