import { useTranslation } from '@hooks';
import styles from '../MyDealsZeroState/Deals.module.css';

const AllDealsZeroState = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.dealTab__zeroWrapper}>
			<div className={styles.dealTab__container}>
				<p className={styles.dealTab__zeroTitle}>{t('No Deals matching your Filter')}</p>
				<span className={styles.dealTab__subTitle}>{t('...or no Deals exist. Try filtering again.')}</span>
			</div>
		</div>
	);
};

export default AllDealsZeroState;
