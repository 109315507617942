import { useQuery, useService } from '@hooks';
import type { TCommunity, TFolder } from '@typings';

export const useCommunityFilesAndFoldersQuery = (communityId: TCommunity['id'], folderId: TFolder['id'] | null) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const filesQuery = useQuery(
		reactQuery.queryKeys.getCommunityFiles(communityId, folderId),
		async () => await api.communityFile.getCommunityFiles({ communityId, folderId: folderId ?? undefined }),
	);
	const foldersQuery = useQuery(
		reactQuery.queryKeys.getCommunityFolders(communityId, folderId),
		async () => await api.communityFolder.getCommunityFolders({ communityId, folderId: folderId ?? undefined }),
		{
			onSuccess(folders) {
				folders.forEach((folder) =>
					reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getCommunityFolder(communityId, folder.id), folder),
				);
			},
		},
	);

	return {
		files: filesQuery.data,
		folders: foldersQuery.data,
		isLoading: filesQuery.isLoading || foldersQuery.isLoading,
		isFetching: filesQuery.isFetching || foldersQuery.isFetching,
	};
};
