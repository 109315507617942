import styles from './SelectExpertiseField.module.css';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Chip, ColoredItemNumber, GripLinesIcon } from '@ui-kit';
import { EXPERTISE } from '@constants';
import { useMemo } from '@hooks';
import clsx from 'clsx';
import { reorderDragItems } from './SelectExpertiseField.utils';

const SelectExpertiseField = ({ data, onRemove, onDrag }: ISelectExpertiseFieldProps) => {
	const getEmptyIndexes = useMemo(
		() =>
			Array(EXPERTISE.LIMIT)
				.fill(null)
				.map((_, index) => index)
				.slice(data.length),
		[data],
	);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		const itemsReordered = reorderDragItems(data, result.source.index, result.destination.index);
		onDrag(itemsReordered.map((el) => el.value));
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided) => (
					<div {...provided.droppableProps} className={styles.drag__container} ref={provided.innerRef}>
						{data.map((item, index) => (
							<Draggable draggableId={item.id} index={index} key={item.id}>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										className={styles.dragItem__container}
									>
										<div className={styles.dragItem__label}>
											<ColoredItemNumber colors={EXPERTISE.COLORS} index={index} />
											<Chip title={item.value} onRemove={() => onRemove(index)} />
										</div>
										<div>
											<GripLinesIcon height={6} width={16} />
										</div>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
			<div className={clsx(styles.drag__container, data.length && styles.drag__container_empty)}>
				{getEmptyIndexes.map((index) => (
					<div className={styles.dragItem__container} key={index}>
						<div className={styles.dragItem__label}>
							<ColoredItemNumber colors={EXPERTISE.COLORS} index={index} />
						</div>
					</div>
				))}
			</div>
		</DragDropContext>
	);
};

export interface ISelectExpertiseFieldProps {
	data: TDataItem[];
	onRemove: (index: number) => void;
	onDrag: (values: string[]) => void;
}

export type TDataItem = {
	value: string;
	id: string;
};

export default SelectExpertiseField;
