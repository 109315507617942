import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.overwrite.css';
import DatePicker from 'react-datepicker';
import { clsx, dayjs, toISOIgnoreTimezone, getDatePickerFormat } from '@utils';
import { CloseIcon, InputErrorMessage } from '@ui-kit';
import styles from './ClosingDateInput.module.css';

const ClosingDateInput = ({
	value,
	onChange,
	placeholder = 'Select date',
	label,
	dateFormat = 'D MMMM YYYY',
	minDate,
	maxDate,
	disabled,
	errorMessage,
	onClear,
	labelPosition = 'top',
	labelStyles,
	errorMessageInitialHidden,
}: IClosingDateInputProps) => {
	const CustomInputButton = forwardRef<HTMLButtonElement, any>(function CustomInput( // TODO: fix types
		{ value, onClick },
		ref: ForwardedRef<HTMLButtonElement>,
	) {
		return (
			<button
				className={clsx(
					styles.closingDateInput,
					!!value && styles.closingDateInput__selected,
					disabled && styles.closingDateInput__disabled,
					!!errorMessage && styles.input_error,
				)}
				disabled={disabled}
				ref={ref}
				onClick={onClick}
			>
				{value ? dayjs(value).format(dateFormat.toUpperCase()) : placeholder}
				{!disabled && onClear && value && (
					<span
						className={styles.closingDateInput__clearButton}
						onClick={(event) => {
							event.stopPropagation();
							onClear();
						}}
						onKeyPress={(event) => {
							event.stopPropagation();
							onClear();
						}}
					>
						<CloseIcon fill="black" height={10} width={10} />
					</span>
				)}
			</button>
		);
	});

	const datePickerFormat = getDatePickerFormat(dateFormat);

	return (
		<div className={styles.closingDateInputWrapper}>
			{label && labelPosition === 'top' && (
				<div className={styles.closingDateInput__labelWrapper}>
					<label>{label}</label>
				</div>
			)}
			<DatePicker
				calendarClassName={clsx(datePickerFormat === 'month' && styles.monthWrapper)}
				customInput={<CustomInputButton />}
				dateFormatCalendar="MMMM"
				dropdownMode="select"
				fixedHeight
				maxDate={maxDate}
				minDate={minDate}
				scrollableYearDropdown
				selected={value}
				showMonthYearPicker={datePickerFormat === 'month'}
				showYearDropdown
				showYearPicker={datePickerFormat === 'year'}
				wrapperClassName={styles.dateInputWrapper}
				onChange={(date) => {
					onChange(date ? toISOIgnoreTimezone(date) : undefined);
				}}
			/>
			{!errorMessage && label && labelPosition === 'bottom' && (
				<div className={styles.closingDateInput__labelWrapper}>
					<label className={clsx(labelStyles)}>{label}</label>
				</div>
			)}
			<InputErrorMessage hidden={errorMessageInitialHidden && !errorMessage} text={errorMessage} />
		</div>
	);
};

export default ClosingDateInput;

export interface IClosingDateInputProps {
	value?: Date | null;
	onChange: (value?: string) => void;
	onClear: () => void;
	placeholder?: string;
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	dateFormat?: string;
	disabled?: boolean;
	errorMessage?: string;
	labelPosition?: 'top' | 'bottom';
	labelStyles?: string;
	errorMessageInitialHidden?: boolean;
}
