import { memo } from 'react';
import { UserListItem, Card, SeeMoreButton } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useMeetingAttendeesQuery } from '@ui-modules/events/hooks/useMeetingAttendeesQuery';
import type { TEvent, TUserProfileRecord } from '@typings';
import { adaptMeetingAttendeeListUserProfileRecord, formatUserSubTitle } from '@utils';
import styles from './MeetingAttendeeList.module.css';

const MeetingAttendeeList = ({ meetingId, goToProfile }: IMeetingAttendeeListProps) => {
	const { t } = useTranslation();

	const {
		data: goingAttendees = [],
		isFetchingNextPage: isFetchingNextGoing,
		fetchMore: fetchMoreGoing,
		hasNextPage: hasNextGoing,
	} = useMeetingAttendeesQuery({ meetingId, guests: false, going: true });
	const {
		data: notGoingAttendees = [],
		isFetchingNextPage: isFetchingNextNotGoing,
		fetchMore: fetchMoreNotGoing,
		hasNextPage: hasNextNotGoing,
	} = useMeetingAttendeesQuery({ meetingId, guests: false, going: false });
	const {
		data: goingGuests = [],
		isFetchingNextPage: isFetchingNextGuests,
		fetchMore: fetchMoreGuests,
		hasNextPage: hasNextGuests,
	} = useMeetingAttendeesQuery({ meetingId, guests: true, going: true });
	const hasAttendees = goingAttendees.length > 0 || notGoingAttendees.length > 0 || goingGuests.length > 0;

	if (!hasAttendees) return null;
	return (
		<Card backgroundPlacement="none" title={t('Attendees')}>
			{!!goingAttendees.length && (
				<div className={styles.meetingAttendeeList__section}>
					<p className={styles.guestsLabel}>{t('Going')}</p>
					{goingAttendees.map((member) => (
						<UserListItem
							isBackgroundTransparent
							key={member.id}
							renderUserSubTitle={formatUserSubTitle(t)}
							size="small"
							userProfileRecord={adaptMeetingAttendeeListUserProfileRecord(member)}
							onClick={goToProfile}
						/>
					))}
					<SeeMoreButton
						hasNext={!!hasNextGoing}
						isFetching={isFetchingNextGoing}
						title={t('See more')}
						onClick={fetchMoreGoing}
					/>
				</div>
			)}
			{!!notGoingAttendees.length && (
				<div className={styles.meetingAttendeeList__section}>
					<p className={styles.guestsLabel}>{t('Not going')}</p>
					{notGoingAttendees.map((member) => (
						<UserListItem
							isBackgroundTransparent
							key={member.id}
							renderUserSubTitle={formatUserSubTitle(t)}
							size="small"
							userProfileRecord={adaptMeetingAttendeeListUserProfileRecord(member)}
							onClick={goToProfile}
						/>
					))}
					<SeeMoreButton
						hasNext={!!hasNextNotGoing}
						isFetching={isFetchingNextNotGoing}
						title={t('See more')}
						onClick={fetchMoreNotGoing}
					/>
				</div>
			)}
			{!!goingGuests.length && (
				<div className={styles.meetingAttendeeList__section}>
					<p className={styles.guestsLabel}>{t('Guests')}</p>
					{goingGuests.map((member) => (
						<UserListItem
							isBackgroundTransparent
							key={member.id}
							renderUserSubTitle={formatUserSubTitle(t)}
							size="small"
							userProfileRecord={adaptMeetingAttendeeListUserProfileRecord(member)}
							onClick={goToProfile}
						/>
					))}
					<SeeMoreButton
						hasNext={!!hasNextGuests}
						isFetching={isFetchingNextGuests}
						title={t('See more')}
						onClick={fetchMoreGuests}
					/>
				</div>
			)}
		</Card>
	);
};

export interface IMeetingAttendeeListProps {
	meetingId: TEvent['id'];
	goToProfile: (userProfileRecord: TUserProfileRecord) => void;
}

export default memo(MeetingAttendeeList);
