import { useMemo } from '@hooks';
import type { TBaseOption } from './LabeledArrayField';

/** Find out labels which can be used to add new item or change label for existing one. */
export const useAvailableTypes = (options: Array<TBaseOption>, types: string[]) => {
	return useMemo(() => {
		const usedLabels = options.map((option) => option.type);
		const unusedLabels = types.filter((type) => !usedLabels.includes(type));
		return unusedLabels;
	}, [types, options]);
};
