import { memo } from 'react';
import { FixedWidthContainer, SectionList, UserListItem } from '@ui-kit';
import { useCommunityModeratorMembersQuery } from '@ui-modules/communities/hooks/useCommunityModeratorMembersQuery';
import { useCommunityRegularMembersInfiniteQuery } from '@ui-modules/communities/hooks/useCommunityRegularMembersInfiniteQuery';
import { useCallback, useMemo, useTranslation } from '@hooks';
import { adaptCommunityMemberToUserProfileRecord, compact, formatUserSubTitle } from '@utils';
import styles from './CommunityMemberSectionList.module.css';
import type { TSectionListItem, ISectionListProps } from '@ui-kit';
import type { TCommunity, TCommunityMember, TUserProfileRecord } from '@typings';

const CommunityMemberSectionList = ({
	communityId,
	moderatorMembersTitle,
	regularMembersTitle,
	onClick,
	...sectionListProps
}: ICommunityMemberSectionListProps) => {
	const { t } = useTranslation();

	const moderatorMembersQuery = useCommunityModeratorMembersQuery(communityId, { suspense: true });
	const regularMembersInfiniteQuery = useCommunityRegularMembersInfiniteQuery(communityId, { suspense: true });
	const isFetching = moderatorMembersQuery.isFetching || regularMembersInfiniteQuery.isFetching;
	const isLoading = moderatorMembersQuery.isLoading || regularMembersInfiniteQuery.isLoading;
	const data: TSectionListItem<TCommunityMember>[] = useMemo(
		function buildSectionListItems() {
			if (isLoading) return [];
			return compact([
				moderatorMembersQuery.data?.length && {
					title: moderatorMembersTitle,
					data: moderatorMembersQuery.data ?? [],
				},
				regularMembersInfiniteQuery.data.length && {
					title: regularMembersTitle,
					data: regularMembersInfiniteQuery.data ?? [],
				},
			]);
		},
		[isLoading, moderatorMembersQuery.data, regularMembersInfiniteQuery.data],
	);
	const renderUserItem = useCallback(
		(communityMember: TCommunityMember) => (
			<FixedWidthContainer>
				<UserListItem
					className={styles.communityMemberSectionList__userListItem}
					renderUserSubTitle={formatUserSubTitle(t)}
					size="medium"
					userProfileRecord={adaptCommunityMemberToUserProfileRecord(communityMember)}
					onClick={onClick}
				/>
			</FixedWidthContainer>
		),
		[],
	);

	const shouldShowEmptyMembersMessage =
		!!moderatorMembersQuery.data?.length && !regularMembersInfiniteQuery.data.length;

	return (
		<SectionList<TCommunityMember>
			className={styles.communityMemberSectionList__sectionList}
			data={data}
			emptyMessage={t('Sorry, no group members were found.')}
			headerClassName={styles.communityMemberSectionList__header}
			isFetching={isFetching}
			isLoading={isLoading}
			ListFooterComponent={
				shouldShowEmptyMembersMessage ? (
					<FixedWidthContainer>
						<div className={styles.communityMemberSectionList__emptyMembersMessage}>
							<span>{t('Sorry, no group members were found.')}</span>
						</div>
					</FixedWidthContainer>
				) : null
			}
			renderItem={renderUserItem}
			onEndReached={regularMembersInfiniteQuery.fetchMore}
			{...sectionListProps}
		/>
	);
};

export interface ICommunityMemberSectionListProps
	extends Pick<ISectionListProps<TCommunityMember>, 'ListHeaderComponent' | 'ListFooterComponent'> {
	communityId: TCommunity['id'];
	moderatorMembersTitle: string;
	regularMembersTitle: string;
	className?: string;
	onClick: (userProfile: TUserProfileRecord) => void;
}

export default memo(CommunityMemberSectionList);
