import { FEED_COMMENT_ID_DOM_ATTRIBUTE } from '@constants';
import type { RefObject } from 'react';

export const scrollToComment = (scrollerRef: RefObject<HTMLDivElement>, isEditing?: boolean, commentId?: string) => {
	if (!scrollerRef?.current) return;
	const selector = `[${FEED_COMMENT_ID_DOM_ATTRIBUTE}="${commentId}"]`;
	const timeoutTime = isEditing ? 0 : 500; // if a new comment need a time to append new child in a list.

	setTimeout(() => {
		const commentElement = document.querySelector(selector);
		if (!commentElement) {
			// scroll to very bottom.
			scrollerRef.current?.scrollTo(0, scrollerRef.current?.scrollHeight);
			return;
		}
		// scroll to element in case if element is not in view.
		if (
			commentElement.getBoundingClientRect().bottom > window.innerHeight ||
			commentElement.getBoundingClientRect().top < 0
		) {
			commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, timeoutTime);
};
