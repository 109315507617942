import { useService } from '@services';
import { useQuery, useList, useNotification, useEffect } from '@hooks';
import { uniq, dayjs } from '@utils';
import type { TTag, TTagsType } from '@typings';

export const useTagsQuery = (tagsType: TTagsType, defaultTags: string[] = []) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();

	const [tags, { push: createTag, set }] = useList(defaultTags);
	const { data: gottenTags = [], isLoading: isTagsLoading } = useQuery<TTag[], Error>(
		['profile', 'tags', tagsType],
		async () => {
			switch (tagsType) {
				case 'expertise':
					return await api.expertise.getExpertiseList();
				case 'interests':
					return await api.interest.getInterests();
				case 'investments':
					return await api.investment.getInvestments();
			}
		},
		{
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	useEffect(() => {
		if (!gottenTags.length) return;
		const tagNames = gottenTags.map((tag) => tag.name);
		const newTags = uniq([...defaultTags, ...tagNames]);
		set(newTags);
	}, [gottenTags, defaultTags]);

	return { createTag, tags, isTagsLoading };
};
