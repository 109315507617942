import { Toast } from '@ui-kit';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IToastProps, IToastService } from '../interfaces/ToastService.interface';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';
import { NOTIFICATION_TIME } from '@tiger21-llc/connect-shared/src/constants';
import type { ReactNode } from 'react';

export class ToastService implements IToastService {
	static inject = ['logger'] as const;
	constructor(logger: IDevLogger) {
		this.logger = logger.child('ToastService');
	}

	private logger: IDevLogger;

	showError({ title, subtitle, shouldShowClose, linksDisabled = false, autoClose = true, onClick }: IToastProps) {
		toast(
			({ closeToast }) => (
				<Toast
					linksDisabled={linksDisabled}
					shouldShowClose={shouldShowClose}
					subtitle={subtitle}
					title={title}
					type="error"
					onClose={() => closeToast?.()}
					onPress={onClick}
				/>
			),
			{
				autoClose: autoClose ? NOTIFICATION_TIME : false,
			},
		);
		this.logger.debug('error', title, subtitle);
	}

	showInfo({ title, subtitle, shouldShowClose, linksDisabled = false, autoClose = true, onClick }: IToastProps) {
		toast(
			({ closeToast }) => (
				<Toast
					linksDisabled={linksDisabled}
					shouldShowClose={shouldShowClose}
					subtitle={subtitle}
					title={title}
					type="info"
					onClose={() => closeToast?.()}
					onPress={onClick}
				/>
			),
			{
				autoClose: autoClose ? NOTIFICATION_TIME : false,
			},
		);
		this.logger.debug('info', title, subtitle);
	}

	showSuccess({ title, subtitle, shouldShowClose, linksDisabled = false, autoClose = true, onClick }: IToastProps) {
		toast(
			({ closeToast }) => (
				<Toast
					linksDisabled={linksDisabled}
					shouldShowClose={shouldShowClose}
					subtitle={subtitle}
					title={title}
					type="success"
					onClose={() => closeToast?.()}
					onPress={onClick}
				/>
			),
			{
				autoClose: autoClose ? NOTIFICATION_TIME : false,
			},
		);
		this.logger.debug('success', title, subtitle);
	}

	showCustom(component: (closeToast?: () => void) => ReactNode, options?: ToastOptions) {
		toast(({ closeToast }) => component(closeToast), options);
	}
}
