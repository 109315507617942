import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useCallback, useTranslation } from '@hooks';
import type { TRadioGroupInputOption } from '@ui-kit';
import { DropdownMenu, Gap, RadioGroupInput } from '@ui-kit';
import { dealFormFields } from '../DealForm.schema';
import type { ReactNode } from 'react';
import type { TDropdownOption } from '@ui-kit/components/DropdownMenu';
import type { TDealAssetClass } from '@typings';

export const AssetClassesSectionTemplate = ({
	formProps,
	joinNetworkError,
	isClassSelectorDisabled,
	assetClassOptions,
	assetClasses,
	subClassOptions,
	typesOptions,
	isEdit,
}: IAssetClassesSectionProps) => {
	const { t } = useTranslation();
	const { errors, values, setFieldTouched, setFieldValue, setErrors } = formProps;

	const clearDependentValues = useCallback(() => {
		setFieldValue(dealFormFields.SUB_CLASS, undefined);
		setFieldValue(dealFormFields.TYPE, undefined);
		// Clean up template values while template schema is changing
		setFieldValue(dealFormFields.DYNAMIC_DETAILS, {});
		setFieldValue(dealFormFields.DYNAMIC_FINANCIAL_DETAILS, {});
	}, [setFieldValue]);

	const clearErrorsOnAssetClassChange = useCallback(() => {
		setErrors({
			...errors,
			dealAssetClass: undefined,
			dealSubClass: undefined,
			dealType: undefined,
			// Clean up template errors while template schema is changing
			detailsTemplate: undefined,
			financialDetailsTemplate: undefined,
		});
	}, [errors, setErrors]);

	const onSelectAssetClass = useCallback(
		(id: string) => {
			const assetClass = assetClasses?.find((assetClass) => assetClass['@id'] === id);
			setFieldTouched(dealFormFields.ASSET_CLASS, true);
			setFieldValue(dealFormFields.ASSET_CLASS, id);
			setFieldValue(dealFormFields.COMMUNITY, assetClass?.community);
			clearDependentValues();
			clearErrorsOnAssetClassChange();
		},
		[setFieldValue, clearDependentValues, clearErrorsOnAssetClassChange, assetClasses, setFieldTouched],
	);

	const onChangeSubClass = useCallback(
		(value: string) => {
			setFieldTouched(dealFormFields.SUB_CLASS, true);
			setFieldValue(dealFormFields.SUB_CLASS, value);
			setErrors({
				...errors,
				dealSubClass: undefined,
				dealType: undefined,
				detailsTemplate: undefined,
				financialDetailsTemplate: undefined,
			});
		},
		[setFieldValue, setErrors, errors, setFieldTouched],
	);

	const onChangeType = useCallback(
		(value: string) => {
			setFieldTouched(dealFormFields.TYPE, true);
			setFieldValue(dealFormFields.TYPE, value);
			setErrors({
				...errors,
				dealType: undefined,
				detailsTemplate: undefined,
				financialDetailsTemplate: undefined,
			});
		},
		[setFieldValue, setErrors, errors, setFieldTouched],
	);

	return (
		<DealFormSection title={t('Asset Classes')}>
			<DropdownMenu
				customError={joinNetworkError}
				disabled={isClassSelectorDisabled}
				errorMessage={errors.dealAssetClass}
				iconType={'clear'}
				label={t('Asset class')}
				options={assetClassOptions}
				placeholder={t('Type an asset class')}
				showSearchIcon
				value={values.dealAssetClass}
				variant="medium"
				onChange={(value) => onSelectAssetClass(value)}
			/>
			{values.dealAssetClass && !errors.dealAssetClass && !!subClassOptions.length && (
				<>
					<Gap gap={24} />
					<RadioGroupInput
						backgroundColor="white"
						disabled={isEdit}
						errorMessage={errors.dealSubClass}
						label={t('Sub-class')}
						labelType="bold"
						options={subClassOptions}
						value={values.dealSubClass}
						onChange={onChangeSubClass}
					/>
				</>
			)}
			{values.dealSubClass && !!typesOptions.length && (
				<>
					<Gap gap={24} />
					<RadioGroupInput
						backgroundColor="white"
						disabled={isEdit}
						errorMessage={errors.dealType}
						label={t('Type')}
						labelType="bold"
						options={typesOptions}
						value={values.dealType}
						onChange={onChangeType}
					/>
				</>
			)}
		</DealFormSection>
	);
};

interface IAssetClassesSectionProps extends IDealFormSectionSectionProps {
	joinNetworkError?: ReactNode;
	isClassSelectorDisabled?: boolean;
	assetClassOptions: TDropdownOption<string>[];
	assetClasses?: TDealAssetClass[];
	subClassOptions: TRadioGroupInputOption<string>[];
	typesOptions: TRadioGroupInputOption<string>[];
}
