import { useEvent, useService } from '@hooks';
import { useSelector } from 'react-redux';
import type { IChatMessageDraft } from '../../../services/implementations/ReduxService/slices/chatMessageDrafts.slice';

export const useDraftAPI = (channelId: string) => {
	const redux = useService('ReduxService');
	const chatMessageDraftsActions = redux.useBindActionCreators(redux.chatMessageDrafts);

	const draftMessage = useSelector(redux.selectMessageDraft(channelId));

	const updateMessageDraft = useEvent((draft: IChatMessageDraft) =>
		chatMessageDraftsActions.updateMessageDraft({ channelId, draft }),
	);
	const removeMessageDraft = useEvent(() => chatMessageDraftsActions.removeMessageDraft(channelId));

	return { draftMessage, updateMessageDraft, removeMessageDraft };
};
