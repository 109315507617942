import {
	Button,
	EditIcon,
	BookmarkIcon,
	VisuallyHidden,
	ActivityIndicator,
	FixedWidthContainer,
	SearchableScreenHeader,
} from '@ui-kit';
import { ROUTES, BOOKMARKS_TAB } from '@constants';
import { NetworkConnectionEntity, textRegisterPluralize } from '@utils';

import { useMe, useNavigate, useTranslation } from '@hooks';
import NetworkConnectionsFilterBar from '@ui-modules/connections/components/NetworkConnectionsFilterBar';
import NetworkConnectionsSortWidget from '@ui-modules/connections/components/NetworkConnectionsSortWidget';

import styles from './NetworkConnectonsWidgets.module.css';

import type { HTMLAttributes, LegacyRef } from 'react';
import type { TCommunity, TNetworkConnectionQuerySort, TNetworkConnectionSortOption } from '@typings';

/** Component that renders network connections widgets including filters and sort options. */
const NetworkConnectionsWidgets = ({
	name,
	floatingHeaderClassName,
	floatingHeaderRef,
	showSearchBar,
	isFiltersLoading,
	communityId,
	networkConnectionsName,
	searchQuery,
	setSearchQuery,
	setIsSearchFocused,
	setSelectedSortOption,
	selectedSortOption,
	sortOptions,
	sortDisabled,
}: INetworkConnectionsWidgetsProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { user } = useMe();
	const openNewPostPage = () => navigate(ROUTES.createNetworkConnection(communityId));

	return (
		<div className={styles.networkConnectionsSelectWidget__floatingHeaderContainer}>
			<div className={floatingHeaderClassName} ref={floatingHeaderRef}>
				<FixedWidthContainer className={styles.networkConnectionsSelectWidget__fixedWidthContainer}>
					<SearchableScreenHeader
						autoSizeDimensions={{
							horizontal: !showSearchBar,
							vertical: true,
						}}
						customButtonsElement={
							<>
								<Button
									icon={<BookmarkIcon fill="#000000" height={14} width={14} />}
									iconPosition="left"
									title={t('Bookmarks')}
									variant="small"
									onClick={() => navigate(ROUTES.networkTab(communityId, 'connections', false, BOOKMARKS_TAB))}
								/>
								{NetworkConnectionEntity.canCreate(user) && (
									<Button
										icon={<EditIcon fill="#000000" height={14} width={14} />}
										iconPosition="left"
										title={t('New')}
										variant="small"
										onClick={openNewPostPage}
									/>
								)}
							</>
						}
						placeholder={`${t('Search')} ${textRegisterPluralize(networkConnectionsName)}`}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						title={textRegisterPluralize(networkConnectionsName)}
						onChangeSearchQuery={setSearchQuery}
						onFocus={() => setIsSearchFocused(true)}
						onSearchCanceled={() => setIsSearchFocused(false)}
					/>
				</FixedWidthContainer>

				<VisuallyHidden hidden={showSearchBar}>
					{isFiltersLoading ? (
						<ActivityIndicator />
					) : (
						<>
							<NetworkConnectionsFilterBar
								communityId={communityId}
								name={name}
								networkConnectionsName={networkConnectionsName}
							/>
							<div className={styles.networkConnectionsSelectWidget__sorterWrapper}>
								<NetworkConnectionsSortWidget
									selectedOption={selectedSortOption}
									sortDisabled={sortDisabled}
									sortOptions={sortOptions}
									onSelect={(option) => setSelectedSortOption(option)}
								/>
							</div>
						</>
					)}
				</VisuallyHidden>
			</div>
		</div>
	);
};

export interface INetworkConnectionsWidgetsProps {
	/** Floating header ref */
	name: string;
	/** Floating header ref */
	floatingHeaderRef: LegacyRef<HTMLDivElement>;
	/** Floating header classname for custom styles */
	floatingHeaderClassName: HTMLAttributes<HTMLDivElement>['className'];
	/** Parent community ID */
	communityId: TCommunity['id'];
	/** Boolean that determines whether the search is expanded or not */
	showSearchBar: boolean;
	/** Boolean that determines whether the filters are loading or not */
	isFiltersLoading?: boolean;
	/** Name of the network connections */
	networkConnectionsName: string;
	/** Search query string */
	searchQuery: string;
	/** Function that sets the search query */
	setSearchQuery: (query: string) => void;
	/** Function that sets the search "is focused" state.*/
	/** Function to set the focused state of the search field. */
	setIsSearchFocused: (isFocused: boolean) => void;
	/** Function to set the selected sorting option for network connections. */
	setSelectedSortOption: (option: TNetworkConnectionQuerySort) => void;
	/** Holds the currently selected sorting option for network connections. */
	selectedSortOption: TNetworkConnectionQuerySort;
	/** Array of sorting options available for network connections. */
	sortOptions: TNetworkConnectionSortOption[];
	/** Optional flag to disable the sorting functionality. */
	sortDisabled?: boolean;
}

export default NetworkConnectionsWidgets;
