import type { TDealCreatorType } from '@typings';
import type { TFunction } from 'i18next';

export const getDealCreatorTypeDescription =
	(t: TFunction) =>
	(dealCreatorType: TDealCreatorType): string => {
		switch (dealCreatorType) {
			case 'owner':
				return t('Deal owner');
			case 'investor':
				return t('Investing in this deal');
			case 'referrer':
				return t('Sharing deal for discussion');
			default:
				return '';
		}
	};
