import { useState } from 'react';
import { CloseIcon, VideoAttachmentIcon } from '@ui-kit';
import PostAttachment from '@ui-modules/feed/components/PostAttachment';
import { extractFileNameFromStreamUrl, downloadFile } from '@utils';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import styles from './MessageInputAttachmentPreview.module.css';
import type { TAttachments } from '@typings';

const MessageInputAttachmentPreview = ({ attachment, onClosePressed }: IMessageInputAttachmentPreview) => {
	const [showControls, setShowControls] = useState(false);
	const showCloseIcon = !attachment?.url?.startsWith('blob');
	const pdfViewer = usePdfViewer();

	const handleMouseEnter = () => {
		setShowControls(true);
	};

	const handleMouseLeave = () => {
		setShowControls(false);
	};

	const videoAttachment = (
		<div key={attachment?.url}>
			<video
				className={styles.attachment__videoThumbnail}
				controls={showControls} // Show controls when showControls is true
				height="50"
				key={attachment?.url}
				width="50"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<source key={attachment?.url} src={attachment?.url} />
				<track default key={attachment?.url} kind="captions" src={attachment?.url} srcLang="en" />
			</video>

			<VideoAttachmentIcon className={styles.attachment__videoIcon} height={16} width={16} />
		</div>
	);

	return (
		<div className={styles.attachmentContainer}>
			{attachment?.fileType === 'video' || attachment?.fileType === 'image' ? (
				<div className={styles.attachment}>
					{showCloseIcon ? (
						<button
							className={styles.attachment__button}
							onClick={() => {
								attachment && onClosePressed(attachment);
							}}
						>
							<CloseIcon height={9} width={9} />
						</button>
					) : null}
					{attachment?.fileType === 'image' ? (
						<button className={styles.attachment__buttonImg}>
							<img alt={attachment?.url} className={styles.attachment} src={attachment.url} />
						</button>
					) : (
						videoAttachment
					)}
				</div>
			) : null}
			{attachment?.fileType === 'file' ? (
				<div className={styles.attachment__fileContainer}>
					<PostAttachment
						editable={!showCloseIcon}
						file={attachment}
						onDelete={onClosePressed}
						onFileClick={() => {
							if (attachment.mediaType === 'application/pdf') pdfViewer.open(String(attachment.url));
							else downloadFile(String(attachment.url), extractFileNameFromStreamUrl(String(attachment.url)));
						}}
					/>
				</div>
			) : null}
		</div>
	);
};
export default MessageInputAttachmentPreview;

export interface IMessageInputAttachmentPreview {
	attachment?: TAttachments;
	onClosePressed: (attachment: TAttachments) => void;
}
