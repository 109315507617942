export const handleVideoSizeViaUrls = (urls: string[]) => {
	let totalSize = 0;

	for (const url of urls) {
		const http = new XMLHttpRequest();
		http.open('HEAD', url, false);
		http.send(null);

		if (http.status === 200) {
			const fileSize = http.getResponseHeader('content-length');
			if (fileSize) {
				totalSize += Number(fileSize);
			}
		}
	}

	// Convert to megabytes
	const totalSizeInMegabytes = totalSize * 1e-6;
	return totalSizeInMegabytes;
};
