import { clsx, noop } from '@utils';
import styles from './RadioButton.module.css';
import type { ReactNode } from 'react';

const RadioButton = ({
	disabled,
	errored,
	value,
	size = 'medium',
	children,
	backgroundColor = 'transparent',
	onChange,
	customLabelWrapper = '',
}: IRadioButtonProps) => {
	const handleChange = () => {
		onChange(!value);
	};

	return (
		<label
			className={clsx(styles.radioButton, disabled && styles.radioButton_disabled)}
			role="none"
			onClick={(event) => event.stopPropagation()}
			onKeyPress={(event) => event.stopPropagation()}
		>
			<input
				checked={value}
				className={styles.radioButton__input}
				disabled={disabled}
				type="radio"
				onChange={noop} // To prevent warning "You provided a `checked` prop to a form field without an `onChange` handler." [@dmitriy.nikolenko]
				onClick={handleChange}
			/>
			<div
				className={clsx(
					styles.radioButton__icon,
					errored && styles.radioButton__icon_errored,
					disabled && styles.radioButton__icon_disabled,
				)}
				data-color={backgroundColor}
				data-size={size}
			>
				{value ? <div className={styles.radioButton__iconMark} data-testid="radioButton__iconMark" /> : null}
			</div>
			<div className={customLabelWrapper}>{children}</div>
		</label>
	);
};

export type TRadioButtonSize = 'medium' | 'small';
export interface IRadioButtonProps {
	value: boolean;
	/** If true forbids change value.*/
	disabled?: boolean;
	/** Highlights input with red color. */
	errored?: boolean;
	/** Title of the radio button displayed on the right side. */
	children?: ReactNode;
	/** Size of the radio icon. Default "medium". Medium equals to 25px, small = 15px. */
	size?: TRadioButtonSize;
	/** Background color of radio icon */
	backgroundColor?: 'white' | 'transparent';
	/** Returns a new checked value */
	onChange: (newValue: boolean) => void;
	/** Custom wrapper for the including text */
	customLabelWrapper?: string;
}

export default RadioButton;
