import { Page } from '../../components';
import { useTranslation, useParams, useSearchParams, useCurrentLocation } from '@hooks';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { DealForm, getEditInitialValues } from '@ui-modules/deals/components/DealForm';
import { useGetDealTemplateQuery } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { extractIdFromIri, noop } from '@utils';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { ROUTES } from '@constants';
import { Navigate } from '@ui-kit';

const EditDealPage = () => {
	const { t } = useTranslation();
	const { currentLocation } = useCurrentLocation();
	const dealId = useParams<IEditDealPageParams>().dealId as IEditDealPageParams['dealId'];
	const sourceCommunityId = useSearchParams()[0].get('sourceCommunityId');
	const { data: deal, isLoading } = useGetDealQuery(dealId);
	const { data: dealTemplate } = useGetDealTemplateQuery(
		noop,
		extractIdFromIri(deal?.dealAssetClass?.['@id'] || ''),
		extractIdFromIri(deal?.dealSubClass?.['@id'] || ''),
		extractIdFromIri(deal?.dealType?.['@id'] || ''),
	);
	useDealsDisabledEffect();
	const { enabled } = useDealsEnabled();

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;

	return (
		<Page scrollDisabled tabName={sourceCommunityId ? 'networks' : 'invest'} title={t('New Deal Page')}>
			<DealForm
				dealId={dealId}
				editValues={getEditInitialValues(deal, dealTemplate, currentLocation.callingCode)}
				isLoading={isLoading}
				status={deal?.status}
			/>
		</Page>
	);
};

interface IEditDealPageParams extends Record<string, string | undefined> {
	dealId: string;
}

export default EditDealPage;
