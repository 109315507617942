import PdfViewer from './PdfViewer';
import { createContext, useContext, useRef } from 'react';
import type { PropsWithChildren, RefObject } from 'react';
import type { IPdfViewerApi } from './PdfViewer';

const PdfViewerContext = createContext<RefObject<IPdfViewerApi>>({
	current: {
		open: () => console.error('PdfViewerProvider not found'),
		close: () => console.error('PdfViewerProvider not found'),
	},
});

export const PdfViewerProvider = ({ children }: PropsWithChildren) => {
	const pdfViewerRef = useRef<IPdfViewerApi>(null);

	return (
		<PdfViewerContext.Provider value={pdfViewerRef}>
			{children}
			<PdfViewer ref={pdfViewerRef} />
		</PdfViewerContext.Provider>
	);
};

export const usePdfViewer = () => useContext(PdfViewerContext).current as IPdfViewerApi;
