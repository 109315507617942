import styles from './InputErrorMessage.module.css';
import { clsx } from '@utils';

/** Error message red text showed under a form input field.
 * 	Has minimum space works as gap between elements and prevents shifts of elements when message appeared.
 */
const InputErrorMessage = ({ hidden, text, keepMarginBottom }: IInputErrorMessageProps) => {
	return hidden ? null : (
		<div
			className={clsx(styles.inputErrorMessage, keepMarginBottom && text && styles.inputErrorMessage_keepMarginBottom)}
		>
			{text ? <small className={styles.inputErrorMessage__text}>{text}</small> : null}
		</div>
	);
};

export interface IInputErrorMessageProps {
	/** Copy of error message. */
	text?: string;
	/** By default the error message place is always visible and uses for displaying without layout shift. If 'true' after appearing an error message the margin between message and bottom will appear. Default 'false'. */
	keepMarginBottom?: boolean;
	hidden?: boolean;
}

export default InputErrorMessage;
