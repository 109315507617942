import { AccountMenu, Page, PageLayout } from '../../components';
import { useMe, useTranslation } from '@hooks';
import { TIGER_PULSE_LINK } from '@constants';
import BaseStaticIFrame from '@ui-modules/account/components/BaseStaticIFrame';

const TigerPulsePage = () => {
	const { t } = useTranslation();
	const { user } = useMe();

	return (
		<Page title={t('TIGER Pulse')}>
			<PageLayout containerMode={false} sidebarContent={<AccountMenu />}>
				<BaseStaticIFrame title={t('TIGER Pulse')} url={TIGER_PULSE_LINK} userId={user.id} />
			</PageLayout>
		</Page>
	);
};

export default TigerPulsePage;
