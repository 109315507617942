import { Page } from '../../components';
import { useTranslation } from '@hooks';
import styles from './TrustedDevicePage.module.css';
import { Gap, PhoneShakingIcon } from '@ui-kit';
import { useResendTrustedDeviceNotification } from '@ui-modules/notifications/hooks/useResendTrustedDeviceNotification';

const TrustedDevicePage = () => {
	const { t } = useTranslation();
	const number = 32; // TODO update with real data;
	const { mutateAsync: resendTrustedDeviceNotification } = useResendTrustedDeviceNotification();

	return (
		<Page title={t('Trusted Device')}>
			<div className={styles.container}>
				<h2 className={styles.title}>{t('We Don’t Recognize This Device')}</h2>
				<Gap gap={32} />
				<PhoneShakingIcon />
				<Gap gap={16} />
				<span className={styles.description}>
					{t('Check your phone.')} <br /> {t('Select the matching number.')}
				</span>
				<div className={styles.number}>{number}</div>
				<span className={styles.description}>
					{t('Didn’t receive the notification?')}
					<span
						className={styles.button}
						onClick={() => resendTrustedDeviceNotification()}
						onKeyDown={() => resendTrustedDeviceNotification()}
					>
						{' '}
						{t('Resend')}
					</span>
				</span>
			</div>
		</Page>
	);
};

export default TrustedDevicePage;
