import { ADDRESS_BOOK_ITEM_CLASS_KEY, ROUTES } from '@constants';
import { useLocation, useNavigate, useMemo } from '@hooks';

const addressBookPageParams = 'ADDRESS_BOOK_PAGE_PARAMS';
const addressBookLocationKey = 'ADDRESS_BOOK_LOCATION_KEY';

export const useAddressBook = (setPageSavedData: (isMyContacts: boolean) => void) => {
	const location = useLocation();
	const navigate = useNavigate();

	const saveScrollIndex = ({ onlyMyContacts, profileId, searchQuery }: IAddressBookPageProps) => {
		// get second visible list item parent element (the first one is hidden over header content)
		const secondVisibleItem = document.getElementsByClassName(ADDRESS_BOOK_ITEM_CLASS_KEY)[1]?.parentElement;
		if (secondVisibleItem && !searchQuery) {
			// get visible item index that saved by react-virtuoso in dataset
			const position = Number(secondVisibleItem.dataset.itemIndex) || 0;
			sessionStorage.setItem(addressBookPageParams, JSON.stringify({ position, onlyMyContacts }));
		} else {
			sessionStorage.setItem(addressBookPageParams, JSON.stringify({ position: 0, onlyMyContacts }));
		}
		navigate(ROUTES.contact(profileId));
	};

	const initialPosition = useMemo(() => {
		let initPosition = 0;
		const storageData = sessionStorage.getItem(addressBookPageParams);
		const { position = 0, onlyMyContacts = false } = storageData ? JSON.parse(storageData) : {};
		const locationKey = sessionStorage.getItem(addressBookLocationKey);

		if (location.key !== locationKey) {
			sessionStorage.removeItem(addressBookPageParams);
			sessionStorage.setItem(addressBookLocationKey, location.key);
			return;
		}
		if (position > 1) {
			initPosition = position;
		}
		setPageSavedData(onlyMyContacts);
		sessionStorage.setItem(addressBookLocationKey, location.key);
		return initPosition;
	}, [location]);

	return {
		saveScrollIndex,
		initialPosition,
	};
};

interface IAddressBookPageProps {
	onlyMyContacts: boolean;
	profileId: string;
	searchQuery: string;
}
