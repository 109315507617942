import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

export function checkIdInArray(ids: string[], arr: ICheckmarkState[], replaceValue = '') {
	const updatedArr = [...arr];

	// Loop through each ID in the array
	for (const id of ids) {
		// Loop through each item in the array
		for (const element of updatedArr) {
			const item = element;
			// Check if the current item's value is true
			if (item.id === id.replace(replaceValue, '')) {
				// If there is a match, update the value to true
				// item.value = true;
				item.value = true;
			}
		}
	}

	return updatedArr;
}
