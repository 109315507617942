import Button from '../Button';
import HeaderSearchInput from '../HeaderSearchInput';
import { SearchIcon } from '../../icons';
import { useRef, useState } from '@hooks';
import styles from './SearchableScreenBar.module.css';
import type { ReactNode } from 'react';

const SearchableScreenBar = ({
	children,
	searchTitle,
	searchQuery,
	placeholder,
	onChangeSearchQuery,
	onFocus,
	onBlur,
	onSearchOpened,
	noSearch,
}: ISearchableScreenBarProps) => {
	const [isExpanded, setIsExpanded] = useState(!!searchQuery.length);
	const inputRef = useRef<HTMLInputElement>(null);

	const expandSearch = () => {
		setIsExpanded(true);
		if (onSearchOpened) onSearchOpened(true);
		setTimeout(() => {
			inputRef.current && inputRef.current.focus();
		}, 0);
	};

	const onSearchCleared = () => {
		onChangeSearchQuery('');
	};

	const onSearchCanceled = () => {
		inputRef.current?.blur();
		onBlur?.();
		setIsExpanded(false);
		if (onSearchOpened) onSearchOpened(false);
		onChangeSearchQuery('');
	};

	return (
		<div className={styles.searchHeader}>
			{isExpanded ? (
				<div className={styles.searchableBar__input}>
					<HeaderSearchInput
						placeholder={placeholder}
						ref={inputRef}
						type="cleared"
						value={searchQuery}
						onBlur={() => {
							onBlur?.();
						}}
						onChange={(e) => onChangeSearchQuery(e.target.value)}
						onFocus={() => {
							onFocus?.();
						}}
						onSearchCanceled={onSearchCanceled}
						onSearchCleared={onSearchCleared}
					/>
				</div>
			) : (
				<div className={styles.searchableBar__controller}>
					<div>
						{noSearch ? null : (
							<Button
								icon={<SearchIcon className={styles.searchableBar__buttonIcon} height={12} width={12} />}
								title={searchTitle}
								variant="small"
								onClick={expandSearch}
							/>
						)}
					</div>
					<div className={styles.searchableBar__buttons}>{children}</div>
				</div>
			)}
		</div>
	);
};

export interface ISearchableScreenBarProps {
	children: ReactNode;
	searchTitle: string;
	searchQuery: string;
	placeholder: string;
	onChangeSearchQuery: (searchQuery: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onSearchOpened?: (open: boolean) => void;
	noSearch?: boolean;
}

export default SearchableScreenBar;
