import { AbstractReduxService } from '../../abstracts/AbstractReduxService';
import type { TReducerActions } from '../../abstracts/AbstractReduxService';
import type { IStorage } from '../../interfaces/Storage.interface';
// Slices:
import { globalSearchStateSlice } from './slices/globalSearchState.slice';
import { globalSearchHistorySlice } from './slices/globalSearchHistory.slice';
import { meSlice } from './slices/me.slice';
import { signInSlice } from './slices/signIn.slice';
import { calendarSlice } from './slices/calendar.slice';
import { profileTagsSlice } from './slices/profileTags.slice';
import { crossPromotionsSlice } from './slices/crossPromotions.slice';
import { settingsSlice } from './slices/settings.slice';
import { chatSlice } from './slices/chat.slice';
import { chatMessageDraftsSlice, chatMessageDraftsSelectors } from './slices/chatMessageDrafts.slice';
import { userPreferencesSlice } from './slices/userPreferences.slice';
import { usersGroupsSlice } from './slices/usersGroups';
import { chatChannelFlaggedMessagesSlice } from './slices/chatChannelFlaggedMessages.slice';
import { networkConnectionFilterSlice } from './slices/networkConnectionFilter.slice';
import { authSlice } from './slices/auth.slice';
import { broadcastSlice } from './slices/broadcast.slice';

/** Implementation of Redux Global Storage. */
export class ReduxService extends AbstractReduxService<TRootState, TAllActions> {
	static inject = ['LocalStorageService'] as const;
	constructor(readonly localStorage: IStorage) {
		const rootReducer = ReduxService.makeRootReducer(localStorage);
		super(rootReducer, {
			whitelist: [
				'auth/saveTokensResponse',
				'me/saveAccount',
				'userPreferences/setPersistShowingAppOnTablet',
				'broadcast/signOut',
			],
			broadcastChannelOption: { type: 'localstorage' },
		});
	}

	// Configuration.
	static makeRootReducer = (localStorage: IStorage) =>
		AbstractReduxService.combineReducers({
			globalSearchState: globalSearchStateSlice.reducer,
			globalSearchHistory: AbstractReduxService.persistReducer(
				{
					key: 'globalSearchHistory',
					storage: localStorage,
				},
				globalSearchHistorySlice.reducer,
			),
			crossPromotionsForm: crossPromotionsSlice.reducer,
			me: AbstractReduxService.persistReducer(
				{
					key: 'me',
					storage: localStorage,
					version: 2,
				},
				meSlice.reducer,
			),
			auth: AbstractReduxService.persistReducer(
				{
					key: 'authSlice',
					storage: localStorage,
				},
				authSlice.reducer,
			),
			broadcast: broadcastSlice.reducer,
			signIn: AbstractReduxService.persistReducer(
				{
					key: 'signIn',
					storage: localStorage,
					version: 2,
				},
				signInSlice.reducer,
			),
			calendar: calendarSlice.reducer,
			chat: chatSlice.reducer,
			chatMessageDrafts: AbstractReduxService.persistReducer(
				{
					key: 'chatMessageDrafts',
					storage: localStorage,
				},
				chatMessageDraftsSlice.reducer,
			),
			chatChannelFlaggedMessages: chatChannelFlaggedMessagesSlice.reducer,
			profileTags: profileTagsSlice.reducer,
			settings: settingsSlice.reducer,
			userPreferences: AbstractReduxService.persistReducer(
				{
					key: 'userPreferences',
					storage: localStorage,
				},
				userPreferencesSlice.reducer,
			),
			usersGroups: usersGroupsSlice.reducer,
			networkConnectionFilter: networkConnectionFilterSlice.reducer,
		});

	// Actions.
	globalSearchState = globalSearchStateSlice.actions;
	globalSearchHistory = globalSearchHistorySlice.actions;
	crossPromotionsForm = crossPromotionsSlice.actions;
	me = meSlice.actions;
	auth = authSlice.actions;
	broadcast = broadcastSlice.actions;
	signIn = signInSlice.actions;
	calendar = calendarSlice.actions;
	profileTags = profileTagsSlice.actions;
	chat = chatSlice.actions;
	chatMessageDrafts = chatMessageDraftsSlice.actions;
	settings = settingsSlice.actions;
	userPreferences = userPreferencesSlice.actions;
	usersGroups = usersGroupsSlice.actions;
	chatChannelFlaggedMessages = chatChannelFlaggedMessagesSlice.actions;
	networkConnectionFilter = networkConnectionFilterSlice.actions;

	// Selectors.
	selectMessageDraft = chatMessageDraftsSelectors.selectMessageDraft;
}

export type TRootState = ReturnType<ReturnType<typeof ReduxService.makeRootReducer>>;

type TAllActions = TReducerActions<typeof meSlice.actions, 'me'> &
	TReducerActions<typeof authSlice.actions, 'auth'> &
	TReducerActions<typeof authSlice.actions, 'broadcast'> &
	TReducerActions<typeof signInSlice.actions, 'signIn'> &
	TReducerActions<typeof calendarSlice.actions, 'calendar'> &
	TReducerActions<typeof profileTagsSlice.actions, 'profileTags'> &
	TReducerActions<typeof chatSlice.actions, 'chat'> &
	TReducerActions<typeof chatMessageDraftsSlice.actions, 'chatMessageDrafts'> &
	TReducerActions<typeof settingsSlice.actions, 'settings'> &
	TReducerActions<typeof settingsSlice.actions, 'userPreferences'> &
	TReducerActions<typeof usersGroupsSlice.actions, 'usersGroups'> &
	TReducerActions<typeof chatChannelFlaggedMessagesSlice.actions, 'chatChannelFlaggedMessages'> &
	TReducerActions<typeof usersGroupsSlice.actions, 'usersGroups'> &
	TReducerActions<typeof networkConnectionFilterSlice.actions, 'networkConnectionFilter'>;
