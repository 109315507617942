import { useService, useQuery, useTranslation } from '@hooks';
import { INVEST_NETWORKS_TAB_SLUG } from '@constants';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TCommunityTabConfig } from '@typings';

export const useNetworkTabsQuery = (
	queryOptions?: UseQueryOptions<TCommunityTabConfig[], Error, TCommunityTabConfig[]>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { t } = useTranslation();

	return useQuery<TCommunityTabConfig[], Error, TCommunityTabConfig[]>(
		reactQuery.queryKeys.getNetworkTabs(),
		() => api.community.getNetworkTabs(),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
			select: (lifestyleTabs) => {
				const nonEmptyLifestyleTabs = lifestyleTabs.filter((tab) => tab.visibleCount > 0);
				const sortedLifestyleTabs = nonEmptyLifestyleTabs.sort((a, b) => a.label.localeCompare(b.label));
				const investTab: TCommunityTabConfig = {
					slug: INVEST_NETWORKS_TAB_SLUG,
					label: t('Invest'),
					visibleCount: Number.MAX_SAFE_INTEGER, // it is determined dynamically when the networks page is loaded.
				};
				const allTabs = [...sortedLifestyleTabs, investTab];
				return allTabs;
			},
			...queryOptions,
		},
	);
};
