import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDealType } from '@typings';

export const useGetDealTypesQuery = (dealSubClassId?: string[] | string) => {
	const api = useService('ApiService');
	return useQuery<TDealType[], Error>(
		['dealType.getTypes', dealSubClassId],
		() => {
			return dealSubClassId ? api.dealType.getTypes(dealSubClassId as string) : [];
		},
		{
			enabled: !!dealSubClassId?.length,
		},
	);
};
