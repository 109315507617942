import { useService } from '@hooks';
import { difference } from '@utils';

export const useTrackDealFiltersChanged = () => {
	const analytics = useService('AnalyticsService');

	return function trackFilterChanged(initialFilters: TFilterValue[], finalFilters: TFilterValue[]) {
		const initialFilterValues = getActiveFilterValues(initialFilters);
		const finalFilterValues = getActiveFilterValues(finalFilters);
		const filterValuesOn = difference(finalFilterValues, initialFilterValues);
		const filterValuesOff = difference(initialFilterValues, finalFilterValues);

		analytics.trackEvent('InvestFilterChanged', {
			filter_values_on: filterValuesOn,
			filter_values_off: filterValuesOff,
		});
	};
};

/** Returns only checked/set/active values of filters values and sorted alphabetically. */
function getActiveFilterValues(filters: TFilterValue[]) {
	return filters
		.reduce<string[]>((acc, item) => {
			if (item.value) {
				acc.push(item.label);
			}
			return acc;
		}, [])
		.sort();
}

type TFilterValue = { label: string; value: boolean };
