import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CROSS_PROMOTIONS_INITIAL_STATE } from '@constants';
import type { TAttachments, TCrossPromotionsData } from '@typings';

export const initialState: TCrossPromotionsData & { attachments?: TAttachments[] } = CROSS_PROMOTIONS_INITIAL_STATE;

export const crossPromotionsSlice = createSlice({
	name: 'crossPromotionsForm',
	initialState,
	reducers: {
		setCrossPromotionsForm: (state, { payload: crossPromotionsData }: PayloadAction<TCrossPromotionsData>) => {
			state.author = crossPromotionsData.author;
			state.network = crossPromotionsData.network;
			state.learnCategoryId = crossPromotionsData.learnCategoryId;
			state.postDateType = crossPromotionsData.postDateType;
			state.scheduleDate = crossPromotionsData.scheduleDate;
			state.postType = crossPromotionsData.postType;
			state.event = crossPromotionsData.event;
			state.linkType = crossPromotionsData.linkType;
			state.link = crossPromotionsData.link;
			state.buttonTitle = crossPromotionsData.buttonTitle;
			state.imageLocalUri = crossPromotionsData.imageLocalUri;
			state.imageFile = crossPromotionsData.imageFile;
			state.expireDate = crossPromotionsData.expireDate;
			state.disableNotification = crossPromotionsData.disableNotification;
			state.selectedActivity = crossPromotionsData.selectedActivity;
			state.adVideo = crossPromotionsData.adVideo;
			state.includeButton = crossPromotionsData.includeButton;
		},
		resetCrossPromotionsForm: (state) => {
			state.author = initialState.author;
			state.network = initialState.network;
			state.learnCategoryId = initialState.learnCategoryId;
			state.postDateType = initialState.postDateType;
			state.scheduleDate = initialState.scheduleDate;
			state.postType = initialState.postType;
			state.event = initialState.event;
			state.linkType = initialState.linkType;
			state.link = initialState.link;
			state.buttonTitle = initialState.buttonTitle;
			state.imageLocalUri = initialState.imageLocalUri;
			state.imageFile = initialState.imageFile;
			state.expireDate = initialState.expireDate;
			state.attachments = initialState.attachments;
			state.disableNotification = initialState.disableNotification;
			state.selectedActivity = initialState.selectedActivity;
			state.adVideo = initialState.adVideo;
			state.includeButton = initialState.includeButton;
			state.isInitialFormDirty = false;
		},
		setCrossPromotionsAttachments: (
			state,
			{ payload: { attachments, isFormDirty } }: PayloadAction<{ isFormDirty: boolean; attachments: TAttachments[] }>,
		) => {
			state.attachments = attachments;
			state.isInitialFormDirty = isFormDirty;
		},
	},
});
