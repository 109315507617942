import { useMemo } from 'react';
import { flattenDeep } from 'lodash';

import { arrangeNetworksForFilter, checkIdInArray, checkIdInNestedArray } from '@utils';

import { useGetDealTypesWithIdsQuery } from '@ui-modules/deals/hooks/useGetDealTypesWithIdsQuery';
import { useGetSubClassesWithIdsQuery } from '@ui-modules/deals/hooks/useGetSubClassesWithIdsQuery';

import type { TDealAssetClass, TDealFilter, TDealSubClass } from '@typings';

import useAllDealFilterConfig from './useAllDealFilterConfigQuery';

import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { getUniqueDealTypes } from '../utils/getUniqueDealTypes';

export const useSelectedSubClasses = (checkMarks: ICheckmarkState[]) => {
	return useMemo(
		() =>
			flattenDeep(
				checkMarks?.map((item) =>
					item.children?.map((child) => {
						return child.value && `/api/deal_sub_classes/${child.id}`;
					}),
				),
			)
				.filter((item) => !!item)
				.sort(),
		[checkMarks],
	);
};

export const useSelectedDealTypes = (types: ICheckmarkState[]) => {
	return useMemo(
		() => flattenDeep(types?.map((item) => item.value && `/api/deal_types/${item.id}`)).filter((item) => !!item),
		[types],
	);
};

export const useParseAllDealFilters = ({
	initialDealSubClasses,
	optionIndex,
	selectedSubClasses,
	initialDealTypes,
}: IUseParseDealFilters) => {
	const {
		data: allDealFilterConfig,
		isLoading: isAllDealFilterConfigLoading,
		isFetched: isAllDealFilterConfigFetched,
	} = useAllDealFilterConfig();

	const initialCheckMarks = useMemo(() => {
		return checkIdInNestedArray(
			initialDealSubClasses as string[],
			arrangeNetworksForFilter(
				allDealFilterConfig?.assetClasses as TDealAssetClass[],
				!initialDealSubClasses?.length,
				true,
			),
			'/api/deal_sub_classes/',
		);
	}, [allDealFilterConfig?.assetClasses, initialDealSubClasses]);

	const allDealTypes = arrangeNetworksForFilter(allDealFilterConfig?.dealTypes as TDealAssetClass[], true, false);

	const { data: appliedSubClasses, isLoading: isDealSubClassesLoading } = useGetSubClassesWithIdsQuery(
		selectedSubClasses?.map((item) => item?.replace('/api/deal_sub_classes/', '')) as string[],
	);

	// Selected deal types based on subClasses [@hrant]
	const availableDealTypes = useMemo(
		() =>
			flattenDeep(
				appliedSubClasses?.map((item: TDealSubClass) => item?.dealTypes?.map((dealType: string) => dealType)),
			).filter((item: string) => !!item),
		[appliedSubClasses],
	);

	// Understanding what eligible deal types do we have for current options [@hrant]
	const dealTypeIds = useMemo(() => {
		if (optionIndex === 0 || availableDealTypes.length === 0) {
			return allDealTypes.map((item) => item.id);
		} else {
			return availableDealTypes.map((item) => item?.replace('/api/deal_types/', ''));
		}
	}, [allDealTypes, optionIndex, availableDealTypes]);

	const {
		data: dealTypesData,
		isLoading: isDealTypeLoading,
		isRefetching: isDealTypeRefetching,
	} = useGetDealTypesWithIdsQuery(dealTypeIds);

	const dealTypes = useMemo(() => {
		return checkIdInArray(
			initialDealTypes as string[],
			arrangeNetworksForFilter(getUniqueDealTypes(dealTypesData as TDealAssetClass[]), false, false),
			'/api/deal_types/',
		);
	}, [initialDealTypes, dealTypesData]);

	return {
		initialCheckMarks,
		dealTypes: !!selectedSubClasses.length && optionIndex === 1 && !availableDealTypes.length ? [] : dealTypes,
		isLoading: isAllDealFilterConfigLoading && isDealSubClassesLoading,
		isDealTypeLoading: isDealTypeLoading || isDealTypeRefetching,
		allDealTypes,
		readyToRender: isAllDealFilterConfigFetched,
	};
};

export interface IUseParseDealFilters {
	initialDealTypes: TDealFilter['dealTypes'];
	initialDealSubClasses: TDealFilter['dealSubClasses'];
	optionIndex: number;
	selectedSubClasses: string[];
}
