import CryptoES from 'crypto-es';
import type { ICryptoConfig } from '../interfaces/AppConfig.interface';

export class CryptoService {
	static inject = ['AppConfigService'];
	constructor(appConfig: ICryptoConfig) {
		this.#cryptoSecret = CryptoES.enc.Utf8.parse(appConfig?.CRYPTO_SECRET);
	}

	#cryptoSecret: CryptoES.lib.WordArray;
	#iv: CryptoES.lib.WordArray = CryptoES.enc.Utf8.parse('0000000000000000'); // empty iv

	encryptAes256Cbc(textToEncrypt: string): string {
		const parsedText = CryptoES.enc.Utf8.parse(textToEncrypt);

		const encrypted = CryptoES.AES.encrypt(parsedText, this.#cryptoSecret, {
			mode: CryptoES.mode.CBC,
			padding: CryptoES.pad.Pkcs7,
			iv: this.#iv,
		});
		return encrypted.toString();
	}

	decryptAes256Cbc(encryptedText: string): string {
		const ciphertext = CryptoES.enc.Base64.parse(encryptedText);

		const decrypted = CryptoES.AES.decrypt({ ciphertext }, this.#cryptoSecret, {
			mode: CryptoES.mode.CBC,
			padding: CryptoES.pad.Pkcs7,
			iv: this.#iv,
		});
		const decryptedText = decrypted.toString(CryptoES.enc.Utf8);

		return decryptedText;
	}
}
