import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';

export const useMyInvestNetworksQuery = () => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery(queryKeys.getMyInvestNetworks(), async () => await api.community.getMyInvestNetworks(), {
		staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
	});
};
