import { Button, FileListItem, Gap, Input, Formik } from '@ui-kit';
import { useDownloadEncryptedFileMutation } from '@ui-modules/files/hooks/useDownloadEncryptedFileMutation';
import { useTranslation } from '@hooks';
import { downloadEventFileFormSchema } from '@schemas';
import { extractMetaFromMediaObject } from '@ui-kit/components/FileListItem/FileListItem';
import { FileEntity } from '@utils';
import styles from './DownloadEncryptedFileForm.module.css';
import type { TFile } from '@typings';
import type { TMediaObject } from '@typings';

const DownloadEncryptedFileForm = ({ file, onClose, onOpenFile }: IDownloadEncryptedFileFormProps) => {
	const { mutateAsync: download, isLoading } = useDownloadEncryptedFileMutation(file, onClose, onOpenFile);
	const { t } = useTranslation();

	const isFileToOpenInViewer = FileEntity.isPdfFile(file);

	return (
		<Formik
			initialValues={{ password: '' }}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={downloadEventFileFormSchema(t)}
			onSubmit={(values, helpers) => download({ eventFileForm: values, formHandlers: helpers })}
		>
			{(formProps) => {
				const { values, handleChange, handleBlur, errors, isSubmitting, submitForm } = formProps;
				return (
					<>
						<FileListItem
							meta={extractMetaFromMediaObject(file.mediaObject as TMediaObject)}
							type="fit-card"
							onRemove={undefined}
						/>
						<Gap gap={16} />
						<Input
							autoCapitalize="none"
							disabled={isSubmitting}
							errorMessage={errors.password}
							label={t('Password')}
							placeholder={t('Password')}
							secureTextEntry
							value={values.password}
							onBlur={handleBlur('password')}
							onChange={handleChange('password')}
						/>
						<div className={styles.downloadFileForm__buttons}>
							<Button title={t('Cancel')} type={'clear'} onClick={onClose} />
							<Button
								disabled={isLoading}
								iconPosition={'right'}
								loading={isLoading}
								title={isFileToOpenInViewer ? t('Open') : t('Download')}
								onClick={submitForm}
							/>
						</div>
					</>
				);
			}}
		</Formik>
	);
};

export interface IDownloadEncryptedFileFormProps {
	file: TFile;
	onClose: () => void;
	onOpenFile: (url: Blob) => void;
}

export default DownloadEncryptedFileForm;
