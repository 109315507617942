import type { IDealStatus, TDeal, TMediaObject } from '@typings';
import { dealTemplateSectionSlug } from '@typings';
import { useCreateDealMutation } from '@ui-modules/deals/hooks/useCreateDealMutation';
import type { TDealFormValues } from '../DealForm.schema';
import { compact } from '@utils';
import { collectDealContentWhichMayHasUrls } from '@ui-modules/deals/utils/collectDealContentWhichMayHasUrls';
import { useCreateDealFilesMutation } from '@ui-modules/deals/hooks/useCreateDealFilesMutation';
import { useCheckTextOnMaliciousUrls, useNotification, useService, useTranslation } from '@hooks';
import { useUpdateDealMutation } from '@ui-modules/deals/hooks/useUpdateDealMutation';
import { useDeleteDealFileMutation } from '@ui-modules/deals/hooks/useDeleteDealFileMutation';
import type { FormikHelpers } from 'formik';
import { useSendThirdPartyFormMutation } from '@ui-modules/deals/hooks/useSendThirdPartyFormMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';

export const useSubmitDeal = (onNavigateBack: () => void, dealId?: string, status?: IDealStatus) => {
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');
	const { mutateAsync: createDeal } = useCreateDealMutation();
	const { mutateAsync: updateDeal } = useUpdateDealMutation();
	const { mutateAsync: sendThirdPartyForm } = useSendThirdPartyFormMutation();
	const { mutateAsync: createDealFiles } = useCreateDealFilesMutation();
	const { mutateAsync: deleteFile } = useDeleteDealFileMutation();
	const { showUnknownError, showSuccess, showInfo } = useNotification();
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		dealId: dealId as string,
		folderId: null,
	});
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });

	const onDealSaved = async (
		deal: TDeal,
		owner: string,
		files: TMediaObject[],
		isEdit = false,
		deletedFiles: string[],
		makeFileReadOnlyActions: TMediaObject['id'][],
		makeFileDownloadableActions: TMediaObject['id'][],
		isThirdParty: boolean,
	) => {
		for (const fileId of deletedFiles) {
			await deleteFile(fileId);
		}
		for (const fileId of makeFileReadOnlyActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: true });
		}
		for (const fileId of makeFileDownloadableActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: false });
		}
		await createDealFiles(
			{ files, owner, relatedEntity: deal['@id'], isDealInformationFile: true },
			{
				onSuccess: () => {
					if (isThirdParty) {
						if (isEdit) {
							showInfo({ title: t('Deal Updated') });
						} else {
							showInfo({
								title: t('Deal Sent for Review'),
								subtitle: t('{{userName}} will be notified.', { userName: deal?.contactName }),
							});
						}
					} else {
						if (isEdit) {
							showSuccess({ title: t('Deal Updated') });
						} else {
							showSuccess({ title: t('Deal Published') });
						}
					}
					// Updating cash with edited deal to prevent long time list updated.
					reactQuery.updateDealsCache(deal.id, () => deal);
					// left refetching deals as they can change order depending on sorting.
					reactQuery.refetchDealsQueries(deal.community.id);
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealFiles(dealId as TDeal['id'], null));
					onNavigateBack();
				},
				onError: (error) => showUnknownError(error),
			},
		);
	};

	const updateDealInCache = (deal: TDeal) => {
		reactQuery.updateDealsCache(deal?.id, (_, index, dealsArr) => {
			if (!dealsArr || index === undefined) return;
			dealsArr[index] = deal;
		});
	};

	return async (
		{
			detailsTemplate,
			financialDetailsTemplate,
			webLink1,
			webLink2,
			webLink3,
			title,
			minInvestment,
			files = [],
			deletedFiles = [],
			makeFileReadOnlyActions = [],
			makeFileDownloadableActions = [],
			creator,
			videoUrl,
			creatorOption,
			priorExperience,
			...values
		}: TDealFormValues,
		formikHelpers: FormikHelpers<TDealFormValues>,
	) => {
		try {
			const detailsTemplateSection = detailsTemplate ? Object.values(detailsTemplate) : [];
			const financialDetailsTemplateSection = financialDetailsTemplate ? Object.values(financialDetailsTemplate) : [];
			const defaultMinInvestment = creatorOption === 'third-party' ? null : 0;

			const data = {
				...values,
				name: title,
				creator: creator.iriId,
				minInvestment: Number.parseInt(minInvestment?.replaceAll(',', ''), 10) || defaultMinInvestment,
				webUrls: compact([webLink1, webLink2, webLink3]),
				videoUrl: videoUrl?.length ? videoUrl : null,
				priorExperience: priorExperience?.trim()?.length ? priorExperience : null,
				data: {
					body: {
						sections: [
							detailsTemplateSection?.length
								? {
										section_slug: dealTemplateSectionSlug.DEAL_DETAILS,
										fields: detailsTemplateSection,
								  }
								: null,
							financialDetailsTemplateSection.length
								? {
										section_slug: dealTemplateSectionSlug.FINANCIAL_DETAILS,
										fields: financialDetailsTemplateSection,
								  }
								: null,
						].filter((el) => el !== null),
					},
				},
			};

			await checkTextOnMaliciousUrls(collectDealContentWhichMayHasUrls(data));

			if (dealId) {
				const deal = await updateDeal(
					{ id: dealId, deal: data },
					{
						onError: (error) => {
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
						onSuccess: updateDealInCache,
					},
				);
				await onDealSaved(
					deal,
					creator.iriId,
					files,
					true,
					deletedFiles,
					makeFileReadOnlyActions,
					makeFileDownloadableActions,
					deal.status === 'draft',
				);
			} else {
				let dealStatus = 'draft';
				if (creatorOption !== 'third-party' && values.creatorType === 'owner') {
					dealStatus = 'published';
				}
				await createDeal(
					{ ...data, status: dealStatus },
					{
						onSuccess: async (deal) => {
							await onDealSaved(
								deal,
								creator.iriId,
								files,
								false,
								deletedFiles,
								makeFileReadOnlyActions,
								makeFileDownloadableActions,
								dealStatus === 'draft',
							);
							// send form to the 3rd party
							if (dealStatus === 'draft') {
								await sendThirdPartyForm({ id: deal.id, email: deal.contactEmail });
							}
						},
						onError: (error) => {
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
			}
		} catch (error) {
			showUnknownError(error as Error);
		} finally {
			formikHelpers.setSubmitting(false);
		}
	};
};
