import type { TFunction } from 'i18next';

export const getDealFilterStatus = (t: TFunction, filter: string | null) => {
	switch (filter) {
		case 'published':
			return t('Open');
		case 'closed':
			return t('Closed');
		default:
			return;
	}
};
