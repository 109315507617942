const mainComparativeChartTheme = {
	labelColor: '#FFFFFF',
	labelBackgroundColor: '#597CBD',
	lineColor: '#597CBD',
	graphBackgroundColor: 'linear-gradient(270deg, #597CBD 5%, #DBE8FF 100%)',
};
const secondaryComparativeChartTheme = {
	labelColor: '#8E723B',
	labelBackgroundColor: 'transparent',
	lineColor: '#8E723B',
	graphBackgroundColor: 'linear-gradient(90deg, #E0D4BA 0%, #BC9D58 95%)',
};

export { mainComparativeChartTheme, secondaryComparativeChartTheme };
