import { MAX_POST_IMAGE_HEIGHT } from '@constants';
import type { TAttachments } from '@typings';
import { CloseIcon, FileListItem, Gap } from '@ui-kit';
import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';
import { makeFileFromAttachment, makeFileFromUrl } from '@ui-kit/components/Post/Post.utils';
import { clsx } from '@utils';
import styles from './PostAttachment.module.css';
import ApiVideoSelectorPreview from '@ui-modules/crossPromotion/components/ApiVideoSelectorPreview';

const PostAttachment = ({
	file,
	editable,
	mediaHeight = MAX_POST_IMAGE_HEIGHT,
	onDelete,
	onImageClick = () => null,
	onFileClick = () => null,
}: IPostAttachmentProps) => {
	const fileData = editable ? makeFileFromUrl(file.url || '') : makeFileFromAttachment(file);
	return (
		<div
			className={clsx(
				file.fileType !== 'file' && file.fileType !== 'video' && styles.attachment__image,
				file.fileType === 'file' && styles.attachment__file,
				file.fileType === 'video' && styles.attachment__video,
			)}
			style={
				file.fileType === 'file' || file.fileType === 'video' ? {} : { height: mediaHeight, width: mediaHeight * 1.5 }
			}
		>
			{editable ? null : (
				<button className={styles.attachment__button} onClick={() => onDelete(file)}>
					<CloseIcon height={8} width={8} />
				</button>
			)}
			{file.fileType === 'image' ? (
				<button className={styles.attachment__buttonImg} onClick={onImageClick}>
					<img alt={file.name} src={file.url} />
				</button>
			) : file.fileType === 'video' && file?.url ? (
				file.url.startsWith('blob') ? (
					<video className={styles.attachment__video} controls width="250">
						<source src={file.url} />
						<track default kind="captions" src={file.url} srcLang="en" />
					</video>
				) : (
					<ApiVideoSelectorPreview
						option={{
							id: file.url,
							picture: file.url,
							uri: file.url,
							created: fileData.createdAt,
						}}
						playerWidth={250}
					/>
				)
			) : (
				<FileListItem meta={extractMetaFromFile(fileData)} type="fit-card" onClick={onFileClick} />
			)}
			<Gap gap={16} />
		</div>
	);
};

interface IPostAttachmentProps {
	file: TAttachments;
	editable?: boolean;
	onDelete: (file: TAttachments) => void;
	onImageClick?: () => void;
	onFileClick?: () => void;
	mediaHeight?: number;
}

export default PostAttachment;
