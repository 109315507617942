import type { TMeatballMenuOption } from '@ui-kit';
import { Card, DeleteIcon, SpacedGroup, EyeIcon, EyeClosedIcon, FileListItem } from '@ui-kit';
import { useCallback, useNotification, useRef, useService, useState, useTranslation } from '@hooks';
import { MEETING_FILES } from '@constants';
import type { TFile } from '@typings';
import EventFilePicker from '../EventFilePicker';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import styles from './EventFileList.module.css';
import { useUploadEventFileMutation } from '@ui-modules/files/hooks/useUploadEventFileMutation';
import { compact } from '@utils';
import DownloadEncryptedFileModal from '@ui-modules/files/components/DownloadEncryptedFileModal';
import DeleteFileForCalendarModal from '@ui-modules/files/components/DeleteFileForCalendarModal';
import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import FileUploadDialogues from '@ui-modules/files/components/FileUploadDialogues';
import type { IFileUploadDialoguesApi } from '@ui-modules/files/components/FileUploadDialogues/FileUploadDialogues';

const EventFileList = ({
	eventFiles,
	canUpload,
	canDelete,
	canMakeDownloadable,
	canMakeViewOnly,
	eventId,
}: IEventFileListProps) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const fileUploadDialoguesRef = useRef<IFileUploadDialoguesApi>(null);

	const [fileToDownload, setFileToDownload] = useState<TFile | undefined>();
	const [deletingFileId, setDeletingFileId] = useState<string | null>(null);
	const { showUnknownError } = useNotification();
	const pdfViewer = usePdfViewer();

	const { mutate: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({ eventId });
	const { uploadFile, isLoading } = useUploadEventFileMutation(
		eventId,
		undefined,
		fileUploadDialoguesRef.current?.closeDialogues,
	);

	const onItemClick = useCallback((file: TFile) => {
		try {
			if (file.mediaObject.encrypted) {
				setFileToDownload(file);
			} else {
				if (file.mediaObject.mimeType === 'application/pdf') {
					pdfViewer.open(file);
				} else {
					api.mediaObject.downloadNonEncryptedFileUrl(file);
				}
			}
		} catch (error) {
			showUnknownError(error as Error);
		}
	}, []);

	const renderFileItem = useCallback((file: TFile) => {
		const fileMenuOptions: TMeatballMenuOption[] | undefined = compact([
			canMakeViewOnly(file) && {
				text: t('Make Read Only'),
				icon: <EyeClosedIcon className={styles.eventFileList__actionIcon} height={16} width={14} />,
				onClick: () =>
					updateMediaObjectSetViewerOnly({
						fileId: file.mediaObject.id,
						isViewerOnly: true,
					}),
				type: 'solid',
			},
			canMakeDownloadable(file) && {
				text: t('Make Downloadable'),
				icon: <EyeIcon className={styles.eventFileList__actionIcon} height={16} width={14} />,
				onClick: () =>
					updateMediaObjectSetViewerOnly({
						fileId: file.mediaObject.id,
						isViewerOnly: false,
					}),
				type: 'solid',
			},
			canDelete(file) && {
				text: t('Delete File'),
				icon: <DeleteIcon className={styles.eventFileList__deleteIcon} height={16} width={14} />,
				onClick: () => setDeletingFileId(file.id),
				type: 'destructive',
			},
		]);

		return (
			<FileListItem
				fileMenuOptions={fileMenuOptions}
				key={file.name}
				meta={extractMetaFromFile(file)}
				openInViewerOnly={file.mediaObject.openInViewerOnly}
				viewerModeText={t('read only')}
				onClick={() => {
					onItemClick(file);
				}}
			/>
		);
	}, []);

	if (!eventFiles.length && !canUpload) return null;
	return (
		<Card backgroundPlacement="none" title={t('Files')}>
			<>
				{fileToDownload && (
					<DownloadEncryptedFileModal
						file={fileToDownload}
						onClose={() => setFileToDownload(undefined)}
						onOpenFile={(fileUrl) => {
							pdfViewer.open(fileUrl, fileToDownload.mediaObject.openInViewerOnly);
						}}
					/>
				)}
				<FileUploadDialogues
					isUploading={isLoading}
					passwordFormSubTitle={t(
						'Only share this password with your members. TIGER 21 will never have access to this password. Password-protected files are automatically removed after 7 days.',
					)}
					ref={fileUploadDialoguesRef}
					onUploadFile={uploadFile}
				/>
				{deletingFileId ? (
					<DeleteFileForCalendarModal
						eventId={eventId}
						fileId={deletingFileId}
						visible={!!deletingFileId}
						onClose={() => setDeletingFileId(null)}
					/>
				) : null}
			</>
			<SpacedGroup className={styles.eventFileList__content} direction="vertical" gap={10}>
				<span className={styles.eventFileList__title}>
					{t('Secure files are automatically removed {{numberOfDays}} days after the meeting', {
						numberOfDays: MEETING_FILES.daysToRemove,
					})}
				</span>
				{canUpload ? (
					<EventFilePicker onUpload={(fileToUpload) => fileUploadDialoguesRef.current?.openDialogue(fileToUpload)} />
				) : null}
				<SpacedGroup direction="vertical" gap={10}>
					{eventFiles.map((file) => renderFileItem(file))}
				</SpacedGroup>
			</SpacedGroup>
		</Card>
	);
};

export interface IEventFileListProps {
	canUpload: boolean;
	canDelete: (file: TFile) => boolean;
	canMakeDownloadable: (file: TFile) => boolean;
	canMakeViewOnly: (file: TFile) => boolean;
	eventFiles: TFile[];
	eventId: string;
}

export default EventFileList;
