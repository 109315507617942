import { useMutation, useService } from '@hooks';
import type { TDeal } from '@typings';

export const useSendDealPreviewMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useMutation<TDeal, Error, any>(
		['deal.sendDealPreview'],
		(data: { id: string; deal: any }) => api.invest.sendDealPreview(data.id, data.deal),
		{
			onSuccess: (deal) => {
				reactQuery.refetchDealsQueries(deal.community.id);
			},
		},
	);
};
