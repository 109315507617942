import { extractFileNameFromStreamUrl } from '@tiger21-llc/connect-shared/src/utils';

export const downloadFile = (fileUri: string, fileName?: string) => {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = fileUri;
	a.download = fileName ?? extractFileNameFromStreamUrl(fileUri);
	document.body.appendChild(a);
	a.click();
};
