import { UserListItem } from '@ui-kit';
import {
	useCallback,
	useTranslation,
	useNavigate,
	useMemo,
	useToggle,
	useUserSessionStorageValue,
	useService,
} from '@hooks';
import { adaptRecommendationMemberToUserProfileRecord, formatUserSubTitle } from '@utils';
import { ROUTES } from '../../../../app/routes';
import type { TRecommendationMember, TUserProfileRecord } from '@typings';
import styles from './AssetAllocationRecommendations.module.css';

const MEMBERS_DISPLAY_AMOUNT = 3;

const RecommendationsMemberBlock = ({ members = [], networkName }: IRecommendationsMemberBlockProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const { storageSavedValue: isMembersSectionExpanded, setStorageSavedValue: setIsMembersSectionExpanded } =
		useUserSessionStorageValue(`assetAllocationNetworks_membersToggle_${networkName}`);

	const [expanded, setExpanded] = useToggle(
		isMembersSectionExpanded ? Boolean(JSON.parse(isMembersSectionExpanded)) : false,
	);

	const handleClick = (member: TUserProfileRecord) => {
		navigate(ROUTES.memberProfile(member.profile.id));
		analytics.trackEvent('AssetAllocationInteractions', {
			interaction_type: 'recommendation_interaction',
			action: 'view_member',
		});
	};

	const onToggleClick = () => {
		setIsMembersSectionExpanded(JSON.stringify(!expanded));
		setExpanded(!expanded);
	};

	const getSeeAllButton = useCallback(() => {
		return (
			<div>
				<div className={styles.expandableBlock__showAllWrapper} onClick={onToggleClick} onKeyDown={onToggleClick}>
					<span>{!expanded ? t('Load more') : t('Show less')}</span>
				</div>
			</div>
		);
	}, [expanded]);

	const renderMembers = useMemo(() => {
		const initialMembers = members.slice(0, MEMBERS_DISPLAY_AMOUNT);
		const hiddenMembers = members.slice(MEMBERS_DISPLAY_AMOUNT);
		return { initialMembers, hiddenMembers };
	}, [members]);

	const memberBlock = (member: TRecommendationMember) => (
		<UserListItem
			avatarSize={64}
			className={styles.collapsible__memberWrapper}
			key={member.id}
			renderUserSubTitle={formatUserSubTitle(t)}
			secondarySubtitleElement={
				<span
					className={styles.collapsible__memberWrapperExpertise}
					dangerouslySetInnerHTML={{ __html: member.expertiseTitle || '' }}
				/>
			}
			size="unset"
			subtitleClassName={styles.collapsible__memberWrapperSubtitle}
			titleClassName={styles.collapsible__memberWrapperName}
			userProfileRecord={adaptRecommendationMemberToUserProfileRecord(member)}
			withSeparator={false}
			onClick={handleClick}
		/>
	);

	return (
		<div className={styles.collapsible__members}>
			{renderMembers.initialMembers.map((member) => memberBlock(member))}
			{expanded && renderMembers.hiddenMembers && renderMembers.hiddenMembers.map((member) => memberBlock(member))}
			{members.length > MEMBERS_DISPLAY_AMOUNT && getSeeAllButton()}
		</div>
	);
};

export default RecommendationsMemberBlock;

interface IRecommendationsMemberBlockProps {
	members: TRecommendationMember[];
	networkName: string;
}
