import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TLeadsCommunities, TProfile } from '@typings';

export const useProfileLeadsCommunitiesQuery = <T = TLeadsCommunities>(
	profileId: TProfile['id'],
	enabled?: boolean,
	queryOptions?: UseQueryOptions<TLeadsCommunities, Error, T>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TLeadsCommunities, Error, T>(
		queryKeys.getProfileLeadsCommunities(profileId),
		() => api.community.getProfileLeadsCommunities(profileId),
		{
			enabled,
			...queryOptions,
		},
	);
};
