import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import { translateOktaUnsuitableResponse } from '../utils/translateOktaUnsuitableResponse';
import type { UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { TSetNewPasswordForm } from '@schemas';

export const useOktaSetNewPasswordMutation = (options?: UseMutationOptions<void, AxiosError, TSetNewPasswordForm>) => {
	const { t } = useTranslation();
	const okta = useService('OktaService');
	const { showSuccess } = useNotification();

	const setNewPasswordMutation = useMutation<void, AxiosError, TSetNewPasswordForm>(
		['auth.setNewPassword'],
		async ({ password }) => {
			await okta.setNewPassword(password);
		},
		{
			...options,
			onSuccess(data, variables, context) {
				showSuccess({ title: t('Password is reset successfully') });
				options?.onSuccess?.(data, variables, context);
			},
		},
	);

	const submitError = translateOktaUnsuitableResponse(t)(setNewPasswordMutation.error);

	return {
		...setNewPasswordMutation,
		submitError,
	};
};
