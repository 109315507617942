import  { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { useToggle, useClickAway, useRef, useMemo } from '@hooks';
import { EyeIcon } from '@ui-kit';
import styles from './CountrySelect.module.css';
import type { Country } from 'react-phone-number-input';
import { FLAG_BASE_URL, TOP_COUNTRIES_SEARCH } from '@constants';
import { sortBy } from '@utils';

const getCountryFlagUrl = (country: string) => FLAG_BASE_URL.replace('{XX}', country).replace('{xx}', country?.toLowerCase());

const CountrySelect = ({ value, onChange, labels, width = 700, ...rest }: TCountrySelectProps) => {
	const [expanded, toggleExpanded] = useToggle(false);
	const meatballMenuRef = useRef(null);
	useClickAway(meatballMenuRef, () => toggleExpanded(false));

	const countries = useMemo(() => {
		const filteredCountries = getCountries().filter(country => !TOP_COUNTRIES_SEARCH.includes(country))

		const mappedData = (country: string) =>  ({
				value: country,
				name: labels?.[country],
				countryCode: getCountryCallingCode(country as Country),
				flagUrl: getCountryFlagUrl(country),
			})

		return [...TOP_COUNTRIES_SEARCH.map(mappedData), ...sortBy(filteredCountries.map(mappedData), 'name')]
	}, [])

	return (
		<div className={styles.select__container}>
			<div onClick={toggleExpanded} onKeyDown={toggleExpanded} className={styles.button}>
				{value ? <img
				className={styles.flagImg}
					alt={value}
					role={value}
					src={getCountryFlagUrl(value)}
				/> : <EyeIcon className={styles.flagImg} width={16} height={12} />}
			<div className={styles.triangle_bottom} />
			</div>
			{expanded && (
				<div ref={meatballMenuRef} style={{ width }} className={styles.container}>
					{countries.map((country) => (
						<div
							onClick={() => onChange(country.value as Country)}
							onKeyDown={() => onChange(country.value as Country)}
							key={country.value}
							className={styles.countryItem}
						>
							<span>
								<img
								className={styles.flagImg}
									alt={country.value}
									role={country ? undefined : 'presentation'}
									src={country.flagUrl}
								/>
							</span>
							<span className={styles.countryItem__countryName}>{country.name} </span>
							<span className={styles.countryItem__code}>+{country.countryCode}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

type TCountrySelectProps = {
	value: string;
	onChange: (value: Country) => void;
	labels: any;
	width?: number;
};

type TCountryItem = {
	value: string;
	name: string;
	countryCode: string;
	flagUrl: string;
}

export default CountrySelect;
