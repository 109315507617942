import { useTranslation } from '@hooks';
import { Modal } from '@ui-kit';
import type { IModalProps } from '@ui-kit';
import type { TFolder } from '@typings';
import type { IFilesWidgetApiCallbacks } from '../FilesWidgetApiCallbacks.interface';

/** Confirms deleting of a folder and performs it. */
const DeleteFolderModal = ({ visible, folderId, folderName, onClose, onDeleteFolder }: IDeleteFolderModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			subTitle={t('This action will also permanently delete any files in this folder.')}
			title={t('Delete folder “{{folderName}}”?', { folderName })}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				onDeleteFolder(folderId);
				onClose();
			}}
		/>
	);
};

export interface IDeleteFolderModalProps
	extends Pick<IModalProps, 'visible'>,
		Pick<IFilesWidgetApiCallbacks, 'onDeleteFolder'> {
	/** Target Folder ID. */
	folderId: TFolder['id'];
	/** Target Folder name to display in confirmation dialog. */
	folderName: TFolder['name'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default DeleteFolderModal;
