import * as yup from 'yup';
import { useState } from 'reinspect';
import { useEffect, useMe, useMemo, useNavigate, useParams, useTranslation } from '@hooks';
import { ActivityIndicator, EmptyStateMessage, FixedWidthContainer, Formik } from '@ui-kit';

import { noop } from 'lodash';
import { NetworkConnectionUserFilterEntity } from '@utils';
import {
	dateFieldValidation,
	numericAndMoneyFieldValidation,
} from '@tiger21-llc/connect-shared/src/schemas/NetworkConnectionFilterForm.schema';
import { useConnectionFiltersChanged } from '@ui-modules/connections/hooks/useConnectionFiltersChanged';
import { useNetworkConnectionFilterFormRenderer } from '@ui-modules/connections/hooks/useNetworkConnectionFilterFormRenderer';
import { useUpdateNetworkConnectionUserFilterMutation } from '@ui-modules/connections/hooks/useUpdateNetworkConnectionUserFilterMutation';

import NetworkConnectionsFilterForm from '@ui-modules/connections/components/NetworkConnectionsFilterForm';
import NetworkConnectionsFilterTitle from '@ui-modules/connections/components/NetworkConnectionsFilterTitle';

import styles from './NetworkConnectionFiltersPage.module.css';

import { FormPageLayout, Page } from '../../components';

import type { FormikHelpers } from 'formik/dist/types';
import type { TDynamicFilter, TNetworkConnectionUserFilters } from '@typings';
import type { TInitialFilters } from '@ui-modules/connections/hooks/useConnectionFiltersChanged';

const NetworkConnectionFiltersPage = () => {
	const navigate = useNavigate();

	const { user } = useMe();

	const { t } = useTranslation();
	const { id } = useParams<INetworkConnectionFiltersPageParams>();

	const { mutateAsync, isSuccess } = useUpdateNetworkConnectionUserFilterMutation({
		onSuccess: () => navigate(-1),
	});
	const { filters, canRender, networkConnectionsName, latestAppliedFilters, currency } =
		useNetworkConnectionFilterFormRenderer(id as string);
	const { trackFiltersChange } = useConnectionFiltersChanged(networkConnectionsName);

	const validationSchema = yup.object({
		dynamicFields: yup
			.object()
			.nullable(true)
			.shape(
				// @TODO fixme
				filters.dynamicFilters.reduce<Record<string, yup.AnySchema>>((schema, filter: any) => {
					switch (filter.type) {
						case 'numeric':
						case 'money':
							schema[filter.name] = numericAndMoneyFieldValidation;
							break;

						case 'date':
							schema[filter.name] = dateFieldValidation;
							break;
						default:
							break;
					}
					return schema;
				}, {}),
			),
	});

	const [filtersState, setFiltersState] = useState(filters);

	useEffect(() => {
		setFiltersState(filters);
	}, [filters]);

	const onSubmit = async (values: TNetworkConnectionUserFilters) => {
		await mutateAsync({
			community: `/api/communities/${id}`,
			owner: user['@id'],
			filters: NetworkConnectionUserFilterEntity.removeEmptyKeys(values, undefined) || {
				categories: [],
				dynamicFields: {},
			},
		});

		trackFiltersChange(filters as unknown as TInitialFilters /* TODO Hrant should fix 'filters' typings */, values);
	};

	const resetFilters = ({ setValues }: FormikHelpers<TNetworkConnectionUserFilters>) => {
		setFiltersState({
			dynamicFilters: filtersState.dynamicFilters.map((item) => ({
				...(item as object),
				filtered: false,
				filtersApplied: {},
				value: null,
			})),
			categories: filtersState.categories.map((item) => ({
				...item,
				value: false,
				children: item?.children?.map((item) => ({
					...(item as object),
					value: false,
				})),
			})),
		});

		setValues({
			categories: [],
			dynamicFields: {} as TDynamicFilter,
		});
	};

	const hasFilters = !!filters.categories?.length || !!filters.dynamicFilters?.length;
	const initialValues = useMemo(() => latestAppliedFilters, [latestAppliedFilters]);

	if (!canRender)
		return (
			<div>
				<ActivityIndicator color="#000" />
			</div>
		);
	return (
		<Page title={t('Network Connection Filters')}>
			<Formik<TNetworkConnectionUserFilters>
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					return (
						<FormPageLayout
							allowedNextPaths={[]}
							buttonBordered={false}
							customHeaderStyles={styles.header}
							customLeftIcon={<>{t('Cancel')}</>}
							formProps={formProps}
							headerTitle={t('Filter {{networkConnectionsName}}', {
								networkConnectionsName,
							})}
							saveButtonTitle={t('Apply')}
							submitLoading={isSuccess}
							onSuccessSubmit={noop}
						>
							<FixedWidthContainer className={styles.container}>
								{!hasFilters ? (
									<EmptyStateMessage text={t('No Filters Available')} />
								) : (
									<>
										<NetworkConnectionsFilterTitle
											networkConnectionsName={networkConnectionsName}
											resetFilters={() => resetFilters(formProps)}
										/>
										<NetworkConnectionsFilterForm
											currency={currency}
											filters={filtersState}
											formProps={formProps}
											key={JSON.stringify(filtersState)}
										/>
									</>
								)}
							</FixedWidthContainer>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};
export interface INetworkConnectionFiltersPageParams extends Record<string, string | undefined> {
	id: string;
}

export default NetworkConnectionFiltersPage;
