import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TUser } from '@typings';

export const useIsUserPausedQuery = <T = boolean>(
	userSlug: TUser['slug'],
	queryOptions?: UseQueryOptions<boolean, Error, T>,
) => {
	const chat = useService('ChatService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<boolean, Error, T>(
		reactQuery.queryKeys.isChatUserPaused(userSlug),
		async () => Boolean(await chat.isUserPaused(userSlug)),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
