import { clsx } from '@utils';
import styles from './InputLabel.module.css';
import type { ReactNode } from 'react';

/** The common component for displaying title displayed above an input. */
const InputLabel = ({ text, htmlFor, className }: IInputLabelProps) => {
	return text ? (
		<div className={styles.inputLabel}>
			<label className={clsx(styles.inputLabel__text, className)} htmlFor={htmlFor}>
				{text}
			</label>
		</div>
	) : null;
};

export interface IInputLabelProps {
	/** Text of the label. If it is absent the label will not be rendered at all. */
	text: string | ReactNode | undefined;
	/** ID of the input element ot bind it with. */
	htmlFor?: string;
	className?: string;
}

export default InputLabel;
