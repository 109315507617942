import { useCallback, useEffect, useState, Fragment } from 'react';
import { useCollapse, useTranslation } from '@hooks';
import { SwitchButton, Checkbox } from '@ui-kit';
import styles from './ChapterSettingsCard.module.css';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';
import type { TCommunityNotificationSettings } from '@typings';
import { usePostCommunitiesNotificationSettingsMutation } from '@ui-modules/settings/hooks/usePostCommunitiesNotificationSettings';
import SpacedGroup from '@ui-kit/components/SpacedGroup';

const ChapterSettingsCard = ({
	onChangeCollapsed,
	title,
	isDefaultExpanded = false,
	filteredChapters,
	isLoadingOfChaptersSettings,
	isChaptersFetching,
}: IChapterSettingsCardProps) => {
	const { t } = useTranslation();
	const { mutate: postCommunitiesNotificationSettings, isLoading: isPostingCommunitiesNotificationSettings } =
		usePostCommunitiesNotificationSettingsMutation();
	const [chaptersSettingsLocal, setChaptersSettingsLocal] = useState(
		filteredChapters?.map((chapter) => ({ ...chapter, isDisabled: false })),
	);
	const { getCollapseProps, isExpanded, setExpanded } = useCollapse({
		defaultExpanded: isDefaultExpanded,
	});

	useEffect(() => {
		setChaptersSettingsLocal(filteredChapters?.map((chapter) => ({ ...chapter, isDisabled: false })));
	}, [filteredChapters]);

	const resetSettings = useCallback(() => {
		chaptersSettingsLocal?.forEach((chapter) => {
			if (chapter.massEmailAllowed) {
				handleCheckboxChange(chapter, false);
			}
		});
	}, [chaptersSettingsLocal]);

	const handleSwitchChange = useCallback(() => {
		setExpanded(!isExpanded);
		onChangeCollapsed(!isExpanded);
		if (isExpanded) {
			resetSettings();
		}
	}, [isExpanded, setExpanded, onChangeCollapsed, resetSettings]);

	const handleCheckboxChange = (selectedChapter: TCommunityNotificationSettings, newValue: boolean) => {
		setChaptersSettingsLocal((prevChapters) =>
			prevChapters?.map((chap) =>
				chap.communityId === selectedChapter.communityId
					? { ...chap, massEmailAllowed: newValue, isDisabled: true }
					: { ...chap, isDisabled: false },
			),
		);
		postCommunitiesNotificationSettings(
			{
				membershipId: selectedChapter.membershipId!,
				isMassEmailAllowed: newValue,
			},
			{
				onSettled: () => {
					setChaptersSettingsLocal((prevChapters) =>
						prevChapters?.map((chap) =>
							chap.communityId === selectedChapter.communityId ? { ...chap, isDisabled: false } : chap,
						),
					);
				},
			},
		);
	};

	return (
		<div className={styles.chapterSettingsCard}>
			{isLoadingOfChaptersSettings ? (
				<ActivityIndicator />
			) : (
				<Fragment>
					<div className={styles.chapterSettingsCard__header}>
						<h3>{title}</h3>
						<SwitchButton
							disabled={!isExpanded && isChaptersFetching}
							value={isExpanded}
							onChange={handleSwitchChange}
						/>
					</div>
					<SpacedGroup direction="vertical" fullWidth>
						<div
							className={styles.chapterSettingsCard__mainContainer}
							data-testid={`CollapsibleChaptersCard__content`}
							{...getCollapseProps()}
						>
							<div className={styles.chapterSettingsCard__label}>{t('email').toUpperCase()}</div>
							<div className={styles.chapterSettingsCard__list}>
								{chaptersSettingsLocal?.map((chapter) => (
									<Fragment key={chapter.communityId}>
										<div className={styles.chapterSettingsCard__item} key={`item-${chapter.communityId}`}>
											<div>
												{t('Chapter Emails')} - {chapter.communityName}
											</div>
											<Checkbox
												disabled={chapter.isDisabled && isPostingCommunitiesNotificationSettings}
												value={chapter.massEmailAllowed!}
												onChange={() => handleCheckboxChange(chapter, !chapter.massEmailAllowed)}
											/>
										</div>
									</Fragment>
								))}
							</div>
						</div>
					</SpacedGroup>
				</Fragment>
			)}
		</div>
	);
};

interface IChapterSettingsCardProps {
	title: string;
	isDefaultExpanded: boolean | undefined;
	onChangeCollapsed: (isCollapsed: boolean) => void;
	filteredChapters: TCommunityNotificationSettings[] | undefined;
	isLoadingOfChaptersSettings: boolean;
	isChaptersFetching: boolean;
}

export default ChapterSettingsCard;
