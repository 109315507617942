import { useToggle, useTranslation } from '@hooks';
import { Gap } from '@ui-kit';
import { translateFactorType } from '@ui-modules/auth/utils/translateFactorType';
import { without } from '@utils';
import styles from './VerifyFactorTypeSelector.module.css';
import type { AuthenticatorKey } from '@okta/okta-auth-js';

const VerifyFactorTypeSelector = ({
	currentFactorType,
	availableFactorTypes,
	testID,
	onChangeCurrentFactorType,
}: IVerifyFactorTypeSelectorProps) => {
	const { t } = useTranslation();

	const [expanded, toggleExpanded] = useToggle(false);

	const handleExpand = (factorType: AuthenticatorKey) => () => {
		onChangeCurrentFactorType(factorType);
		toggleExpanded();
	};

	return availableFactorTypes.length > 1 ? (
		<div data-testid={testID}>
			{expanded ? (
				<>
					<div>
						<span>{t('Try another way')}</span>
					</div>
					<Gap gap={8} />
					{without(availableFactorTypes, currentFactorType).map((factorType, index) => (
						<div key={factorType + index}>
							<span className={styles.verifyFactorTypeSelector__bullet}>{`•`}</span>
							<span
								className={styles.verifyFactorTypeSelector__link}
								role="button"
								tabIndex={0}
								onClick={handleExpand(factorType)}
								onKeyPress={handleExpand(factorType)}
							>
								{translateFactorType(t)(factorType)}
							</span>
						</div>
					))}
				</>
			) : (
				<span
					className={styles.verifyFactorTypeSelector__link}
					role="button"
					tabIndex={0}
					onClick={toggleExpanded}
					onKeyPress={toggleExpanded}
				>
					{t('Try another way')}
				</span>
			)}
		</div>
	) : null;
};

export interface IVerifyFactorTypeSelectorProps {
	currentFactorType: AuthenticatorKey;
	availableFactorTypes: AuthenticatorKey[];
	testID?: string;
	onChangeCurrentFactorType: (factorType: AuthenticatorKey) => void;
}

export default VerifyFactorTypeSelector;
