import { usePaginatedInfiniteQuery, useService } from '@hooks';
import type { TCommunity, TTNetworkConnectionBookmark } from '@typings';

export const useGetNetworkConnectionBookmarks = (community: TCommunity['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return usePaginatedInfiniteQuery<TTNetworkConnectionBookmark>(
		reactQuery.queryKeys.getNetworkConnectionBookmarks(community as TCommunity['id']),
		async ({ pageParam }) => await api.bookmarks.getNetworkConnectionBookmarks({ community, page: pageParam }),
		{
			enabled: !!community,
		},
	);
};
