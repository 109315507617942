import { useQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { TPinnedBanner } from '@typings';

export const usePinnedBannerQuery = (predicateToFilter: (banner: TPinnedBanner) => boolean) => {
	const api = useService('ApiService');
	const feedService = useService('FeedService');
	const { queryKeys } = useService('ReactQueryService');

	const query = useQuery<TPinnedBanner[], Error>(
		queryKeys.getPinnedBanners(),
		async () => api.streamFeeds.getPinnedBanner(),
		{
			staleTime: dayjs.duration(10, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(1, 'hour').asMilliseconds(),
			async onSuccess(banners) {
				if (banners.length) {
					const needsToBeDeleted = banners.filter((banner) => dayjs(banner.expiredAt).isBefore(dayjs()));
					if (needsToBeDeleted.length) {
						await Promise.all(
							needsToBeDeleted.map((banner) => {
								const bannerId = banner.id;
								return feedService.removePinnedBanner(bannerId as string);
							}),
						);
						await query.refetch();
					}
				}
			},
		},
	);

	const data = query.data?.filter((banner) => predicateToFilter(banner)); // filter appropriate ones for a concrete case.

	return { ...query, data };
};
