import { useMutation } from '@hooks';
import { useService } from '@services';

export const useDeleteDealFileMutation = () => {
	const api = useService('ApiService');
	return useMutation(['invest.deleteDealFile'], (id: string) => api.invest.deleteDealFile(id));
};

export const useDeleteDealFilesMutation = () => {
	const api = useService('ApiService');

	const deleteFiles = async (fileIds: string[]) => {
		return Promise.all(
			fileIds.map((fileId) => {
				return api.invest.deleteDealFile(fileId);
			}),
		);
	};

	return {
		deleteFiles,
	};
};
