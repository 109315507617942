import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import { FileEntity } from '@utils';
import type { FormikHelpers } from 'formik';
import type { TFile } from '@typings';
import type { AxiosError } from 'axios';

export const useDownloadEncryptedFileMutation = (
	file: TFile,
	onSuccess?: () => void,
	onOpenFile?: (url: Blob) => void,
) => {
	const api = useService('ApiService');
	const { t } = useTranslation();
	const { showUnknownError } = useNotification();

	return useMutation<
		void,
		AxiosError,
		{ eventFileForm: { password: string }; formHandlers: FormikHelpers<{ password: string }> }
	>(
		['mediaObject.downloadSecureFile'],
		async ({ eventFileForm: { password } }) => {
			const { token } = await api.mediaObject.getMediaObjectDecryptionToken(file, password);
			const downloadUrl = api.mediaObject.getDownloadEncryptedFileUrl(file, token);
			const fileName = file.name;
			if (FileEntity.isPdfFile(file) && onOpenFile) {
				const link = await api.mediaObject.getDownloadFileUrl(downloadUrl);
				onOpenFile(link);
			} else {
				return api.mediaObject.downloadFile(downloadUrl, fileName);
			}
		},
		{
			onSuccess: () => {
				onSuccess?.();
			},
			onError: (error: AxiosError, { formHandlers }) => {
				if (error && error?.response?.status === 422) {
					formHandlers.setFieldError('password', t('Incorrect password'));
				} else {
					showUnknownError(error);
				}
			},
		},
	);
};
