import { useTranslation } from '@hooks';
import { HighlightedText } from '@ui-kit';
import { clsx, toString } from '@utils';
import styles from './DealPost.module.css';

export const DealPostBodyRow = ({
	title,
	subtitle,
	highlightedText,
	required,
	hidden,
	showPlug,
	preview,
}: IDealPostBodyRowProps) => {
	const { t } = useTranslation();

	if (hidden) return null;
	return (
		<div>
			<span className={styles.dealPost__textTitle}>{title}</span>
			{showPlug && !preview ? (
				required ? (
					<span className={styles.dealPost__text_required}>{t('(incomplete)')}</span>
				) : (
					<span className={styles.dealPost__text_notRequired}>{t('(not specified)')}</span>
				)
			) : (
				<HighlightedText
					className={clsx(styles.dealPost__text, preview && styles.text_highlighted)}
					highlightedText={highlightedText}
				>
					{toString(subtitle)}
				</HighlightedText>
			)}
		</div>
	);
};

interface IDealPostBodyRowProps {
	title: string;
	subtitle?: string;
	hidden?: boolean;
	highlightedText?: string;
	required?: boolean;
	showPlug?: boolean;
	preview?: boolean;
}
