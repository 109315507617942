import { useService, useMutation, useNotification } from '@hooks';
import type { TMediaObject } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useUploadMediaObjectMutation = (
	options?: MutationOptions<TMediaObject, Error, IUseUploadMediaObjectMutationProps>,
) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();

	return useMutation<TMediaObject, Error, IUseUploadMediaObjectMutationProps>(
		['mediaObject.uploadDocument'],
		async ({ file, secretKey, isViewOnly }) => await api.mediaObject.uploadDocument(file, secretKey, isViewOnly),
		{
			...options,
			async onSuccess(mediaObject, variables, context) {
				await options?.onSuccess?.(mediaObject, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

export interface IUseUploadMediaObjectMutationProps {
	file: File;
	secretKey?: string;
	isViewOnly?: boolean;
}
