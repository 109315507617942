import { SUPPORT_LINK } from '@constants';
import { IdxStatus } from '@okta/okta-auth-js';
import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaInvalidCredentialsError extends OktaError {
	constructor() {
		super();
	}

	status!: 'INVALID_CREDENTIALS';
	getMessage(t: TFunction) {
		return {
			title: t('Invalid credentials'),
			subtitle: t(
				'Please try again. You can also reset your password, or email {{supportEmail}} for help signing in.',
				{ supportEmail: SUPPORT_LINK },
			),
		};
	}

	static shouldThrowError(transaction: IdxTransaction) {
		return transaction.status === IdxStatus.PENDING && transaction.nextStep?.name === 'identify';
	}
}
