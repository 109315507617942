import { useMutation, useService } from '@hooks';
import type { TDeal } from '@typings';

export const useUpdateDealMutation = () => {
	const api = useService('ApiService');

	// TODO: Update when back-end is finished
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return useMutation<TDeal, Error, any>(
		['deal.updateDeal'],
		async (data: { id: string; deal: any }) => await api.invest.updateDeal(data.id, data.deal),
	);
};
