import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TRequestNetworkForm } from '@schemas';
import type { TNetworkCreationRequest } from '@typings';

/** Performs request user access to a certain network. */
export const useRequestNetworkMutation = (
	options?: MutationOptions<TNetworkCreationRequest, Error, TRequestNetworkForm>,
) => {
	const api = useService('ApiService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	return useMutation<TNetworkCreationRequest, Error, TRequestNetworkForm>(
		['networkCreationRequest.postNetworkCreationRequestV2'],
		async (requestNetworkForm) =>
			await api.networkCreationRequest.postNetworkCreationRequestV3({
				...requestNetworkForm,
				ownerUserId: user.id,
			}),
		{
			...options,
			onSuccess(networkRequest, variables, context) {
				queryClient.setQueryData(queryKeys.getNetworkCreationRequest(networkRequest.id), networkRequest);
				options?.onSuccess?.(networkRequest, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
