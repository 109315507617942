import { useMe, useService } from '@hooks';
import type { TActivity } from '@typings';
import type { FeedAPIResponse } from 'getstream';

/** Loads activity from Stream and merge it with data in feed cache.
 *  It is used when we need to sync data between feeds after like or edit activity.
 */
export const useUpdateActivityInFeedCache = () => {
	const feedService = useService('FeedService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	/** Using mergingPartialActivity makes sense because GetStream often returns stale activity for getActivity(activityId) call after update through API. */
	return async function updateActivityInFeedCache(
		activityId: TActivity['id'],
		mergingPartialActivity?: Partial<TActivity>,
		feedSlug?: string,
	) {
		const updatedActivity = (await feedService.getActivity(activityId, feedSlug ?? user.slug)) as TActivity;
		if (!updatedActivity) return; // If we try to fetch deal activity we will get an error because 'feedService.getActivity' uses 'user.slug' feed and have no access to deal activity.

		const queriesData = reactQuery.queryClient.getQueriesData<FeedAPIResponse>(reactQuery.queryKeys.getUserFeed());

		for (const [queryKey, cache] of queriesData) {
			if (!cache) continue;

			let cacheChanged = false;
			const maybeNewCacheResults = cache.results.map((cachedActivity) => {
				if (cachedActivity.id !== updatedActivity.id) return cachedActivity;

				cacheChanged = true;
				return { ...updatedActivity, ...mergingPartialActivity };
			}) as FeedAPIResponse['results'];

			if (cacheChanged) {
				const newCache: FeedAPIResponse = { ...cache, results: maybeNewCacheResults };
				reactQuery.queryClient.setQueryData(queryKey, newCache);
			}
		}

		return updatedActivity;
	};
};
