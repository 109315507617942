import { MeatballMenu } from '@ui-kit';
import { useTranslation } from '@hooks';
import { clsx, compact } from '@utils';
import styles from './FeedBanner.module.css';
import type { TMeatballMenuOption } from '@ui-kit';
import type { KeyboardEventHandler, MouseEventHandler } from 'react';
import type { IFeedActivityProps } from '../FeedActivity';

/** Image banner displayed as a activity post or pinned banner of the top of the feed. */
const FeedBanner = ({ imageUrl, link, canDeletePost, className, onClick, onDelete }: IFeedBannerProps) => {
	const { t } = useTranslation();

	const menuOptions: TMeatballMenuOption[] = compact([
		canDeletePost && {
			text: t('Delete post'),
			onClick: () => onDelete(),
			type: 'destructive',
		},
	]);

	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onClick(link);
	};

	return (
		<div
			className={clsx(styles.feedBanner, className)}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			<img alt="pinned banner" className={styles.feedBanner__image} src={imageUrl} />

			{menuOptions?.length ? (
				<div className={clsx(styles.feedBanner__meatballMenu)}>
					<MeatballMenu options={menuOptions} position="left" type="solid" />
				</div>
			) : null}
		</div>
	);
};

export interface IFeedBannerProps {
	/** Path to image. */
	imageUrl: string;
	/** Activity link. */
	link: string;
	/** Whether the post can be deleted. */
	canDeletePost?: boolean;
	/** Click handler. */
	onClick: (link: string) => void;
	/** Called when the delete options from the meatball menu pressed. */
	onDelete: () => void;
	className?: string;
}

export default FeedBanner;
