import { useMessageContext } from 'stream-chat-react';
import { useMe } from '@hooks';
import OtherMessage from '../OtherMessage';
import OwnMessage from '../OwnMessage';

const Message = ({ flagMessageIds = {}, chatLogicIsBlocked, isOneToOneChat }: IMessageProps) => {
	const { message } = useMessageContext();
	const { user } = useMe();
	const isMe = user.slug === message.user?.id;
	const quotedMessage = message?.quoted_message;

	return (
		<>
			{isMe ? (
				<OwnMessage quotedMessage={quotedMessage} />
			) : (
				<OtherMessage
					chatLogicIsBlocked={chatLogicIsBlocked}
					flagMessageIds={flagMessageIds}
					isOneToOneChat={isOneToOneChat}
					quotedMessage={quotedMessage}
				/>
			)}
		</>
	);
};

export default Message;
export interface IMessageProps {
	flagMessageIds?: Record<string, boolean> | null | undefined;
	chatLogicIsBlocked: boolean | null;
	isOneToOneChat: boolean;
}
