import { DeleteIcon, EditIcon } from '@ui-kit';
import { useMe, useMemo, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useFolderQuery } from '@ui-modules/files/hooks/useFolderQuery';
import { compact, FolderEntity } from '@utils';
import type { TCommunity, TFolder } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';

/** Constructs menu options for MeatballMenu component
 *  which are responsible for rename and delete folder methods
 *  depending on a user permissions.
 */
export const useManageFolderMeatballMenuOptions = ({
	folderId,
	communityId,
	onRename,
	onDelete,
}: IUseManageFolderMeatballMenuOptions) => {
	const { user } = useMe();
	const { data: community } = useCommunityQuery(communityId);
	const { data: folder } = useFolderQuery(communityId, folderId);

	const { t } = useTranslation();

	return useMemo<TMeatballMenuOption[] | undefined>(() => {
		if (!folder || !community) return EMPTY_MEATBALL_MENU_OPTIONS;
		const options: TMeatballMenuOption[] = compact([
			FolderEntity.canEditFolder(folder, user, community) && {
				text: t('Rename Folder'),
				icon: <EditIcon fill="#000000" height={16} width={18} />,
				onClick: () => onRename(),
			},
			FolderEntity.canDeleteFolder(folder, user, community) && {
				text: 'Delete Folder',
				icon: <DeleteIcon fill="#fa2f53" height={16} width={14} />,
				onClick: () => onDelete(),
				type: 'destructive',
			},
		]);
		return options.length ? options : undefined;
	}, [folder, community]);
};

const EMPTY_MEATBALL_MENU_OPTIONS: TMeatballMenuOption[] = []; // to have stable reference.

interface IUseManageFolderMeatballMenuOptions {
	/** Target Folder ID. */
	folderId: TFolder['id'];
	/** Target Community ID. */
	communityId: TCommunity['id'];
	/** Invokes when the rename action has been called. */
	onRename: () => void;
	/** Invokes when the delete action has been called. */
	onDelete: () => void;
}
