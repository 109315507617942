import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMemo } from '@hooks';
import styles from './EditProfileBiographyPage.module.css';
import { CheckboxInput, Form, Formik, TextArea } from '@ui-kit';
import { switchArrayItem } from '@utils';
import { ROUTES } from '@constants';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import type { TEditProfileBiography } from '@tiger21-llc/connect-shared/src/schemas/EditProfileBiographyForm.schema';
import { editProfileBiographySchema } from '@tiger21-llc/connect-shared/src/schemas/EditProfileBiographyForm.schema';

const EditProfileBiographyPage = () => {
	const { t } = useTranslation();

	const { profile, mutateAsync } = useUpdateProfileMutation();
	const initialValues: TEditProfileBiography = useMemo(
		() => ({
			biography: profile.biography,
			showOnProfile: !profile.hideProperties.includes('biography'),
		}),
		[profile],
	);

	const onSubmit = async ({ biography, showOnProfile }: TEditProfileBiography) => {
		return await mutateAsync({
			biography,
			hideProperties: switchArrayItem(profile.hideProperties, 'biography', !showOnProfile),
		});
	};

	return (
		<Page title={t('Edit Profile Biography')}>
			<Formik<TEditProfileBiography>
				enableReinitialize
				initialValues={initialValues as TEditProfileBiography}
				validationSchema={editProfileBiographySchema}
				onSubmit={(values) => onSubmit(editProfileBiographySchema.cast(values) as TEditProfileBiography)}
			>
				{(formProps) => {
					const { values, handleChange, handleBlur, setFieldValue } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileBiography()]}
							formProps={formProps}
							headerTitle={t('Biography')}
							saveTrackingName="profile--edit-biography"
						>
							<div className={styles.biography}>
								<div className={styles.biography__container}>
									<Form>
										<span className={styles.biography__label}>{t('Let other members learn about you')}</span>
										<TextArea
											minRows={4}
											value={values.biography}
											onBlur={handleBlur('biography')}
											onChange={handleChange('biography')}
										/>
										<CheckboxInput
											label={t('Show this on my profile')}
											size="medium"
											value={values.showOnProfile}
											onChange={(value) => setFieldValue('showOnProfile', value)}
										/>
									</Form>
								</div>
							</div>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileBiographyPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileBiographyPage;
