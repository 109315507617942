import { FullScreenPageLayout, Page } from '../../components';
import { useTranslation, useNavigate, useParams, useGetProfileQuery } from '@hooks';
import styles from './ProfileEventsPage.module.css';
import { useUserEventAttendancesQuery } from '@ui-modules/profile/hooks/useUserEventAttendancesQuery';
import EventCard from '@ui-modules/profile/components/EventCard';
import type { TEventCompact } from '@typings';
import { ROUTES } from '@constants';
import { ActivityIndicator } from '@ui-kit';
import { getEventId } from '@utils';

const ProfileEventsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { profileId } = useParams<IProfileEventsPageParams>();
	// useGetProfileQuery
	const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery(profileId);
	const { data: attendances, isLoading } = useUserEventAttendancesQuery(profile?.user.id);

	const goToEventOrMeeting = (event: TEventCompact) => {
		const eventId = getEventId(event);
		if (event['@type'] === 'Event') {
			navigate(ROUTES.calendarEvent(eventId));
		} else if (event['@type'] === 'Meeting') {
			navigate(ROUTES.calendarMeeting(eventId));
		}
	};

	return (
		<Page title={t('Profile Events')}>
			<FullScreenPageLayout headerTitle={profile ? t('{{name}} Events', { name: profile.user.firstName }) : ''}>
				<div className={styles.events__container}>
					{isLoading || isProfileLoading ? <ActivityIndicator type="fit" /> : null}
					{attendances?.map((attendance) => (
						<EventCard event={attendance.calendarItem} key={attendance['@id']} onPress={goToEventOrMeeting} />
					))}
				</div>
			</FullScreenPageLayout>
		</Page>
	);
};

export interface IProfileEventsPageParams extends Record<string, string | undefined> {
	profileId: string;
}

export default ProfileEventsPage;
