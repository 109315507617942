import { useEffect, useEvent, useMemo, useTranslation, useNavigate } from '@hooks';
import { useUpcomingGroupsQuery } from '@ui-modules/groups/hooks/useUpcomingGroupsQuery';
import { useMyGroupsQuery } from '@ui-modules/groups/hooks/useMyGroupsQuery';
import { useMyChaptersQuery } from '@ui-modules/groups/hooks/useMyChaptersQuery';
import { useForeignGroupsQuery } from '@ui-modules/groups/hooks/useForeignGroupsQuery';
import { compact } from '@utils';
import { ROUTES } from '@constants';
import type { TCommunity, TCommunityCompact } from '@typings';
import { useAllChaptersQuery } from './useAllChaptersQuery';

/** Performs API requests to retrieve all groups and compose to render via SectionList. */
export const useGroupQueriesBreakdown = (
	searchQuery: string,
	onFirstGroupLoaded: (firstLoadedGroup: TCommunityCompact | undefined) => void,
) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// queries
	const myGroupsQuery = useMyGroupsQuery();
	const myChaptersQuery = useMyChaptersQuery();
	const upcomingGroupsQuery = useUpcomingGroupsQuery();
	const foreignGroupsQuery = useForeignGroupsQuery();
	const allChaptersQuery = useAllChaptersQuery();

	// data
	const myGroups = myGroupsQuery.data ?? EMPTY_DATA;
	const myChapters = myChaptersQuery.data ?? EMPTY_DATA;
	const upcomingGroups = upcomingGroupsQuery.data ?? EMPTY_DATA;
	const foreignGroups = foreignGroupsQuery.data ?? EMPTY_DATA;
	const allChapters = allChaptersQuery.data ?? EMPTY_DATA;

	// titles
	const MY_GROUPS_TITLE = t('My Group', { count: myGroups.length });
	const CHAPTERS_TITLE = myChapters.length ? t('My Chapter', { count: myChapters.length }) : t('Chapters');
	const UPCOMING_GROUPS_TITLE = t("Groups we're building");
	const ALL_GROUPS_TITLE = t('All Groups');

	// states
	const queries = [foreignGroupsQuery, myGroupsQuery, myChaptersQuery, upcomingGroupsQuery, allChaptersQuery];
	const isLoading = queries.some((query) => query.isLoading);
	const isSuccess = queries.every((query) => query.isSuccess);
	const isFetching = queries.some((query) => query.isFetching);
	const isRefetching = queries.some((query) => query.isRefetching);

	// functions
	const refetch = useEvent(() => {
		queries.forEach((query) => query.refetch());
	});
	const goToChapters = () => navigate(ROUTES.chapters());

	/** Build a composition of all groups data to pass to SectionList.item property. */
	const sectionListItems = useMemo(() => {
		if (!isSuccess) return [];
		const myGroupsFiltered = filterGroups(myGroups, searchQuery);
		const chaptersFiltered = filterGroups(myChapters, searchQuery);
		const upcomingGroupsFiltered = filterGroups(upcomingGroups, searchQuery);
		const foreignGroupsFiltered = filterGroups(foreignGroups, searchQuery);

		return compact([
			myGroupsFiltered.length && { title: MY_GROUPS_TITLE, data: myGroupsFiltered },
			// should show 'all chapters' even if there are no my chapters but hide if search returns no chapters
			(searchQuery.length ? chaptersFiltered.length : allChapters.length) && {
				title: CHAPTERS_TITLE,
				link: {
					title: t('All Chapters'),
					onClick: goToChapters,
				},
				data: chaptersFiltered,
			},
			upcomingGroupsFiltered.length && {
				title: UPCOMING_GROUPS_TITLE,
				data: upcomingGroupsFiltered,
				subTitle: t('Know someone who would make a great member? Nominate them for one of these groups!'),
			},
			foreignGroupsFiltered.length && { title: ALL_GROUPS_TITLE, data: foreignGroupsFiltered },
		]);
	}, [searchQuery, myGroups, upcomingGroups, foreignGroups, myChapters, allChapters, t, isLoading, CHAPTERS_TITLE]);

	useEffect(() => {
		if (isSuccess) {
			const firstLoadedGroup = sectionListItems?.[0]?.data?.[0];
			onFirstGroupLoaded(firstLoadedGroup);
		}
	}, [isSuccess, sectionListItems]);

	return {
		myGroups,
		chapters: myChapters,
		upcomingGroups,
		foreignGroups,
		sectionListItems,
		isLoading,
		isFetching,
		isRefetching,
		refetch,
		fetchMore: foreignGroupsQuery.fetchMore,
		isFetchingMore: foreignGroupsQuery.isRefetching,
		hasNextPage: foreignGroupsQuery.hasNextPage,
		MY_GROUPS_TITLE,
		CHAPTERS_TITLE,
		UPCOMING_GROUPS_TITLE,
		ALL_GROUPS_TITLE,
	};
};

const EMPTY_DATA: TCommunity[] = [];

/** To filter groups with passed search query. */
function filterGroups(groups: TCommunityCompact[], searchQuery = '') {
	if (!searchQuery.length) return groups;
	return groups.filter((group) => group.name.toLowerCase().includes(searchQuery.toLowerCase()));
}
