import { useMutation, useService } from '@hooks';

export const useSendThirdPartyFormMutation = () => {
	const api = useService('ApiService');

	return useMutation<void, Error, { id: string; email: string }>(
		['deal.sendThirdPartyForm'],
		({ id, email }: { id: string; email: string }) => api.invest.sendThirdPartyForm(id, email),
	);
};
