import { useMemo, useService, useTranslation } from '@hooks';
import { useGetDealFilesQuery } from '@ui-modules/deals/hooks/useGetDealFilesQuery';
import type { TDeal } from '@typings';
import { DealPostBodyRow } from '../DealPost/DealPostBodyRow';
import FilesSection from '@ui-modules/files/components/FilesSection';
import { useGetDealPreviewFilesQuery } from '@ui-modules/deals/hooks/useGetDealPreviewFilesQuery';

/** List of deal files.  */
const DealFiles = ({ dealId, dealName, isDraftDeal, dealHasPreview, isPreviewMode }: IDealFilesProps) => {
	const analytics = useService('AnalyticsService');
	const { t } = useTranslation();

	const { data: dealFiles = [], isLoading } = useGetDealFilesQuery(dealId);
	const { data: dealPreviewFiles = [] } = useGetDealPreviewFilesQuery(dealId, dealHasPreview);

	const previewFiles = useMemo(() => {
		const initialFiles = dealFiles.map((initialFile) => {
			const changedFile = dealPreviewFiles.find((previewFile) => initialFile.id === previewFile.dealFileId);
			return changedFile
				? {
						...initialFile,
						...changedFile,
						mediaObject: { ...initialFile.mediaObject, ...changedFile.mediaObject },
						name:
							changedFile.status === 'deleted'
								? t(`DELETED: {{fileName}}`, { fileName: changedFile.name })
								: changedFile.name,
				  }
				: initialFile;
		});
		const newAddeFiles = dealPreviewFiles.filter((dealPreviewFile) => !dealPreviewFile.dealFileId);

		return [...newAddeFiles, ...initialFiles];
	}, [dealFiles, dealPreviewFiles]);

	const files = isPreviewMode ? previewFiles : dealFiles;

	if (isLoading) return null;
	return isDraftDeal && !files?.length ? (
		<DealPostBodyRow showPlug title={t('Deal files')} />
	) : files?.length ? (
		<FilesSection
			files={files}
			title={t('Deal files')}
			onFileOpened={() => {
				analytics.trackEvent('DealInteractions', {
					deal_id: dealId,
					deal_name: dealName,
					interaction_type: 'deal_information_files',
					interaction_action: 'files viewed',
				});
			}}
		/>
	) : null;
};

export interface IDealFilesProps {
	dealId: TDeal['id'];
	dealName: TDeal['name'];
	isDraftDeal: boolean;
	dealHasPreview?: boolean;
	isPreviewMode?: boolean;
}

export default DealFiles;
