import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TNetworkCreationRequest } from '@typings';

/** Makes fetch query for getting network request by ID. */
export const useNetworkRequestQuery = (
	networkRequestId: TNetworkCreationRequest['id'],
	queryOptions?: UseQueryOptions<TNetworkCreationRequest, Error>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TNetworkCreationRequest, Error>(
		queryKeys.getNetworkCreationRequest(networkRequestId),
		() => api.networkCreationRequest.getNetworkCreationRequest(networkRequestId),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
