import { useService, useQuery } from '@hooks';
import type { TMicrositeWebCarouselImageResponse, TWebCarouselImage } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useAdImageGalleryQuery = (
	queryOptions?: UseQueryOptions<TMicrositeWebCarouselImageResponse[], Error, TWebCarouselImage[]>,
) => {
	const micrositeApi = useService('MicrositeApiService');

	return useQuery<TMicrositeWebCarouselImageResponse[], Error, TWebCarouselImage[]>(
		['micrositeApi.getWebGalleryImages'],
		async () => await micrositeApi.getWebGalleryImages(),
		{
			select: (carouselImages) =>
				carouselImages.map((carouselImage) => ({
					imageUrl: carouselImage.featured_image_src,
					link: carouselImage.acf.target_link,
				})),
			...queryOptions,
			staleTime: Infinity,
			cacheTime: Infinity,
		},
	);
};
