import { Page } from '../../components';
import { useParams, useMe, useTranslation, useEvent, useTrackAlgoliaEventEffect } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { ActivityIndicator, Button, ExternalLinkIcon, Gap } from '@ui-kit';
import EventLocationFlag from '@ui-modules/events/components/EventLocationFlag';
import { clsx, MeetingEntity } from '@utils';
import MeetingActionButton from '@ui-modules/events/components/MeetingActionButton';
import { useEventAttendanceMutations } from '@ui-modules/events/hooks/useEventAttendanceMutations';
import EventDateAndTimeCard from '@ui-modules/events/components/EventDateAndTimeCard';
import EventLocationCard from '@ui-modules/events/components/EventLocationCard';
import EventHostedByCard from '@ui-modules/events/components/EventHostedByCard';
import type { TUserProfileRecord } from '@tiger21-llc/connect-shared/src/typings/entities/User.type';
import EventAgenda from '@ui-modules/events/components/EventAgenda';
import EventFileList from '@ui-modules/events/components/EventFileList';
import EventSpeakersDetails from '@ui-modules/events/components/EventSpeakersDetails';
import MeetingAttendeeList from '@ui-modules/events/components/MeetingAttendeeList';
import EventAddToCalendarButton from '@ui-modules/events/components/EventAddToCalendarButton';
import { useMeetingData } from './MeetingPage.useMeetingData';
import { MeetingPageToast } from './MeetingPageToast';
import styles from './MeetingPage.module.css';

const MeetingPage = ({ scrollable = true }: IMeetingPageProps) => {
	const { eventId } = useParams<IMeetingPage>();
	const navigate = useNavigate();
	const { user } = useMe();
	const { t } = useTranslation();

	const { isSuccess, meeting, community, eventFiles } = useMeetingData(eventId);
	const {
		changeEventAttendanceMutation,
		requestEventAttendanceMutation,
		cancelEventAttendanceMutation,
		launchVirtualMeeting,
	} = useEventAttendanceMutations();
	const openMemberProfile = useEvent(({ profile }: TUserProfileRecord) => navigate(ROUTES.memberProfile(profile.id)));

	useTrackAlgoliaEventEffect('Meeting Viewed', { meetingSlug: String(meeting?.id) }, [meeting?.id]);

	return (
		<Page title={meeting?.title || '...'}>
			{!meeting && <ActivityIndicator size="medium" type="fit" />}
			{meeting && (
				<div className={clsx(styles.meetingPage__contentWrapper, !scrollable && styles.meetingPage__notScrollable)}>
					<div className={styles.meetingPage__content}>
						<MeetingPageToast meeting={meeting} />
						{isSuccess && (
							<div>
								<header>
									{meeting.banner?.contentUrl ? (
										<div className={styles.meetingPag__bannerBox}>
											<img alt="Event banner" className={styles.meetingPage__banner} src={meeting.banner?.contentUrl} />
										</div>
									) : null}
									<div className={styles.meetingPage__actionBox}>
										<div className={styles.meetingPage__locationFlag}>
											<EventLocationFlag eventLocationInfo={meeting} />
										</div>
										<div className={styles.meetingPage__actionButtons}>
											{MeetingEntity.shouldShowAttendanceUrl(meeting, user) ? (
												<>
													<Button
														icon={<ExternalLinkIcon height={14} width={16} />}
														iconPosition="right"
														title={t('Take Attendance')}
														type="outline"
														variant="medium"
														onClick={() => window.open(meeting.attendanceUrl, 'blank')}
													/>
													<Gap gap={8} />
												</>
											) : null}
											<MeetingActionButton
												detailed
												meeting={meeting}
												size="medium"
												onCancelRequestAttendance={cancelEventAttendanceMutation.mutateAsync}
												onChangeAttendance={changeEventAttendanceMutation.mutateAsync}
												onLaunchVirtualMeeting={launchVirtualMeeting}
												onRequestAttendance={requestEventAttendanceMutation.mutateAsync}
											/>
										</div>
									</div>
									<h2 className={styles.meetingPage__title}>{meeting.title}</h2>
								</header>
								{MeetingEntity.canBeAddedToCalendarV2(meeting, user) && eventId ? (
									<div>
										<Gap gap={26} />
										<EventAddToCalendarButton eventId={eventId} />
									</div>
								) : null}
								<Gap gap={26} />
								<EventDateAndTimeCard eventDatesInfo={meeting} />
								{MeetingEntity.shouldShowLocationAndAgenda(meeting, user) ? (
									<EventLocationCard event={meeting} />
								) : null}
								{meeting.hosts?.length ? (
									<EventHostedByCard eventHosts={meeting.hosts} onClick={openMemberProfile} />
								) : null}
								{meeting.agenda && MeetingEntity.shouldShowLocationAndAgenda(meeting, user) ? (
									<EventAgenda agenda={meeting.agenda} />
								) : null}
								{MeetingEntity.shouldShowFiles(meeting, community, user) ? (
									<EventFileList
										canDelete={(file) => MeetingEntity.canDeleteFile(meeting, file, user, community)}
										canMakeDownloadable={(file) =>
											MeetingEntity.canMakeFileDownloadable(meeting, file, user, community)
										}
										canMakeViewOnly={(file) => MeetingEntity.canMakeFileViewOnly(meeting, file, user, community)}
										canUpload={MeetingEntity.canUploadFiles(meeting, community, user)}
										eventFiles={eventFiles}
										eventId={eventId as string}
									/>
								) : null}
								{meeting.speakers?.length ? <EventSpeakersDetails event={meeting} /> : null}
								<MeetingAttendeeList goToProfile={openMemberProfile} meetingId={meeting.id} />
							</div>
						)}
					</div>
				</div>
			)}
		</Page>
	);
};

interface IMeetingPageProps {
	scrollable?: boolean;
}

interface IMeetingPage extends Record<string, string | undefined> {
	eventId?: string;
}

export default MeetingPage;
