import { useRouteSearchQueryState, useState, useToggle } from '@hooks';
import { useDealsSearchQuery } from '@ui-modules/deals/hooks/useDealsSearchQuery';
import type { TCommunity } from '@typings';

export const useDealsSearch = (communityId: TCommunity['id'] | null) => {
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();
	const [searchQuery, setSearchQuery] = useState(routeSearchQuery, 'useDealsSearch:searchQuery');
	const [isSearchFocused, setIsSearchFocused] = useToggle(false);

	const {
		data: searchResult,
		isFetching: isSearchFetching,
		isFetched: isSearchFetched,
		fetchMore: searchMore,
	} = useDealsSearchQuery({
		searchQuery,
		communityId: communityId,
		onSearch: setRouteSearchQuery,
	});

	const showSearchBar = isSearchFocused || !!searchQuery.length;
	const showSearchResults = !!searchQuery.length;

	return {
		searchResult: searchResult ?? [],
		isSearchFetching,
		isSearchFetched,
		searchQuery,
		showSearchResults,
		showSearchBar,
		setSearchQuery,
		searchMore,
		setIsSearchFocused,
	} as const;
};
