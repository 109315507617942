import { useEffect, useService, useMe, useState } from '@hooks';
import type { Event, DefaultGenerics } from 'stream-chat';

export const useChatUnreadCount = () => {
	const chat = useService('ChatService');
	const { user, fullName } = useMe();
	const [unreadCount, setUnreadCount] = useState<number | undefined>(0);

	/**inital unread count */
	useEffect(() => {
		chat.getUnreadCount(user.slug, fullName).then((event) => {
			if (event?.me?.total_unread_count !== undefined) {
				setUnreadCount(event?.me?.total_unread_count);
			}
		});
	}, []);

	useEffect(() => {
		const notificationHandler = (event: Event<DefaultGenerics>) => {
			if (event.total_unread_count !== undefined) {
				const { total_unread_count = 0 } = event;
				setUnreadCount(total_unread_count);
			}
		};
		chat.chatClient.on(notificationHandler);
		return () => {
			chat.chatClient.off(notificationHandler);
		};
	}, []);

	return { unreadCount };
};
