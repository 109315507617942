import { Button } from '@ui-kit';
import { useTranslation } from '@hooks';
import dealsImage from '@ui-modules/deals/assets/deals.png';
import styles from '../MyDealsZeroState/Deals.module.css';

const AllDealsZeroNetworks = ({ onGoToInvestNetworks }: { onGoToInvestNetworks: () => void }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.dealTab__zeroWrapper}>
			<div className={styles.dealTab__container}>
				<img alt="" src={dealsImage} />
				<p className={styles.dealTab__zeroTitle}>{t('You Haven’t Joined any Investment Networks')}</p>
				<span className={styles.dealTab__subTitle}>
					{t(`Deals from your Investment Networks are listed here, but you haven’t joined any Investment Networks.`)}
				</span>
				<Button title={t('Go to Investment Networks')} variant="small" onClick={onGoToInvestNetworks} />
			</div>
		</div>
	);
};

export default AllDealsZeroNetworks;
