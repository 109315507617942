import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useLearnFeedTabsQuery } from '@ui-modules/feed/hooks/useLearnFeedTabsQuery';
import { ROUTES } from '@constants';

/** Handles correctness of cooperation between routes and tabs within LearnPage. */
const LearnRootPage = () => {
	const { data: learnTabs = [] } = useLearnFeedTabsQuery({ suspense: true });

	const feedName = useParams()?.feedName;
	const getDefaultFeedName = () => {
		const defaultFeed = learnTabs.find((learnTab) => learnTab.isAll) ?? learnTabs[0];
		const defaultFeedName = defaultFeed.feedName;
		return defaultFeedName;
	};

	const isFeedNameAbsent = learnTabs.length && !feedName;
	const isFeedNameWrong = learnTabs.length && !learnTabs.find((learnTab) => learnTab.feedName === feedName);

	if (isFeedNameAbsent || isFeedNameWrong) return <Navigate replace to={ROUTES.learnTab(getDefaultFeedName())} />;
	return <Outlet />;
};

export default LearnRootPage;
