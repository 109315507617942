import { Avatar, ChevronRightFilled } from '@ui-kit';
import { transformUTCtoLocalTime } from '@utils';
import styles from './PostFormInfoBar.module.css';
import type { TUser } from '@typings';

/** Displays author and post target information.  */
const PostFormInfoBar = ({ userName, avatarUrl, userRoles, sourceName, time }: IPostFormInfoBarProps) => {
	return (
		<div className={styles.postFormInfoBar__user}>
			<Avatar imageUri={avatarUrl || undefined} outline={userRoles.includes('ROLE_CHAIR')} size={40} title={userName} />
			<div className={styles.postFormInfoBar__userText}>
				<div className={styles.postFormInfoBar__userNetwork}>
					<span className={styles.postFormInfoBar__userName}>{userName}</span>
					{sourceName ? (
						<>
							<ChevronRightFilled />
							<span className={styles.postFormInfoBar__networkName}>{sourceName}</span>
						</>
					) : null}
				</div>
				<span className={styles.postFormInfoBar__userDate}>{transformUTCtoLocalTime(time || '')}</span>
			</div>
		</div>
	);
};

export interface IPostFormInfoBarProps {
	userName: string;
	avatarUrl?: string | null;
	sourceName?: string;
	time: string;
	userRoles: TUser['roles'];
}

export default PostFormInfoBar;
