import styles from '../ChatInputBar/ChatInputBar.module.css';
import { clsx } from '@utils';
import { ImageAttachmentIcon, FileAttachmentIcon, CalendarIcon, GifIcon } from '@ui-kit';
const ChatInputButtons = ({
	isImageEnable,
	isFileEnable,
	isEventEnable,
	isGiphyEnable,
	onImageActionPressed,
	onFileActionPressed,
	toggleShowGifInput,
	onEventActionPressed,
}: IChatInputButtonsProps) => {
	return (
		<>
			<button className={styles.chatInputBar__eventButton} disabled={!isImageEnable} onClick={onImageActionPressed}>
				<ImageAttachmentIcon
					className={clsx(
						styles.chatInputBar__attachmentButton,
						styles.chatInputBar__imageButton,
						!isImageEnable && styles.chatInputBar__attachmentButton_disabled,
					)}
				/>
			</button>

			{/* Attach Files */}
			<button className={styles.chatInputBar__eventButton} disabled={!isFileEnable} onClick={onFileActionPressed}>
				<FileAttachmentIcon
					className={clsx(
						styles.chatInputBar__attachmentButton,
						styles.chatInputBar__fileButton,
						!isFileEnable && styles.chatInputBar__attachmentButton_disabled,
					)}
				/>
			</button>

			{/* Attach Tiger21 Events */}
			<button className={styles.chatInputBar__eventButton} disabled={!isEventEnable} onClick={onEventActionPressed}>
				<CalendarIcon
					className={clsx(
						styles.chatInputBar__attachmentButton,
						styles.chatInputBar__event,
						!isEventEnable && styles.chatInputBar__attachmentButton_disabled,
					)}
				/>
			</button>

			<button
				className={styles.chatInputBar__eventButton}
				disabled={!isGiphyEnable}
				onClick={() => toggleShowGifInput()}
			>
				<GifIcon
					className={clsx(
						styles.chatInputBar__attachmentButton,
						styles.chatInputBar__gif,
						!isGiphyEnable && styles.chatInputBar__attachmentButton_disabled,
					)}
				/>
			</button>
		</>
	);
};
interface IChatInputButtonsProps {
	isImageEnable: boolean;
	isFileEnable: boolean;
	isEventEnable: boolean;
	isGiphyEnable: boolean;
	onImageActionPressed: () => void;
	onFileActionPressed: () => void;
	toggleShowGifInput: () => void;
	onEventActionPressed: () => void;
}
export default ChatInputButtons;
