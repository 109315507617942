import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface IChatFlaggedMessageIds {
	flagMessageIds: Record<string, boolean> | null | undefined;
}

const initialState: IChatFlaggedMessageIds = {
	flagMessageIds: undefined,
};

export const chatChannelFlaggedMessagesSlice = createSlice({
	name: 'chatChannelFlaggedMessages',
	initialState,
	reducers: {
		setFlaggedMessageIds: (state, { payload: value }: PayloadAction<Record<string, boolean> | null | undefined>) => {
			state.flagMessageIds = value;
		},
	},
});
