import { useQuery, useService } from '@hooks';
import type { TNetworkConnection } from '@typings';

export const useGetNetworkConnectionFilesQuery = (networkConnectionId?: TNetworkConnection['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery(
		reactQuery.queryKeys.getNetworkConnectionFiles(networkConnectionId as TNetworkConnection['id'], null),
		async () =>
			await api.networkConnectionFile.getNetworkConnectionFiles({
				networkConnectionId: networkConnectionId as TNetworkConnection['id'],
				folderId: null,
			}),
		{
			enabled: !!networkConnectionId,
		},
	);
};
