import { Button } from '@ui-kit';
import LeaveNetworkModal from '@ui-modules/networks/components/LeaveNetworkModal';
import { useToggle, useTranslation, useEvent } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TCommunity } from '@typings';
import type { TButtonVariant, TButtonType } from '@ui-kit';
import { useNavigate, useLocation } from 'react-router-dom';

/** Action button for a certain network provides ability to join, leave or request access. */
const NetworkMembershipButton = ({
	networkId,
	buttonVariant = 'medium',
	buttonType = 'outline',
	hidePendingRequests = false,
}: INetworkMembershipButtonProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { search } = useLocation();
	const { data: network } = useCommunityQuery(networkId);

	const { joinNetwork, requestAccess, leaveNetwork, isLeavingNetwork, isJoiningNetwork, isRequestingAccess, Modals } =
		useNetworkMutations({
			onJoinSuccess: () => search.includes('true') && navigate(-1),
		});

	const [showDialog, toggleShowDialog] = useToggle(false);
	const openMyDeals = useEvent(() => navigate(ROUTES.dealsTabs('my-deals')));

	if (!network) return null;
	else if (CommunityEntity.canLeave(network)) {
		return (
			<>
				<Button
					loading={isLeavingNetwork}
					title={t('Leave Network')}
					type={buttonType}
					variant={buttonVariant}
					onClick={() => toggleShowDialog()}
				/>
				<LeaveNetworkModal
					network={network}
					visible={showDialog}
					onClose={toggleShowDialog}
					onLeaveNetwork={leaveNetwork}
					onOpenDeals={openMyDeals}
				/>
				{Modals}
			</>
		);
	} else if (CommunityEntity.canJoin(network)) {
		return (
			<Button
				loading={isJoiningNetwork}
				title={t('Join Network')}
				type={buttonType}
				variant={buttonVariant}
				onClick={() => joinNetwork(network)}
			/>
		);
	} else if (CommunityEntity.hasPendingJoinRequest(network) && !hidePendingRequests) {
		return <Button disabled title={t('Pending')} type="blurred" variant={buttonVariant} />;
	} else if (CommunityEntity.canSendJoinRequest(network)) {
		return (
			<Button
				loading={isRequestingAccess}
				title={t('Request Access')}
				type={buttonType}
				variant={buttonVariant}
				onClick={() => requestAccess(network)}
			/>
		);
	} else return null;
};

export interface INetworkMembershipButtonProps {
	/** ID of the target network. */
	networkId: TCommunity['id'];
	/** Can be used to customize "variant" property of the Button. Default "medium".  */
	buttonVariant?: TButtonVariant;
	/** Can be used to customize "type" property of the Leave Button. Default "outline".  */
	buttonType?: TButtonType;
	/** If "true" the "Pending" button is never shown. */
	hidePendingRequests?: boolean;
}

export default NetworkMembershipButton;
