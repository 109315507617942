import { useLayoutEffect } from '@hooks';
import type { TAssetAllocationTab } from '@typings';

export const useAssetAllocationTabsState = (
	assetAllocationTabs: TAssetAllocationTab[],
	tabName: string | undefined,
	navigateToTab: (tabName: string) => void,
) => {
	const currentTabIndex = assetAllocationTabs.findIndex((tab) => tab.slug === tabName);

	const handleChangeTab = (index: number) => {
		const nextTabName = assetAllocationTabs[index]?.slug ?? assetAllocationTabs[0].slug;
		navigateToTab(nextTabName);
	};

	useLayoutEffect(() => {
		if (assetAllocationTabs.length && currentTabIndex === -1) {
			handleChangeTab(0);
		}
	}, [assetAllocationTabs, currentTabIndex]);

	return [currentTabIndex, handleChangeTab] as const;
};
