import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GLOBAL_SEARCH } from '@constants';

export interface IGlobalSearchHistory {
	recentSearches: string[];
}

const initialState: IGlobalSearchHistory = {
	recentSearches: [],
};

export const globalSearchHistorySlice = createSlice({
	name: 'globalSearchHistory',
	initialState,
	reducers: {
		// Recent search.
		addRecentSearch: (state, { payload: search }: PayloadAction<string>) => {
			const trimmedSearch = search.trim();
			const duplicate = state.recentSearches.find((recSearch) => recSearch === trimmedSearch);
			if (trimmedSearch.length >= GLOBAL_SEARCH.validSearchQueryLength && !duplicate) {
				const composedSearches = [trimmedSearch, ...state.recentSearches];
				const prunedSearches = composedSearches.slice(0, GLOBAL_SEARCH.recentSearchesLimit);
				state.recentSearches = prunedSearches;
			}
		},
		removeRecentSearch: (state, { payload: removingSearch }: PayloadAction<string>) => {
			state.recentSearches = state.recentSearches.filter((search) => search !== removingSearch);
		},
		clearRecentSearches: (state) => {
			state.recentSearches = [];
		},
	},
});
