import { memo } from 'react';
import type { ILinkPreviewProps } from './LinkPreview';
import LinkPreview from './LinkPreview';
import { uniq } from '@utils';
import styles from './PostLinksPreview.module.css';

/** Set of LinkPreview cards to show within a Post content. */
const PostLinksPreview = ({ links, onLinkClicked }: IPostLinksPreviewProps) => {
	const filteredLinks = links?.length > 3 ? links?.slice(0, 3) : links;
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div className={styles.postLinksPreview} onClick={(event) => event.stopPropagation()}>
			{uniq(filteredLinks).map((link, index) => (
				<LinkPreview key={link + index} link={link} onLinkClicked={onLinkClicked} />
			))}
		</div>
	);
};

export interface IPostLinksPreviewProps extends Pick<ILinkPreviewProps, 'onLinkClicked'> {
	/** Array of URLs to render. */
	links: string[];
}

export default memo(PostLinksPreview);
