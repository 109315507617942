import { Modal } from '@ui-kit';
import { useDeleteFileForCalendarMutation } from '@ui-modules/files/hooks/useDeleteFileForCalendarMutation';
import { useTranslation } from '@hooks';
import { noop } from '@utils';
import type { IModalProps } from '@ui-kit';
import type { TEvent, TFile } from '@typings';

/** Confirms deleting of an activity and performs it. */
const DeleteFileForCalendarModal = ({ visible, fileId, eventId, onClose, onSuccess = noop }: IDeleteFileModalProps) => {
	const { t } = useTranslation();

	const { mutate: deleteFile, isLoading } = useDeleteFileForCalendarMutation(fileId, eventId, {
		onSuccess: () => {
			onSuccess?.();
			onClose();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('This file will be permanently deleted.')}
			title={t('Are you sure you want to delete?')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				deleteFile(fileId);
			}}
		/>
	);
};

interface IDeleteFileModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Event ID. */
	eventId: TEvent['id'];
	/** Target File ID. */
	fileId: TFile['id'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeleteFileForCalendarModal;
