import { useQuery } from '@hooks';
import axios from 'axios';
import type { Country } from 'react-phone-number-input';

type TLocation = {
	callingCode: string | undefined;
	countryCode: Country | undefined;
};

export const useCurrentLocation = () => {
	const { data: currentLocation } = useQuery(['geoLocation'], async () => await axios.get('https://ipapi.co/json/'), {
		select: (response) => {
			const defaultValue = { callingCode: '+1', countryCode: 'US' as Country }; // US is a default location value.
			const data = response?.data;
			if (data) {
				return { callingCode: data?.country_calling_code, countryCode: data?.country_code };
			} else {
				return defaultValue;
			}
		},
	});

	return {
		currentLocation: (currentLocation || { callingCode: undefined, countryCode: undefined }) as TLocation,
	};
};
