import { useService } from '@services';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IToastProps } from '@services';
import type { AxiosError } from 'axios';
import type { TApiError } from '@typings';
import type { AbstractApplicationError } from '@errors';

/** Exposes methods to show notifications. */
export const useNotification = () => {
	const { t } = useTranslation();
	const toast = useService('ToastService');

	const showError = useCallback((toastProps: IToastProps) => toast.showError(toastProps), []);

	const showInfo = useCallback((toastProps: IToastProps) => toast.showInfo(toastProps), []);

	const showSuccess = useCallback((toastProps: IToastProps) => toast.showSuccess(toastProps), []);

	/** Determines type of an Error and show an error notification with a proper format. */
	const showUnknownError = useCallback((error: Error | AbstractApplicationError) => {
		let title = error.name;
		let subtitle = error.message ?? t('Something went wrong.');
		const toastProps = 'toastProps' in error && error?.toastProps;

		if ('isAxiosError' in error) {
			const apiHydraError = error as AxiosError<{ 'hydra:description'?: string }>;
			subtitle = apiHydraError.message;
			if (apiHydraError.response?.data?.['hydra:description']) {
				const apiError = apiHydraError.response.data as TApiError;
				title = apiError['hydra:title'];
				subtitle = apiError['hydra:description'];
			}

			const apiError = error as AxiosError<{ title: string; detail: string }>;
			if (apiError.response?.data?.['detail']) {
				title = apiError.response.data.title;
				subtitle = apiError.response.data.detail;
			}
		}

		toast.showError({ ...toastProps, title, subtitle });
	}, []);

	return { showError, showUnknownError, showInfo, showSuccess };
};
