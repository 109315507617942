import { useService, useMutation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TPostNetworkConnectionFilterDTO } from '@tiger21-llc/connect-shared/src/typings/api/PostNetworkConnectionUserFilterDto.type';

export const useCreateNetworkConnectionUserFilterMutation = (
	options?: MutationOptions<TPostNetworkConnectionFilterDTO, Error, TPostNetworkConnectionFilterDTO>,
) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();

	return useMutation<TPostNetworkConnectionFilterDTO, Error, TPostNetworkConnectionFilterDTO>(
		['createNetworkConnectionUserFilter'],
		async (params) =>
			await api.networkConnectionUserFilter.postUserFilter(params.community, params.owner, params.filters),
		{
			onSuccess: (response, variables, context) => {
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				options?.onError?.(error, variables, context);
			},
		},
	);
};
