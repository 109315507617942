import { clsx } from '@utils';
import styles from './TagRows.module.css';

const TagRows = ({ title, tags, mode = 'column' }: ITagRowsProps) => {
	return (
		<>
			{title ? <span className={styles.tags__title}>{title}</span> : null}
			<div className={clsx(styles.tags__container, mode === 'row' && styles.tags__container_row)}>
				{tags.map((expertise, index) => (
					<div className={styles.tags__item} key={index + expertise}>
						<span>{expertise}</span>
					</div>
				))}
			</div>
		</>
	);
};

export interface ITagRowsProps {
	title?: string;
	tags: string[];
	mode?: 'column' | 'row';
}

export default TagRows;
