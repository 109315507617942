import { useTranslation } from '@hooks';
import { Toast } from '@ui-kit';

export const TakeEventSurveyToast = ({ surveyUrl }: ITakeEventSurveyToastProps) => {
	const { t } = useTranslation();
	return (
		<Toast
			fullWidth
			subtitle={t('Tap here to take a short survey to help improve your group meeting')}
			title={t('Take the Meeting survey')}
			type="info"
			onPress={() => window.open(surveyUrl, 'blank')}
		/>
	);
};

interface ITakeEventSurveyToastProps {
	surveyUrl: string;
}

export default TakeEventSurveyToast;
