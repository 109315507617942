import { useEvent, useService, useState, useTranslation } from '@hooks';
import { Document, Page } from 'react-pdf';
import styles from './PdfViewer.module.css';
import { Portal, Virtuoso, When } from '@ui-kit';
import { CloseIcon, DownloadIcon } from '@ui-kit/icons';
import { forwardRef, useImperativeHandle } from 'react';
import { isObject, downloadFile } from '@utils';
import type { ForwardedRef } from 'react';
import type { TFile } from '@tiger21-llc/connect-shared/src/typings';

const DEFAULT_FORBID_DOWNLOADING = false;

const PdfViewer = ({ onOpened, onClosed }: IPdfViewerProps, ref: ForwardedRef<IPdfViewerApi>) => {
	const { t } = useTranslation();
	const api = useService('ApiService');

	const [fileUrlOrFile, setFileUrlOrFile] = useState<TFileParam | null>(null);
	const [forbidDownloading, setForbidDownloading] = useState(DEFAULT_FORBID_DOWNLOADING);
	const [numPages, setNumPages] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	// External API available under the ref.
	const open = useEvent<IPdfViewerApi['open']>((fileUrlOrFile, forbidDownloading = false) => {
		setFileUrlOrFile(fileUrlOrFile);
		setForbidDownloading(forbidDownloading);
		onOpened?.();
	});
	const close = useEvent(() => {
		setFileUrlOrFile(null);
		setForbidDownloading(DEFAULT_FORBID_DOWNLOADING);
		onClosed?.();
	});
	useImperativeHandle(ref, () => ({ open, close }));

	if (!fileUrlOrFile) return null;

	const isFile = isObject(fileUrlOrFile) && 'id' in fileUrlOrFile;
	const fileUrl = isFile ? fileUrlOrFile.mediaObject.contentUrl : fileUrlOrFile;
	const canDownload = (isFile && !fileUrlOrFile.mediaObject.openInViewerOnly) || (!isFile && !forbidDownloading);
	const download = () => {
		if (!canDownload) return;
		if (isFile) {
			api.mediaObject.downloadNonEncryptedFileUrl(fileUrlOrFile);
		} else if (typeof fileUrlOrFile === 'string') {
			window.open(fileUrlOrFile, '_blank');
		} else {
			const data = window.URL.createObjectURL(fileUrlOrFile);
			downloadFile(data, 'file');
		}
	};

	function onDocumentLoadSuccess(pdf: { numPages: number }) {
		setNumPages(pdf.numPages);
		setIsLoading(false);
	}
	const renderUserItem = (index: number) => {
		return (
			<Page
				className={styles.page}
				key={`page_${index + 1}`}
				loading={
					<div className={styles.pageLoading}>
						<span>{t('Loading page…')}</span>
					</div>
				}
				pageNumber={index + 1}
				renderAnnotationLayer={false}
				renderTextLayer={false}
			/>
		);
	};

	return (
		<Portal>
			<div aria-labelledby="PDF Viewer" className={styles.modal__container} role="dialog">
				<div className={styles.modal__titleWrapper}>
					<span
						aria-label="close"
						className={styles.modal__toolbarButton}
						role="button"
						tabIndex={-1}
						onClick={close}
						onKeyPress={close}
					>
						<CloseIcon height={16} width={16} />
					</span>

					<When condition={canDownload}>
						<span
							aria-label="download"
							className={styles.modal__toolbarButton}
							role="button"
							tabIndex={-1}
							onClick={download}
							onKeyPress={download}
						>
							<DownloadIcon height={16} width={16} />
						</span>
					</When>
				</div>
				<Document
					className={styles.document}
					file={fileUrl}
					loading={
						<div className={styles.emptyBlock}>
							<span>{t('Loading PDF...')}</span>
						</div>
					}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					{isLoading ? (
						<div className={styles.emptyBlock} />
					) : (
						<div className={styles.listWrapper}>
							<Virtuoso
								className={styles.virtuoso}
								data={Array.from(new Array(numPages))}
								itemContent={renderUserItem}
								totalCount={numPages}
							/>
						</div>
					)}
				</Document>
			</div>
		</Portal>
	);
};

export interface IPdfViewerProps {
	onOpened?: () => void;
	onClosed?: () => void;
}
type TFileParam = string | Blob | TFile;
export interface IPdfViewerApi {
	open: (fileUrlOrFile: TFileParam, forbidDownloading?: boolean) => void;
	close: () => void;
}

export default forwardRef(PdfViewer);
