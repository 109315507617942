import { useTranslation } from '@hooks';
import { AvatarSecondaryIcon } from '@ui-kit/icons';
import styles from './ChatCreatingChannelPreview.module.css';

const ChatCreatingChannelPreview = ({ visible }: IChatCreatingChannelPreviewProps) => {
	const { t } = useTranslation();

	if (!visible) return null;
	return (
		<div className={styles.chatCreatingChannelPreview}>
			<AvatarSecondaryIcon height={40} width={40} />
			<h4 className={styles.chatCreatingChannelPreview__title}>{t('New Message')}</h4>
		</div>
	);
};

export interface IChatCreatingChannelPreviewProps {
	visible: boolean;
}

export default ChatCreatingChannelPreview;
