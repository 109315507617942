import { useCollapse, useService, useTranslation } from '@hooks';
import { Button, Card, TagRows } from '@ui-kit';
import styles from './ProfileInterests.module.css';

const ProfileInterests = ({
	isInvisible,
	interests = [],
	canEdit,
	title,
	editButtonStyle,
	onEdit,
}: IProfileInterestsProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded: false,
		onExpandEnd: () => {
			analytics.trackEvent('ProfileInteractions', {
				interaction_type: 'Read More clicked',
				action_value: 'Interests',
			});
		},
	});
	const canExpand = interests.length > 3;

	return isInvisible ? null : (
		<Card
			actionElement={
				canEdit ? (
					<Button className={editButtonStyle || styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} />
				) : null
			}
			title={title || t('Talk to me about')}
		>
			<div className={styles.interests}>
				{interests.length ? (
					<>
						<div>
							<TagRows mode="row" tags={interests.slice(0, 3)} />
						</div>
						<div data-testid="CollapsibleProfileInterests__content" {...getCollapseProps()}>
							<TagRows mode="row" tags={interests.slice(3, interests.length)} />
						</div>
						{canExpand && (
							<button className={styles.interests__button} {...getToggleProps()} aria-label="toggle">
								<span>{isExpanded ? t('See less') : t('See more')}</span>
							</button>
						)}
					</>
				) : (
					<span>
						{canEdit
							? t('You can show others your interest by editing this section.')
							: t('Ask me about my interests.')}
					</span>
				)}
			</div>
		</Card>
	);
};

export interface IProfileInterestsProps {
	isInvisible?: boolean;
	interests: string[];
	canEdit: boolean;
	title?: string;
	onEdit: () => void;
	editButtonStyle?: string;
}

export default ProfileInterests;
