import { useService, useMutation, useNotification } from '@hooks';
import type { TCommunity, TEvent, TFile, TFolder, TMediaObject, TNetworkConnection } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useUploadMediaObjectSetViewerOnly = (
	dependencies: IUseUploadMediaObjectSetViewerOnlyProps = {},
	options?: MutationOptions<TMediaObject, Error, { fileId: TFile['id']; isViewerOnly: boolean }>,
) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const reactQuery = useService('ReactQueryService');

	return useMutation<TMediaObject, Error, { fileId: TFile['id']; isViewerOnly: boolean }>(
		['mediaObject.updateDocument'],
		async ({ fileId, isViewerOnly }) => await api.mediaObject.setMediaObjectSetViewerOnly(fileId, isViewerOnly),
		{
			...options,
			async onSuccess() {
				if ('eventId' in dependencies) {
					await reactQuery.queryClient.refetchQueries([
						'calendarItemFile.getMeetingFiles',
						dependencies.eventId,
						undefined,
					]);
				} else if ('communityId' in dependencies) {
					await reactQuery.queryClient.refetchQueries(
						reactQuery.queryKeys.getCommunityFiles(dependencies.communityId, dependencies.folderId ?? null),
					);
				} else if ('dealId' in dependencies) {
					await reactQuery.queryClient.refetchQueries(
						reactQuery.queryKeys.getDealFiles(dependencies.dealId, dependencies.folderId ?? null),
					);
				} else if ('networkConnectionId' in dependencies) {
					await reactQuery.queryClient.refetchQueries(
						reactQuery.queryKeys.getNetworkConnectionFiles(
							dependencies.networkConnectionId,
							dependencies.folderId ?? null,
						),
					);
				}
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};

type IUseUploadMediaObjectSetViewerOnlyProps =
	| {
			eventId?: TEvent['id'];
	  }
	| {
			communityId: TCommunity['id'];
			folderId: TFolder['id'] | null;
	  }
	| {
			dealId: TCommunity['id'];
			folderId: TFolder['id'] | null;
	  }
	| {
			networkConnectionId: TNetworkConnection['id'];
			folderId: TFolder['id'] | null;
	  };
