import type { MutableRefObject, SyntheticEvent } from 'react';
import { useState } from 'react';

const PIXELS_SCROLL_TO_SHOW = 60;

export const useFloatingScrollToTop = (scrollRef: MutableRefObject<HTMLDivElement | null>) => {
	const [shouldShowScrollToTop, setShouldShowScrollToTop] = useState(false);
	const scrollToTop = () => scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
	const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
		const yOffset = event.currentTarget.scrollTop;
		setShouldShowScrollToTop(yOffset > PIXELS_SCROLL_TO_SHOW);
	};

	return { shouldShowScrollToTop, onScroll, scrollToTop, scrollRef };
};
