import { ActivityIndicator } from '@ui-kit';
import type { IApiVideoProps } from '@ui-modules/media/components/ApiVideo';
import { lazy, memo, Suspense } from 'react';
import styles from './PostVideos.module.css';

const ApiVideo = lazy(() => import('@ui-modules/media/components/ApiVideo'));

/** Set of video iframes to show as a Post content. */
const PostVideos = ({ videos, onVideoPlayed }: IPostVideosProps) => {
	return (
		<div className={styles.postVideos}>
			<Suspense fallback={<ActivityIndicator />}>
				{videos.map((videoUrl) => (
					<ApiVideo key={videoUrl} videoUrl={videoUrl} onVideoPlayed={onVideoPlayed} />
				))}
			</Suspense>
		</div>
	);
};

export interface IPostVideosProps extends Pick<IApiVideoProps, 'onVideoPlayed'> {
	/** Array of video URLs. */
	videos: string[];
}

export default memo(PostVideos);
