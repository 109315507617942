import { startTransition } from 'react';
import { Button, EditIcon, SearchableScreenBar, When } from '@ui-kit';
import Feed from '@ui-modules/feed/components/Feed';
import FeedSearchResultList from '@ui-modules/feed/components/FeedSearchResultList';
import { useMe, useNavigate, useState, useToggle, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import styles from './ChapterDiscussions.module.css';
import type { TCommunity } from '@typings';

const ChapterDiscussions = ({
	communityId,
	initialSearchQuery = '',
	onChangeSearchQuery,
}: IChapterDiscussionsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	const { data: community } = useCommunityQuery(communityId);
	const [searchQuery, setSearchQuery] = useState(initialSearchQuery, 'ChapterDiscussions:searchQuery');
	const [isSearchFocused, setIsSearchActive] = useToggle(false);
	const showSearchResults = isSearchFocused || !!searchQuery.length;

	const openNewPostPage = () =>
		navigate(ROUTES.addPost(), { state: { networkId: community?.['@id'], definition: community?.definition } });

	if (!community) return null;
	return (
		<>
			<div className={styles.chapterDiscussions__headerContainer}>
				<div className={styles.chapterDiscussions__header}>
					<SearchableScreenBar
						placeholder={t('Search posts')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						onBlur={() => setIsSearchActive(false)}
						onChangeSearchQuery={(searchQuery) => {
							setSearchQuery(searchQuery);
							onChangeSearchQuery(searchQuery);
						}}
						onFocus={() => setIsSearchActive(true)}
					>
						<When condition={CommunityEntity.shouldShowDiscussionsV2(community, user.roles)}>
							<Button
								icon={<EditIcon fill="#000000" height={14} width={14} />}
								iconPosition="left"
								title={t('New Post')}
								variant="small"
								onClick={openNewPostPage}
							/>
						</When>
					</SearchableScreenBar>
				</div>
			</div>

			<When condition={!!showSearchResults}>
				<FeedSearchResultList
					communityName={community.name}
					searchQuery={searchQuery}
					onSearch={(searchQuery) => {
						startTransition(() => {
							onChangeSearchQuery(searchQuery);
						});
					}}
				/>
			</When>
			<Feed hidden={showSearchResults} isActivitySourceHidden userId={community.slug} />
		</>
	);
};

export interface IChapterDiscussionsProps {
	communityId: TCommunity['id'];
	initialSearchQuery: string;
	onChangeSearchQuery: (searchQuery: string) => void;
}

export default ChapterDiscussions;
