import { Button, Card, CheckboxInput, When } from '@ui-kit';
import { useTranslation } from '@hooks';
import { noop } from '@utils';
import styles from './ProfileDietaryRestrictions.module.css';
import type { TDietaryRestrictions } from '@typings';

const ProfileDietaryRestrictions = ({
	canEdit,
	isInvisible,
	dietaryRestrictions,
	sectionName,
	onEdit,
}: IProfileDietaryRestrictionsProps) => {
	const { t } = useTranslation();

	const dietaryRestrictionsEmpty =
		!dietaryRestrictions.dietaryRestrictions?.length && !dietaryRestrictions.dietaryRestrictionsCustom;

	if (isInvisible) return null;
	return (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			title={sectionName}
			titleLabel={`(${t('Only visible to you')})`}
		>
			{dietaryRestrictionsEmpty ? (
				<span>{t('No dietary restrictions specified.')}</span>
			) : (
				<>
					<div className={styles.profileDietaryRestrictions__restrictionList}>
						{dietaryRestrictions.dietaryRestrictions?.map((type) => (
							<CheckboxInput disabled key={type} label={type} value onChange={noop} />
						))}
						<When condition={!!dietaryRestrictions.dietaryRestrictionsCustom}>
							<CheckboxInput disabled label={t('Other')} value onChange={noop} />
						</When>
					</div>
					<div className={styles.profileDietaryRestrictions__otherRestriction}>
						<span className={styles.profileDietaryRestrictions__otherRestrictionText}>
							{dietaryRestrictions.dietaryRestrictionsCustom}
						</span>
					</div>
				</>
			)}
		</Card>
	);
};

export interface IProfileDietaryRestrictionsProps {
	canEdit: boolean;
	isInvisible: boolean;
	dietaryRestrictions: TDietaryRestrictions;
	sectionName: string;
	onEdit: () => void;
}

export default ProfileDietaryRestrictions;
