import { Button, AddCalendarIcon } from '@ui-kit';
import { useTranslation } from '@hooks';

import styles from './EventAddToCalendarButton.module.css';
import { useAddEventToCalendar } from '@ui-modules/events/hooks/useAddEventToCalendar';

const EventAddToCalendarButton = ({ eventId }: IEventAddToCalendarButtonProps) => {
	const { t } = useTranslation();
	const { mutate: downloadFile } = useAddEventToCalendar(eventId);
	return (
		<Button
			className={styles.eventAddToCalendarBUtton}
			icon={<AddCalendarIcon className={styles.eventAddToCalendarButton} height={18} width={18} />}
			iconPosition="left"
			title={t('Add To Calendar')}
			type="clear"
			onClick={() => downloadFile()}
		/>
	);
};

export interface IEventAddToCalendarButtonProps {
	eventId: string;
}

export default EventAddToCalendarButton;
