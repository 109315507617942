import styles from './AlphabetList.module.css';
import type { TUserProfileRecord } from '@typings';

// Used a height 1px element to resolve Virtuoso list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko]
export const ItemPlaceholder = () => <div className={styles.sectionList__groupHeader_placeholder} />;

/** Helps to figure out group counts to pass into react-virtuoso list and letters to render group headers. */
export const getGroupCounts = (userProfileRecords: TUserProfileRecord[] = []) => {
	// Create map with counts, following the schema: { [letter]: count }.
	const groupCountsMap = userProfileRecords.reduce((groups, record) => {
		const letter = record.firstName[0].toUpperCase();
		if (!groups[letter]) {
			groups[letter] = 0;
		}
		groups[letter]++;

		return groups;
	}, {} as TGroupCountsMap);

	// Convert to array of arrays and sort, following the schema [[letter: count], [letter: count]]
	const groupCountsArray: TGroupCountsArray = Object.entries(groupCountsMap).sort((a, b) => a[0].localeCompare(b[0]));

	// Separate into two specialized arrays. groupCounts to pass into the list, groupLetters to render section headers.
	const groupLetters: string[] = [];
	const groupCounts: number[] = [];
	for (const [letter, count] of groupCountsArray) {
		groupLetters.push(letter);
		groupCounts.push(count);
	}

	return { groupCounts, groupLetters };
};

type TGroupCountsMap = Record<string, number>;
type TGroupCountsArray = [string, number][];
