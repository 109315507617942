import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TAssetAllocationPeriod, TAssetAllocationPeriodQueryParams } from '@typings';

export const useAssetAllocationComparePeriodsQuery = <T = TAssetAllocationPeriod[]>(
	params: TAssetAllocationPeriodQueryParams,
	queryOptions?: UseQueryOptions<TAssetAllocationPeriod[], Error, T>,
) => {
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');

	return useQuery<TAssetAllocationPeriod[], Error, T>(
		reactQuery.queryKeys.getAssetAllocationComparePeriods(params.type, params?.communityId),
		async () => await api.assetAllocation.getAssetAllocationComparePeriods(params),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...queryOptions,
		},
	);
};
