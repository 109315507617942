import { useState, useEffect } from 'react';

import type { TFlyoutResponse } from '@typings';

export const useFlyoutSequence = (messages: TFlyoutResponse[]) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [currentMessage, setCurrentMessage] = useState<TFlyoutResponse | null>(null);

	useEffect(() => {
		if (messages && messages.length > 0) {
			setCurrentMessage(messages[currentIndex]);
		}
	}, [currentIndex, messages]);

	const nextMessage = () => {
		if (currentIndex < messages.length - 1) {
			setCurrentIndex(currentIndex + 1);
		} else {
			setCurrentMessage(null);
		}
	};

	return { currentMessage, nextMessage };
};
