import { MeatballMenu } from '@ui-kit';
import { clsx } from '@utils';
import type { ReactNode } from 'react';
import styles from './ContactActionButton.module.css';

const ContactActionButton = ({
	icon,
	title,
	options,
	bordered,
	disabled = false,
	onPress,
}: IContactActionButtonProps) => {
	return options ? (
		<MeatballMenu
			disabled={disabled}
			options={options?.map((el) => ({
				...el,
				onClick: () => onPress && onPress(el.value, el.text),
				icon: icon,
				customOption: (
					<a className={styles.button__option} href={`${el.linkType}:${el.value}`}>
						<div className={styles.button__optionText}>
							<span className={styles.button__optionLabel}>{el.text}</span>
							<span className={styles.button__optionValue}>{el.value}</span>
						</div>
						{icon}
					</a>
				),
			}))}
		>
			<div className={clsx(styles.button__container, bordered && styles.button__bordered)}>
				{icon}
				<span className={styles.button__text}>{title}</span>
			</div>
		</MeatballMenu>
	) : (
		<button className={styles.button} disabled={disabled} onClick={() => onPress && onPress('', '')}>
			<div className={clsx(styles.button__container, bordered && styles.button__bordered)}>
				{icon}
				<span className={styles.button__text}>{title}</span>
			</div>
		</button>
	);
};

export type TContactActionButtonItem = {
	text: string;
	value: string;
	linkType: string;
};

export interface IContactActionButtonProps {
	icon: ReactNode;
	title: string;
	options?: TContactActionButtonItem[];
	onPress?: (value: TContactActionButtonItem['value'] | '', label?: TContactActionButtonItem['text']) => void;
	disabled?: boolean;
	bordered?: boolean;
}

export default ContactActionButton;
