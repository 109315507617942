import { useTranslation, useNavigate } from '@hooks';
import { Button, Gap, Modal, Toast, Trans } from '@ui-kit';
import SignInForm from '@ui-modules/auth/components/SignInForm';
import { useDefaultSignInLogin } from '@ui-modules/auth/hooks/useDefaultSignInLogin';
import { useOktaSignInMutation } from '@ui-modules/auth/hooks/useOktaSignInMutation';
import { Page } from '../../components';
import styles from './SignInPage.module.css';
import { ROUTES, SUPPORT_LINK } from '@constants';
import { noop } from '@utils';

const SignInPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { defaultLogin, setDefaultLogin, resetDefaultLogin } = useDefaultSignInLogin();
	const {
		mutateAsync: submit,
		submitError,
		modalProps: signInFeedbackModalProps,
	} = useOktaSignInMutation({
		onVerifyRequired: () => navigate(ROUTES.verifySignIn('verify-sign-in')),
	});

	const resetPassword = () => navigate(ROUTES.resetPassword());

	return (
		<Page title={t('Sign in')}>
			<div className={styles.signInPage}>
				<header className={styles.signInPage__header}>
					<h4>{t('Sign in')}</h4>
				</header>
				<Gap gap={16} />
				{submitError ? (
					<Toast subtitle={submitError.subtitle} title={submitError.title} type="error" onClose={noop} />
				) : null}
				<Gap gap={16} />
				<section>
					<SignInForm
						defaultShouldRememberLogin={!!defaultLogin}
						initialValues={{ login: defaultLogin ?? '', password: '' }}
						onResetShouldRememberLogin={resetDefaultLogin}
						onSetShouldRememberLogin={setDefaultLogin}
						onSubmit={submit}
					/>

					<Gap gap={32} />
					<Button block="fit" title={t('Reset Password')} type="solid" variant="medium" onClick={resetPassword} />
					<Gap gap={32} />
					<Trans>
						<small>
							Email&nbsp;
							<a href={`mailto:${SUPPORT_LINK}`} rel="noreferrer" target="_blank">
								{SUPPORT_LINK}
							</a>
							&nbsp;for help signing in.
						</small>
					</Trans>
				</section>
			</div>

			<Modal {...signInFeedbackModalProps} />
		</Page>
	);
};

export default SignInPage;
