import { ErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router';
import { useService } from '@hooks';
import { ROUTES } from '@constants';
import type { PropsWithChildren } from 'react';

const CheckAuthErrorBoundary = ({ children }: PropsWithChildren) => {
	const redux = useService('ReduxService');
	return (
		<ErrorBoundary
			fallback={<Navigate replace to={ROUTES.signIn()} />}
			onError={(error, stack) => {
				redux.auth.resetTokensResponse();
				console.error(error, stack);
			}}
		>
			{children}
		</ErrorBoundary>
	);
};

export default CheckAuthErrorBoundary;
