import { useTranslation } from '@hooks';
import styles from './ContactsPersonalInfo.module.css';
import type { TProfile, TUser } from '@typings';
import { Avatar } from '@ui-kit';
import { formatUserContactInfo } from './ContactsPersonalInfo.utils';

const ContactsPersonalInfo = ({ user, profileType, profileTitle, onViewProfile }: IContactsPersonalInfoProps) => {
	const { t } = useTranslation();
	const fullName = `${user.firstName} ${user.lastName}`;
	const role = formatUserContactInfo(t)(user.roles, user.communitiesForBadge, profileTitle);
	const isChair = profileType === 'ProfileChair';

	return (
		<div className={styles.info__container}>
			<Avatar
				imageUri={user.avatar?.contentUrl}
				outline={isChair}
				size={80}
				title={user.firstName[0] + user.lastName[0]}
			/>
			<span className={styles.info__name}>{fullName}</span>
			<span className={styles.info__role}>{role}</span>
			<button className={styles.info__button} onClick={onViewProfile}>
				<span>{t('View Profile')}</span>
			</button>
		</div>
	);
};

export interface IContactsPersonalInfoProps {
	user: TUser;
	profileTitle: TProfile['title'];
	onViewProfile: () => void;
	profileType: TProfile['@type'];
}

export default ContactsPersonalInfo;
