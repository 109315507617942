import type { ReactionOptions } from 'stream-chat-react';

export const customReactionEmojis: ReactionOptions = [
	{
		type: 'haha',
		Component: () => (
			<svg fill="#666666" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.7499 4.88558C8.2903 4.88558 4.67702 8.49886 4.67702 12.9585C4.67702 17.4181 8.2903 21.0314 12.7499 21.0314C17.2096 21.0314 20.8229 17.4181 20.8229 12.9585C20.8229 8.49886 17.2096 4.88558 12.7499 4.88558ZM13.8242 10.159L16.4283 8.59652C16.8189 8.36865 17.2096 8.85693 16.9492 9.18245L15.8424 10.4845L16.9492 11.8192C17.2096 12.1447 16.8189 12.633 16.4283 12.4051L13.8242 10.8426C13.5963 10.6799 13.5963 10.3218 13.8242 10.159ZM8.51817 9.18245C8.25775 8.85693 8.64838 8.36865 9.039 8.59652L11.6432 10.159C11.9036 10.3218 11.9036 10.6799 11.6432 10.8426L9.039 12.4051C8.64838 12.633 8.25775 12.1447 8.51817 11.8192L9.62494 10.4845L8.51817 9.18245ZM17.6328 14.5861C17.3723 16.8973 15.3867 18.6877 13.0104 18.6877H12.4895C10.0807 18.6877 8.12754 16.8973 7.83457 14.5861C7.76947 14.2931 8.02989 14.0002 8.35541 14.0002H17.1445C17.4374 14.0002 17.6979 14.2931 17.6328 14.5861Z" />
			</svg>
		),
		name: 'Joy',
	},
	{
		type: 'love',
		Component: () => (
			<svg fill="#666666" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.4557 6.67594C17.6653 5.146 15.0286 5.43897 13.3684 7.13167L12.7499 7.78272L12.0989 7.13167C10.4713 5.43897 7.80202 5.146 6.01166 6.67594C3.96088 8.43376 3.86322 11.5588 5.68614 13.4468L12.0012 19.9572C12.3919 20.3804 13.0755 20.3804 13.4661 19.9572L19.7812 13.4468C21.6041 11.5588 21.5064 8.43376 19.4557 6.67594Z" />
			</svg>
		),
		name: 'Heart',
	},
	{
		type: 'like',
		Component: () => (
			<svg fill="#666666" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.8183 11.9168H5.21413C4.7584 11.9168 4.43288 12.2749 4.43288 12.6981V20.5106C4.43288 20.9663 4.7584 21.2918 5.21413 21.2918H7.8183C8.24148 21.2918 8.59955 20.9663 8.59955 20.5106V12.6981C8.59955 12.2749 8.24148 11.9168 7.8183 11.9168ZM6.51622 19.9897C6.06049 19.9897 5.73497 19.6642 5.73497 19.2085C5.73497 18.7853 6.06049 18.4272 6.51622 18.4272C6.93939 18.4272 7.29747 18.7853 7.29747 19.2085C7.29747 19.6642 6.93939 19.9897 6.51622 19.9897ZM16.9329 7.29443C16.9329 4.88558 15.3704 4.62516 14.5891 4.62516C13.9055 4.62516 13.6126 5.92725 13.4824 6.51318C13.287 7.22933 13.1243 7.94548 12.636 8.43376C11.5943 9.50798 11.041 10.8426 9.73887 12.1121C9.67377 12.2098 9.64122 12.3075 9.64122 12.4051V19.3713C9.64122 19.5666 9.80398 19.7293 9.99929 19.7619C10.5201 19.7619 11.2037 20.0549 11.7245 20.2827C12.7662 20.7384 14.0357 21.2918 15.5982 21.2918H15.6959C17.0956 21.2918 18.7558 21.2918 19.4068 20.3478C19.6998 19.9572 19.7649 19.4689 19.6022 18.883C20.1555 18.3296 20.416 17.2879 20.1555 16.4416C20.7089 15.6929 20.774 14.6187 20.4485 13.87C20.8391 13.4793 21.0995 12.8608 21.067 12.2749C21.067 11.2658 20.2206 10.3543 19.1464 10.3543H15.8261C16.0865 9.44287 16.9329 8.66162 16.9329 7.29443Z" />
			</svg>
		),
		name: 'Thumbs up',
	},
	{
		type: 'sad',
		Component: () => (
			<svg fill="#666666" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.41661 6.44808V14.2606C4.41661 14.7163 4.74213 15.0418 5.19786 15.0418H7.80202C8.2252 15.0418 8.58327 14.7163 8.58327 14.2606V6.44808C8.58327 6.0249 8.2252 5.66683 7.80202 5.66683H5.19786C4.74213 5.66683 4.41661 6.0249 4.41661 6.44808ZM5.71869 12.9585C5.71869 12.5353 6.04421 12.1772 6.49994 12.1772C6.92312 12.1772 7.28119 12.5353 7.28119 12.9585C7.28119 13.4142 6.92312 13.7397 6.49994 13.7397C6.04421 13.7397 5.71869 13.4142 5.71869 12.9585ZM14.5729 21.2918C15.3541 21.2918 16.9166 21.0314 16.9166 18.6551C16.9166 17.2879 16.0703 16.5067 15.8098 15.5627H19.1301C20.2044 15.5627 21.0507 14.6838 21.0833 13.6746C21.0833 13.0887 20.8229 12.4702 20.4322 12.0796C20.7578 11.3309 20.6926 10.2567 20.1393 9.50798C20.3997 8.66162 20.1393 7.61995 19.5859 7.06657C19.7486 6.48063 19.6835 5.99235 19.3906 5.60173C18.7395 4.65772 17.0794 4.62516 15.6796 4.62516H15.582C14.0195 4.65772 12.7499 5.2111 11.7083 5.66683C11.1874 5.89469 10.5038 6.18766 9.98301 6.18766C9.7877 6.22022 9.62494 6.38298 9.62494 6.57829V13.5444C9.62494 13.6421 9.65749 13.7723 9.7226 13.8374C11.0247 15.1069 11.5781 16.4416 12.6197 17.5158C13.108 18.0041 13.2708 18.7202 13.4661 19.4364C13.5963 20.0223 13.8893 21.2918 14.5729 21.2918Z" />
			</svg>
		),
		name: 'Thumbs down',
	},
	{
		type: 'wow',
		Component: () => (
			<svg fill="#666666" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.5624 18.6877C15.5624 17.2554 14.3905 16.0835 12.9583 16.0835C11.4934 16.0835 10.3541 17.2554 10.3541 18.6877C10.3541 20.1525 11.4934 21.2918 12.9583 21.2918C14.3905 21.2918 15.5624 20.1525 15.5624 18.6877ZM10.6471 5.47152L11.0702 14.3257C11.1028 14.7163 11.4609 15.0418 11.8515 15.0418H14.0325C14.4231 15.0418 14.7812 14.7163 14.8137 14.3257L15.2369 5.47152C15.2694 5.01579 14.9114 4.62516 14.4556 4.62516H11.4283C10.9726 4.62516 10.6145 5.01579 10.6471 5.47152Z" />
			</svg>
		),
		name: 'Astonished',
	},
];
