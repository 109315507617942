import { useEffect } from '@hooks';
import { Checkbox } from '@ui-kit';

import { useState } from 'reinspect';
import styles from './MultiSelect.module.css';

import type { TDynamicFilter } from '@typings';
import type {
	TDataType,
	TFieldValue,
} from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';
import { isArray } from '@utils';

const MultiSelect = ({ fieldData, onFilterUpdate }: IMultiSelectProps) => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>((fieldData?.filtersApplied?.in as string[]) || []);

	useEffect(() => {
		if (fieldData?.filtersApplied?.in) setSelectedOptions(fieldData?.filtersApplied?.in as string[]);
	}, [fieldData?.filtersApplied?.in]);

	const handleCheckboxChange = (option: string, isChecked: boolean) => {
		setSelectedOptions((prev) => {
			const updatedSelectedOptions = isChecked
				? [...prev, option]
				: prev.filter((selectedOption: string) => selectedOption !== option);

			onFilterUpdate(fieldData.name, updatedSelectedOptions as unknown as TFieldValue, 'multi-select');

			return updatedSelectedOptions;
		});
	};

	return (
		<div>
			<span className={styles.multiSelect__title}>{fieldData.label}</span>
			<div className={styles.multiSelect__wrapper}>
				{fieldData.meta.choices?.map(({ choice, infoText }, index) => (
					<Checkbox
						className={styles.multiSelect__checkbox}
						key={index}
						value={Boolean(selectedOptions?.includes?.(choice))}
						onChange={(newValue) => handleCheckboxChange(choice, newValue)}
					>
						<p>{infoText ? `${choice} - ${infoText}` : choice}</p>
					</Checkbox>
				))}
			</div>
		</div>
	);
};

export interface IMultiSelectProps {
	fieldData: TDynamicFilter;
	onFilterUpdate: (fieldName: string, fieldValue: TFieldValue, dataType: TDataType) => void;
}

export default MultiSelect;
