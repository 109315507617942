import { ROUTES } from '@constants';
import { useNavigate, useService } from '@hooks';

export const useGlobalSearchState = () => {
	const { useAppSelector, useBindActionCreators, globalSearchState } = useService('ReduxService');
	const navigate = useNavigate();

	const state = useAppSelector((store) => store.globalSearchState);
	const boundActions = useBindActionCreators(globalSearchState);

	const goToResultsScreen = (searchQuery: string) => {
		boundActions.setIsSearchActive(false);
		boundActions.resetSearchQuery();

		navigate(ROUTES.searchResults(searchQuery));

		boundActions.setSearchQuery('');
		boundActions.setIsSearchActive(false);
	};

	return { ...state, ...boundActions, goToResultsScreen };
};
