import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaAuthenticatorEnrollRequiredError extends OktaError {
	status = 'MFA_ENROLL';
	getMessage(t: TFunction) {
		return {
			title: t('Multi-Factor Authentication Setup Required'),
			subtitle: t(
				'To enhance your account security, we need you to set up Multi-Factor Authentication (MFA). You will be redirected to the OKTA website to complete this process.',
			),
		};
	}

	static shouldThrowError(transaction: IdxTransaction) {
		return transaction.nextStep?.name === 'select-authenticator-enroll';
	}
}
