import { useNotification, useService } from '@hooks';
import { useUpdateDealMutation } from './useUpdateDealMutation';

export const usePublishDeal = () => {
	const { mutate: updateDeal, isLoading } = useUpdateDealMutation();
	const { showUnknownError, showSuccess } = useNotification();
	const reactQuery = useService('ReactQueryService');

	const publishDeal = (delId: string) => {
		updateDeal(
			{ id: delId, deal: { status: 'published' } },
			{
				onSuccess: (deal) => {
					showSuccess({ title: 'Deal Published' });
					reactQuery.refetchDealsQueries(deal.community.id);
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(deal.id));
					reactQuery.updateDealsCache(deal.id, () => deal);
				},
				onError: (error) => {
					showUnknownError(error);
				},
			},
		);
	};

	return { publishDeal, isPublishing: isLoading };
};
