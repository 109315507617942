import { useEffect, useTranslation } from '@hooks';

import styles from './Categories.module.css';
import { CheckboxInput, CheckboxNestedInput } from '@ui-kit';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import RadioButtonsFilters from '@ui-modules/deals/components/RadioButtonsFilters';
import { useState } from 'reinspect';

const updateCategories = (categories: ICheckmarkState[]) => {
	return categories.map((category) => {
		return {
			...category,
			value: false,
			children: category.children?.map((child) => {
				return { ...child, value: false };
			}),
		};
	});
};

const Categories = ({ categories, onChange }: ICategoriesProps) => {
	const { t } = useTranslation();
	const [optionIndex, setOptionIndex] = useState(0);

	useEffect(() => {
		const hasSelectedChildren = categories.some(
			(category) => category.children && category.children.some((child) => child.value),
		);

		if (categories?.some((item) => item.value) || hasSelectedChildren) {
			setOptionIndex(1);
		}
	}, [categories]);

	const onLimitSelectionChange = () => {
		setOptionIndex(1);
	};
	const onShowAllChange = () => {
		setOptionIndex(0);
		onChange(updateCategories(categories));
	};

	return (
		<div>
			<p className={styles.title}>
				<span>{t('Categories')}</span>
				{t('(select all that apply)')}
			</p>
			<RadioButtonsFilters
				optionIndex={optionIndex}
				onLimitSelectionChange={onLimitSelectionChange}
				onShowAllChange={onShowAllChange}
			/>
			<div className={styles.checkboxWrapper}>
				<CheckboxNestedInput
					checkMarks={categories}
					childrenDisabled={optionIndex === 0}
					receiveChanges={(updatedCategories) => onChange(updatedCategories)}
					renderChild={({ child, handleChildPress }) => (
						<CheckboxInput
							filterCheckbox
							label={child.label}
							value={child.value}
							onChange={() => handleChildPress(child.id)}
						/>
					)}
					showHorizontalRule
				/>
			</div>
		</div>
	);
};

export interface ICategoriesProps {
	categories: ICheckmarkState[];
	onChange: (categories: ICheckmarkState[]) => void;
}

export default Categories;
