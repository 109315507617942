import type { TDynamicFieldLongText } from '@typings';
import styles from './DynamicLongTextSection.module.css';
import PostContent from '../Post/PostContent';

const DynamicLongTextSection = ({
	textFields = [],
	collapsedTitle,
	expandedTitle,
	highlightedText,
	collapsedNumberOfLines,
	onCollapse,
}: IDynamicLongTextSectionProps) => {
	if (!textFields?.length) return null;
	return (
		<div>
			{textFields.map((el) => {
				return (
					<div className={styles.textSection} key={`${el.name}_${el.label}`}>
						<span className={styles.textSection__title}>{el.label}</span>
						<PostContent
							className={styles.textSection__text}
							collapsedNumberOfLines={collapsedNumberOfLines}
							collapsedTitle={collapsedTitle}
							expandedTitle={expandedTitle}
							highlightingText={highlightedText}
							onCollapse={onCollapse}
						>
							{el.value as string}
						</PostContent>
					</div>
				);
			})}
		</div>
	);
};

export interface IDynamicLongTextSectionProps {
	textFields: TDynamicFieldLongText[];
	expandedTitle: string;
	collapsedTitle: string;
	highlightedText?: string;
	collapsedNumberOfLines?: number;
	onCollapse?: () => void;
}

export default DynamicLongTextSection;
