import { useCallback, useService } from '@hooks';
import { dayjs, noop, last } from '@utils';
import type { QueryKey } from '@tanstack/react-query';
import type { FeedAPIResponse, ReactionFilterConditions } from 'getstream';
import type { FlatFeedProps } from 'react-activity-feed';

/** Custom SinglePost doReactionsFilterRequest implementation made in order to:
 * 	 1. loading from cache provides ability to restore scroll position within a Feed.
 */
export const useDoReactionsFilterRequest = ({ activityId, onLoadedFromCache = noop }: TUseDoFeedRequestCallbacks) => {
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const feedService = useService('FeedService');

	const fetchFeed = async (options: ReactionFilterConditions, page: string) =>
		await queryClient.fetchQuery(
			queryKeys.getPostActivityReactions(activityId, page),
			async () => {
				// By default comments does not have own likes which brakes like functionality after page reload. Fix (T21C-2869). [@dmitriy.nikolenko]
				const res = await feedService.doReactionsFilterRequest(options);
				return res;
			},
			{
				cacheTime: dayjs.duration(7, 'seconds').asMilliseconds(),
				staleTime: dayjs.duration(7, 'seconds').asMilliseconds(),
			},
		);

	const getFeedCache = (page: string): TFeedQueryCache =>
		queryClient.getQueriesData<FeedAPIResponse>(
			queryKeys.getPostActivityReactions(activityId, page),
		) as TFeedQueryCache;

	const extractFeedFromQueryCache = (feedCache: TFeedQueryCache) => {
		if (!feedCache || !feedCache.length) return {} as FeedAPIResponse;

		const allFeedResponseResults = feedCache.flatMap(
			([queryKey, cacheRecord]) => cacheRecord.results as any,
		) as FeedAPIResponse['results'];
		const lastFeedResponse = last(feedCache)?.[1];
		const composedFeedResponse: FeedAPIResponse = {
			...lastFeedResponse,
			results: allFeedResponseResults,
		} as FeedAPIResponse;
		return composedFeedResponse;
	};

	//  Main function.

	return useCallback<TDoFeedRequest>(
		async (options) => {
			const page = options?.limit ? options?.id_gt || '' : '0'; //calculateFeedPage(options);
			const feedCache = getFeedCache(page);

			try {
				if (feedCache.length) {
					const feedApiResponse = extractFeedFromQueryCache(feedCache);
					setTimeout(onLoadedFromCache ?? noop, 0); // postpone task to the next event loop iteration to allow the list to be rendered on time.
					return feedApiResponse as unknown as ReturnType<TDoFeedRequest>;
				} else {
					throw new Error('There is no cache hit');
				}
				// Wrapped to catch to handle also exceptions on extractFeedFromQueryCache due to corrupted cache (fixes T21C-3255) [@dmitriy.nikolenko].
			} catch (error) {
				const feedApiResponse = await fetchFeed(options, page);
				return feedApiResponse as unknown as ReturnType<TDoFeedRequest>;
			}
		},
		[activityId],
	);
};

type TFeedQueryCache = [QueryKey, FeedAPIResponse][];
type TDoFeedRequest = Required<FlatFeedProps>['doReactionsFilterRequest'];

export type TUseDoFeedRequestCallbacks = {
	onLoadedFromCache: () => void;
	activityId?: string;
};
