import styles from './AssetAllocationHero.module.css';
import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

const AssetAllocationHero = ({ backgroundImageUrl, children }: IAssetAllocationHeroProps) => {
	const backgroundImage = `url('${backgroundImageUrl}')`;
	return (
		<div className={styles.assetAllocationHero} style={{ backgroundImage }}>
			<div className={styles.assetAllocationHero__backDrop}>
				<div className={styles.assetAllocationHero__foregroundImage} style={{ backgroundImage }}>
					<div
						className={clsx(
							styles.assetAllocationHero__foreground,
							backgroundImageUrl && styles.assetAllocationHero__foregroundGradient,
						)}
					>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export interface IAssetAllocationHeroProps extends PropsWithChildren {
	backgroundImageUrl?: string;
}

export default AssetAllocationHero;
