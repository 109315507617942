import { clsx } from '@utils';
import styles from './RibbonFlag.module.css';

const RibbonFlag = ({ title, size = 'medium', color, className, direction = 'right' }: IRibbonFlagProps) => {
	return (
		<div
			className={clsx(styles.ribbon, className)}
			data-direction={direction}
			data-size={size}
			style={{ borderColor: color }}
		>
			<div className={styles.ribbon__main} style={{ backgroundColor: color }}>
				<span className={clsx(styles.ribbon__title)}>{title}</span>
			</div>
		</div>
	);
};

type TIRibbonFlagSize = 'small' | 'medium';
export interface IRibbonFlagProps {
	title: string;
	size?: TIRibbonFlagSize;
	color?: string;
	className?: string;
	direction?: 'right' | 'left';
}

export default RibbonFlag;
