import {
	CsvFileIcon,
	DocFileIcon,
	DocxFileIcon,
	HtmlFileIcon,
	MdFileIcon,
	OdtFileIcon,
	PdfFileIcon,
	PptFileIcon,
	PptxFileIcon,
	RarFileIcon,
	RtfFileIcon,
	SevenZipFileIcon,
	TarGzFileIcon,
	TxtFileIcon,
	XlsFileIcon,
	XlsxFileIcon,
	ZipFileIcon,
	PngFileIcon,
	JpgFileIcon,
	GenericAudioFileIcon,
	GenericImageFileIcon,
	GenericFileIcon,
} from '../../fileIcons';

export const pickUpFileAvatarIcon = (mimeType: string) => {
	switch (mimeType) {
		case 'text/csv':
			return CsvFileIcon;
		case 'application/msword':
			return DocFileIcon;
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return DocxFileIcon;
		case 'text/html':
			return HtmlFileIcon;
		case 'text/markdown':
			return MdFileIcon;
		case 'application/vnd.oasis.opendocument.text':
			return OdtFileIcon;
		case 'application/pdf':
			return PdfFileIcon;
		case 'application/vnd.ms-powerpoint':
			return PptFileIcon;
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			return PptxFileIcon;
		case 'application/vnd.rar':
			return RarFileIcon;
		case 'application/rtf':
			return RtfFileIcon;
		case 'application/x-7z-compressed':
			return SevenZipFileIcon;
		case 'application/x-tar':
			return TarGzFileIcon;
		case 'text/plain':
			return TxtFileIcon;
		case 'application/vnd.ms-excel':
			return XlsFileIcon;
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return XlsxFileIcon;
		case 'application/zip':
			return ZipFileIcon;
		case 'image/png':
			return PngFileIcon;
		case 'image/jpeg':
			return JpgFileIcon;
		default: {
			if (mimeType?.startsWith('audio/')) return GenericAudioFileIcon;
			if (mimeType?.startsWith('image/')) return GenericImageFileIcon;

			return GenericFileIcon;
		}
	}
};
