import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity, TCommunityMember } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useCommunityModeratorMembersQuery = <T = TCommunityMember[]>(
	communityId: TCommunity['id'],
	queryOptions?: UseQueryOptions<TCommunityMember[], Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TCommunityMember[], Error, T>(
		['membership.getCommunityModeratorMembers', communityId],
		async () => await api.membership.getCommunityModeratorMembers(communityId),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...queryOptions,
		},
	);
};
