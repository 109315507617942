import type { TMediaObject, TFile } from '@typings';
import { dayjs, downloadFile, FileEntity, saveFile } from '@utils';
import type { IAxiosService } from '../interfaces/AxiosService.interface';

export class MediaObjectApiService {
	constructor(private readonly axios: IAxiosService) {}

	/** Creates a MediaObject resource. */
	async uploadImage(imageFile: File): Promise<TMediaObject> {
		const formData = new FormData();

		formData.append('file', imageFile);

		return await this.axios.post(`/media_objects`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			transformRequest: () => formData, // bypass issue (https://github.com/axios/axios/issues/4406#issuecomment-1048693170)
			timeout: dayjs.duration(2, 'minutes').asMilliseconds(),
		});
	}

	/** Creates a MediaObject resource. */
	async uploadDocument(documentInfo: File, secretKey?: string, isViewOnly = false): Promise<TMediaObject> {
		const formData = new FormData();

		formData.append('file', documentInfo);

		if (documentInfo.type === 'application/pdf') {
			formData.append('openInViewerOnly', JSON.stringify(isViewOnly));
		}

		if (secretKey) {
			formData.append('secretKey', secretKey);
		}

		return await this.axios.post(`/media_objects`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			transformRequest: () => formData, // bypass issue (https://github.com/axios/axios/issues/4406#issuecomment-1048693170)
			timeout: dayjs.duration(2, 'minutes').asMilliseconds(),
		});
	}

	getDownloadEncryptedFileUrl(file: TFile, token: string): string {
		const searchParams = new URLSearchParams(`token=${token}`);
		return `/media_objects/${file.mediaObject.id}/decrypt-by-token?${searchParams}`;
	}

	/** Get the public decryption token for the MediaObject. */
	async getMediaObjectDecryptionToken(file: TFile, secretKey: string): Promise<{ token: string }> {
		return await this.axios.get(`/media_objects/${file.mediaObject.id}/decryption-token`, {
			params: {
				secretKey: secretKey,
			},
		});
	}

	async downloadFile(downloadUrl: string, fileName: string) {
		const result: unknown = await this.axios.get(downloadUrl, {
			responseType: 'blob',
			timeout: dayjs.duration(2, 'minutes').asMilliseconds(),
		});
		saveFile(result as Blob, fileName);
	}

	async getDownloadFileUrl(downloadUrl: string): Promise<Blob> {
		return await this.axios.get(downloadUrl, {
			responseType: 'blob',
			timeout: dayjs.duration(2, 'minutes').asMilliseconds(),
			// `withCredentials` indicates whether or not cross-site Access-Control requests should be made using credentials
			withCredentials: false,
		});
	}

	async downloadNonEncryptedFileUrl(file: TFile): Promise<void> {
		const fileUrl: string = await this.axios.get(`/download_media/${file.mediaObject.id}`);
		downloadFile(fileUrl, FileEntity.getFileNameV2(file));
	}

	/** Set it as read only or downloadable file */
	async setMediaObjectSetViewerOnly(fileId: TFile['id'], isViewerOnly: boolean): Promise<TMediaObject> {
		return await this.axios.patch(`/media_objects/${fileId}/set-viewer-only`, {
			isViewerOnly,
		});
	}
}
