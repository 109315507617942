import { Link } from 'react-router-dom';
import Avatar from '../../Avatar';
import BlockLink from '../../BlockLink';
import RelativeTime from '../../RelativeTime';
import { clsx, compact } from '@utils';
import { ChevronRightFilled, FileAttachmentIcon, ImageAttachmentIcon, VideoAttachmentIcon } from '@ui-kit/icons';
import styles from './PostHeader.module.css';
import type { TCommunity, TIsoDate } from '@typings';
import type { IAvatarProps } from '../../Avatar';
import { useGetUserGroups } from '../../../../common/hooks/useGetUserGroups';

const PostHeader = ({
	avatarProps,
	userName,
	sourceName,
	createdAt,
	showFileAttachments = false,
	showImageAttachments = false,
	showVideoAttachments = false,
	profileLink,
	sourceLink,
	userId,
	subtitle,
	subtitleLink,
	userType,
	withoutUserGroups = false,
	classNameForHeader,
	classNameForAvatar,
	onClick,
	onSourceNameClicked,
	onProfileClicked,
}: IPostHeaderProps) => {
	const { userGroups } = useGetUserGroups(userId);
	const attachmentIcons = compact([
		showFileAttachments && (
			<FileAttachmentIcon
				className={styles.postHeader__attachmentIcon}
				height={12}
				key="FileAttachmentIcon"
				width={12}
			/>
		),
		showImageAttachments && (
			<ImageAttachmentIcon
				className={styles.postHeader__attachmentIcon}
				height={12}
				key="ImageAttachmentIcon"
				width={14}
			/>
		),
		showVideoAttachments && (
			<VideoAttachmentIcon
				className={styles.postHeader__attachmentIcon}
				height={12}
				key="VideoAttachmentIcon"
				width={14}
			/>
		),
	]);

	const shouldDisplaySecondLine = userGroups && !withoutUserGroups;
	const shouldShowSource = sourceName && sourceLink;
	const shouldDisplayThirdLine = attachmentIcons?.length || createdAt || subtitle || shouldShowSource;

	return (
		<div
			className={clsx(
				styles.postHeader,
				(!shouldDisplaySecondLine || !shouldDisplayThirdLine) && styles.postHeader_tighter,
				!shouldDisplaySecondLine && !shouldDisplayThirdLine && styles.postHeader_tightest,
				classNameForHeader,
			)}
		>
			<BlockLink
				className={clsx(styles.postHeader__avatarWrapper, classNameForAvatar)}
				to={profileLink as string}
				onClick={(event) => {
					event.stopPropagation();
					onProfileClicked?.();
				}}
			>
				<Avatar title={userName} {...avatarProps} />
			</BlockLink>
			<div className={styles.postHeader__body}>
				<div>
					<Link
						className={clsx(styles.postHeader__title, !profileLink && styles.postHeader__title_disabled)}
						to={profileLink ?? '#'}
						onClick={(event) => {
							event.stopPropagation();
							onProfileClicked?.();
						}}
					>
						{userName}
						{userType ? <span>{` ${userType}`}</span> : null}
					</Link>
					{shouldShowSource ? (
						<>
							<ChevronRightFilled className={styles.postHeader__chevronIcon} height={9} width={9} />
							<Link
								className={styles.postHeader__title}
								to={sourceLink}
								onClick={(event) => {
									event.stopPropagation();
									onSourceNameClicked?.();
								}}
							>
								{sourceName}
							</Link>
						</>
					) : null}
				</div>
				{shouldDisplaySecondLine ? <div className={styles.postHeader__groups}>{userGroups}</div> : null}

				<div className={styles.postHeader__subtitle}>
					{attachmentIcons}
					{createdAt ? <RelativeTime className={styles.postHeader__relativeTime} dateString={createdAt} /> : null}
					{subtitle ? (
						<>
							<span className={styles.postHeader__subtitle_text}>{` • `}</span>
							<Link
								className={clsx(styles.postHeader__subtitle_text, subtitleLink && styles.postHeader__subtitle_link)}
								to={subtitleLink ?? '#'}
								onClick={(event) => {
									event.stopPropagation();
									onClick?.();
								}}
							>
								{subtitle}
							</Link>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

export interface IPostHeaderProps {
	avatarProps: IAvatarProps;
	userName: string;
	userId: string | undefined;
	sourceName?: TCommunity['name'] | string;
	createdAt?: TIsoDate;
	showFileAttachments?: boolean;
	showImageAttachments?: boolean;
	showVideoAttachments?: boolean;
	profileLink?: string;
	sourceLink?: string;
	subtitle?: string;
	subtitleLink?: string;
	userType?: string;
	withoutUserGroups?: boolean;
	classNameForHeader?: string;
	classNameForAvatar?: string;
	onClick?: () => void;
	onSourceNameClicked?: () => void;
	onProfileClicked?: () => void;
}

export default PostHeader;
