import { useTranslation } from '@hooks';
import { Post } from '@ui-kit';
import { toString, extractIdFromIri } from '@utils';
import { ROUTES } from '@constants';
import type { TSearchDiscussion } from '@typings';
import styles from './FeedActivitySearchCard.module.css';

/** Post card showed as search result. */
const FeedActivitySearchCard = ({ searchQuery, feedActivitySearchResult }: IFeedActivitySearchCardProps) => {
	const { t } = useTranslation();

	// Extract data pieces.
	const title = toString(feedActivitySearchResult.title);
	const content = toString(feedActivitySearchResult.content);
	const userName = `${feedActivitySearchResult.owner.firstName} ${feedActivitySearchResult.owner.lastName}`;
	const isChair = feedActivitySearchResult.owner.profileType === 'Chair';
	const hasFiles = !!feedActivitySearchResult.attachments?.files?.length;
	const hasImages = !!feedActivitySearchResult.attachments?.images?.length;
	const hasVideos = !!feedActivitySearchResult.attachments?.videos?.length;
	const profileId = extractIdFromIri(feedActivitySearchResult.owner.profileIri);
	const postLink = ROUTES.post(feedActivitySearchResult.streamActivityId, feedActivitySearchResult.streamFeedId);

	return (
		<Post
			className={styles.feedActivity__post}
			headerElement={
				<Post.Header
					avatarProps={{
						imageUri: feedActivitySearchResult.owner.avatar,
						outline: isChair,
						size: 40,
					}}
					createdAt={feedActivitySearchResult.createdAt}
					profileLink={ROUTES.memberProfile(profileId)}
					showFileAttachments={hasFiles}
					showImageAttachments={hasImages}
					showVideoAttachments={hasVideos}
					sourceName={feedActivitySearchResult.community}
					userId={feedActivitySearchResult.owner.id}
					userName={userName}
				/>
			}
			isCard
			postContentLink={postLink}
			postLink={postLink}
		>
			<>
				<Post.Title highlightingText={searchQuery}>{title}</Post.Title>
				<Post.Content
					collapsedNumberOfLines={2}
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					highlightingText={searchQuery}
				>
					{content}
				</Post.Content>
			</>
		</Post>
	);
};

export interface IFeedActivitySearchCardProps {
	/** Search result data given from a search engine. */
	feedActivitySearchResult: TSearchDiscussion;
	/** Used search query to highlight text within the card. */
	searchQuery: string;
}

export default FeedActivitySearchCard;
