import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TProfileDeal, TUserProfileRecord } from '@typings';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetUserDealsQuery = <T = TProfileDeal[]>(
	profileId: TUserProfileRecord['id'] | undefined,
	options?: UseQueryOptions<TProfileDeal[], AxiosError, T>,
) => {
	const api = useService('ApiService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();
	const reactQuery = useService('ReactQueryService');

	return useQuery<TProfileDeal[], AxiosError, T>(
		reactQuery.queryKeys.getProfileDeal(profileId),
		async () => api.invest.getUserDeals(profileId as TUserProfileRecord['id']),
		{
			enabled: !isWaiverLoading && !isMessageShown,
			...options,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
				options?.onError?.(error);
			},
		},
	);
};
