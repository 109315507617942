import { GLOBAL_SEARCH } from '@constants';
import { useEffect, useQuery, useQueryClient, useService } from '@hooks';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TSearchData } from '@typings';

export const useGlobalSearchResults = <T = TSearchData>(
	searchQuery: string,
	debouncedSearchQuery?: string,
	options?: UseQueryOptions<TSearchData, unknown, T, string[]>,
) => {
	const algoliaSearch = useService('AlgoliaSearchService');
	const queryClient = useQueryClient();

	const { enabled: isDealsEnabled } = useDealsEnabled();
	const finalSearchQuery = (debouncedSearchQuery || searchQuery).trim();
	const query = useQuery<TSearchData, unknown, T, string[]>(
		['globalSearch'],
		async () =>
			await algoliaSearch.searchByAllIndexes(finalSearchQuery, {
				omitDefinitions: isDealsEnabled ? undefined : ['deal', 'dealDocuments'],
			}),
		{
			enabled: false,
			staleTime: 0,
			cacheTime: 0,
			...options,
		},
	);

	useEffect(() => {
		if (searchQuery?.length < GLOBAL_SEARCH.validSearchQueryLength) {
			queryClient.removeQueries(['globalSearch']);
		}
	}, [searchQuery]);

	useEffect(() => {
		if (searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength) {
			query.refetch();
		}
	}, [debouncedSearchQuery]);

	return query;
};
