import { ReplyIcon } from '@ui-kit/icons';
import type { MouseEventHandler, KeyboardEventHandler } from 'react';
import styles from './ReplyButton.module.css';

/** Button to use under posts and comments. */
const ReplyButton = ({ replyTitle, size, onClickReply }: IReplyButtonProps) => {
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.stopPropagation();
		onClickReply();
	};

	return (
		<button className={styles.replyButton} data-size={size} onClick={handleClick}>
			<ReplyIcon className={styles.replyButton__icon} />
			<span>{replyTitle}</span>
		</button>
	);
};

export interface IReplyButtonProps {
	/** Title of the button. */
	replyTitle: string;
	/** Affects the font size of the text title & icon size. */
	size: 'medium' | 'small';
	/** Called when button clicked. */
	onClickReply: () => void;
}

export default ReplyButton;
