import { clsx } from '@utils';
import styles from './SideActionButton.module.css';
import ActivityIndicator from '../ActivityIndicator';

const SideActionButton = ({ onClick, title, Icon, disabled, position, loading }: ISideActionButtonProps) => {
	return (
		<button
			className={clsx(styles.button, position === 'trailing' ? styles.button_trailing : styles.button_leading, {
				[styles.hover]: !disabled,
				[styles.active]: !disabled,
				[styles.disabled]: disabled,
			})}
			disabled={disabled}
			onClick={onClick}
		>
			{!loading ? (
				<>
					{Icon}
					{title}
				</>
			) : (
				<ActivityIndicator />
			)}
		</button>
	);
};

export interface ISideActionButtonProps {
	onClick: () => void;
	title: string;
	Icon?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	position: 'trailing' | 'leading';
}

export default SideActionButton;
