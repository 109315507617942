import { ROUTES } from '@constants';
import {
	useBlockedAndBlockingUsers,
	useCallback,
	useCollapse,
	useNavigate,
	useService,
	useState,
	useTranslation,
} from '@hooks';
import type { TProfile, TProfileDeal, TUser } from '@typings';
import { ActivityIndicator, Button, Card, Gap, EntityInformationCard } from '@ui-kit';
import styles from './ProfileDeals.module.css';
import JoinDealNetworkModal from './JoinDealNetworkModal';
import { extractIdFromIri, capitalize, DealEntity } from '@utils';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import SolicitationWaiverModal from '@ui-modules/deals/components/SolicitationWaiverModal';
import { useGetUserDealsQuery } from '@ui-modules/deals/hooks/useGetUserDealsQuery';

const ProfileDeals = ({ userName, isMe, profileId }: IProfileDealsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const {
		data: deals = [],
		isLoading: isDealsLoading,
		error: dealsError,
		refetch,
	} = useGetUserDealsQuery(profileId, {
		select: (deals) => deals.filter((deal) => !DealEntity.isBlocked(blockedAndBlockingUsers, deal)),
	});
	const { isMessageShown, isLoading: isWaiverLoading, latestWaiver, onSubmit } = useSolicitationWaiver();

	const [selectedDeal, setSelectedDeal] = useState<TProfileDeal | null>(null);
	const [isModalShown, setIsModalShown] = useState(false);

	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

	const onDealCardPress = (deal: TProfileDeal) => {
		if (!deal.community.membership?.id) {
			setSelectedDeal(deal);
		} else {
			navigate(ROUTES.dealTabs(deal?.id));
		}
	};

	const renderDealItem = useCallback(
		(deal: TProfileDeal) => (
			<EntityInformationCard
				avatarSize={40}
				avatarUrl={deal.creator.avatarUrl}
				className={styles.dealPost}
				communitiesForBadge={deal.creator.communitiesForBadge?.shortNames}
				createdAt={deal.createdAt}
				creatorName={`${deal.creator.fullName} (${capitalize(deal.creatorType)})`}
				key={deal.id}
				profileLink={ROUTES.memberProfile(extractIdFromIri(deal.creator.profileIri))}
				subtitleAdditionalInfo={deal.community.name}
				subtitleLink={ROUTES.networkTab(deal.community.id, 'deals')}
				title={`${deal?.status === 'closed' ? t('CLOSED:') : deal?.status === 'cancelled' ? t('CANCELLED:') : ''}${
					deal.name
				}`}
				withArrow={false}
				marginMode={false}
				onClick={() => onDealCardPress(deal)}
			/>
		),
		[deals],
	);

	const solicitationWaiverMessage = isMe
		? t('To see your Deals, read and confirm the Solicitation Waiver.')
		: t('To see this Member’s Deals, read and confirm the Solicitation Waiver.');
	const emptyListMessage = isMe
		? t('You haven’t yet indicated interest in any Deals. When you do, those Deals will show here.')
		: `${userName} ${t('hasn’t yet indicated interest in any Deals. When they do, those Deals will show here.')}`;

	if (isMessageShown && !isWaiverLoading) {
		return (
			<Card articleMode="transparent" backgroundPlacement="article" title={t('My Deals')}>
				<div className={styles.deals__container}>
					<div className={styles.deals_waiverContainer}>
						<span className={styles.deals__emptyMessage}>{solicitationWaiverMessage}</span>
						<Button
							title={t('Review Solicitation Waiver')}
							onClick={() => {
								setIsModalShown(true);
								analytics.trackEvent('DealsSolicitationWaiverViewed', {
									action: 'waiver_reviewed',
								});
							}}
						/>
					</div>
				</div>
				{isModalShown && latestWaiver && (
					<SolicitationWaiverModal
						content={latestWaiver.body}
						version={latestWaiver?.version}
						onAgree={() => {
							onSubmit();
							setIsModalShown(false);
							analytics.trackEvent('DealsSolicitationWaiverViewed', {
								action: 'waiver_agreed',
							});
						}}
						onClose={() => {
							setIsModalShown(false);
							analytics.trackEvent('DealsSolicitationWaiverViewed', {
								action: 'waiver_declined',
							});
						}}
					/>
				)}
			</Card>
		);
	}

	if (dealsError) {
		// Don't display deals section if member deals is disabled. If deals disabled the 404 error will be return.
		return null;
	}

	return (
		<>
			{isDealsLoading || isWaiverLoading ? (
				<div className={styles.loading__container}>
					<ActivityIndicator />
				</div>
			) : (
				<Card articleMode="transparent" backgroundPlacement="article" title={t('My Deals')}>
					<div className={styles.deals__container}>
						{!deals.length ? (
							<span className={styles.deals__emptyMessage}>{emptyListMessage}</span>
						) : (
							<>
								<div className={styles.dealCard__container}>
									{deals.slice(0, 9).map((deal) => renderDealItem(deal))}
								</div>
								<div data-testid={`CollapsibleProfileDeals_${profileId}`} {...getCollapseProps()}>
									<Gap gap={16} />
									<div className={styles.dealCard__container}>{deals.slice(9).map((deal) => renderDealItem(deal))}</div>
								</div>
								{deals.length > 9 && (
									<button className={styles.deal__allButton} {...getToggleProps()} aria-label="toggle">
										<span>
											{!isExpanded ? t('Show All {{numberOfDeal}}', { numberOfDeal: deals.length }) : t('Show less')}
										</span>
									</button>
								)}
							</>
						)}
					</div>
					{selectedDeal && (
						<JoinDealNetworkModal
							deal={selectedDeal}
							onClose={() => setSelectedDeal(null)}
							onJoinSuccess={() => {
								setSelectedDeal(null);
								refetch();
								navigate(ROUTES.dealTabs(selectedDeal?.id));
							}}
							onRequestSuccess={() => {
								setSelectedDeal(null);
								refetch();
							}}
						/>
					)}
				</Card>
			)}
		</>
	);
};

export interface IProfileDealsProps {
	userName: TUser['firstName'];
	isMe?: boolean;
	profileId: TProfile['id'];
}

export default ProfileDeals;
