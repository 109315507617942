import { useCallback } from '@hooks';
import { useAdImageGalleryQuery } from '@ui-modules/account/hooks/useAddImageGalleryQuery';
import { clsx } from '@utils';
import styles from './AdImageGalleyWidget.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import type { CarouselProps } from 'react-responsive-carousel';

const AdImageGalleyWidget = () => {
	const { data: adImages } = useAdImageGalleryQuery();

	const handleClick = (index: number) => {
		if (!adImages?.length) return;
		const targetLink = adImages[index].link;
		window.open(targetLink, 'blank');
	};

	const renderIndicator = useCallback<CarouselProps['renderIndicator']>(
		(clickHandler, isSelected, index) => (
			<li className={styles.adImageGalleyWidget__dotItem} key={index} value={index}>
				<button
					aria-label={`slide item ${index}`}
					className={clsx(styles.adImageGalleyWidget__dot, isSelected && styles.adImageGalleyWidget__dot_selected)}
					onClick={clickHandler}
				/>
			</li>
		),
		[],
	);

	if (!adImages?.length) return null;
	return (
		<Carousel
			autoPlay
			axis="horizontal"
			className={styles.adImageGalleyWidget}
			dynamicHeight={false}
			infiniteLoop
			renderIndicator={renderIndicator}
			showArrows={false}
			showIndicators
			showStatus={false}
			showThumbs={false}
			stopOnHover
			swipeable
			useKeyboardArrows
			onClickItem={handleClick}
		>
			{adImages.map((adImage) => (
				<img
					alt={adImage.link}
					className={styles.adImageGalleyWidget__image}
					key={adImage.imageUrl}
					src={adImage.imageUrl}
				/>
			))}
		</Carousel>
	);
};

export default AdImageGalleyWidget;
