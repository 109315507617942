import React from 'react';
import { useTranslation } from '@hooks';
import { clsx } from '@utils';
import { PieChartIcon } from '@ui-kit';
import styles from './ModalHeader.module.css';

const ModalHeader = ({ mode = 'small' }: TAssetAllocationModalHeaderProps) => {
	const { t } = useTranslation();
	const iconSize = mode === 'large' ? 54 : 36;
	return (
		<div
			className={clsx(
				styles.slider__headerWrapper,
				mode === 'large' ? styles.slider__headerWrapper_large : styles.slider__headerWrapper_small,
			)}
		>
			<PieChartIcon width={iconSize} />
			<div
				className={clsx(
					styles.slider__headerWrapperBody,
					mode === 'large' ? styles.slider__headerWrapperBody_large : styles.slider__headerWrapperBody_small,
				)}
			>
				<p className={styles.slider__headerWrapperBodyTitle}>{t('Introducing')}...</p>
				<p
					className={clsx(styles.slider__headerWrapperBodySubTitle)}
					style={{ fontSize: mode === 'large' ? '28px' : '21px', lineHeight: mode === 'large' ? '32px' : '24px' }}
				>
					{t('Asset Allocation')}
				</p>
			</div>
		</div>
	);
};

type TAssetAllocationModalHeaderProps = {
	mode?: 'large' | 'small';
};

export default ModalHeader;
