import type { IStorage } from '../interfaces/Storage.interface';

export class LocalStorageService implements IStorage {
	static inject = [] as const;

	async getItem(key: string): Promise<string | null> {
		return await localStorage.getItem(key);
	}
	async setItem(key: string, value: string): Promise<void> {
		return await localStorage.setItem(key, value);
	}
	async removeItem(key: string): Promise<void> {
		return await localStorage.removeItem(key);
	}
}
