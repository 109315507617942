import { clsx, isEqual, map, noop, onClipboardPaste } from '@utils';
import { ChatAutoComplete, useMessageInputContext } from 'stream-chat-react';
import {
	useState,
	useEffect,
	useChat,
	useNavigate,
	useTranslation,
	useCallback,
	useMemo,
	useService,
	useNotification,
} from '@hooks';
import { useChatAttachmentsState } from '@ui-modules/chat/hooks/useChatAttachmentsState';
import { usePasteCopiedChatMessageEffect } from '@ui-modules/chat/hooks/usePasteCopiedChatMessageEffect';
import MessageInputAttachmentPreview from '@ui-modules/chat/components/MessageInputAttachmentPreview';
import { useSendMessage } from '@ui-modules/chat/hooks/useSendMessage';
import ChatEventMessage from '@ui-modules/chat/components/ChatEventMessage';
import ChatInputButtons from '@ui-modules/chat/components/ChatInputButtons';
import SendButton from '@ui-modules/chat/components/SendButton';
import EditMessageContainer from '@ui-modules/chat/components/EditMessageContainer';
import { STREAM_CHAT_BASE_URL } from '@constants';
import { ActivityIndicator, CloseIcon } from '@ui-kit';
import type { TAttachments, TEvent } from '@typings';
import styles from './ChatInputBar.module.css';
import {
	getChatInputPlaceholder,
	getMessageAttachment,
	shouldDisableSendButton,
	shouldEnableKindOfMediaAttachment,
} from './ChatInputBar.utils';
import ChatEventsModal from '../ChatEventsModal';
import EventItem from '@ui-modules/events/components/EventItem';
import { ROUTES, IMAGE, FILE, EVENT, GIPHY, MAXIMUM_ALLOWED_ATTACHMENTS_COUNT } from '@constants';
import React from 'react';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import type { Attachment } from 'stream-chat';

const ChatInputBar = ({ onMaximumFilesAmountReached, disabled, cid }: IChatInputBarProps) => {
	const { t } = useTranslation();
	const { setText, text, textareaRef, handleChange, handleSubmit } = useMessageInputContext();
	const { draftMessage, updateMessageDraft, removeMessageDraft } = useDraftAPI(cid as string);

	const navigate = useNavigate();
	const [showEventsModal, setShowEventsModal] = useState(false);
	const [showGifInput, setShowGifInput] = useState<boolean>(false);
	const { editMessage, quotedMessageId, reset, selectedEvent, setSelectedEvent, setEditMessage } = useChat();
	const reactQuery = useService('ReactQueryService');
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();

	useEffect(() => {
		const draftSelectedEvent = draftMessage?.selectedEvent;

		if (!selectedEvent && draftSelectedEvent) {
			setSelectedEvent(draftSelectedEvent);
		} else {
			updateMessageDraft({ selectedEvent });
		}
	}, [selectedEvent]);

	useEffect(() => {
		setSelectedEvent(draftMessage?.selectedEvent ?? null);

		if (draftMessage?.editingMessage) {
			setEditMessage({
				...draftMessage.editingMessage,
				text: draftMessage?.text,
				attachments: draftMessage?.attachments,
				eventId: draftMessage?.selectedEvent?.id,
			});
		}
	}, []);

	const toggleShowGifInput = () => {
		setShowGifInput((value) => {
			const newValue = !value;
			if (newValue) {
				textareaRef.current?.focus();
			}
			return newValue;
		});
	};

	const FileAttachments = useChatAttachmentsState({
		onlyFileAccepted: true,
		shouldUploadToStream: true,
		isChat: true,
		cid,
		forbidDuplicates: true,
	});
	const MediaAttachments = useChatAttachmentsState({
		onlyMediaAccepted: true,
		shouldUploadToStream: true,
		isChat: true,
		cid,
	});

	const attachments = useMemo(
		() => [...FileAttachments.attachments, ...MediaAttachments.attachments],
		[FileAttachments.attachments, MediaAttachments.attachments],
	);

	const loadingStateAttachments = useMemo(
		() => [...FileAttachments.loadingStateAttachments, ...MediaAttachments.loadingStateAttachments],
		[FileAttachments.loadingStateAttachments, MediaAttachments.loadingStateAttachments],
	);

	const setAttachments = (attachments: TAttachments[]) => {
		MediaAttachments.setAttachments(
			attachments.filter(
				(attachment) => attachment.type !== 'file' && attachment.fileType !== 'file',
			) as TAttachments[],
		);
		FileAttachments.setAttachments(
			attachments.filter(
				(attachment) => attachment.type === 'file' || attachment.fileType === 'file',
			) as TAttachments[],
		);
	};

	const { onSendPressed, onGiphySendPressed } = useSendMessage(editMessage, quotedMessageId);

	const isFilesLoading = !!FileAttachments.loadingStateAttachments.length;
	const isMediaLoading = !!MediaAttachments.loadingStateAttachments.length;
	const isLoading = isFilesLoading || isMediaLoading;
	const isEventEmpty = !editMessage?.eventId;

	const isMessageEmpty = shouldDisableSendButton(attachments, text, selectedEvent) || isLoading;

	const shouldDisableEditSendButton = () => {
		const attachmentsUrls = map(editMessage?.attachments || [], (item) => item.image_url || item.asset_url);
		const isAttachmentsEqual = isEqual(
			attachmentsUrls.sort(),
			map([...(MediaAttachments.attachments || []), ...(FileAttachments.attachments || [])], 'url').sort(),
		);

		const isEventEqual = (editMessage?.eventId ?? undefined) === (selectedEvent?.id ?? undefined);
		return isMessageEmpty || ((editMessage?.text || '') === (text || '') && isAttachmentsEqual && isEventEqual);
	};

	const disableSendButton = editMessage ? shouldDisableEditSendButton() : isMessageEmpty;

	const fetchCalendarItem = (calendarItemId: string | null | undefined) => {
		if (!calendarItemId) {
			setSelectedEvent(null);
			updateMessageDraft({ selectedEvent: null });
			return;
		}
		reactQuery.queryClient
			.fetchQuery(['calendarItem.getCalendarItem', calendarItemId], async () =>
				api.calendarItem.getCalendarItem(calendarItemId),
			)
			.then((event) => {
				setSelectedEvent(event);
				updateMessageDraft({ selectedEvent: event });
			})
			.catch(showUnknownError);
	};

	useEffect(() => {
		if (editMessage || quotedMessageId) {
			textareaRef.current?.focus();
			if (editMessage?.text?.trim()) {
				if (editMessage.eventId) {
					const calendarItemId = editMessage.eventId as TEvent['id'];
					fetchCalendarItem(calendarItemId);
				}
				updateMessageDraft({ text: editMessage.text, editingMessage: editMessage });
				setText(editMessage.text);
			}
			if (selectedEvent) {
				setAttachments([]);
				updateMessageDraft({ selectedEvent, attachments: [] });
			}
			if (editMessage?.attachments && editMessage.attachments.length > 0) {
				setSelectedEvent(null);
				const isDraftMessageAttachment = (item: Attachment) => 'fileType' in item;
				const filterPreviewUrlImages = editMessage.attachments?.filter(
					(item) =>
						item.image_url?.includes(STREAM_CHAT_BASE_URL) ||
						item.asset_url?.includes(STREAM_CHAT_BASE_URL) ||
						isDraftMessageAttachment(item), // skip for draft message
				);

				const attachments = getMessageAttachment(filterPreviewUrlImages) as TAttachments[];

				setAttachments(attachments);
				updateMessageDraft({ attachments, selectedEvent: null });
			}
		}
	}, [editMessage, quotedMessageId]);

	usePasteCopiedChatMessageEffect(text, setText, setAttachments, fetchCalendarItem);

	const onEventPressed = (event: TEvent) => {
		if (event['@type'] === 'Event') {
			navigate(ROUTES.viewEvent(event.id));
		} else if (event['@type'] === 'Meeting') {
			navigate(ROUTES.viewMeeting(event.id));
		}
	};

	/** reset data when message send */
	const resetData = () => {
		FileAttachments.setAttachments([]);
		FileAttachments.setLoadingStateAttachments([]);
		MediaAttachments.setAttachments([]);
		MediaAttachments.setLoadingStateAttachments([]);
		setText('');
		reset();
		setSelectedEvent(null);
		removeMessageDraft();
		setShowGifInput(false);
	};

	const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
		(event) => {
			const deletePressed =
				event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'deleteContentBackward';

			if (text.length === 1 && deletePressed) {
				setShowGifInput(false);
			}

			if (text.startsWith('/giphy')) {
				setShowGifInput(true);
			}
			updateMessageDraft({ text: event.target.value });

			handleChange(event);
		},
		[text, setShowGifInput], // eslint-disable-line
	);

	const onSubmit = async (event: React.BaseSyntheticEvent) => {
		const currentEvent = event as unknown as KeyboardEvent;
		event.preventDefault();
		if (currentEvent.keyCode === 13 && !disableSendButton) {
			handleSendPressed(event);
		}
	};

	const handleSendPressed = (event: React.BaseSyntheticEvent) => {
		removeMessageDraft();

		if (showGifInput) {
			const gifMessage = text.startsWith('/giphy')
				? { quoted_message_id: quotedMessageId }
				: { text: `/giphy ${text}`, quoted_message_id: quotedMessageId };
			handleSubmit(event, gifMessage);
			onGiphySendPressed();

			resetData();
		} else {
			if (attachments.length > MAXIMUM_ALLOWED_ATTACHMENTS_COUNT) {
				return onMaximumFilesAmountReached();
			}
			if (!disableSendButton) {
				onSendPressed({ attachments, onSuccess: resetData, text, selectedEvent }).then((success) => {
					if (success) {
						resetData();
					}
				});
			}
		}
	};

	// Conditions for disabling icons and change styles of them
	const shouldEnableImage = shouldEnableKindOfMediaAttachment(
		disabled,
		IMAGE,
		editMessage,
		attachments,
		selectedEvent,
		showGifInput,
	);
	const shouldEnableFile = shouldEnableKindOfMediaAttachment(
		disabled,
		FILE,
		editMessage,
		attachments,
		selectedEvent,
		showGifInput,
	);
	const shouldEnableEvent = shouldEnableKindOfMediaAttachment(
		disabled,
		EVENT,
		editMessage,
		attachments,
		selectedEvent,
		showGifInput,
	);
	const shouldEnableGiphy = shouldEnableKindOfMediaAttachment(
		disabled,
		GIPHY,
		editMessage,
		attachments,
		selectedEvent,
		showGifInput,
	);

	const renderAttachments = ({ data }: TRenderAttachments) => {
		return data.length > 0 ? (
			<div className={styles.chatInputBar_attachmentContainer}>
				{data.map((item) => (
					<React.Fragment key={item.id}>
						<MessageInputAttachmentPreview
							attachment={item}
							onClosePressed={
								/*
								 * If the attachments are still loading, we don't want to remove them
								 * because they are not yet added to the list of attachments
								 * and removing them will cause an error.
								 * The reason is bad implementation of having 2 arrays of attachments.
								 */
								// isLoading ? noop :
								item?.isFile ? FileAttachments.removeAttachment : MediaAttachments.removeAttachment
							}
						/>
					</React.Fragment>
				))}
				{isLoading ? (
					<div className={styles.activityIndicator}>
						<ActivityIndicator />
					</div>
				) : null}
			</div>
		) : null;
	};

	const onEventActionPressed = () => setShowEventsModal(true);
	return (
		<div className="str-chat__input-flat str-chat__input-flat--send-button-active">
			<div className={styles.chatInputBar_container}>
				<EditMessageContainer
					editMessage={!!editMessage}
					quotedMessageId={quotedMessageId}
					resetData={resetData}
					showGifInput={showGifInput}
				/>

				<div className={styles.chatInputBar}>
					<div className={styles.chatInputBar__attachmentButtonsSection}>
						<input {...FileAttachments.getInputProps()} readOnly={true} />
						{/* hidden input to make file upload working */}
						<input {...MediaAttachments.getInputProps()} readOnly={true} />
						{/* hidden input to make media upload working */}
						<ChatInputButtons
							isEventEnable={shouldEnableEvent && !isFilesLoading && !isMediaLoading}
							isFileEnable={shouldEnableFile && !isMediaLoading}
							isGiphyEnable={shouldEnableGiphy && !isFilesLoading && !isMediaLoading}
							isImageEnable={shouldEnableImage && !isFilesLoading}
							toggleShowGifInput={toggleShowGifInput}
							onEventActionPressed={onEventActionPressed}
							onFileActionPressed={shouldEnableFile ? FileAttachments.uploadAttachments : noop}
							onImageActionPressed={shouldEnableImage ? MediaAttachments.uploadAttachments : noop}
						/>
					</div>

					<div className={styles.chatInputBar__inputSection}>
						<div className={clsx(styles.chatInputBar__inputTextArea, disabled && styles.chatInputBar_disabled)}>
							<div>{renderAttachments({ data: [...attachments, ...loadingStateAttachments] })}</div>
							<ChatAutoComplete
								handleSubmit={onSubmit}
								placeholder={getChatInputPlaceholder(t)({ showGifInput, disabled })}
								value={text}
								onChange={onChange}
								onPaste={(event) => onClipboardPaste(event, (files) => MediaAttachments.onAddAttachmentsSuccess(files))}
							/>
							<>
								{!editMessage && selectedEvent ? (
									<div className={styles.event}>
										<EventItem
											className={styles.event__item}
											event={selectedEvent}
											eventTypeClassName={styles.event__itemType}
											hideBanner
											hideChevronIcon
											shouldShowMeetingAction={false}
											onClick={() => onEventPressed(selectedEvent)}
										/>
										<button
											className={styles.buttonRemove}
											onClick={() => {
												setSelectedEvent(null);
												updateMessageDraft({ selectedEvent: null });
											}}
										>
											<CloseIcon height={8} width={8} />
										</button>
									</div>
								) : null}

								{editMessage && (isEventEmpty ? isEventEmpty : editMessage?.eventId) && !editMessage?.deleted_at && (
									<>
										{selectedEvent && <ChatEventMessage cid={cid} eventId={selectedEvent?.id as string} isRemovable />}
									</>
								)}
							</>
						</div>
					</div>
					<SendButton disable={disableSendButton} handleSendPressed={handleSendPressed} showGifInput={showGifInput} />
				</div>
			</div>
			<ChatEventsModal
				setSelectedEvent={(event) => {
					setSelectedEvent(event);
					updateMessageDraft({ selectedEvent: event });
				}}
				visible={showEventsModal}
				onClose={() => setShowEventsModal(false)}
			/>
		</div>
	);
};

export type TRenderAttachments = {
	data: TAttachments[];
};

interface IChatInputBarProps {
	onMaximumFilesAmountReached: () => void;
	disabled: boolean;
	cid?: string;
}

export default ChatInputBar;
