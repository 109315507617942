import { useMe, useMutation, useNotification, useService, useTranslation } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import { ActivityEntity } from '@tiger21-llc/connect-shared/src/utils';
import type { MutateOptions } from '@tanstack/react-query';
import type { TActivity, TComment } from '@typings';

export const useDeleteReactionMutation = (
	feedSlug: string,
	options?: MutateOptions<void, Error, TUseDeleteReactionMutationPayload>,
) => {
	const { t } = useTranslation();
	const { showSuccess, showUnknownError } = useNotification();
	const feedContext = useFeedContext();
	const feedService = useService('FeedService');
	const reactQuery = useService('ReactQueryService');
	const updateActivityInFeedCache = useUpdateActivityInFeedCache();
	const { user } = useMe();

	return useMutation<void, Error, TUseDeleteReactionMutationPayload>(
		['feed.onRemoveReaction'],
		async ({ activity, comment }) => {
			const itsOwnReaction = user.slug === comment.user?.id;
			if (!itsOwnReaction) await feedService.deleteReaction(comment.id); // Stream doesn't allow to remove foreign reactions. The straight API call is used here to avoid.
			// Next methods should be called anyway to trigger internal GetStream state updates. The permission errors are suppressed by the patch. (@see T21C-7015)  [@DmitriyNikolenko]

			const isReply = !!comment.parent;
			if (isReply) {
				await feedContext.onRemoveChildReaction('comment', comment.parent as any, comment.id); // Interface requires the whole reaction but indeed the parent comment ID is enough to delete [@dmitriy.nikolenko].
			} else {
				await feedContext.onRemoveReaction('comment', activity as any, comment.id);
			}
		},
		{
			...options,
			async onSuccess(data, variables, context) {
				const isReply = !!variables.comment.parent;

				if (!isReply) {
					// updates only high-level comments to reflect comments counter (replies are not counted). [@dmitriy.nikolenko]
					const updatedActivity = await updateActivityInFeedCache(variables.activity.id, {}, feedSlug);
					await updateActivityInFeedCache(variables.activity.id);
					if (ActivityEntity.isNetworkConnectionActivity(variables.activity)) {
						const communityId = ActivityEntity.getCommunityId(variables.activity);
						updatedActivity &&
							reactQuery.updateNetworkConnectionReactionsInCache(updatedActivity, communityId as string);
					}
				}

				const successMessage = isReply ? t('Your reply was deleted') : t('Your comment was deleted');
				showSuccess({ title: successMessage });

				options?.onSuccess?.(data, variables, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TUseDeleteReactionMutationPayload = { comment: TComment; activity: TActivity };
