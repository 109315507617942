import { useCalendarCell } from 'react-aria';
import { useRef } from 'react';
import styles from './Calendar.module.css';
import { clsx } from '@utils';
import type { ICalendarCellProps, TCalendarCellMode } from './Calendar.types';

export const CalendarCell = ({ state, date, isCurrent, mode }: ICalendarCellProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const { cellProps, buttonProps, isDisabled, formattedDate } = useCalendarCell({ date }, state, ref);

	const modes: Record<TCalendarCellMode, string> = {
		outlined: styles.calendarCell__btn_outlined,
		alert: styles.calendarCell__btn_alert,
		highlighted: styles.calendarCell__btn_highlighted,
	};

	return (
		<td {...cellProps}>
			<div className={styles.calendarCell__btnWrapper}>
				<div
					ref={ref}
					{...buttonProps}
					className={clsx(
						styles.calendarCell__btn,
						isDisabled && styles.calendarCell__btn_disabled,
						isCurrent && styles.calendarCell__btn_current,
						!isDisabled && mode && modes[mode],
					)}
				>
					<span>{formattedDate}</span>
				</div>
			</div>
		</td>
	);
};
