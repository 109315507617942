import type { TCommunitiesForBadge, TProfile, TRole } from '@typings';
import { translateUserRole } from '@utils';
import type { TFunction } from 'i18next';

export const formatUserContactInfo =
	(t: TFunction) =>
	(userRoles: TRole[], communitiesForBadge: TCommunitiesForBadge | undefined, profileTitle?: TProfile['title']) => {
		const isStaff = userRoles.includes('ROLE_STAFF');
		let translatedRole = translateUserRole(userRoles, t);
		if (isStaff && profileTitle) {
			translatedRole = `${translatedRole} - ${profileTitle}`;
		}

		const names = communitiesForBadge?.shortNames ?? [];
		const groups = names.join(', ');
		const contactInfo = groups && !isStaff ? translatedRole + ' - ' + groups : translatedRole;

		return contactInfo;
	};
