import { dayjs } from '@utils';
import timezoneAbbreviations from 'timezone-abbreviations';

export const useMyTimezone = () => {
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
	const timezone = timeZone || dayjs.tz.guess();

	return {
		formattedTimezone: findTimezoneShortName(timezone),
		timezone,
	};
};

export const findTimezoneShortName = (longTimezone: string) => {
	const shortTimezone = timezoneAbbreviations.find(
		(record) =>
			record.description === longTimezone ||
			record.names?.includes(longTimezone) ||
			record.offset === longTimezone?.replace('GMT', 'UTC'),
	);

	return shortTimezone?.abbr || longTimezone;
};
