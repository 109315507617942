import { ROUTES } from '@constants';
import { useNavigate, useNotification, useTranslation } from '@hooks';
import type { TModerator, TProfileWithUser, TUser } from '@typings';
import { Card } from '@ui-kit';
import { useChatEvents } from '@ui-modules/chat/hooks/useChatEvents';
import StaffCard from '@ui-modules/account/components/StaffCard';
import styles from './StaffList.module.css';

const StaffList = ({ staffProfiles }: { staffProfiles: TModerator[] }) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const navigate = useNavigate();
	const { isUserPaused } = useChatEvents();

	const onOpenChat = (slug: TUser['slug']) => {
		if (isUserPaused) {
			showInfo({ title: t('Chat has been temporarily disabled') });
		} else {
			navigate(ROUTES.chatChannel(slug));
		}
	};

	const onViewProfile = (profileId: TProfileWithUser['id']) => navigate(ROUTES.memberProfile(profileId));

	return (
		<Card backgroundPlacement="none" title={t('Chair & Staff')}>
			<div className={styles.staffList__container}>
				{staffProfiles.map((profile) => (
					<StaffCard key={profile.id} profile={profile} onOpenChat={onOpenChat} onViewProfile={onViewProfile} />
				))}
			</div>
		</Card>
	);
};

export default StaffList;
