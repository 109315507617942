import { useMutation, useService } from '@hooks';
import type { TFile, TMediaObject } from '@typings';

type TCreateDealFilesData = {
	files: TMediaObject[];
	relatedEntity: string;
};

export const useCreateDealPreviewFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<TFile[], Error, TCreateDealFilesData>(
		['deal.createDealPreviewFiles'],
		({ files, relatedEntity }: TCreateDealFilesData) => {
			return Promise.all(
				files.map((mediaObject) => {
					return api.dealFile.postDealPreviewFile({
						mediaObjectId: mediaObject.id,
						name: mediaObject.originalName,
						dealId: relatedEntity,
						openInViewerOnly: mediaObject.openInViewerOnly,
						status: 'new',
					});
				}),
			);
		},
	);
};
