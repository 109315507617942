import { CloseIcon } from '@ui-kit/icons';
import { clsx } from '@utils';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import Portal from '../Portal';
import styles from './ModalContainer.module.css';

const ModalContainer = ({
	visible,
	style = 'default',
	variant = 'medium',
	closeButtonMode = 'close',
	closeButtonPosition = 'left',
	position = 'center',
	title,
	cancelTitle,
	children,
	onClose,
	backdropClose = false,
	showClose = true,
}: IModalContainerProps) => {
	const contentRef = useRef(null);
	useClickAway(contentRef, () => (backdropClose ? onClose() : null));

	return (
		<>
			{visible ? (
				<Portal>
					<div
						className={clsx(styles.modal__container, style === 'default' && styles.modal__container_black)}
						data-position={position}
					>
						<div
							className={clsx(styles.modal__content, style === 'default' && styles.modal__content_black)}
							data-variant={variant}
							ref={contentRef}
						>
							<div
								className={clsx(
									showClose && styles.modal__header,
									closeButtonPosition === 'right' && styles.modal__header_right,
								)}
							>
								{showClose && (
									<button className={styles.modal__closeButton} onClick={onClose}>
										{closeButtonMode === 'cancel' ? (
											<span>{cancelTitle}</span>
										) : (
											<CloseIcon className={styles.modal__closeIcon} />
										)}
									</button>
								)}

								{title ? <span className={styles.modal__title}>{title}</span> : null}
								<div className={styles.modal__closeButton} />
							</div>
							{children}
						</div>
					</div>
				</Portal>
			) : null}
		</>
	);
};

export interface IModalContainerProps {
	visible: boolean;
	title?: string;
	style?: 'default' | 'transparent';
	variant?: 'small' | 'medium';
	position?: 'center' | 'top';
	closeButtonMode?: 'close' | 'cancel';
	closeButtonPosition?: 'right' | 'left';
	onClose: () => void;
	children: ReactNode;
	cancelTitle?: string;
	backdropClose?: boolean;
	showClose?: boolean;
}

export default ModalContainer;
