import FeedBanner from '@ui-modules/feed/components/FeedBanner';
import DeletePinnedBannerModal from '../DeletePinnedBannerModal';
import { useMe, useState } from '@hooks';
import { usePinnedBannerQuery } from '@ui-modules/feed/hooks/usePinnedBannerQuery';
import { useCrossPromotionsAnalytics } from '@ui-modules/feed/hooks/useCrossPromotionsAnalytics';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import styles from './PinnedBanners.module.css';
import type { TPinnedBanner } from '@typings';
import { clsx } from '@utils';

const PinnedBanners = ({ predicateToFilter, containerClassName }: IPinnedBannersProps) => {
	const { user } = useMe();
	const { data: banners = [], isFetched } = usePinnedBannerQuery(predicateToFilter);

	const [deletingPinnedBanner, setDeletingPinnedBanner] = useState<TPinnedBanner | null>(null);

	const handleClickLink = useHandleActivityLink();
	const { trackPinnedBannerClick } = useCrossPromotionsAnalytics();

	if (!banners.length || !isFetched) return null;
	return (
		<div className={clsx(styles.pinnedBanners, containerClassName)}>
			{banners.map((banner) => (
				<FeedBanner
					canDeletePost={user.roles.includes('ROLE_ADMIN')}
					imageUrl={banner.pinnedBannerImageUrl}
					key={banner['@id']}
					link={banner.pinnedBannerUrl}
					onClick={(link) => {
						trackPinnedBannerClick(link, banner);
						handleClickLink(link);
					}}
					onDelete={() => setDeletingPinnedBanner(banner)}
				/>
			))}

			{deletingPinnedBanner ? (
				<DeletePinnedBannerModal
					pinnedBanner={deletingPinnedBanner}
					visible={!!deletingPinnedBanner}
					onClose={() => setDeletingPinnedBanner(null)}
				/>
			) : null}
		</div>
	);
};

export interface IPinnedBannersProps {
	containerClassName?: string;
	predicateToFilter: (banner: TPinnedBanner) => boolean;
}

export default PinnedBanners;
