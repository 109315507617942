import { createSlice } from '@reduxjs/toolkit';
import { PUBLIC_AUTH_ROUTES, ROUTES } from '@constants';
import { appRouter } from '../../../../app/appRouter';

/** Broadcast slice is used to broadcast actions between multiple browser tabs. */
export const broadcastSlice = createSlice({
	name: 'broadcast',
	initialState: {},
	reducers: {
		signOut: () => {
			const appAlreadyOnAuthRoute = Object.values(PUBLIC_AUTH_ROUTES).some(
				(route) => window.location.pathname === route(),
			);
			if (!appAlreadyOnAuthRoute) {
				appRouter.navigate(ROUTES.signOut());
			}
		},
	},
});
