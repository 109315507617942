import { ROUTES } from '@constants';

export const getInAppPageRoute = (pageAlias: string) => {
	switch (pageAlias) {
		case 'member-guide-book':
			return ROUTES.memberGuideBook();
		case 'partner-benefits':
			return ROUTES.partnerBenefits();
		case 'tiger-pulse':
			return ROUTES.tigerPulse();
		case 'staff-and-support':
			return ROUTES.staffAndSupport();
		case 'refer-an-associate':
			return ROUTES.referAssociate();
		case 'covid-policies':
			return ROUTES.policies();
	}
};
