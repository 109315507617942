import type { TMediaObject, TMediaObjectCompact } from '@typings';
import { ActivityIndicator } from '@ui-kit';
import styles from './ImagePickerInput.module.css';

const ImagePickerInput = ({ label, value, loading, buttonTitle, onChange, onUpload }: IImagePickerInputProps) => {
	const onPickImage = async (img: File) => {
		const mediaObject = await onUpload(img);
		onChange(mediaObject);
	};

	return (
		<div>
			<span className={styles.image__label}>{label}</span>
			<div className={styles.image__container}>
				{loading && (
					<div className={styles.image__loading}>
						<ActivityIndicator size="large" type="fit" />
					</div>
				)}
				{value?.contentUrl ? (
					<img alt="profile_img" className={styles.image} src={value?.contentUrl} />
				) : (
					<div className={styles.image} />
				)}
				<input
					accept="image/*"
					className={styles.image__input}
					id={'image_file'}
					type="file"
					onChange={(event) => {
						if (!event?.target?.files?.[0]) return;
						onPickImage(event?.target?.files?.[0]);
					}}
				/>

				<label className={styles.image__button} htmlFor={'image_file'}>
					{buttonTitle}
				</label>
			</div>
		</div>
	);
};

export interface IImagePickerInputProps {
	label: string;
	value: TMediaObjectCompact | null | undefined;
	onChange: (value: TMediaObjectCompact) => void;
	onUpload: (imageFile: File) => Promise<TMediaObject>;
	buttonTitle: string;
	loading?: boolean;
}

export default ImagePickerInput;
