import { useState } from '@hooks';

import styles from './TabSelect.module.css';
import { useEffect } from 'react';

const TabSelect = ({ tabs, value = 0, onChange }: ITabSelectProps) => {
	const [tab, setTab] = useState<string | number>(value);

	useEffect(() => {
		setTab(value);
	}, [value]);

	return (
		<div className={styles.tabsWrapper}>
			{tabs.map(({ id, title }) => {
				return (
					<button
						className={styles.tab}
						key={id}
						style={{ backgroundColor: tab === id ? 'black' : 'white' }}
						type="button"
						onClick={() => {
							setTab(id);
							onChange(tabs[id as number]);
						}}
					>
						<p className={styles.tabText} style={{ color: tab === id ? 'white' : 'black' }}>
							{title}
						</p>
					</button>
				);
			})}
		</div>
	);
};

export type TTab = {
	id: number;
	title: string;
	value?: 'published' | 'closed' | null;
};
export interface ITabSelectProps {
	tabs: TTab[];
	value?: string | number;
	onChange: (selectedObj: TTab) => void;
	alertOnLongPress?: boolean;
}

export default TabSelect;
