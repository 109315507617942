import { Button } from '@ui-kit';
import { BellIcon, CheckMarkIcon, PieChartIcon } from '@ui-kit/icons';
import AssetAllocationEmptyState from '@ui-modules/assetAllocation/components/AssetAllocationEmptyState';
import { useTranslation } from '@hooks';
import { useNotifyWhenAssetAllocationReady } from '@ui-modules/assetAllocation/hooks/useNotifyWhenAssetAllocationReady';
import styles from './AssetAllocationCommunityEmptyState.module.css';
import type { TCommunity } from '@typings';

const AssetAllocationCommunityEmptyState = ({ communityId }: IAssetAllocationEmptyStateProps) => {
	const { t } = useTranslation();

	const { notificationEnabled, setNotificationEnabled, isLoading, isMutating } =
		useNotifyWhenAssetAllocationReady(communityId);

	return (
		<AssetAllocationEmptyState
			description={t('There’s not enough data to show meaningful results. Check back later, or get notified.')}
			footerMessage={notificationEnabled ? t('We’ll notify you when this page is ready.') : ''}
			IconComponent={PieChartIcon}
			title={t('Not Enough Data Yet')}
		>
			{notificationEnabled ? (
				<Button
					icon={<CheckMarkIcon className={styles.assetAllocationCommunityEmptyState__notificationButtonIcon_enabled} />}
					iconPosition="left"
					loading={isLoading || isMutating}
					title={t('Notify Me')}
					type="dark"
					variant="medium"
					onClick={() => setNotificationEnabled(false)}
				/>
			) : (
				<Button
					icon={<BellIcon className={styles.assetAllocationCommunityEmptyState__notificationButtonIcon_disabled} />}
					iconPosition="left"
					loading={isLoading || isMutating}
					title={t('Notify Me')}
					type="outline"
					variant="medium"
					onClick={() => setNotificationEnabled(true)}
				/>
			)}
		</AssetAllocationEmptyState>
	);
};
export interface IAssetAllocationEmptyStateProps {
	communityId: TCommunity['id'];
}

export default AssetAllocationCommunityEmptyState;
