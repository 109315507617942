import ApiVideo from '@ui-modules/media/components/ApiVideo';
import { VideoPlayIcon } from '@ui-kit/icons';
import { useLinkPreviewQuery } from '@ui-modules/media/hooks/useLinkPreviewQuery';
import styles from './UniversalVideo.module.css';
import clsx from 'clsx';
import type { IApiVideoProps } from '@ui-modules/media/components/ApiVideo';

const UniversalVideo = ({ videoUrl, responsive, isUrlPreview = false }: IUniversalVideoProps) => {
	const isApiVideoUrl = videoUrl.includes('api.video');
	const { data: linkPreviewData } = useLinkPreviewQuery(videoUrl, {
		enabled: !isApiVideoUrl,
	});
	const bannerUrl = linkPreviewData?.error === undefined ? linkPreviewData?.banner : undefined;

	if (isApiVideoUrl)
		return (
			<div className={clsx(styles.universalVideo__container, isUrlPreview && styles.universalVideo__container_preview)}>
				<ApiVideo responsive={responsive} videoUrl={videoUrl} />
			</div>
		);
	return bannerUrl ? (
		<div className={clsx(styles.universalVideo__container, isUrlPreview && styles.universalVideo__container_preview)}>
			<img alt="video preview" className={styles.universalVideo__image} src={bannerUrl} />
			<div className={styles.universalVideo__playIconContainer}>
				<VideoPlayIcon />
			</div>
			<a className={styles.universalVideo__link} href={videoUrl} rel="noreferrer" target="_blank">
				Open in new tab
			</a>
		</div>
	) : null;
};

export interface IUniversalVideoProps extends Pick<IApiVideoProps, 'videoUrl' | 'responsive'> {
	isUrlPreview?: boolean;
}

export default UniversalVideo;
