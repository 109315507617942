import type { TAttachments, TFullUser } from '@typings';
import type { TFunction } from 'i18next';

export const filterImageUrls = (attachments: TAttachments[]) =>
	attachments.filter(({ fileType }) => fileType === 'image').map(({ url }) => url || '');

export const mapSuggestionData = (users: TFullUser[] | undefined) =>
	users?.map((user) => ({
		...user,
		display: `${user.firstName} ${user.lastName}`,
		id: user.id,
	})) || [];

export const suggestionTitle = (t: TFunction) => (value?: string) => {
	if (!value) return 'Suggestions';
	return Number(value?.length) < 1 ? t('Suggestions') : t('Matches');
};

export const handleRemoveAttachment = (
	onRemoveAttachment: (attachment: TAttachments) => void,
	attachment: TAttachments,
) => onRemoveAttachment(attachment);
