import { clsx } from '@utils';
import type { ReactNode } from 'react';
import InputDescription from '../InputDescription';
import InputErrorMessage from '../InputErrorMessage';
import InputLabel from '../InputLabel';
import type { IRadioButtonProps } from '../RadioButton';
import RadioButton from '../RadioButton';
import styles from './RadioGroupInput.module.css';
import React from 'react';

const RadioGroupInput = <TValue extends string = string>({
	label,
	disabled,
	errorMessage,
	options,
	description,
	value,
	onChange,
	fixedHeight = false,
	labelType,
	backgroundColor,
}: IRadioGroupInputProps<TValue>) => {
	return (
		<div className={clsx(errorMessage && styles.input_error)}>
			<InputLabel className={clsx(labelType === 'bold' && styles.radioGroupInput__labelBold)} text={label} />
			<div
				className={
					fixedHeight
						? clsx(styles.radioGroupInput__radioButtonsWrapped, styles.radioGroupInput__radioButtons)
						: styles.radioGroupInput__radioButtons
				}
			>
				{options.map((option) => (
					<React.Fragment key={option.value}>
						<RadioButton
							backgroundColor={backgroundColor}
							disabled={disabled || option.disabled}
							errored={!!errorMessage}
							value={option.value === value}
							onChange={() => onChange(option.value)}
						>
							<div className={styles.radioGroupInput__labelWrapper}>
								<span>{option.label}</span>
								{!!option.subLabel && <span className={styles.radioGroupInput__subLabel}>{option.subLabel}</span>}
							</div>
						</RadioButton>
						{option?.children}
					</React.Fragment>
				))}
			</div>
			<InputDescription text={description} />
			{errorMessage ? <InputErrorMessage text={errorMessage} /> : null}
		</div>
	);
};

export type TRadioGroupInputOption<TValue extends string = string> = {
	label: string;
	value: TValue;
	disabled?: boolean;
	subLabel?: string | ReactNode;
	children?: ReactNode;
};
export interface IRadioGroupInputProps<TValue extends string = string> {
	/** Specify the name to group radio buttons into one value */
	options: TRadioGroupInputOption<TValue>[];
	/** String defining which is chosen on radio group. */
	value: TValue;
	/** Title of the whole input. */
	label?: string | undefined;
	/** If true forbids change value.*/
	disabled?: boolean;
	/** Help text displayed between label and error message. */
	description?: string;
	/** If exists displays red error message given from form validation. */
	errorMessage?: string;
	/** Returns a new checked value */
	onChange: (newValue: TValue) => void;
	fixedHeight?: boolean;
	labelType?: 'bold';
	/** Background color of radio icon */
	backgroundColor?: IRadioButtonProps['backgroundColor'];
}

export default RadioGroupInput;
