import Avatar from '../Avatar';
import styles from './MemberBlock.module.css';

const MemberBlock = ({ title, descriptions = [], fullName, avatarUrl, isChair, onClick }: IMemberBlockProps) => {
	return (
		<div className={styles.memberBlock__box}>
			<div>
				<Avatar imageUri={avatarUrl} outline={isChair} size={64} title={fullName} onClick={onClick} />
			</div>
			<div className={styles.memberBlock__body}>
				<span className={styles.memberBlock__title}>{title}</span>
				{descriptions.map((description, index) => (
					<span className={styles.memberBlock__description} key={index}>
						{description}
					</span>
				))}
			</div>
		</div>
	);
};

export interface IMemberBlockProps {
	fullName: string;
	avatarUrl?: string;
	isChair: boolean;
	title: string;
	descriptions?: string[];
	onClick: () => void;
}

export default MemberBlock;
