import { useCollapse, useService, useTranslation } from '@hooks';
import type { TInvestmentLevel } from '@typings';
import { Button, Card, LevelIcon, TagRows } from '@ui-kit';
import { translateInvestmentLevel } from '@utils';
import styles from './ProfileInvestmentExpertise.module.css';

const ProfileInvestmentExpertise = ({
	isInvisible,
	onEdit,
	canEdit,
	investmentLevel,
	investmentExpertise = [],
}: IProfileInvestmentExpertiseProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded: false,
		onExpandEnd: () => {
			analytics.trackEvent('ProfileInteractions', {
				interaction_type: 'Read More clicked',
				action_value: 'Investment Expertise',
			});
		},
	});
	const canExpand = investmentExpertise.length > 2;

	return isInvisible ? null : (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			title={t('Investment Expertise')}
		>
			<div className={styles.investment}>
				{canEdit ? <span>{t('Select your level of investment and investment types to display')}</span> : null}
				{!canEdit && !investmentExpertise.length ? <span>{t('Ask me about my investment expertise.')}</span> : null}
				<div className={styles.investment__title}>
					<LevelIcon height={18} width={18} />
					<span>{translateInvestmentLevel(investmentLevel, t)}</span>
				</div>
				<div>
					<TagRows mode="row" tags={investmentExpertise.slice(0, 2)} title={t('Investment types')} />
				</div>
				<div data-testid="CollapsibleProfileInvestment__content" {...getCollapseProps()}>
					<TagRows mode="row" tags={investmentExpertise.slice(2, investmentExpertise.length)} />
				</div>
				{canExpand && (
					<button className={styles.investment__button} {...getToggleProps()} aria-label="toggle">
						<span>{isExpanded ? t('See less') : t('See more')}</span>
					</button>
				)}
			</div>
		</Card>
	);
};

export interface IProfileInvestmentExpertiseProps {
	isInvisible?: boolean;
	investmentLevel: TInvestmentLevel;
	investmentExpertise: string[];
	canEdit: boolean;
	onEdit: () => void;
}

export default ProfileInvestmentExpertise;
