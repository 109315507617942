import { useCallback, useEffect, useMemo, useState } from '@hooks';
import { debounce } from '@utils';

/**
 * Custom hook to prevent rapid consecutive form submissions after reset.
 *
 * This hook implements a temporary block on the Apply button when filters are reset
 * to prevent race conditions that could occur from rapid reset -> apply actions.
 *
 * @returns {{isApplyBlocked: boolean, handleReset: () => void}} An object containing:
 * - isApplyBlocked: boolean indicating if Apply action is currently blocked
 * - handleReset: function to trigger the temporary block on reset
 *
 * @example
 * const { isApplyBlocked, handleReset } = useApplyBlocker();
 * // Pass isApplyBlocked to disable your applied button
 * <Button disabled={isApplyBlocked} />
 * // Pass handleReset to your reset handler
 * <ResetButton onClick={handleReset} />
 */
export const useApplyBlocker = (): { isApplyBlocked: boolean; handleReset: () => void } => {
	const [isApplyBlocked, setIsApplyBlocked] = useState(false);

	const unblock = useCallback(() => setIsApplyBlocked(false), []);
	const debouncedUnblock = useMemo(() => debounce(unblock, 3000), [unblock]);

	const handleReset = useCallback(() => {
		setIsApplyBlocked(true);
		debouncedUnblock();
	}, [debouncedUnblock]);

	useEffect(() => {
		return () => {
			debouncedUnblock.cancel();
		};
	}, [debouncedUnblock]);

	return {
		isApplyBlocked,
		handleReset,
	};
};
