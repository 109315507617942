import LikesButton from '../../LikesButton';
import ReplyButton from '../../ReplyButton';
import RelativeTime from '@ui-kit/components/RelativeTime';
import { clsx } from '@utils';
import type { ILikesButtonProps } from '../../LikesButton';
import type { IReplyButtonProps } from '../../ReplyButton';
import type { TIsoDate } from '@typings';
import styles from './PostCommentActionBar.module.css';

const CommentActionBar = ({
	hasMyLike,
	likes,
	likeTitle,
	replyTitle,
	createdAt,
	onLike,
	onClickReply,
	className,
}: ICommentActionBarProps) => {
	return (
		<div className={clsx(styles.postCommentActionBar, className)}>
			<div className={styles.postCommentActionBar__createdAt}>
				<RelativeTime dateString={createdAt} />
			</div>
			<LikesButton hasMyLike={hasMyLike} likes={likes} likeTitle={likeTitle} size="small" onLike={onLike} />
			<ReplyButton replyTitle={replyTitle} size="small" onClickReply={onClickReply} />
		</div>
	);
};

export interface ICommentActionBarProps extends Omit<ILikesButtonProps, 'size'>, Omit<IReplyButtonProps, 'size'> {
	createdAt: TIsoDate;
	className?: string;
}

export default CommentActionBar;
