import { useService } from '@hooks';
import type { TActivity, TLatestActivity } from '@typings';

/** Get single activity data response after editing and merge it with data in feed cache.
 * (as when we get data from feedService.getActivity not always receive immediately updated data)
 *  It is used when we need to edit activity on single post page.
 */
export const useUpdateSingleActivityInFeedCache = () => {
	const reactQuery = useService('ReactQueryService');

	return async function updateSingleActivityCache(activityId: TActivity['id'], activity: TLatestActivity) {
		const queryData = reactQuery.queryClient.getQueryData(reactQuery.queryKeys.getSingleActivity(activityId));
		if (queryData) {
			reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getSingleActivity(activityId), {
				...queryData,
				body: activity.body,
				subject: activity.title,
				attachments: {
					images: activity.images,
					videos: activity.videos,
					files: activity.files,
				},
			});
		}
	};
};
