import {
	Avatar,
	ChatsIcon,
	EventsIcon,
	NetworkIcon,
	UsersIcon,
	LearnDocumentIcon,
	FileTypeAvatar,
	FolderFileIcon,
} from '@ui-kit';
import { clsx } from '@utils';
import { GLOBAL_SEARCH_DEFINITION } from '@constants';
import styles from './SearchResult.module.css';
import type { TProfile } from '@typings';

export const IconByDefinition = ({ definition, avatar, name, profileType, mimeType }: IIconByDefinition) => {
	switch (definition) {
		case GLOBAL_SEARCH_DEFINITION.member:
		case GLOBAL_SEARCH_DEFINITION.deal:
		case GLOBAL_SEARCH_DEFINITION.networkConnection:
			return (
				<div className={styles.searchResult__iconWrapper}>
					<Avatar imageUri={avatar} outline={profileType === 'ProfileChair'} size={26} title={name} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.network:
		case GLOBAL_SEARCH_DEFINITION.discussion:
			return (
				<div className={clsx([styles.searchResult__iconWrapper, styles.searchResult__networksIconWrapper])}>
					<NetworkIcon className={styles.searchResult__whiteIcon} height={12} width={15} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.group:
		case GLOBAL_SEARCH_DEFINITION.chapter:
			return (
				<div className={clsx([styles.searchResult__iconWrapper, styles.searchResult__groupsIconWrapper])}>
					<UsersIcon className={styles.searchResult__whiteIcon} height={12} width={15} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.chat:
			return (
				<div className={styles.searchResult__iconWrapper}>
					<ChatsIcon className={styles.searchResult__primaryDarkIcon} height={16} width={16} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.event:
		case GLOBAL_SEARCH_DEFINITION.meeting:
			return (
				<div className={styles.searchResult__iconWrapper}>
					<EventsIcon className={styles.searchResult__primaryDarkIcon} height={16} width={16} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.learn:
			return (
				<div className={styles.searchResult__iconWrapper}>
					<LearnDocumentIcon className={styles.searchResult__primaryDarkIcon} height={16} width={16} />
				</div>
			);
		case GLOBAL_SEARCH_DEFINITION.document:
			return (
				<div className={styles.searchResult__iconWrapper}>
					{mimeType ? (
						<FileTypeAvatar height={20} mimeType={String(mimeType)} width={17} />
					) : (
						<FolderFileIcon height={20} width={17} />
					)}
				</div>
			);
	}
};

interface IIconByDefinition {
	definition: string;
	avatar?: string | null;
	name?: string;
	profileType?: TProfile['@type'];
	mimeType?: string;
}
