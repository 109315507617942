import styles from './EmptyStateMessage.module.css';

/** Message to display in lists OR search results when nothing found. */
const EmptyStateMessage = ({ text, colorMode = 'dark', hidden = false }: IEmptyStateMessageProps) => {
	return hidden ? null : (
		<div className={styles.emptyStateMessage}>
			<span className={styles.emptyStateMessage__text} data-color={colorMode}>
				{text}
			</span>
		</div>
	);
};

export interface IEmptyStateMessageProps {
	/** Message to show. */
	text: string;
	/** Should it be present on the DOM. Default false. */
	hidden?: boolean;
	/** Color of the message */
	colorMode?: 'dark' | 'white';
}

export default EmptyStateMessage;
