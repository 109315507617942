import { getLabelMiddleAngle } from './getLabelMiddleAngle';
import type { TLabelProps } from '../PieChart';

/**  Calculates proper textAnchor position to avoid label overlapping
 * @param {number} labelAnchorDegreeShift Means how many degrees shift from 0° and 180° is needed to choose not he default 'middle' textAnchor position.
 * @see PieChart.png
 */
export function getLabelTextAnchor(dataEntry: TLabelProps['dataEntry'], labelAnchorDegreeShift: number) {
	function isBetween(value: number, min: number, max: number): boolean {
		return value >= min && value <= max;
	}

	const labelMiddleAngle = getLabelMiddleAngle(dataEntry);

	const isTopLeftSector = isBetween(labelMiddleAngle, 360 - labelAnchorDegreeShift, 360);
	const isBottomLeftSector = isBetween(labelMiddleAngle, 180, 180 + labelAnchorDegreeShift);
	const isTopRightSector = isBetween(labelMiddleAngle, 0, 0 + labelAnchorDegreeShift);
	const isBottomRightSector = isBetween(labelMiddleAngle, 180 - labelAnchorDegreeShift, 180);

	const textAnchor =
		isTopLeftSector || isBottomLeftSector ? 'end' : isTopRightSector || isBottomRightSector ? 'start' : 'middle';

	return textAnchor;
}
