import { Modal } from '@ui-kit';
import { useTranslation } from '@hooks';
import DownloadEncryptedFileForm from '../DownloadEncryptedFileForm';
import type { IDownloadEncryptedFileFormProps } from '../DownloadEncryptedFileForm';

const DownloadEncryptedFileModal = ({ file, onClose, onOpenFile }: IDownloadEncryptedFileModalProps) => {
	const { t } = useTranslation();
	return (
		<Modal
			modalContent={<DownloadEncryptedFileForm file={file} onClose={onClose} onOpenFile={onOpenFile} />}
			subTitle={t('Please request the password from your Group Chair.')}
			title={t('Password')}
			visible
		/>
	);
};

interface IDownloadEncryptedFileModalProps extends IDownloadEncryptedFileFormProps {}

export default DownloadEncryptedFileModal;
