import { ActivityIndicator, Card, Gap, SpacedGroup } from '@ui-kit';
import { EventLocationCardButton } from './EventLocationCardButton';
import { useFindAddressLocation, useMe } from '@hooks';
import { useTranslation } from '@hooks';
import { formatEventVenue, guessConferencePlatform, MeetingEntity } from '@utils';
import type { TEvent } from '@typings';

const EventLocationCard = ({ event }: IEventLocationCardProps) => {
	const { t } = useTranslation();
	const { user } = useMe();

	const venue = formatEventVenue(event).join(', ');
	const virtualConferenceLink = MeetingEntity.getUserConferenceLink(event);
	const coordinates = MeetingEntity.getLocation(event);
	const { location, openLocation, isFetching } = useFindAddressLocation(coordinates, venue);
	const showConferenceLink = MeetingEntity.shouldShowConferenceLink(event, user);

	if (isFetching) {
		return <ActivityIndicator size="small" />;
	}

	const showLocation = event.isAllowedInPersonAttendance && (!!venue || !!location);
	const showCard = showLocation || showConferenceLink;

	if (!showCard) return null;

	return (
		<Card backgroundPlacement="none" title={t('Location')}>
			<SpacedGroup direction="vertical" gap={16}>
				{/* Venue */}
				{showLocation ? (
					<div>
						<span>{venue}</span>
						{location ? <EventLocationCardButton title={t('Get directions')} onClick={openLocation} /> : null}
					</div>
				) : null}
				{/* Conference */}
				{showConferenceLink ? (
					<div>
						<span>
							{t('{{conferencePlatform}} meeting', {
								conferencePlatform: guessConferencePlatform(virtualConferenceLink).toUpperCase(),
							})}
						</span>
						<Gap gap={4} />
						<EventLocationCardButton
							title={t('Join {{conferencePlatform}} Meeting', {
								conferencePlatform: guessConferencePlatform(virtualConferenceLink).toUpperCase(),
							})}
							onClick={() => window.open(String(virtualConferenceLink), 'blank')}
						/>
					</div>
				) : null}
			</SpacedGroup>
		</Card>
	);
};

export interface IEventLocationCardProps {
	event: TEvent;
}

export default EventLocationCard;
