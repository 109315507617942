import type { TDynamicFieldLink } from '@typings';
import styles from './DynamicLinksSection.module.css';

const DynamicLinksSection = ({ title, links = [], onLinkOpened }: IDynamicLinksSectionProps) => {
	if (!links?.length) return null;
	return (
		<div className={styles.linksSection}>
			<h3 className={styles.linksSection__headerTitle}>{title}</h3>
			{links.map((link, index) => {
				return (
					<div key={link.value + index}>
						<a
							href={link.value}
							rel="noreferrer"
							target="_blank"
							title={link.meta?.anchor ?? link.label}
							onClick={onLinkOpened}
						>
							<span>{link.value}</span>
						</a>
					</div>
				);
			})}
		</div>
	);
};

export interface IDynamicLinksSectionProps {
	title: string;
	links: TDynamicFieldLink[];
	onLinkOpened?: (e: React.MouseEvent<HTMLAnchorElement> | undefined) => void;
}

export default DynamicLinksSection;
