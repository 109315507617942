import { useMutation, useNotification, useService } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TCommunity, TNetworkConnection } from '@typings';
import type { AxiosError } from 'axios';

export const useNetworkConnectionBookmarkMutation = (
	networkConnectionId: TNetworkConnection['id'],
	communityId: TCommunity['id'],
	options?: MutationOptions<void, AxiosError, boolean | undefined>,
) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const reactQuery = useService('ReactQueryService');

	return useMutation<void, AxiosError, boolean | undefined>(
		reactQuery.queryKeys.patchNetworkConnectionBookmarks(),
		async (isBookmarked: boolean | undefined) => {
			if (isBookmarked) {
				await api.bookmarks.deleteNetworkConnectionBookmark(networkConnectionId);
			} else {
				await api.bookmarks.createNetworkConnectionBookmark(networkConnectionId);
			}
		},
		{
			...options,
			onSuccess(data, variables, context) {
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getNetworkConnectionBookmarks(communityId));
				options?.onSuccess?.(data, variables, context);
			},
			onMutate: (isBookmarked) => {
				reactQuery.updateNetworkConnectionCache(networkConnectionId, setNetworkBookmark(isBookmarked)); // optimistic update
				options?.onMutate?.(isBookmarked);
			},
			onError: (error, isBookmarked, context) => {
				showUnknownError(error);
				reactQuery.updateNetworkConnectionCache(networkConnectionId, setNetworkBookmark(isBookmarked)); // optimistic update
				options?.onError?.(error, isBookmarked, context);
			},
		},
	);
};

const setNetworkBookmark = (isBookmarked?: boolean) => (connection: TNetworkConnection) => {
	connection.isBookmarked = !isBookmarked;
};
