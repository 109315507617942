import { useEffect, useService, useMe, useState } from '@hooks';

export const useChatEvents = () => {
	const chat = useService('ChatService');
	const { user } = useMe();
	const [isUserPaused, setUserPaused] = useState(false);

	function getUserStatus() {
		chat.queryUser(user.slug).then((currentUser) => {
			if (user) {
				setUserPaused(currentUser.banned as boolean);
			}
		});
	}
	useEffect(() => {
		//inital status
		getUserStatus();
		// event will be called when user get unpaused
		chat.chatClient.on('user.banned', getUserStatus);
		// event will be called when user get unpaused
		chat.chatClient.on('user.unbanned', getUserStatus);
		return () => {
			chat.chatClient.off('user.unbanned', getUserStatus);
			chat.chatClient.off('user.banned', getUserStatus);
		};
	}, []);

	return { isUserPaused };
};
