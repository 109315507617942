import { ActivityIndicator } from '@ui-kit';
import FilesWidget from '@ui-modules/files/components/FilesWidget/FilesWidget';
import { useCallback, useEffect, useEvent, useMe, useService } from '@hooks';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useNetworkConnectionFilesAndFoldersQuery } from '@ui-modules/files/hooks/useNetworkConnectionFilesAndFoldersQuery';
import { useNetworkConnectionFolderQuery } from '@ui-modules/files/hooks/useNetworkConnectionFolderQuery';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import { useUploadNetworkConnectionFileMutation } from '@ui-modules/files/hooks/useUploadNetworkConnectionFileMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import { useDeleteNetworkConnectionFileMutation } from '@ui-modules/files/hooks/useDeleteNetworkConnectionFileMutation';
import { useCreateNetworkConnectionFolderMutation } from '@ui-modules/files/hooks/useCreateNetworkConnectionFolderMutation';
import { useDeleteNetworkConnectionFolderMutation } from '@ui-modules/files/hooks/useDeleteNetworkConnectionFolderMutation';
import { useRenameNetworkConnectionFolderMutation } from '@ui-modules/files/hooks/useRenameNetworkConnectionFolderMutation';
import { NetworkConnectionFilesEntity } from '@utils';
import type { TFile, TFolder, TNetworkConnection } from '@typings';
import type { IFilesWidgetApiCallbacks } from '@ui-modules/files/components/FilesWidget/FilesWidgetApiCallbacks.interface';
import { useCommunityQuery } from '../../../communities/hooks/useCommunityQuery';

const NetworkConnectionFilesWidget = ({
	networkConnectionId,
	folderId,
	onOpenFile,
	onOpenFolder,
	makeFolderLink,
}: INetworkConnectionFilesWidgetProps) => {
	// Dependencies.
	const { user } = useMe();
	const analytics = useService('AnalyticsService');

	// Fetch related data.
	const isRootFolder = !folderId;
	const {
		files = [],
		folders = [],
		isInitialLoading,
	} = useNetworkConnectionFilesAndFoldersQuery(networkConnectionId, folderId, isRootFolder);
	const { data: networkConnection, isLoading: isNetworkConnectionLoading } =
		useNetworkConnectionQuery(networkConnectionId);
	const { data: network } = useCommunityQuery(networkConnection?.community?.id);
	const networkConnectionsName = network?.networkConnectionsName as string;
	const { data: folder } = useNetworkConnectionFolderQuery(networkConnectionId, folderId);

	// Determine data & permissions.
	const canMakeFileViewOnly = useCallback(
		(file: TFile) =>
			Boolean(networkConnection && NetworkConnectionFilesEntity.canMakeFileViewOnly(file, user, networkConnection)),
		[networkConnection],
	);
	const canMakeFileDownloadable = useCallback(
		(file: TFile) =>
			Boolean(networkConnection && NetworkConnectionFilesEntity.canMakeFileDownloadable(file, user, networkConnection)),
		[networkConnection],
	);
	const canDeleteFile = useCallback((file: TFile) => NetworkConnectionFilesEntity.canDeleteFile(file, user), []);

	// User actions.
	const { uploadFile, isLoading: isFileUploading } = useUploadNetworkConnectionFileMutation(
		networkConnection as TNetworkConnection,
		folderId,
		{
			onSuccess: (file) => {
				onOpenFolder(file.folder?.id ?? null);
				networkConnection &&
					analytics.trackGenericEvent(
						'{{connectionName}}Interactions',
						{
							interaction_type: 'investor_files',
							interaction_action: 'files attached',
							'{{connectionName}}_id': networkConnection.id,
							'{{connectionName}}_name': networkConnection.title,
						},
						{
							connectionName: networkConnectionsName,
						},
					);
			},
		},
	);
	const { openFilePicker: pickAndUploadFile, getInputProps } = usePickUpFile((file) => uploadFile({ file }));
	const { mutate: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		networkConnectionId: networkConnectionId,
		folderId,
	});
	const { mutate: deleteFile } = useDeleteNetworkConnectionFileMutation(
		networkConnectionId,
		folderId,
		String(networkConnection?.title),
		networkConnectionsName,
	);
	const { mutate: createFolder } = useCreateNetworkConnectionFolderMutation(
		networkConnectionId,
		folderId,
		String(networkConnection?.title),
		networkConnectionsName,
	);
	const { mutate: deleteFolder } = useDeleteNetworkConnectionFolderMutation(
		networkConnectionId,
		String(networkConnection?.title),
		networkConnectionsName,
		{
			onSuccess: () => onOpenFolder(folder?.folder?.id ?? undefined),
		},
	);
	const { mutate: renameFolder } = useRenameNetworkConnectionFolderMutation(
		networkConnectionId,
		folder as TFolder,
		String(networkConnection?.title),
		networkConnectionsName,
	);
	const openFile = useEvent(async (file: TFile) => {
		networkConnection &&
			analytics.trackGenericEvent(
				'{{connectionName}}Interactions',
				{
					interaction_type: 'investor_files',
					interaction_action: 'files viewed',
					'{{connectionName}}_id': networkConnection.id,
					'{{connectionName}}_name': networkConnection.title,
				},
				{
					connectionName: networkConnectionsName,
				},
			);
		return onOpenFile(file);
	});

	useEffect(() => {
		if (networkConnection && networkConnectionsName)
			analytics.trackGenericEvent(
				'{{connectionName}}Interactions',
				{
					'{{connectionName}}_id': networkConnectionId,
					'{{connectionName}}_name': networkConnection.title,
					interaction_type: 'investor_files',
					interaction_action: 'folder viewed',
				},
				{
					connectionName: networkConnectionsName,
				},
			);
	}, [networkConnection, networkConnectionsName, folderId]);

	if (!networkConnection) return <ActivityIndicator type="fit" />;
	return (
		<>
			<FilesWidget
				canCreateFolders={NetworkConnectionFilesEntity.canCreateFolder(networkConnection, user, folder ?? null)}
				canDeleteFile={canDeleteFile}
				canDeleteFolders={NetworkConnectionFilesEntity.canDeleteFolder(folder, user, networkConnection, isRootFolder)}
				canEditFolders={NetworkConnectionFilesEntity.canEditFolder(folder, user, networkConnection, isRootFolder)}
				canMakeFileDownloadable={canMakeFileDownloadable}
				canMakeFileViewOnly={canMakeFileViewOnly}
				canUploadFiles={NetworkConnectionFilesEntity.canUploadFile(networkConnection, user, folder)}
				currentFolder={folder}
				files={files}
				folders={folders}
				isFileUploading={isFileUploading}
				isLoading={isInitialLoading || isNetworkConnectionLoading}
				isRootFolder={isRootFolder}
				makeFolderLink={makeFolderLink}
				onCreateFolder={createFolder}
				onDeleteFile={deleteFile}
				onDeleteFolder={deleteFolder}
				onOpenFile={openFile}
				onOpenFolder={onOpenFolder}
				onPickAndUploadFile={pickAndUploadFile}
				onRenameFolder={renameFolder}
				onSetViewOnlyFileStatus={updateMediaObjectSetViewerOnly}
			/>
			<input {...getInputProps()} /> {/* hidden input to make file upload working */}
		</>
	);
};

export interface INetworkConnectionFilesWidgetProps
	extends Pick<IFilesWidgetApiCallbacks, 'onOpenFile' | 'onOpenFolder'> {
	/** Target NetworkConnection ID which files and folders should be shown. */
	networkConnectionId: TNetworkConnection['id'];
	/** Target NetworkConnection ID which files and folders should be shown. Pass 'null' to show the root folder. */
	folderId: TFolder['id'] | null;
	/** Function to generate a link to a certain folder. */
	makeFolderLink: (folderId: TFolder['id'] | null) => string;
}

export default NetworkConnectionFilesWidget;
