import ActivityIndicator from '../ActivityIndicator';
import styles from './Notifier.module.css';

/** Button with loading indicator which can be showed on the top og a list to call refreshing. */
const Notifier = ({ title, isLoading, onClick }: INotifierProps) => {
	return (
		<div className={styles.notifier} role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
			<ActivityIndicator hidden={!isLoading} size="extraSmall" />
			<span className={styles.notifier__title}>{title}</span>
		</div>
	);
};

export interface INotifierProps {
	/** Text of the button. */
	title: string;
	/** Should show activity indicator. Default 'false'. */
	isLoading: boolean;
	/** Called when button clicked. */
	onClick: () => void;
}

export default Notifier;
