import { useQuery } from '@hooks';
import { useService } from '@services';

export const useThirdPartyDeal = ({ clientId, clientSecret }: IUseThirdPartyDealProps) => {
	const api = useService('ApiService');

	const { data, isLoading } = useQuery<{ token: string }, Error>(
		['thirdParty.getToken'],
		async () => await api.thirdPartyDeal.getThirdPartyToken(clientId, clientSecret),
		{
			onSuccess: async (data) => {
				await api.thirdPartyDeal.updateAuthorization(data?.token);
			},
		},
	);

	return {
		isTokenAvailable: !!data?.token,
		isLoading,
	};
};

interface IUseThirdPartyDealProps {
	clientSecret?: string;
	clientId?: string;
}
