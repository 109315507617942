import { useService, useMutation, useNotification } from '@hooks';
import type { TDeal, TFolder } from '@typings';
import type { UseMutationOptions } from '@tanstack/react-query';

export const useDeleteDealFolderMutation = (
	dealId: TDeal['id'],
	dealName: TDeal['name'],
	options?: UseMutationOptions<void, Error, TFolder['id']>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');

	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFolder['id']>(
		['dealFolder.deleteDealFolder'],
		async (folderId) => await api.dealFolder.deleteDealFolder(folderId),
		{
			...options,
			async onSuccess(response, variables, context) {
				await reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getDealFolders(dealId));
				analytics.trackEvent('DealInteractions', {
					interaction_type: 'investor_files',
					interaction_action: 'folder deleted',
					deal_id: dealId,
					deal_name: dealName,
				});
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
