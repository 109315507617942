import { Formik, Gap, RadioGroupInput, TextArea, Button } from '@ui-kit';
import { useTranslation, useRef } from '@hooks';
import { getReportContentFormSchema } from '@schemas';
import { REPORT_CONTENT_OTHER_VALUE } from '@constants';
import styles from './ReportContentForm.module.css';
import type { TRadioGroupInputOption } from '@ui-kit';
import type { TReportContentForm } from '@schemas';
import type { ReactNode } from 'react';
import type { FormikProps } from 'formik';
import type { TReportContentType } from '@typings';

const ReportContentForm = ({
	contentPreviewElement,
	type,
	onSubmit,
	renderWrapper = ({ children }) => children,
}: IReportContentFormProps) => {
	const { t } = useTranslation();

	// Render form data.
	const optionList = useRef<TRadioGroupInputOption[]>([
		{ value: 'Suspected solicitation', label: t('Suspected solicitation') },
		{ value: 'Unacceptable behavior', label: t('Unacceptable behavior') },
		{ value: REPORT_CONTENT_OTHER_VALUE, label: t('Other') },
	]).current;

	const isReasonHidden = type === 'deal' || type === 'connection';

	return (
		<Formik<TReportContentForm>
			initialValues={getReportContentFormSchema(t, !isReasonHidden).getDefault() as TReportContentForm}
			validateOnBlur={false}
			validateOnChange={false}
			validateOnMount={false}
			validationSchema={getReportContentFormSchema(t, !isReasonHidden)}
			onSubmit={(message) => onSubmit(message)}
		>
			{(formProps) => {
				const { values, setFieldValue, setFieldError, handleBlur, submitForm, errors, isSubmitting } = formProps;
				return renderWrapper({
					formProps,
					children: (
						<>
							<Gap gap={24} />
							<h4>{t('Here is what you’re reporting')}</h4>
							<Gap gap={16} />
							{contentPreviewElement}
							<Gap gap={8} />
							{isReasonHidden ? null : (
								<>
									<Gap gap={16} />
									<h4>{t("What's happening?")}</h4>
									<RadioGroupInput
										disabled={isSubmitting}
										errorMessage={errors.reason}
										options={optionList}
										value={values.reason}
										onChange={(value) => setFieldValue('reason', value)}
									/>
								</>
							)}
							<Gap gap={16} />
							<TextArea
								disabled={isSubmitting}
								errorMessage={errors.explanation}
								label={t("Let us know what's going on")}
								minRows={4}
								placeholder={t('Enter a description')}
								value={values.explanation}
								onBlur={handleBlur('explanation')}
								onChange={(e) => {
									setFieldValue('explanation', e.target.value);
									setFieldError('explanation', undefined);
								}}
							/>
							<small className={styles.reportContentForm__submitHint}>
								{isReasonHidden
									? t('This report will go to the Network’s Chair and TIGER 21 administrative staff.')
									: t(
											'Reports are always 100% anonymous, and your data is never shared outside of TIGER 21 administrative staff.',
									  )}
							</small>
							<Gap gap={20} />
							<Button
								block="fit"
								loading={isSubmitting}
								title={t('Submit Report')}
								type="outline"
								variant="medium"
								onClick={submitForm}
							/>
						</>
					),
				});
			}}
		</Formik>
	);
};

export interface IReportContentFormProps {
	/** React element with content which is going to be reported as inappropriate. */
	contentPreviewElement: ReactNode | null;
	/** Form submit handler. */
	onSubmit: (values: TReportContentForm) => Promise<unknown>;
	/** Optional renderer to wrap form content and pass formProps to a wrapper React component. */
	renderWrapper?: ({
		formProps,
		children,
	}: {
		formProps: FormikProps<TReportContentForm>;
		children: ReactNode;
	}) => ReactNode;
	/** Type of reported content */
	type: TReportContentType;
}

export default ReportContentForm;
