import { ROUTES } from '@constants';
import { useNavigate, useTranslation } from '@hooks';
import { Card, RightIcon } from '@ui-kit';
import styles from './SupportMenu.module.css';

const SupportMenu = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const options: TSupportResourceOption[] = [
		{
			title: t('Frequently Asked Questions'),
			screenName: ROUTES.supportFaq(),
		},
		{
			title: t('App Tutorials'),
			screenName: ROUTES.supportAppTutorials(),
		},
		{
			title: t('Request Technical Support'),
			screenName: ROUTES.supportRequestHelp(),
		},
	];

	return (
		<Card backgroundPlacement="none" title={t('Support')}>
			<div className={styles.menu__container}>
				{options.map((option) => (
					<button className={styles.menu__option} key={option.title} onClick={() => navigate(option.screenName)}>
						<span>{option.title}</span>
						<RightIcon height={16} width={14} />
					</button>
				))}
			</div>
		</Card>
	);
};

export type TSupportResourceOption = { title: string; screenName: string };

export default SupportMenu;
