import { useEvent, useLogger, useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import { getInAppPageRoute } from '../utils/getInAppPageRoute';
import { ACTIVITY_LINK_TYPE_MAP, getActivityLinkType } from '../utils/getActivityLinkType';

/** Handles click by Activity post button. Performs navigation to a certain screen depends on the button link value. */
export const useHandleActivityLink = () => {
	const navigate = useNavigate();
	const logger = useLogger('useHandleActivityLink');

	return useEvent((link: string) => {
		const type = getActivityLinkType(link);

		switch (type) {
			case ACTIVITY_LINK_TYPE_MAP.event: {
				const eventId = link.replace('/api/events/', '');
				return navigate(ROUTES.viewEvent(eventId));
			}
			case ACTIVITY_LINK_TYPE_MAP.url: {
				const isLinkOnTheSameHost = window.location.hostname === new URL(link).hostname;
				if (isLinkOnTheSameHost) {
					navigate(new URL(link)?.pathname);
				} else {
					window.open(link);
				}
				return;
			}
			case ACTIVITY_LINK_TYPE_MAP.inAppPage: {
				const route = getInAppPageRoute(link);
				if (route) {
					navigate(route);
				} else {
					logger.error('unknown in app page', link);
				}
				return;
			}
			case ACTIVITY_LINK_TYPE_MAP.activity: {
				const activityId = link.replace('getstream::', '');
				return navigate(ROUTES.post(activityId));
			}
			default: {
				logger.error('cannot open link', link, type);
			}
		}
	});
};
