import { memo } from 'react';
import { DropdownMenu } from '@ui-kit';
import { useMemo } from '@hooks';
import { convertAssetAllocationPeriodToYearValue } from '@ui-modules/assetAllocation/utils/convertAssetAllocationPeriodToYearValue';
import { convertAssetAllocationYearValueToPeriod } from '@ui-modules/assetAllocation/utils/convertAssetAllocationYearValueToPeriod';
import type { TAssetAllocationPeriod } from '@typings';
import type { TDropdownOption } from '@ui-kit/components/DropdownMenu';

export const AssetAllocationComparePeriodSelector = ({
	comparePeriods = [],
	selectedComparePeriod,
	onSelectComparePeriod,
}: IAssetAllocationComparePeriodSelectorProps) => {
	const periodValue = selectedComparePeriod
		? convertAssetAllocationPeriodToYearValue(selectedComparePeriod)
		: undefined;
	const periodOptions = useMemo<TDropdownOption<number | undefined>[]>(() => {
		return comparePeriods.map((comparePeriod) => ({
			value: convertAssetAllocationPeriodToYearValue(comparePeriod),
			title: `${comparePeriod.year} Q${comparePeriod.quarter}`,
		}));
	}, [comparePeriods]);
	const handleChangePeriod = (yearValue: number | undefined) => {
		const selectedPeriod = yearValue ? convertAssetAllocationYearValueToPeriod(yearValue) : undefined;
		onSelectComparePeriod(selectedPeriod);
	};

	if (!comparePeriods?.length) return null;
	return (
		<DropdownMenu<number | undefined>
			disabled={false}
			isSearchable={false}
			options={periodOptions}
			value={periodValue}
			onChange={handleChangePeriod}
		/>
	);
};

export interface IAssetAllocationComparePeriodSelectorProps {
	comparePeriods: TAssetAllocationPeriod[];
	selectedComparePeriod: TAssetAllocationPeriod | undefined;
	onSelectComparePeriod: (period: TAssetAllocationPeriod | undefined) => void;
}

export default memo(AssetAllocationComparePeriodSelector);
