import { useService, useQuery, useMe } from '@hooks';

import type { UseQueryOptions } from '@tanstack/react-query';
import type { TCommunity } from '@typings';
import type { TGetNetworkConnectionUserFilterDto } from '@tiger21-llc/connect-shared/src/typings/api/GetNetworkConnectionUserFilterDto.type';
import type { AxiosError } from 'axios';
import { dayjs } from '@utils';
import { useCreateNetworkConnectionUserFilterMutation } from '@ui-modules/connections/hooks/useCreateNetworkConnectionUserFilterMutation';

export const useNetworkConnectionUserFiltersQuery = <T = TUseNetworkConnectionUserFiltersQueryData>(
	params: { communityId: TCommunity['id'] },
	queryOptions?: UseQueryOptions<TUseNetworkConnectionUserFiltersQueryData, AxiosError, T>,
) => {
	const { user } = useMe();
	const api = useService('ApiService');

	const reactQuery = useService('ReactQueryService');

	const { mutateAsync } = useCreateNetworkConnectionUserFilterMutation();

	return useQuery<TUseNetworkConnectionUserFiltersQueryData, AxiosError, T>(
		reactQuery.queryKeys.getNetworkConnectionUserFilters(params.communityId),
		() => api.networkConnectionUserFilter.getUserFilter(params.communityId),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			onError: async (err: AxiosError) => {
				if (err?.response?.status === 404) {
					await mutateAsync({
						community: `/api/communities/${params.communityId}`,
						owner: user['@id'],
						filters: { categories: [] },
					});
				}
			},
		},
	);
};

type TUseNetworkConnectionUserFiltersQueryData = TGetNetworkConnectionUserFilterDto;
