import { TabPanel } from 'react-tabs';
import styles from './Tabs.module.css';
import { clsx } from '@utils';
import type { TabPanelProps } from 'react-tabs';

const TabsPanel = ({ children, darkMode = false, ...passThroughProps }: ITabsPanelProps) => {
	const { selected } = passThroughProps as ITabsPanelPassThroughProps;
	return (
		<TabPanel
			aria-hidden={!selected}
			className={clsx(
				darkMode ? styles.tabs__tabDarkPanel : styles.tabs__tabPanel,
				!selected && styles.tabs__tabPanel_hidden,
			)}
			selected={selected}
			{...passThroughProps}
		>
			{children}
		</TabPanel>
	);
};

interface ITabsPanelPassThroughProps {
	selected: boolean;
	id: string;
}
export interface ITabsPanelProps {
	/** Content of a tab. */
	children: TabPanelProps['children'];
	/** For some features (example as a Deals) we need to have dark mode of tabs */
	darkMode?: boolean;
}

TabsPanel.tabsRole = 'TabPanel'; // Required field to use your custom TabPanel  (see https://github.com/reactjs/react-tabs#custom-components)
export default TabsPanel;
