import { isObject } from '@utils';
import { parseBlackthornUrl } from './parseBlackthornUrl';

export const setUpBlackthornEventView = (registrationLink: string, callback?: () => void): EventsApp | undefined => {
	const { orgId, eventPath } = parseBlackthornUrl(registrationLink);

	if (eventPath && orgId) {
		const app = new EventsApp({
			orgId: orgId,
			path: eventPath,
			interceptors: [
				{
					event: 'ROUTE_CHANGE',
					handler: function (params, next) {
						next();
					},
				},
				{
					event: 'OPEN_EXTERNAL_LINK',
					handler: function (params, next) {
						if (isInterceptorAddOnlineToCalendarLink(params)) {
							next?.preventDefault?.();
							window.open(params.url, '_blank');
						} else {
							next();
						}
					},
				},
			],
		});
		app.mount('.events-container');
		callback?.();

		return app;
	}
};

/** Check if the link is a link to add the event to the calendar
 * 	which should be opened in a new tab instead of the app (as fix for T21C-5555).
 * 	because it doesn't download the .ics/.ical file but opens the external app dialog
 * 	(which isn't open within the iframe).
 * 	Makes sense when you registered for the event, opened 'Add to Calendar' dropdown and chose an options with "(online)" title.
 * 	Examples of the links:
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=google"
 *   - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=office365"
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=outlook.com"
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA?p=yahoo&offset=-180"
 *  Next links should be skipped because they are not downloading in the new window, we can rely on the "download" property in the interceptor "params" object:
 * 	 - "http://events.blackthorn.io/00D8D0000008j1WUAQ/ical/a2j8D000001ebwgQAA"
 *  @author DmitriyNikolenko
 */
function isInterceptorAddOnlineToCalendarLink(params: TBlackthornOpenExternalLinkInterceptorParams) {
	return (
		isObject(params) &&
		'url' in params &&
		params.url.includes('events.blackthorn.io') &&
		params.url.includes('/ical/') &&
		(!('download' in params) || ('download' in params && !params.download))
	);
}

type TBlackthornOpenExternalLinkInterceptorParams =
	| {
			path: string; // e.g. '/tiger-talk-october-5a2j8D1ebwg/confirmation';
			viewName: string; // e.g. 'eventDetails';
			viewNamesFromRoot: string[]; // e.g.  ['eventDetails'];
	  }
	| {
			url: string;
			windowFeatures: string[] | undefined;
			windowName: '_system' | string;
	  }
	| {
			url: string;
			download: true;
	  };
