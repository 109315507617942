export type TMeatBallPosition =
	| 'top'
	| 'top-left'
	| 'top-right'
	| 'right'
	| 'bottom'
	| 'bottom-left'
	| 'bottom-right'
	| 'left'
	| 'center';

export const getMenuPosition = (rect: DOMRect, position: TMeatBallPosition) => {
	const { width, height, x, y } = rect;
	switch (position) {
		case 'right':
		case 'top-right':
			return { left: x + width, top: y + window.scrollY };
		case 'bottom':
		case 'bottom-left':
			return { left: x, top: y + window.scrollY + height };
		case 'bottom-right':
			return { left: x + width, top: y + window.scrollY + height };
		case 'center':
			return { left: x + width / 2, top: rect.y + height / 2 };
		default:
			return { left: x, top: y + window.scrollY };
	}
};
