import { useTranslation, useState, useSubmitOrScrollToError } from '@hooks';
import {
	Formik,
	Button,
	Gap,
	TextArea,
	CheckboxInput,
	ActivityIndicator,
	InputErrorMessage,
	RadioGroupInput,
	DropdownMenu,
	WebLinksSection,
	FormSection,
	When,
} from '@ui-kit';
import type { TDealThirdPartyFormValues } from '../ThirdPartyDealForm.schema';
import { dealFormFields } from '../ThirdPartyDealForm.schema';
import { getValidateHandler } from '../ThirdPartyDealForm.schema';
import { DealContactSection } from '../sections/DealContactSection';
import { DealDetails } from '../sections/DealDetails';
import { AttachmentsSection } from '../sections/AttachmentsSection';
import { FinancialDetails } from '../sections/FinancialDetails';
import { CreatorSection } from './CreatorSection';
import { ThirdPartyDealFormSubmitMessage } from './ThirdPartyDealFormSubmitMessage';

import styles from './ThirdPartyDealForm.module.css';
import type { TDealFormValues } from '../BaseForm';
import { getThirdPartyInitialValues } from '../BaseForm';
import { useGetDealTermsQuery } from '@ui-modules/deals/hooks/useGetDealTermsQuery';
import { useSubmitDeal } from './ThirdPartyForm.useSubmitDeal';
import type { FormikProps } from 'formik';
import { clsx, extractIdFromIri, noop } from '@utils';
import { DealFormSection } from '../sections/DealFormSection';
import { ThirdPartyDealFormErrorMessage } from './ThirdPartyDealFormErrorMessage';
import { useGetDealTemplateQuery } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { useGetThirdPartyDeal } from '@ui-modules/deals/hooks/useGetThirdPartyDeal';
import { DealRejectionStatus } from './DealRejectionStatus';
import type { TFile } from '@typings';

export const ThirdPartyDealForm = ({ dealId }: IDealFormProps) => {
	const { t } = useTranslation();
	const { deal, isLoading, dealError, previewDealStatus, isPreviewRequests } = useGetThirdPartyDeal(dealId);
	const [termsSigned, setTermsSigned] = useState(false);
	const [termsSignedError, setTermsSignedError] = useState(false);

	const { data: termsAndConditions } = useGetDealTermsQuery();
	const submitOrScrollToErrorField = useSubmitOrScrollToError();
	const isDealEditingDisabled = deal?.status === 'closed';

	const { data: dealTemplate } = useGetDealTemplateQuery(
		noop,
		extractIdFromIri(deal?.dealAssetClass?.['@id'] || ''),
		extractIdFromIri(deal?.dealSubClass?.['@id'] || ''),
		extractIdFromIri(deal?.dealType?.['@id'] || ''),
	);

	const defaultValues = { ...getThirdPartyInitialValues(deal, dealTemplate), submitAction: 'save' };

	const creatorEmail = deal?.creatorEmail || ' ';
	const creatorName = deal?.creator.fullName || '';
	const creatorPhone = deal?.creatorPhone || ' ';

	const [dealSubmitted, setDealSubmitted] = useState(false);
	const onSubmit = useSubmitDeal(dealId, deal?.status, isPreviewRequests, (dealReview) => {
		if (dealReview) {
			setDealSubmitted(true);
		}
	});

	const validateDisableMutateFile = (file?: TFile) => {
		if (!file) return true;
		return (deal?.status === 'draft' || deal?.status === 'review') && file.owner['@type'] !== 'ApiClient'; // 3rd party can edit only own files.
	};

	if (dealError || deal?.status === 'cancelled') {
		return <ThirdPartyDealFormErrorMessage />;
	}

	if (dealSubmitted) {
		return (
			<ThirdPartyDealFormSubmitMessage
				creatorEmail={creatorEmail}
				creatorName={creatorName}
				creatorPhone={creatorPhone}
			/>
		);
	}
	return !isLoading && deal ? (
		<div className={styles.form}>
			<Formik
				enableReinitialize={true}
				initialValues={defaultValues as TDealThirdPartyFormValues}
				validate={getValidateHandler(t, deal?.status === 'published' || deal?.status === 'closed')}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					const { setFieldError, setFieldValue, values, errors } = formProps;
					const onSubmitPress = (submitAction: string) => {
						if (!termsSigned) {
							setTermsSignedError(true);
							return;
						}
						formProps.setFieldValue(dealFormFields.SUBMIT_ACTION, submitAction);
						formProps.validateForm({ ...formProps.values, submitAction }).then(() => {
							submitOrScrollToErrorField(formProps.submitForm);
						});
					};

					return (
						<div className={styles.contentWrapper}>
							<div className={styles.content}>
								<DealRejectionStatus dealCreator={deal.creator.fullName} previewDealStatus={previewDealStatus} />
								<CreatorSection
									email={creatorEmail}
									name={creatorName}
									phone={creatorPhone}
									published={isDealEditingDisabled}
								/>
								<DealContactSection
									disabled={isDealEditingDisabled}
									formProps={formProps as unknown as FormikProps<TDealFormValues>}
									hideHints
									title={t('Issuer Details')}
								/>
								<DealDetails
									dealDetails={{
										dealAssetClass: deal.dealAssetClass['@id'],
										dealSubClass: deal.dealSubClass['@id'],
										dealType: deal.dealType['@id'],
									}}
									disabled={isDealEditingDisabled}
									formProps={formProps as unknown as FormikProps<TDealFormValues>}
									isEdit
									minDescriptionRows={5}
								/>
								<DealFormSection className={styles.section} title={t('Asset Classes')}>
									<DropdownMenu
										disabled
										iconType={'clear'}
										label={t('Asset class')}
										options={[{ title: deal.dealAssetClass.name, value: deal.dealAssetClass.name }]}
										placeholder={t('Type an asset class')}
										showSearchIcon
										value={deal.dealAssetClass.name}
										variant="medium"
										onChange={noop}
									/>
									<Gap gap={24} />
									<RadioGroupInput
										backgroundColor="white"
										disabled
										label={t('Sub-class')}
										labelType="bold"
										options={[{ label: deal.dealSubClass.name, value: deal.dealSubClass.name }]}
										value={deal.dealSubClass.name}
										onChange={noop}
									/>
									<Gap gap={12} />
									<RadioGroupInput
										backgroundColor="white"
										disabled
										label={t('Type')}
										labelType="bold"
										options={[{ label: deal.dealType.name, value: deal.dealType.name }]}
										value={deal.dealType.name}
										onChange={noop}
									/>
								</DealFormSection>
								<FinancialDetails
									dealDetails={{
										dealAssetClass: deal.dealAssetClass['@id'],
										dealSubClass: deal.dealSubClass['@id'],
										dealType: deal.dealType['@id'],
									}}
									disabled={isDealEditingDisabled}
									formProps={formProps as unknown as FormikProps<TDealFormValues>}
									isEdit
								/>
								<FormSection title={t('Web Links (optional)')}>
									<WebLinksSection
										disabled={isDealEditingDisabled}
										options={[
											{
												filed: dealFormFields.WEB_LINK_1,
												label: t('Web link 1'),
												value: values.webLink1,
												error: errors.webLink1,
											},
											{
												filed: dealFormFields.WEB_LINK_2,
												label: t('Web link 2'),
												value: values.webLink2,
												error: errors.webLink2,
											},
											{
												filed: dealFormFields.WEB_LINK_3,
												label: t('Web link 3'),
												value: values.webLink3,
												error: errors.webLink3,
											},
										]}
										placeholder={t('e.g. {{example}}', { example: 'https://www.financialreports.com/...' })}
										onChange={(field: string, value: string) => {
											setFieldValue(field, value);
											setFieldError(field, undefined);
										}}
									/>
								</FormSection>
								<AttachmentsSection
									dealHasPreview={isPreviewRequests}
									dealId={dealId}
									disabled={isDealEditingDisabled}
									formProps={formProps as unknown as FormikProps<TDealFormValues>}
									isEdit
									isThirdParty
									validateMutateDisabled={validateDisableMutateFile}
								>
									<TextArea
										errorMessageStyle={termsSignedError}
										inputClassName={styles.termsAndConditions}
										label={t('Terms & Conditions')}
										minRows={7}
										readOnly
										rounded
										value={termsAndConditions?.text || ''}
									/>
									<CheckboxInput
										className={termsSignedError ? styles.checkboxInput__error : styles.checkboxInput}
										errored={termsSignedError}
										label={t('I accept the Terms & Conditions')}
										labelContainerStyles={styles.checkbox}
										labelStyles={styles.checkboxLabel}
										value={termsSigned}
										onChange={(value) => {
											setTermsSigned(value);
											setTermsSignedError(false);
										}}
									/>
									<When condition={termsSignedError}>
										<InputErrorMessage text={t('Please read and agree to the Terms & Conditions')} />
									</When>
									<Gap gap={24} />
								</AttachmentsSection>
								<div
									className={clsx(
										styles.actionsWrapper,
										(isDealEditingDisabled || deal.status === 'published') && styles.actionsWrapper__oneButton,
									)}
								>
									<Button
										disabled={!formProps.dirty || formProps.isSubmitting}
										loading={formProps.isSubmitting && formProps.values.submitAction == 'save'}
										title={t('Save')}
										type={deal.status === 'published' && styles.actionsWrapper__oneButton ? 'outline' : 'solid'}
										onClick={() => onSubmitPress('save')}
									/>
									{isDealEditingDisabled || deal.status === 'published' ? null : (
										<Button
											disabled={formProps.isSubmitting}
											loading={formProps.isSubmitting && formProps.values.submitAction == 'submit'}
											title={t('Submit')}
											onClick={() => onSubmitPress('submit')}
										/>
									)}
								</div>
								<Gap gap={12} />
								<p className={styles.saveHint}>
									{t(
										'Submitting the form will notify {{creator}} of your updates, and give them an opportunity to accept them or request changes.',
										{ creator: creatorName },
									)}
								</p>
							</div>
						</div>
					);
				}}
			</Formik>
		</div>
	) : (
		<ActivityIndicator size="medium" type="fit" />
	);
};

interface IDealFormProps {
	dealId: string;
}
