import { IdxStatus } from '@okta/okta-auth-js';
import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaVerificationChallengeRequiredError extends OktaError {
	status!: 'VERIFICATION_REQUIRED';

	static shouldThrow(transaction: IdxTransaction) {
		return transaction.status === IdxStatus.PENDING && transaction.nextStep?.name === 'authenticator-verification-data';
	}

	getMessage(t: TFunction) {
		return {
			title: t('Verification required'),
			subtitle: '',
		};
	}
}
