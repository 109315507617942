import { useMutation, useService } from '@hooks';
import type { TDeal, TFile } from '@typings';

type TUpdateDealFilesData = {
	fileId: TFile['id'];
	openInViewerOnly: boolean;
	dealId: TDeal['id'];
	name: string;
};

export const useUpdateDealPreviewFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<TFile, Error, TUpdateDealFilesData>(
		['deal.updateDealPreviewFiles'],
		async (params: TUpdateDealFilesData) => await api.dealFile.updateDealPreviewFile(params),
	);
};
