import { Gap, Input, Modal } from '@ui-kit';
import { useTranslation, useFormik } from '@hooks';
import { useCreateFolderMutation } from '@ui-modules/files/hooks/useCreateFolderMutation';
import { editFolderSchema } from '@schemas';
import type { IModalProps } from '@ui-kit';
import type { TCommunity, TFolder } from '@typings';
import type { TEditFolderForm } from '@schemas';

/** Asks for the name of a new folder in a modal window and creates one. */
const AddFolderModal = ({ communityId, folderId, visible, onClose }: IAddFolderModalProps) => {
	const { t } = useTranslation();

	const releaseDialog = () => {
		resetForm();
		onClose();
	};
	const { mutate: createFolder, isLoading } = useCreateFolderMutation(communityId, folderId, {
		onSuccess: () => releaseDialog(),
	});
	const { values, errors, handleChange, handleBlur, handleSubmit, submitForm, resetForm } = useFormik<TEditFolderForm>({
		validationSchema: editFolderSchema,
		initialValues: { name: '' },
		onSubmit: (formValues) => createFolder(formValues),
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Create')}
			confirmTrackingName="files--create-folder"
			isConfirmLoading={isLoading}
			modalContent={
				<form onSubmit={handleSubmit}>
					<Gap gap={8} />
					<Input
						errorMessage={errors.name}
						label={t('Folder Name')}
						placeholder={t('Folder name')}
						value={values.name}
						onBlur={handleBlur('name')}
						onChange={handleChange('name')}
					/>
				</form>
			}
			title={t('New Folder')}
			variant="medium"
			visible={visible}
			onCancel={releaseDialog}
			onConfirm={submitForm}
		/>
	);
};

export interface IAddFolderModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Community ID. */
	communityId: TCommunity['id'];
	/** Target Folder ID. */
	folderId: TFolder['id'] | null;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default AddFolderModal;
