import { EMAIL_LABELS } from '@constants';
import { useTranslation } from '@hooks';
import type { TEditContactCard } from '@schemas';
import { emptyEmailContactValue } from '@schemas';
import type { TEmailContact, TEmailContactType } from '@typings';
import { Checkbox } from '@ui-kit';
import { isUndefined, translateEmailLabelToTitle } from '@utils';
import type { FormikProps } from 'formik';
import { FieldArray } from 'formik';
import ContactCard from '../ContactCard';
import LabeledArrayField from '../LabeledArrayField';
import type { TBaseOption } from '../LabeledArrayField/LabeledArrayField';
import styles from './EmailsForm.module.css';

const isOptionPrimary = (option: TBaseOption) => option.type === 'primary';

const EmailsForm = ({ formProps }: IEmailsFormProps) => {
	const { t } = useTranslation();
	const { values, setFieldValue, setFieldError, errors } = formProps;

	return (
		<ContactCard secondaryTitle={t('Visible to all members')} title={t('Email')}>
			<FieldArray
				name="emails"
				render={(arrayHelpers) => (
					<LabeledArrayField
						addButtonTitle={t('Add Email')}
						arrayHelpers={arrayHelpers}
						canRemoveOption={(option) => !isOptionPrimary(option)}
						emptyValue={emptyEmailContactValue}
						getFieldError={(index: number) =>
							(errors?.emails?.[index] as TEmailContact)?.email
								? t('The email field is empty or invalid. Please check your input.')
								: ''
						}
						modalTitle={t('Email Label')}
						options={values.emails}
						translateType={(label) => translateEmailLabelToTitle(label as TEmailContactType, t)}
						types={EMAIL_LABELS}
						onOptionRemoved={() => setFieldError(`emails`, undefined)}
					>
						{(option, index) => {
							const visibleFormValue = values.emails[index].visibleToAll;
							const checkedValue = isUndefined(visibleFormValue) ? true : !!visibleFormValue;
							return (
								<div className={styles.inputRow} key={option.type}>
									<input
										value={values.emails[index].email}
										onChange={(e) => {
											setFieldValue(`emails[${index}].email`, e.target.value);
											setFieldError(`emails`, undefined);
										}}
									/>
									<Checkbox
										value={checkedValue}
										onChange={(checked: boolean) => setFieldValue(`emails[${index}].visibleToAll`, checked)}
									/>
								</div>
							);
						}}
					</LabeledArrayField>
				)}
			/>
		</ContactCard>
	);
};

export interface IEmailsFormProps {
	formProps: FormikProps<TEditContactCard>;
}

export default EmailsForm;
