import { memo } from 'react';
import { useCallback, useTranslation } from '@hooks';
import type { ISectionListProps } from '@ui-kit';
import { CommunityCard, CommunityListItem, SectionList } from '@ui-kit';
import { useGroupQueriesBreakdown } from '@ui-modules/groups/hooks/useGroupQueriesBreakdown';
import { translateMembers, translateGroups } from '@utils';
import type { TCommunityCompact } from '@typings';

const GroupSectionList = ({
	searchQuery,
	selectedGroupId,
	initialTopMostItemIndex,
	rangeChanged,
	onSelectGroup,
	onFirstGroupLoaded: onFirstItemLoaded,
}: IGroupSectionListProps) => {
	const { t } = useTranslation();

	const { sectionListItems, isLoading, MY_GROUPS_TITLE, CHAPTERS_TITLE, isRefetching, fetchMore, isFetchingMore } =
		useGroupQueriesBreakdown(searchQuery, onFirstItemLoaded);

	const renderItem = useCallback<ISectionListProps<TCommunityCompact, unknown>['renderItem']>(
		(item, indexInGroup, groupTitle, isLastInGroup, index) => {
			return groupTitle === MY_GROUPS_TITLE || groupTitle === CHAPTERS_TITLE ? (
				<CommunityCard
					community={item}
					formatGroupsCount={translateGroups(t)}
					formatMembersCount={translateMembers(t)}
					isSelected={item.id === selectedGroupId}
					onClick={() => onSelectGroup(item, index)}
				/>
			) : (
				<CommunityListItem
					community={item}
					formatMembersCount={translateMembers(t)}
					isSelected={item.id === selectedGroupId}
					onClick={() => onSelectGroup(item, index)}
				/>
			);
		},
		[sectionListItems, selectedGroupId],
	);

	return (
		<SectionList<TCommunityCompact>
			data={sectionListItems}
			emptyMessage={t('Sorry, we couldn’t find any matching results')}
			headerPadded
			hideSectionHeaders={!!searchQuery.length}
			initialTopMostItemIndex={initialTopMostItemIndex}
			isFetching={isRefetching || isFetchingMore}
			isLoading={isLoading}
			rangeChanged={rangeChanged}
			renderItem={renderItem}
			onEndReached={fetchMore}
		/>
	);
};

export interface IGroupSectionListProps
	extends Pick<ISectionListProps<TCommunityCompact>, 'initialTopMostItemIndex' | 'rangeChanged'> {
	selectedGroupId: TCommunityCompact['id'] | undefined;
	searchQuery: string;
	onSelectGroup: (community: TCommunityCompact, index: number) => void;
	onFirstGroupLoaded: (firstLoadedGroup: TCommunityCompact | undefined) => void;
}

export default memo(GroupSectionList);
