import { useService, useQuery, useMutation } from '@hooks';
import type {
	TNetworkConnection,
	TNetworkConnectionMembersResponse,
	TUid,
	TNetworkConnectionMemberRelation,
} from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';
import { dayjs, produce, uniqBy } from '@utils';

export const useNetworkConnectionMembersQuery = <T = TNetworkConnectionMembersResponse>(
	networkConnectionId: TNetworkConnection['id'],
	queryOptions?: UseQueryOptions<TNetworkConnectionMembersResponse, Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { mutate: loadMore, isLoading: loadingMore } = useMutation<
		TNetworkConnectionMemberRelation[],
		Error,
		{ page: number; networkConnectionRelationStatusId: TUid; networkConnectionRelationStatus: string }
	>(
		['getMoreNetworkConnectionMembers'],
		async ({ page, networkConnectionRelationStatusId }) =>
			await api.networkConnection.getNetworkConnectionUserRelations(networkConnectionId, page, {
				networkConnectionRelationStatusId,
			}),
		{
			onSuccess(networkConnectionMemberRelations, { networkConnectionRelationStatus }, context) {
				reactQuery.queryClient.setQueryData<TNetworkConnectionMembersResponse>(
					reactQuery.queryKeys.getNetworkConnectionMembers(networkConnectionId),
					(cache) => {
						if (!cache) return cache;
						return produce(cache, (draft) => {
							draft[networkConnectionRelationStatus].items.push(...networkConnectionMemberRelations);
							draft[networkConnectionRelationStatus].items = uniqBy(draft[networkConnectionRelationStatus].items, 'id');
						});
					},
				);
			},
		},
	);

	const removeMore = (networkConnectionRelationStatus: string) => {
		reactQuery.queryClient.setQueryData<TNetworkConnectionMembersResponse>(
			reactQuery.queryKeys.getNetworkConnectionMembers(networkConnectionId),
			(cache) => {
				if (!cache) return cache;
				return produce(cache, (draft) => {
					draft[networkConnectionRelationStatus].items = draft[networkConnectionRelationStatus].items.slice(0, 5);
				});
			},
		);
	};

	const query = useQuery<TNetworkConnectionMembersResponse, Error, T>(
		reactQuery.queryKeys.getNetworkConnectionMembers(networkConnectionId),
		async () => await api.networkConnection.getNetworkConnectionMembers(networkConnectionId),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);

	return { ...query, loadMore, removeMore, loadingMore };
};
