import { useMutation, useService } from '@hooks';
import type { TFile, TMediaObject, TNetworkConnection } from '@typings';

type TCreateFilesData = {
	files: TMediaObject[];
	owner: string;
	networkConnection: TNetworkConnection;
	isNetworkConnectionInformationFile: boolean;
};

export const useCreateNetworkConnectionFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<TFile[], Error, TCreateFilesData>(
		['networkConnection.createFiles'],
		({ files, owner, networkConnection, isNetworkConnectionInformationFile }: TCreateFilesData) => {
			return Promise.all(
				files.map((mediaObject) => {
					return api.networkConnectionFile.postNetworkConnectionFile({
						networkConnectionId: networkConnection.id,
						mediaObjectId: mediaObject.id,
						name: mediaObject.originalName,
						ownerIri: owner,
						isNetworkConnectionInformationFile,
					});
				}),
			);
		},
	);
};
