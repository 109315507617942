import { useTranslation } from '@hooks';
import { Avatar, ChatIcon, EmailIcon, PhoneIcon, ProfileIcon } from '@ui-kit';
import {
	mapEmailTypeToTitle,
	mapPhoneTypeToTitle,
} from '@ui-modules/contacts/components/ContactActionButtonSet/ContactActionButtonSet.utils';
import styles from './StaffCard.module.css';
import ContactActionButton from '@ui-modules/contacts/components/ContactActionButton';
import { formatModeratorSubTitle } from '@utils';
import type { TModerator } from '@typings';

const StaffCard = ({ profile, subtitle, onViewProfile, onOpenChat }: IStaffCardProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.staffUser} key={profile.id}>
			<div className={styles.staffUser__topContent}>
				<Avatar
					imageUri={profile.avatar}
					outline={profile['@type'] === 'ProfileChair'}
					size={65}
					title={profile.firstName[0] + profile.lastName[0]}
				/>
				<div className={styles.staffUser__titles}>
					<span className={styles.staffUser__name}>{`${profile.firstName} ${profile.lastName}`}</span>
					<span className={styles.staffUser__subtitle}>{subtitle || formatModeratorSubTitle(t)(profile)}</span>
				</div>
			</div>
			<div className={styles.staffUser__actionButtons}>
				{profile.phones.length ? (
					<ContactActionButton
						bordered
						icon={<PhoneIcon className={styles.button__icon} height={18} width={18} />}
						options={mapPhoneTypeToTitle(profile.phones, t)}
						title={t('Call')}
					/>
				) : null}
				{profile.emails.length ? (
					<ContactActionButton
						bordered
						icon={<EmailIcon className={styles.button__icon} height={18} width={18} />}
						options={mapEmailTypeToTitle(profile.emails, t)}
						title={t('Email')}
					/>
				) : null}
				<ContactActionButton
					bordered
					icon={<ChatIcon className={styles.button__icon} height={18} width={18} />}
					title={t('Chat')}
					onPress={() => onOpenChat(profile?.slug)}
				/>
				<ContactActionButton
					bordered
					icon={<ProfileIcon className={styles.button__icon} height={18} width={18} />}
					title={t('Profile')}
					onPress={() => onViewProfile(profile.id)}
				/>
			</div>
		</div>
	);
};

interface IStaffCardProps {
	profile: TModerator;
	onViewProfile: (id: string) => void;
	onOpenChat: (id: string) => void;
	subtitle?: string;
}

export default StaffCard;
