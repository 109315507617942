import { useRefreshTokenRefetchingQuery } from '@ui-modules/auth/hooks/useRefreshTokenRefetchingQuery';
import { Navigate } from 'react-router-dom';
import { REDIRECT_TO_PARAM_NAME, ROUTES } from '@constants';
import { useLogger, useService, useEffect } from '@hooks';
import type { PropsWithChildren } from 'react';

/** Wraps React Router routes relates to authentication to show them only if the user is NOT authenticated.
 * 	Otherwise redirects to the home page.
 */
const AuthRoutesWrapper = ({ children, loadingElement }: IAuthRoutesWrapperProps) => {
	const redux = useService('ReduxService');
	const logger = useLogger('AuthRoutesWrapper');

	const accessToken = redux.useAppSelector((state) => state.auth.accessToken);
	const { isFetching } = useRefreshTokenRefetchingQuery({
		suspense: false,
		onSuccess(data) {
			logger.debug('refresh token refetching succeeded', data.refreshToken);
		},
		onError(error) {
			logger.error('refresh token refetching failed', error);
		},
	});

	useEffect(() => {
		logger.debug('mounted', `refresh token present? = ${!!accessToken}`);
	}, []);

	if (isFetching) return loadingElement;
	else if (accessToken) {
		const redirectToParam = new URLSearchParams(window.location.search).get(REDIRECT_TO_PARAM_NAME);
		const initialUrl = redirectToParam ?? ROUTES.home();
		return <Navigate replace to={initialUrl} />;
	}

	return <>{children}</>;
};

export interface IAuthRoutesWrapperProps extends PropsWithChildren {
	loadingElement: JSX.Element | null;
}

export default AuthRoutesWrapper;
