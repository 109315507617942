import { FormPageLayout, Page } from '../../components';
import { useTranslation } from '@hooks';
import type { TEditProfileEvents } from '@schemas';
import { editProfileEventsSchema } from '@schemas';
import { Formik } from '@ui-kit';
import { ROUTES } from '@constants';
import { useUpdateProfileHidePropertyMutation } from '@ui-modules/profile/hooks/useUpdateProfileHiddenessMutation';
import EditProfileSwitchForm from '@ui-modules/profile/components/EditProfileSwitchForm';

const EditProfileEventsPage = () => {
	const { t } = useTranslation();
	const { initialValues, submit } = useUpdateProfileHidePropertyMutation('events');

	return (
		<Page title={t('Edit Profile Events')}>
			<Formik<TEditProfileEvents>
				enableReinitialize
				initialValues={initialValues as TEditProfileEvents}
				validationSchema={editProfileEventsSchema}
				onSubmit={submit}
			>
				{(formProps) => {
					const { values, setFieldValue } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileEvents()]}
							formProps={formProps}
							headerTitle={t('Events')}
							saveTrackingName="profile--edit-events"
						>
							<EditProfileSwitchForm
								activeDescription={t('Members can see how you’re engaging with the community.')}
								activeTitle={t('Show my recent events')}
								inactiveDescription={t('Members won’t be able to see how you’re engaging with the community.')}
								inactiveTitle={t('Hide my recent events')}
								value={values.showOnProfile}
								onChange={(value) => setFieldValue('showOnProfile', value)}
							/>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileEventsPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileEventsPage;
