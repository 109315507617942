import { useTranslation } from '@hooks';
import { verifySignInCodeFormSchema } from '@schemas';
import { Button, Formik, Input, Form } from '@ui-kit';
import type { TVerifySignInCodeForm } from '@schemas';
import type { ReactNode } from 'react';

const VerifySignInForm = ({ BottomContentComponent, onSubmit }: IVerifySignInFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TVerifySignInCodeForm>
			initialValues={{ code: '' }}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={verifySignInCodeFormSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			{({ values, errors, isSubmitting, handleChange, handleBlur, submitForm }) => {
				return (
					<Form>
						<Input
							ariaLabel="code"
							autoCapitalize="none"
							autoComplete="one-time-code"
							errorMessage={errors.code}
							label={t('Enter code')}
							value={values.code}
							onBlur={handleBlur('code')}
							onChange={handleChange('code')}
						/>
						{BottomContentComponent}
						<Button
							block="fit"
							loading={isSubmitting}
							title={t('Verify')}
							type="outline"
							variant="medium"
							onClick={submitForm}
						/>

						{/* Submitting a form by pressing enter without a submit button */}
						<input hidden type="submit" />
					</Form>
				);
			}}
		</Formik>
	);
};

export interface IVerifySignInFormProps {
	BottomContentComponent?: ReactNode;
	onSubmit: (formValues: TVerifySignInCodeForm) => void;
}

export default VerifySignInForm;
