import { useService } from '@services';
import type { TProfile, TUser } from '@typings';

/** Returns account information (user, profile, etc) of a current user has logged to the app. */
export const useMe = () => {
	const { useAppSelector } = useService('ReduxService');

	const profile = useAppSelector((store) => store.me.profile) as TProfile;
	const user = useAppSelector((store) => store.me.user) as TUser;
	const fullName = `${user?.firstName} ${user?.lastName}`;

	return { profile, user, fullName };
};
