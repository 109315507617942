import { useRef, useState, useTranslation, useMe } from '@hooks';
import styles from './DealsTabHeader.module.css';
import { Button, EditIcon, HeaderSearchInput, SearchIcon } from '@ui-kit';
import { clsx, DealEntity } from '@utils';

const DealsTabHeader = ({
	title,
	searchQuery,
	placeholder,
	onChangeSearchQuery,
	onSearchOpened,
	onSearchCanceled,
	onAddNewDeal,
	onChangeFocus,
}: IDealsTabHeaderProps) => {
	const [isExpanded, setIsExpanded] = useState(!!searchQuery.length);
	const inputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();
	const { user } = useMe();

	const expandSearch = () => {
		setIsExpanded(true);
		if (onSearchOpened) onSearchOpened(true);
		setTimeout(() => {
			inputRef.current && inputRef.current.focus();
		}, 0);
	};

	const handleSearchCanceled = () => {
		inputRef.current?.blur();
		setIsExpanded(false);
		if (onSearchOpened) onSearchOpened(false);
		onChangeSearchQuery('');
		onSearchCanceled?.();
		onChangeFocus?.(false);
	};

	const handleSearchCleared = () => {
		onChangeSearchQuery('');
	};

	return (
		<div className={styles.header}>
			<div className={styles.header__controller}>
				{title ? <span className={styles.header__title}>{title}</span> : null}
				{isExpanded ? (
					<div className={clsx(styles.header__input)}>
						<HeaderSearchInput
							autoFocus
							placeholder={placeholder}
							ref={inputRef}
							type="cleared"
							value={searchQuery}
							onChange={(e) => onChangeSearchQuery(e.target.value)}
							onFocus={() => onChangeFocus?.(true)}
							onReset={() => onChangeSearchQuery('')}
							onSearchCanceled={handleSearchCanceled}
							onSearchCleared={handleSearchCleared}
						/>
					</div>
				) : (
					<div className={styles.header__buttons}>
						<Button
							icon={<SearchIcon fill="black" height={16} width={16} />}
							iconPosition="left"
							title={t('Search')}
							type="outline"
							variant="small"
							onClick={expandSearch}
						/>
						{DealEntity.canCreateDeal(user) && onAddNewDeal ? (
							<Button
								icon={<EditIcon fill="black" height={16} width={16} />}
								iconPosition="left"
								title={t('New')}
								variant="small"
								onClick={onAddNewDeal}
							/>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
};

export interface IDealsTabHeaderProps {
	title?: string;
	searchQuery: string;
	placeholder: string;
	clearMode?: boolean;
	onChangeSearchQuery: (searchQuery: string) => void;
	onSearchOpened?: (isOpened: boolean) => void;
	onSearchCanceled?: () => void;
	onChangeFocus?: (isFocused: boolean) => void;
	onAddNewDeal?: () => void;
}

DealsTabHeader.height = 64;
export default DealsTabHeader;
