import { ActivityIndicator } from '@ui-kit';
import EventItem from '@ui-modules/events/components/EventItem';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import type { TEvent } from '@tiger21-llc/connect-shared/src/typings';

/** Downloads event by ID and displays as a card. */
const PostEvent = ({ eventId, onClick }: IPostEventProps) => {
	const { data: event } = useGetEventQuery(eventId);

	if (!event) return <ActivityIndicator size="small" type="fit" />;

	return <EventItem event={event} onClick={() => onClick(event)} />;
};
export interface IPostEventProps {
	/** Event ID to display. */
	eventId: TEvent['id'];
	/** Calls when event clicked. */
	onClick: (event: TEvent) => void;
}

export default PostEvent;
