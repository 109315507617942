import { useMe, useTranslation, useService, useNavigate, useChat } from '@hooks';
import { compact } from '@utils';
import { useDeleteChatChannelMutation } from './useDeleteChatChannelMutation';
import { ROUTES } from '@constants';
import type { TMeatballMenuOption } from '@ui-kit';
import { BlockIcon, EditIcon, ManageMemberIcon, DeleteIcon } from '@ui-kit/icons';

export const useMutateChatMeatballMenuOptions = (hasPauseUsers: boolean, isChannelAdmin: boolean | false) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const chat = useService('ChatService');
	const { selectedChannel } = useChat();
	const navigate = useNavigate();
	const isGroupChat = chat?.isGroupChat(selectedChannel);
	const isAdmin = user.roles.includes('ROLE_ADMIN');
	const [deleteChatChannel, DeleteChatChannelConfirmationModal] = useDeleteChatChannelMutation(
		String(selectedChannel?.cid),
		{
			onSuccess: () => {
				navigate(ROUTES.chat());
			},
		},
	);

	const meatballMenuOptions: TMeatballMenuOption[] = compact([
		isGroupChat && isChannelAdmin
			? {
					icon: <ManageMemberIcon color="black" height={16} width={20} />,
					text: t('Manage Members'),
					onClick: () => navigate(ROUTES.chatEditGroup(selectedChannel?.id)),
			  }
			: null,

		isGroupChat && isChannelAdmin
			? {
					icon: <EditIcon color="black" height={16} width={20} />,
					text: t('Rename group chat'),
					onClick: () => navigate(ROUTES.chatRenameGroup(selectedChannel?.id)),
			  }
			: null,

		isAdmin && hasPauseUsers
			? {
					icon: <BlockIcon fill="black" height={16} width={16} />,
					text: t('Resume Members'),
					onClick: async () => {
						navigate(ROUTES.chatUnPauseMembers(selectedChannel?.id));
					},
			  }
			: null,
		isGroupChat && isAdmin
			? {
					icon: <DeleteIcon fill="#fa2f53" />,
					text: t('Delete Chat'),
					type: 'destructive',
					onClick: async () => {
						deleteChatChannel();
					},
			  }
			: null,
	]);

	return [meatballMenuOptions.length ? meatballMenuOptions : undefined, DeleteChatChannelConfirmationModal] as const;
};
