import { useMutation, useService } from '@hooks';
import type { TMediaObject } from '@typings';

type TCreateDealFilesData = {
	files: TMediaObject[];
	owner: string;
	relatedEntity: string;
	isDealInformationFile?: boolean;
};

export const useCreateDealFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<void[], Error, TCreateDealFilesData>(
		['deal.createDealFiles'],
		({ files, owner, relatedEntity, isDealInformationFile }: TCreateDealFilesData) => {
			return Promise.all(
				files.map((mediaObject) => {
					return api.invest.createDealFiles({
						mediaObject: mediaObject['@id'],
						name: mediaObject.originalName,
						relatedEntity,
						owner,
						isDealInformationFile,
					});
				}),
			);
		},
	);
};
