import { useEvent, useSearchParams } from '@hooks';

/** Parses 'searchQuery' search param from the route and can set it. */
export const useRouteSearchQueryState = (): [string, (searchQuery: string) => void] => {
	const [searchQuery, setSearchParams] = useSearchParams();
	const routeSearchQuery = searchQuery.get('searchQuery') || '';

	const setRouteSearchQuery = useEvent((searchQuery: string) => {
		setSearchParams(
			(prev) => {
				if (searchQuery) {
					prev.set('searchQuery', searchQuery);
				}
				if (!searchQuery && prev.has('searchQuery')) {
					prev.delete('searchQuery');
				}
				return prev.toString();
			},
			{ replace: true },
		);
	});

	return [routeSearchQuery, setRouteSearchQuery];
};
