import { useTranslation } from '@hooks';
import styles from './ThirdPartyDealFormSubmitMessage.module.css';

export const ThirdPartyDealFormErrorMessage = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.submitMessage__contentWrapper}>
			<div className={styles.errorMessage__container}>
				<p className={styles.errorMessage__title}>{t('This deal is no longer available.')}</p>
				<p className={styles.errorMessage__subTitle}>{t('This is most likely due to the deal being cancelled.')}</p>
			</div>
		</div>
	);
};
