import { Avatar } from '@ui-kit';
import type { TChatUser } from '@typings';
import { useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import type { MessageResponse } from 'stream-chat';

const QuotedMessageAvatar = ({ quotedMessage }: IQuotedMessageAvatar) => {
	const navigate = useNavigate();
	const user = quotedMessage?.user as TChatUser;
	const isChair = user?.roles?.includes('ROLE_CHAIR') || false;
	const isInActive = user?.roles?.includes('ROLE_INACTIVE') || false;
	const onAvatarPressed = () => {
		if (user.profileId) {
			navigate(ROUTES.memberProfile(user.profileId));
		}
	};
	return (
		<div onClick={onAvatarPressed} onKeyDown={onAvatarPressed}>
			<Avatar
				imageUri={quotedMessage?.user?.avatar ? `${quotedMessage?.user?.avatar}` : null}
				isInactive={isInActive}
				outline={isChair}
				size={25}
				title={quotedMessage?.user?.name}
			/>
		</div>
	);
};

export default QuotedMessageAvatar;

export interface IQuotedMessageAvatar {
	quotedMessage: MessageResponse | undefined;
}
