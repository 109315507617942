import { FullScreenPageLayout, Page } from '../../components';
import { ActivityIndicator, FixedWidthContainer, Gap, Toast } from '@ui-kit';
import NetworkRequestView from '@ui-modules/networks/components/NetworkRequestView';
import { useTranslation, useNavigate, useParams, useEvent, useHandleBackButton } from '@hooks';
import { useNetworkRequestQuery } from '@ui-modules/networks/hooks/useNetworkRequestQuery';
import { ROUTES } from '@constants';
import styles from './RequestConfirmationPage.module.css';
import type { TNetworkCreationRequest, TProfile } from '@typings';

const RequestNetworkConfirmationPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const networkRequestId = useParams<IRequestConfirmationPageParams>().networkRequestId as string;
	const { onBackButtonPress } = useHandleBackButton();

	const { data: networkRequest, isLoading } = useNetworkRequestQuery(networkRequestId, { suspense: true });
	const openMemberProfile = useEvent((profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId)));

	return (
		<Page title={t('Request confirmation')}>
			<FullScreenPageLayout
				headerTitle={t('Request confirmation')}
				rightButtonText={t('Done')}
				onRightButtonPress={onBackButtonPress}
			>
				<FixedWidthContainer>
					<div className={styles.requestNetworkConfirmationPage}>
						<ActivityIndicator hidden={!isLoading} size="medium" type="fit" />
						{networkRequest ? (
							<>
								<div className={styles.requestNetworkConfirmationPage__toastBox}>
									<Toast
										subtitle={t('Your network submittion details are below')}
										title={t('Thanks! Your request is sent')}
										type="success"
									/>
								</div>
								<NetworkRequestView networkRequest={networkRequest} onUserClick={openMemberProfile} />
							</>
						) : null}
						<Gap gap={32} />
					</div>
				</FixedWidthContainer>
			</FullScreenPageLayout>
		</Page>
	);
};

export interface IRequestConfirmationPageParams extends Record<string, string | undefined> {
	networkRequestId: TNetworkCreationRequest['id'];
}

export default RequestNetworkConfirmationPage;
