import { Button, RightArrowIcon } from '@ui-kit';

import styles from './EventLocationCard.module.css';

export const EventLocationCardButton = ({ title, onClick }: IEventLocationCardButtonProps) => {
	return (
		<Button
			className={styles.eventLocationCardButton}
			icon={<RightArrowIcon className={styles.eventLocationCardButton__icon} height={14} width={14} />}
			iconPosition="right"
			title={title}
			type="clear"
			onClick={onClick}
		/>
	);
};

export interface IEventLocationCardButtonProps {
	title: string;
	onClick: () => void;
}
