import { formatEventVenue, MeetingEntity, clsx } from '@utils';
import type { TEvent } from '@typings';
import EventAvatar from '../EventAvatar';
import { adaptEventRecord } from './adaptEventReccord';
import styles from './EventItem.module.css';
import { useTranslation } from '@hooks';
import { CheckMarkIcon, LongChevronRightIcon, When } from '@ui-kit';
import MeetingActionButton from '../MeetingActionButton';
import type { IMeetingActionButtonCallbacksProps } from '../MeetingActionButton/MeetingActionButton';
import type { PropsWithChildren } from 'react';
import type { KeyboardEventHandler, MouseEventHandler } from 'react';
import { translateEventType, getCompiledEventType, getEventTypeColors } from '@utils';

const getStatusStyle = (isAttending?: boolean, isCancelled?: boolean) => {
	if (isCancelled) return undefined;
	if (isAttending) return { card: styles.eventItem_attending, badge: styles.eventItem__badge_attending };
};

const EventItem = ({
	event: eventData,
	hideBanner,
	allowPropagation,
	onClick,
	className,
	isSelected,
	isCancelled,
	isAttending,
	hideEventType,
	fixedWidthCard,
	shouldShowMeetingAction,
	onChangeAttendance,
	onLaunchVirtualMeeting,
	onRequestAttendance,
	onCancelRequestAttendance,
	hideChevronIcon,
	children,
	eventTypeClassName,
	titleVariant,
}: IEventItemProps) => {
	const event = adaptEventRecord(eventData);
	const showBanner = !!event.banner?.contentUrl && !hideBanner;
	const venue = formatEventVenue(event).join(', ');
	const { t } = useTranslation();
	const compiledEventType = getCompiledEventType(event);
	const eventTypeTitle = translateEventType(t)(compiledEventType);
	const { primaryColor } = getEventTypeColors(compiledEventType);
	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		if (!allowPropagation) event.stopPropagation();
		onClick?.();
	};

	const statusStyle = getStatusStyle(isAttending, isCancelled);

	return (
		<div
			className={clsx(
				className,
				styles.eventItem__wrapper,
				isSelected && styles.eventItem__wrapper_selected,
				isSelected === undefined && styles.eventItem__wrapper_nonSelectable,
			)}
		>
			<div
				aria-selected={isSelected}
				className={clsx(fixedWidthCard ? styles.eventItemWFixed : styles.eventItem, statusStyle?.card, className)}
				role="option"
				tabIndex={0}
				onClick={handleClick}
				onKeyPress={handleClick}
			>
				<When condition={isAttending && !isCancelled}>
					<div className={clsx(styles.eventItem__badge, statusStyle?.badge)}>
						<CheckMarkIcon />
						<span>{t('Attending')}</span>
					</div>
				</When>
				{showBanner && <img alt="Event banner" className={styles.eventItem__banner} src={event.banner?.contentUrl} />}
				<div className={styles.eventItem__content}>
					<div className={styles.eventItem__infoWrapper}>
						<div className={styles.eventItem__avatarWrapper}>
							<EventAvatar event={event} />
						</div>
						<div className={styles.eventItem__body}>
							<When condition={!hideEventType}>
								<span className={clsx(styles.eventItem__titleType, eventTypeClassName)} style={{ color: primaryColor }}>
									{eventTypeTitle}
								</span>
							</When>
							<h3 className={clsx(styles.eventItem__title, titleVariant === 'large' && styles.eventItem__title_large)}>
								{event.title}
							</h3>
							{venue ? (
								<p className={clsx(styles.eventItem__venue, titleVariant === 'large' && styles.eventItem__venue_large)}>
									{venue}
								</p>
							) : null}
							{MeetingEntity.getUserConferenceLink(event) || event.isAllowedVirtualAttendance ? (
								<p
									className={clsx(
										styles.eventItem__conferenceLabel,
										titleVariant === 'large' && styles.eventItem__conferenceLabel_large,
									)}
								>{`(${t('video conference')})`}</p>
							) : null}
							{shouldShowMeetingAction &&
							onChangeAttendance &&
							onLaunchVirtualMeeting &&
							onRequestAttendance &&
							onCancelRequestAttendance ? (
								<div className={styles.eventItem__actionButton}>
									<MeetingActionButton
										detailed={false}
										meeting={event}
										size="small"
										onCancelRequestAttendance={onCancelRequestAttendance}
										onChangeAttendance={onChangeAttendance}
										onLaunchVirtualMeeting={onLaunchVirtualMeeting}
										onRequestAttendance={onRequestAttendance}
									/>
								</div>
							) : null}
						</div>
					</div>
					<div>
						{!hideChevronIcon && (
							<LongChevronRightIcon className={styles.eventItem__chevronIcon} fill="black" height={16} width={8} />
						)}
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};

interface IEventItemProps extends Partial<IMeetingActionButtonCallbacksProps>, PropsWithChildren {
	event: TEvent;
	isSelected?: boolean;
	onClick?: () => void;
	shouldShowMeetingAction?: boolean;
	isCancelled?: boolean;
	className?: string;
	eventTypeClassName?: string;
	hideBanner?: boolean;
	hideEventType?: boolean;
	hideChevronIcon?: boolean;
	allowPropagation?: boolean;
	isAttending?: boolean;
	titleVariant?: 'default' | 'large';
	fixedWidthCard?: boolean;
}

export default EventItem;
