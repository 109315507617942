import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDealAssetClass } from '@typings';
import type { TCommunity } from '@typings';

export const useGetDealAssetClassesWithCommunityIdsQuery = (communityIds: TCommunity['id'][], enabled = true) => {
	const api = useService('ApiService');

	return useQuery<TDealAssetClass[], Error>(
		['dealAssetClass.getDealAssetClassesWithCommunityIdsQuery'],
		() => {
			return api.dealAssetClass.getDealAssetClassesWithCommunityIds(communityIds);
		},
		{
			cacheTime: 0,
			staleTime: 0,
			enabled,
		},
	);
};
