import CardHeader from './CardHeader';
import CardArticle from './CardArticle';
import type { ICardHeaderProps } from './CardHeader';
import type { ICardArticleProps } from './CardArticle';

/** Simple card with the gold underline. */
const Card = ({ children, articleMode, iconPosition = 'right', ...cardHeaderProps }: ICardProps) => {
	return (
		<CardHeader {...cardHeaderProps}>
			<CardArticle
				articleMode={articleMode || (cardHeaderProps.backgroundPlacement === 'article' ? 'default' : 'transparent')}
				iconPosition={iconPosition}
			>
				{children}
			</CardArticle>
		</CardHeader>
	);
};

export interface ICardProps extends ICardHeaderProps, ICardArticleProps {}

export default Card;
