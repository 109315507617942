import { Tab } from 'react-tabs';
import styles from './Tabs.module.css';
import type { TabProps } from 'react-tabs';
import { clsx } from '@utils';

const TabsTab = ({
	children: title,
	disabled = false,
	selectedClassName,
	darkMode = false,
	nonSelectedClassName = '',
	...passThroughProps
}: ITabsTabProps) => {
	return (
		<Tab
			className={clsx(
				styles.tabs__tab,
				darkMode ? styles.tabs__tab_dark : styles.tabs__tab_light,
				nonSelectedClassName,
			)}
			disabled={disabled}
			disabledClassName={styles.tabs__tab_disabled}
			selectedClassName={clsx(darkMode ? styles.tabs__tabDark_selected : styles.tabs__tab_selected, selectedClassName)}
			{...passThroughProps}
		>
			{title}
		</Tab>
	);
};

export interface ITabsTabProps {
	/** Title of the Tab. */
	children: TabProps['children'];
	/** Disable this tab which will make it not do anything when clicked. Default false.*/
	disabled?: TabProps['disabled'];
	/** CSS class passed to selected tab item. */
	selectedClassName?: string;
	/** For some features (example as a Deals) we need to have dark mode of tabs */
	darkMode?: boolean;
	/** For some features (example as a Deals) we need to have another color of tab's title */
	nonSelectedClassName?: string;
}

TabsTab.tabsRole = 'Tab'; // Required field to use your custom Tab (see https://github.com/reactjs/react-tabs#custom-components)
export default TabsTab;
