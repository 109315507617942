import { useMe, useMutation, useNotification, useService } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { toString, settingsPostTypeToInternalPostType } from '@utils';
import type { Reaction } from 'getstream';
import type { TActivity, TAddReactionResponse, TCommentReactionData, TCommunity } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useAddChildReactionMutation = (
	activity: TActivity,
	options?: MutationOptions<TAddReactionResponse, Error, TUseAddChildReactionMutationPayload>,
) => {
	const { showUnknownError } = useNotification();
	const feedContext = useFeedContext();
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const mixpanelAnalytics = useService('AnalyticsService');
	const { user } = useMe();
	const reactQuery = useService('ReactQueryService');

	return useMutation<TAddReactionResponse, Error, TUseAddChildReactionMutationPayload>(
		['feed.onAddChildReaction'],
		async ({ reaction, comment }) => {
			const childReaction = (await feedContext.onAddChildReaction(
				'comment',
				reaction.parent || (reaction.id as any),
				comment,
			)) as unknown as TAddReactionResponse;
			if (!childReaction) throw new Error('Failed to add child reaction');
			return childReaction;
		},
		{
			...options,
			onSuccess(addedReply, variables, context) {
				const { reaction, community } = variables;
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getPostActivityReactions(activity.id));
				algoliaAnalytics.trackEvent('User Interacted', {
					replyOwnerId: user.id,
					discussionOwnerId: activity.actor.data.userId,
				});
				algoliaAnalytics.trackEvent('Reply Created', { replySlug: reaction.id, discussionId: activity.foreign_id });
				if (community) {
					const networkType = community.features.includes('invest') ? 'Invest' : 'Lifestyle';
					if (networkType === 'Lifestyle') {
						mixpanelAnalytics.trackEvent('LifestyleNetworkDiscussionReplyCreated', {
							network_id: community.id,
							PostTitle: activity?.subject || toString(activity?.body).slice(0, 15),
							PostType: settingsPostTypeToInternalPostType(activity.settings.postType),
							PostCharacterLength: addedReply.data.text?.length ?? 0,
							post_id: addedReply.activity_id,
							reply_id: addedReply.id,
							ParentPostID: activity.foreign_id,
						});
					}
				}
				options?.onSuccess?.(addedReply, variables, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TUseAddChildReactionMutationPayload = {
	comment: TCommentReactionData;
	reaction: Reaction;
	community?: TCommunity;
};
