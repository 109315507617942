import { memo, useRef } from 'react';
import { Button, CommunityCard, SectionList } from '@ui-kit';
import NetworkAttendanceButton from '@ui-modules/networks/components/NetworkAttendanceButton';
import { useCallback, useEvent, useNavigate, useTranslation, useMemo, useScrollToChangedItem } from '@hooks';
import { translateMembers } from '@utils';
import { useNetworkQueriesBreakdown } from '@ui-modules/networks/hooks/useNetworkQueriesBreakdown';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import type { ISectionListProps } from '@ui-kit';
import type { TCommunity, TCommunityCompact } from '@typings';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import styles from './NetworkSectionList.module.css';
import type { ISectionListHandle } from '@ui-kit';

const NetworkSectionList = ({
	networkTab,
	searchQuery,
	selectedNetworkId,
	onSelectNetwork,
	onFirstNetworkLoaded,
	shouldOverrideInvestStyles = true,
	...sectionListProps
}: INetworkSectionListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const listRef = useRef<ISectionListHandle>(null);
	const isInvestSection = networkTab === INVEST_NETWORKS_TAB_SLUG;

	const { sectionListItems, isLoading, myNetworks } = useNetworkQueriesBreakdown(
		networkTab,
		searchQuery,
		onFirstNetworkLoaded,
	);
	const { setLastChangedItemId } = useScrollToChangedItem(listRef, myNetworks);
	const { joinNetwork, requestAccess } = useNetworkMutations({
		onJoinSuccess(networkId) {
			setLastChangedItemId(networkId);
		},
	});

	const openRequestNetwork = useEvent(() => navigate(ROUTES.requestNetwork()));

	const renderItem = useCallback<ISectionListProps<TCommunity, unknown>['renderItem']>(
		(network, indexInGroup, groupTitle, isLastInGroup, index) => (
			<CommunityCard
				community={network}
				formatMembersCount={translateMembers(t)}
				isSelected={network.id === selectedNetworkId}
				onClick={() => onSelectNetwork(network, index)}
			>
				<NetworkAttendanceButton
					network={network}
					onJoin={() => joinNetwork(network)}
					onRequestAccess={() => requestAccess(network)}
				/>
			</CommunityCard>
		),
		[sectionListItems, selectedNetworkId],
	);

	const listFooter = useMemo(
		() =>
			searchQuery.length ? null : (
				<div className={styles.networksPage__requestNetworkButton}>
					<Button block="fit" title={t('Request a network')} type="outline" onClick={openRequestNetwork} />
				</div>
			),
		[searchQuery],
	);

	return (
		<SectionList<TCommunity>
			className={isInvestSection && shouldOverrideInvestStyles ? styles.list : ''}
			data={sectionListItems}
			emptyMessage={t('Sorry, we couldn’t find any matching results')}
			emptyMessageColorMode={isInvestSection ? 'white' : 'dark'}
			headerClassName={isInvestSection && shouldOverrideInvestStyles ? styles.listHeader : ''}
			headerPadded
			hideSectionHeaders={!!searchQuery.length}
			innerRef={listRef}
			isLoading={isLoading}
			ListFooterComponent={isInvestSection && shouldOverrideInvestStyles ? null : listFooter}
			renderItem={renderItem}
			{...sectionListProps}
		/>
	);
};

export interface INetworkSectionListProps
	extends Pick<
		ISectionListProps<TCommunity>,
		'ListHeaderComponent' | 'ListFooterComponent' | 'initialTopMostItemIndex' | 'rangeChanged'
	> {
	selectedNetworkId: TCommunity['id'] | undefined;
	searchQuery: string;
	networkTab: string;
	onSelectNetwork: (community: TCommunityCompact, index: number) => void;
	onFirstNetworkLoaded: (firstLoadedGroup: TCommunityCompact | undefined) => void;
	shouldOverrideInvestStyles?: boolean;
}

export default memo(NetworkSectionList);
