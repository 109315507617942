import { useService, useMutation, useQueryClient, useNotification } from '@hooks';
import { useUploadMediaObjectMutation } from '@ui-modules/files/hooks/useUploadMediaObjectMutation';
import type { TMediaObject, TFile, TEvent, TFolder } from '@typings';

export const useUploadEventFileMutation = (
	calendarItemId: TEvent['id'],
	folderId: TFolder['id'] | undefined,
	onSuccess?: () => void,
) => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { showUnknownError } = useNotification();

	const uploadFileMutation = useMutation<TFile, Error, TMediaObject>(
		['calendarItemFile.postMeetingFile'],
		async (mediaObject) =>
			await api.calendarItemFile.postMeetingFile({
				calendarItemId,
				folderId,
				mediaObjectId: mediaObject.id,
				name: mediaObject.originalName,
			}),
		{
			onSuccess: () => {
				queryClient.refetchQueries(['calendarItemFile.getMeetingFiles', calendarItemId, folderId]);
				onSuccess?.();
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	const uploadMediaObjectMutation = useUploadMediaObjectMutation({
		onSuccess: (mediaObject) => {
			uploadFileMutation.mutateAsync(mediaObject);
		},
	});

	return {
		uploadFile: uploadMediaObjectMutation.mutateAsync,
		isLoading: uploadMediaObjectMutation.isLoading || uploadFileMutation.isLoading,
	};
};
