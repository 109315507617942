import { Navigate } from 'react-router-dom';
import { Tabs, Button, EditIcon, Gap, Modal, RightArrowIcon } from '@ui-kit';
import { useTranslation, useParams, useNavigate, useMe, useState } from '@hooks';
import { useDealsTabsHandler } from '@ui-modules/deals/hooks/useDealsTabsHandler';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { ROUTES } from '../../routes';
import { AllDealsPage } from '../AllDealsPage';
import MyDealsPage from '../MyDealsPage';
import NotInterestedDealsPage from '../NotInterestedDealsPage';
import { DealEntity } from '@utils';
import type { TDealsTabName } from '@typings';
import styles from './DealsTabsPage.module.css';
import { TabWrapper } from '../../components';
import { useMyInvestNetworksQuery } from '@ui-modules/networks/hooks/useMyInvestNetworksQuery';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';

const DealsTabsPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	useDealsDisabledEffect();

	const dealsTab = useParams<IDealsTabsPageParams>().dealsTab as IDealsTabsPageParams['dealsTab'];
	const tabs: TDealsTabName[] = ['my-deals', 'all-deals', 'not-interested'];
	const { currentTabIndex, onChangeTab } = useDealsTabsHandler(tabs, dealsTab);

	const { user } = useMe();
	const { isMessageShown } = useSolicitationWaiver();

	const [showDialog, setShowDialog] = useState(false);
	const { data: myNetworks = [], isLoading: isMyInvestNetworksLoading } = useMyInvestNetworksQuery();

	const { enabled } = useDealsEnabled();

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;

	return (
		<div className={styles.dealTab__wrapper}>
			<Gap gap={24} />
			<div className={styles.dealTab__header}>
				<p className={styles.dealTab__zeroTitle}>{t('Invest')}</p>
				<div className={styles.dealTab__headerButtons}>
					{DealEntity.canCreateDeal(user) ? (
						<Button
							disabled={isMessageShown || isMyInvestNetworksLoading}
							icon={<EditIcon fill="#000000" height={14} width={14} />}
							title={t('New')}
							variant="small"
							onClick={() => {
								if (!myNetworks.length) {
									setShowDialog(true);
									return;
								}
								navigate(ROUTES.newDeal());
							}}
						/>
					) : null}
				</div>
			</div>
			<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
				<Tabs.List>
					{tabs.map((tab) => {
						switch (tab) {
							case 'my-deals':
								return (
									<Tabs.Tab darkMode key="myDeals">
										{t('My Deals')}
									</Tabs.Tab>
								);
							case 'all-deals':
								return (
									<Tabs.Tab darkMode key="allDeals">
										{t('All Deals')}
									</Tabs.Tab>
								);
							case 'not-interested':
								return (
									<Tabs.Tab darkMode key="notInterested">
										{t('Not Interested')}
									</Tabs.Tab>
								);
						}
					})}
				</Tabs.List>
				{tabs.map((tab) => {
					switch (tab) {
						case 'my-deals':
							return (
								<Tabs.Panel darkMode key="my-deals">
									<TabWrapper className={styles.dealTabWrapper}>
										<MyDealsPage />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'all-deals':
							return (
								<Tabs.Panel darkMode key="all-deals">
									<TabWrapper className={styles.dealTabWrapper}>
										<AllDealsPage />
									</TabWrapper>
								</Tabs.Panel>
							);
						case 'not-interested':
							return (
								<Tabs.Panel darkMode key="not-interested">
									<TabWrapper className={styles.dealTabWrapper}>
										<NotInterestedDealsPage />
									</TabWrapper>
								</Tabs.Panel>
							);
					}
				})}
			</Tabs>
			<Modal
				cancelTitle={t('Cancel')}
				confirmIcon={<RightArrowIcon />}
				confirmTitle={t('Investment Networks')}
				subTitle={t(
					'To create a Deal, first join the Investment Network where your Deal should appear, then return to create your Deal.',
				)}
				title={t('First, Join an Investment Network')}
				variant="medium"
				visible={showDialog}
				onCancel={() => setShowDialog(false)}
				onConfirm={() => {
					setShowDialog(false);
					navigate(ROUTES.networksType('invest'));
				}}
			/>
		</div>
	);
};

export interface IDealsTabsPageParams extends Record<string, string | undefined> {
	dealTab: TDealsTabName;
}

export default DealsTabsPage;
