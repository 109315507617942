import {
	useMe,
	useTranslation,
	useService,
	useNavigate,
	useChat,
	useToggle,
	useUserSessionStorageValue,
	useQuery,
} from '@hooks';
import { compact } from '@utils';
import { COPIED_TEXT, ROUTES } from '@constants';
import type { MessageResponse } from 'stream-chat';

import type { TMeatballMenuOption } from '@ui-kit';
import { BanUserIcon } from '@ui-kit';
import { Modal } from '@ui-kit';
import { ReplyIcon, BoldEditIcon, CopyIcon, DeleteIcon, ReportIcon } from '@ui-kit';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import type { TChatUser, TEvent } from '@typings';
import { usePauseUserMutation } from '@ui-modules/chat/hooks/usePauseUserMutation';
import { useUnpauseUserMutation } from '@ui-modules/chat/hooks/useUnpauseUserMutation';
import { useIsUserPausedQuery } from '@ui-modules/chat/hooks/useIsUserPausedQuery';

export const useMutateMessageMeatballMenuOptions = (message: MessageResponse) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const chat = useService('ChatService');
	const { setEditMessage, reset, setQuotedMessageId, setShouldUpdateChannel, setSelectedEvent } = useChat();
	const { setStorageSavedValue } = useUserSessionStorageValue('active-chat-message-id');
	const navigate = useNavigate();

	const messageUser = message.user as TChatUser;
	const { data: isUserPaused } = useIsUserPausedQuery(messageUser.id);
	const isMe = user.slug === messageUser.id;
	const isAdmin = user.roles.includes('ROLE_ADMIN');
	const messageUserIsAdminOrStaff = Boolean(
		messageUser.roles?.includes?.('ROLE_ADMIN') || messageUser.roles?.includes?.('ROLE_STAFF'),
	);

	const { data: event } = useGetEventQuery(message?.eventId as string);

	const { mutateAsync: pause } = usePauseUserMutation();
	const { mutateAsync: unpause } = useUnpauseUserMutation();

	const [isDeleteMessageDialogVisible, toggleDeleteMessageDialogVisible] = useToggle(false);

	const meatballMenuOptions: TMeatballMenuOption[] = compact([
		{
			icon: <ReplyIcon fill="black" />,
			text: t('Reply'),
			onClick: () => {
				reset();
				setQuotedMessageId(message.id);
			},
		},
		isMe
			? {
					icon: <BoldEditIcon fill="black" />,
					text: t('Edit'),
					onClick: () => {
						reset();
						setEditMessage(message);
						setSelectedEvent(event as TEvent);
					},
			  }
			: null,
		{
			icon: <CopyIcon fill="black" />,
			text: t('Copy Message'),
			onClick: () => navigator.clipboard.writeText(`${COPIED_TEXT}${message.id}`),
		},
		!isMe
			? {
					icon: <ReportIcon fill="#fa2f53" />,
					text: t('Report Message'),
					onClick: () => {
						setShouldUpdateChannel(false);
						setStorageSavedValue(message.id);
						navigate(ROUTES.reportContent('chat-message', message.id));
					},
			  }
			: null,
		isAdmin &&
			isUserPaused &&
			!messageUserIsAdminOrStaff && {
				icon: <BanUserIcon fill="black" />,
				text: t('Resume'),
				onClick: () => {
					unpause(`${message?.user?.id}`);
				},
			},
		isAdmin &&
			!isUserPaused &&
			!messageUserIsAdminOrStaff && {
				icon: <BanUserIcon fill="#fa2f53" />,
				text: t('Pause'),
				onClick: () => {
					pause(`${message?.user?.id}`);
				},
			},

		isMe || isAdmin
			? {
					icon: <DeleteIcon fill="#fa2f53" />,
					text: t('Delete Message'),
					type: 'destructive',
					onClick: () => {
						toggleDeleteMessageDialogVisible();
					},
			  }
			: null,
	]);

	const ConfirmationModals = (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete Message')}
			subTitle={t('Are you sure you want to permanently delete the message?')}
			title={t('Delete')}
			variant="medium"
			visible={isDeleteMessageDialogVisible}
			onCancel={() => toggleDeleteMessageDialogVisible()}
			onConfirm={() => {
				chat.chatClient.deleteMessage(message.id).finally(() => {
					toggleDeleteMessageDialogVisible(false);
				});
			}}
		/>
	);

	return [meatballMenuOptions.length ? meatballMenuOptions : undefined, ConfirmationModals] as const;
};
