import { ROUTES } from '@constants';
import { useMe, useTranslation } from '@hooks';
import {
	AddressBooksIcon,
	ProfileIcon,
	ReferAnAssoicateIcon,
	MemberGuideBookIcon,
	PartnerBenefitsIcon,
	NewspaperIcon,
	SettingsIcon,
	StaffAndSupportIcon,
	CovidPoliciesIcon,
	MenuNavigationButton,
	Anchor,
	PieChartFilledIcon,
	When,
} from '@ui-kit';
import { getDisplayedAppVersion } from '@utils';
import { DrawerActionBox } from '../DrawerActionBox';
import styles from './AccountMenu.module.css';
import { useFlyoutQuery } from '@ui-modules/flyout/hooks/useFlyoutQuery';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';

const AccountMenu = () => {
	const { t } = useTranslation();
	const { fullName, profile, user } = useMe();
	const APP_VERSION = getDisplayedAppVersion();
	const { data: flyoutMessages = [] } = useFlyoutQuery();

	return (
		<div className={styles.menu__container} id={styles['accountMenu']}>
			<div className={styles.menu__links}>
				<MenuNavigationButton
					icon={(props) => <ProfileIcon {...props} />}
					label={t('Profile')}
					linkTo={ROUTES.profile()}
				/>
				<MenuNavigationButton
					icon={(props) => <AddressBooksIcon {...props} />}
					label={t('Address Book')}
					linkTo={ROUTES.addressBook()}
				/>
				<MenuNavigationButton
					icon={(props) => <ReferAnAssoicateIcon {...props} />}
					label={t('Nominate a New Member')}
					linkTo={ROUTES.referAssociate()}
				/>
				<MenuNavigationButton
					icon={(props) => <MemberGuideBookIcon {...props} />}
					label={t('Member Guidebook')}
					linkTo={ROUTES.memberGuideBook()}
				/>
				<MenuNavigationButton
					icon={(props) => <PartnerBenefitsIcon {...props} />}
					label={t('Member Benefits')}
					linkTo={ROUTES.partnerBenefits()}
				/>
				<MenuNavigationButton
					icon={(props) => <NewspaperIcon {...props} />}
					label={t('TIGER Pulse')}
					linkTo={ROUTES.tigerPulse()}
				/>
				<When condition={ProfileEntity.isFeatureEnabled(user, 'asset_allocations')}>
					<MenuNavigationButton
						badgeShown={!!flyoutMessages.length}
						icon={(props) => <PieChartFilledIcon {...props} />}
						label={t('Asset Allocation')}
						linkTo={ROUTES.assetAllocation('default')} // non-empty and non-existing tabName will redirect to the initial tab.
					/>
				</When>
				<MenuNavigationButton
					icon={(props) => <CovidPoliciesIcon {...props} />}
					label={t('Policies')}
					linkTo={ROUTES.policies()}
				/>
				<MenuNavigationButton
					icon={(props) => <StaffAndSupportIcon {...props} />}
					label={t('TIGER 21 Staff and Support')}
					linkTo={ROUTES.staffAndSupport()}
				/>
				<MenuNavigationButton
					icon={(props) => <SettingsIcon {...props} />}
					label={t('Settings')}
					linkTo={ROUTES.settings()}
				/>
				<div className={styles.menu__title}>
					<small>
						{t('Logged in as {{fullName}}.', { fullName })}{' '}
						<Anchor href={ROUTES.signOut()} trackingName="auth--sign-out">
							{t('Sign out')}
						</Anchor>
					</small>
				</div>
				<DrawerActionBox isAdmin={user.roles.includes('ROLE_ADMIN')} profileType={profile['@type']} />
			</div>
			<small className={styles.menu__appVersion}>{APP_VERSION}</small>
		</div>
	);
};

export default AccountMenu;
