import type { TUserProfileRecord, TUser, TProfile } from '@typings';

export const filterChannelMembers = (
	members: IChannelMembers[],
	allContacts: TUserProfileRecord[],
	profile?: TProfile,
	user?: TUser,
) => {
	const contacts = allContacts?.filter((contact) => members.find((member) => member.id === contact.slug));
	return user && profile ? [...contacts, adaptUserRecordToUserProfileRecord(profile, user)] : contacts;
};

/**Ask dima if there is any other good way */
const adaptUserRecordToUserProfileRecord = (profile: TProfile, user: TUser): TUserProfileRecord => {
	const record: TUserProfileRecord = {
		id: user.id,
		roles: user.roles,
		firstName: user.firstName,
		lastName: user.lastName,
		communitiesForBadge: user.communitiesForBadge,
		slug: user.slug,
		avatar: user.avatar,
		profile: {
			id: profile.id,
			'@type': profile['@type'],
			title: profile.title,
		},
		contactId: profile.contactRecord?.id,
	};
	return record;
};

export interface IChannelMembers {
	id?: string;
	role?: string;
	banned?: boolean;
}
