import type { ChangeEvent } from 'react';
import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useCallback, useTranslation } from '@hooks';
import { DropdownMenu, Gap, Input, RadioGroupInput, CheckboxGroupInput, AmountInput, ClosingDateInput } from '@ui-kit';
import { useCurrencyCodes } from './useCurrencyCodes';
import {
	dealFormFields,
	getDynamicFinancialDetailsFieldPath,
	getDynamicFinancialDetailsFieldValuePath,
} from '../DealForm.schema';
import type { TDealTemplateQueryResult } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { useGetDealTemplateQuery } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import { isUndefined, get, extractIdFromIri, convertArrayToOptions, clsx, dayjs, toString } from '@utils';
import styles from './sections.module.css';

export const FinancialDetails = ({ formProps, isEdit, dealDetails = {}, disabled }: IFinancialDetailsProps) => {
	const { t } = useTranslation();
	const currencyOptions = useCurrencyCodes();
	const { setFieldValue, errors, values, setFieldError, touched } = formProps;
	const isAssetClassFieldsTouched =
		get(touched, dealFormFields.SUB_CLASS, false) || get(touched, dealFormFields.TYPE, false);
	const { dealAssetClass = '', dealSubClass = '', dealType = '' } = values;
	const skipOverrideValues = isEdit && !isAssetClassFieldsTouched;

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	const onQueryTemplateSuccess = useCallback(
		(response: TDealTemplateQueryResult) => {
			const { financialDetails } = response || {};
			!isEdit && setFieldValue('financialDetailsTemplate', {}); // erase field values, fixes T21C-4501
			if (financialDetails?.fields) {
				financialDetails?.fields.map((field) => {
					if (!skipOverrideValues) {
						// Initialize dynamic fields values
						const fieldPath = getDynamicFinancialDetailsFieldPath(field.slug);
						setFieldValue(fieldPath, { ...field, value: '' });
					}
					return field.slug;
				});
			}
		},
		[setFieldValue, skipOverrideValues],
	);

	const { data } = useGetDealTemplateQuery(
		onQueryTemplateSuccess,
		extractIdFromIri(dealAssetClass || dealDetails.dealAssetClass || ''),
		extractIdFromIri(dealSubClass || dealDetails.dealSubClass || ''),
		extractIdFromIri(dealType || dealDetails.dealType || ''),
	);

	const handleChangeSingleSelectField = (option: string, value: string, valuePath: string, isRequired: boolean) => {
		if (isRequired) {
			setFieldValue(valuePath, option);
		} else {
			value === option ? setFieldValue(valuePath, '') : setFieldValue(valuePath, option);
		}
	};
	const handleChangeMultiSelectField = (option: string, value: string[], valuePath: string) => {
		const newOptions = value?.includes(option)
			? value?.filter((el: string) => el !== option)
			: [...(value || []), option];
		setFieldValue(valuePath, newOptions.length ? newOptions?.sort() : undefined);
	};

	return (
		<DealFormSection className={styles.sectionDetails} title={t('Financial Details')}>
			<DropdownMenu
				customDescription={
					!errors.currency ? (
						<span className={styles.currencyDescription}>{t('Select currency to enable subsequent fields')}</span>
					) : null
				}
				disabled={disabled}
				errorMessage={errors.currency}
				iconType="clear"
				isSearchable
				label={t('Currency')}
				options={currencyOptions}
				placeholder={t('Find the currency')}
				showSearchIcon
				value={values.currency}
				variant="medium"
				onChange={(value) => handleChangeField(dealFormFields.CURRENCY, value)}
			/>
			{!errors.currency && <Gap gap={20} />}
			<AmountInput
				disabled={!values.currency || disabled}
				errorMessage={errors.minInvestment}
				label={t('Minimum investment')}
				placeholder={t('e.g 50,000')}
				value={toString(values.minInvestment)}
				onChange={(e) => {
					handleChangeField(dealFormFields.MIN_INVESTMENT, e.target.value?.replaceAll(',', ''));
				}}
			/>
			{!errors.minInvestment && <Gap gap={20} />}
			<div className={clsx(!!data?.financialDetails?.fields && styles.dynamicSection__container)}>
				{!!data?.financialDetails?.fields?.length && (
					<>
						{data?.financialDetails?.fields?.map((field) => {
							const fieldPath = getDynamicFinancialDetailsFieldPath(field.slug);
							const valuePath = getDynamicFinancialDetailsFieldValuePath(field.slug);
							const isRequired = isUndefined(field.required) || field.required;
							const value = get(values, valuePath);
							const isDynamicFieldDisabled = disabled || !values.currency;
							const errorMessage: string = get(errors, fieldPath);

							const handleChangeDynamicField = (e: ChangeEvent<HTMLInputElement>) => {
								setFieldValue(valuePath, e.target.value);
								setFieldError(fieldPath, undefined);
							};
							switch (field.type) {
								case 'money':
									return (
										<div key={field.slug}>
											<AmountInput
												disabled={!values.currency}
												errorMessage={errorMessage}
												key={field.slug}
												label={isRequired ? field.label : `${field.label} (optional)`}
												name={field.slug}
												placeholder={field.placeholder ?? t('e.g 50,000')}
												value={value}
												onChange={handleChangeDynamicField}
											/>
										</div>
									);
								case 'multi-select':
									return (
										<CheckboxGroupInput
											containerClassName={styles.multiSelect__input}
											disabled={isDynamicFieldDisabled}
											errorMessage={errorMessage}
											key={field.slug}
											label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
											options={convertArrayToOptions(field.choices)}
											value={value}
											onChange={(option) => {
												handleChangeMultiSelectField(option, value, valuePath);
												setFieldError(fieldPath, undefined);
											}}
										/>
									);
								case 'single-select':
									return (
										<div key={field.slug}>
											<RadioGroupInput
												backgroundColor="white"
												disabled={isDynamicFieldDisabled}
												errorMessage={errorMessage}
												key={field.slug}
												label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
												options={convertArrayToOptions(field.choices)}
												value={value}
												onChange={(selectedValue) => {
													handleChangeSingleSelectField(selectedValue, value, valuePath, isRequired);
													setFieldError(fieldPath, undefined);
												}}
											/>
										</div>
									);
								case 'date':
									return (
										<ClosingDateInput
											disabled={disabled}
											errorMessage={errorMessage}
											errorMessageInitialHidden
											key={field.slug}
											label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
											placeholder={field.placeholder ?? t('e.g. {{example}}', { example: '12 Sep 2025' })}
											value={value ? new Date(value) : null}
											onChange={(value) => {
												setFieldValue(valuePath, dayjs(value).format('YYYY-MM-DD'));
												setFieldError(fieldPath, undefined);
											}}
											onClear={() => {
												setFieldValue(valuePath, null);
												setFieldError(fieldPath, undefined);
											}}
										/>
									);
								default: {
									return (
										<Input
											disabled={isDynamicFieldDisabled}
											errorMessage={errorMessage}
											errorMessageInitialHidden
											key={field.slug}
											label={isRequired ? field.label : t(`{{label}} (optional)`, { label: field.label })}
											placeholder={field.placeholder ?? field.label}
											value={value}
											onChange={handleChangeDynamicField}
										/>
									);
								}
							}
						})}
					</>
				)}
			</div>
		</DealFormSection>
	);
};

interface IFinancialDetailsProps extends IDealFormSectionSectionProps {
	dealDetails?: {
		dealAssetClass?: string;
		dealSubClass?: string;
		dealType?: string;
	};
	disabled?: boolean;
}
