import type { INotificationFeedActivity } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { CloseIcon } from '@ui-kit';
import { MeatballMenu, Avatar } from '@ui-kit';
import type { TCustomMenuContainerProps } from '@ui-kit/components/MeatballMenu/MeatballMenu';
import RelativeTime from '@ui-kit/components/RelativeTime';
import { clsx } from '@utils';
import { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import NotificationAction from '../NotificationAction';

import styles from './NotificationCard.module.css';
import { pickUpNotificationAvatar, isVerbWithOutline, useFormattedMessage } from './NotificationsCard.utils';

const NotificationCard = ({
	onClick,
	isUnread,
	menuOptions,
	notification,
	isAggregating,
	activities = [],
	onClose,
}: INotificationCardProps) => {
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const avatarUriSource = pickUpNotificationAvatar(notification);
	const shouldShowOutlineAvatar =
		(notification?.actor?.data?.roles?.includes?.('ROLE_CHAIR') && isVerbWithOutline(notification.verb)) ?? false;
	const content = useFormattedMessage(
		notification.data?.formattedContent ?? notification.body,
		isAggregating,
		activities,
	);

	const MenuContainer = useMemo(() => {
		const Container = ({ menuOptions, menuRef }: TCustomMenuContainerProps) => {
			return (
				<div className={styles.notificationCard__menuContainer} ref={menuRef}>
					{menuOptions}
				</div>
			);
		};
		return Container;
	}, []);

	return (
		<div className={styles.notificationCard} onClick={onClick} onKeyDown={onClick}>
			{isUnread && <div className={styles.notificationCard__unreadMarker} />}
			<div className={styles.notificationCard__contentWrapper}>
				{menuOptions && !!menuOptions.length && (
					<div
						className={clsx(
							styles.notificationCard__menuButton,
							isMenuVisible && styles.notificationCard__menuButton_visible,
						)}
					>
						<MeatballMenu
							CustomMenuContainer={MenuContainer}
							options={menuOptions}
							position="left"
							type="solid"
							onMenuVisible={(visible) => setIsMenuVisible(visible)}
						/>
					</div>
				)}
				{onClose && (
					<div
						aria-label="close"
						className={styles.notificationCard__closeIcon}
						role="button"
						tabIndex={-1}
						onClick={(e) => {
							e.stopPropagation();
							onClose();
						}}
						onKeyPress={(e) => {
							e.stopPropagation();
							onClose();
						}}
					>
						<CloseIcon height={12} width={12} />
					</div>
				)}
				<Avatar
					imageUri={avatarUriSource}
					outline={shouldShowOutlineAvatar}
					size={64}
					title={notification.actor?.data?.name}
				/>
				<div className={styles.notificationCard__content}>
					<ReactMarkdown>{content}</ReactMarkdown>
					<RelativeTime className={styles.notificationCard__time} dateString={notification.time} />
					<div className={styles.notificationCard__actionBox}>
						<NotificationAction notificationData={notification.data} />
					</div>
				</div>
			</div>
		</div>
	);
};

interface INotificationCardProps {
	onClick: () => void;
	isUnread?: boolean;
	menuOptions?: TMeatballMenuOption[];
	notification: INotificationFeedActivity;
	isAggregating: boolean;
	activities?: INotificationFeedActivity[];
	onClose?: () => void;
}

export default NotificationCard;
