import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import type { TDeal } from '@typings';

export const useManageThirdPartyAssesTokenMutation = (dealId: TDeal['id']) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const { showSuccess, showError } = useNotification();
	const reactQuery = useService('ReactQueryService');

	const revokeAccess = useMutation<void, Error>(
		['deal.revokeAccess'],
		() => api.invest.revokeThirdPartyAssesToken(dealId),
		{
			onSuccess: () => {
				showSuccess({ title: t('Third Party Access Revoked') });
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
				reactQuery.updateDealsCache(dealId, (deal) => {
					deal.hasActiveThirdParties = false;
				});
			},
			onError: () => {
				showError({ title: t('Error Revoke Third Party Access') });
			},
		},
	);

	const restoreAccess = useMutation<void, Error>(
		['deal.restoreAccess'],
		() => api.invest.restoreThirdPartyAssesToken(dealId),
		{
			onSuccess: () => {
				showSuccess({ title: t('Third Party Access Restored') });
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
				reactQuery.updateDealsCache(dealId, (deal) => {
					deal.hasActiveThirdParties = true;
				});
			},
			onError: () => {
				showError({ title: t('Error Restore Third Party Access') });
			},
		},
	);

	return {
		revokeAccess: revokeAccess.mutateAsync,
		restoreAccess: restoreAccess.mutateAsync,
	};
};
