import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TReportDealDto } from '@typings';

export const useReportDealMutation = (options?: MutationOptions<TReportDealDto, Error, TReportDealDto>) => {
	const api = useService('ApiService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<TReportDealDto, Error, TReportDealDto>(
		['invest.postReportDeal'],
		async (report) => await api.invest.postReportDeal(report),
		{
			...options,
			async onSuccess(data, reportDto, context) {
				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(data, reportDto, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
