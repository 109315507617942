import type { TNetworkConnection, TCommunity } from '@typings';
import { BookmarkIcon, Button, BookmarkFilledIcon } from '@ui-kit';
import { useNetworkConnectionBookmarkMutation } from '@ui-modules/connections/hooks/useNetworkConnectionBookmarkMutation';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import styles from './NetworkConnectionBookmarkButton.module.css';

const NetworkConnectionBookmarkButton = ({
	networkConnectionId,
	networkId,
	onToggled,
}: INetworkConnectionBookmarkButtonProps) => {
	const { data: networkConnection } = useNetworkConnectionQuery(networkConnectionId);
	const { mutate: toggle, isLoading: isMutating } = useNetworkConnectionBookmarkMutation(
		networkConnectionId,
		networkId,
		{
			onSuccess: (response, isBookmarked) => onToggled(!isBookmarked),
		},
	);

	return (
		<Button
			className={styles.button}
			icon={
				networkConnection?.isBookmarked ? (
					<BookmarkFilledIcon fill="#fff" height={12} width={9} />
				) : (
					<BookmarkIcon fill="#000000" height={12} width={9} />
				)
			}
			iconPosition="left"
			loading={isMutating}
			type={networkConnection?.isBookmarked ? 'dark' : 'outline'}
			variant="small"
			onClick={() => toggle(networkConnection?.isBookmarked)}
		/>
	);
};

export interface INetworkConnectionBookmarkButtonProps {
	networkConnectionId: TNetworkConnection['id'];
	networkId: TCommunity['id'];
	onToggled: (newToggled: boolean) => void;
}

export default NetworkConnectionBookmarkButton;
