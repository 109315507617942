import { useService, useTranslation } from '@hooks';
import type { TCommunityCompact, TProfile } from '@typings';
import { Card, CommunityCard, Gap, RibbonFlag } from '@ui-kit';
import { useProfileLeadsCommunitiesQuery } from '@ui-modules/communities/hooks/useProfileLeadsCommunitiesQuery';
import { translateMembers, translateGroups } from '@utils';
import styles from './ProfileLeadingGroups.module.css';

const ProfileLeadingGroups = ({
	isInvisible,
	profileId,
	onPressCommunity,
	onPressNomination,
	onPressRequestAttend,
}: IProfileLeadingGroupsProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { data } = useProfileLeadsCommunitiesQuery(profileId, !isInvisible);

	return isInvisible ? null : (
		<>
			{data?.groups?.length ? (
				<>
					<Gap gap={16} />
					<Card cardContentClassName={styles.groups__cardContent} title={t('Groups I am leading')}>
						<div className={styles.groups__container}>
							{data?.groups?.map((group) => (
								<div key={group.id}>
									<CommunityCard
										community={group}
										formatGroupsCount={translateGroups(t)}
										formatMembersCount={translateMembers(t)}
										style={styles.group__card}
										onClick={() => {
											onPressCommunity(group);
											analytics.trackEvent('ProfileInteractions', {
												interaction_type: 'Groups clicked',
												action_value: group.name,
											});
										}}
									/>
									<div className={styles.cardButtons__container}>
										<button className={styles.cardButton} onClick={onPressNomination}>
											{t('Nominate a Member')}
										</button>
										<button className={styles.cardButton} onClick={() => onPressRequestAttend(group)}>
											{t('Request to Attend')}
										</button>
									</div>
								</div>
							))}
						</div>
					</Card>
				</>
			) : null}
			{data?.networks?.length ? (
				<>
					<Gap gap={16} />
					<Card cardContentClassName={styles.groups__cardContent} title={t('Networks I am leading')}>
						<div className={styles.groups__container}>
							{data?.networks?.map((network) => {
								const isInvestNetwork = network && network?.features?.includes('invest');

								return (
									<div className={styles.card} key={network.id}>
										<CommunityCard
											community={network}
											formatGroupsCount={translateGroups(t)}
											formatMembersCount={translateMembers(t)}
											style={styles.network__card}
											onClick={() => {
												onPressCommunity(network);
												analytics.trackEvent('ProfileInteractions', {
													interaction_type: 'Networks clicked',
													action_value: network.name,
												});
											}}
										/>
										<div className={styles.card__ribbonContainer}>
											{isInvestNetwork ? (
												<RibbonFlag className={styles.card__ribbon} color="#62B195" size="small" title={t('Invest')} />
											) : null}
										</div>
									</div>
								);
							})}
						</div>
					</Card>
				</>
			) : null}
		</>
	);
};

export interface IProfileLeadingGroupsProps {
	isInvisible?: boolean;
	profileId: TProfile['id'];
	onPressCommunity: (community: TCommunityCompact) => void;
	onPressNomination: () => void;
	onPressRequestAttend: (group: TCommunityCompact) => void;
}

export default ProfileLeadingGroups;
