import { useNotification, useService, useTranslation } from '@hooks';
import { isEmpty, map, parseUrlRegexp, uniq } from '@utils';
import { AbstractApplicationError } from '@errors';

export const useCheckTextOnMaliciousUrls = (args?: { showMessage?: boolean; throwError?: boolean }) => {
	const { t } = useTranslation();
	const webRisksApi = useService('WebRiskApiService');
	const { showError } = useNotification();

	return async function checkTextOnMaliciousUrls(text: string): Promise<string | undefined> {
		const parsedUrls: string[] | null = text.match(parseUrlRegexp);
		if (!parsedUrls?.length) return undefined;

		const webRisks = await webRisksApi.findThreats(parsedUrls);
		if (isEmpty(webRisks)) return undefined;

		const message = webRisks
			? t('URL may be malicious. To post, remove this link: {{links}}', {
					count: webRisks.length,
					links: uniq(map(webRisks, 'url')).map((url) => `\n  •  ${url}`),
			  })
			: undefined;

		if (message) {
			if (args?.showMessage) {
				showError({
					title: t('Security check failed'),
					subtitle: message,
					shouldShowClose: true,
					linksDisabled: true,
					autoClose: false,
				});
			}
			if (args?.throwError) {
				class MaliciousUrlError extends AbstractApplicationError {
					name = t('Security check failed');
					toastProps = { shouldShowClose: true, linksDisabled: true, autoClose: false };
				}

				throw new MaliciousUrlError(message);
			}
		}

		return message;
	};
};
