import { BASE_STATIC_WEB_PAGE_PASSWORD, BASE_STATIC_WEB_PAGE_URL } from '@constants';
import { useMemo, useState } from '@hooks';
import type { TUser } from '@typings';
import { ActivityIndicator } from '@ui-kit';
import styles from './BaseStaticIFrame.module.css';

const BaseStaticIFrame = ({ url, userId, title }: IBaseStaticIFrameProps) => {
	const [isContentLoading, setIsLoading] = useState(true);

	const iframeLink = useMemo(() => {
		if (!url.includes(BASE_STATIC_WEB_PAGE_URL)) return url;
		const preparedUrl = new URL(url);
		userId && preparedUrl.searchParams.append('user_id', userId);
		if (!preparedUrl.searchParams.get('pwd')) {
			preparedUrl.searchParams.append('pwd', BASE_STATIC_WEB_PAGE_PASSWORD);
		}
		return preparedUrl.toString();
	}, [url]);

	return (
		<>
			{isContentLoading ? <ActivityIndicator type="fit" /> : null}
			<iframe
				className={styles.iframe}
				sandbox="allow-scripts allow-forms allow-downloads allow-popups allow-same-origin"
				src={iframeLink}
				title={title}
				onLoad={() => setIsLoading(false)}
			/>
		</>
	);
};

export interface IBaseStaticIFrameProps {
	url: string;
	title: string;
	userId?: TUser['id'];
}

export default BaseStaticIFrame;
