import styles from './ScreenHeader.module.css';
import type { ReactNode } from 'react';

/** Wrapper to render single page title on the left with a set of button on the right.
 * 	Considers a children as an element to render responsive buttons.
 */
const ScreenHeader = ({ title, children }: IScreenHeaderProps) => {
	return (
		<header className={styles.screenHeader__header}>
			<div className={styles.screenHeader__title}>
				<h2>{title}</h2>
			</div>
			<div className={styles.screenHeader__actionBox}>{children}</div>
		</header>
	);
};

export interface IScreenHeaderProps {
	/** Title text of the screen */
	title: string;
	/** The prop to render responsive buttons are passed as an argument. */
	children: ReactNode | null;
}

export default ScreenHeader;
