import { useChat, useEffect, useMemo, useState, useTranslation } from '@hooks';
import type { TAttachments, TFileType } from '@typings';
import { uploadAttachmentInStream } from '@ui-modules/chat/components/ChatInputBar/ChatInputBar.utils';
import { byteToMb } from '@utils';
import { useChannelStateContext } from 'stream-chat-react';
import { usePickUpAttachments } from '@ui-modules/feed/hooks/usePickUpAttachments';
import { extractFileStats } from '@ui-modules/feed/utils/extractFileStats';
import { FILE, IMAGE, MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE } from '@constants';
import { useNotification } from '../../../common/hooks/useNotification';
import { handleVideoSizeViaUrls } from '../../../common/utils/handleVideoSizeViaUrls';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';

export const useChatAttachmentsState = ({
	onlyMediaAccepted = false,
	onlyFileAccepted = false,
	savedAttachments,
	shouldUploadToStream = false,
	isChat = false,
	cid,
	forbidDuplicates = false,
}: IUseChatAttachmentsStateStateProps) => {
	const { channel } = useChannelStateContext();
	const { showError, showInfo } = useNotification();
	const { t } = useTranslation();
	const { draftMessage, updateMessageDraft } = useDraftAPI(cid as string);

	const [attachments, setAttachments] = useState<TAttachmentsWithHash[]>([]);

	useEffect(() => {
		if (onlyMediaAccepted) {
			setAttachments((draftMessage?.attachments ?? []).filter((item: TAttachments) => !item.isFile));
		}
		if (onlyFileAccepted) {
			setAttachments((draftMessage?.attachments ?? []).filter((item: TAttachments) => item.isFile));
		}
	}, [onlyFileAccepted, onlyMediaAccepted]);

	const filesSize = useMemo(() => calculateFileSize(attachments), [attachments]);
	const [loadingStateAttachments, setLoadingStateAttachments] = useState<TAttachments[]>([]);
	const [chatVideosSize, setChatVideosSize] = useState(0);

	const getType = (file: File): TFileType => {
		return file.type.includes('image') ? 'image' : file.type.includes('video') ? 'video' : 'file';
	};

	const mergeAddedAttachments = (addedAttachments: TAttachments[]) => {
		setAttachments((currentAttachments) => {
			const newAttachments = [...currentAttachments, ...addedAttachments];
			updateMessageDraft({ attachments: newAttachments });
			return newAttachments;
		});
	};

	const updateStreamAttachments = async (transformedFiles: TAttachments[]) => {
		setLoadingStateAttachments((files) => files.concat(transformedFiles));

		if (!shouldUploadToStream) {
			const transformedFilesWithHash = transformedFiles.map((file) => ({
				...file,
				hash: createFallbackHash('no_file_uri_when_editing_fallback'),
			}));
			mergeAddedAttachments(transformedFilesWithHash);
			return;
		}

		let duplicatesFound = false;
		for (const transformedFile of transformedFiles) {
			const transformedFileHash = await getFileHash(String(transformedFile.url));
			if (forbidDuplicates && doesAttachmentsContainsHash(attachments, transformedFileHash)) {
				duplicatesFound = true; // skip uploading
			} else {
				const uploadedAttachment = await uploadAttachmentInStream(transformedFile, channel, showError);
				if (uploadedAttachment) {
					const newAttachment = { ...uploadedAttachment, hash: transformedFileHash };
					mergeAddedAttachments([newAttachment]);
				}
			}

			setLoadingStateAttachments((attachments) => attachments.filter((file) => file.id !== transformedFile.id));
		}

		if (forbidDuplicates && duplicatesFound) {
			showInfo({
				title: t('Duplicate files are excluded'),
				subtitle: t('The file can be attached only once to the message'),
			});
		}
	};

	const videoUrls = attachments.map((item) => {
		if (item.fileType === 'video') {
			return item.url;
		}
	});

	const onAddAttachmentsSuccess = (files: File[]) => {
		let filesImagesSize = 0;
		const transformedFiles = files
			.filter((file) => {
				const isAttachmentPresent = attachments.find((attachment) => attachment.name === file.name);
				if (isAttachmentPresent) return false;
				return file;
			})
			.map((file) => {
				const fileType = getType(file);

				if (fileType !== 'video') {
					filesImagesSize = filesImagesSize + file.size;
				}

				const fileConverted = {
					id: crypto.randomUUID(),
					name: file.name,
					isFile: fileType === 'file',
					size: file.size,
					url: URL.createObjectURL(file),
					mimeType: file.type,
					mediaType: file.type,
					file: file,
					fileType,
				} as TAttachments;
				return fileConverted;
			});
		updateStreamAttachments(transformedFiles);
	};

	const { openFilePicker: uploadAttachments, getInputProps } = usePickUpAttachments(
		(files) => onAddAttachmentsSuccess(files),
		onlyMediaAccepted,
		onlyFileAccepted,
		MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE * 1024 * 1024,
	);
	const { editMessage } = useChat();

	const removeAttachment = async (file: TAttachments) => {
		try {
			if (!editMessage) {
				if (file?.fileType?.includes(IMAGE) && file.url) {
					await channel.deleteImage(file.url);
				}
				if (file?.fileType === FILE && file.url) {
					await channel.deleteFile(file.url);
				}
			}
		} finally {
			const isNotCurrentFilePredicate = (existing: TAttachments) => existing.url !== file.url; // id might be absent here if it is already saved attachment.
			setAttachments((attachments) => {
				const newAttachments = attachments.filter(isNotCurrentFilePredicate);
				updateMessageDraft({
					attachments: newAttachments,
				});
				return newAttachments;
			});
			setLoadingStateAttachments((attachments) => attachments.filter(isNotCurrentFilePredicate));
		}
	};

	const setInitialAttachments = ({ images = [], files = [], videos = [] }: IInitialAttachments) => {
		const convertedImages = images.map((image) => ({ ...extractFileStats(image), fileType: 'image' as TFileType }));
		const convertedFiles = files.map((file) => ({ ...extractFileStats(file), fileType: 'file' as TFileType }));
		const convertedVideos = videos.map((video) => ({ ...extractFileStats(video), fileType: 'video' as TFileType }));
		const initialAttachments = [...convertedFiles, ...convertedImages, ...convertedVideos];
		updateStreamAttachments(initialAttachments);
	};

	useEffect(() => {
		if (savedAttachments === undefined) {
			// Handle the case when savedAttachments is undefined
			// You can set default values or perform any other required action
			return;
		}

		updateStreamAttachments(savedAttachments);
	}, [savedAttachments]);

	useEffect(() => {
		if (chatVideosSize < 0) {
			setChatVideosSize(0);
		}
	}, [filesSize, chatVideosSize]);

	useEffect(() => {
		if (videoUrls.length && isChat) {
			const videosSize = videoUrls.length && handleVideoSizeViaUrls(videoUrls as string[]);
			if (videosSize < 0) setChatVideosSize(0);
			setChatVideosSize(videosSize);
		}
	}, [videoUrls.length, isChat]);

	return {
		attachments,
		filesSize,
		chatVideosSize,
		removeAttachment,
		uploadAttachments,
		setInitialAttachments,
		setAttachments,
		setLoadingStateAttachments,
		getInputProps,
		loadingStateAttachments,
		onAddAttachmentsSuccess,
	};
};

// Helpers

const calculateFileSize = (attachments: TAttachments[]) => {
	const totalSize = attachments.reduce((totalSize, attachment) => {
		if (attachment.fileType === 'file') {
			return (totalSize += attachment.size || 0);
		}
		return totalSize;
	}, 0);

	return byteToMb(Math.max(totalSize, 0));
};

async function getFileHash(fileUrl: string) {
	try {
		const response = await fetch(fileUrl);
		const arrayBuffer = await response.arrayBuffer();
		const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
		const hash = Array.from(new Uint8Array(hashBuffer))
			.map((byte) => byte.toString(16).padStart(2, '0'))
			.join('');

		return hash;
	} catch (error) {
		return createFallbackHash('get_file_hash_fn_error_fallback');
	}
}

function createFallbackHash(descriptionTag: string | undefined) {
	return `${descriptionTag}-${Math.random()}`;
}

function doesAttachmentsContainsHash(attachments: TAttachmentsWithHash[], fileHash: string) {
	return attachments.some((attachment) => attachment.hash === fileHash);
}

//  Typings

type TAttachmentsWithHash = TAttachments & {
	/** Calculated MD5-hash of the file content to prevent uploading duplicate files during creating a message. */
	hash?: string;
};

interface IInitialAttachments {
	images?: string[];
	files?: string[];
	videos?: string[];
}

interface IUseChatAttachmentsStateStateProps {
	onlyMediaAccepted?: boolean;
	onlyFileAccepted?: boolean;
	savedAttachments?: TAttachments[];
	shouldUploadToStream?: boolean;
	isChat?: boolean;
	cid?: string;
	forbidDuplicates?: boolean;
}
