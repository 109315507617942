import { CloseIcon } from '@ui-kit';
import ApiVideo from '@ui-modules/media/components/ApiVideo';
import { useTranslation } from '@hooks';
import { dayjs } from '@utils';
import styles from './ApiVideoSelectorPreview.module.css';
import type { IApiVideoSearchResult } from '@typings';

const ApiVideoSelectorPreview = ({ option, onReset, playerWidth = 327 }: IApiVideoSelectorPreviewProps) => {
	const { t } = useTranslation();

	const title = option.value;
	const description = t('uploaded {{createdDate}}', {
		createdDate: dayjs(option.created).format('D MMM YYYY'),
	});

	return (
		<div className={styles.apiVideoSelectorPreview}>
			<ApiVideo playerWidth={playerWidth} videoUrl={option.uri} />
			<div className={styles.apiVideoSelectorPreview__body}>
				<span className={styles.apiVideoSelectorPreview__title}>{title}</span>
				<span className={styles.apiVideoSelectorPreview__description}>{description}</span>
			</div>
			{onReset ? (
				<button className={styles.apiVideoSelectorPreview__resetButton} onClick={onReset}>
					<CloseIcon className={styles.apiVideoSelectorPreview__resetButtonIcon} height={10} width={10} />
				</button>
			) : null}
		</div>
	);
};

export interface IApiVideoSelectorPreviewProps {
	option: IApiVideoSearchResult;
	onReset?: () => void;
	playerWidth?: number;
}

export default ApiVideoSelectorPreview;
