import { useMutation, useService } from '@hooks';
import type { TSupportedChannel } from '@typings';
import useDealFiltersSettingsMutation from './useDealFiltersSettingsMutation';

type TDealSettingsItem = {
	name: string;
	options: TSupportedChannel[];
	id: string;
	subtitle: string;
	status: string | null;
};

export const useResetDealFiltersNotificationsMutation = () => {
	const { patchDealFiltersSettings } = useDealFiltersSettingsMutation(false);
	const { queryClient, queryKeys } = useService('ReactQueryService');

	return useMutation<Array<void | null>, Error, TDealSettingsItem[]>(
		['deal.resetDealFiltersNotifications'],
		(settingsArray) => {
			return Promise.all(
				settingsArray.map(async (setting) => {
					if (setting.options.length) {
						return await patchDealFiltersSettings({
							id: setting.id,
							data: { notificationChannels: [], alerts: false },
						});
					}
					return null;
				}),
			);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKeys.getDealSettings());
			},
		},
	);
};
