import { useRouteSearchQueryState, useState, useToggle } from '@hooks';
import type { TCommunity } from '@typings';
import { useNetworkConnectionsSearchQuery } from '@ui-modules/connections/hooks/useNetworkConnectionsSearchQuery';

export const useNetworkConnectionsSearch = (communityId: TCommunity['id'] | null) => {
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();
	const [searchQuery, setSearchQuery] = useState(routeSearchQuery, 'useNetworkConnectionsSearch');
	const [isSearchFocused, setIsSearchFocused] = useToggle(false);

	const {
		data: searchResult,
		isFetching: isSearchFetching,
		isFetched: isSearchFetched,
		fetchMore: searchMore,
	} = useNetworkConnectionsSearchQuery({
		searchQuery,
		communityId,
		onSearch: setRouteSearchQuery,
	});

	const showSearchBar = isSearchFocused || !!searchQuery.length;
	const showSearchResults = !!searchQuery.length;

	return {
		searchResult: searchResult ?? [],
		isSearchFetching,
		isSearchFetched,
		searchQuery,
		showSearchResults,
		showSearchBar,
		setSearchQuery,
		searchMore,
		setIsSearchFocused,
		isSearchFocused,
	} as const;
};
