import { useTranslation } from '@hooks';
import { setNewPasswordFormSchema } from '@schemas';
import { Button, Formik, PasswordInput, Form, Gap } from '@ui-kit';
import type { TSetNewPasswordForm } from '@schemas';

const SetNewPasswordForm = ({ onSubmit }: ISetNewPasswordFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TSetNewPasswordForm>
			initialValues={{ password: '', confirmPassword: '' }}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={setNewPasswordFormSchema}
			onSubmit={async (values) => await onSubmit(values)}
		>
			{({ values, errors, isSubmitting, handleChange, handleBlur, submitForm }) => {
				return (
					<Form>
						<PasswordInput
							ariaLabel="NewPassword"
							disabled={isSubmitting}
							errorMessage={errors.password}
							hidePasswordLabel={t('Hide password')}
							label={<b>{t('New Password')}</b>}
							placeholder={t('Password')}
							showPasswordLabel={t('Show password')}
							value={values.password}
							onBlur={handleBlur('password')}
							onChange={handleChange('password')}
						/>
						<PasswordInput
							ariaLabel="ConfirmNewPassword"
							disabled={isSubmitting}
							errorMessage={errors.confirmPassword}
							hidePasswordLabel={t('Hide password')}
							label={<b>{t('Confirm New Password')}</b>}
							placeholder={t('Password')}
							showPasswordLabel={t('Show password')}
							value={values.confirmPassword}
							onBlur={handleBlur('confirmPassword')}
							onChange={handleChange('confirmPassword')}
						/>
						<Gap gap={16} />
						<Button
							block="fit"
							loading={isSubmitting}
							title={t('Save')}
							trackingName="auth--set-new-password"
							type="outline"
							onClick={submitForm}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export interface ISetNewPasswordFormProps {
	onSubmit: (values: TSetNewPasswordForm) => void;
}

export default SetNewPasswordForm;
