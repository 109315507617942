import ActivityIndicator from '../ActivityIndicator';
import styles from './SeeMoreButton.module.css';

const SeeMoreButton = ({ hasNext, isFetching, title, onClick }: ISeeMoreButtonProps) => {
	if (!hasNext) return null;
	return (
		<button aria-label={title} className={styles.seeMoreButton} disabled={isFetching} type="button" onClick={onClick}>
			{title}
			<ActivityIndicator invisible={!isFetching} size="extraSmall" testID="ActivityIndicator" type="inline" />
		</button>
	);
};

export interface ISeeMoreButtonProps {
	hasNext: boolean;
	isFetching: boolean;
	title: string;
	onClick: () => void;
}

export default SeeMoreButton;
