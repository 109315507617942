import { useTranslation } from '@hooks';
import type { TDeal } from '@typings';
import { ContactBlock } from '@ui-kit';

interface IDealContactBlock {
	deal: TDeal;
	dealPreview?: TDeal;
	onEmailOpened?: () => void;
	onPhoneOpened?: () => void;
}

const getIsPreviewField = (deal: TDeal, key: string, dealPreview?: TDeal) =>
	dealPreview && dealPreview?.[key as keyof TDeal] !== deal[key as keyof TDeal];

const DealContactBlock = ({ deal, dealPreview, onEmailOpened, onPhoneOpened }: IDealContactBlock) => {
	const { t } = useTranslation();
	const displayDeal = dealPreview ?? deal;

	return (
		<ContactBlock
			contactEmail={displayDeal?.contactEmail}
			contactEmailHighlighted={getIsPreviewField(deal, 'contactEmail', dealPreview)}
			contactName={displayDeal.contactName}
			contactNameHighlighted={getIsPreviewField(deal, 'contactName', dealPreview)}
			contactPhone={displayDeal?.contactPhone}
			contactPhoneHighlighted={getIsPreviewField(deal, 'contactPhone', dealPreview)}
			title={t('Contact')}
			onEmailOpened={onEmailOpened}
			onPhoneOpened={onPhoneOpened}
		/>
	);
};

export default DealContactBlock;
