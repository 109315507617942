import styles from './EditProfileSwitchForm.module.css';
import { Form, SwitchButton } from '@ui-kit';

const EditProfileSwitchForm = ({
	value,
	onChange,
	activeTitle,
	inactiveTitle,
	activeDescription,
	inactiveDescription,
}: IEditProfileSwitchFormProps) => {
	return (
		<div className={styles.activity}>
			<div className={styles.activity__container}>
				<Form>
					<div className={styles.activitySwitch}>
						<SwitchButton value={value} onChange={onChange} />
						<span className={styles.activitySwitch__label}>{value ? activeTitle : inactiveTitle}</span>
					</div>
					<span className={styles.activitySwitch__description}>{value ? activeDescription : inactiveDescription}</span>
				</Form>
			</div>
		</div>
	);
};

export interface IEditProfileSwitchFormProps {
	value: boolean;
	activeTitle: string;
	activeDescription: string;
	inactiveTitle: string;
	inactiveDescription: string;
	onChange: (value: boolean) => void;
}

export default EditProfileSwitchForm;
