import { convertHighlightingTextToHtml } from '../Post.utils';
import styles from './PostTitle.module.css';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';

const PostTitle = ({ highlightingText, children, ...props }: IPostTitleProps) => {
	return (
		<h4
			className={styles.postTitle}
			{...props}
			dangerouslySetInnerHTML={{
				__html: convertHighlightingTextToHtml(highlightingText, children),
			}}
		/>
	);
};

interface IPostTitleProps extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
	children: string;
	/** Substring to highlight with the boldest style as a search result. */
	highlightingText?: string;
}

export default PostTitle;
