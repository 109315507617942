import { useMe, useMemo, useEvent } from '@hooks';
import { camelCase } from '@utils';

/** Saves opened network id to open it by default whe navigation on some of the screens */
export const useUserSessionStorageValue = (id: TUserSessionStorageValueId) => {
	const { user } = useMe();
	const individualId = `${id}-${user.slug}`;

	const storageSavedValue = useMemo(() => {
		const selectedValue = getSelectedValue(individualId);
		return selectedValue;
	}, [individualId]);

	const setStorageSavedValue = useEvent((value: string) => saveSelectedValue(individualId, value));

	return { storageSavedValue, setStorageSavedValue };
};

const prepareStorageKey = (id: string): string => `userSessionStorageValue__${camelCase(id)}`;

const getSelectedValue = (id: string): string => {
	const persistKey = prepareStorageKey(id);
	const persistedValue = sessionStorage.getItem(persistKey) || '';

	return persistedValue;
};

const saveSelectedValue = (id: string, value: string) => {
	const persistingValue = String(value);
	const persistKey = prepareStorageKey(id);

	sessionStorage.setItem(persistKey, persistingValue);
};

export const resetUserSessionStorageValue = (idItem: string) => {
	saveSelectedValue(idItem, '');
};

type TUserSessionStorageValueId =
	| 'assetAllocationNetworks'
	| 'active-chat-message-id'
	| 'asset-allocation-tiger21-period'
	| string;
