import { createSlice } from '@reduxjs/toolkit';
import { sortBy, uniqBy } from 'lodash';
import { dayjs } from '@utils';
import type { TCalendarDaysStatuses, TEvent } from '@typings';
import type { PayloadAction } from '@reduxjs/toolkit';
import { buildCalendarDaysStatuses } from '@ui-modules/events/utils/buildCalendarDaysStatuses';

type TRange = [string, string];
type TCalendarItemsState = {
	calendarItems: TEvent[];
	dayStatuses: TCalendarDaysStatuses;
	calendarItemsRange: [string, string];
};

const initialState: TCalendarItemsState = {
	calendarItems: [],
	dayStatuses: {},
	calendarItemsRange: [new Date().toISOString(), new Date().toISOString()],
};

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		addCalendarItems: (
			state,
			{
				payload: { calendarItems, calendarItemsRange },
			}: PayloadAction<{ calendarItems: TEvent[]; calendarItemsRange: TRange }>,
		) => {
			const newItems = [...calendarItems, ...state.calendarItems];
			const uniqueItems = uniqBy(newItems, 'id');
			const sortedItems = sortBy(uniqueItems, ['startDate']);
			state.calendarItems = sortedItems;

			state.dayStatuses = buildCalendarDaysStatuses(state.calendarItems);

			const fromDate = dayjs.min(dayjs(state.calendarItemsRange[0]), dayjs(calendarItemsRange[0])) as dayjs.Dayjs;
			const toDate = dayjs.max(dayjs(state.calendarItemsRange[1]), dayjs(calendarItemsRange[1])) as dayjs.Dayjs;
			state.calendarItemsRange = [fromDate.toISOString(), toDate.toISOString()];
		},
		updateCalendarItem: (
			state,
			{
				payload: { calendarItemId, callback },
			}: PayloadAction<{ calendarItemId: TEvent['id']; callback: (calendarItem: TEvent) => TEvent }>,
		) => {
			const foundCalendarItemIndex = state.calendarItems.findIndex(
				(calendarItem) => calendarItem.id === calendarItemId,
			);
			if (foundCalendarItemIndex >= 0) {
				const newCalendarItems = [...state.calendarItems];
				newCalendarItems[foundCalendarItemIndex] = callback(newCalendarItems[foundCalendarItemIndex]);
				state.calendarItems = newCalendarItems;
				state.dayStatuses = buildCalendarDaysStatuses(newCalendarItems);
			}
		},
	},
});
