import { useCreateDealPreviewFilesMutation } from '@ui-modules/deals/hooks/useCreateDealPreviewFilesMutation';
import { useUpdateDealPreviewFilesMutation } from '@ui-modules/deals/hooks/useUpdateDealPreviewFilesMutation';
import { useDealDocumentsQuery } from '@ui-modules/deals/hooks/useDealDocumentsQuery';
import { useGetDealPreviewFilesQuery } from '@ui-modules/deals/hooks/useGetDealPreviewFilesQuery';
import { useUpdateOwnerDealPreviewFilesMutation } from '@ui-modules/deals/hooks/useUpdateOwnerDealPreviewFilesMutation';
import { useDeleteDealPreviewFilesMutation } from '@ui-modules/deals/hooks/useDeleteDealPreviewFilesMutation';
import { useNotification, useService, useTranslation } from '@hooks';
import type { TDeal, TMediaObject } from '@typings';

export const useManageDealPreviewFiles = (dealId?: string, isPreviewRequests?: boolean) => {
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess } = useNotification();
	const { t } = useTranslation();

	const { mutateAsync: createDealPreviewFiles } = useCreateDealPreviewFilesMutation();
	const { mutateAsync: updateOwnerDealPreviewFile } = useUpdateOwnerDealPreviewFilesMutation();
	const { mutateAsync: updateDealPrevieFile } = useUpdateDealPreviewFilesMutation();
	const { mutateAsync: deletePreviewFile } = useDeleteDealPreviewFilesMutation();

	const { data: dealFiles = [] } = useDealDocumentsQuery(dealId);
	const { data: dealPreviewFiles = [] } = useGetDealPreviewFilesQuery(dealId, isPreviewRequests);

	const getDeletedFilesById = (deletedFiles: string[]) => {
		return deletedFiles.map((fileId) => {
			const isOwnerFile = dealFiles?.find((file) => file.id === fileId);
			const isThirdPartyFile = dealPreviewFiles?.find((file) => file.id === fileId);

			return { ...(isOwnerFile || {}), ...(isThirdPartyFile || {}), isThirdPartyFile: !!isThirdPartyFile?.id };
		});
	};

	const getActionsFilesById = (fileIds: TMediaObject['id'][]) => {
		return fileIds.map((mediaObjectId) => {
			const isOwnerFile = dealFiles?.find((file) => file.mediaObject.id === mediaObjectId);
			const previewFiles = dealPreviewFiles.map((file) => {
				if (file.mediaObject.id) return file;
				const ownedItem = dealFiles.find((el) => el.id === file.dealFileId);
				return { ...file, mediaObject: { ...ownedItem?.mediaObject } };
			});
			const isThirdPartyFile = previewFiles?.find((file) => file.mediaObject.id === mediaObjectId);
			return { ...(isOwnerFile || {}), ...(isThirdPartyFile || {}), isThirdPartyFile: !!isThirdPartyFile };
		});
	};

	const onPreviewDealSaved = async (
		deal: TDeal,
		files: TMediaObject[] = [],
		deletedFiles: string[],
		makeFileReadOnlyActions: TMediaObject['id'][],
		makeFileDownloadableActions: TMediaObject['id'][],
		onSuccess: () => void,
	) => {
		// handle deleted files
		const deltedFiles = getDeletedFilesById(deletedFiles);
		for (const file of deltedFiles) {
			if (file.isThirdPartyFile) {
				await deletePreviewFile({ fileId: file.id as string });
			} else {
				await updateOwnerDealPreviewFile({
					name: file.name || '',
					dealId: deal.id,
					dealFile: file.id as string,
					openInViewerOnly: !!file.mediaObject?.openInViewerOnly,
					status: 'deleted',
				});
			}
		}
		// handle files that will be read only
		const readOnlyFiles = getActionsFilesById(makeFileReadOnlyActions);
		for (const file of readOnlyFiles) {
			if (file.isThirdPartyFile) {
				await updateDealPrevieFile({
					fileId: file.id as string,
					dealId: deal.id,
					openInViewerOnly: true,
					name: file.name || '',
				});
			} else {
				await updateOwnerDealPreviewFile({
					name: file.name || '',
					dealId: deal.id,
					dealFile: file.id as string,
					openInViewerOnly: true,
					status: 'updated',
				});
			}
		}
		// handle files that will be downloadable;
		const downloadableFiles = getActionsFilesById(makeFileDownloadableActions);
		for (const file of downloadableFiles) {
			if (file.isThirdPartyFile) {
				await updateDealPrevieFile({
					fileId: file.id as string,
					dealId: deal.id,
					openInViewerOnly: false,
					name: file.name || '',
				});
			} else {
				await updateOwnerDealPreviewFile({
					name: file.name || '',
					dealId: deal.id,
					dealFile: file.id as string,
					openInViewerOnly: false,
					status: 'updated',
				});
			}
		}
		reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
		// handle new created files
		await createDealPreviewFiles(
			{ files, relatedEntity: deal.id },
			{
				onSuccess: async () => {
					showSuccess({ title: t('Updates Submitted Successfully') });
					await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealDocuments(deal.id));
					onSuccess();
				},
				onError: async (error) => {
					reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(deal.id));
					showUnknownError(error);
				},
			},
		);
	};

	return { onPreviewDealSaved };
};
