import { When } from '@ui-kit';
import {
	APPLE_STORE_BUTTON_IMAGE_PATH,
	GOOGLE_PLAY_BUTTON_IMAGE_PATH,
	T21_CONNECT_LOGO_IMAGE_PATH,
	IOS_APP_APPLE_STORE_LINK,
	ANDROID_APP_GOOGLE_PLAY_LINK,
} from '@constants';
import styles from './DownloadMobileAppOverlay.module.css';
import type { ReactNode } from 'react';

/** Window covering the app which proposes to download the mobile application.
 *  The reason why can be passed with title & subtitle properties.
 */
const DownloadMobileAppOverlay = ({ title, subtitle, children }: IDownloadMobileAppOverlayProps) => {
	return (
		<div className={styles.downloadMobileAppOverlay}>
			<div>
				<img alt="T21 Connect logo" src={T21_CONNECT_LOGO_IMAGE_PATH} />
			</div>
			{children}
			<div className={styles.downloadMobileAppOverlay__titleBox}>
				<h3>{title}</h3>
			</div>
			<When condition={!!subtitle}>
				<>
					<div className={styles.downloadMobileAppOverlay__subtitleBox}>
						<h4>{subtitle}</h4>
					</div>
				</>
			</When>
			<div className={styles.downloadMobileAppOverlay__buttonsBox}>
				<a href={IOS_APP_APPLE_STORE_LINK} target="blank">
					<img alt="Google Play app link" src={APPLE_STORE_BUTTON_IMAGE_PATH} />
				</a>
				<a href={ANDROID_APP_GOOGLE_PLAY_LINK} target="blank">
					<img alt="App Store app link" src={GOOGLE_PLAY_BUTTON_IMAGE_PATH} />
				</a>
			</div>
		</div>
	);
};

interface IDownloadMobileAppOverlayProps {
	/** Primary text content. */
	title: string;
	/** Optional secondary text content. */
	subtitle?: string;
	/** React element showing between logo and titles. */
	children?: ReactNode;
}

export default DownloadMobileAppOverlay;
