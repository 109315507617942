import { ServicesProvider } from '@services';
import { NOTIFICATION_TIME } from '@constants';
import { OktaProvider } from './components/OktaProvider';
import { ReduxProvider } from './components/ReduxProvider';
import { UikitContextProvider } from './components/UikitContextProvider';
import { PdfViewerProvider } from '@ui-modules/files/components/PdfViewer';
import { QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { Slide, ToastContainer } from 'react-toastify';
import type { ReactNode } from 'react';
import type { ServicesContainer } from '../services/setup/ServicesContainer';

function AppContextsProvider({ servicesContainer, children }: IAppContextsProviderProps) {
	const queryClient = servicesContainer.resolve('ReactQueryService').queryClient;
	const i18n = servicesContainer.resolve('I18nService').i18n;

	return (
		<ServicesProvider value={servicesContainer}>
			<QueryClientProvider client={queryClient}>
				<I18nextProvider i18n={i18n}>
					<ReduxProvider>
						<OktaProvider>
							<UikitContextProvider>
								<PdfViewerProvider>
									<>
										{children}
										<ToastContainer
											autoClose={NOTIFICATION_TIME}
											closeButton={false}
											hideProgressBar
											limit={3}
											newestOnTop
											position="top-center"
											transition={Slide}
										/>
									</>
								</PdfViewerProvider>
							</UikitContextProvider>
						</OktaProvider>
					</ReduxProvider>
				</I18nextProvider>
			</QueryClientProvider>
		</ServicesProvider>
	);
}

interface IAppContextsProviderProps {
	servicesContainer: ServicesContainer;
	children: ReactNode;
}

export default AppContextsProvider;
