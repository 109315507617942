import { useService } from '@services';

export const useNetworkConnectionFilterTitle = () => {
	const { useAppSelector, useBindActionCreators, networkConnectionFilter } = useService('ReduxService');

	const state = useAppSelector((store) => store.networkConnectionFilter);
	const boundActions = useBindActionCreators(networkConnectionFilter);

	return { ...state, ...boundActions };
};
