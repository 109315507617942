import { QuestionIcon } from '@ui-kit/icons';
import Portal from '../Portal';
import { useRef, useToggle } from '@hooks';
import styles from './Hint.module.css';
import type { PropsWithChildren, RefObject } from 'react';

/** Shows popup when question icon is hovered. */
const Hint = ({ children, position = 'bottom-left', iconColor }: IHintProps) => {
	const [isHintShowed, toggleHintShowed] = useToggle(false);

	const ref = useRef() as RefObject<HTMLDivElement>;
	const getHintPopupStyle = () => {
		const rect = ref.current?.getBoundingClientRect();
		return rect ? { top: rect.y, left: rect.x } : {};
	};

	return (
		<>
			<div className={styles.hint}>
				<div
					className={styles.hint__trigger}
					ref={ref}
					onMouseOutCapture={() => toggleHintShowed(false)}
					onMouseOverCapture={() => toggleHintShowed(true)}
				>
					<QuestionIcon className={styles.hint__trigger} height={16} style={{ fill: iconColor }} width={16} />
				</div>

				<Portal>
					{isHintShowed ? (
						<div className={styles.hint__content} data-position={position} style={getHintPopupStyle()}>
							{children}
						</div>
					) : null}
				</Portal>
			</div>
		</>
	);
};

export interface IHintProps extends PropsWithChildren {
	/** Position to render popup. */
	position?: 'bottom-left';
	iconColor?: string;
}

export default Hint;
