import type { IdxAuthenticator, IdxTransaction } from '@okta/okta-auth-js';
import { AuthenticatorKey } from '@okta/okta-auth-js';
import { RESEND_AUTH_FACTOR_TIMEOUT_SECONDS } from '@tiger21-llc/connect-shared/src/constants';
import { dayjs, intersection } from '@utils';
import { AbstractExternalStore } from '../../../../services/abstracts/AbstractExternalStorage';

export class OktaVerifyState extends AbstractExternalStore<IInitialState> {
	constructor() {
		super(OktaVerifyState.initialState);
	}

	static initialState: IInitialState = {
		availableFactorTypes: undefined,
		currentFactorType: undefined,
		currentAuthenticator: undefined,
		authenticators: {} as IInitialState['authenticators'],
	};

	actions = {
		setIdxTransaction: ({ idxTransaction, factorType }: TSetIdxTransactionAction) => {
			this.setState((newState) => {
				// Save idxTransaction
				const newFactorType = factorType || OktaVerifyState.helpers.getCurrentTransactionFactorType(idxTransaction);
				if (!newFactorType) throw new Error('No factor type found');
				newState.currentFactorType = newFactorType;
				if (!newState.authenticators[newFactorType]) {
					newState.authenticators[newFactorType] = {
						idxTransaction,
						expirationDate: OktaVerifyState.helpers.createExpirationDate(),
						startedWhenExpired: false,
					};
				} else {
					newState.authenticators[newFactorType].idxTransaction = idxTransaction;

					// Update expirationDate
					const currentExpirationTime = newState.authenticators[newFactorType].expirationDate;
					const isExpired = dayjs().isAfter(dayjs(currentExpirationTime));
					if (isExpired) {
						newState.authenticators[newFactorType].expirationDate = OktaVerifyState.helpers.createExpirationDate();
						newState.authenticators[newFactorType].startedWhenExpired = false;
					} else {
						newState.authenticators[newFactorType].startedWhenExpired = true;
					}
				}

				// Actualize idxTransaction data
				newState.currentAuthenticator = OktaVerifyState.helpers.getTransactionAuthenticator(
					idxTransaction,
					newFactorType,
				);
				newState.availableFactorTypes = OktaVerifyState.helpers.getPrioritizedFactorTypes(idxTransaction);
			});
		},
	};

	selectors = {
		selectFactorTypeExpirationDate: (factorType: AuthenticatorKey) => {
			const state = this.getState();
			return state.authenticators?.[factorType]?.expirationDate;
		},
		selectCurrentAuthenticatorStartedWhenExpired: () => {
			const state = this.getState();
			const currentFactorType = state.currentFactorType;
			if (!currentFactorType) return false;
			return state.authenticators?.[currentFactorType]?.startedWhenExpired;
		},
	};

	static config = {
		handledFactorTypesPriority: [AuthenticatorKey.PHONE_NUMBER, AuthenticatorKey.OKTA_EMAIL],
	};

	static helpers = {
		createExpirationDate: (): Date => {
			const newExpirationDate = dayjs().add(RESEND_AUTH_FACTOR_TIMEOUT_SECONDS, 'seconds').toDate();
			return newExpirationDate;
		},

		sortFactorTypesByPriority: (factorTypes: AuthenticatorKey[]): AuthenticatorKey[] => {
			const sorted = factorTypes.sort((a, b) => {
				const firstIndex = this.config.handledFactorTypesPriority.indexOf(a);
				const secondIndex = this.config.handledFactorTypesPriority.indexOf(b);
				return (firstIndex === -1 ? 5 : firstIndex) - (secondIndex === -1 ? 5 : secondIndex);
			});
			return sorted;
		},
		getPrioritizedFactorTypes: (idxTransaction: IdxTransaction | undefined): AuthenticatorKey[] => {
			if (!idxTransaction) return [];
			// authenticators value; key;
			const factorTypes = idxTransaction.availableSteps
				?.find((step) => step.name === 'select-authenticator-authenticate')
				?.inputs?.[0].options?.map((option) => option.value) as AuthenticatorKey[];
			const handledFactorTypes: AuthenticatorKey[] = intersection(factorTypes, this.config.handledFactorTypesPriority);

			const prioritizedFactorTypes = OktaVerifyState.helpers.sortFactorTypesByPriority(handledFactorTypes);

			return prioritizedFactorTypes;
		},
		getTransactionAuthenticator: (
			idxTransaction: IdxTransaction,
			factorType: AuthenticatorKey,
		): IdxAuthenticator | undefined => {
			const idxAuthenticator = idxTransaction.availableSteps
				?.find((step) => step.name === 'challenge-authenticator')
				?.authenticatorEnrollments?.find((authenticator) => authenticator.key === factorType);

			return idxAuthenticator;
		},
		getCurrentTransactionAuthenticator: (idxTransaction: IdxTransaction | undefined): IdxAuthenticator | undefined => {
			if (!idxTransaction) return undefined;
			const authenticator = idxTransaction.nextStep?.authenticator;
			return authenticator;
		},
		getCurrentTransactionFactorType: (idxTransaction: IdxTransaction | undefined): AuthenticatorKey | undefined => {
			if (!idxTransaction) return undefined;
			const factorType = OktaVerifyState.helpers.getCurrentTransactionAuthenticator(idxTransaction)
				?.key as AuthenticatorKey;
			return factorType;
		},
	};
}

export type IInitialState = {
	currentFactorType?: AuthenticatorKey;
	authenticators: Record<
		AuthenticatorKey,
		{
			expirationDate: Date;
			idxTransaction: IdxTransaction;
			startedWhenExpired: boolean; // if select the authenticator again within 30 seconds the code will not be sent automatically. This var helps to understand this.
		}
	>;
	currentAuthenticator: IdxAuthenticator | undefined;
	availableFactorTypes?: AuthenticatorKey[];
};

export type TSetIdxTransactionAction = { idxTransaction: IdxTransaction; factorType?: AuthenticatorKey };
