import { useTranslation } from '@hooks';
import { Card } from '@ui-kit';
import ReactMarkdown from 'react-markdown';

const EventAgenda = ({ agenda }: IEventAgendaProps) => {
	const { t } = useTranslation();
	return (
		<Card backgroundPlacement="none" title={t('Agenda')}>
			<ReactMarkdown>{agenda}</ReactMarkdown>
		</Card>
	);
};

export interface IEventAgendaProps {
	agenda: string;
}

export default EventAgenda;
