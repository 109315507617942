import { useService, useMutation, useNotification } from '@hooks';
import type { TCommunity, TFolder } from '@typings';
import type { TEditFolderForm } from '@schemas';
import type { MutationOptions } from '@tanstack/react-query';

export const useRenameFolderMutation = (
	communityId: TCommunity['id'],
	folderId: TFolder['id'],
	options?: MutationOptions<TFolder, Error, TEditFolderForm>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useMutation<TFolder, Error, TEditFolderForm>(
		['communityFolder.renameCommunityFolder', folderId],
		async ({ name }) => await api.communityFolder.renameCommunityFolder({ folderId, name }),
		{
			async onSuccess(folder, variables, context) {
				await reactQuery.queryClient.setQueriesData(
					reactQuery.queryKeys.getCommunityFolder(communityId, folderId),
					folder,
				);
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getCommunityFolders(communityId));
				options?.onSuccess?.(folder, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
