import { FixedWidthContainer } from '@ui-kit';
import styles from './GroupTabActionBar.module.css';
import type { PropsWithChildren } from 'react';

/** Bar hanging on absolute on the top of the tab content to store buttons. */
const GroupTabActionBar = ({ children }: IGroupTabActionBarProps) => {
	return (
		<div className={styles.groupTabActionBar__box}>
			<FixedWidthContainer>
				<div className={styles.groupTabActionBar__actionsBox}>{children}</div>
			</FixedWidthContainer>
		</div>
	);
};

interface IGroupTabActionBarProps extends PropsWithChildren {}

export default GroupTabActionBar;
