import { AppLogoImage, AppNameImage } from '@ui-kit';
import { Outlet } from '../../components';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import styles from './AuthShell.module.css';

const AuthShell = () => {
	return (
		<ErrorBoundary>
			<div className={styles.authShell}>
				<div className={styles.authShell__layout}>
					<div className={styles.authShell__logo}>
						<AppLogoImage className={styles.authShell__logoImage} height={38} width={36} />
						<AppNameImage className={styles.authShell__logoTitle} height={36} width={151} />
					</div>
					<Outlet />
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default AuthShell;
