import { ROUTES } from '@constants';
import type { TEvent, TCommunity, TActivity, TProfile, TDeal, TNetworkConnection } from '@typings';
import type { TNotificationData, TNotificationTypeActionType } from '@typings';

const typeActionToRouteMap: Record<TNotificationTypeActionType, (destination: string, slug?: string) => string> = {
	url: (url: string) => url,
	login: (url: string) => url,
	event: (eventId: TEvent['id']) => ROUTES.viewEvent(eventId),
	meeting: (meetingId: TEvent['id']) => ROUTES.viewMeeting(meetingId),
	group: (groupId: TCommunity['id']) => ROUTES.group(groupId),
	chapter: (chapterId: TCommunity['id']) => ROUTES.chapter(chapterId),
	network: (networkId: TCommunity['id']) => ROUTES.network(networkId),
	post: (postId: TActivity['id'], streamFeedId?: string) => ROUTES.post(postId, streamFeedId),
	profile: (profileId: TProfile['id']) => ROUTES.memberProfile(profileId),
	message: (channelId: string) => ROUTES.chatChannel(channelId),
	meeting_request: () => ROUTES.meetingsRequests(),
	notification_settings: () => ROUTES.notificationSettings(),
	addcontact: () => ROUTES.addressBook(), // the feature is implemented only for mobile. Target route is just fallback.
	deal_details: (dealId: TDeal['id']) => ROUTES.dealTabs(dealId),
	deal: (dealId: TDeal['id']) => ROUTES.dealTabs(dealId),
	deal_discussions: (dealId: TDeal['id']) => ROUTES.dealTabs(dealId, 'discussions'),
	network_connection: (networkConnectionId: TNetworkConnection['id']) =>
		ROUTES.networkConnection(networkConnectionId, 'details'),
	network_connection_details: (networkConnectionId: TNetworkConnection['id']) =>
		ROUTES.networkConnection(networkConnectionId, 'details'),
	network_connection_discussions: (networkConnectionId: TNetworkConnection['id']) =>
		ROUTES.networkConnection(networkConnectionId, 'discussions'),
	asset_allocation_aggregation_network: (communityId: TCommunity['id']) => ROUTES.assetAllocationNetwork(communityId),
	asset_allocation_aggregation_group: (communityId: TCommunity['id']) => ROUTES.assetAllocation(communityId),
	asset_allocation_aggregation_chapter: (communityId: TCommunity['id']) => ROUTES.assetAllocation(communityId),
};

export const buildNotificationUrl = (
	notificationData: TNotificationData,
): { url: string; external?: boolean; type: TNotificationTypeActionType; destination: string } | undefined => {
	if (!notificationData || !notificationData.tapAction) return;
	const tapAction = notificationData.tapAction;
	if (tapAction) {
		const { type, destination, slug } = tapAction ?? {};
		const url = typeActionToRouteMap[type as TNotificationTypeActionType](destination, slug);
		if (url) return { url, external: url.startsWith('http'), type, destination };
	}
};
