import type { MessageResponse } from 'stream-chat';

export const messageHasGiphyAttachment = (message: MessageResponse) => {
	let hasGiphyAttachment = false;
	message.attachments?.forEach((attachment) => {
		if (attachment.type === 'giphy') {
			hasGiphyAttachment = true;
		}
	});
	return hasGiphyAttachment;
};
