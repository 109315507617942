import { useService, useQuery } from '@hooks';
import type { TNetworkConnection, TFolder } from '@typings';

export const useNetworkConnectionFolderQuery = (
	networkConnectionId: TNetworkConnection['id'],
	folderId: TFolder['id'] | null,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TFolder, Error>(
		reactQuery.queryKeys.getNetworkConnectionFolder(networkConnectionId, folderId),
		async () => await api.networkConnectionFolder.getNetworkConnectionFolder(String(folderId)),
		{
			enabled: !!folderId,
		},
	);
};
