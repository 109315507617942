import styles from './HomePageLayout.module.css';
import type { ReactNode } from 'react';

const HomePageLayout = ({ sidebarContent, children }: IHomePageLayoutProps) => {
	return (
		<div className={styles.homePageLayout}>
			<aside className={styles.homePageLayout__sideBar}>{sidebarContent}</aside>
			<div className={styles.homePageLayout__body}>
				<div className={styles.homePageLayout__contentContainer}>{children}</div>
			</div>
		</div>
	);
};

export default HomePageLayout;

export interface IHomePageLayoutProps {
	children: ReactNode;
	sidebarContent: ReactNode;
}
