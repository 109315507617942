import { Page } from '../../components';
import { useTranslation, useParams, useSearchParams } from '@hooks';
import { ThirdPartyDealForm } from '@ui-modules/deals/components/DealForm';
import { ActivityIndicator, LogoHeader, Navigate } from '@ui-kit';
import styles from './EditThirdPartyDealPage.module.css';
import { useThirdPartyDeal } from '@ui-modules/deals/hooks/useThirdPartyDeal';
import { ROUTES } from '@constants';

const EditThirdPartyDealPage = () => {
	const { t } = useTranslation();
	const dealId = useParams<IThirdPartyDealPageParams>().dealId as IThirdPartyDealPageParams['dealId'];
	const [searchParams] = useSearchParams();
	const clientId = searchParams.get('clientId') || undefined;
	const clientSecret = searchParams.get('clientSecret') || undefined;
	const { isTokenAvailable, isLoading } = useThirdPartyDeal({ clientSecret, clientId });

	if (!clientId || !clientSecret) return <Navigate replace={true} to={ROUTES.signIn()} />;

	return (
		<Page scrollDisabled tabName="invest" title={t('Edit Deal Page')}>
			<LogoHeader />
			<div className={styles.container}>
				{!isLoading && isTokenAvailable ? (
					<ThirdPartyDealForm dealId={dealId} />
				) : (
					<ActivityIndicator size="medium" type="fit" />
				)}
			</div>
		</Page>
	);
};

interface IThirdPartyDealPageParams extends Record<string, string | undefined> {
	dealId: string;
}

export default EditThirdPartyDealPage;
