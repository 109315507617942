import { useService, useMe, useTranslation, useMutation, useNotification, useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import type { TUser } from '@typings';

export const useOpenDirectChatMutation = () => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { showInfo } = useNotification();
	const chat = useService('ChatService');
	const navigate = useNavigate();

	return useMutation<void, Error, TUser['id']>(
		['useOpenDirectChat'],
		async (targetUserSlug) => {
			const someonePaused = await chat.someUserPaused([user.slug, targetUserSlug]);
			if (someonePaused) {
				throw new ChatDisabledError();
			} else {
				navigate(ROUTES.chatChannel(targetUserSlug));
			}
		},
		{
			onError: (error) => {
				if (error instanceof ChatDisabledError) {
					showInfo({ title: t('Chat has been temporarily disabled') });
				}
			},
		},
	);
};

class ChatDisabledError extends Error {
	constructor() {
		super('One of the users is paused.');
		this.name = 'ChatDisabledError';
	}
}
