import { useMe, useService, useTranslation } from '@hooks';
import type { TDeal, TDealTemplateField } from '@typings';
import { CollapsibleTextSection, Gap } from '@ui-kit';
import DealContactBlock from '@ui-modules/deals/components/DealBlocks/DealContactBlock';
import DealFiles from '@ui-modules/deals/components/DealFiles';
import DealLinks from '@ui-modules/deals/components/DealLinks';
import type { IDealMemberBlockProps } from '@ui-modules/deals/components/DealMemberBlock';
import DealMemberBlock from '@ui-modules/deals/components/DealMemberBlock';
import { DealPostBodyRow } from '@ui-modules/deals/components/DealPost/DealPostBodyRow';
import UniversalVideo from '@ui-modules/media/components/UniversalVideo';
import { getDynamicFieldSubtitle } from '@ui-modules/deals/utils/getDynamicFieldSubtitle';
import { DealEntity, dayjs, difference, formatAmount, formatCurrencySymbol, sortBy } from '@utils';
import styles from './DealDetails.module.css';

const getIsPreviewField = (deal: TDeal, key: string, dealPreview?: TDeal) =>
	dealPreview && dealPreview?.[key as keyof TDeal] !== deal[key as keyof TDeal];

const DealDetails = ({ deal, dealPreview, onGoToProfile }: IDealDetailsProps) => {
	const displayDeal = dealPreview ?? deal;
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');
	const { user } = useMe();

	const isDraftDeal = deal?.status === 'draft' || deal?.status === 'review';
	const isDealHasPreview = deal?.status === 'published' && DealEntity.canSeeReviewUpdates(deal, user);

	return (
		<div className={styles.dealTab__details}>
			<DealMemberBlock deal={deal} onGoToProfile={onGoToProfile} />
			<Gap gap={16} />
			{deal?.priorExperience ? (
				<CollapsibleTextSection
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					text={deal.priorExperience}
					title={t('Prior Experience')}
				/>
			) : null}

			{isDraftDeal && !deal?.description ? (
				<DealPostBodyRow required showPlug={!deal?.description} subtitle={deal?.description} title={t('Description')} />
			) : deal?.description ? (
				<CollapsibleTextSection
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					text={displayDeal.description}
					textHighlighted={getIsPreviewField(deal, 'description', dealPreview)}
					title={t('Description')}
				/>
			) : null}
			{displayDeal.videoUrl ? (
				<UniversalVideo
					isUrlPreview={getIsPreviewField(deal, 'videoUrl', dealPreview)}
					videoUrl={displayDeal.videoUrl}
				/>
			) : null}
			<div className={styles.dealTab__rowContainer}>
				{/* Static fields */}
				<DealPostBodyRow required subtitle={deal?.dealSubClass?.name} title={t('Sub-class')} />
				<DealPostBodyRow required subtitle={deal?.dealType?.name} title={t('Type')} />
				<DealPostBodyRow
					preview={getIsPreviewField(deal, 'currency', dealPreview)}
					required
					showPlug={!deal?.currency}
					subtitle={`${formatCurrencySymbol(displayDeal?.currency)} ${displayDeal.currency}`}
					title={t('Currency')}
				/>
				<DealPostBodyRow
					hidden={deal?.minInvestment === 0}
					preview={
						getIsPreviewField(deal, 'minInvestment', dealPreview) || getIsPreviewField(deal, 'currency', dealPreview)
					}
					required
					showPlug={!deal?.minInvestment}
					subtitle={`${formatCurrencySymbol(displayDeal?.currency)}${formatAmount(Number(displayDeal?.minInvestment))}`}
					title={t('Minimum')}
				/>
				{displayDeal.closingDate || isDraftDeal ? (
					<DealPostBodyRow
						preview={getIsPreviewField(deal, 'closingDate', dealPreview)}
						showPlug={!deal?.closingDate}
						subtitle={dayjs.tz(displayDeal?.closingDate, 'UTC').format('DD MMM YYYY')}
						title={t('Closing')}
					/>
				) : null}
				{/* Dynamic fields */}
				{deal?.data?.body?.sections?.map((section, sectionIndex) =>
					sortBy(section.fields, 'display_order').map((field: unknown, fieldIndex) => {
						const dynamicField = field as TDealTemplateField;
						const dynamicPreviewField = sortBy(
							dealPreview?.data?.body?.sections?.[sectionIndex]?.fields,
							'display_order',
						)?.[fieldIndex] as TDealTemplateField;
						const subtitle = dealPreview
							? getDynamicFieldSubtitle(
									dynamicPreviewField?.value,
									section.section_slug,
									dealPreview?.currency || deal?.currency,
									dynamicField.type,
							  )
							: getDynamicFieldSubtitle(dynamicField?.value, section.section_slug, deal?.currency, dynamicField.type);

						return (dealPreview ? dynamicPreviewField?.value : dynamicField?.value) || isDraftDeal ? (
							<DealPostBodyRow
								key={`${dynamicField.slug}_${dynamicField.type}`}
								preview={isDynamicFieldChanged(
									dynamicField,
									dynamicPreviewField,
									section.section_slug,
									getIsPreviewField(deal, 'currency', dealPreview),
								)}
								required={dynamicField.required}
								showPlug={!dynamicField.value}
								subtitle={subtitle}
								title={dynamicField.label}
							/>
						) : null;
					}),
				)}
			</div>
			<DealLinks
				hasPreview={!!dealPreview}
				links={deal.webUrls}
				previewLinks={dealPreview?.webUrls}
				onLinkOpened={() => {
					analytics.trackEvent('DealInteractions', {
						deal_id: deal.id,
						deal_name: deal.name,
						interaction_type: 'details',
						interaction_action: 'info link opened',
					});
				}}
			/>
			<DealFiles
				dealHasPreview={isDealHasPreview}
				dealId={deal.id}
				dealName={deal.name}
				isDraftDeal={isDraftDeal}
				isPreviewMode={!!dealPreview}
			/>
			<Gap gap={24} />
			<DealContactBlock
				deal={deal}
				dealPreview={dealPreview}
				onEmailOpened={() => {
					analytics.trackEvent('DealInteractions', {
						deal_id: deal.id,
						deal_name: deal.name,
						interaction_type: 'details',
						interaction_action: 'contact email opened',
					});
				}}
				onPhoneOpened={() => {
					analytics.trackEvent('DealInteractions', {
						deal_id: deal.id,
						deal_name: deal.name,
						interaction_type: 'details',
						interaction_action: 'contact phone opened',
					});
				}}
			/>
		</div>
	);
};

const isDynamicFieldChanged = (
	field: TDealTemplateField,
	previewField?: TDealTemplateField,
	slug?: string,
	isCurrencyChanged?: boolean,
) => {
	if (!previewField) return false;
	const numberConverting = Array.isArray(previewField?.value)
		? false
		: Number(previewField?.value?.replaceAll(',', '')); // check if it number string *convert "1,000" to 1000.
	if (slug === 'financial-details' && numberConverting) {
		return previewField.value !== field.value || isCurrencyChanged;
	}
	if (previewField.type === 'multi-select') {
		const valueDifference = difference(field.value, previewField?.value || []);
		return Boolean(field?.value?.length !== previewField?.value?.length || valueDifference?.length);
	}
	return previewField.value !== field.value;
};

interface IDealDetailsProps extends Pick<IDealMemberBlockProps, 'onGoToProfile'> {
	deal: TDeal;
	dealPreview?: TDeal;
}

export default DealDetails;
