import { useMutation, useNotification, useService, useTranslation, useQuery, useQueryClient } from '@hooks';
import type { TNominationRequest, TNominationResponse } from '@typings';

export const useNominations = () => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { showUnknownError, showSuccess } = useNotification();
	const { t } = useTranslation();

	const getNominations = useQuery<TNominationResponse[], Error>(
		['nominations.getNominations'],
		async () => await api.nomination.getNominations(),
	);

	const { mutateAsync: addNomination } = useMutation<void, Error, TNominationRequest>(
		['nomination.addNomination'],
		async (nomination) => {
			return await api.nomination.addNomination(nomination);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['nominations.getNominations']);
				showSuccess({
					title: t('Success'),
					subtitle: t('Nomination has been added'),
				});
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	return {
		getNominations,
		addNomination,
	};
};
