import { useCalendarItemsQuery } from '@ui-modules/events/hooks/useCalendarItemsQuery';
import type { TEvent } from '@typings';
import { useIsFetching } from '@tanstack/react-query';
import { useEffect } from 'react';

const CalendarItemsQuery = ({ searchQuery, selectedDate, children, onFirstEventLoaded }: ICalendarItemsQueryProps) => {
	const { eventSections } = useCalendarItemsQuery(selectedDate, searchQuery);
	const isInitialLoading = useIsFetching(['calendarItem.getInitialCalendarItems']) > 0;
	const isItemsLoading = useIsFetching(['calendarItem.getCalendarItems']) > 0;

	useEffect(() => {
		if (eventSections.length) {
			const firstEvent = eventSections[0]?.data[0];
			if (firstEvent) onFirstEventLoaded(firstEvent);
		}
	}, [eventSections, onFirstEventLoaded]);

	return children({ eventSections, isLoading: isInitialLoading || isItemsLoading });
};

type TCalendarItemsQueryRenderProps = {
	eventSections: {
		title: string;
		data: TEvent[];
	}[];
	isLoading: boolean;
};

interface ICalendarItemsQueryProps {
	searchQuery: string;
	selectedDate: string;
	children: (renderProps: TCalendarItemsQueryRenderProps) => JSX.Element;
	onFirstEventLoaded: (firstLoadedEvent: TEvent | undefined) => void;
}

export default CalendarItemsQuery;
