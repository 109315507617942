import { useService } from '@services';
import type { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

function ReduxProvider({ children }: PropsWithChildren) {
	const { store, persistor } = useService('ReduxService');
	const logger = useService('logger').child('ReduxProvider');

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor} onBeforeLift={() => logger.debug('PersistGate before lift')}>
				{children}
			</PersistGate>
		</Provider>
	);
}

export default ReduxProvider;
