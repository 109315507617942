import type { ReactNode, SVGProps } from 'react';
import { NavLink, ChevronRightIcon } from '@ui-kit';
import { clsx } from '@utils';
import styles from './MenuNavigationButton.module.css';
import { useFlyoutMutation } from '@ui-modules/flyout/hooks/useFlyoutMutation';
import { useFlyoutQuery } from '@ui-modules/flyout/hooks/useFlyoutQuery';
import { useFlyoutSequence } from '@ui-modules/flyout/hooks/useFlyoutSequence';

const MenuNavigationButton = ({ linkTo, label, icon, badgeShown = false }: IMenuNavigationButtonProps) => {
	const { data: flyoutMessages = [] } = useFlyoutQuery();

	const { mutateAsync } = useFlyoutMutation({}, true);
	const { currentMessage } = useFlyoutSequence(flyoutMessages);

	const handleReadFlyoutMessage = async () => {
		if (currentMessage) {
			await mutateAsync(currentMessage.id);
		}
	};
	return (
		<NavLink
			className={({ isActive }) => (isActive ? styles.menuButton__container_active : styles.menuButton__container)}
			to={linkTo}
			onClick={handleReadFlyoutMessage}
		>
			{({ isActive }) => (
				<div className={styles.menuButton__wrapper}>
					<div className={styles.menuButton__leftSide}>
						{icon ? (
							<div className={styles.menuButton__iconWrapper}>
								{badgeShown && <div className={styles.menuButton__redDot} />}
								{icon({ className: isActive ? styles.menuButton__icon_active : styles.menuButton__icon })}
							</div>
						) : null}
						<p className={clsx(styles.menuButton__label, isActive && styles.menuButton__label_active)}>{label}</p>
					</div>
					<ChevronRightIcon
						className={isActive ? styles.menuButton__iconChevron_active : styles.menuButton__iconChevron}
						width={16}
					/>
				</div>
			)}
		</NavLink>
	);
};

export interface IMenuNavigationButtonProps {
	linkTo: string;
	label: string;
	icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
	badgeShown?: boolean;
}

export default MenuNavigationButton;
