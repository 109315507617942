import { CloseIcon, SearchIcon } from '@ui-kit/icons';
import styles from './FilterInput.module.css';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

const FilterInput = (
	{ value, onSearchCleared, placeholder = 'Search', ...props }: IFilterInputProps,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	return (
		<div className={styles.input__wrapper}>
			<div className={styles.input__container}>
				<input
					className={styles.input}
					placeholder={placeholder}
					ref={ref}
					value={value}
					onBlur={(e) => e.preventDefault()}
					{...props}
				/>
				<span className={styles.input__icon}>
					{value?.length ? (
						<button
							className={styles.input__clearButton}
							onClick={onSearchCleared}
							onMouseDown={(e) => e.preventDefault()}
						>
							<CloseIcon className={styles.input__iconSvg} height={8} width={8} />
						</button>
					) : (
						<SearchIcon className={styles.input__iconSvg_search} height={12} width={12} />
					)}
				</span>
			</div>
		</div>
	);
};

export interface IFilterInputProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	onSearchCleared: () => void;
}

export default forwardRef(FilterInput);
