import { useService, useQuery, useMe } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TEvent } from '@typings';

export const useNextGroupMeetingsQuery = (queryOptions?: UseQueryOptions<TEvent[], Error>) => {
	const api = useService('ApiService');
	const { user } = useMe();

	return useQuery<TEvent[], Error>(['events.nextGroupMeetings'], () => api.meeting.getNextGroupMeetings(user.id), {
		staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		select: (data) => data.slice(0, 3),
		...queryOptions,
	});
};
