import type { ReactNode } from 'react';
import { useTranslation, useCallback, useMemo, useHandleBackButton, useEvent, useDebouncedCallback } from '@hooks';
import { BackIcon, Button } from '@ui-kit';
import DiscardFormChangesModal from '@ui-modules/forms/components/DiscardFormChangesModal';
import { clsx, isEmpty, isUndefined } from '@utils';
import styles from './FormPageLayout.module.css';
import type { FormikErrors, FormikProps } from 'formik';
import type { TMixpanelEvent } from '@typings';

/** Form with header controllers and "keep editing" modal  */
function FormPageLayout<TValues extends Record<string, unknown>>({
	formProps,
	ignoreDirty,
	saveButtonTitle,
	isSaveButtonHidden = false,
	saveTrackingName,
	children,
	headerTitle,
	customLeftIcon,
	buttonBordered = true,
	customDisabled,
	onSuccessSubmit,
	onSaveFailed,
	onDiscarded,
	scrollEnabled = false,
	onSaveHandler,
	allowedNextPaths = [],
	customHeaderStyles = '',
	contentContainerStyle = '',
	submitLoading = false,
	additionalDisabled = false,
}: IFormPageLayoutProps<TValues>) {
	const { onBackButtonPress } = useHandleBackButton();
	const { t } = useTranslation();
	const finalSaveButtonTitle = saveButtonTitle ?? t('Save');
	const isDirtyOrInvalid = useMemo(
		() => (ignoreDirty ? !formProps.isValid : !formProps.dirty || !formProps.isValid),
		[ignoreDirty, formProps],
	);

	const onSave = useCallback(async () => {
		try {
			const validationErrors = await formProps.validateForm();
			if (!isEmpty(validationErrors)) {
				onSaveFailed?.(validationErrors);
				return;
			}
			await formProps.submitForm();
			onSuccessSubmit ? onSuccessSubmit() : onBackButtonPress();
		} catch (error) {
			console.info(`Save form error (Block:${headerTitle}):`, error);
		}
	}, [formProps]);
	const handleSave = useDebouncedCallback(onSaveHandler || onSave, 1000, { leading: true, trailing: false });

	return (
		<div className={clsx(styles.layout, scrollEnabled && styles.layout__scrollable, contentContainerStyle)}>
			{headerTitle ? (
				<div className={clsx(styles.layout__header, styles.layout__header_transparent, customHeaderStyles)}>
					<div className={styles.layout__headerButtonBlock}>
						<button className={styles.layout__headerButton} onClick={onBackButtonPress}>
							{customLeftIcon || <BackIcon className={styles.layout__headerBackIcon} height={16} width={16} />}
						</button>
					</div>
					<span className={styles.layout__headerTitle}>{headerTitle}</span>
					<div className={clsx(styles.layout__headerButtonBlock, styles.layout__headerButtonBlock_right)}>
						{isSaveButtonHidden ? null : (
							<Button
								disabled={(!isUndefined(customDisabled) ? customDisabled : isDirtyOrInvalid) || additionalDisabled}
								loading={submitLoading || formProps.isSubmitting}
								title={finalSaveButtonTitle}
								trackingName={saveTrackingName}
								type={buttonBordered ? 'outline' : 'transparent'}
								variant={buttonBordered ? 'small' : 'medium'}
								onClick={handleSave}
							/>
						)}
					</div>
				</div>
			) : null}
			<div className={clsx(styles.layout__content, scrollEnabled && styles.layout__contentScrollable)}>{children}</div>
			<DiscardFormChangesModal allowedNextPaths={allowedNextPaths} ignoreDirty={ignoreDirty} onDiscard={onDiscarded} />
		</div>
	);
}

interface IFormPageLayoutProps<TValues> {
	/** form props for the header controllers */
	formProps: FormikProps<TValues>;
	/** Header save button title can be changed from default "save" */
	saveButtonTitle?: string;
	/** Decides whether save button should be hidden. Default 'false'. */
	isSaveButtonHidden?: boolean;
	/** Name of the event send to analytics on click. */
	saveTrackingName?: TMixpanelEvent;
	/** If header save button should not be disabled if form has not changed */
	ignoreDirty?: boolean;
	/** Subheader title */
	headerTitle?: string;
	/** Icon that will be displayed on the left button*/
	customLeftIcon?: ReactNode;
	/** ReactNode content of the form. */
	children: ReactNode;
	/** Is save button should be bordered */
	buttonBordered?: boolean;
	/** Disabled option that not connected to form validation */
	customDisabled?: boolean;
	/** Additional disabled option that not related to form validation and customDisabled, checks with "||" */
	additionalDisabled?: boolean;
	/** If 'true' header layout shows without background color and shadow. Default 'false'. */
	isBackgroundTransparent?: boolean;
	/** Call function when save was failed because of form validation */
	onSaveFailed?: (errors: FormikErrors<TValues>) => void;
	/** Custom function that would be called on submit form success */
	onSuccessSubmit?: () => void;
	/** Custom function that would be called on onConfirm for Discard Modal
	 * for example if we use FormikPersist component we need to clear storage after discard */
	onDiscarded?: () => void;
	/** Define if scroll appears*/
	scrollEnabled?: boolean;
	/** List of routes to which you can navigate without triggering showing DiscardChanges modal dialog.
	 * 	It's important to add at least "current" path, because sometimes it may be redirected to itself with new state or props that should trigger redundant dialog
	 */
	allowedNextPaths: string[];
	/** On save click handler*/
	onSaveHandler?: () => void;
	/** Will apply custom styles to the header */
	customHeaderStyles?: string;
	/** Will apply custom styles to the container */
	contentContainerStyle?: string;
	/** Boolean to check form submitting status */
	submitLoading?: boolean;
}

export default FormPageLayout;
