import { useEffect, useEvent, useSearchParams } from '@hooks';
import type { TDealQuerySort } from '@typings';

export const useDealsSortSearchParams = (defaultSortParams: TDealQuerySort) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const sort = searchParams.get('sort');
	const parsedSort = parseSortParams(sort) || defaultSortParams;

	const setSort = useEvent((sortParams: TDealQuerySort) => {
		setSearchParams(
			(prev) => {
				prev.set('sort', stringifySortParams(sortParams));
				const newSearchParams = prev.toString();
				return newSearchParams;
			},
			{ replace: true },
		);
	});

	useEffect(
		function setDefaultSortIfAbsent() {
			if (!sort) {
				setSort(defaultSortParams);
			}
		},
		[sort],
	);

	return [parsedSort, setSort] as const;
};

// Helpers:

const DATA_SEPARATOR = '.';

function stringifySortParams(sortParams: TDealQuerySort) {
	return `${sortParams.field}${DATA_SEPARATOR}${sortParams.order}`;
}

function parseSortParams(sortString: string | null) {
	if (!sortString) return undefined;
	try {
		const [field, order] = sortString.split(DATA_SEPARATOR);
		return { field, order } as TDealQuerySort;
	} catch (error) {
		return undefined;
	}
}
