import { useEffect } from 'react';
import { useEvent } from '@hooks';
import mitt from 'mitt';

const emitter = mitt<TFeedNotifierEvents>();

/** Allows to open meatball menu by ID from any place of the app. */
export const FeedNotifierEmitter = {
	useEmitter: () => {
		const refreshActivity = useEvent((activityId: string) => {
			emitter.emit('REFRESH_ACTIVITY', activityId);
		});

		return { refreshActivity };
	},
	useListener: (activityId: string | undefined, callback: () => void) => {
		useEffect(() => {
			if (!activityId) return;

			const handler = (triggeredActivityId: string) => {
				if (triggeredActivityId === activityId) {
					callback();
				}
			};
			if (activityId) {
				emitter.on('REFRESH_ACTIVITY', handler);
			}

			return () => {
				if (activityId) {
					emitter.off('REFRESH_ACTIVITY', handler);
				}
			};
		}, []);
	},
};

type TFeedNotifierEvents = {
	REFRESH_ACTIVITY: string;
};
