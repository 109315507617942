import When from '../When';
import RadioButton from '../RadioButton';
import Avatar from '../Avatar';
import { MyContactIcon, BlockIcon } from '../../icons';
import styles from './UserListItem.module.css';
import { clsx } from '@utils';
import type { TUserProfileRecord } from '@typings';
import type { ReactNode } from 'react';

/** Row with user information, can show "my contact" icon. Can be selectable.
 *  renderUserSubTitle allows to put some function (like formatUserSubTitle) to show subtitle with user role or groups.
 */
const UserListItem = ({
	userProfileRecord,
	isMyContact,
	isSelected,
	actionElement,
	secondarySubtitleElement,
	onClick,
	renderUserSubTitle = () => undefined,
	className,
	titleClassName,
	subtitleClassName,
	size = 'medium',
	isBackgroundTransparent = false,
	withSeparator = false,
	showBlockIcon = false,
	onBlockUnBlockPressed,
	avatarSize = 26,
}: IUserListItemProps) => {
	const isSelectable = typeof isSelected === 'boolean';
	const fullName = `${userProfileRecord.firstName} ${userProfileRecord.lastName}`;
	const userSubTitle = renderUserSubTitle(userProfileRecord);
	const isChair = userProfileRecord.profile && userProfileRecord.profile['@type'] === 'ProfileChair';

	const handleClick = () => onClick?.(userProfileRecord);
	return (
		<div
			className={clsx(
				styles.userListItem,
				isBackgroundTransparent && styles.userListItem_transparent,
				withSeparator && styles.userListItem_withSeparator,
				!!onClick && styles.userListItem_clickable,
				className,
			)}
			data-size={size}
			onClick={(event) => {
				event.stopPropagation();
				handleClick();
			}}
			onKeyPress={(event) => {
				event.stopPropagation();
				handleClick();
			}}
		>
			<div className={styles.userListItem__body}>
				<When condition={isSelectable}>
					<div className={styles.userListItem__selectionBox}>
						<RadioButton size="small" value={Boolean(isSelected)} onChange={handleClick} />
					</div>
				</When>
				<div className={styles.userListItem__header}>
					<Avatar
						imageUri={userProfileRecord.avatar?.contentUrl}
						outline={isChair}
						size={avatarSize}
						title={fullName}
					/>
					<div className={styles.userListItem__content}>
						<div className={styles.userListItem__titleBox}>
							<span className={clsx(styles.userListItem__title, titleClassName)}>{fullName}</span>
							<When condition={isMyContact}>
								<MyContactIcon className={styles.userListItem__contactIcon} />
							</When>
						</div>
						<div className={clsx(styles.userListItem__subtitle, subtitleClassName)}>{userSubTitle}</div>
						<When condition={!!secondarySubtitleElement}>{secondarySubtitleElement}</When>
					</div>
				</div>
				<When condition={!!actionElement}>
					<div
						className={styles.userListItem__actionBox}
						onClick={(event) => event.stopPropagation()}
						onKeyPress={(event) => event.stopPropagation()}
					>
						{actionElement}
					</div>
				</When>
				{showBlockIcon && onBlockUnBlockPressed ? (
					<div
						className={styles.userListItem__blockIIcon}
						onClick={() => onBlockUnBlockPressed(userProfileRecord)}
						onKeyPress={() => onBlockUnBlockPressed(userProfileRecord)}
					>
						<BlockIcon fill="#FA2F53" />
					</div>
				) : null}
			</div>
		</div>
	);
};

export interface IUserListItemProps {
	/** Data to render. */
	userProfileRecord: TUserProfileRecord;
	/** If "true" shows checkmark icon means that the user is 'my' contact. */
	isMyContact?: boolean;
	/** Resolves should show radio button. If "undefined" it is hidden, but if "true" or "false" it shows with related state. Default "undefined". */
	isSelected?: boolean;
	/** Any React element which will be displayed on the right side of the row. May be button, icon, etc. */
	actionElement?: ReactNode;
	/** Any React element which will be displayed inside the card under main subtitle */
	secondarySubtitleElement?: ReactNode;
	/** Put some function (like formatUserSubTitle from @utils) to show subtitle with user role or groups. */
	renderUserSubTitle?: (userProfileRecord: TUserProfileRecord) => string | undefined;
	/** Invokes when component is clicked. */
	onClick?: (userProfileRecord: TUserProfileRecord) => void;
	/** List item className */
	className?: string;
	/** Custom style for name text element */
	titleClassName?: string;
	/** Custom style for subtitle text element */
	subtitleClassName?: string;
	/** Size of the list item (changes height & paddings). */
	size?: 'small' | 'medium' | 'unset';
	/** Resolves if should background be transparent. Default false. */
	isBackgroundTransparent?: boolean;
	/** Resolves if should the bottom line as separator be shown. Default false. */
	withSeparator?: boolean;
	showBlockIcon?: boolean;
	onBlockUnBlockPressed?: (userProfileRecord: TUserProfileRecord) => void;
	avatarSize?: number;
}

export default UserListItem;
