import type { TEmailContact, TPhoneContact } from '@typings';
import { translateEmailLabelToTitle, translatePhoneLabelToTitle } from '@utils';
import type { TFunction } from 'i18next';

export function mapPhoneTypeToTitle(phones: TPhoneContact[], t: TFunction) {
	return phones.map((phone) => ({
		value: phone.tel,
		text: translatePhoneLabelToTitle(phone.type, t),
		linkType: 'tel',
	}));
}
export function mapEmailTypeToTitle(emails: TEmailContact[], t: TFunction) {
	return emails.map((email) => ({
		value: email.email,
		text: translateEmailLabelToTitle(email.type, t),
		linkType: 'mailto',
	}));
}
