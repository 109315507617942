import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TWaiver } from '@typings';

export const useLatestWaiverQuery = (enabled = true) => {
	const api = useService('ApiService');
	return useQuery<TWaiver, Error>(['waivers.getLatest'], async () => api.waiver.getLatestWaiver(), {
		enabled,
	});
};
