import { AuthenticatorKey } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export const translateFactorType = (t: TFunction) => (factorType: AuthenticatorKey) => {
	switch (factorType) {
		case AuthenticatorKey.OKTA_EMAIL:
			return t('Email');
		case AuthenticatorKey.PHONE_NUMBER:
			return t('SMS');
	}
};
