import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDeal } from '@typings';

export const useDealDocumentsQuery = (dealId?: string) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery(
		reactQuery.queryKeys.getDealDocuments(dealId as TDeal['id']),
		async () => await api.dealFile.getDealDocuments({ dealId: dealId as TDeal['id'] }),
		{
			enabled: !!dealId,
		},
	);
};
