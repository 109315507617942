import type { FeedContextValue } from 'react-activity-feed';
import { Feed, FeedContext } from 'react-activity-feed';
import { useMe, useService } from '@hooks';
import type { ReactNode } from 'react';

const NotificationsFeedContextProvider = ({ children }: INotificationsFeedContextProviderProps) => {
	const { user } = useMe();
	const feed = useService('FeedService');

	return (
		<Feed feedGroup={feed.notificationFeedGroup} notify userId={user.slug}>
			<FeedContext.Consumer>
				{(feedContext) => {
					const context = feedContext;
					return children(context as FeedContextValue);
				}}
			</FeedContext.Consumer>
		</Feed>
	);
};

export interface INotificationsFeedContextProviderProps {
	children: (feedContext: FeedContextValue) => ReactNode;
}

export default NotificationsFeedContextProvider;
