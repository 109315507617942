import { Button, Modal } from '@ui-kit';
import { useState, useTranslation, useEvent } from '@hooks';
import ReactMarkdown from 'react-markdown';

import styles from './SolicitationWaiverModal.module.css';

const SolicitationWaiverModal = ({ onAgree, onClose, version, content }: ISolicitationWaiverModalProps) => {
	const { t } = useTranslation();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const checkShouldEnableAgreeButton = useEvent((target: HTMLElement) => {
		const isBottomReached = Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight;
		if (isBottomReached) setIsButtonDisabled(false);
	});

	const modalContent = (
		<div className={styles.waiverModal__content}>
			<h3 className={styles.waiverModal__title}>{t('Solicitation Waiver v{{version}}.0', { version })}</h3>
			<div className={styles.waiverModal__body}>
				<div className={styles.gradientTop} />
				<div
					className={styles.waiverModal__contentWrapper}
					ref={(ref) => ref && checkShouldEnableAgreeButton(ref)}
					onScroll={(event) => checkShouldEnableAgreeButton(event.target as HTMLDivElement)}
				>
					<ReactMarkdown>{content}</ReactMarkdown>
				</div>
				<div className={styles.gradientBottom} />
			</div>
			<div className={styles.waiverModal__footer}>
				<span className={styles.waiverModal__hint}>{t('Read to the bottom to continue')}</span>
				<div className={styles.waiverModal__buttons}>
					<Button title={t('Decline')} type="clear" onClick={onClose} />
					<Button disabled={isButtonDisabled} title={t('Agree and Continue')} onClick={onAgree} />
				</div>
			</div>
		</div>
	);
	return <Modal contentClassName={styles.waiverModal} modalContent={modalContent} variant="large" visible />;
};

interface ISolicitationWaiverModalProps {
	onAgree: () => void;
	onClose: () => void;
	version: number;
	content: string;
}

export default SolicitationWaiverModal;
