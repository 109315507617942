/* 	Try to use aspect ratio cache to avoid layout shift during loading feed. 
	Works when container is showed not first time.
	Helps to fix issue "T21C-3112: [WEB] An user doesn't return back to the same place in the Posts list if s/he returns from the post details page using the back button"
	[@dmitriy.nikolenko]
*/
export class AspectRatioCache {
	constructor() {
		this.aspectRatioCache = new Map<string, number>([]);
	}

	private aspectRatioCache: Map<string, number>;

	getAspectRatio(videoId: string) {
		const cachedAspectRatio = this.aspectRatioCache.get(String(videoId)) ?? undefined;
		const cssAspectRatio = cachedAspectRatio ? `1 / ${cachedAspectRatio}` : undefined;
		return cssAspectRatio;
	}

	setAspectRatio(videoId: string, aspectRatio: number) {
		this.aspectRatioCache.set(videoId, aspectRatio);
	}
}
