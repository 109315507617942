import { useRouteError } from 'react-router-dom';
import { useState, useEffect, useDebouncedCallback, useOktaAuth, useNavigate } from '@hooks';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { ROUTES } from '@constants';
import ErrorFallback from '../ErrorFallback';
import NotFound404 from '../NotFound404';
import { TabBar } from '../TabBar';

/** Shows component for React Router errorElement (@see https://reactrouter.com/en/main/route/error-element). */
const ErrorRoute = () => {
	const routeError = useRouteError() as TRouteError;
	const navigate = useNavigate();
	const { authState } = useOktaAuth();

	const { isSearchActive } = useGlobalSearchState();
	const [isNotificationsShown, setNotificationsShown] = useState(false);
	const toggleNotificationsSection = useDebouncedCallback(() => setNotificationsShown((opened) => !opened), 100);

	useEffect(() => {
		if (isNotificationsShown || isSearchActive) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isNotificationsShown, isSearchActive]);

	const errorComponent =
		routeError.status !== 404 ? (
			<ErrorFallback
				error={routeError.error}
				resetErrorBoundary={() => {
					window.location.replace(window.origin);
				}}
			/>
		) : (
			<>
				<TabBar isNotificationsShown={isNotificationsShown} toggleNotificationsSection={toggleNotificationsSection} />
				<NotFound404 />
			</>
		);

	return authState?.isAuthenticated ? errorComponent : (navigate(ROUTES.signIn()) as never);
};

export default ErrorRoute;

type TRouteError = {
	data: string;
	error: Error;
	internal: boolean;
	status: number;
	statusText: string;
};
