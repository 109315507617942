import { useLocation } from '@hooks';
import { Navigate } from 'react-router-dom';

/** Just redirects to "/calendar/meetings/:id" or "/calendar/events/:id"
 * 	to follow the same URL schema as a mobile app.
 */
const RedirectToCalendar = () => {
	const location = useLocation();
	return <Navigate replace={true} to={`/calendar` + location.pathname} />;
};

export default RedirectToCalendar;
