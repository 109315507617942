import { useTranslation } from '@hooks';
import { dayjs } from '@utils';
import {
	Input,
	DropdownMenu,
	AmountInput,
	CheckboxGroupInput,
	ClosingDateInput,
	CheckboxInput,
	TextArea,
} from '@ui-kit';
import type { ChangeEvent } from 'react';
import type {
	TChoice,
	TDynamicFieldDate,
	TDynamicFieldLink,
	TDynamicFieldMultiSelect,
	TDynamicFieldSingleSelect,
} from '@typings';
import { convertChoicesToOptions } from '@ui-modules/connections/utils/convertChoicesToOptions';
import { NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT } from '@constants';
import { transformLongTextError } from '@ui-modules/connections/utils/transformLongTextError';
import { convertChoicesToSingleSelectOptions } from '@ui-modules/connections/utils/convertChoicesToSingleSelectOptions';

const DetailsDynamicField = ({
	type,
	value,
	errorMessage,
	label,
	meta,
	placeholder,
	options = [],
	setFieldValue,
	setFieldError,
	valuePath,
	fieldPath,
	slug,
	choices,
	isRequired,
}: IDetailsDynamicFieldProps) => {
	const { t } = useTranslation();

	const handleChangeField = (field: string, value: string) => {
		setFieldValue(field, value);
		setFieldError(field, undefined);
	};

	const handleChangeMultiSelectField = (option: string) => {
		const newOptions = value?.includes(option)
			? (value as never as string[])?.filter((el: string) => el !== option)
			: [...(value || []), option];
		setFieldValue(valuePath, newOptions.length ? newOptions : undefined);
	};

	switch (type) {
		case 'dropdown':
			return (
				<DropdownMenu
					errorMessage={errorMessage}
					isSearchable
					label={label}
					options={options?.map((option) => ({ value: option, title: option }))}
					placeholder={t('- select -')}
					value={value}
					variant="medium"
					onChange={(value) => {
						handleChangeField(valuePath, value);
					}}
				/>
			);
		case 'money':
		case 'numeric':
			return (
				<AmountInput
					allowLeadingZeros={false}
					errorMessage={errorMessage}
					label={label}
					name={slug}
					placeholder={placeholder ?? t('e.g 50,000')}
					value={value}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setFieldValue(valuePath, e.target.value?.replaceAll(',', '')?.replace('.', ''));
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		case 'multi-select':
			return (
				<CheckboxGroupInput
					errorMessage={errorMessage}
					label={label}
					options={convertChoicesToOptions(choices)}
					value={value as never as string[]}
					onChange={(option) => {
						handleChangeMultiSelectField(option);
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		case 'single-select':
			return (
				<DropdownMenu
					errorMessage={errorMessage}
					iconType={isRequired ? 'select' : 'clear'}
					isSearchable
					label={label}
					options={convertChoicesToSingleSelectOptions(choices)}
					placeholder={t('- select -')}
					value={value}
					variant="medium"
					onChange={(value) => {
						handleChangeField(valuePath, value);
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		case 'date': {
			const dateMeta = meta as TDynamicFieldDate['meta'];
			return (
				<ClosingDateInput
					dateFormat={dateMeta?.dateFormat}
					errorMessage={errorMessage}
					label={label}
					maxDate={dateMeta?.maxDate ? dayjs.unix(Number(dateMeta.maxDate)).toDate() : undefined}
					minDate={dateMeta?.minDate ? dayjs.unix(Number(dateMeta.minDate)).toDate() : undefined}
					placeholder={(placeholder as string) || label}
					value={value ? new Date(value) : null}
					onChange={(value) => {
						setFieldValue(valuePath, dayjs(value).format('YYYY-MM-DD'));
						setFieldError(fieldPath, undefined);
					}}
					onClear={() => {
						setFieldValue(valuePath, null);
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		}
		case 'boolean':
			return (
				<CheckboxInput
					errorMessage={errorMessage}
					filterCheckbox
					label={label}
					value={String(value).toLowerCase() === 'true'}
					onChange={(value) => {
						setFieldValue(valuePath, String(value));
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		case 'long-text':
			return (
				<TextArea
					charactersLimit={NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT}
					errorMessage={transformLongTextError(value, errorMessage)}
					errorMessagePosition={value?.length > NETWORK_CONNECTION_LONG_TEXT_FIELD_LIMIT ? 'right' : 'left'}
					label={label}
					minRows={4}
					rounded
					value={value}
					onChange={(e) => {
						setFieldValue(valuePath, e.target.value);
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		default: {
			return (
				<Input
					errorMessage={errorMessage}
					errorMessageInitialHidden
					label={label}
					placeholder={placeholder || label}
					value={value}
					onChange={(e) => {
						setFieldValue(valuePath, e.target.value);
						setFieldError(fieldPath, undefined);
					}}
				/>
			);
		}
	}
};

interface IDetailsDynamicFieldProps {
	type: 'dropdown' | 'money' | string;
	value: string;
	errorMessage: string;
	label: string;
	meta:
		| TDynamicFieldDate['meta']
		| TDynamicFieldMultiSelect['meta']
		| TDynamicFieldSingleSelect['meta']
		| TDynamicFieldLink['meta']
		| undefined;
	placeholder?: string | null;
	options?: string[];
	setFieldValue: (field: string, value: string | null | undefined | string[] | boolean) => void;
	setFieldError: (field: string, value: string | undefined) => void;
	valuePath: string;
	fieldPath: string;
	slug: string;
	isRequired?: boolean;
	choices?: TChoice[];
}

export default DetailsDynamicField;
