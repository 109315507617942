import { createSlice } from '@reduxjs/toolkit';
import { NetworkConnectionUserFilterEntity } from '@utils';

import type { PayloadAction } from '@reduxjs/toolkit';

type TNetworkConnectionFilterState = {
	filterTitle: string;
};

const initialState: TNetworkConnectionFilterState = {
	filterTitle: '',
};

export const networkConnectionFilterSlice = createSlice({
	name: 'networkConnectionFilter',
	initialState,
	reducers: {
		updateFilter: (
			state,
			{
				payload: { networkConnectionName, filters },
			}: // @TODO fixme until back end adds validation
			PayloadAction<{ networkConnectionName: string; filters: any }>,
		) => {
			// @TODO fixme until back end adds validation
			state.filterTitle = NetworkConnectionUserFilterEntity.generateFilterTitle<any>(networkConnectionName, filters);
		},
		resetFilter: (state) => {
			state.filterTitle = '';
		},
	},
});
