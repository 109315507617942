import { Avatar } from '@ui-kit';
import networkAvatarSource from './network_avatar.png';
import styles from './ActivityCard.module.css';
import type { TLatestActivity } from '@typings';

const ActivityCard = ({ activity, onPress }: IActivityCardProps) => {
	const communityName = activity?.collectionCommunityReference?.data?.name || activity?.community?.name; // migration to use "collectionCommunityReference" (T21C-2485)
	const avatarContentUrl =
		activity?.collectionCommunityReference?.data?.avatar || activity?.community?.avatar?.contentUrl; // migration to use "collectionCommunityReference" (T21C-2485)

	return (
		<button className={styles.activityCard} onClick={() => onPress(activity)}>
			<div className={styles.activityCard__imageContainer}>
				<Avatar
					containerClassName={styles.activityCard__image}
					imageUri={avatarContentUrl || networkAvatarSource}
					outline={false}
					size={42}
					title={communityName}
				/>
				<span>{communityName}</span>
			</div>
			{activity.title ? <span className={styles.activityCard__title}>{activity.title}</span> : null}
			<span className={styles.activityCard__body}>{activity.body}</span>
		</button>
	);
};

export interface IActivityCardProps {
	activity: TLatestActivity;
	onPress: (activity: TLatestActivity) => void;
}

export default ActivityCard;
