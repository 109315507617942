import { useTranslation } from '@hooks';
import styles from './EmptyStateIndicator.module.css';

const EmptyStateIndicator = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.message__emptyStateContainer}>
			<div className={styles.message__emptyStateText}>{t(`Let’s get the conversation started`)}</div>
		</div>
	);
};
export default EmptyStateIndicator;
