import { mapNetworkConnectionMemberResponseItemToProfileRecords } from './mapNetworkConnectionMemberResponseItemToProfileRecords';
import type { TSectionListItem } from '@ui-kit/components/SectionList/SectionList';
import type { TNetworkConnectionMembersResponse, TUserProfileRecord } from '@typings';

const PAGE_SIZE = 50;
const SHORT_LIST_SIZE = 5;

export const mapNetworkConnectionMembersResponseToUserProfileRecordSectionListItems = (
	data: TNetworkConnectionMembersResponse,
	formatSectionTitle: (networkConnectionRelationStatus: string, totalItems: number) => string,
	loadMore: (args: {
		page: number;
		networkConnectionRelationStatusId: string;
		networkConnectionRelationStatus: string;
	}) => void,
	formatLoadMoreTitle: (networkConnectionRelationStatus: string) => string,
	removeMore: (networkConnectionRelationStatus: string) => void,
	formatRemoveMoreTitle: (networkConnectionRelationStatus: string) => string,
) => {
	return Object.keys(data).map((name) => {
		// Render data logic
		const section = data[name];
		const totalItems = section.totalItems;
		const userProfileRecords = section.items.map(mapNetworkConnectionMemberResponseItemToProfileRecords);

		// Render pagination logic
		const loadedItemsCount = section.items.length;
		const canLoadMore = totalItems > loadedItemsCount;
		const moreLoaded = loadedItemsCount > SHORT_LIST_SIZE;
		const currentPageNumber = Math.floor(loadedItemsCount / PAGE_SIZE);
		const nextPageNumber = currentPageNumber + 1;
		const networkConnectionRelationStatusId = section.items[0].networkConnectionStatus.id;

		const sectionListItem: TSectionListItem<TUserProfileRecord> = {
			title: formatSectionTitle(name, totalItems),
			data: userProfileRecords,
			footerLink: canLoadMore
				? {
						title: formatLoadMoreTitle(name),
						onClick: () =>
							loadMore({
								page: nextPageNumber,
								networkConnectionRelationStatus: name,
								networkConnectionRelationStatusId,
							}),
				  }
				: moreLoaded
				? {
						title: formatRemoveMoreTitle(name),
						onClick() {
							removeMore(name);
						},
				  }
				: undefined,
		};
		return sectionListItem;
	});
};
