import { useQuery, useService } from '@hooks';
import type { TCommunityNotificationSettings } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useNotificationsSettingsWithChaptersQuery = <T = TUseNotificationsSettingsWithChaptersQueryData>(
	queryOptions: UseQueryOptions<TUseNotificationsSettingsWithChaptersQueryData, Error, T> = {},
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	return useQuery<TUseNotificationsSettingsWithChaptersQueryData, Error, T>(
		reactQuery.queryKeys.chaptersNotificationSettings(),
		async () => await api.notificationSettings.getCommunitiesNotificationSettings(),
		{
			staleTime: Infinity,
			...queryOptions,
		},
	);
};
type TUseNotificationsSettingsWithChaptersQueryData = TCommunityNotificationSettings[];
