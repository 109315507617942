import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDeal } from '@typings';

export const useGetDealFilesQuery = (dealId?: string) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery(
		reactQuery.queryKeys.getDealFiles(dealId as TDeal['id'], null),
		async () => await api.dealFile.getDealFiles({ dealId: dealId as TDeal['id'], folderId: null }),
		{
			enabled: !!dealId,
		},
	);
};
