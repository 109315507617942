import { useTranslation } from '@hooks';
import { SuccessModalIcon } from '@ui-kit';
import styles from './ThirdPartyDealFormSubmitMessage.module.css';

export const ThirdPartyDealFormSubmitMessage = ({
	creatorEmail,
	creatorName,
	creatorPhone,
}: IThirdPartyDealFormSubmitMessage) => {
	const { t } = useTranslation();

	return (
		<div className={styles.submitMessage__contentWrapper}>
			<div>
				<div className={styles.submitMessage__header}>
					<SuccessModalIcon height={118} width={118} />
					<h1 className={styles.submitMessage__headerTitle}>Information Submitted</h1>
				</div>

				<p className={styles.submitMessage__paragraph}>
					{t('{{userName}} will be notified.', { userName: creatorName })}
				</p>
				<br />
				<p className={styles.submitMessage__paragraph}>
					<b>{creatorName}</b>
				</p>
				<p className={styles.submitMessage__paragraph}>{t('TIGER 21 Member')}</p>
				<p className={styles.submitMessage__paragraph}>{creatorPhone}</p>
				<p className={styles.submitMessage__paragraph}>
					<a href={`mailto:${creatorEmail}`}>{creatorEmail}</a>
				</p>
			</div>
		</div>
	);
};

interface IThirdPartyDealFormSubmitMessage {
	creatorEmail: string;
	creatorName: string;
	creatorPhone: string;
}
