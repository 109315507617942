import { clsx } from '@utils';
import type { ReactNode } from 'react';
import styles from './FixedWidthContainer.module.css';

const FixedWidthContainer = ({ children, autoSize = false, className }: IFixedWidthContainerProps) => {
	return <div className={clsx(autoSize ? styles.container__autoSize : styles.container, className)}>{children}</div>;
};

export interface IFixedWidthContainerProps {
	children: ReactNode;
	autoSize?: boolean;
	className?: string;
}

export default FixedWidthContainer;
