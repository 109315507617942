import PhoneInputComponent from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import { useEffect, useState, useId, useCurrentLocation } from '@hooks';
import { InputErrorMessage, InputLabel } from '@ui-kit';
import { clsx } from '@utils';
import styles from './PhoneInput.module.css';
import 'react-phone-number-input/style.css';

import CountrySelect from '../CountrySelect';
import { FocusEventHandler } from 'react';

const inputClassName = "phoneInput"
const selector = `[class*="${inputClassName}"]`

const PhoneInput = ({
	placeholder,
	value,
	onChange,
	errorMessage,
	label,
	className,
	onFocus,
	onBlur,
	disabled,
	keepMarginBottom = false,
}: IPhoneInputComponentProps) => {
	const inputId = useId();
	const [focused, setFocused] = useState(false);
	const { currentLocation } = useCurrentLocation();

	useEffect(() => {
		if (value?.length || !currentLocation.callingCode) return;
		onChange(currentLocation.callingCode);
	}, [currentLocation]);

	return (
		<>
			<InputLabel htmlFor={inputId} text={label} />
			<PhoneInputComponent
				disabled={disabled}
				id={inputId}
				placeholder={placeholder}
				defaultCountry={value?.length ? currentLocation.countryCode : undefined}
				value={value}
				international
				onChange={(val)=> {
					onChange(val ?? '')
				}}
				className={clsx(
					styles.phoneInput,
					focused && styles.phoneInput_focused,
					errorMessage && styles.input_error,
					disabled && styles.phoneInput_disabled,
					className,
					inputClassName,
				)}
				onFocus={(e) => {
					setFocused(true);
					onFocus && onFocus(e);
				}}
				onBlur={(e) => {
					setFocused(false);
					onBlur && onBlur(e);
				}}
				countrySelectComponent={(props) => (
					<CountrySelect
						{...props}
						// make selector full input width (not only parent button)
						width={document?.querySelector(selector)?.parentElement?.clientWidth || 700}
						labels={en}
					/>
				)}
			/>
			<InputErrorMessage keepMarginBottom={keepMarginBottom} text={errorMessage} />
		</>
	);
};

export interface IPhoneInputComponentProps {
	placeholder?: string;
	value: string;
	onChange: (value: string) => void;
	onFocus?: FocusEventHandler<HTMLElement>;
	onBlur?: FocusEventHandler<HTMLElement>;
	errorMessage?: string;
	label?: string;
	className?: string;
	disabled?: boolean;
	keepMarginBottom?: boolean;
}

export default PhoneInput;
