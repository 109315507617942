import { appRouter } from '../../app/appRouter';
import { ROUTES } from '@constants';
import type { IMixPanelAnalyticsService } from '../interfaces/AnalyticsService.MixPanel.interface';
import type { IRouterService } from '../interfaces/RouterService.interface';
import type { Router } from '@remix-run/router';
import type { IDevLogger } from '../interfaces/DevLogger.interface';

export class RouterService implements IRouterService {
	static inject = ['AnalyticsService', 'logger'];
	constructor(
		private readonly analytics: IMixPanelAnalyticsService,
		logger: IDevLogger,
		private readonly router: Router = appRouter,
	) {
		this.logger = logger.child('RouterService');
		this.navigate = this.router.navigate;

		this.trackRouteChanged(this.router.state); // track initial route.
		this.router.subscribe((routeState) => this.trackRouteChanged(routeState)); // track further routes.
	}

	private logger: IDevLogger;
	navigate: Router['navigate'];

	private trackRouteChanged(routerState: Router['state']) {
		this.logger.debug('trackRouteChanged', routerState.location);
		this.analytics.trackEvent('ScreenOpened', {
			screen_name: routerState.location.pathname,
		});
	}

	get origin() {
		return window.location.origin;
	}

	handleUserUnauthorized() {
		// For the third party form we need to show error on page instead of unauthorize user.
		if (window.location.pathname.startsWith('/third-party-deal')) {
			return;
		}

		this.router.navigate(ROUTES.signOut());
	}
}
