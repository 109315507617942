import { useService } from '@services';
import { usePaginatedInfiniteQuery, useRef, useTranslation } from '@hooks';
import { useDealsSortSearchParams } from './useDealsSortSearchParams';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import { dayjs, getDealSortOptions } from '@utils';
import type { TDeal, TPaginatedList } from '@typings';
import type { AxiosError } from 'axios';

export const useGetMyDeals = () => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();
	const reactQuery = useService('ReactQueryService');

	const [selectedSortOption, setSelectedSortOption] = useDealsSortSearchParams({ field: 'updatedAt', order: 'desc' });
	const sortOptions = useRef(getDealSortOptions('my')(t)).current;

	const query = usePaginatedInfiniteQuery<TDeal, TPaginatedList<TDeal>, AxiosError>(
		reactQuery.queryKeys.getMyDeals(selectedSortOption),
		async ({ pageParam }) => api.invest.getMyDeals({ page: pageParam, sort: selectedSortOption }),
		{
			enabled: !isWaiverLoading && !isMessageShown,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
			staleTime: dayjs.duration(1, 'minute').asMilliseconds(),
		},
	);

	return {
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		...query,
	};
};
