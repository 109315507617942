import { useService, useTranslation } from '@hooks';
import type { TCommunityCompact, TProfile } from '@typings';
import { Card, CommunityCard } from '@ui-kit';
import { useProfileCommunitiesQuery } from '@ui-modules/communities/hooks/useProfileCommunitiesQuery';
import { translateMembers, translateGroups } from '@utils';
import styles from './ProfileGroups.module.css';

const ProfileGroups = ({ isInvisible, profileId, isMe, onPressGroup }: IProfileGroupsProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { data: groups = [] } = useProfileCommunitiesQuery(profileId);
	const groupsInvisible = !groups?.length && !isMe;

	return isInvisible || groupsInvisible ? null : (
		<Card articleMode="transparent" backgroundPlacement="article" title={t('My Groups')}>
			<div className={styles.groups__container}>
				{groups.map((group) => (
					<CommunityCard
						community={group}
						formatGroupsCount={translateGroups(t)}
						formatMembersCount={translateMembers(t)}
						key={group.id}
						style={styles.group__card}
						onClick={(community) => {
							onPressGroup(community);
							analytics.trackEvent('ProfileInteractions', {
								interaction_type: 'Groups clicked',
								action_value: group.name,
							});
						}}
					/>
				))}
			</div>
		</Card>
	);
};

export interface IProfileGroupsProps {
	isInvisible?: boolean;
	isMe: boolean;
	profileId: TProfile['id'];
	onPressGroup: (groupId: TCommunityCompact) => void;
}

export default ProfileGroups;
