import { Card, CollapsibleText, SpacedGroup, UserListItem } from '@ui-kit';
import { useNavigate, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import styles from './EventSpeakersDetails.module.css';
import type { TEvent, TEventSpeaker, TUserProfileRecord } from '@typings';

const EventSpeakersDetails = ({ event }: IEventSpeakersDetailsProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const openSpeakerProfile = (speaker: TEventSpeaker) => {
		const speakerProfileId = speaker.account?.profile?.id;
		if (speakerProfileId) {
			navigate(ROUTES.memberProfile(speakerProfileId));
		}
	};

	return (
		<Card backgroundPlacement="none" title={t('Speakers')}>
			<SpacedGroup direction="vertical" gap={12}>
				{event.speakers?.map((speaker) => {
					const [firstName, lastName] = speaker.name.split(' ');
					return (
						<div key={speaker.id}>
							<UserListItem
								className={styles.eventSpeakersDetails__userItem}
								isBackgroundTransparent
								renderUserSubTitle={() => speaker.presentationName}
								size="small"
								userProfileRecord={
									{
										firstName,
										lastName,
										id: speaker.id,
										avatar: speaker.avatar && { contentUrl: speaker.avatar.contentUrl },
										profile: {
											'@type': speaker.account?.profile?.['@type'],
											id: speaker.account?.profile?.id,
										},
									} as TUserProfileRecord
								}
								onClick={() => openSpeakerProfile(speaker)}
							/>
							{!!speaker.biography && (
								<CollapsibleText collapsedTitle={t('Read more')} expandedTitle={t('Show less')}>
									{speaker.biography}
								</CollapsibleText>
							)}
						</div>
					);
				})}
			</SpacedGroup>
		</Card>
	);
};

export interface IEventSpeakersDetailsProps {
	event: TEvent;
}

export default EventSpeakersDetails;
