import { useTranslation } from '@hooks';
import { FormSection, CheckboxNestedInput, CheckboxInput, Gap, InputErrorMessage } from '@ui-kit';
import type { TCommunity } from '@typings';
import type { FormikProps } from 'formik';
import { clsx, NetworkConnectionEntity } from '@utils';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import styles from './CategorySection.module.css';

const CategorySection = ({ formProps }: ICategorySectionProps) => {
	const { t } = useTranslation();
	const { setFieldValue, setFieldError, values, errors } = formProps;

	if (!values.categories?.length) return null;

	return (
		<FormSection title={t('Category')}>
			<div className={clsx(errors.categories && styles.input_error)}>
				<span className={styles.subtitle}>{t('(select all that apply)')}</span>
				<CheckboxNestedInput
					checkMarks={NetworkConnectionEntity.sortCategoriesByLabel(values.categories)}
					childrenDefaultValue={false}
					receiveChanges={(checkmark) => {
						setFieldValue(connectionFormFields.CATEGORIES, checkmark);
						setFieldError(connectionFormFields.CATEGORIES, undefined);
					}}
					renderChild={({ child, handleChildPress }) => (
						<CheckboxInput
							filterCheckbox
							label={child.label}
							value={child.value}
							onChange={() => handleChildPress(child.id)}
						/>
					)}
				/>
				{errors.categories ? <InputErrorMessage text={errors.categories as string} /> : <Gap gap={24} />}
			</div>
		</FormSection>
	);
};

interface ICategorySectionProps {
	formProps: FormikProps<TNetworkConnectionFormValues>;
	communityId: TCommunity['id'];
}

export default CategorySection;
