import { ActivityIndicator, CloseIcon } from '@ui-kit';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import EventItem from '@ui-modules/events/components/EventItem';
import { useNavigate, useTranslation, useChat } from '@hooks';
import { ROUTES } from '@constants';
import { clsx } from '@utils';
import styles from './ChatEventMessage.module.css';
import { useMessageContext } from 'stream-chat-react';
import type { TEvent } from '@typings';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';

/** Downloads event by ID and displays as a card. */
const ChatEventMessage = ({ eventId, isRemovable = false, cid }: IChatEventTypeMessageProps) => {
	const { data: event, isLoading, isFetched } = useGetEventQuery(eventId);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { isMyMessage } = useMessageContext();
	const { selectedEvent, setSelectedEvent } = useChat();
	const { updateMessageDraft } = useDraftAPI(cid as string);

	const onEventMessagePressed = (event: TEvent) => {
		if (event['@type'] === 'Event') {
			navigate(ROUTES.viewEvent(event.id));
		} else if (event['@type'] === 'Meeting') {
			navigate(ROUTES.viewMeeting(event.id));
		}
	};

	if (isLoading && !isFetched) {
		return (
			<div className={styles.eventLoading}>
				<ActivityIndicator size="small" type="fit" />
			</div>
		);
	}
	if (event)
		return (
			<>
				<div className={styles.event}>
					{selectedEvent?.id === eventId ? (
						<EventItem
							className={styles.event__item}
							event={event}
							eventTypeClassName={styles.eventMessage__type}
							hideBanner
							hideChevronIcon
							shouldShowMeetingAction={false}
							onClick={() => {
								onEventMessagePressed(event);
							}}
						/>
					) : (
						<EventItem
							event={event}
							eventTypeClassName={styles.eventMessage__type}
							hideBanner
							hideChevronIcon
							shouldShowMeetingAction={false}
							onClick={() => {
								onEventMessagePressed(event);
							}}
						/>
					)}
					{isRemovable && (
						<button
							className={styles.buttonRemove}
							onClick={() => {
								setSelectedEvent(null);
								updateMessageDraft({ selectedEvent: null });
							}}
						>
							<CloseIcon height={8} width={8} />
						</button>
					)}
				</div>
			</>
		);
	else
		return (
			<div
				className={clsx(
					styles.eventMessage__hiddenMessageBox,
					isMyMessage()
						? styles.eventMessage__hiddenMessageBox_myMessage
						: styles.eventMessage__hiddenMessageBox_othersMessage,
				)}
			>
				<span className={styles.eventMessage__hiddenMessage}>{t('This private event/meeting can not be viewed')}</span>
			</div>
		);
};
export interface IChatEventTypeMessageProps {
	eventId: string | undefined;
	isRemovable?: boolean;
	cid?: string;
}

export default ChatEventMessage;
