import type { ControlProps, GroupBase, OptionProps } from 'react-select';
import Select, { components } from 'react-select';
import { DropdownIcon, CloseIcon, InputDescription, SearchIcon } from '@ui-kit';
import InputLabel from '../InputLabel';
import InputErrorMessage from '../InputErrorMessage';
import { useMemo, useId } from '@hooks';
import { clsx, toString } from '@utils';
import './DropdownMenu.css';
import styles from './DropdownMenu.module.css';
import type { ReactNode } from 'react';
import type { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import type { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

/** Dropdown select input which allows to choose one of the option. Use as generic to point more strong type of the value. */
const DropdownMenu = <TValue extends TDefaultValue = TDefaultValue>({
	value,
	label,
	options,
	disabled = false,
	placeholder = '',
	placeholderColor,
	errorMessage,
	name,
	description,
	IconComponent = null,
	filterOption = (option, inputValue) => toString(option.data.title).toLowerCase().includes(inputValue.toLowerCase()),
	onChange,
	onBlur,
	variant = 'small',
	iconType = 'select',
	bottomBorderedStyle = false,
	nonOptionValueDisplay = false,
	renderOption,
	onSearch,
	isSearchable = true,
	customDescription,
	customError,
	showSearchIcon,
	labelClassName,
	...rest
}: IDropdownMenuProps<TValue>) => {
	const inputId = useId();
	const matchOption = options?.find((option) => option.value === value);
	const selectedOption = options
		? matchOption || (nonOptionValueDisplay ? ({ value, title: value } as TDropdownOption<TValue>) : null)
		: undefined;

	// Should be memoized to avoid remounting issues (see https://react-select.com/components#defining-components).
	const Control = useMemo(
		() =>
			function CustomControl({
				children,
				...rest
			}: ControlProps<TDropdownOption<TValue>, false, GroupBase<TDropdownOption<TValue>>>) {
				return (
					<components.Control {...rest}>
						<div className={styles.dropdownMenu__iconComponentWrapper}>{IconComponent}</div> {children}
					</components.Control>
				);
			},
		[],
	);

	return (
		<div>
			<InputLabel className={labelClassName} htmlFor={inputId} text={label} />
			<Select<TDropdownOption<TValue>>
				className={clsx(
					errorMessage && 'react-select--is-errored',
					variant === 'medium' && 'react-select--medium',
					variant === 'large' && 'react-select--large',
					bottomBorderedStyle && 'react-select--bottom-border',
					!!errorMessage && styles.input_error,
				)}
				classNamePrefix="react-select"
				closeMenuOnScroll
				components={{
					IndicatorSeparator: null,
					DropdownIndicator: (props) =>
						!selectedOption && showSearchIcon ? (
							<components.DropdownIndicator {...props}>
								<SearchIcon className={styles.input__iconSvg_search} height={12} width={12} />
							</components.DropdownIndicator>
						) : iconType === 'select' && options?.length ? (
							<components.DropdownIndicator {...props}>
								<DropdownIcon className="react-select__close-icon" height={12} width={8} />
							</components.DropdownIndicator>
						) : null,
					ClearIndicator: (props) =>
						iconType === 'clear' && options?.length ? (
							<components.ClearIndicator {...props}>
								<CloseIcon className="react-select__close-icon" fill="black" height={10} width={10} />
							</components.ClearIndicator>
						) : null,
					Option: (props) => {
						return renderOption ? (
							renderOption(props)
						) : (
							<components.Option {...props}>
								<div>
									<span className="select__optionLabel">{props.data.title}</span>
								</div>
								<div>
									<span className="react-select__optionSubTitle">{props.data.subtitle}</span>
								</div>
							</components.Option>
						);
					},
					Placeholder: (props) =>
						placeholder ? (
							<components.Placeholder {...props}>
								<span className="react-select__placeholder" style={{ color: placeholderColor }}>
									{placeholder}
								</span>
							</components.Placeholder>
						) : null,
					...(IconComponent && { Control }),
				}}
				filterOption={filterOption}
				formatOptionLabel={(data) => data.title}
				inputId={inputId}
				isClearable
				isDisabled={disabled}
				isOptionDisabled={(option) => option.disabled || false}
				isSearchable={isSearchable}
				name={name}
				options={options}
				value={selectedOption}
				onBlur={onBlur}
				onChange={(option) => onChange(option?.value as TValue, option as TDropdownOption<TValue>)}
				onInputChange={(value) => onSearch?.(value)}
				{...rest}
			/>
			{customDescription || <InputDescription text={description} />}
			{errorMessage ? customError || <InputErrorMessage text={errorMessage} /> : null}
		</div>
	);
};

type TDefaultValue = number | string | null | undefined;
export type TDropdownOption<TValue extends TDefaultValue = TDefaultValue> = {
	value: TValue;
	title: string;
	subtitle?: string;
	disabled?: boolean;
};
export interface IDropdownMenuProps<TValue extends TDefaultValue = TDefaultValue> {
	/** Selected value. */
	value: TDropdownOption<TValue>['value'] | undefined;
	/** List of options. */
	options: TDropdownOption<TValue>[];
	/** Text label displayed above an input. */
	label?: string;
	/** Text placeholder displayed when an input is empty. */
	placeholder?: string;
	/** Custom color for the placeholder text. */
	placeholderColor?: string;
	/** The form name of the field which can be used as a selector for tests. */
	name?: string;
	/** Does the input is unavailable for selecting. */
	disabled?: boolean;
	/** The text of error displayed below an input. */
	errorMessage?: string;
	/** Help text displayed between input and error message. */
	description?: string;
	/** Callback returning a new value when it was chosen. */
	onChange: (chosenOptionValue: TValue, option: TDropdownOption<TValue>) => void;
	/** Blur event callback  */
	onBlur?: StateManagerProps['onBlur'];
	/** Size of dropdown  */
	variant?: 'small' | 'medium' | 'large';
	/** Dropdown icon type  */
	iconType?: 'select' | 'clear';
	/** Custom variant of Option component */
	renderOption?: (
		props: OptionProps<TDropdownOption<TValue>, false, GroupBase<TDropdownOption<TValue>>>,
	) => JSX.Element;
	/** Opens menu on click */
	openMenuOnClick?: boolean;
	/** Opens menu on focus */
	openMenuOnFocus?: boolean;
	/** Search Input handler */
	onSearch?: (value: string) => void;
	/** Icon/image component showing before the input. Can be search icon or something similar. */
	IconComponent?: ReactNode;
	/** Set dropdown searchable */
	isSearchable?: boolean;
	/** Custom style where dropdown have only bottom border */
	bottomBorderedStyle?: boolean;
	// Should display value if it not matched options array
	nonOptionValueDisplay?: boolean;
	/** Custom react-select filter options function. */
	filterOption?: ((option: FilterOptionOption<TDropdownOption<TValue>>, inputValue: string) => boolean) | null;
	customDescription?: ReactNode;
	customError?: ReactNode;
	/** Display search Icon if no value selected */
	showSearchIcon?: boolean;
	/** Focus the input when it is mounted */
	autoFocus?: boolean;
	/** Style for the input label */
	labelClassName?: string;
}

export default DropdownMenu;
