import { memo } from 'react';
import { When } from '@ui-kit';
import { useTranslation } from '@hooks';
import SpecificUserList from '@ui-modules/contacts/components/SpecificUserList';
import styles from './NetworkRequestView.module.css';
import type { TNetworkCreationRequest, TProfile, TUserProfileRecord } from '@typings';

/** Renders data of the network request including name, purpose and lists with members & leaders. */
const NetworkRequestView = ({ networkRequest, onUserClick }: INetworkRequestViewProps) => {
	const { t } = useTranslation();

	const leaderProfileIds = mapProfileIds(networkRequest?.leaders);

	return (
		<div>
			<div className={styles.networkRequestView__dataBox}>
				<h3>{t('Network Topic')}</h3>
				<div>
					<span>{networkRequest.name}</span>
				</div>

				<h3>{t('Purpose of the Network')}</h3>
				<div>
					<span>{networkRequest.purpose}</span>
				</div>

				<When condition={!!leaderProfileIds.length}>
					<h3>{t('Network Leader')}</h3>
					<SpecificUserList
						profileIds={leaderProfileIds}
						onClick={(profileWithUser) => onUserClick(profileWithUser.id)}
					/>
				</When>

				<h3>{t('Your background or expertise')}</h3>
				<div>
					<span>{networkRequest.background}</span>
				</div>
			</div>
		</div>
	);
};

const mapProfileIds = (userProfileRecords: TUserProfileRecord[] = []) =>
	userProfileRecords.map((member) => member.profile.id) ?? [];

export interface INetworkRequestViewProps {
	networkRequest: TNetworkCreationRequest;
	onUserClick: (profileId: TProfile['id']) => void;
}

export default memo(NetworkRequestView);
