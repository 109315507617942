import { useMutation, useNotification, useService, useState, useTranslation } from '@hooks';
import { useOktaVerifyState } from './useOktaVerifyState';
import { OktaAuthenticatorEnrollRequiredError } from '../../../services/implementations/OktaService/errors/OktaAuthenticatorEnrollRequiredError';
import { translateOktaUnsuitableResponse } from '../utils/translateOktaUnsuitableResponse';
import { translateFactorType } from '../utils/translateFactorType';
import type { AuthenticatorKey, IdxTransaction } from '@okta/okta-auth-js';
import type { OktaError } from '../../../services/implementations/OktaService';
import type { TVerifySignInCodeForm } from '@tiger21-llc/connect-shared/src/schemas/VerifySignInCodeForm.schema';
import type { TOktaVerificationFlow, TVerificationResult } from '../../../services/interfaces/OktaService.interface';
import type { IModalProps } from '@ui-kit';

const CLOSED_MODAL_PROPS: IModalProps = { visible: false };

/** Handles MFA OKTA authorization after sign in or recover password flows. */
export const useOktaVerify = (
	flow: TOktaVerificationFlow | undefined,
	{ onError, onSetNewPasswordRequired, onNextVerificationRequired, onCancelVerification }: IOktaVerifyCallbacks,
) => {
	const okta = useService('OktaService');
	const { t } = useTranslation();
	const { showError, showSuccess } = useNotification();

	const [modalProps, setModalProps] = useState<IModalProps>(CLOSED_MODAL_PROPS);

	const [
		{ availableFactorTypes = [], currentFactorType, currentAuthenticator, currentExpirationDate },
		{ setIdxTransaction },
		{ selectFactorTypeExpirationDate, selectCurrentAuthenticatorStartedWhenExpired },
		{ getPrioritizedFactorTypes },
	] = useOktaVerifyState();
	const currentAuthenticatorStartedWhenExpired = selectCurrentAuthenticatorStartedWhenExpired();

	const initializeVerification = async () => {
		const idxTransaction = await okta.getCurrentTransaction();
		if (!idxTransaction) return onError(new Error('No transaction found'));

		const availableFactorTypes = getPrioritizedFactorTypes(idxTransaction);

		if (availableFactorTypes.length) {
			beginVerification(availableFactorTypes[0]);
		} else {
			window.open(okta.getOktaIssuerUrl(), '_blank'); // If there is no factor type which can be handled, open okta.
		}
	};

	const beginVerification = async (factorType: AuthenticatorKey) => {
		const nextFactorExpirationDate = selectFactorTypeExpirationDate(factorType);
		const expirationDatePassed =
			!nextFactorExpirationDate || new Date().getTime() - nextFactorExpirationDate.getTime() > 0;

		const idxTransaction = await startVerification(factorType);
		if (expirationDatePassed) {
			showSuccess({
				title: t('Authentication code sent'),
				subtitle: t('Check your {{authenticatorChannel}}.', {
					authenticatorChannel: translateFactorType(t)(factorType)?.toLowerCase?.(),
				}),
			});
		}

		return idxTransaction;
	};

	const { mutateAsync: startVerification, isLoading: isStarting } = useMutation<
		IdxTransaction,
		Error,
		AuthenticatorKey
	>(['okta.startVerification'], async (factorType) => await okta.startVerification(factorType), {
		onSuccess(idxTransaction, factorType) {
			setIdxTransaction({ idxTransaction, factorType });
		},
		onError(error) {
			return onError(error);
		},
	});

	const { mutateAsync: restartVerification, isLoading: isRestarting } = useMutation<IdxTransaction, OktaError>(
		['okta.restartVerification'],
		async () => await okta.startVerification(currentFactorType as AuthenticatorKey),
		{
			onSuccess(idxTransaction) {
				setIdxTransaction({ idxTransaction, factorType: currentFactorType });
			},
			onError(error) {
				const message = translateOktaUnsuitableResponse(t)(error);
				message && showError(message);
			},
		},
	);

	const { mutateAsync: finishVerification, isLoading: isSubmitting } = useMutation<
		TVerificationResult,
		Error | OktaError,
		TVerifySignInCodeForm
	>(['okta.finishVerification'], async ({ code }) => await okta.finishVerification(code, flow), {
		onSuccess(verificationStatus) {
			if (verificationStatus === 'new-password-required') onSetNewPasswordRequired();
			else if (verificationStatus === 'next-verification-required') onNextVerificationRequired();
			else if (verificationStatus === 'authenticator-enroll-required') {
				const errorMessage = new OktaAuthenticatorEnrollRequiredError().getMessage(t);
				const modal: IModalProps = {
					visible: true,
					title: errorMessage.title,
					modalContent: errorMessage.subtitle,
					confirmTitle: t('Proceed'),
					onConfirm: () => {
						setModalProps(CLOSED_MODAL_PROPS);
						onCancelVerification();
						window.location.href = okta.getOktaIssuerUrl();
					},
					cancelTitle: t('Cancel'),
					onCancel: () => {
						onCancelVerification();
						setModalProps(CLOSED_MODAL_PROPS);
					},
				};
				setModalProps(modal);
			}
		},
		onError(error) {
			const message = translateOktaUnsuitableResponse(t)(error);
			message && showError(message);
		},
	});

	const cancelVerification = () => {
		okta.cancelTransaction();
	};

	return {
		currentFactor: currentFactorType,
		currentAuthenticator,
		expirationDate: currentExpirationDate,
		availableFactorTypes,
		isStarting,
		isRestarting,
		isSubmitting,
		currentAuthenticatorStartedWhenExpired,
		initializeVerification,
		beginVerification,
		startVerification,
		cancelVerification,
		restartVerification,
		finishVerification,
		modalProps,
	};
};

interface IOktaVerifyCallbacks {
	onError: (error: Error) => void;
	onSetNewPasswordRequired: () => void;
	onNextVerificationRequired: () => void;
	onCancelVerification: () => void;
}
