import { useTranslation, useEffect, useNotification, useService } from '@hooks';
import { DealEntity } from '@utils';
import type { TDeal } from '@typings';

export const useNotifyOnDealClosedSoon = (deal: TDeal | undefined) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const { DEAL_CLOSING_DATE_LIMIT } = useService('AppConfigService');

	useEffect(() => {
		if (deal && DealEntity.shouldShowDealClosingSoon(deal, DEAL_CLOSING_DATE_LIMIT)) {
			showInfo({ title: t('Deal closing soon') });
		}
	}, [deal?.id]);
};
