import { useFormikContext } from 'formik';
import { Checkbox, SpacedGroup } from '@ui-kit';
import { useMe, useService } from '@hooks';
import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import { addOrRemoveArrayElement } from '@utils';
import { useCreateNotificationSetting } from '../../hooks/useCreateNotificationSettings';
import { useUpdateNotificationSettings } from '../../hooks/useUpdateNotificationSettings';
import styles from './OptionsBlock.module.css';
import type { TSupportedChannel } from '@typings';

const OptionsBlock = ({
	title,
	options,
	notificationId,
	isCustom,
	isOpened,
	hasPushSettings,
	groupName,
	creationParams = {},
}: IOptionsBlock) => {
	const analytics = useService('AnalyticsService');
	const { values, setFieldValue } = useFormikContext<{
		notificationId: string;
		options: string[];
		id: string;
	}>();

	const { push, email } = NOTIFICATION_SETTING_CHANNEL;

	const { user } = useMe();
	const { mutate: createNotificationSetting, isLoading: isCreatingSetting } = useCreateNotificationSetting(groupName);
	const { mutate: updateSettings, isLoading: isPatchingSetting } = useUpdateNotificationSettings(groupName);

	const onOption = (key: string, value: typeof email | typeof push) => {
		const newOptions = addOrRemoveArrayElement(values.options, value);
		setFieldValue('notificationId', key);
		setFieldValue('options', newOptions);
		if (isCustom && values.id) {
			updateSettings({ id: values.id, notificationSetting: { channels: newOptions as TSupportedChannel[] } });
		} else {
			createNotificationSetting({
				notificationId,
				owner: user['@id'],
				channels: newOptions,
				constraint: creationParams.constraint ?? null,
				sourceId: creationParams.sourceId ?? null,
			});
		}
		analytics.trackEvent('AccountInteractions', {
			interaction_type: 'Notification',
			action: `settings changed to ${JSON.stringify(newOptions)}`,
		});
	};

	return isOpened ? (
		<div className={styles.optionsBlock}>
			<span>{title}</span>
			<SpacedGroup gap={25}>
				<div style={{ visibility: options.includes(email) ? 'visible' : 'hidden' }}>
					<Checkbox
						disabled={isCreatingSetting || isPatchingSetting}
						value={!!values?.options.find((opt) => opt === email)}
						onChange={() => {
							onOption(notificationId, email);
						}}
					/>
				</div>
				{hasPushSettings && (
					<div style={{ visibility: options.includes(push) ? 'visible' : 'hidden' }}>
						<Checkbox
							disabled={isCreatingSetting || isPatchingSetting}
							value={!!values?.options.find((opt) => opt === push)}
							onChange={() => {
								onOption(notificationId, push);
							}}
						/>
					</div>
				)}
			</SpacedGroup>
		</div>
	) : null;
};

interface IOptionsBlock {
	options: string[];
	title: string;
	notificationId: string;
	isCustom: boolean;
	isOpened: boolean;
	hasPushSettings?: boolean;
	groupName?: string;
	creationParams: { sourceId?: string; constraint?: string };
}
export default OptionsBlock;
