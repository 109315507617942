import type { TComment } from '@typings';
import { createContext, useState } from 'react';

export type TCommentEditingState = { comment?: TComment; reply?: boolean } | null;

export const CommentControlsEditingContext = createContext<
	[TCommentEditingState, (newVal: TCommentEditingState) => void]
>([null, () => null]);

/** Shares context of comment/reply which is currently editing.
 *  Specifically created to avoid waterfall updates in the component tree to fix T21C-7602,T21C-7603,T21C-7600
 */
export const CommentControlsEditingContextProvider = ({ children }: { children: React.ReactNode }) => {
	const state = useState<TCommentEditingState>(null);

	return <CommentControlsEditingContext.Provider value={state}>{children}</CommentControlsEditingContext.Provider>;
};
