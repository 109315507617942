import { Avatar } from '@ui-kit';
import type { TChatUser } from '@typings';
import { useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import { useMessageContext } from 'stream-chat-react';

const MessageAvatar = () => {
	const { message } = useMessageContext();
	const navigate = useNavigate();
	const user = message?.user as TChatUser;
	const isChair = user?.roles?.includes('ROLE_CHAIR') || false;
	const isInActive = user?.roles?.includes('ROLE_INACTIVE') || false;
	const onAvatarPressed = () => {
		if (isInActive) return;
		if (user.profileId) {
			navigate(ROUTES.memberProfile(user.profileId));
		}
	};
	return (
		<div onClick={onAvatarPressed} onKeyDown={onAvatarPressed}>
			<Avatar
				imageUri={message?.user?.avatar ? `${message?.user?.avatar}` : null}
				isInactive={isInActive}
				outline={isChair}
				size={25}
				title={message?.user?.name}
			/>
		</div>
	);
};

export default MessageAvatar;
