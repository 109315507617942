import type { TProfileWithUser } from '@typings';
import { findClosestSize } from '@ui-kit/components/Avatar/Avatar.utils';
import styles from './ProfilePhoto.module.css';
import { useGetProfileQuery, useService, useTranslation } from '@hooks';
import { Button, LocationIcon, RibbonFlag, ViewMemberIcon, When } from '@ui-kit';
import BlockUserToggleButton from '../BlockUserToggleButton';
import { formatFullName } from './ProfilePhoto.utils';
import type { TRole } from '@typings';
import clsx from 'clsx';
import { dayjs, formatUserSubTitle, translateUserRole, getAvatarInitials } from '@utils';

const isRibbonAbsolutePosition = (roles: TRole[]) => {
	return roles.includes('ROLE_STAFF');
};

const ProfilePhoto = ({
	profile,
	isMe,
	canEdit,
	canImpersonate,
	onImpersonate,
	onEdit,
	onContactPress,
}: IProfilePhotoProps) => {
	const { t } = useTranslation();
	const { AVATAR_BASE_URI } = useService('AppConfigService');

	const closestSize = findClosestSize(164);
	const compiledTitle = getAvatarInitials(`${profile.user.firstName} ${profile.user.lastName}`);
	const fallbackUri = `${AVATAR_BASE_URI}/${compiledTitle}-${closestSize}.png`;
	const { isSectionInvisible } = useGetProfileQuery(profile.id);

	const shouldShowLocation = profile['@type'] === 'ProfileStaff' && profile.user.location;
	const shouldShowGroups = profile['@type'] === 'ProfileMember' || profile['@type'] === 'ProfileChair';

	const isChairProfile = profile.user.roles.includes('ROLE_CHAIR');

	return (
		<div className={styles.profile__user}>
			<img
				alt="user_avatar"
				className={styles.profile__avatar}
				src={profile.user.avatar?.contentUrl ?? fallbackUri}
				title={compiledTitle}
			/>
			<div className={isChairProfile ? styles.profile__chairInfo : styles.profile__userInfo}>
				<div className={styles.profile__userInfoTitle}>
					<span className={styles.profile__userName}>{formatFullName(profile.user)}</span>
					<div className={styles.profile__actionsBar}>
						<When condition={canEdit}>
							<Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} />
						</When>
						<When condition={canImpersonate}>
							<Button
								icon={<ViewMemberIcon fill="#000000" height={12} width={12} />}
								iconPosition="left"
								title={t('Impersonate')}
								variant="small"
								onClick={onImpersonate}
							/>
						</When>
					</div>
				</div>
				{profile.title ? <span className={styles.profile__userProfileTitle}>{profile.title}</span> : null}
				{shouldShowLocation ? (
					<div className={styles.profile__location}>
						<LocationIcon className={styles.profile__locationIcon} fill="black" height={14} width={14} />
						<span className={styles.profile__locationTitle}>{profile.user.location}</span>
					</div>
				) : null}
				{shouldShowGroups ? (
					<div className={styles.profile__groups}>
						<span>{formatUserSubTitle(t)({ ...profile.user, profile }, ' • ', 'shortNames')}</span>
					</div>
				) : null}

				<div
					className={clsx(
						styles.profile__ribbon,
						isRibbonAbsolutePosition(profile.user.roles) && styles.profile__ribbon_absolute,
					)}
				>
					<RibbonFlag title={translateUserRole(profile.user.roles, t)} />
					{isSectionInvisible('sinceInfo') || !profile.since ? null : (
						<span className={styles.sinceText}>{`${t('Since')} ${dayjs(new Date(profile.since)).year()}`}</span>
					)}
				</div>
				<div className={styles.profile__bottomButtonGroup}>
					{onContactPress ? (
						<div className={styles.profile__contact}>
							<Button title={t('Contact')} trackingName="profile--view-my-contact" onClick={onContactPress} />
						</div>
					) : null}
					<BlockUserToggleButton hidden={isMe || isSectionInvisible('blockUser')} userId={profile.user.id} />
				</div>
			</div>
		</div>
	);
};

export interface IProfilePhotoProps {
	profile: TProfileWithUser;
	canEdit: boolean;
	canImpersonate: boolean;
	isMe: boolean;
	onEdit: () => void;
	onImpersonate: () => void;
	onContactPress: undefined | (() => void);
}

export default ProfilePhoto;
