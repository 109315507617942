import { EntityInformationCard } from '@ui-kit';
import DealAlertsButton from '../DealAlertsButton';
import { useTranslation } from '@hooks';
import { capitalize, extractIdFromIri } from '@utils';
import { ROUTES } from '@constants';
import type { TDeal, TDealMemberRelation, TDealMemberRelationsStatus, TFullUser } from '@typings';
import DealRibbon from '../DealRibbon';

const MyDealCard = ({
	creator,
	creatorType,
	createdAt,
	assetName,
	dealName,
	dealStatus,
	dealMemberRelation,
	status,
	className,
	subtitleLink,
	hasPreview,
	canSeePreview,
	onPress,
	onToggleAlerts,
}: IMyDealCardProps) => {
	const { t } = useTranslation();

	return (
		<EntityInformationCard
			avatarUrl={creator.avatarUrl}
			className={className}
			communitiesForBadge={creator.communitiesForBadge?.shortNames}
			createdAt={createdAt}
			creatorName={`${creator.fullName} (${capitalize(creatorType)})`}
			FooterComponent={
				<>
					<DealRibbon
						canSeePreview={canSeePreview}
						dealStatus={dealStatus}
						hasPreview={hasPreview}
						isDraftDeal={status === 'draft' || status === 'review'}
						status={status}
					/>

					{onToggleAlerts && <DealAlertsButton dealMemberRelation={dealMemberRelation} onToggle={onToggleAlerts} />}
				</>
			}
			profileLink={ROUTES.memberProfile(extractIdFromIri(creator.profileIri))}
			subtitleAdditionalInfo={assetName}
			subtitleLink={subtitleLink}
			title={`${status === 'closed' ? t('CLOSED:') : status === 'cancelled' ? t('CANCELLED:') : ''}${dealName}`}
			onClick={onPress}
		/>
	);
};

interface IMyDealCardProps {
	creator: Pick<TFullUser, '@id' | 'firstName' | 'lastName' | 'id' | 'communitiesForBadge'> & {
		fullName: string;
		avatarUrl?: string;
		profileIri: string;
	};
	createdAt: string;
	creatorType: TDeal['creatorType'];
	assetName: string;
	dealName?: string;
	dealStatus?: TDealMemberRelationsStatus;
	dealMemberRelation?: TDealMemberRelation | undefined;
	status: TDeal['status'];
	className?: string;
	subtitleLink?: string;
	withArrow?: boolean;
	avatarSize?: number;
	hasPreview?: boolean;
	canSeePreview: boolean;
	onPress?: () => void;
	onToggleAlerts?: (data: { relationId: string; newPostAlerts: boolean }) => void;
}

export default MyDealCard;
