import { useIsWindowTooSmall, useService, useShowPageInMobile, useTranslation } from '@hooks';
import { DownloadMobileAppOverlay } from '../DownloadMobileAppOverlay';

/** Must prevent access the whole application itself to prevent using app when:
 * 	- small width because of UI-design restrains.
 *  - when the APP_HIDDEN environment variable is set to true.
 */
const SmallScreenOverlay = () => {
	const { APP_HIDDEN } = useService('AppConfigService');
	const isWindowSmall = useIsWindowTooSmall();
	const { showMobilePage } = useShowPageInMobile();

	const { t } = useTranslation();

	return !showMobilePage && (isWindowSmall || APP_HIDDEN) ? (
		<DownloadMobileAppOverlay title={t('Please download the T21 Connect app for the best experience.')} />
	) : null;
};

export default SmallScreenOverlay;
