import { Outlet, Page, PageLayout } from '../../components';
import { useTranslation, useState, useNavigate, useChat, useEffect } from '@hooks';
import ChatChannelList from '@ui-modules/chat/components/ChatChannelList';
import { useChatEvents } from '@ui-modules/chat/hooks/useChatEvents';
import { SearchableScreenHeader, Button, EditIcon, Backdrop } from '@ui-kit';
import styles from './ChatPage.module.css';

import { ROUTES } from '@constants';

const ChatPage = () => {
	const { t } = useTranslation();
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const { setSelectedUserIds, setSelectedChannel, setShouldUpdateChannel } = useChat();

	const navigate = useNavigate();
	const { isUserPaused } = useChatEvents();

	const onNewChatPressed = () => {
		setShouldUpdateChannel(false);
		setSelectedUserIds(null);
		navigate(ROUTES.createChat());
	};

	useEffect(() => {
		return () => {
			setSelectedChannel(null);
		};
	}, []);

	return (
		<Page title={t('Chat')}>
			{isUserPaused ? (
				<div className={styles.chat__memberPauseTextContainer}>
					{t('Chat has been temporarily disabled by the administrators')}
				</div>
			) : (
				<PageLayout
					containerMode={false}
					sidebarContent={
						<div className={styles.chat__container}>
							<SearchableScreenHeader
								customButtonsElement={
									<>
										<Button
											icon={<EditIcon fill="#000000" height={14} width={14} />}
											title={t('New Chat')}
											variant="small"
											onClick={onNewChatPressed}
										/>
									</>
								}
								placeholder={t('Search chats')}
								searchQuery={searchQuery}
								searchTitle={t('Search')}
								title={t('Chat')}
								onBlur={() => setIsSearchActive(false)}
								onChangeSearchQuery={setSearchQuery}
								onFocus={() => setIsSearchActive(true)}
							/>
							<ChatChannelList filterQuery={searchQuery} />
						</div>
					}
				>
					<Outlet /> {/* ChatChannelPage is rendered here */}
					<Backdrop hidden={!isSearchActive} />
				</PageLayout>
			)}
		</Page>
	);
};

export interface IChatPageParams {
	id: string;
}

export default ChatPage;
