import BlockUserToggleButton from '../BlockUserToggleButton';
import { useBlockedAndBlockingUsers, useTranslation, useMemo } from '@hooks';
import styles from './BlockedUserMessage.module.css';
import type { TUser } from '@typings';
import type { PropsWithChildren } from 'react';

const BlockedUserMessage = ({
	userId,
	blockedTitle,
	blockedDescription,
	blockingDescription,
	children,
}: IBlockedUserMessageProps) => {
	const { t } = useTranslation();
	const { blockedUserRelations, usersBlockingMeRelations } = useBlockedAndBlockingUsers();

	const maybeBlockedUser = useMemo(
		() => blockedUserRelations.find((blockedUser) => blockedUser.blockedUser.id === userId),
		[blockedUserRelations, userId],
	);
	const isBlocked = !!maybeBlockedUser;

	const maybeBlockingUser = useMemo(
		() => usersBlockingMeRelations.find((blockedUser) => blockedUser.owner.id === userId),
		[usersBlockingMeRelations, userId],
	);
	const isBlocking = !!maybeBlockingUser;

	if (isBlocked)
		return (
			<div className={styles.blockedUserMessage__box}>
				<div className={styles.blockedUserMessage__body}>
					<p>
						<b>
							{blockedTitle ??
								t('You blocked {{userName}}', {
									userName: `${maybeBlockedUser.blockedUser.firstName} ${maybeBlockedUser.blockedUser.lastName}`,
								})}
						</b>
					</p>
					<p>{blockedDescription}</p>
					<BlockUserToggleButton userId={userId} />
				</div>
			</div>
		);
	else if (isBlocking)
		return (
			<div className={styles.blockedUserMessage__box}>
				<div className={styles.blockedUserMessage__body}>
					<p>{blockingDescription}</p>
				</div>
			</div>
		);
	else return <>{children}</>;
};

export interface IBlockedUserMessageProps extends PropsWithChildren {
	/** The user id of the user who can be blocked. */
	userId: TUser['id'];
	/** The title of the message if you have blocked the user. Default 'You blocked {{userName}}' */
	blockedTitle?: string;
	/** The description of the message if you have blocked the user. */
	blockedDescription: string;
	/** The description of the message if the user has blocked you. */
	blockingDescription: string;
}

export default BlockedUserMessage;
