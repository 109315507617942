import { useState, useEffect } from 'react';
import type { SetStateAction } from 'react';

import { CheckboxInput } from '@ui-kit';

import styles from './CheckboxNestedInput.module.css';

import type { ICheckmarkState } from './CheckboxNestedInput';

const CheckBoxParent = ({
	label,
	value,
	id,
	disabled,
	checkMarksState,
	handleParentPress,
	setCheckMarksState,
	linkPressPayload,
	showHorizontalRule,
	handleLinkPress,
	isCheckMarkLink,
	allChildSelected,
}: ICheckBoxParent) => {
	const [allSelected, setAllSelected] = useState(false);
	const [partiallySelected, setPartiallySelected] = useState(false);

	useEffect(() => {
		// When the checkMarksState changes, update the state of allSelected and partiallySelected
		let someChecked;
		for (const element of checkMarksState) {
			if (element.id === id) {
				someChecked = element.children?.some((item) => item.value);
			}
		}

		let allChecked;
		for (const element of checkMarksState) {
			if (element.id === id) {
				allChecked = element.children?.every((item) => item.value);
			}
		}
		setAllSelected(allChecked as boolean);
		setPartiallySelected(someChecked as boolean);
	}, [checkMarksState, id]);

	return (
		<div className={styles.parentWrapper}>
			<CheckboxInput
				disabled={disabled}
				filterCheckbox
				label={label}
				labelContainerStyles={styles.labelContainerStyles}
				labelPostfix={isCheckMarkLink ? ' →' : ''}
				labelPostfixStyles={(isCheckMarkLink && styles.linkInput_postfix) || ''}
				labelStyles={(isCheckMarkLink && styles.linkInput) || ''}
				showHorizontalRule={!allChildSelected && (showHorizontalRule || (partiallySelected && !allSelected))}
				value={value || allChildSelected}
				onChange={() => (isCheckMarkLink ? handleLinkPress?.(id as string) : handleParentPress(id))}
				onLabelClick={() => (isCheckMarkLink ? handleLinkPress?.(id as string) : null)}
			/>
		</div>
	);
};

export interface ICheckBoxParent extends ICheckmarkState {
	handleParentPress: (label: string) => void;
	checkMarksState: ICheckmarkState[];
	isCheckMarkLink: boolean;
	allChildSelected: boolean;
	handleLinkPress?: (linkPressPayload: string) => void;
	linkPressPayload?: string;
	disabled?: boolean;
	showHorizontalRule?: boolean;
	setCheckMarksState: React.Dispatch<SetStateAction<ICheckmarkState[]>>;
}

export default CheckBoxParent;
