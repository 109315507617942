import { useService, useMutation, useNotification } from '@hooks';
import useAllDealFilters from './useAllDealFiltersQuery';
import type { TDealFilter } from '@typings';

const useDealFiltersApi = (id?: string | null, shouldRefetchOnSuccess = true) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const reactQuery = useService('ReactQueryService');
	const { data: allDealFilters } = useAllDealFilters();
	const allDealsFilterId = allDealFilters?.[0]?.id;

	const { mutateAsync: patchDealFilters, isLoading: isPatchLoading } = useMutation(
		['deal.patchDealFilter'],
		(data: Partial<TDealFilter>) => {
			return api.invest.patchDealFilter(String(id || allDealsFilterId), data);
		},
		{
			onSuccess: () => {
				if (shouldRefetchOnSuccess) {
					reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getMyInvestNetworks());
					reactQuery.queryClient.refetchQueries(['community.getForeignInvestNetworks']);
					reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getAllDealFilters());
					reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getAllDealFilterConfig());
					reactQuery.queryClient.refetchQueries(['dealFilter.viaCommunityId']);
					reactQuery.refetchDealsQueries(String(id || allDealsFilterId));
				}
			},
			onError: (error: Error) => {
				showUnknownError(error);
			},
		},
	);

	return {
		patchDealFilters,
		isPatchLoading,
	};
};

export default useDealFiltersApi;
