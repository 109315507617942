import { useCallback, useMe, useEffect } from '@hooks';
import { useService } from '../../../services/setup/ServicesContext';
import { useOpenNotification } from './useOpenNotification';
import type { IFeedListenerParams } from '../../../services/interfaces/FeedService.interface';
import NotificationToast from '../components/NotificationToast';

let allowShowNotification = true;
let isListenerSet = false;

export const useNotificationToast = () => {
	const toast = useService('ToastService');
	const feed = useService('FeedService');
	const analytics = useService('AnalyticsService');
	const { user } = useMe();

	const onClickNotification = useOpenNotification();

	const listener = useCallback(
		function showNotification(data: IFeedListenerParams) {
			if (allowShowNotification) {
				data.new.forEach((activity) => {
					toast.showCustom(
						(closeToast) => {
							return (
								<NotificationToast
									notification={activity}
									onClick={() => {
										onClickNotification(activity.data);
										analytics.trackEvent('NotificationInteractions', {
											action_taken: 'notification message clicked',
											notification_id: activity.id,
											notification_source: activity.actor?.data?.userId,
											notification_content: activity.data.formattedContent,
										});
									}}
									onClose={closeToast}
								/>
							);
						},
						{ position: 'top-right', autoClose: 5000 },
					);
				});
			}
		},
		[toast, onClickNotification],
	);

	useEffect(() => {
		if (!isListenerSet) {
			feed.onSubscribe(user.slug, listener);
			isListenerSet = true;
		}
	});

	return {
		allowShowToast: (allow: boolean) => (allowShowNotification = allow),
	};
};
