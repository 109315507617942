import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function checkIdInNestedArray(ids: string[], arr: ICheckmarkState[], replaceValue = '') {
	const updatedArr = [...arr];

	// Loop through each ID in the array

	for (const id of ids) {
		// Loop through each item in the array
		for (const element of updatedArr) {
			const item = element;

			// Check if the current item has children
			if (item?.children) {
				// Check if the current item's all children value is true
				if (item.children.some((child) => !!child.value)) {
					item.value = true;
				}
				if (item.children.every((child) => !!child.value)) {
					item.value = true;
				}
				// Loop through the children of the current item
				for (const child of item.children) {
					// Check if the ID matches the ID of the child
					if (child.id === id.replace(replaceValue, '')) {
						// If there is a match, update the value to true
						item.value = true;
						child.value = true;
					}
				}
			}
		}
	}

	return updatedArr;
}
