import { Page } from '../../components';
import {
	useTranslation,
	useNavigate,
	useSet,
	useEvent,
	useState,
	useService,
	useMe,
	useChat,
	useEffect,
	useDebouncedCallback,
} from '@hooks';
import { useFilterUsersProfilesRecords } from '@ui-modules/chat/hooks/useFilterUsersProfileRecords';
import type { TProfile, TUserProfileRecord } from '@typings';
import type { IAlphabetListProps } from '@ui-kit/components/AlphabetList';
import ChatAddEditMembers from '@ui-modules/chat/components/ChatAddEditMembers';

import { ROUTES } from '@constants';

const CreateChatPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const {
		selectedUsersIds,
		setSelectedUserIds,
		removedChannelIds,
		setRemovedChannelsId,
		setSelectedChannel,
		setChatName,
	} = useChat();
	const chat = useService('ChatService');
	const [searchQuery, setSearchQuery] = useState('');
	const { filteredProfiles, isLoading, userProfileRecords } = useFilterUsersProfilesRecords(searchQuery);
	// Selected list states & handlers.
	const [selectedProfileIdsSet, { add, remove }] = useSet<TProfile['id']>(new Set());
	const selectedProfileIds = Array.from(selectedProfileIdsSet.values());

	const handleSelectUser: IAlphabetListProps['onClick'] = useEvent(({ slug }, isSelected) => {
		isSelected ? remove(slug) : add(slug);
	});
	const handleRemoveUser = useEvent(({ slug }: TUserProfileRecord) => remove(slug));

	useEffect(() => {
		if (selectedUsersIds) {
			selectedUsersIds.forEach((id) => {
				add(id);
			});
		}
	}, []);

	useEffect(() => {
		// RESET saved "new chat name" only in case if no user previously saved and selected.
		if (!selectedUsersIds?.length && !selectedProfileIds.length) {
			setChatName('');
		}
	}, [selectedUsersIds]);

	const submit = useDebouncedCallback(
		() => {
			if (selectedProfileIds && selectedProfileIds.length === 1) {
				chat.getOrMarkOneToOneChatWithUser(selectedProfileIds[0], user.slug, true).then((oneToOneChannel) => {
					navigate(ROUTES.chatChannel(oneToOneChannel.id), { replace: true });
					setSelectedChannel(oneToOneChannel);
					const filterRemoveChannelIds = removedChannelIds
						? removedChannelIds?.filter((id) => id !== oneToOneChannel.id)
						: null;
					setRemovedChannelsId(filterRemoveChannelIds);
				});
			} else if (selectedProfileIds && selectedProfileIds.length > 1) {
				setSelectedUserIds(selectedProfileIds);
				navigate(ROUTES.createChatGroup(selectedProfileIds as any));
			}
		},
		1000,
		{ leading: true, trailing: false },
	);

	const onCancelPressed = () => {
		navigate(-1);
	};
	return (
		<Page title={t('Add Members')}>
			<ChatAddEditMembers
				filteredProfiles={filteredProfiles}
				handleRemoveUser={handleRemoveUser}
				handleSelectUser={handleSelectUser}
				isLoading={isLoading}
				searchQuery={searchQuery}
				selectedProfileIds={selectedProfileIds}
				userProfileRecords={userProfileRecords}
				onCancelPressed={onCancelPressed}
				onChangeSearchQuery={setSearchQuery}
				onPressed={submit}
			/>
		</Page>
	);
};

export default CreateChatPage;
