import type { ReactNode } from 'react';
import styles from './Sidebar.module.css';
import { clsx } from '@utils';

const Sidebar = ({ children, className }: ISidebarProps) => {
	return <aside className={clsx(styles.sidebar, className)}>{children}</aside>;
};

export interface ISidebarProps {
	children: ReactNode;
	className?: string;
}

export default Sidebar;
