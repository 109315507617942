import { useService } from '@services';
import type { TResultsAddresses } from '@typings';
import { parsePlace } from '@utils';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export const useAutocompletePlace = (options?: IUseAutocompletePlaceProps) => {
	const appConfig = useService('AppConfigService');

	const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
		apiKey: appConfig.GOOGLE_PLACE_API_KEY,
	});

	const getPlaceOptions = (searchQuery?: string) => {
		getPlacePredictions({ input: searchQuery || '', ...(options || {}) });
	};

	const onPlaceSelect = (placeId: string, onSuccess: (address: TResultsAddresses) => void) => [
		placesService?.getDetails(
			{
				placeId: placeId,
			},
			(placeDetails) => {
				if (!placeDetails || !placeDetails.address_components) return;
				const parsedPlace = parsePlace(placeDetails.address_components);
				onSuccess(parsedPlace);
			},
		),
	];

	return {
		placeOptions: placePredictions,
		loading: isPlacePredictionsLoading,
		getPlaceOptions,
		onPlaceSelect,
	};
};

interface IUseAutocompletePlaceProps {
	componentRestrictions: {
		country: string | null;
	};
}
