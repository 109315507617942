import axios from 'axios';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { IMixPanelAnalyticsService } from '../../interfaces/AnalyticsService.MixPanel.interface';
import type { IBugTracker } from '../../interfaces/BugTracker.interface';
import type { IDevLogger } from '../../interfaces/DevLogger.interface';
import type { IEnvironmentConfig, IGooglePlaceConfig } from '../../interfaces/AppConfig.interface';

export abstract class AbstractWebRiskApiService {
	constructor(
		appConfig: IEnvironmentConfig & IGooglePlaceConfig,
		logger: IDevLogger,
		private readonly bugTracker: IBugTracker,
		private readonly analytics: IMixPanelAnalyticsService,
	) {
		this.logger = logger.child('WebRiskApiService');

		this.axiosClient = axios.create({
			baseURL: 'https://safebrowsing.googleapis.com/v4/',
			params: {
				key: appConfig.GOOGLE_PLACE_API_KEY,
			},
			responseEncoding: 'utf8',
		});
		this.axiosClient.interceptors.response.use(
			(response) => {
				this.onRequestFinished(response.config, response);
				return this.transformResponse(response);
			},
			(error) => {
				// If we doesn't wrap error handling in setTimeout, react-query's hooks does not obtain AxiosError but CancelledError.
				setTimeout(() => {
					this.onError(error);
				}, 0);

				return Promise.reject(error);
			},
		);
	}

	private logger: IDevLogger;
	protected axiosClient: AxiosInstance;

	// Configuration.

	protected transformResponse(response: AxiosResponse<unknown, unknown>) {
		return response.data;
	}
	protected onError(error: AxiosError): void {
		this.bugTracker.captureException(error);
		this.logger.error(error);

		const request = error?.response?.config as AxiosRequestConfig | undefined;
		const response = error?.response as AxiosResponse;
		this.analytics.trackEvent('http_request_failed', {
			'#url': String(request?.url),
			'#method': String(request?.method),
			'#responseCode': response?.status ?? 0,
			'#accessToken': request?.headers?.Authorization?.toString?.(),
			'#responseData': response?.data,
		});
	}

	protected onRequestFinished(request: AxiosRequestConfig, response?: AxiosResponse): void {
		this.logger.debug('request finished', request?.url, response?.status);

		this.analytics.trackEvent('http_request_completed', {
			'#url': String(request?.url),
			'#method': String(request?.method),
			'#responseCode': response?.status ?? 0,
		});
	}
}
