import { useTranslation } from '@hooks';
import { ArrowDownIcon } from '@ui-kit';
import { clsx } from '@utils';
import styles from './NetworkConnectionsSortWidget.module.css';
import type { TNetworkConnectionQuerySort, TNetworkConnectionSortOption } from '@typings';

const NetworkConnectionsSortWidget = ({
	sortOptions,
	selectedOption,
	sortDisabled = false,
	onSelect,
}: INetworkConnectionsSortWidgetProps) => {
	const { t } = useTranslation();

	const handleSelectOption = (option: TNetworkConnectionSortOption) => {
		onSelect({
			field: option.value,
			order: selectedOption.field === option.value && selectedOption.order === 'asc' ? 'desc' : 'asc',
		});
	};

	return (
		<div
			className={clsx(
				styles.networkConnectionSort__container,
				sortDisabled && styles.networkConnectionSort__container_disabled,
			)}
		>
			<span className={styles.networkConnectionSort__title}>{t('Sort By')}</span>

			{sortOptions.map((option) => {
				const isSelected = selectedOption.field === option.value;
				return (
					<div
						className={clsx(
							styles.networkConnectionSort__option,
							isSelected && styles.networkConnectionSort__option_selected,
						)}
						key={option.title + option.title}
						onClick={() => handleSelectOption(option)}
						onKeyDown={() => handleSelectOption(option)}
					>
						{isSelected ? (
							<div className={styles.networkConnectionSort__arrow} data-order={selectedOption.order}>
								<ArrowDownIcon fill="blue" />
							</div>
						) : null}
						<span>{option.title}</span>
					</div>
				);
			})}
		</div>
	);
};

export interface INetworkConnectionsSortWidgetProps {
	selectedOption: TNetworkConnectionQuerySort;
	sortOptions: TNetworkConnectionSortOption[];
	sortDisabled?: boolean;
	onSelect: (option: TNetworkConnectionQuerySort) => void;
}

export default NetworkConnectionsSortWidget;
