import { useService, useQuery, useMe } from '@hooks';
import type { Channel } from 'stream-chat';
import type { UseQueryOptions } from '@tanstack/react-query';

/** Makes fetch query for getting channel by ID. */
export const useGetChannelQuery = (channelId: string, queryOptions?: UseQueryOptions<Channel | null, Error>) => {
	const chat = useService('ChatService');
	const { queryKeys } = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<Channel | null, Error>(
		queryKeys.getChannel(channelId),
		() => {
			if (channelId.startsWith('U--')) {
				return chat.getOrMarkOneToOneChatWithUser(channelId, user.slug, true);
			} else {
				return chat.getChannel(channelId);
			}
		},
		{
			staleTime: 0,
			cacheTime: 0,
			refetchOnMount: 'always',
			enabled: !!channelId,
			...queryOptions,
		},
	);
};
