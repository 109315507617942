import { ADDRESS_LABELS } from '@constants';
import { useMe, useTranslation, useCountriesRegions } from '@hooks';
import type { TEditContactCard } from '@schemas';
import { emptyAddressContactValue } from '@schemas';
import type { TAddressContact, TAddressContactType, TResultsAddresses } from '@typings';
import { CheckboxInput, DropdownMenu } from '@ui-kit';
import { translateAddressLabelToTitle, toString } from '@utils';
import type { FormikProps } from 'formik';
import { FieldArray } from 'formik';
import ContactCard from '../ContactCard';
import LabeledArrayField from '../LabeledArrayField';
import type { TBaseOption } from '../LabeledArrayField/LabeledArrayField';
import SuggestionPlaceModal from '../SuggestionPlaceModal';
import styles from './AddressesForm.module.css';
import { transformAddressDetails } from './AddressesForm.utils';

const isBillingAddress = (option: TBaseOption) => option.type === 'billing';

const AddressesForm = ({ formProps }: IAddressesFormProps) => {
	const { t } = useTranslation();
	const { values, setFieldValue, setFieldError, setValues, errors } = formProps;
	const { profile } = useMe();

	const { getListOfRegions, transformCountryNameToISO, countryList } = useCountriesRegions();

	const setIsPrimaryValue = (index: number) => (value: boolean) => {
		if (value) {
			const newAddress = (values.address ?? []).map((address, addressIndex) =>
				addressIndex === index ? { ...address, primary: value } : { ...address, primary: false },
			);
			setFieldValue(`address`, newAddress);
		} else {
			setFieldValue(`address[${index}].primary`, value);
		}
	};

	const translateField = (key: string) => {
		const keyMap: Record<string, string> = {
			postalCode: t('postal code'),
			streetAddress1: t('street address 1'),
			country: t('country'),
			city: t('city'),
		};

		return keyMap[key] || key;
	};

	return (
		<ContactCard title={t('Address')}>
			<FieldArray
				name="address"
				render={(arrayHelpers) => (
					<LabeledArrayField
						addButtonHidden={profile['@type'] === 'ProfileStaff'}
						addButtonTitle={t('Add Address')}
						arrayHelpers={arrayHelpers}
						canRemoveOption={(option) => !isBillingAddress(option)}
						emptyValue={emptyAddressContactValue}
						getFieldError={(index: number) => {
							const addressErrors = errors?.address?.[index] as any as TAddressContact;

							if (addressErrors) {
								const errorKeys = Object.keys(addressErrors.address).map(translateField);

								if (errorKeys.length === 1) {
									return `The ${errorKeys} field is empty or invalid. Please check your input.`;
								} else {
									return `The ${errorKeys.join(', ')} fields are empty or invalid. Please check your input.`;
								}
							}

							return '';
						}}
						modalTitle={t('Address Label')}
						options={values.address ?? []}
						translateType={(label) => translateAddressLabelToTitle(label as TAddressContactType, t)}
						types={ADDRESS_LABELS}
					>
						{(option, index) => {
							const regionsList = getListOfRegions(values?.address?.[index]?.address?.country || '');
							const suggestDetails = (details: TResultsAddresses) => {
								const updatedValues = transformAddressDetails({ details, values, index });
								setValues(updatedValues);
							};
							return !values.address?.[index] ? null : (
								<div className={styles.address__container} key={option.type}>
									<DropdownMenu
										bottomBorderedStyle
										iconType="clear"
										options={countryList}
										placeholder={t('Select a Country')}
										value={values.address[index].address.country}
										variant="medium"
										onChange={(countryName) => {
											setFieldValue(`address[${index}].address.country`, countryName);
											setFieldError(`address`, undefined);
											// CLEAR state if another country was selected from dropdown
											setFieldValue(`address[${index}].address.state`, '');
											setFieldError(`address`, undefined);
										}}
									/>
									{!!values.address[index].address.country && !regionsList.length ? null : (
										<DropdownMenu
											bottomBorderedStyle
											iconType="clear"
											options={regionsList}
											placeholder={t('State')}
											value={toString(values.address[index].address.state)}
											variant="medium"
											onChange={(state) => {
												setFieldValue(`address[${index}].address.state`, state);
												setFieldError(`address`, undefined);
											}}
										/>
									)}
									<SuggestionPlaceModal
										placeholder={t('Street')}
										searchOptions={{
											componentRestrictions: {
												country: transformCountryNameToISO(values?.address?.[index]?.address?.country) as string,
											},
										}}
										suggestDetails={suggestDetails}
										value={toString(values.address[index].address.streetAddress1)}
										onChange={(e) => {
											setFieldValue(`address[${index}].address.streetAddress1`, e.target.value);
											setFieldError(`address`, undefined);
										}}
										onClear={() => setFieldValue(`address[${index}].address.streetAddress1`, '')}
									/>
									<input
										autoComplete="address-line2"
										placeholder={t('Street')}
										value={toString(values.address[index].address.streetAddress2)}
										onChange={(e) => {
											setFieldValue(`address[${index}].address.streetAddress2`, e.target.value);
											setFieldError(`address`, undefined);
										}}
									/>
									<input
										autoComplete="street-address"
										placeholder={t('City')}
										value={toString(values.address[index].address.city)}
										onChange={(e) => {
											setFieldValue(`address[${index}].address.city`, e.target.value);
											setFieldError(`address`, undefined);
										}}
									/>
									<div className={styles.address__row}>
										<input
											autoComplete="postal-code"
											placeholder={t('Postcode')}
											value={toString(values.address[index].address.postalCode)}
											onChange={(e) => {
												setFieldValue(`address[${index}].address.postalCode`, e.target.value);
												setFieldError(`address`, undefined);
											}}
										/>
									</div>
									{isBillingAddress(option) ? null : (
										<div className={styles.address__controls}>
											<CheckboxInput
												label={t('Visible to all members')}
												size="small"
												value={values.address[index].visibleToAll}
												onChange={(value) => setFieldValue(`address[${index}].visibleToAll`, value)}
											/>
											<CheckboxInput
												label={t('Primary address')}
												size="small"
												value={values.address[index].primary}
												onChange={setIsPrimaryValue(index)}
											/>
										</div>
									)}
								</div>
							);
						}}
					</LabeledArrayField>
				)}
			/>
		</ContactCard>
	);
};

export interface IAddressesFormProps {
	formProps: FormikProps<TEditContactCard>;
}

export default AddressesForm;
