import { createContext, useContext } from 'react';
import { useService } from '@hooks';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import type { PropsWithChildren } from 'react';

const PlacesServiceContext = createContext<google.maps.places.PlacesService | null>(null);

/**	Passes 'placesService' via context to use in 'useFindAddressLocation' hook.
 * We use usePlacesAutocompleteService in context instead of in place,
 * because if navigate fast between meeting sometimes 'placesService' is not initialized (Fixes-T21C-5233).
 * @author DmitriyNikolenko
 */
export const PlacesServiceContextProvider = ({ children }: PropsWithChildren) => {
	const appConfig = useService('AppConfigService');
	const { placesService } = usePlacesAutocompleteService({
		apiKey: appConfig.GOOGLE_PLACE_API_KEY,
	});

	return <PlacesServiceContext.Provider value={placesService}>{children}</PlacesServiceContext.Provider>;
};

export const usePlacesService = () => useContext(PlacesServiceContext);
