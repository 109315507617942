import styles from '../ChatInputBar/ChatInputBar.module.css';
import { SendButtonIcon, SearchIcon } from '@ui-kit';

const SendButton = ({ disable, showGifInput, handleSendPressed }: ISendButtonProps) => {
	return (
		<div className={styles.chatInputBar__sendButtonSection}>
			<button className={styles.chatInputBar__sendButton} disabled={disable} onClick={handleSendPressed}>
				{showGifInput ? (
					<SearchIcon fill="#bc9d58" height={18} width={18} />
				) : (
					<SendButtonIcon fill={disable ? '#979797' : `#bc9d58`} height={18} width={18} />
				)}
			</button>
		</div>
	);
};

interface ISendButtonProps {
	disable: boolean;
	showGifInput: boolean;
	handleSendPressed: (event: React.BaseSyntheticEvent) => void;
}
export default SendButton;
