import { Input } from '@ui-kit';
import { Fragment } from 'react';

const WebLinksSection = ({ options, placeholder, disabled, onChange }: IWebLinksSectionProps) => {
	return (
		<Fragment>
			{options.map((option) => (
				<Input
					autoCapitalize="none"
					disabled={disabled}
					errorMessage={option.error}
					key={option.label}
					label={option.label}
					placeholder={placeholder}
					value={option.value}
					onChange={(e) => onChange(option.filed, e.target.value)}
				/>
			))}
		</Fragment>
	);
};

export interface IWebLinksSectionOption {
	filed: string;
	label?: string;
	value?: string;
	error?: string;
}

export interface IWebLinksSectionProps {
	title?: string;
	placeholder: string;
	disabled?: boolean;
	onChange: (field: string, value: string) => void;
	options: IWebLinksSectionOption[];
}

export default WebLinksSection;
