import { useCallback, useNotification, useTranslation } from '@hooks';
import type { TEditDealFilters } from '@schemas';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

export const useAllFilterValidation = () => {
	const { t } = useTranslation();
	const { showError } = useNotification();

	return useCallback(
		(values: TEditDealFilters, optionIndex: number, availableDealTypes: ICheckmarkState[] | null): boolean => {
			if (optionIndex === 1) {
				if (!values.dealSubClasses?.length) {
					showError({ title: t('Please select at least one asset-class') });
					return false;
				}

				if (!values.dealTypes?.length && !!availableDealTypes?.length) {
					showError({ title: t('Please select at least one deal-type') });
					return false;
				}
			}
			return true;
		},
		[t, showError],
	);
};
