import { memo } from 'react';
import {
	ActivityIndicator,
	EmptyStateMessage,
	FileListItem,
	FixedWidthContainer,
	FolderListItem,
	Gap,
	SectionListRow,
	When,
} from '@ui-kit';
import { useCallback, useTranslation } from '@hooks';
import styles from './FilesSectionList.module.css';
import type { TFile, TFolder } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';

/** List of folders & files separated into sections.
 * 	We haven't manage to use SectionList because I found it hard to make height of the list floating (following the UI design) [@dmitriy.nikolenko].
 */
const FilesSectionList = ({
	folderId,
	files,
	folders,
	isLoading,
	makeFileMenuOptions,
	onOpenFile,
	onOpenFolder,
}: IFilesSectionListProps) => {
	// Dependencies.
	const { t } = useTranslation();

	// Render list helpers.
	const renderFileItem = useCallback(
		(file: TFile, isLastInGroup: boolean) => {
			return (
				<FileListItem
					fileMenuOptions={makeFileMenuOptions(file)}
					iconHeight={40}
					iconWidth={34}
					key={file.id}
					meta={extractMetaFromFile(file)}
					openInViewerOnly={file.mediaObject.openInViewerOnly}
					viewerModeText={t('read only')}
					withSeparator={!isLastInGroup}
					onClick={() => onOpenFile(file)}
				/>
			);
		},
		[folderId],
	);
	const renderFolderItem = useCallback(
		(folder: TFolder, isLastInGroup: boolean) => {
			return (
				<FolderListItem
					folder={folder}
					key={folder.id}
					withSeparator={!isLastInGroup}
					onClick={() => onOpenFolder(folder.id)}
				/>
			);
		},
		[folderId],
	);

	if (isLoading) return <ActivityIndicator size="medium" type="fit" />;
	if (!files.length && !folders.length) {
		return (
			<div className={styles.dealFilesSectionList__emptyMessageBox}>
				<EmptyStateMessage text={t('This folder is empty')} />
			</div>
		);
	}
	return (
		<>
			<div className={styles.dealFilesSectionList}>
				<FixedWidthContainer>
					<When condition={!!folders.length}>
						<SectionListRow headerStyles={styles.filesSectionList__header} title={t('Folders')} />
						{folders.map((file, index, allFolders) => renderFolderItem(file, isLastItemInGroup(index, allFolders)))}
					</When>
					<When condition={!!files.length}>
						<Gap gap={8} />
						<SectionListRow title={t('Files')} />
						{files.map((file, index, allFiles) => renderFileItem(file, isLastItemInGroup(index, allFiles)))}
					</When>
				</FixedWidthContainer>
			</div>
		</>
	);
};

/** Helps to determine whether the item is last within a group to define if we need to render bottom separator. */
const isLastItemInGroup = (currentIndex: number, arrayOfItems: unknown[]) => arrayOfItems.length - 1 === currentIndex;

export interface IFilesSectionListProps {
	/** Target Folder ID which files should be shown. Set 'null' for the root folder. */
	folderId: TFolder['id'] | null;
	/** List of files. */
	files: TFile[];
	/** List of folders. */
	folders: TFolder[];
	/** Are files or folders loaded. */
	isLoading: boolean;
	hideSectionHeaders?: boolean;
	makeFileMenuOptions: (file: TFile) => TMeatballMenuOption[];
	onOpenFile: (fileId: TFile) => void;
	onOpenFolder: (folderId: TFolder['id']) => void;
}

export default memo(FilesSectionList);
