import { Page, FullScreenPageLayout } from '../../components';
import { useParams, useService, useState, useNavigate, useTranslation } from '@hooks';
import styles from './RenameGroupPage.module.css';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';
import { filterChannelMembers } from '@ui-modules/chat/utils/filterChannelMembers';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import type { TUserProfileRecord } from '@typings';
import ChatMembers from '@ui-modules/chat/components/ChatMembers';
import { ROUTES } from '@constants';

const RenameGroupPage = () => {
	const channelId = useParams<IChatViewMembersParams>().channelId as string;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [groupName, setGroupName] = useState('');
	const { data: userProfileRecords = [] } = useAddressBookQuery();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [channelMember, setChannelMember] = useState<TUserProfileRecord[]>([]);
	const { data: channel } = useGetChannelQuery(channelId, {
		onSuccess: (channel) => {
			if (channel) {
				setGroupName(`${channel?.data?.name}`);
				chat.getMembersChannel(channel).then((members) => {
					if (members) {
						const channelMember = filterChannelMembers(members, userProfileRecords);
						setChannelMember(channelMember);
					}
					setIsLoading(false);
				});
			} else {
				setIsLoading(false);
			}
		},
	});

	const chat = useService('ChatService');

	const onSavePressed = () => {
		if (channel) {
			chat.updateNameChannel(channel, groupName).then(() => navigate(ROUTES.chatChannel(channel.id)));
		}
	};
	return (
		<Page title={t('Rename Group')}>
			<FullScreenPageLayout
				headerTitle={t('Group Chat')}
				rightButtonText={channel?.data?.name !== groupName.trim() && groupName.trim() !== '' ? t('Save') : undefined}
				onLeftButtonPress={() => navigate(-1)}
				onRightButtonPress={onSavePressed}
			>
				<div className={styles.renameGroupChat__wrapper}>
					<div className={styles.renameGroupChat__inputContainer}>
						<div className={styles.renameGroupChat__nameText}>{t('Name')}</div>
						<input
							className={styles.renameGroupChat__input}
							placeholder={'Rename a group chat name'}
							type="text"
							value={groupName}
							onChange={(e) => setGroupName(e.target.value)}
						/>
					</div>
					<ChatMembers channelMembers={channelMember} isLoading={isLoading} listId={`rename-${channelId}`} />
				</div>
			</FullScreenPageLayout>
		</Page>
	);
};

export default RenameGroupPage;

export interface IChatViewMembersParams extends Record<string, string | undefined> {
	channelId: string;
}
