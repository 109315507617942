import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { without, union } from '@utils';

export interface ISettingsState {
	collapsedNotificationsSettings: string[];
}

const initialState: ISettingsState = {
	collapsedNotificationsSettings: [],
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		addCollapsedNotificationsSettings: (state, { payload: settingsSectionName }: PayloadAction<string>) => {
			state.collapsedNotificationsSettings = union(state.collapsedNotificationsSettings, [settingsSectionName]);
		},
		removeCollapsedNotificationsSettings: (state, { payload: settingsSectionName }: PayloadAction<string>) => {
			state.collapsedNotificationsSettings = without(state.collapsedNotificationsSettings, settingsSectionName);
		},
	},
});
