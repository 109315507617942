type TActivityLinkType = 'inAppPage' | 'url' | 'event' | 'activity';

/** Config of Activity post button link values. */
export const ACTIVITY_LINK_TYPE_MAP: Record<TActivityLinkType, TActivityLinkType> = {
	inAppPage: 'inAppPage',
	url: 'url',
	event: 'event',
	activity: 'activity',
};

/** Determines the type of the link. */
export const getActivityLinkType = (link: string): TActivityLinkType => {
	if (link.startsWith('/api/events/')) {
		return ACTIVITY_LINK_TYPE_MAP.event;
	} else if (link.startsWith('https:') || link.startsWith('http:') || link.startsWith('mailto:')) {
		return ACTIVITY_LINK_TYPE_MAP.url;
	} else if (link.startsWith('getstream::')) {
		return ACTIVITY_LINK_TYPE_MAP.activity;
	} else {
		return ACTIVITY_LINK_TYPE_MAP.inAppPage;
	}
};
