import { LongChevronRightIcon } from '@ui-kit';

import styles from './NavigationCard.module.css';

const NavigationCard = ({ title, description, onClick }: INavigationCardProps) => {
	return (
		<div className={styles.navigationCard} role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
			<div>
				<h3>{title}</h3>
				{!!description && <span className={styles.navigationCard__description}>{description}</span>}
			</div>
			<div>
				<LongChevronRightIcon height={16} width={8} />
			</div>
		</div>
	);
};

interface INavigationCardProps {
	title: string;
	description?: string;
	onClick: () => void;
}

export default NavigationCard;
