import type { TNotificationData } from '@typings';
import NotificationMeetingAttendanceAction from '../NotificationMeetingAttendanceAction';

const NotificationAction = ({ notificationData }: INotificationActionProps) => {
	if (notificationData?.notificationAction?.type === 'meetingAttendance') {
		return <NotificationMeetingAttendanceAction meetingId={notificationData.tapAction.destination} />;
	} else {
		return null;
	}
};

export interface INotificationActionProps {
	notificationData: TNotificationData;
}

export default NotificationAction;
