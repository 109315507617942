import { GLOBAL_SEARCH } from '@constants';
import { useMemo, useService } from '@hooks';

export const useGlobalSearchHistory = (searchQuery: string, debouncedSearchQuery?: string) => {
	const { useAppSelector, useBindActionCreators, globalSearchHistory } = useService('ReduxService');

	const recentSearches = useAppSelector((store) => store.globalSearchHistory.recentSearches);
	const boundActions = useBindActionCreators(globalSearchHistory);

	const filteredRecentSearches = useMemo(
		() =>
			recentSearches.filter((search: string) => {
				return debouncedSearchQuery && search.includes(debouncedSearchQuery);
			}),
		[debouncedSearchQuery, recentSearches],
	);

	if (searchQuery.length < GLOBAL_SEARCH.validSearchQueryLength) {
		return { recentSearches, ...boundActions };
	} else {
		return { recentSearches: filteredRecentSearches, ...boundActions };
	}
};
