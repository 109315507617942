import { useRef, useService } from '@hooks';
import { ActivityIndicator } from '@ui-kit';
import CustomMessage from '@ui-modules/chat/components/Message';
import { Channel, MessageList, Chat } from 'stream-chat-react';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import styles from './ChatMessagePreview.module.css';
import type { StreamMessage } from 'stream-chat-react';

const ChatMessagePreview = ({ message }: IChatMessagePreviewProps) => {
	const chat = useService('ChatService');
	const { data: channel, isLoading: isLoadingOfChannelData } = useGetChannelQuery(String(message.channel?.id));

	const messages = useRef([message]).current;

	if (isLoadingOfChannelData) return <ActivityIndicator type="fit" />;
	if (channel)
		return (
			<div className={styles.chatMessagePreview__сhatThemeOverridden}>
				<Chat client={chat.chatClient}>
					<Channel channel={channel} Message={CustomMessage as any}>
						<div className={styles.chatMessagePreview__messageListContainer}>
							<MessageList
								hasMore={false}
								hideDeletedMessages={false}
								hideNewMessageSeparator
								messageActions={[]}
								messages={messages}
								noGroupByUser
							/>
						</div>
						{/* <Message message={message} /> */}
					</Channel>
				</Chat>
			</div>
		);
	else return <div className={styles.chatMessagePreview__message_fallback}>{message.text}</div>;
};

export interface IChatMessagePreviewProps {
	message: StreamMessage;
}

export default ChatMessagePreview;
