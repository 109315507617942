import { Outlet, useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { ActivityIndicator, Button, SpacedGroup, ScreenHeader, When } from '@ui-kit';
import { CommentIcon } from '@ui-kit/icons';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useEffect, useParams, useTrackAlgoliaEventEffect, useTranslation, useChat, useMe } from '@hooks';
import { CommunityEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TCommunity } from '@typings';

const GroupPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { groupId, groupTab } = useParams<IGroupPageParams>() as IGroupPageParams;
	const { setShouldUpdateChannel } = useChat();
	const { profile } = useMe();
	const userIsStaff = profile['@type'] === 'ProfileStaff';

	const { data: group, isLoading, isSuccess } = useCommunityQuery(groupId);
	const titleGroupName = group?.name ? group.name : '...';

	// Actions.
	const openGroupChat = (groupSlug: TCommunity['slug']) => {
		navigate(ROUTES.chatChannel(groupSlug));
		setShouldUpdateChannel(false);
	};

	useEffect(
		function redirectToDefaultTabIfItIsAbsent() {
			if (group && !groupTab) {
				navigate(ROUTES.groupTab(groupId), { replace: true });
			}
		},
		[groupTab, group],
	);

	useTrackAlgoliaEventEffect('Group Viewed', { communitySlug: String(groupId) }, [groupId]);

	return (
		<Page title={titleGroupName}>
			{isLoading ? <ActivityIndicator type="fit" /> : null}
			{isSuccess ? (
				<>
					<ScreenHeader title={group.name}>
						<SpacedGroup>
							<When condition={CommunityEntity.shouldShowGroupCommunicationFeatures(group) && !userIsStaff}>
								<Button
									icon={<CommentIcon fill="black" height={16} width={16} />}
									iconPosition="left"
									title={t('Group Chat')}
									type="outline"
									variant="small"
									onClick={() => openGroupChat(group.slug)}
								/>
							</When>
						</SpacedGroup>
					</ScreenHeader>
					<Outlet />
				</>
			) : null}
		</Page>
	);
};

export interface IGroupPageParams extends Record<string, string | undefined> {
	groupId: string;
}

export default GroupPage;
