import { useService, useTranslation } from '@hooks';
import { ActivityIndicator } from '@ui-kit';
import ReviewSolicitationWaiverWrapper from '@ui-modules/deals/components/ReviewSolicitationWaiverWrapper';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';
import DealSettingsCardContent from './DealSettingsCardContent';
import { useDealFiltersSettings } from './useDealFiltersSettings';
import { useEffect } from 'react';

const DealSettingsCard = ({ onChangeCollapsed, onDealsDisabled }: IDealSettingsCardProps) => {
	const { t } = useTranslation();

	useDealsDisabledEffect({
		message: t('To access the invest notifications settings, please enable the Investment feature first'),
		onDisabled: onDealsDisabled,
	});

	const { sortedUserDealFiltersSettings, eachDealSettingIsOff, isLoading, isDealSettingsLoading } =
		useDealFiltersSettings({
			enabledRequest: true,
		});

	const isContentReady =
		!!sortedUserDealFiltersSettings?.length &&
		typeof eachDealSettingIsOff === 'boolean' &&
		!isLoading &&
		!isDealSettingsLoading;

	return (
		<ReviewSolicitationWaiverWrapper>
			{isContentReady ? (
				<DealSettingsCardContent
					isDefaultExpanded={eachDealSettingIsOff}
					settingsArray={sortedUserDealFiltersSettings}
					onChangeCollapsed={onChangeCollapsed}
				/>
			) : (
				<ActivityIndicator size="medium" type="fit" />
			)}
		</ReviewSolicitationWaiverWrapper>
	);
};

export interface IDealSettingsCardProps {
	onChangeCollapsed: (isCollapsed: boolean) => void;
	onDealsDisabled: () => void;
}

export default DealSettingsCard;
