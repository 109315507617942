import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDealSubClass } from '@typings';

export const useGetDealSubClassesQuery = (dealAssetClassId?: string) => {
	const api = useService('ApiService');
	return useQuery<TDealSubClass[], Error>(
		['dealSubClass.getSubClasses', dealAssetClassId],
		() => {
			return dealAssetClassId ? api.dealSubClass.getSubClasses(dealAssetClassId) : [];
		},
		{
			enabled: !!dealAssetClassId?.length,
		},
	);
};
