import { useService, useMutation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TNetworkConnection, TNetworkConnectionAction } from '@typings';

export const useNetworkConnectionDonationActionMutation = (
	options?: MutationOptions<TNetworkConnectionAction | undefined, Error, TMutationArgs>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useMutation<TNetworkConnectionAction | undefined, Error, TMutationArgs>(
		['networkConnectionDonationAction'],
		async ({ networkConnectionAction, networkConnectionId, donationLink }) => {
			if (networkConnectionAction?.id && donationLink && donationLink !== networkConnectionAction.buttonLink) {
				// UPDATE
				return await api.networkConnection.updateNetworkConnectionDonationAction({
					networkConnectionAction,
					networkConnectionId,
					donationLink,
				});
			} else if (networkConnectionAction?.id && !donationLink) {
				// DELETE
				return await api.networkConnection.deleteNetworkConnectionDonationAction({
					networkConnectionActionId: networkConnectionAction.id,
				});
			} else if (!networkConnectionAction?.id && donationLink) {
				// CREATE
				return await api.networkConnection.createNetworkConnectionDonationAction({
					donationLink: donationLink,
					networkConnectionId,
				});
			}
		},
		{
			onSuccess: (response, variables, context) => {
				if (variables?.networkConnectionAction?.id && !variables?.donationLink) {
					reactQuery.queryClient.removeQueries([
						'networkConnection.getNetworkConnectionAction',
						variables.networkConnectionId,
					]);
				}
				reactQuery.queryClient.refetchQueries([
					'networkConnection.getNetworkConnectionAction',
					variables.networkConnectionId,
				]);
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TMutationArgs = {
	networkConnectionId: TNetworkConnection['id'];
	networkConnectionAction?: TNetworkConnectionAction | null;
	donationLink: string;
};
