export function sortByAlphabet(values: string[]) {
	return [...values].sort((a, b) => a.localeCompare(b));
}

export function filterTagsByQuery(tags: string[], query: string) {
	if (!query.length) return tags;

	const filteredTags = tags.filter((tag) => tag.toLowerCase().includes(query.toLowerCase()));

	return filteredTags;
}

export function sanitizeFilterQuery(filterQuery: string) {
	return filterQuery.trim();
}
