import { useService, useQuery } from '@hooks';
import type { TComment } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useFeedCommentQuery = (commentId: TComment['id'], queryOptions?: UseQueryOptions<TComment, Error>) => {
	const feedService = useService('FeedService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TComment, Error>(
		reactQuery.queryKeys.getSingleActivity(commentId),
		async () => await feedService.getComment(commentId as string),
		{
			enabled: !!commentId,
			...queryOptions,
		},
	);
};
