import { LinksSection } from '@ui-kit';
import { useTranslation } from '@hooks';
import styles from './DealLinks.module.css';

const DealLinks = ({ links, previewLinks, hasPreview, onLinkOpened }: IDealLinksProps) => {
	const { t } = useTranslation();

	const linksArray = hasPreview ? previewLinks : links;
	const isPreviewLink = (link: string, index: number) => links[index] !== link;

	if (!linksArray?.length) return null;
	return (
		<LinksSection
			getLinkClassName={(link, index) => {
				if (hasPreview && isPreviewLink(link, index)) {
					return styles.text_highlighted;
				}
			}}
			links={hasPreview ? previewLinks : links}
			title={t('Info link')}
			onLinkOpened={onLinkOpened}
		/>
	);
};

export interface IDealLinksProps {
	links: string[];
	previewLinks?: string[];
	hasPreview?: boolean;
	onLinkOpened?: () => void;
}

export default DealLinks;
