import { useService } from '@hooks';

export const useGetUserGroups = (userId?: string) => {
	const { useAppSelector } = useService('ReduxService');
	const groups = useAppSelector((store) => store.usersGroups.groups);

	return {
		userGroups: userId ? groups?.[userId] : '',
	};
};
