import { useQuery, useService } from '@hooks';
import type { TCommunity } from '@typings';

export const useUpcomingGroupsQuery = () => {
	const api = useService('ApiService');

	return useQuery<TCommunity[], Error>(
		['community.getUpcomingGroups'],
		async () => await api.community.getUpcomingGroups(),
		{
			staleTime: Infinity,
		},
	);
};
