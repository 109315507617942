import { useService, useMutation, useNotification } from '@hooks';
import type { TCommunity, TFolder } from '@typings';
import type { UseMutationOptions } from '@tanstack/react-query';

export const useDeleteFolderMutation = (
	communityId: TCommunity['id'],
	options?: UseMutationOptions<void, Error, TFolder['id']>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFolder['id']>(
		['communityFolder.deleteCommunityFolder'],
		async (folderId) => await api.communityFolder.deleteCommunityFolder(folderId),
		{
			...options,
			async onSuccess(response, variables, context) {
				await reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getCommunityFolders(communityId));
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
