import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';

/** Query all networks when user is a member. */
export const useMyLifestyleNetworksQuery = (networkTab: string | undefined) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery(
		queryKeys.getMyLifestyleNetworks(networkTab),
		async () => await api.community.getMyLifestyleNetworks(networkTab),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);
};
