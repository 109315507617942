import { useCheckTextOnMaliciousUrls, useMemo, useNotification, useService, useState, useTranslation } from '@hooks';
import type { TActivity, TAttachments, TComment, TCommunity } from '@typings';
import { useAddChildReactionMutation } from '@ui-modules/feed/hooks/useAddChildReactionMutation';
import { useAddReactionMutation } from '@ui-modules/feed/hooks/useAddReactionMutation';
import { useEditReactionMutation } from '@ui-modules/feed/hooks/useEditReactionMutation';
import { ActivityEntity, stripText } from '@utils';
import { filterAttachments, filterNestedArrs } from './CommentControls.utils';

export const useCommentControlsState = ({ isReply, activity, reaction, community }: IUseCommentControlsStateProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { mutateAsync: addComment } = useAddReactionMutation(activity);
	const { mutateAsync: addReply } = useAddChildReactionMutation(activity);
	const { mutateAsync: onEditReaction } = useEditReactionMutation();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ showMessage: true });
	const feedService = useService('FeedService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError, showError } = useNotification();

	const placeholder = useMemo(() => {
		if (!isReply && reaction?.id && !!reaction?.parent) {
			t('Edit reply');
		} else if (!isReply && reaction?.id && !reaction?.parent) {
			t('Edit comment');
		} else if (isReply) {
			return t('Write a reply');
		} else {
			return t('Write a comment');
		}
	}, [isReply, reaction]);

	const onSendPressed = async ({
		attachments,
		comment,
		onSuccess,
	}: {
		attachments: TAttachments[];
		comment: string;
		onSuccess: (commentId: string) => void;
	}) => {
		setLoading(true);

		if (await checkTextOnMaliciousUrls(comment)) {
			return setLoading(false);
		}

		const images = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'image'));
		const videos = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'video'));
		const files = filterAttachments(attachments.filter((attachment) => attachment.fileType === 'file'));
		let imagesUrl;
		let videosUrl;
		let filesUrl;
		try {
			if (images.newAttachments?.length) {
				imagesUrl = (await feedService.uploadImages(images.newAttachments)) || [];
			}
			if (videos.newAttachments?.length) {
				videosUrl = (await feedService.uploadVideos(videos.newAttachments)) || [];
			}
			if (files.newAttachments?.length) {
				filesUrl = (await feedService.uploadDocuments(files.newAttachments)) || [];
			}
		} catch (e) {
			const error = e as Error;
			const parsedErrorMessage = JSON.parse(JSON.stringify(error)); // Handle error that returned from streamClient to show error details.
			parsedErrorMessage?.response?.data?.detail
				? showError({ title: t('Error uploading attachment'), subtitle: parsedErrorMessage?.response?.data?.detail })
				: showUnknownError(error as Error);
			setLoading(false);
			return;
		}

		const commentData = {
			text: stripText(comment),
			images: imagesUrl as string[],
			videos: videosUrl as string[],
			files: filesUrl as string[],
			time: new Date().toISOString(),
		};

		if (reaction?.id && !isReply) {
			onEditReaction({
				comment: reaction,
				text: stripText(comment),
				activityId: activity.id,
				images: filterNestedArrs([imagesUrl, images?.uploadedAttachments?.map((item) => item.url)]) as string[],
				videos: filterNestedArrs([videosUrl, videos?.uploadedAttachments?.map((item) => item.url)]) as string[],
				files: filterNestedArrs([filesUrl, files?.uploadedAttachments?.map((item) => item.url)]) as string[],
			});

			onSuccess(reaction.id);
			setLoading(false);
			return;
		}

		if (isReply) {
			if (reaction) {
				const reply = await addReply({ reaction: reaction, comment: commentData, community });
				onSuccess(reply.id);
			}
		} else {
			const comment = await addComment({ comment: commentData, community });
			onSuccess(comment.id);
		}
		const activityEntity = new ActivityEntity(activity);
		if (activityEntity.isFromLearn()) {
			analytics.trackEvent('LearnInteractions', {
				interaction_type: 'commented by user',
			});
		}
		setLoading(false);
	};

	return {
		loading,
		placeholder,
		onSendPressed,
	};
};

interface IUseCommentControlsStateProps {
	isReply?: boolean;
	activity: TActivity;
	reaction?: TComment;
	community?: TCommunity;
}
