import styles from './ResetFilters.module.css';
import { clsx } from '@utils';

import type { MouseEventHandler } from 'react';

const ResetFilters = ({ onClick, disabled = false, title }: IResetFiltersProps) => {
	return (
		<button className={styles.container} onClick={onClick}>
			<span className={clsx(styles.title, disabled && styles.disabledTitle)}>{title}</span>
			<div className={clsx(styles.line, disabled && styles.disabledLine)} />
		</button>
	);
};

export interface IResetFiltersProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	title: string;
}

export default ResetFilters;
