import { useService } from '@services';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { dayjs, map } from '@utils';
import type { TBlockedUserRelation } from '@typings';

export const useBlockedAndBlockingUsers = () => {
	const api = useService('ApiService');

	const { data: blockedUserRelations = [], isLoading: isBlockedUsersLoading } = useQuery<TBlockedUserRelation[], Error>(
		['userBlocking.getBlockedUsers'],
		async () => await api.userBlocking.getBlockedUsers(),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		},
	);

	const { data: usersBlockingMeRelations = [], isLoading: isUsersBlockingMeLoading } = useQuery<
		TBlockedUserRelation[],
		Error
	>(['userBlocking.getUsersBlockingMe'], async () => await api.userBlocking.getUsersBlockingMe(), {
		staleTime: Infinity,
		cacheTime: Infinity,
		refetchInterval: dayjs.duration(15, 'minutes').asMilliseconds(),
	});

	/** Memoized composition of 'blockedUsers' and 'usersBlockingMe' users to use inside consumer components without overhead. */
	const blockedAndBlockingUsers = useMemo(
		() => [...map(blockedUserRelations, 'blockedUser'), ...map(usersBlockingMeRelations, 'owner')],
		[blockedUserRelations, usersBlockingMeRelations],
	);

	return {
		blockedUserRelations,
		usersBlockingMeRelations,
		isBlockedUsersLoading,
		isUsersBlockingMeLoading,
		blockedAndBlockingUsers,
	};
};
