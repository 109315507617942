import { useMutation, useService } from '@hooks';
import type { TDeal, TFile } from '@typings';

type TUpdateDealFilesData = {
	openInViewerOnly: boolean;
	dealId: TDeal['id'];
	name: string;
	mediaObjectId?: string;
	status: string;
	dealFile: TFile['id'];
};

export const useUpdateOwnerDealPreviewFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<TFile, Error, TUpdateDealFilesData>(
		['deal.updateOwnerDealPreviewFiles'],
		async (params: TUpdateDealFilesData) =>
			await api.dealFile.postDealPreviewFile({
				mediaObjectId: params.mediaObjectId,
				name: params.name,
				dealId: params.dealId,
				openInViewerOnly: params.openInViewerOnly,
				dealFile: params.dealFile,
				status: params.status,
			}),
	);
};
