import { Page } from '../../components';
import { useMe, useMemo, useParams, useTranslation } from '@hooks';
import ConnectionForm from '@ui-modules/connections/components/ConnectionForm';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { ActivityIndicator } from '@ui-kit';
import { useGetNetworkConnectionTemplateQuery } from '@ui-modules/connections/hooks/useGetNetworkConnectionTemplateQuery';
import { getEditInitialValues } from '@ui-modules/connections/components/ConnectionForm/ConnectionForm.schema';
import { useNetworkConnectionMemberRelationQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationQuery';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { useNetworkConnectionActionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionActionQuery';
import type { TNetworkConnection } from '@typings';
import { formatNetworkConnectionDonationAction } from '@utils';

const EditNetworkConnectionPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();

	const { networkConnectionId } = useParams<INetworkConnectionTabsPageParams>() as INetworkConnectionTabsPageParams;
	const { data: networkConnection, isLoading: networkConnectionLoading } =
		useNetworkConnectionQuery(networkConnectionId);
	const { data: formTemplate } = useGetNetworkConnectionTemplateQuery(networkConnection?.community?.id);
	const { data: networkConnectionAction } = useNetworkConnectionActionQuery(networkConnection as TNetworkConnection, {
		select: (networkConnectionAction) => {
			if (!networkConnectionAction) return null;
			return formatNetworkConnectionDonationAction(networkConnectionAction, networkConnection as TNetworkConnection);
		},
	});

	const { data: networkConnectionMemberRelation, isLoading } =
		useNetworkConnectionMemberRelationQuery(networkConnectionId);
	const { data: memberRelations = [] } = useNetworkConnectionMemberRelationsQuery(
		networkConnection?.community?.id as string,
	);

	const editValues = useMemo(
		// memoizing prevents for re-initializing when DiscardChanges modal is rendered (fixes T21C-6928) [@DmitriyNikolenko]
		() =>
			getEditInitialValues({
				connection: networkConnection,
				template: formTemplate,
				relationStatus: networkConnectionMemberRelation?.networkConnectionStatus,
				currentUser: user,
				memberRelations,
				networkConnectionAction,
			}),
		[networkConnection, formTemplate, networkConnectionMemberRelation, memberRelations, networkConnectionAction],
	);

	if (!networkConnectionId) return null;
	if (isLoading || networkConnectionLoading) return <ActivityIndicator size="medium" type="fit" />;

	return (
		<Page scrollDisabled tabName={'networks'} title={t('New Connection Page')}>
			<ConnectionForm
				communityId={networkConnection?.community?.id as string}
				editValues={editValues}
				networkConnectionId={networkConnection?.id as string}
			/>
		</Page>
	);
};

export interface INetworkConnectionTabsPageParams extends Record<string, string | undefined> {
	networkConnectionId: string;
}

export default EditNetworkConnectionPage;
