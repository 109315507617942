import { REVIEW_DEAL_COMMENT_CHARACTER_LIMIT } from '@constants';
import { useState, useFormik, useTranslation } from '@hooks';
import type { TRejectDealReviewForm } from '@schemas';
import { rejectDealReviewSchema } from '@schemas';
import type { TDeal } from '@typings';
import { Button, Modal, TextArea, Gap } from '@ui-kit';
import { useApproveDealReviewMutation } from '@ui-modules/deals/hooks/useApproveDealReviewMutation';
import { useRejectDealReviewMutation } from '@ui-modules/deals/hooks/useRejectDealReviewMutation';
import styles from './DealSubHeader.module.css';

const DealReviewWidget = ({ deal, visible, onReviewAccept }: IDealReviewWidgetProps) => {
	const { t } = useTranslation();

	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const { mutate: approveDealReview, isLoading: isApprovingReview } = useApproveDealReviewMutation(
		deal.id,
		deal.community.id,
		{
			onSuccess: onReviewAccept,
		},
	);
	const { mutate: rejectDealPreview, isLoading: isRejectingReview } = useRejectDealReviewMutation(
		deal.id,
		deal.community.id,
		{
			onSuccess: () => {
				onReviewAccept();
				resetForm();
			},
		},
	);

	const rejectDealReview = (formValues: TRejectDealReviewForm) => {
		rejectDealPreview(formValues.comment?.trim());
		setShowRejectModal(false);
	};

	const { values, errors, handleChange, handleBlur, handleSubmit, submitForm, resetForm } =
		useFormik<TRejectDealReviewForm>({
			validationSchema: rejectDealReviewSchema,
			initialValues: { comment: '' },
			onSubmit: (formValues) => rejectDealReview(formValues),
		});

	if (!visible) return null;
	return (
		<>
			<Button
				loading={isRejectingReview}
				title={t('Reject Changes')}
				variant="small"
				onClick={() => setShowRejectModal(true)}
			/>
			<Button
				loading={isApprovingReview}
				title={t('Approve & Publish')}
				variant="small"
				onClick={() => setShowApproveModal(true)}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Approve Changes')}
				contentClassName={styles.review_modal}
				subTitle={t('Once you approve suggested changes you will be able to Publish your Deal.')}
				title={t('Confirm Approval')}
				variant="small"
				visible={showApproveModal}
				onCancel={() => setShowApproveModal(false)}
				onConfirm={() => {
					approveDealReview();
					setShowApproveModal(false);
				}}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Reject Changes')}
				isConfirmDisabled={!!errors.comment}
				modalContent={
					<form onSubmit={handleSubmit}>
						<Gap gap={8} />
						<TextArea
							errorMessage={
								values.comment && values.comment?.length > REVIEW_DEAL_COMMENT_CHARACTER_LIMIT
									? `${values.comment?.length ?? 0} ${t('of {{limit}} characters remaining', {
											limit: REVIEW_DEAL_COMMENT_CHARACTER_LIMIT,
									  })}`
									: !values.comment?.trim?.()?.length && errors.comment
									? errors.comment
									: ''
							}
							maxRows={6}
							minRows={6}
							placeholder={t('Enter Rejection Comment')}
							value={values.comment}
							onBlur={handleBlur('comment')}
							onChange={handleChange('comment')}
						/>
						{(values.comment?.length ?? 0) <= REVIEW_DEAL_COMMENT_CHARACTER_LIMIT && (
							<p className={styles.commentInput__counter}>
								{`${values.comment?.length ?? 0} ${t('of {{limit}} characters remaining', {
									limit: REVIEW_DEAL_COMMENT_CHARACTER_LIMIT,
								})}`}
							</p>
						)}
						<Gap gap={12} />
					</form>
				}
				title={t('Send Rejection Message')}
				variant="small"
				visible={showRejectModal}
				onCancel={() => setShowRejectModal(false)}
				onConfirm={submitForm}
			/>
		</>
	);
};

interface IDealReviewWidgetProps {
	deal: TDeal;
	visible?: boolean;
	onReviewAccept: () => void;
}
export default DealReviewWidget;
