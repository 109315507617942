import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMemo } from '@hooks';
import { transformLinksHiddenProperties } from '@utils';
import styles from './EditProfileDetailsPage.module.css';
import { CheckboxInput, Form, Formik, Gap } from '@ui-kit';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import { mapProfileDetailsDataToForm, mapProfileDetailsFormToData } from './EditProfileDetailsScreen.utils';
import CustomLinkInputField from '@ui-modules/profile/components/CustomLinkInputField';
import { ROUTES } from '@constants';
import type { TEditProfileDetails } from '@schemas';
import { getEditProfileLinksDetailsSchema } from '@schemas';

const EditProfileDetailsPage = () => {
	const { t } = useTranslation();
	const { profile, user, mutateAsync } = useUpdateProfileMutation();
	const initialValues: TEditProfileDetails = useMemo(
		() => mapProfileDetailsDataToForm(profile.links, profile.hideProperties),
		[profile],
	);
	const googleSearchLink = encodeURI(`www.google.com/search?q=${user.firstName} ${user.lastName}`);
	const onSubmit = async (values: TEditProfileDetails) => {
		const links = mapProfileDetailsFormToData({
			...values,
			google: { ...values.google, link: values.google.link ? googleSearchLink : '' },
		});
		return await mutateAsync({ links, hideProperties: transformLinksHiddenProperties({ ...profile, links }) });
	};

	return (
		<Page title={t('Edit Profile Details')}>
			<Formik<TEditProfileDetails>
				initialValues={getEditProfileLinksDetailsSchema(t).cast(initialValues) as TEditProfileDetails}
				validateOnBlur
				validateOnChange={false}
				validationSchema={getEditProfileLinksDetailsSchema(t)}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					const { values, setFieldValue, initialValues } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileDetails()]}
							formProps={formProps}
							headerTitle={t('Details')}
							saveTrackingName="profile--edit-details"
						>
							<div className={styles.details}>
								<div className={styles.details__container}>
									<span className={styles.details__title}>{t('Links to display')}</span>
									<Form>
										<CustomLinkInputField name="linkedin" title={t('Linkedin')} />
										<Gap gap={16} />
										<span className={styles.details__label}>{t('Google me')}</span>
										<Gap gap={16} />
										<CheckboxInput
											label={t('Hide this')}
											size="medium"
											value={!values.google.showOnProfile}
											onChange={(value) => {
												setFieldValue('google.showOnProfile', !value);
												setFieldValue('google.link', !value ? initialValues.google.link || googleSearchLink : '');
											}}
										/>
										<Gap gap={16} />
										<CustomLinkInputField name="website" title={t('Website')} />
										<Gap gap={16} />
										{values.others.map((_, index) => (
											<>
												<CustomLinkInputField
													key={index}
													name={`others[${index}]`}
													title={t('Additional website {{number}}', { number: index + 1 })}
													withOptions
												/>
												<Gap gap={16} />
											</>
										))}
									</Form>
								</div>
							</div>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileDetailsPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileDetailsPage;
