import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';

export const useMyGroupsQuery = () => {
	const api = useService('ApiService');

	return useQuery(['community.getMyGroups'], async () => await api.community.getMyGroups(), {
		staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
	});
};
