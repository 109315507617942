import { useService, useMutation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { Channel } from 'stream-chat';

export const useToggleChatAlerts = (channel: Channel, options?: MutationOptions<void, Error, boolean>) => {
	const chat = useService('ChatService');

	return useMutation<void, Error, boolean>(
		async (mute: boolean) => await chat.setNotificationChannel(channel, mute),
		options,
	);
};
