import { useTranslation } from '@hooks';
import { Hint } from '@ui-kit';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { CommunityEntity } from '@utils';
import styles from './NetworkAccessibilityHint.module.css';
import type { TCommunity } from '@typings';

/** Hint displays visibility and solicitation information in human readable format. */
const NetworkAccessibilityHint = ({ networkId }: INetworkAccessibilityHintProps) => {
	const { t } = useTranslation();

	const { data: network } = useCommunityQuery(networkId);

	if (!network) return null;

	const visibilityInformation = network && CommunityEntity.getVisibilityInformation(network, t);

	return (
		<Hint iconColor="#ffffff">
			<div className={styles.networkAccessibilityHint}>
				<h4>{visibilityInformation.title}</h4>
				<small>{visibilityInformation.description}</small>
				<span className={styles.networkAccessibilityHint__solicitation}>
					{CommunityEntity.getSolicitationStatus(network, t)}
				</span>
			</div>
		</Hint>
	);
};

export interface INetworkAccessibilityHintProps {
	/** ID of the target network. */
	networkId: TCommunity['id'];
}

export default NetworkAccessibilityHint;
