import { ActivityIndicator, SegmentedControl } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useEventAttendanceMutations } from '@ui-modules/events/hooks/useEventAttendanceMutations';
import type { TEvent } from '@typings';

const NotificationMeetingAttendanceAction = ({ meetingId }: INotificationMeetingAttendanceActionProps) => {
	const { t } = useTranslation();
	const { data: meeting } = useGetMeetingQuery(meetingId);

	const { changeEventAttendanceMutation } = useEventAttendanceMutations();
	const changeEventAttendance = (going: boolean) =>
		meeting && changeEventAttendanceMutation.mutateAsync({ event: meeting, going: going });

	return meeting ? (
		<SegmentedControl
			isLeftOptionSelected={meeting.currentUserAttendance?.status === 'going'}
			leftOptionTitle={t('Attending')}
			rightOptionTitle={t("Can't Make it")}
			size={'small'}
			onLeftOptionClick={() => changeEventAttendance(true)}
			onRightOptionClick={() => changeEventAttendance(false)}
		/>
	) : (
		<ActivityIndicator />
	);
};

export interface INotificationMeetingAttendanceActionProps {
	meetingId: TEvent['id'];
}

export default NotificationMeetingAttendanceAction;
