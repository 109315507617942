import { useEffect, useLogger, useMe, useService } from '@hooks';
import { ActivityIndicator } from '@ui-kit';

const SignOutPage = () => {
	const logger = useLogger('SignOutPage');
	const okta = useService('OktaService');
	const reactQuery = useService('ReactQueryService');
	const chat = useService('ChatService');
	const sentry = useService('SentryService');
	const analytics = useService('AnalyticsService');
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { useAppDispatch, me, globalSearchHistory, chatMessageDrafts, broadcast } = useService('ReduxService');
	const dispatch = useAppDispatch();
	const feed = useService('FeedService');
	const { user } = useMe();
	const userSlug = user?.slug; // cache before removing from redux.

	useEffect(() => {
		Promise.allSettled([
			dispatch(broadcast.signOut()),
			userSlug && feed.unsubscribe(userSlug),
			reactQuery.queryClient.clear(),
			sentry.resetUser(),
			dispatch(chatMessageDrafts.removeAllMessageDrafts()),
			dispatch(me.removeAccount()),
			dispatch(me.removeImpersonateToken()),
			dispatch(globalSearchHistory.clearRecentSearches()),
			chat.disconnect(),
			sessionStorage.clear(),
			analytics.resetUser(),
			algoliaAnalytics.resetUser(),
		]).finally(() => {
			logger.log('sign out scripts have performed');
			okta.signOut(); // it navigates to sign in page after Okta redirect
		});
	}, []);

	return <ActivityIndicator size="large" />;
};

export default SignOutPage;
