import { useRef } from 'react';
import { useSliderState } from 'react-stately';
import { mergeProps, useFocusRing, useNumberFormatter, useSlider, useSliderThumb, VisuallyHidden } from 'react-aria';
import styles from './Slider.module.css';

const Slider = (props: ISliderProps) => {
	const trackRef = useRef(null);
	const inputRef = useRef(null);

	const numberFormatter = useNumberFormatter({ style: 'decimal' });
	const state = useSliderState({ ...props, numberFormatter });
	const { groupProps, trackProps } = useSlider(props, state, trackRef);
	const { thumbProps, inputProps, isDragging } = useSliderThumb({ index: 0, trackRef, inputRef }, state);
	const { focusProps, isFocusVisible } = useFocusRing();

	const progress = state.getThumbPercent(0);

	return (
		<div {...groupProps} className={styles.slider} data-disabled={state.isDisabled}>
			<div {...trackProps} className={styles.slider__track} ref={trackRef}>
				<div className={styles.slider__trackProgress} style={{ width: `${progress * 100}%` }} />
				<div className={styles.slider__thumb} data-dragging={isDragging} data-focus={isFocusVisible} {...thumbProps}>
					<VisuallyHidden>
						<input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
					</VisuallyHidden>
				</div>
			</div>
		</div>
	);
};

export interface ISliderProps {
	/** The current value (controlled). */
	value: number;
	/** Handler that is called when the value changes. */
	onChange: (value: number) => void;
	/** Whether the whole Slider is disabled. */
	isDisabled?: boolean;
	/** The slider's minimum value. Default 0. */
	minValue?: number;
	/** The slider's maximum value. Default 100. */
	maxValue?: number;
	/** The slider's step value. Default 1.  */
	step?: number;
}

export default Slider;
