import { Page, FullScreenPageLayout } from '../../components';
import { useParams, useService, useNavigate, useTranslation, useMe, useQuery, useChat } from '@hooks';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';
import type { IChannelMembers } from '@ui-modules/chat/utils/filterChannelMembers';
import { filterChannelMembers } from '@ui-modules/chat/utils/filterChannelMembers';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import ChatMembers from '@ui-modules/chat/components/ChatMembers';
import type { TUserProfileRecord } from '@typings';
import { ROUTES } from '@constants';
import { dayjs } from '@utils';
import type { Channel } from 'stream-chat';

const ChannelViewMembers = () => {
	const channelId = useParams<IChatViewMembersParams>().channelId as string;
	const { profile, user } = useMe();

	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: userProfileRecords = [], isFetched } = useAddressBookQuery();
	const chat = useService('ChatService');

	const { selectedChannel, setSelectedChannel } = useChat();

	const { data: channel, isLoading: isChannelLoading } = useGetChannelQuery(channelId, {
		onSuccess: (channel) => {
			if (selectedChannel?.id !== channel?.id) {
				setSelectedChannel(channel);
			}
		},
	});
	const { data: channelMember = [], isLoading: isMembersLoading } = useQuery<
		IChannelMembers[],
		Error,
		TUserProfileRecord[]
	>(['getMembersChannel', channelId], () => chat.getMembersChannel(channel as Channel), {
		enabled: !!channel && isFetched,
		select: (members) => filterChannelMembers(members, userProfileRecords, profile, user),
		staleTime: dayjs.duration(15, 'seconds').asMilliseconds(),
	});

	const handleClick = (user: TUserProfileRecord) => {
		navigate(ROUTES.memberProfile(user.profile.id));
	};

	return (
		<Page title={t('View Members')}>
			<FullScreenPageLayout headerTitle={t('Group Chat')} onLeftButtonPress={() => navigate(-1)}>
				<ChatMembers
					channelMembers={channelMember}
					isLoading={isMembersLoading || isChannelLoading}
					listId={`view-${channelId}`}
					onClick={handleClick}
				/>
			</FullScreenPageLayout>
		</Page>
	);
};

export default ChannelViewMembers;

export interface IChatViewMembersParams extends Record<string, string | undefined> {
	channelId: string;
}
