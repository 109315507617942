import { CommentList, InfiniteScrollPaginator } from 'react-activity-feed';
import FeedComment from '@ui-modules/feed/components/FeedComment';
import DeleteReactionModal from '@ui-modules/feed/components/DeleteReactionModal';
import CommentControls from '../CommentControls';
import { useCallback, useMe, useState, useContext } from '@hooks';
import { memo } from 'react';
import type { TActivity, TComment } from '@typings';
import type { LoadMorePaginatorProps } from 'react-activity-feed';
import type { ReactElement } from 'react';
import type { Reaction } from 'getstream';

const MemoizedCommentList = memo(CommentList);

const FeedCommentList = ({
	activity,
	className,
	openFileUrl,
	feedSlug,
	onCommentLikeToggled,
}: IFeedCommentListProps) => {
	// Dependencies
	const { user } = useMe();
	const [commentToDelete, setCommentToDelete] = useState<TComment | null>(null, 'Discussions:commentToDelete');

	// User action handlers.
	const [, setEditingReaction] = useContext(CommentControls.EditingContext);
	const handleReply = (comment?: TComment) => {
		setEditingReaction(comment ? { comment: comment, reply: true } : null);
	};
	const handleEdit = (comment: TComment) => setEditingReaction({ comment, reply: false });

	// Render functions.
	const renderComment = useCallback<TRenderComment>(
		({ comment }) => {
			const commentWithParent = !!comment?.parent;
			return (
				<FeedComment
					activity={activity as TActivity}
					comment={comment as TComment}
					feedSlug={feedSlug}
					isReply={commentWithParent}
					key={comment.id}
					renderReply={renderComment}
					userSlug={user.slug}
					onClickReply={handleReply}
					onDelete={setCommentToDelete}
					onEdit={handleEdit}
					onFileClick={openFileUrl}
					onLikeToggled={onCommentLikeToggled}
				/>
			);
		},
		[activity.id],
	);
	const renderPaginator = useCallback(
		(props: LoadMorePaginatorProps) => <InfiniteScrollPaginator {...props} Loader={<div />} useWindow={false} />,
		[],
	);

	return (
		<>
			<div className={className}>
				<MemoizedCommentList
					activityId={activity.id}
					CommentItem={renderComment as TRenderComment}
					oldestToNewest={true}
					Paginator={renderPaginator}
					reverseOrder={false}
				/>
			</div>

			{/* Should be within Feed context to be able to obtain feed context for useDeleteReactionMutation() */}
			{commentToDelete ? (
				<DeleteReactionModal
					activity={activity as unknown as TActivity}
					comment={commentToDelete}
					feedSlug={feedSlug}
					key={commentToDelete.id}
					visible={!!commentToDelete}
					onClose={() => setCommentToDelete(null)}
					onSuccess={() => setCommentToDelete(null)}
				/>
			) : null}
		</>
	);
};

type TCommentWithChildrenCounts = Reaction & { children_counts?: Record<string, number> };
type TRenderComment = ({ comment }: { comment: TCommentWithChildrenCounts }) => ReactElement<any, any>;

interface IFeedCommentListProps {
	activity: TActivity;
	feedSlug: string;
	className?: string;
	openFileUrl: (url: string) => void;
	onCommentLikeToggled?: (liked: boolean) => void;
	onCommentSent?: (wasEditing: boolean, commentId?: string) => void;
}

export default memo(FeedCommentList, function propsAreEqual(prevProps, nextProps) {
	return prevProps.activity.id === nextProps.activity.id;
});
