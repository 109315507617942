import { useQuery, useService } from '@hooks';
import type { TNetworkConnection, TFolder } from '@typings';

export const useNetworkConnectionFilesAndFoldersQuery = (
	networkConnectionId: TNetworkConnection['id'],
	folderId: TFolder['id'] | null,
	isRootFolder: boolean,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const filesQuery = useQuery(
		reactQuery.queryKeys.getNetworkConnectionFiles(networkConnectionId, folderId),
		async () =>
			await api.networkConnectionFile.getNetworkConnectionFiles({
				networkConnectionId,
				folderId: folderId ?? undefined,
			}),
		{
			enabled: !isRootFolder,
		},
	);
	const foldersQuery = useQuery(
		reactQuery.queryKeys.getNetworkConnectionFolders(networkConnectionId, folderId),
		async () =>
			await api.networkConnectionFolder.getNetworkConnectionFolders({
				networkConnectionId,
				folderId: folderId ?? undefined,
			}),
		{
			onSuccess(folders) {
				folders.forEach((folder) =>
					reactQuery.queryClient.setQueryData(
						reactQuery.queryKeys.getNetworkConnectionFolder(networkConnectionId, folder.id),
						folder,
					),
				);
			},
		},
	);

	return {
		files: isRootFolder ? undefined : filesQuery.data,
		folders: foldersQuery.data,
		isLoading: filesQuery.isLoading || foldersQuery.isLoading,
		isInitialLoading: filesQuery.isInitialLoading || foldersQuery.isInitialLoading,
		isFetching: filesQuery.isFetching || foldersQuery.isFetching,
	};
};
