import { makeHtmlContent } from '@utils';
import { clsx } from '@utils';
import styles from './ChatMessageText.module.css';

const ChatMessageText = ({ className, text, mentions }: IChatMessageTextProps) => {
	return (
		<div
			className={clsx(styles.chatMessageText, className)}
			dangerouslySetInnerHTML={{
				__html: makeHtmlContent(text, mentions),
			}}
		/>
	);
};

export interface IChatMessageTextProps {
	text: string | undefined;
	className?: string;
	mentions?: string[];
}

export default ChatMessageText;
