import { FullScreenPageLayout, Page, TabWrapper } from '../../components';
import { When } from '@ui-kit';
import CommunityFilesWidget from '@ui-modules/files/components/CommunityFilesWidget';
import DeleteFolderModal from '@ui-modules/files/components/DeleteFolderModal';
import RenameFolderModal from '@ui-modules/files/components/RenameFolderModal';
import { useNavigate, useParams, useToggle, useLocation } from '@hooks';
import { useManageFolderMeatballMenuOptions } from '@ui-modules/files/hooks/useManageFolderMeatballMenuOptions';
import { useFolderQuery } from '@ui-modules/files/hooks/useFolderQuery';
import { toString } from '@utils';
import { parseCommunityDefinitionFromLocation } from '@ui-modules/communities/utils/parseCommunityDefinitionFromLocation';
import type { TCommunity, TFolder } from '@typings';

const CommunityFilesPage = () => {
	// Dependencies.
	const navigate = useNavigate();
	const location = useLocation();

	// Navigation state.
	const { networkId, groupId, chapterId } = useParams();
	const communityId = (networkId || groupId || chapterId) as string; // in order to handle both network, group and chapter related paths.
	const folderId = useParams().folderId as string;

	// Page data & states.
	const { data: folder } = useFolderQuery(communityId, folderId);
	const [isRenameFolderDialogVisible, toggleRenameFolderDialog] = useToggle(false);
	const [isDeleteFolderDialogVisible, toggleDeleteFolderDialog] = useToggle(false);
	const meatballMenuOptions = useManageFolderMeatballMenuOptions({
		folderId,
		communityId,
		onRename: toggleRenameFolderDialog,
		onDelete: toggleDeleteFolderDialog,
	});

	return (
		<Page title={toString(folder?.name)}>
			<FullScreenPageLayout headerTitle={toString(folder?.name)} isFixed meatballMenuOptions={meatballMenuOptions}>
				<TabWrapper>
					<CommunityFilesWidget
						communityDefinition={parseCommunityDefinitionFromLocation(location.pathname)}
						communityId={communityId}
						folderId={folderId}
					/>
				</TabWrapper>
			</FullScreenPageLayout>

			{folder ? (
				<>
					<When condition={isDeleteFolderDialogVisible}>
						<DeleteFolderModal
							communityId={communityId}
							folderId={folderId}
							folderName={folder.name}
							visible={isDeleteFolderDialogVisible}
							onClose={toggleDeleteFolderDialog}
							onSuccess={() => navigate(-1)}
						/>
					</When>
					{/* When component is used to force rerender in order to remount with new folderName when folder is changing. */}
					<When condition={isRenameFolderDialogVisible}>
						<RenameFolderModal
							communityId={communityId}
							folderId={folderId}
							folderName={folder.name}
							visible={isRenameFolderDialogVisible}
							onClose={toggleRenameFolderDialog}
						/>
					</When>
				</>
			) : null}
		</Page>
	);
};

export type TCommunityFilesPageParams =
	| {
			networkId: TCommunity['id'];
			folderId: TFolder['id'];
	  }
	| {
			groupId: TCommunity['id'];
			folderId: TFolder['id'];
	  };

export default CommunityFilesPage;
