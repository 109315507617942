import { useService } from '@services';
import { useQuery } from '@hooks';
import type { AxiosError } from 'axios';

export const useGetDealTermsQuery = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<{ text: string; updatedAt: string }, AxiosError>(
		reactQuery.queryKeys.getDealTerms(),
		async () => await api.invest.getDealTerms(),
	);
};
