import { Button } from '@ui-kit';
import { useTranslation } from '@hooks';
import dealsImage from '@ui-modules/deals/assets/deals.png';
import styles from './Deals.module.css';

const MyDealsZeroState = ({ onGoToInvestNetworks }: IMyDealsZeroStateProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.dealTab__zeroWrapper}>
			<img alt="" src={dealsImage} />
			<p className={styles.dealTab__zeroTitle}>{t(`You’re not Following any Deals`)}</p>
			<span className={styles.dealTab__zeroSubTitle}>{t('Deals you Follow are listed here.')}</span>
			<span className={styles.dealTab__zeroSubTitle}>
				{t('To Follow a Deal, visit the Deals tab in an Investment Network.')}
			</span>
			<Button title={t('Go to Investment Networks')} variant="small" onClick={onGoToInvestNetworks} />
		</div>
	);
};

interface IMyDealsZeroStateProps {
	onGoToInvestNetworks: () => void;
}

export default MyDealsZeroState;
