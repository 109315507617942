import { CardHeader, CardArticle } from '../Card';
import { DownChevronIcon } from '../../icons';
import { useCollapse, useMemo } from '@hooks';
import { clsx } from '@utils';
import styles from './CollapsibleCard.module.css';
import type { ICardProps } from '../Card';

/** The variant of the Card component with integrated collapsible functionality. It is collapsed by default. */
const CollapsibleCard = ({
	children,
	defaultExpanded = false,
	expanded,
	toggle,
	isClickableHeader,
	iconColor = 'black',
	...props
}: ICollapsibleCardProps) => {
	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded,
		isExpanded: expanded,
	});

	const toggleProps = useMemo(() => {
		const props = getToggleProps();
		return toggle ? { ...props, onClick: toggle } : props;
	}, [toggle, getToggleProps]);

	return (
		<CardHeader
			{...props}
			actionElement={
				<button className={styles.collapsibleCard__toggleButton} {...toggleProps} aria-label="toggle">
					<DownChevronIcon
						className={clsx(
							styles.collapsibleCard__toggleIcon,
							isExpanded && styles.collapsibleCard__toggleIcon_expanded,
						)}
						fill={iconColor}
					/>
				</button>
			}
			backgroundPlacement="card"
			headerButtonProps={isClickableHeader ? toggleProps : undefined}
		>
			<div data-testid="CollapsibleCard__content" {...getCollapseProps()}>
				<CardArticle>{children}</CardArticle>
			</div>
		</CardHeader>
	);
};

export interface ICollapsibleCardProps extends Omit<ICardProps, 'actionElement' | 'backgroundPlacement'> {
	/** Should the card be collapsed by default. Default true. */
	defaultExpanded?: boolean;
	/** Set card expanded */
	expanded?: boolean;
	/** Toggle card */
	toggle?: () => void;
	/** Define header as a toggle button. Default false */
	isClickableHeader?: boolean;
	/** The icon color of dropdown */
	iconColor?: string;
}

export default CollapsibleCard;
