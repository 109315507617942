import { pluralizeUtils } from '@utils';

export const isAcronym = (text = '') => {
	// Check if the text consists of uppercase letters and/or numbers
	// and does not contain spaces
	return text.length > 1 && /^[A-Z0-9]+$/.test(text);
};

export const textRegisterPluralize = (text = '') => {
	if (!isAcronym(text)) {
		return pluralizeUtils.plural(text);
	}
	const pluralForm = pluralizeUtils.plural(text);
	return `${text}${pluralForm.replace(text, '').toLowerCase()}`;
};
