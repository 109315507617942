import { Button, UploadFileIcon } from '@ui-kit';
import { useTranslation } from '@hooks';
import styles from './FilesWidget.module.css';
import type { IFilesWidgetApiCallbacks } from './FilesWidgetApiCallbacks.interface';

const FilesWidgetEmptyState = ({
	canUploadFiles,
	isFileUploading,
	onPickAndUploadFile,
}: IFilesWidgetEmptyStateProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.filesWidget__emptyState}>
			<div className={styles.filesWidget__emptyStateMessage}>
				<span>{t('No extra files have been added yet.')}</span>
			</div>
			{canUploadFiles ? (
				<Button
					icon={<UploadFileIcon fill="#000000" height={12} width={14} />}
					iconPosition="right"
					loading={isFileUploading}
					title={t('Upload')}
					variant="small"
					onClick={onPickAndUploadFile}
				/>
			) : null}
		</div>
	);
};

export interface IFilesWidgetEmptyStateProps extends Pick<IFilesWidgetApiCallbacks, 'onPickAndUploadFile'> {
	canUploadFiles: boolean;
	isFileUploading: boolean;
}

export default FilesWidgetEmptyState;
