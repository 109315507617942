import { Page } from '../../components';
import {
	useParams,
	useService,
	useState,
	useMe,
	useNavigate,
	useTranslation,
	useSet,
	useEvent,
	useEffect,
	useDebouncedCallback,
	useMemo,
} from '@hooks';
import { useFilterUsersProfilesRecords } from '@ui-modules/chat/hooks/useFilterUsersProfileRecords';
import ChatAddEditMembers from '@ui-modules/chat/components/ChatAddEditMembers';
import type { IAlphabetListProps } from '@ui-kit/components/AlphabetList';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import type { TProfile, TUserProfileRecord } from '@typings';
import { onSavePressed, makeChannelName } from './ChatEditGroupPage.utils';
import { ROUTES } from '@constants';

const ChatEditGroupPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const chat = useService('ChatService');
	const reactQuery = useService('ReactQueryService');
	const channelId = useParams<IChatViewMembersParams>().channelId as string;
	const { data: channel } = useGetChannelQuery(channelId, {
		onSuccess: (channel) => {
			if (channel) {
				getInitialSelectedUser();
			}
		},
	});
	// Search state.
	const [searchQuery, setSearchQuery] = useState('');
	const { filteredProfiles, isLoading, userProfileRecords } = useFilterUsersProfilesRecords(searchQuery);
	const [initialSelectedUserIds, setInitialSelectedUserIds] = useState<string[]>();
	const [initialSelectedUsersName, setInitialSelectedUsersName] = useState<string[]>();
	const [changeChannelName, setChangeChannelName] = useState<boolean>();
	// Selected list states & handlers.
	const [selectedProfileIdsSet, { add, remove }] = useSet<TProfile['id']>(new Set());
	const selectedProfileIds = Array.from(selectedProfileIdsSet.values());
	const selectedProfiles = userProfileRecords.filter((userProfileRecord) =>
		selectedProfileIds.includes(userProfileRecord.slug),
	);

	const onPressed = useDebouncedCallback(
		async () => {
			const { removedUsersId, removeUsersMessage, addUsersMessage, selectedContactsId, staffAndChairIds } =
				onSavePressed(
					selectedProfiles,
					initialSelectedUserIds ? initialSelectedUserIds : [],
					initialSelectedUsersName ? initialSelectedUsersName : [],
					user.slug,
					t,
				);
			if (selectedContactsId.length === 1) return;
			if (channel) {
				if (removedUsersId?.length) await chat.removeMember(channel, removedUsersId, removeUsersMessage);
				if (changeChannelName) {
					const selectedContactsNames = selectedProfiles.map(
						(contact) => `${contact.firstName} ${contact.lastName}`,
					) as string[];
					const groupName = makeChannelName([...selectedContactsNames, `${user.firstName} ${user.lastName}`]);
					chat.updateNameChannel(channel, groupName);
				}

				await chat.addMembers(channel, selectedContactsId, addUsersMessage, staffAndChairIds);
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getChatMemberAvatar(String(channel.id))); // refresh avatar group in ChannelList (@see T21C-3332)
				navigate(ROUTES.chatChannel(channelId), { replace: true });
			}
		},
		1200,
		{ leading: true, trailing: false },
	);

	// Used to change group name when user removed from group.
	// Only works when you did set any user name and we are using channel members name as group name
	useEffect(() => {
		if (initialSelectedUsersName && makeChannelName(initialSelectedUsersName) === channel?.data?.name) {
			setChangeChannelName(true);
		}
	}, [initialSelectedUsersName]);

	const handleSelectUser: IAlphabetListProps['onClick'] = useEvent(({ slug }, isSelected) => {
		isSelected ? remove(slug) : add(slug);
	});
	const handleRemoveUser = useEvent(({ slug }: TUserProfileRecord) => remove(slug));

	// Used to get initial user selected at the time of group creation.
	const getInitialSelectedUser = () => {
		const selectedUsersId =
			channel && Object.keys(channel?.state?.members).length > 0 ? Object.keys(channel.state.members) : [];
		setInitialSelectedUserIds(selectedUsersId);
		const initialSelectedUser = filteredProfiles.filter((item) => selectedUsersId.includes(item.slug as string));
		initialSelectedUser.forEach((i) => {
			return add(i.slug);
		});
		const selectedUserName = initialSelectedUser.map((contact) => `${contact.firstName} ${contact.lastName}`);
		setInitialSelectedUsersName([...selectedUserName, `${user.firstName} ${user.lastName}`]);
	};

	const onCancelPressed = () => {
		navigate(-1);
	};

	const initialNoOwnerMembersIds = useMemo(() => {
		return initialSelectedUserIds?.filter((userId) => userId !== user?.slug) || [];
	}, [initialSelectedUserIds]);

	return (
		<Page title={t('Edit Members')}>
			<ChatAddEditMembers
				filteredProfiles={filteredProfiles}
				handleRemoveUser={handleRemoveUser}
				handleSelectUser={handleSelectUser}
				initialNoOwnerMembersIds={initialNoOwnerMembersIds}
				isEditing={true}
				isLoading={isLoading}
				searchQuery={searchQuery}
				selectedProfileIds={selectedProfileIds}
				userProfileRecords={userProfileRecords}
				onCancelPressed={onCancelPressed}
				onChangeSearchQuery={setSearchQuery}
				onPressed={onPressed}
			/>
		</Page>
	);
};

export default ChatEditGroupPage;

export interface IChatViewMembersParams extends Record<string, string | undefined> {
	channelId: string;
}
