import { useEffect } from '@hooks';
import type { RefObject } from 'react';

const ROW_LINE_HEIGHT = 16;

export const useTextAreaResize = ({ ref, maxRows, minRows }: IUseTextAreaResizeProps) => {
	const resize = (onSuccess?: () => void) => {
		if (!ref?.current) return;
		if (ref.current.scrollHeight > ROW_LINE_HEIGHT * maxRows) {
			ref.current.style.height = ROW_LINE_HEIGHT * maxRows + 'px';
			onSuccess && onSuccess();
			return;
		}
		ref.current.style.height = 'auto';
		ref.current.style.height = ref.current.scrollHeight + 'px';
		onSuccess && onSuccess();
	};

	const delayedResize = () => {
		window.setTimeout(resize, 0);
	};

	useEffect(() => {
		if (!ref?.current) return;
		ref.current.style.minHeight = ROW_LINE_HEIGHT * minRows + 'px';

		resize();
	}, []);

	return { delayedResize, resize };
};

interface IUseTextAreaResizeProps {
	ref: RefObject<HTMLTextAreaElement>;
	maxRows: number;
	minRows: number;
}
