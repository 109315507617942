import React from 'react';
import { useTranslation } from '@hooks';
import styles from './ThirdPageBody.module.css';
import { GlobeIcon, LocationIcon, MemberGroupIcon } from '@ui-kit';
import { AllocationsList } from '../AllocationsList';

const ThirdPageBody = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Compare your allocation to...')}</p>
			<AllocationsList
				allocations={[
					{
						Icon: MemberGroupIcon,
						title: t('Your Groups'),
						color: '#8E723B',
					},
					{
						Icon: LocationIcon,
						title: t('Your Chapter'),
						color: '#333333',
					},
					{
						Icon: GlobeIcon,
						title: t('All Members'),
						color: '#007D8A',
					},
				]}
			/>
		</div>
	);
};

export default ThirdPageBody;
