import { PercentComparisonChart } from '@ui-kit';
import styles from './AssetAllocationComparativeChart.module.css';
import { useTranslation, useMemo } from '@hooks';
import type { TAssetAllocationPeriod, TAssetAllocationValues } from '@typings';
import { formatAssetAllocationToAssetAllocationComparative } from '@ui-modules/assetAllocation/utils/formatAssetAllocationToAssetAllocationComparative';

const AssetAllocationComparativeChart = ({
	comparativeTitle,
	comparePeriod,
	userAssetAllocation,
	comparingAssetAllocation,
}: IAssetAllocationComparativeChartProps) => {
	const { t } = useTranslation();

	const title = comparePeriod ? t('Comparing Data to {{year}} Q{{quarter}}', comparePeriod) : t('Compare');
	const chartData = useMemo(() => {
		const EMPTY_ASSET_ALLOCATION = {} as TAssetAllocationValues; // it used for generating initial values if the user asset allocation is not created yet.
		return formatAssetAllocationToAssetAllocationComparative(t)(
			userAssetAllocation ?? EMPTY_ASSET_ALLOCATION,
			comparingAssetAllocation ?? EMPTY_ASSET_ALLOCATION,
		);
	}, [userAssetAllocation, comparingAssetAllocation]);

	return (
		<div className={styles.chart__container}>
			<div className={styles.chart__heading}>
				<p className={styles.chart__title}>{title}</p>
				<div className={styles.chart__subtitle}>
					<span className={styles.chart__subtitle_comparativeMain}>{t('me')}</span>
					<span className={styles.chart__subtitle_connector}>{t('vs')}</span>
					<span className={styles.chart__subtitle_comparative}>{comparativeTitle}</span>
				</div>
			</div>
			<div className={styles.chart__content}>
				{chartData.map((category) => (
					<PercentComparisonChart chartData={category.data} key={category.id} title={category.title} />
				))}
			</div>
		</div>
	);
};

export interface IAssetAllocationComparativeChartProps {
	comparativeTitle: string;
	comparePeriod?: TAssetAllocationPeriod;
	userAssetAllocation: TAssetAllocationValues;
	comparingAssetAllocation: TAssetAllocationValues;
}
export default AssetAllocationComparativeChart;
