import { useService } from '@hooks';
import { getActivityLinkType } from '../utils/getActivityLinkType';
import type { TActivity, TMixpanelCrossPromotionCtaType, TPinnedBanner } from '@typings';

export const useCrossPromotionsAnalytics = () => {
	const analytics = useService('AnalyticsService');

	const trackActivityClick = (link: string, activity: TActivity) => {
		const communitySlug = activity.collectionCommunityReference?.data?.slug || activity.community?.slug; // migration to use "collectionCommunityReference" (T21C-2485)
		analytics.trackEvent('cross_promotion_opened', {
			'#post_title': activity?.subject ?? undefined,
			'#stream_activity_id': activity?.id,
			'#symfony_activity_id': activity?.foreign_id,
			'#cta_type': getCrossPromotionCtaType(activity),
			'#feed_slug': communitySlug ?? activity.origin,
			'#cta_destination': getActivityLinkType(link),
		});
	};

	const trackPinnedBannerClick = (link: string, banner: TPinnedBanner) => {
		analytics.trackEvent('cross_promotion_opened', {
			'#stream_activity_id': banner?.streamActivityId,
			'#symfony_activity_id': banner['@id'],
			'#feed_slug': banner?.slug,
			'#cta_type': 'banner',
			'#cta_destination': link, // Type should be inAppPage , url or event
		});
	};

	const trackActivityView = (activity: TActivity) => {
		if (activity && activity.isPromoted) {
			const communitySlug = activity.collectionCommunityReference?.data?.slug || activity.community?.slug; // migration to use "collectionCommunityReference" (T21C-2485)
			analytics.trackEvent('cross_promotion_opened', {
				'#post_title': activity.subject ?? undefined,
				'#stream_activity_id': activity.id,
				'#symfony_activity_id': activity.foreign_id,
				'#cta_type': 'normal',
				'#feed_slug': communitySlug ?? activity.origin,
				'#cta_destination': 'post_screen', // As we are just getting redirect user to details screen
			});
		}
	};

	return { trackPinnedBannerClick, trackActivityClick, trackActivityView };
};

const getCrossPromotionCtaType = (activity: TActivity): TMixpanelCrossPromotionCtaType => {
	switch (activity.settings.postType) {
		case 'adImage':
			return 'ad_image';
		case 'button':
			return 'button';
		case 'event':
			return 'event';
		case 'pinnedBanner':
			return 'banner';
		case 'post':
		default:
			return 'normal';
	}
};
