import { Toast } from '@ui-kit';
import { useTranslation } from '@hooks';

const EventCancelledToast = ({ canceledReason }: IEventCancelledToastProps) => {
	const { t } = useTranslation();
	return (
		<Toast
			fullWidth
			subtitle={
				canceledReason
					? t('This event was cancelled due to {{canceledReason}}.', {
							canceledReason,
					  })
					: t('This event was cancelled.')
			}
			title={t('Cancelled')}
			type="error"
		/>
	);
};

interface IEventCancelledToastProps {
	canceledReason?: string | null;
}

export default EventCancelledToast;
