import { useMe, useMutation } from '@hooks';
import { useService } from '@services';
import type { TActivity, TCommunity } from '@typings';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';

export const useOpenOrJoinCommunity = ({ onPostOpen }: TOpenOrJoinCommunityParams) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { joinNetwork, isLoading: isJoiningNetwork } = useNetworkMutations();
	const { user } = useMe();

	const mutation = useMutation(
		async ({ communityId, activityId, globalFeedName }: TOpenOrJoinCommunityParamsMutation) => {
			if (communityId) {
				const community = await reactQuery.queryClient.fetchQuery<TCommunity>(
					['community.getCommunity', communityId],
					async () => api.community.getCommunity(communityId as string),
				);

				const isMember = community.membership?.participant === `/api/users/${user.id}`;

				if (!isMember) {
					// If not a member, join the community and then open the post
					joinNetwork(community, {
						onSettled: () => onPostOpen(activityId, globalFeedName),
					});
				} else {
					onPostOpen(activityId, globalFeedName);
				}
				return;
			}
			onPostOpen(activityId, globalFeedName);
		},
	);

	return {
		...mutation,
		isJoiningNetwork,
	};
};

export type TOpenOrJoinCommunityParams = {
	onPostOpen: (activityId: TActivity['id'], globalFeedName: string | undefined) => void;
};

export type TOpenOrJoinCommunityParamsMutation = {
	communityId?: string;
	activityId: string;
	globalFeedName?: string;
};
