import { useMutation, useService } from './index';
import { Lock } from '@utils';
import type { TActivity, TDealWithReactions, TNetworkConnectionWithReactions } from '@typings';

const lock = new Lock<TGenericActivityId>();

export const useToggleLikeMutation = <TActivityWithReactions extends TGenericActivity>(
	params?: { onSuccess?: (activity: TActivity) => void } | undefined,
) => {
	const feed = useService('FeedService');

	return useMutation<boolean, Error, { activity: TActivityWithReactions; liked: boolean }>(
		['ToggleLikeMutation'],
		async ({ activity, liked }) => {
			if (lock.isAcquired(activity.id)) return false;
			await lock.acquire(activity.id);
			if (liked) {
				await feed.likeActivity(activity.streamActivityId);
				return true;
			}
			const reactionId = activity.ownLikes?.[0]?.id;
			if (reactionId) {
				await feed.unlikeActivity(reactionId);
				return true;
			}
			return false;
		},
		{
			onSuccess: async (isDone, { activity: likedActivity }) => {
				if (!isDone) return;

				const updatedActivity = (await feed.getActivities([likedActivity.streamActivityId]))[0] as TActivity;
				if (!updatedActivity) return console.warn('Activity not found', likedActivity.streamActivityId);

				params?.onSuccess?.(updatedActivity);
				lock.release(likedActivity.id);
			},
			onError: (error, { activity }) => {
				lock.release(activity.id);
			},
		},
	);
};

type TGenericActivity = TDealWithReactions | TNetworkConnectionWithReactions;
type TGenericActivityId = TGenericActivity['id'];
