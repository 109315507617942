import { useService, useQuery, useMe } from '@hooks';
import { dayjs } from '@utils';
import type { TAssetAllocationExpanded } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useMyAssetAllocationQuery = <T = TAssetAllocationExpanded>(
	queryOptions?: UseQueryOptions<TAssetAllocationExpanded, Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TAssetAllocationExpanded, Error, T>(
		reactQuery.queryKeys.getMyAssetAllocation(),
		async () => await api.assetAllocation.getAssetAllocation(user.id),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
