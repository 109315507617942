import ClampLines from 'react-multiline-clamp';
import { useService, useTranslation } from '@hooks';
import { useGlobalSearchNavigation } from '@ui-modules/globalSearch/hooks/useGlobalSearchNavigation';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import type { TSearchResult } from '@typings';
import styles from './SearchResult.module.css';
import { IconByDefinition } from './IconByDefinition';
import { SubtitleByDefinition } from './SubtitleByDefinition';

const SearchResult = ({
	title,
	definition,
	searchQuery,
	objectID,
	avatar,
	name,
	expertise,
	investmentExpertise,
	interests,
	communities,
	startDate,
	communityName,
	communitySlug,
	streamFeedId,
	streamActivityId,
	virtualType = false,
	inPersonType = false,
	biography,
	profileType,
	assetClass,
	fileOrFolder,
	folderID,
	mimeType,
	relatedEntityId,
	relatedEntityName,
	relatedEntityDefinition,
}: TSearchResult) => {
	const { resetSearchQuery, setIsSearchActive } = useGlobalSearchState();
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const getSearchResultNavigation = useGlobalSearchNavigation({
		definition: relatedEntityDefinition ?? definition,
		objectID: relatedEntityId ?? objectID,
		communitySlug,
		streamActivityId,
		streamFeedId,
		fileOrFolder,
		folderID: fileOrFolder === 'folder' ? objectID : folderID,
	});
	const { addRecentSearch } = useGlobalSearchHistory(searchQuery);

	const membersPotentialMatches = {
		expertise,
		interests,
		communities,
		biography,
		investmentExpertise,
	};

	const eventOrMeetingType =
		virtualType && inPersonType ? t('virtual • in person') : virtualType ? t('virtual') : t('in person event');

	const boldFragmentBySearchQuery = new RegExp(searchQuery, 'gi');
	const parsedName = name?.replace(boldFragmentBySearchQuery, (substring) => '<b>' + substring + '</b>') || '';
	const parsedTitle = title?.replace(boldFragmentBySearchQuery, (substring) => '<b>' + substring + '</b>') || '';

	const handlePressSearchResult = () => {
		resetSearchQuery();
		setIsSearchActive(false);
		setTimeout(() => {
			getSearchResultNavigation();
		}, 100);
		addRecentSearch(searchQuery);
		analytics.trackEvent('SearchInteractions', { search_location: definition, search_query: searchQuery });
	};

	return (
		<div
			className={styles.searchResult__wrapper}
			onClick={handlePressSearchResult}
			onKeyPress={handlePressSearchResult}
		>
			{IconByDefinition({ definition, avatar, name, profileType, mimeType })}
			<div className={styles.searchResult__dataWrapper}>
				{parsedName || parsedTitle ? (
					<ClampLines lines={3} showLessElement={() => null} showMoreElement={() => null}>
						<span
							className={styles.searchResult__title}
							dangerouslySetInnerHTML={{ __html: parsedName || parsedTitle }}
						></span>
					</ClampLines>
				) : null}
				<SubtitleByDefinition
					assetClass={assetClass}
					communityName={communityName}
					definition={definition}
					eventOrMeetingType={eventOrMeetingType}
					membersPotentialMatches={membersPotentialMatches}
					relatedEntityName={relatedEntityName}
					searchQuery={searchQuery}
					startDate={startDate}
				/>
			</div>
		</div>
	);
};
export default SearchResult;
