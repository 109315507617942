import styles from './Card.module.css';
import type { ReactNode } from 'react';
import { clsx } from '@utils';

/** The body part of the Card component. */
const CardArticle = ({ children, articleMode = 'transparent', iconPosition }: ICardArticleProps) => {
	return (
		<article className={clsx(iconPosition === 'right' && styles.card__article)} data-mode={articleMode}>
			{children}
		</article>
	);
};

export interface ICardArticleProps {
	/** ReactNode content of the card. */
	children: ReactNode;
	iconPosition?: 'right' | 'left';
	articleMode?: 'default' | 'transparent';
}

export default CardArticle;
