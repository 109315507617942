import { useService, useQuery } from '@hooks';

import type { TDealFilter } from '@typings';
import { dayjs } from '@utils';

export const useDealFilterViaCommunityId = (communityId: string | null) => {
	const api = useService('ApiService');
	const query = useQuery<Partial<TDealFilter>[], Error>(
		['dealFilter.viaCommunityId', communityId],
		async () => await api.invest.getDealFilterViaCommunityId(communityId as string),
		{
			enabled: !!communityId,
			staleTime: dayjs.duration(5, 'minute').asMilliseconds(),
			cacheTime: dayjs.duration(5, 'minute').asMilliseconds(),
		},
	);

	// Ensure the data is properly typed
	const data = query.data as Record<string, TDealFilter[]> | undefined;
	return { ...query, data: Array.isArray(data) ? data : data?.['hydra:member'] }; // temporary check two different response types: T21C-5585 [@juliayojji]
};
