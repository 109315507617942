import { useService, useQuery, useTranslation } from '@hooks';
import { kebabCase, ActivityEntity } from '@utils';
import type { QueryObserverOptions } from '@tanstack/react-query';
import type { TGlobalFeeds } from '@typings';

export const useLearnFeedTabsQuery = (options?: QueryObserverOptions<TGlobalFeeds[], Error, TLearnFeedTab[]>) => {
	const { t } = useTranslation();
	const api = useService('ApiService');

	return useQuery<TGlobalFeeds[], Error, TLearnFeedTab[]>(
		['feed.useLearnCategory'],
		async () => api.streamFeeds.getLearnCategoriesList(),
		{
			staleTime: Infinity,
			select: (feeds) =>
				feeds
					.filter((feed) => feed.tag !== 'global')
					.map<TLearnFeedTab>((feed) => {
						const isAllFeed = feed.feedId === 'user:LEARNING';
						const title = isAllFeed ? t('All') : ActivityEntity.humanizeLearnFeedName(feed.name);
						return {
							isAll: isAllFeed,
							title: title,
							feedName: kebabCase(title),
							slug: feed.feedId.replace('user:', ''),
						};
					}),
			...options,
		},
	);
};

export type TLearnFeedTab = { title: string; feedName: string; slug: string; isAll: boolean };
