import type { TEvent, TEventAttendanceCanceling, TEventAttendanceChanging, TEventAttendanceRequesting } from '@typings';
import { getEventAttendanceMutationPredicate } from '@ui-modules/events/hooks/useEventAttendanceMutations';
import { useIsMutating, useMe, useToggle, useTranslation } from '@hooks';
import { MeetingEntity } from '@utils';
import { Button, RightArrowIcon, CloseIcon, ClockIcon, EmailIcon, SegmentedControl, Modal } from '@ui-kit';
import styles from './MeetingActionButton.module.css';
import { useMemberMembershipQuery } from '@ui-modules/account/hooks/useMemberMembershipQuery';

const MeetingActionButton = ({
	meeting,
	detailed,
	size,
	onRequestAttendance,
	onCancelRequestAttendance,
	onChangeAttendance,
	onLaunchVirtualMeeting,
}: IMeetingActionButtonProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { data: userMemberships = [] } = useMemberMembershipQuery();

	const launchVirtualMeeting = () => onLaunchVirtualMeeting(meeting);
	const changeEventAttendance = (going: boolean) => onChangeAttendance({ event: meeting, going: going });
	const requestToAttend = () =>
		onRequestAttendance({ event: meeting, type: meeting.isAllowedInPersonAttendance ? 'in-person' : 'virtual' });
	const cancelRequestToAttend = () => onCancelRequestAttendance({ event: meeting });
	const isRequestSending = useIsMutating(['attendance.requestMeetingAttendance'], {
		predicate: getEventAttendanceMutationPredicate(meeting.id),
	});
	const isRequestCancelling = useIsMutating(['attendance.cancelMeetingAttendance'], {
		predicate: getEventAttendanceMutationPredicate(meeting.id),
	});
	const [showDialog, toggleShowDialog] = useToggle(false);

	if (MeetingEntity.isCancelled(meeting) && detailed) {
		return <Button disabled title={t('Cancelled')} type="outline" variant={size} />;
	} else if (MeetingEntity.isClosed(meeting, user)) {
		return detailed ? <Button disabled title={t('Closed')} type="outline" variant={size} /> : null;
	} else if (MeetingEntity.canJoinToVirtualMeeting(meeting))
		return (
			<Button
				icon={<RightArrowIcon height={14} width={14} />}
				iconPosition="right"
				title={t('Launch Virtual Meeting')}
				trackingName="events--launch-virtual-meeting"
				type="outline"
				variant={size}
				onClick={launchVirtualMeeting}
			/>
		);
	else if (MeetingEntity.hasActiveRequestToAttend(meeting))
		return (
			<>
				<Button
					className={styles.meetingActionButton_borderless}
					icon={
						detailed || !!isRequestCancelling ? undefined : (
							<CloseIcon className={styles.meetingActionButton__tickIcon} height={8} width={8} />
						)
					}
					iconPosition="left"
					loading={!!isRequestCancelling}
					title={t('Cancel Request')}
					type="outline"
					variant={size}
					onClick={toggleShowDialog}
				/>
				<Modal
					cancelTitle={t('Cancel')}
					confirmTitle={t('Cancel Request')}
					subTitle={t(
						'Are you sure you want to cancel {{meetingName}} meeting Request to Attend? This cannot be undone.',
						{ meetingName: meeting.title },
					)}
					title={t('Cancel this Request to Attend?')}
					variant="medium"
					visible={showDialog}
					onCancel={toggleShowDialog}
					onConfirm={() => {
						cancelRequestToAttend();
						toggleShowDialog();
					}}
				/>
			</>
		);
	else if (MeetingEntity.canRequestToAttendLater(meeting, user))
		return (
			<Button
				className={styles.meetingActionButton_borderless}
				disabled
				icon={<ClockIcon height={14} width={14} />}
				iconPosition="left"
				title={t('Requests open {{date}}', {
					date: MeetingEntity.getRegistrationStartDate(meeting)?.format('D MMM YYYY'),
				})}
				titleClassName={styles.meetingActionButton__title}
				type="outline"
				variant={size}
			/>
		);
	else if (MeetingEntity.canRequestToAttend(meeting))
		return (
			<Button
				icon={detailed || !!isRequestSending ? undefined : <EmailIcon color="black" height={12} width={12} />}
				iconPosition="left"
				loading={!!isRequestSending}
				title={t('Request to Attend')}
				trackingName="events--request-to-attend"
				type="outline"
				variant={size}
				onClick={requestToAttend}
			/>
		);
	else if (MeetingEntity.canRegister(meeting))
		return (
			<SegmentedControl
				disabled={MeetingEntity.isAttendanceChangeDisabled(meeting, userMemberships)}
				isLeftOptionSelected={
					meeting.currentUserAttendance ? meeting.currentUserAttendance?.status === 'going' : undefined
				}
				leftOptionTitle={t('Attending')}
				rightOptionTitle={t("Can't Make it")}
				size={size}
				onLeftOptionClick={() => changeEventAttendance(true)}
				onRightOptionClick={() => changeEventAttendance(false)}
			/>
		);
	else return null;
};

export interface IMeetingActionButtonCallbacksProps {
	onChangeAttendance: (eventAttendanceChanging: TEventAttendanceChanging) => void;
	onRequestAttendance: (eventAttendanceRequesting: TEventAttendanceRequesting) => void;
	onCancelRequestAttendance: (eventAttendanceCanceling: TEventAttendanceCanceling) => void;
	onLaunchVirtualMeeting: (event: TEvent) => void;
}

export interface IMeetingActionButtonProps extends IMeetingActionButtonCallbacksProps {
	meeting: TEvent;
	/** If detailed = false, then cancelled, closed and future registration states are hidden. */
	detailed: boolean;
	size: 'medium' | 'small';
}

export default MeetingActionButton;
