import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TProfileWithUser } from '@typings';

/** Returns set of profiles which belongs to passed array of profile IDs. */
export const useProfilesByIdsInfiniteQuery = (profileIds: TProfileWithUser['id'][] = []) => {
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TProfileWithUser>(
		['profile.getProfilesByIds', profileIds],
		async ({ pageParam }) => await api.profile.getPaginatedProfilesByIds(profileIds, pageParam),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			enabled: !!profileIds.length,
		},
	);
};
