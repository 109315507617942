import { Gap, Button, Toast } from '@ui-kit';
import SetNewPasswordForm from '@ui-modules/auth/components/SetNewPasswordForm';
import { Page } from '../../components';
import { useTranslation, useNavigate, useService } from '@hooks';
import { useOktaSetNewPasswordMutation } from '@ui-modules/auth/hooks/useOktaSetNewPasswordMutation';
import { noop } from '@utils';
import { ROUTES } from '@constants';
import styles from './SetNewPasswordPage.module.css';

const SetNewPasswordPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const okta = useService('OktaService');

	const { mutateAsync: setNewPassword, submitError } = useOktaSetNewPasswordMutation({
		onSuccess: () => navigate(ROUTES.signIn(), { replace: true }),
	});
	const goBack = () => {
		okta.cancelTransaction().finally(() => navigate(ROUTES.signIn(), { replace: true }));
	};

	return (
		<Page title={t('New password')}>
			<div className={styles.setNewPasswordPage}>
				<header className={styles.setNewPasswordPage__header}>
					<h4>{t('Reset password')}</h4>
				</header>
				<Gap gap={16} />
				{submitError ? (
					<Toast subtitle={submitError.subtitle} title={submitError.title} type="error" onClose={noop} />
				) : null}
				<Gap gap={16} />
				<section>
					<SetNewPasswordForm onSubmit={setNewPassword} />
					<Gap gap={32} />
					<Button block="fit" title={t('Back to Sign in')} type="solid" variant="medium" onClick={goBack} />
					<Gap gap={32} />
				</section>
			</div>
		</Page>
	);
};

export default SetNewPasswordPage;
