import { forwardRef, Suspense } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { ActivityIndicator } from '@ui-kit';
import styles from './TabWrapper.module.css';
import type { PropsWithChildren, ForwardedRef } from 'react';
import { clsx } from '@utils';

/** Can be used as a container for TabPanel content. Provides paddings, error & loading handling. */
const TabWrapper = ({ children, className }: ITabWrapperProps, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<ErrorBoundary>
			<Suspense fallback={<ActivityIndicator size="medium" type="fit" />}>
				<div className={styles.communityTabWrapper}>
					<div className={clsx(styles.communityTabWrapper__children, className)} ref={ref}>
						{children}
					</div>
				</div>
			</Suspense>
		</ErrorBoundary>
	);
};

export interface ITabWrapperProps extends PropsWithChildren {
	className?: string;
}

export default forwardRef(TabWrapper);
