import { forwardRef } from 'react';
import type { ForwardedRef } from 'react';
import { clsx } from '@utils';
import styles from './InputSearch.module.css';
import { ResetIcon, SearchIcon } from '@ui-kit/icons';

const InputSearch = (
	{
		value,
		placeholder = 'Search',
		setIsSearchActive,
		setSearchQuery,
		addRecentSearch,
		searchQuery = '',
		appearance = 'default',
		onReset,
		...props
	}: IInputSearchProps,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	return (
		<div className={styles.input__wrapper} data-appearance={appearance}>
			<div className={clsx(styles.input__container, styles.input__wrapper)}>
				<span className={clsx(styles.input__icon, styles.input__icon_left)}>
					<SearchIcon className={styles.input__icon_search} height={12} width={12} />
				</span>
				<input
					className={styles.input}
					placeholder={placeholder}
					ref={ref}
					type="text"
					value={searchQuery}
					onChange={(e) => {
						setSearchQuery(e.target.value);
					}}
					onClick={() => setIsSearchActive?.(true)}
					onFocus={() => setIsSearchActive?.(true)}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && searchQuery.length >= 3) {
							addRecentSearch?.(searchQuery);
						}
					}}
					{...props}
				/>
				<span
					className={clsx(styles.input__icon, styles.input__icon_right)}
					onClick={() => {
						setSearchQuery('');
						onReset?.();
					}}
					onKeyPress={() => {
						setSearchQuery('');
						onReset?.();
					}}
				>
					{searchQuery.length ? <ResetIcon className={styles.input__icon_reset} height={10} width={10} /> : null}
				</span>
			</div>
		</div>
	);
};

export interface IInputSearchProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	setIsSearchActive?: (status: boolean) => void;
	searchQuery: string;
	appearance?: 'default' | 'bold';
	setSearchQuery: (query: string) => void;
	addRecentSearch?: (recent: string) => void;
	onReset?: () => void;
}

export default forwardRef(InputSearch);
