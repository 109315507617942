import { ActivityIndicator } from '@ui-kit';
import type { LoadMorePaginatorProps } from 'react-activity-feed';
import { useFeedContext } from 'react-activity-feed';
import { useCallback, useEffect, useMe, useService } from '@hooks';
import InfiniteScroll from 'react-infinite-scroller';

import styles from './NotificationsList.module.css';
import NotificationListHeader from './NotificationListHeader';
import type { IFeedListenerParams } from '../../../../services/interfaces/FeedService.interface';

export const NotificationListPaginator = (props: INotificationListPaginatorProps) => {
	const { unread, refresh, refreshing } = useFeedContext();
	const feed = useService('FeedService');
	const { user } = useMe();

	const listener = useCallback(
		function refetchNotifications(data: IFeedListenerParams) {
			// ignore data.deleted
			if (data.new?.length) {
				refresh();
			}
		},
		[refresh],
	);

	useEffect(() => {
		feed.onSubscribe(user.slug, listener);
	}, [feed, user, refresh, listener]);

	const markAllAsRead = useCallback(() => {
		feed.markAllNotificationsAsRead(user.slug).finally(refresh);
	}, [feed, user, refresh]);

	return (
		<>
			<NotificationListHeader isMarkAsReadDisabled={!unread} onMarkAsRead={markAllAsRead} />
			<div className={styles.notificationsList__content}>
				<InfiniteScroll
					hasMore={props.hasNextPage}
					loader={<ActivityIndicator type="fit" />}
					loadMore={() => {
						if (!refreshing) {
							props.loadNextPage?.();
						}
					}}
					useWindow={false}
				>
					{props.children}
				</InfiniteScroll>
			</div>
		</>
	);
};

interface INotificationListPaginatorProps extends LoadMorePaginatorProps {}
