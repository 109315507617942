import { Page, FullScreenPageLayout } from '../../components';
import { useParams, useService, useState, useNavigate, useTranslation, useNotification } from '@hooks';
import { filterChannelMembers } from '@ui-modules/chat/utils/filterChannelMembers';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import { useUnbanChatMemberMutation } from '@ui-modules/chat/hooks/useUnbanChatMemberMutation';
import ChatMembers from '@ui-modules/chat/components/ChatMembers';
import type { TUserProfileRecord } from '@typings';
import { ROUTES } from '@constants';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';

const ChatUnPauseMembers = () => {
	const channelId = useParams<IChatUnPauseMembersParams>().channelId as string;
	const { mutateAsync: getPauseMembers } = useUnbanChatMemberMutation();

	const { data: channel } = useGetChannelQuery(channelId, {
		onSuccess: () => {
			if (channel) {
				getPauseMembers(channel, {
					onSuccess(members) {
						if (members.length > 0) {
							const pauseMembers = filterChannelMembers(members, userProfileRecords);
							setChannelMember(pauseMembers);
						}
					},
				});
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		},
	});
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: userProfileRecords = [] } = useAddressBookQuery();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [channelMember, setChannelMember] = useState<TUserProfileRecord[]>([]);
	const chat = useService('ChatService');
	const { showSuccess } = useNotification();

	const onBlockUnBlockPressed = (member: TUserProfileRecord) => {
		const userName = `${member.firstName} ${member.lastName}`;
		chat
			.unpauseUser(member.slug)
			.then(() => {
				const members = channelMember.filter((item) => item.slug !== member.slug);
				setChannelMember(members);
			})
			.then(() => {
				showSuccess({
					title: t('You have resumed {{userName}}', { userName }),
					subtitle: t('{{userName}} can now resume chat', { userName }),
				});
			});
	};
	return (
		<Page title={t('Resume Members')}>
			<FullScreenPageLayout
				headerTitle={t('Group Chat')}
				onLeftButtonPress={() => navigate(ROUTES.chatChannel(channel?.id))}
			>
				<ChatMembers
					channelMembers={channelMember}
					isLoading={isLoading}
					listId={`unpause-${channelId}`}
					showBlockIcon={true}
					onBlockUnBlockPressed={onBlockUnBlockPressed}
				/>
			</FullScreenPageLayout>
		</Page>
	);
};

export default ChatUnPauseMembers;

export interface IChatUnPauseMembersParams extends Record<string, string | undefined> {
	channelId: string;
}
