import { DropdownButton } from '@ui-kit';
import { useMe } from '@hooks';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useNetworkConnectionMemberRelationQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationQuery';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { NetworkConnectionEntity } from '@utils';
import type { TNetworkConnection, TNetworkConnectionMemberRelationStatus } from '@typings';
import { useNetworkConnectionMemberRelationStatusMutation } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationStatusMutation';
import styles from './NetworkConnectionStatusButton.module.css';

const NetworkConnectionStatusButton = ({
	networkConnectionId,
	networkConnectionsName,
}: INetworkConnectionStatusButtonProps) => {
	const { user } = useMe();

	const { data: networkConnection, isLoading: isNetworkConnectionLoading } =
		useNetworkConnectionQuery(networkConnectionId);
	const {
		data: networkConnectionMemberRelation,
		isRefetching,
		isLoading,
	} = useNetworkConnectionMemberRelationQuery(networkConnectionId);
	const {
		data: memberRelations = [],
		isLoading: isMemberRelationsLoading,
		isError: isMemberRelationsError,
	} = useNetworkConnectionMemberRelationsQuery(networkConnection?.community?.id as string, {
		select: (data) =>
			NetworkConnectionEntity.prepareAvailableMemberRelationStatuses(
				data,
				networkConnection as TNetworkConnection,
				user,
			).map((relationOption) =>
			!relationOption.id ? { ...relationOption, style: styles.option } : relationOption,
		),
		enabled: !!networkConnection,
	});
	const value = NetworkConnectionEntity.getMemberRelationStatusId(
		networkConnectionMemberRelation?.networkConnectionStatus,
	);

	const { mutate: changeMemberRelation, isLoading: isMutating } = useNetworkConnectionMemberRelationStatusMutation(
		networkConnectionId,
		networkConnectionsName,
	);

	const isProcessing =
		isNetworkConnectionLoading || isMemberRelationsLoading || isMutating || isRefetching || isLoading;

	return (
		<DropdownButton<string>
			disabled={isMemberRelationsError}
			loading={isProcessing}
			options={memberRelations}
			value={value}
			onChange={(selectedNetworkConnectionRelationStatusId: TNetworkConnectionMemberRelationStatus['id']) => {
				changeMemberRelation(
					networkConnectionMemberRelation
						? {
								networkConnectionRelationId: networkConnectionMemberRelation.id,
								networkConnectionRelationStatusId: NetworkConnectionEntity.isNotFollowingMemberRelationStatusId(
									selectedNetworkConnectionRelationStatusId,
								)
									? undefined
									: selectedNetworkConnectionRelationStatusId,
						  }
						: {
								networkConnectionRelationId: undefined,
								networkConnectionRelationStatusId: selectedNetworkConnectionRelationStatusId,
						  },
				);
			}}
		/>
	);
};

export interface INetworkConnectionStatusButtonProps {
	networkConnectionId: string;
	networkConnectionsName: string;
}

export default NetworkConnectionStatusButton;
