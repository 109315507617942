import { useLayoutEffect, useService, useQuery, useNavigate, useLogger } from '@hooks';
import { ROUTES } from '@constants';
import type { PropsWithChildren } from 'react';
import { formatUserSubTitle } from '@utils';
import type { TCommunitiesForBadge } from '@typings';

/** Get currently authenticated user.
 * 	Disabled by default to run it only during authorization process.
 */
const InitializeUserWrapper = ({ children }: IInitializeSessionWrapperProps) => {
	const logger = useLogger('InitializeUserWrapper');
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const language = useService('I18nService');
	const navigate = useNavigate();
	const { useAppDispatch, me, usersGroups } = useService('ReduxService');

	const dispatch = useAppDispatch();

	const { data: meResponse } = useQuery(
		reactQuery.queryKeys.getMe(),
		async () => {
			const meResponse = await api.user.getMe();
			dispatch(me.saveAccount(meResponse)); // Synchronize meResponse with Redux immediately in the query function to prevent stopping Suspense before Redux updated. [@dmitriy.nikolenko]
			dispatch(
				usersGroups.setUsersGroups({
					[meResponse.id]: formatUserSubTitle(language.i18n.t)(
						{
							...meResponse,
							communitiesForBadge: { names: meResponse?.communitiesForBadge?.shortNames || [] } as TCommunitiesForBadge,
						},
						'•',
					),
				}),
			);
			return meResponse;
		},
		{
			enabled: true,
			staleTime: Infinity,
			suspense: true,
			onSuccess: () => {
				logger.log('current user profile uploaded');
			},
			onError() {
				navigate(ROUTES.signOut());
			},
		},
	);

	useLayoutEffect(() => {
		if (meResponse) dispatch(me.saveAccount(meResponse)); // Synchronize meResponse with Redux when changed through invalidateQueries or direct setQueryCache. [@dmitriy.nikolenko]
	}, [meResponse]);

	return <>{children}</>;
};

export interface IInitializeSessionWrapperProps extends PropsWithChildren {}

export default InitializeUserWrapper;
