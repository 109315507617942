import { useEffect } from 'react';
import { extractSearchQuery } from '@ui-modules/chat/components/AutocompleteSuggestionList/utils';
import type { IAutocompleteSuggestionListProps } from '../components/AutocompleteSuggestionList';

export const useChatMentionsVisibility = (props: IAutocompleteSuggestionListProps) => {
	const extractedMention = extractSearchQuery(props?.value, props?.selectionEnd);

	useEffect(() => {
		const suggestionListContainer = document.querySelector(
			'.rta__autocomplete.str-chat__suggestion-list-container.str-chat__emojisearch',
		) as HTMLElement;

		if (suggestionListContainer) {
			suggestionListContainer.style.display = extractedMention !== undefined ? 'block' : 'none';
		}
	}, [extractedMention]);

	return extractedMention;
};
