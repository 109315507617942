import { useEffect, useMemo, useState, useTranslation, useRef, useClickAway } from '@hooks';
import type { TEvent } from '@typings';
import { ActivityIndicator, InputSearch, Portal } from '@ui-kit';
import EventItem from '@ui-modules/events/components/EventItem';
import { useCalendarItemsQuery } from '@ui-modules/events/hooks/useCalendarItemsQuery';
import { dayjs } from '@utils';
import styles from './ChatEventsModal.module.css';

const ChatEventsModal = ({ visible, onClose, setSelectedEvent }: IChatEventsModalProps) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState('');
	const date = dayjs().format('YYYY-MM-DD');
	const { allEventsLoading, allEvents } = useCalendarItemsQuery(date, searchQuery);
	const modalRef = useRef(null);
	useClickAway(modalRef, () => onClose());

	const events = useMemo(() => {
		return allEvents.filter((item) => item.isCanceled === false);
	}, [allEvents.length]);

	useEffect(() => {
		if (visible) {
			setSearchQuery('');
		}
	}, [visible]);

	const renderItem = (event: TEvent) => {
		return (
			<div className={styles.event__itemContainer}>
				<EventItem
					className={styles.event__item}
					event={event}
					eventTypeClassName={styles.event__itemType}
					hideBanner
					hideChevronIcon
					shouldShowMeetingAction={false}
					onClick={() => {
						setSelectedEvent(event);
						onClose();
					}}
				/>
			</div>
		);
	};

	return visible ? (
		<Portal>
			<div className={styles.modal__container}>
				<div className={styles.modal__content} ref={modalRef}>
					<div className={styles.modal__topContent}>
						<div className={styles.modal__header}>
							<button className={styles.modal__headerButton} onClick={onClose}>
								<span>{t('Cancel')}</span>
							</button>
							<span className={styles.modal__headerTitle}>{t('Event search')}</span>
							<div className={styles.modal__headerBlock} />
						</div>
						<div className={styles.modal__searchContainer}>
							<InputSearch
								autoFocus
								placeholder={t('Search for an event')}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
							/>
						</div>
					</div>
					<div className={styles.contentList}>
						<div className={styles.contentListBackground}>
							{allEventsLoading ? (
								<div className={styles.contentList__empty}>
									<ActivityIndicator />
								</div>
							) : (
								<>
									{events?.length ? (
										events.map((event) => {
											return renderItem(event);
										})
									) : (
										<div className={styles.contentList__empty}>
											<span>{t('Sorry, no events were found.')}</span>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Portal>
	) : null;
};

export interface IChatEventsModalProps {
	visible: boolean;
	onClose: () => void;
	setSelectedEvent: (event: TEvent) => void;
}

export default ChatEventsModal;
