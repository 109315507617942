import { Button } from '@ui-kit';
import { useNotification, useTranslation, useDropzone } from '@hooks';
import { clsx } from '@utils';

import styles from './EventFilePicker.module.css';
import { COMMUNITY_DOCUMENTS } from '@tiger21-llc/connect-shared/src/constants';

const EventFilePicker = ({ onUpload, isLoading, description, title, className, disabled }: IEventFilePickerProps) => {
	const { showError } = useNotification();
	const { t } = useTranslation();

	const { getRootProps, isDragActive, getInputProps, open, isDragReject } = useDropzone({
		onDropRejected: () => showError({ title: 'Rejected', subtitle: 'Not allowed file type' }),
		onDrop: (acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles[0]) {
				onUpload(acceptedFiles[0]);
			}
		},
		noClick: true,
		multiple: false,
		accept: COMMUNITY_DOCUMENTS.availableMimeTypes.reduce((acc, type) => {
			return { ...acc, [type]: [] };
		}, {}),
	});

	return (
		<div
			className={clsx(
				styles.eventFilePicker,
				isDragActive && styles.eventFilePicker_dragActive,
				isDragReject && styles.eventFilePicker_dragReject,
				className,
			)}
			{...getRootProps()}
		>
			<input {...getInputProps()} /> {/* hidden input to make file upload working */}
			<div className={styles.eventFilePicker__infoBox}>
				<h4>{title || t('Attach a file')}</h4>
				<span>{description || t('Chairs may attach a file and optionally set a password.')}</span>
			</div>
			<Button disabled={disabled} loading={isLoading} title={t('Select A File')} type="blurred" onClick={open} />
		</div>
	);
};

interface IEventFilePickerProps {
	onUpload: (acceptedFiles: File) => void;
	isLoading?: boolean;
	description?: string;
	title?: string;
	className?: string;
	disabled?: boolean;
}

export default EventFilePicker;
