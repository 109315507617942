import { useCheckTextOnMaliciousUrls, useMe, useMutation, useNotification, useService, useTranslation } from '@hooks';
import { extractIdFromIri, toString } from '@utils';
import type { TLatestActivity, TPostActionParams } from '@typings';

export const usePostCreation = () => {
	const { t } = useTranslation();
	const feedService = useService('FeedService');
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const mixpanelAnalytics = useService('AnalyticsService');
	const { user } = useMe();
	const { showUnknownError, showError } = useNotification();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });

	const onCommunityPostAdd = useMutation<TLatestActivity, Error, TPostActionParams>(
		['streamActivityDiscussionPost.createNewPost'],
		async ({ networkSlug, body, subject, owner, files, images, videos }) => {
			await checkTextOnMaliciousUrls(String(body));
			return await feedService.createPost({
				networkSlug,
				body,
				subject,
				owner,
				files: files || [],
				images: images || [],
				videos: videos || [],
				isNeedSendNotification: true,
			});
		},
		{
			async onSuccess(createdPost, { network }) {
				const communitySlug = createdPost.collectionCommunityReference?.data?.slug || createdPost.community?.slug; // migration to use "collectionCommunityReference" (T21C-2485)
				communitySlug && queryClient.removeQueries(queryKeys.getUserFeed(communitySlug)); // Network discussion
				queryClient.removeQueries(queryKeys.getUserFeed(user.slug)); // Home feed

				const newActivityId = extractIdFromIri(String(createdPost['@id']));
				algoliaAnalytics.trackEvent('Discussion Created', { discussionId: newActivityId });
				if (network) {
					const networkType = network.features.includes('invest') ? 'Invest' : 'Lifestyle';
					if (networkType === 'Lifestyle') {
						mixpanelAnalytics.trackEvent('LifestyleNetworkDiscussionCreated', {
							network_id: network.id,
							PostTitle: createdPost?.title || toString(createdPost?.body).slice(0, 15) || undefined,
							PostType: 'Normal',
							PostCharacterLength: createdPost.body?.length || 0,
							PostID: newActivityId, // TODO we have to send streamActivityId but the backend can't supply us with it. A solution is absent, solving is postponed (@see T21C-6039) [@DmitriyNikolenko]
							NetworkName: network.name,
						});
					}
				}
			},
			onError(error: Error) {
				const parsedErrorMessage = JSON.parse(JSON.stringify(error)); // Handle error that returned from streamClient to show error details.
				parsedErrorMessage?.response?.data?.detail
					? showError({ title: t('Error uploading attachment'), subtitle: parsedErrorMessage?.response?.data?.detail })
					: showUnknownError(error as Error);
			},
		},
	);

	return {
		onCommunityPostAdd: onCommunityPostAdd.mutateAsync,
	};
};
