import { useNotification, useLayoutEffect, useTranslation } from '@hooks';
import { useDealsEnabled } from './useDealsEnabled';

export const useDealsDisabledEffect = (options?: TUseDealsDisabledEffect) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const { enabled: isDealsEnabled } = useDealsEnabled();

	useLayoutEffect(() => {
		if (!isDealsEnabled) {
			showInfo({
				title: options?.message ?? t('Invest is disabled. Please visit settings to enable.'),
			});
			options?.onDisabled?.();
		}
	}, []);
};

type TUseDealsDisabledEffect = {
	message?: string;
	onDisabled?: () => void;
};
