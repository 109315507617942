import { Textfit } from 'react-textfit';
import styles from './FitText.module.css';
import type { ITextfitProps } from 'react-textfit';

/** Text which tries to adjust width of the parent container.
 *  Had better to pass the 'min' props with minimum font size.
 *  When a minimum font size reached the text will be tailed with ellipsis.
 *  @example <FitText max={21} min={16} mode="single">Hello world</FitText>
 */
const FitText = ({ children, mode = 'single', ...props }: IFitTextProps) => {
	return (
		<Textfit className={styles.fitText} mode={mode} {...props}>
			{children}
		</Textfit>
	);
};

export interface IFitTextProps extends ITextfitProps {}

export default FitText;
