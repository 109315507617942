import { scrollToFirstError } from '@utils';

export const useSubmitOrScrollToError = () => {
	const submitOrScrollToErrorField = (submitForm: () => void) => {
		const hasError = scrollToFirstError();
		if (!hasError) submitForm();
	};

	return submitOrScrollToErrorField;
};
