import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity, TEvent } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useCommunityCalendarItemsQuery = (
	communityId: TCommunity['id'],
	queryOptions?: UseQueryOptions<TEvent[], Error>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TEvent[], Error>(
		queryKeys.getCommunityCalendarItems(communityId),
		async () => await api.calendarItem.getCommunityCalendarItems(communityId),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...queryOptions,
		},
	);
};
