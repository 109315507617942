import { useEffect, useRef } from '@hooks';
import { clsx } from '@utils';
import InputDescription from '../InputDescription';
import InputErrorMessage from '../InputErrorMessage';
import InputLabel from '../InputLabel';
import styles from './TextArea.module.css';
import When from '../When';

const ROW_LINE_HEIGHT = 16;
const TOTAL_PADDING_VERTICAL = 20;

const TextArea = ({
	value,
	label,
	placeholder,
	disabled,
	hint,
	errorMessage,
	errorMessageStyle,
	keepMarginBottom,
	minRows = 3,
	maxRows = 7,
	onChange,
	rounded,
	inputClassName,
	charactersLimit = 0,
	errorMessagePosition = 'left',
	...props
}: ITextAreaProps) => {
	const ref = useRef<HTMLTextAreaElement>(null);

	const resize = () => {
		if (!ref?.current) return;
		if (ref.current.scrollHeight > ROW_LINE_HEIGHT * maxRows + TOTAL_PADDING_VERTICAL) {
			ref.current.style.height = ROW_LINE_HEIGHT * maxRows + TOTAL_PADDING_VERTICAL + 'px';
			return;
		}
		ref.current.style.height = 'auto';
		ref.current.style.height = ref.current.scrollHeight + 'px';
	};

	const delayedResize = () => {
		window.setTimeout(resize, 0);
	};

	useEffect(() => {
		if (!ref?.current) return;
		ref.current.style.minHeight = ROW_LINE_HEIGHT * minRows + TOTAL_PADDING_VERTICAL + 'px';

		resize();
	}, []);

	return (
		<div className={styles.textarea__container}>
			<InputLabel text={label} />
			<textarea
				aria-label={label}
				className={clsx(
					styles.textarea,
					(errorMessage || errorMessageStyle) && styles.input_error,
					rounded && styles.textarea_rounded,
					inputClassName,
				)}
				disabled={disabled}
				placeholder={placeholder}
				ref={ref}
				value={value}
				onChange={(e) => {
					resize();
					onChange && onChange(e);
				}}
				onCut={delayedResize}
				onDrop={delayedResize}
				onKeyDown={delayedResize}
				onPaste={delayedResize}
				{...props}
			/>
			<InputDescription text={hint} />
			<div className={clsx(errorMessagePosition === 'right' && styles.textarea_errorRight)}>
				<InputErrorMessage keepMarginBottom={keepMarginBottom} text={errorMessage} />
			</div>
			<When condition={!!charactersLimit}>
				{(value?.length ?? 0) <= charactersLimit && (
					<p className={styles.textarea__counter}>{`${value?.length ?? 0}/${charactersLimit}`}</p>
				)}
			</When>
		</div>
	);
};

export interface ITextAreaProps
	extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	value?: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	errorMessage?: string;
	errorMessageStyle?: boolean;
	hint?: string;
	minRows?: number;
	maxRows?: number;
	/** By default the error message place is always visible and uses for displaying without layout shift. If 'true' after appearing an error message the margin between message and bottom will appear. Default 'false'. */
	keepMarginBottom?: boolean;
	rounded?: boolean;
	inputClassName?: string;
	errorMessagePosition?: 'left' | 'right' | 'none';
	charactersLimit?: number;
}

export default TextArea;
