import { useTranslation } from '@hooks';
import { Card, CollapsibleText } from '@ui-kit';

const ProfileMyRole = ({ isInvisible, myRole, title, collapsible }: IProfileMyRoleProps) => {
	const { t } = useTranslation();

	return isInvisible ? null : (
		<Card title={title || t('My Role')}>
			{myRole ? (
				collapsible ? (
					<CollapsibleText collapsedNumberOfLines={5} collapsedTitle={t('Read more')} expandedTitle={t('Show less')}>
						{myRole}
					</CollapsibleText>
				) : (
					<span>{myRole}</span>
				)
			) : (
				<span>{t('Start a conversation to learn more about me.')}</span>
			)}
		</Card>
	);
};

export interface IProfileMyRoleProps {
	isInvisible?: boolean;
	myRole?: string;
	title?: string;
	collapsible?: boolean;
}

export default ProfileMyRole;
