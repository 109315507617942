import { ActivityReportedError, ActivityMadeByBlockedUserError } from '@errors';
import { useBlockedAndBlockingUsers, useEffect, useNotification, useTranslation } from '@hooks';
import type { TActivity } from '@typings';

/** Validates whether certain activity can be viewed and calling 'onError' if not.
 *  Checks if activity is reported, made by blocked user, etc.
 *  Note; didn't manage to put it into useFeedActivityQuery because of significant delay in displaying error message and calling onError callback.
 *  @author dmitriy.nikolenko
 */
export const useValidateDisplayingActivity = (activity: TActivity | undefined, onError: () => void) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	useEffect(() => {
		if (!activity) return;

		try {
			ActivityReportedError.throwUnlessValid(activity);
			ActivityMadeByBlockedUserError.throwUnlessValid(activity, blockedAndBlockingUsers);
		} catch (error) {
			if (error instanceof ActivityReportedError) {
				showInfo(ActivityReportedError.getNotificationMessage(t));
			} else if (error instanceof ActivityMadeByBlockedUserError) {
				showInfo(ActivityMadeByBlockedUserError.getNotificationMessage(t));
			}
			onError();
		}
	}, [activity, blockedAndBlockingUsers]);
};
