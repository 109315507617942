import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDeal, TDealMember, TDealMemberRelationsStatusForRequestQuery, TLatestMembersForDeal } from '@typings';

export const useGetDealMemberRelationsQuery = (
	dealId: TDeal['id'],
	// we need all members for relation only when expand 'show all btn'
	isExpanded: boolean,
	status?: TDealMemberRelationsStatusForRequestQuery,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TDealMember[], Error>(
		reactQuery.queryKeys.getDealMemberRelations(dealId, status),
		async () => api.invest.getDealMemberRelations(dealId, status),
		{
			enabled: isExpanded,
		},
	);
};

export const useGetLatestMembersForDealQuery = (dealId: TDeal['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TLatestMembersForDeal, Error>(reactQuery.queryKeys.getLatestMembersForDeal(dealId), async () =>
		api.invest.getLatestMembersForDeal(dealId),
	);
};
