import type { TChoice } from '@typings';

export const convertChoicesToSingleSelectOptions = (choices?: TChoice[]) => {
	if (!choices?.length) return [];
	return (
		choices?.map((option) => ({
			value: option.choice,
			title: option.infoText ? `${option.choice} - ${option.infoText}` : option.choice,
		})) || []
	);
};
