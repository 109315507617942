import { memo } from 'react';
import useEvent from 'react-use-event-hook';
import { createContext, useContext, useSyncExternalStore } from 'react';
import type { PropsWithChildren } from 'react';
import type { createBrowserRouter } from 'react-router-dom';

const snapshot = { pathname: window.location.pathname, pathnameChangedAt: Date.now() };

const AppRouterContext = createContext(snapshot);

export const AppRouterContextProvider = memo(
	function AppRouterContextProvider({ getAppRouter, children }: PropsWithChildren<{ getAppRouter: () => TRouter }>) {
		const subscriber = useEvent((callback: () => void) =>
			getAppRouter().subscribe((routerState) => {
				const currentPathname = routerState.location.pathname;
				if (snapshot.pathname !== currentPathname) {
					snapshot.pathname = currentPathname;
					snapshot.pathnameChangedAt = Date.now();
					callback();
				}
			}),
		);

		const newSnapshot = useSyncExternalStore(subscriber, () => snapshot);

		return <AppRouterContext.Provider value={newSnapshot}>{children}</AppRouterContext.Provider>;
	},
	() => true,
);

export const useAppRouterContext = () => useContext(AppRouterContext);

type TRouter = ReturnType<typeof createBrowserRouter>;
