import styles from './PostLinksPreview.module.css';
import { useLinkPreviewQuery } from '@ui-modules/media/hooks/useLinkPreviewQuery';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import type { KeyboardEventHandler, MouseEventHandler } from 'react';
import { ActivityIndicator } from '@ui-kit';
import { clsx } from '@utils';

/** Card link with URLs. It uploads the link data from external resource. */
const LinkPreview = ({ link, onLinkClicked, style }: ILinkPreviewProps) => {
	const { data: linkPreview, isLoading } = useLinkPreviewQuery(link);
	const openLink = useHandleActivityLink();

	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		event.preventDefault();
		event.stopPropagation();
		openLink(link);
		onLinkClicked?.(link);
	};

	if (isLoading)
		return (
			<div className={styles.linkPreviewLoading}>
				<ActivityIndicator size="extraSmall" type="fit" />
			</div>
		);

	return linkPreview && linkPreview.error == undefined ? (
		<a className={clsx(styles.linkPreview, style)} href={link} target="blank" onClick={handleClick} onKeyPress={handleClick}>
			<span className={styles.linkPreview__domain}>{linkPreview.domain}</span>
			<span className={styles.linkPreview__title}>{linkPreview.title}</span>
		</a>
	) : null;
};

export interface ILinkPreviewProps {
	/** URL to show. */
	link: string;
	/** Callback to handle link click. */
	onLinkClicked?: (link: string) => void;
	style?: string;
}

export default LinkPreview;
