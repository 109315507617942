export const findClosestSize = (size: number) => {
	const availableSizes = [26, 34, 40, 64];

	const closestSize = availableSizes.reduce((prev, curr) => {
		return Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev;
	});

	return closestSize;
};

const titleSizeMap: Record<string, number> = {
	26: 13,
	34: 16,
	40: 18,
	64: 22,
};

export const getLabelSize = (size: number) => titleSizeMap[size] ?? size * 0.5;
