import { Gap } from '@ui-kit';
import type { ReactNode } from 'react';
import { clsx } from '@utils';
import styles from './FormSection.module.css';

const FormSection = ({ children, bordered = true, title, className }: IFormSectionProps) => {
	return (
		<div className={clsx(styles.section, bordered && styles.borderBottom, className)}>
			{title && (
				<>
					<h3>{title}</h3>
					<Gap gap={24} />
				</>
			)}
			{children}
		</div>
	);
};

export interface IFormSectionProps {
	title?: string;
	children: ReactNode;
	bordered?: boolean;
	className?: string;
}

export default FormSection;
