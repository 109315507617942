import type { DetailedHTMLProps, HTMLAttributes } from 'react';

/* DIV which visually hides children without unmount. */
const VisuallyHidden = ({ children, hidden, ...props }: IVisuallyHiddenProps) => (
	<div {...props} aria-hidden={hidden} hidden={hidden} style={{ display: hidden ? 'none' : 'block' }}>
		{children}
	</div>
);

export interface IVisuallyHiddenProps
	extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'style'> {
	hidden: boolean;
}

export default VisuallyHidden;
