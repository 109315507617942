import { useService } from '@services';
import { StreamApp } from 'react-activity-feed';
import type { ReactNode } from 'react';
import { ActivityIndicator } from '@ui-kit';

/** Manages the connection with Stream. Any components that should talk to Stream should be a child of this component.
 * 	Proxy component over StreamApp
 */
const FeedStreamApp = ({ children }: IFeedStreamAppProps) => {
	const feedService = useService('FeedService');
	const { apiKey, appId, token } = feedService.getFeedConnectionSettings();

	if (apiKey && appId && token) {
		return (
			<StreamApp apiKey={apiKey} appId={appId} errorHandler={feedService.handleFeedError} token={token}>
				{children}
			</StreamApp>
		);
	}

	return <ActivityIndicator size="large" type="fit" />;
};

export interface IFeedStreamAppProps {
	children: ReactNode;
}

export default FeedStreamApp;
