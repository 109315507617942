import { LongChevronRightIcon } from '@ui-kit/icons';
import { clsx } from '@utils';
import styles from './CommunityListItem.module.css';
import type { TCommunityCompact } from '@typings';

const CommunityListItem = ({
	community,
	isSelected = false,
	formatMembersCount = (membersCount) => membersCount,
	onClick,
}: ICommunityListItemProps) => {
	const handleClick = () => !isSelected && onClick(community);

	return (
		<div
			aria-selected={isSelected}
			className={clsx(styles.listItem, isSelected && styles.listItem_selected)}
			role="option"
			tabIndex={0}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			<div className={styles.listItem__body}>
				<span className={styles.listItem__title}>{community.name}</span>
				{community.membersCount ? (
					<span className={styles.listItem__subtitle}>{formatMembersCount(community.membersCount)}</span>
				) : null}
			</div>
			<div className={styles.listItem__footer}>
				<LongChevronRightIcon className={styles.listItem__chevronIcon} height={16} width={8} />
			</div>
		</div>
	);
};

export interface ICommunityListItemProps {
	/** Community data to render. */
	community: TCommunityCompact;
	/** Should show blue border. Default false. */
	isSelected?: boolean;
	/** Function to alter members count. */
	formatMembersCount?: (membersCount: number) => string | number;
	/** Callback called when a card is selected. Does not called when card is already selected. */
	onClick: (community: TCommunityCompact) => void;
}

export default CommunityListItem;
