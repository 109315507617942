import { useService, useQuery } from '@hooks';
import type { TCommunity, TFolder } from '@typings';

export const useFolderQuery = (communityId: TCommunity['id'], folderId: TFolder['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TFolder, Error>(
		reactQuery.queryKeys.getCommunityFolder(communityId, folderId),
		async () => await api.communityFolder.getCommunityFolder(folderId),
	);
};
