import styles from './InputDescription.module.css';

/** Description text showed under a form input field. */
const InputDescription = ({ text: description, theme = 'primary' }: IInputDescriptionProps) => {
	return description ? (
		<div className={styles.inputDescription} data-theme={theme}>
			<small>{description}</small>
		</div>
	) : null;
};

export interface IInputDescriptionProps {
	/** Text of a description. */
	text?: string;
	/** How the text should look like. "Primary" has black color, "secondary" has medium grey. Default 'primary'. */
	theme?: 'primary' | 'secondary';
}

export default InputDescription;
