import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TNetworkConnection, TNetworkConnectionAction } from '@typings';

export const useNetworkConnectionActionQuery = <T = TNetworkConnectionAction | null>(
	networkConnection?: TNetworkConnection,
	queryOptions?: UseQueryOptions<TNetworkConnectionAction | null, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TNetworkConnectionAction | null, Error, T>(
		['networkConnection.getNetworkConnectionAction', networkConnection?.id],
		async () => {
			if (!networkConnection) return null;
			const template = await api.networkConnection.getTemplate(networkConnection.community.id);
			if (template.actionTemplate) {
				const action = await api.networkConnection.getNetworkConnectionAction(networkConnection.id);
				const { networkConnectionTemplateId, ...actionTemplateData } = template.actionTemplate;
				const actionTemplate: TNetworkConnectionAction = {
					...action,
					...actionTemplateData,
					id: action.id,
				};
				return actionTemplate;
			} else {
				return null;
			}
		},
		{
			enabled: !!networkConnection,
			staleTime: Infinity,
			...queryOptions,
		},
	);
};
