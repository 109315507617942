import { CommunityEntity } from '@utils';
import type { TCommunity, TNetworkTabName, TRole } from '@typings';

/** Determines abilities which tabs should be rendered and in which sequence. */
export const getNetworkTabsPermissions = (
	network: TCommunity | undefined,
	userRoles: TRole[],
	dealsEnabled = false,
) => {
	const tabs: TNetworkTabName[] = [];

	if (!network) return { tabs };

	const userHasMembership = network ? CommunityEntity.userHasMembership(network) : false;
	const isDiscussionsShowed = CommunityEntity.shouldShowDiscussionsV2(network, userRoles);
	const iConnectionsShowed = CommunityEntity.shouldShowConnection(network);
	const isEventsShowed = CommunityEntity.shouldShowMeetingsOrEvents(network, userRoles);
	const isMembersShowed = CommunityEntity.shouldShowMembers(network, userRoles);
	const isDocumentsShowed = CommunityEntity.shouldShowDocumentsV2(network, userRoles);
	const isResourcesShowed = CommunityEntity.shouldShowResources(network);
	const isInvestTabShowed = CommunityEntity.shouldShowDeals(network) && dealsEnabled;

	tabs.push('about');
	if (isDiscussionsShowed) tabs.push('discussions');
	if (iConnectionsShowed) tabs.push('connections');
	if (isInvestTabShowed) tabs.push('deals');
	if (userHasMembership) {
		// There is a UX requirement that sequence of tabs for the member and non-member user is different.
		if (isEventsShowed) tabs.push('events');
		if (isMembersShowed) tabs.push('members');
	} else {
		if (isMembersShowed) tabs.push('members');
		if (isEventsShowed) tabs.push('events');
	}
	if (isDocumentsShowed) tabs.push('files');
	if (isResourcesShowed) tabs.push('resources');

	return { tabs };
};
