import { useEffect, useRef, useState } from '@hooks';
import { clsx } from '@utils';
import type { ForwardedRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import InputDescription from '../InputDescription';
import InputErrorMessage from '../InputErrorMessage';
import InputLabel from '../InputLabel';
import styles from './AutoGrowInput.module.css';

const ROW_LINE_HEIGHT = 16;
const TOTAL_PADDING_VERTICAL = 0;

const AutoGrowInput = (
	{
		value,
		label,
		placeholder,
		disabled,
		hint,
		errorMessage,
		keepMarginBottom,
		minRows = 1,
		maxRows = 7,
		onChange,
		transparentMode = false,
		setFieldValue,
		size = 'medium',
		fieldName,
		forcedResize,
		onPaste,
		...props
	}: ITextAreaProps,
	outerRef: ForwardedRef<HTMLTextAreaElement>,
) => {
	const ref = useRef<HTMLTextAreaElement>(null);
	const [isFocused, setIsFocused] = useState(false);
	useImperativeHandle(outerRef, () => ref?.current as HTMLTextAreaElement);

	const resize = () => {
		if (!ref?.current) return;
		if (ref.current.scrollHeight > ROW_LINE_HEIGHT * maxRows + TOTAL_PADDING_VERTICAL) {
			ref.current.style.height = ROW_LINE_HEIGHT * maxRows + TOTAL_PADDING_VERTICAL + 'px';
			return;
		}
		ref.current.style.height = 'auto';
		ref.current.style.height = ref.current.scrollHeight + 'px';
	};

	const delayedResize = () => {
		window.setTimeout(resize, 0);
	};

	useEffect(() => {
		if (!ref?.current) return;
		ref.current.style.minHeight = ROW_LINE_HEIGHT * minRows + TOTAL_PADDING_VERTICAL + 'px';

		resize();
	}, []);

	useEffect(() => {
		if (forcedResize) {
			resize();
		}
	}, [forcedResize]);

	return (
		<div className={styles.textarea__container}>
			<InputLabel text={label} />
			<div
				className={clsx(
					!transparentMode && styles.inputContainer,
					isFocused && styles.inputContainer_focus,
					errorMessage && styles.textarea_error,
				)}
			>
				<textarea
					aria-label={label}
					className={styles.textarea}
					data-size={size}
					disabled={disabled}
					placeholder={placeholder}
					ref={ref}
					value={value}
					onBlur={(e) => {
						setIsFocused(false);
						props?.onBlur && props?.onBlur(e);
					}}
					onChange={(e) => {
						resize();
						onChange && onChange(e);
						setFieldValue(fieldName, e.target.value);
					}}
					onCut={delayedResize}
					onDrop={delayedResize}
					onFocus={(e) => {
						setIsFocused(true);
						props?.onFocus && props?.onFocus(e);
					}}
					onKeyDown={delayedResize}
					onPaste={(event) => {
						onPaste?.(event);
						delayedResize();
					}}
					{...props}
				/>
			</div>
			<InputDescription text={hint} />
			<InputErrorMessage keepMarginBottom={keepMarginBottom} text={errorMessage} />
		</div>
	);
};

export interface ITextAreaProps
	extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	value?: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	errorMessage?: string;
	hint?: string;
	minRows?: number;
	maxRows?: number;
	/** By default the error message place is always visible and uses for displaying without layout shift. If 'true' after appearing an error message the margin between message and bottom will appear. Default 'false'. */
	keepMarginBottom?: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	fieldName: string;
	transparentMode?: boolean;
	size?: 'small' | 'medium';
	forcedResize?: boolean;
}

export default forwardRef(AutoGrowInput);
