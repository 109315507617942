import { useMutation, useNotification, useService } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import { useUpdateActivityInFeedCache } from './useUpdateActivityInFeedCache';
import { toString, settingsPostTypeToInternalPostType, ActivityEntity } from '@utils';
import type { TActivity, TCommunity, TCommentReactionData, TAddReactionResponse } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useAddReactionMutation = (
	activity: TActivity,
	options?: MutationOptions<TAddReactionResponse, Error, TUseAddReactionMutationPayload>,
) => {
	const { showUnknownError } = useNotification();
	const feedContext = useFeedContext();
	const feed = useService('FeedService');

	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const mixpanelAnalytics = useService('AnalyticsService');
	const reactQuery = useService('ReactQueryService');

	const updateActivityInFeedCache = useUpdateActivityInFeedCache();
	return useMutation<TAddReactionResponse, Error, TUseAddReactionMutationPayload>(
		['feed.onAddReaction'],
		async ({ comment }) => {
			const addedReaction = (await feedContext.onAddReaction(
				'comment',
				activity as any,
				comment,
			)) as unknown as TAddReactionResponse;
			if (!addedReaction) throw new Error('Failed to add reaction');
			return addedReaction;
		},
		{
			...options,
			async onSuccess(addedComment, variables, context) {
				const { community } = variables;
				await updateActivityInFeedCache(activity.id);
				const updatedActivity = (await feed.getActivities([activity.id]))[0] as TActivity;

				reactQuery.refetchDealsAfterReactionsMutation(updatedActivity);
				if (ActivityEntity.isNetworkConnectionActivity(activity)) {
					const communityId = ActivityEntity.getCommunityId(activity);
					reactQuery.updateNetworkConnectionReactionsInCache(updatedActivity, communityId as string);
				}
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getPostActivityReactions(activity.id));

				algoliaAnalytics.trackEvent('User Interacted', { discussionOwnerId: activity.actor.data.userId });
				algoliaAnalytics.trackEvent('Reply Created', { discussionId: activity.foreign_id });
				if (community) {
					const networkType = community.features.includes('invest') ? 'Invest' : 'Lifestyle';
					if (networkType === 'Lifestyle') {
						mixpanelAnalytics.trackEvent('LifestyleNetworkDiscussionReplyCreated', {
							network_id: community.id,
							PostTitle: activity?.subject || toString(activity?.body).slice(0, 15),
							PostType: settingsPostTypeToInternalPostType(activity.settings.postType),
							PostCharacterLength: addedComment.data.text.length ?? 0,
							post_id: addedComment.activity_id,
							reply_id: addedComment.id,
							ParentPostID: activity.foreign_id,
						});
					}
				}
				options?.onSuccess?.(addedComment, variables, context);
			},
			onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TUseAddReactionMutationPayload = {
	comment: TCommentReactionData;
	community?: TCommunity;
};
