export const getSelectedIds = (categories: TCategory[] = []) => {
	return categories?.reduce((accumulator: string[], category) => {
		if (category.value === true) {
			accumulator.push(`/api/network_connection_categories/${category.id}`);
			category?.children?.forEach(
				(childCategory) =>
					childCategory.value === true && accumulator.push(`/api/network_connection_categories/${childCategory.id}`),
			);
		}
		return accumulator;
	}, []);
};

type TCategory = { id: string; value: boolean; children?: { id: string; value: boolean }[] };
