import { useMutation, useNotification, useService } from '@hooks';
import { useInitAlgoliaSearchMutation } from '@ui-modules/globalSearch/hooks/useInitAlgoliaSearchMutation';
import { pick } from '@utils';
import type { TCreateDealMemberRelation, TDeal, TUpdateDealMemberRelationResponse } from '@typings';

export const useDealMemberRelationsMutation = (dealId: TDeal['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();
	const { mutate: refetchAlgoliaSearchToken } = useInitAlgoliaSearchMutation();

	const dealMemberRelations = useMutation<
		TUpdateDealMemberRelationResponse | void,
		Error,
		{ id?: string; data?: TCreateDealMemberRelation }
	>(
		['deal.changeDealMemberRelations', dealId],
		async ({ id, data }) => {
			if (id && data) {
				return await api.invest.patchDealMemberRelations(id, data);
			} else if (!id && data) {
				return await api.invest.createDealMemberRelations(data);
			} else if (id && !data) {
				return await api.invest.removeDealMemberRelations(id);
			}
		},
		{
			onSuccess: (response, { data: dealMemberRelation }) => {
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getNotInterestedDeals());
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getAllDeals());
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getMyDeals());
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealMemberRelations(dealId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getLatestMembersForDeal(dealId));
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getProfileDeal());
				reactQuery.updateDealsCache(dealId, (deal: TDeal) => {
					const wasCreatingOrUpdating = response;
					if (wasCreatingOrUpdating) {
						deal.currentDealMemberRelation = pick(response, ['@id', 'status', 'newPostAlerts']);
					} else {
						deal.currentDealMemberRelation = undefined;
					}
				});
				refetchAlgoliaSearchToken();
				analytics.trackEvent('InvestDealStatusChanged', {
					deal_status_change: dealMemberRelation?.status ?? 'not following',
				});
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	return {
		dealMemberRelations: dealMemberRelations.mutateAsync,
		dealMemberRelationsIsLoading: dealMemberRelations.isLoading,
	};
};
