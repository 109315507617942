import AnimateHeight from 'react-animate-height';
import clsx from 'clsx';
import { When } from '@ui-kit';
import { useState, useTranslation, useLayoutEffect } from '@hooks';
import styles from './FeedReplies.module.css';
import type { TComment } from '@typings';
import type { ReactNode } from 'react';

const expandedCommentsMap = new Map<TComment['id'], boolean>(); // to prevent expanding comment after like pressed because Comment rerendered and replies become collapsed. [@dmitriy.nikolenko].
const previousCommentsCountMap = new Map<TComment['id'], number>(); // cache for preserving expanded state when reply added/removed. [@dmitriy.nikolenko].

const FeedReplies = ({ comment, renderReply, className }: IFeedRepliesProps) => {
	const { t } = useTranslation();

	// Input data.
	const replies = comment?.latest_children?.comment ?? [];
	const commentQuantity = replies.length;

	// Replies states & data.
	const [repliesExpanded, setRepliesExpanded] = useState(expandedCommentsMap.get(comment.id) ?? false);
	const toggleRepliesExpanded = (newExpanded?: boolean) =>
		setRepliesExpanded((value) => {
			const newValue = newExpanded ?? !value;
			expandedCommentsMap.set(comment.id, newValue);
			return newValue;
		});
	const [firstReply, ...restReplies] = replies.slice().reverse();
	const canExpandReplies = commentQuantity > 1;

	useLayoutEffect(function preserveRepliesOpenedOnAddedOrRemoved() {
		const previousCommentQuantity = previousCommentsCountMap.get(comment.id);
		if (previousCommentQuantity !== undefined && previousCommentQuantity !== commentQuantity) {
			toggleRepliesExpanded(true);
		}
		previousCommentsCountMap.set(comment.id, commentQuantity);
	}, []);

	if (!replies.length) return null;
	return (
		<>
			{/* Replies list. The first one is always visible. Others are collapsed until 'Show more' clicked. */}
			<div>
				{renderReply({ comment: firstReply })}
				<AnimateHeight duration={200} height={repliesExpanded ? 'auto' : 0}>
					{restReplies.map((comment) => renderReply({ comment }))}
				</AnimateHeight>
			</div>

			{/* Collapse/expand link. */}
			<When condition={!!canExpandReplies}>
				<div className={clsx(styles.feedReplies__toggleRepliesBox, className)}>
					<span
						className={styles.feedReplies__toggleRepliesLink}
						role="button"
						tabIndex={0}
						onClick={() => toggleRepliesExpanded()}
						onKeyPress={() => toggleRepliesExpanded()}
					>
						{repliesExpanded ? t('Show less') : t('Show more')}
					</span>
				</div>
			</When>
		</>
	);
};

export interface IFeedRepliesProps {
	comment: TComment;
	renderReply: ({ comment }: { comment: TComment }) => ReactNode;
	className?: string;
}

export default FeedReplies;
