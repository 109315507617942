import { useQuery, useService } from '@hooks';
import type {
	TDynamicField,
	TDynamicFieldHeading,
	TNetworkConnectionCategory,
	TNetworkConnectionTemplate,
} from '@typings';
import type { TCommunity } from '@typings';
import { sortBy } from '@utils';

export const useGetNetworkConnectionTemplateQuery = (
	communityId?: TCommunity['id'],
	onSuccess?: (data: TNetworkConnectionTemplate) => void,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TNetworkConnectionTemplate, Error>(
		reactQuery.queryKeys.getNetworkConnectionTemplate(communityId as TCommunity['id']),
		async () => {
			return await api.networkConnection.getTemplate(communityId as TCommunity['id']);
		},
		{ onSuccess, enabled: !!communityId },
	);
};

export const convertDynamicFields = (dynamicFields?: TNetworkConnectionTemplate['dynamicFields']) => {
	const sections = [];
	const mainSectionDynamicFields = [] as TDynamicField[];
	let singleSection = {} as TDynamicFieldHeading & { fields: TDynamicField[] };
	const sortedDynamicFields = sortBy(dynamicFields, 'position') || [];

	sortedDynamicFields.forEach((field) => {
		if (field.type === 'heading') {
			if (singleSection?.fields?.length) sections.push(singleSection);
			singleSection = { ...field, fields: [] };
		} else {
			if (singleSection.name) {
				singleSection.fields = [...(singleSection.fields || []), field];
			} else {
				mainSectionDynamicFields.push(field);
			}
		}
	});
	sections.push(singleSection);
	return { dynamicSections: sections, mainSectionDynamicFields };
};

type TNestedNetworkConnectionCategory = TNetworkConnectionCategory & {
	children?: TNetworkConnectionCategory[];
	value: boolean;
};

export const convertToNestedCategories = (categories: TNestedNetworkConnectionCategory[]) => {
	const res: { [key: string]: TNestedNetworkConnectionCategory } = {};
	if (!categories?.length) return [];
	categories.forEach((category) => {
		if (!category.parent && !res[category.id]) {
			res[category.id] = { ...category, children: [] };
		} else if (!category.parent && res[category.id]) {
			res[category.id] = { ...res[category.id], ...category };
		} else if (category.parent && res[category.parent.id]) {
			res[category.parent.id].children?.push(category);
		} else if (category.parent && !res[category.parent.id]) {
			res[category.parent.id] = { children: [category] } as never as TNestedNetworkConnectionCategory;
		}
	});
	return Object.values(res);
};
