import type { TNominateMemberForm } from '@schemas';
import { NominateMemberSchema } from '@schemas';
import type { TProfileType } from '@typings';
import { Formik } from 'formik';
import { useState, useTranslation } from '@hooks';

import styles from './NominateMemberForm.module.css';
import { useNominations } from '../../hooks/useNominations';
import { useMarketsOptions } from '../../hooks/useMarketsOptions';
import { Button, CheckboxInput, DropdownMenu, Gap, Input, RadioGroupInput, TextArea, PhoneInput, When } from '@ui-kit';
import { useSubmitOrScrollToError } from './useSubmitOrScrollToError';
import type { IAutocompleteSearchUser } from '../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import UserSearchAutocomplete from '../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';

const NominateMemberForm = ({ userEntityId, profileType }: INominateMemberFormProps) => {
	const { t } = useTranslation();
	const { addNomination } = useNominations();
	const marketsOptions = useMarketsOptions();

	const [selectedValue, setSelectedValue] = useState<IAutocompleteSearchUser | null>(null);
	const schema = NominateMemberSchema(t);

	const submitOrScrollToErrorField = useSubmitOrScrollToError();
	const initialValues = {
		...schema.getDefault(),
		owner: userEntityId,
	} as TNominateMemberForm;

	return (
		<div>
			<p className={styles.nominateMemberForm__title}>{t('All fields required unless marked “optional”')}</p>
			<Formik<TNominateMemberForm>
				enableReinitialize
				initialValues={initialValues}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={schema}
				onSubmit={async (formValues, formikHelpers) => {
					const { owner, onBehalfOf, ...values } = formValues;
					const newNomination = {
						...values,
						owner: onBehalfOf ? owner : userEntityId,
					};
					await addNomination(newNomination, {
						onSettled: () => {
							formikHelpers.resetForm();
							setSelectedValue(null);
						},
					});
				}}
			>
				{(formProps) => {
					const { values, setFieldValue, handleChange, handleBlur, submitForm, validateForm, errors, isSubmitting } =
						formProps;
					const onSelectUser = (user: IAutocompleteSearchUser) => {
						// set data for Nomination Member Form
						setSelectedValue(user);
						setFieldValue('owner', user?.id || userEntityId);
						setFieldValue('nominatorFullName', user?.fullName);
					};
					return (
						<>
							<RadioGroupInput
								label={t('I am nominating a...')}
								options={[
									{ label: t('Member'), value: 'Member' },
									{ label: t('Chair'), value: 'Chair' },
								]}
								value={values.roles[0] === 'ROLE_MEMBER' ? 'Member' : 'Chair'}
								onChange={(value) => setFieldValue('roles', [value === 'Chair' ? 'ROLE_CHAIR' : 'ROLE_MEMBER'])}
							/>
							<When condition={profileType === 'ProfileChair'}>
								<div className={styles.nominateMemberForm__postBehalf}>
									<CheckboxInput
										label={t('I’m submitting this nomination on behalf of someone else (optional)')}
										size="small"
										value={Boolean(values.onBehalfOf)}
										onChange={(value) => {
											setFieldValue(`onBehalfOf`, value);
											if (!value) {
												setSelectedValue(null);
												setFieldValue(`nominatorFullName`, null);
												setFieldValue('owner', userEntityId);
											}
										}}
									/>
									<div className={styles.nominateMemberForm__searchBox}>
										<div className={styles.nominateMemberForm__search}>
											<UserSearchAutocomplete
												defaultValue={selectedValue}
												disabled={!values.onBehalfOf}
												placeholder={t('person’s name')}
												onBlur={(e) => {
													if (!e.target.value) return;
													onSelectUser({
														fullName: e.target.value,
														role: '',
														avatar: '',
														id: userEntityId,
														enabled: false,
													});
												}}
												onSelect={onSelectUser}
											/>
											<span className={styles.search__hint}>
												{t(
													'When you submit a nomination on someone else’s behalf, updates will only show in their “My Nominations” list—not yours.',
												)}
											</span>
										</div>
									</div>
								</div>
							</When>
							<div className={styles.nominateMemberForm__nameFields}>
								<Input
									autoComplete="off"
									errorMessage={errors.firstName}
									label={t('Nominee’s first name')}
									value={values.firstName || ''}
									onBlur={handleBlur('firstName')}
									onChange={handleChange('firstName')}
								/>
								<Gap gap={8} />
								<Input
									autoComplete="off"
									errorMessage={errors.lastName}
									label={t('Nominee’s last name')}
									value={values.lastName || ''}
									onBlur={handleBlur('lastName')}
									onChange={handleChange('lastName')}
								/>
							</div>
							<Input
								autoCapitalize="none"
								autoComplete="off"
								errorMessage={errors.email}
								label={t('Nominee’s email')}
								value={values.email || ''}
								onBlur={handleBlur('email')}
								onChange={handleChange('email')}
							/>
							<Gap gap={4} />
							<PhoneInput
								errorMessage={errors.phone}
								label={t('Nominee’s phone number')}
								value={values.phone || ''}
								onBlur={handleBlur('phone')}
								onChange={handleChange('phone')}
							/>
							<Gap gap={4} />
							<DropdownMenu
								errorMessage={errors.city}
								isSearchable={false}
								label={t('Nominee’s market')}
								options={marketsOptions}
								placeholder={`- ${t('select')} -`}
								value={values.city}
								variant="medium"
								onChange={(id) => setFieldValue('city', id)}
							/>
							<Gap gap={20} />
							<TextArea
								autoComplete="off"
								errorMessage={errors.reason}
								label={t('Reason for Nomination')}
								minRows={5}
								value={values.reason || ''}
								onBlur={handleBlur('reason')}
								onChange={handleChange('reason')}
							/>
							<Gap gap={4} />
							<TextArea
								autoComplete="off"
								errorMessage={errors.information}
								label={t('Additional information (optional)')}
								minRows={5}
								value={values.information || ''}
								onBlur={(e) => setFieldValue('information', e.target.value?.length ? e.target.value : undefined)}
								onChange={(e) => setFieldValue('information', e.target.value?.length ? e.target.value : undefined)}
							/>
							<Gap gap={4} />
							<div className={styles.nominateMemberForm__submitButtonBox}>
								<Button
									disabled={isSubmitting}
									title={t('Submit')}
									type="outline"
									variant="medium"
									onClick={() =>
										validateForm().then(() => {
											submitOrScrollToErrorField(submitForm);
										})
									}
								/>
							</div>
						</>
					);
				}}
			</Formik>
		</div>
	);
};

export interface INominateMemberFormProps {
	profileType: TProfileType;
	userEntityId: string;
}

export default NominateMemberForm;
