import { getLabelTextAnchor } from './getLabelTextAnchor';
import { isSegmentOnLeftChartSide } from './isSegmentOnLeftChartSide';
import type { TLabelProps, TPieChartLabelRenderProps } from '../PieChart';

// Scale and shift values are taken experimentally to fix wrong positioning.
const SVG_TO_HTML_SIZE_SCALE = 3;
const SVG_TO_HTML_X_SHIFT = 200;
const SVG_TO_HTML_Y_SHIFT = -12;

export function mapLabelPropsToRenderProps(
	labelPropsSet: TLabelProps[],
	labelAnchorDegreeShift: number,
): TPieChartLabelRenderProps[] {
	return labelPropsSet.map<TPieChartLabelRenderProps>((labelProps) => {
		const value = labelProps.dataEntry.value;
		const label = labelProps.dataEntry.title;
		const isLeft = isSegmentOnLeftChartSide(labelProps.dataEntry);

		const x = (labelProps.x + labelProps.dx) * SVG_TO_HTML_SIZE_SCALE + SVG_TO_HTML_X_SHIFT;
		const y = (labelProps.y + labelProps.dy) * SVG_TO_HTML_SIZE_SCALE + SVG_TO_HTML_Y_SHIFT;

		const textAnchor = getLabelTextAnchor(labelProps.dataEntry, labelAnchorDegreeShift);
		const labelPosition = textAnchor === 'start' ? 'left' : 'right';
		const labelTranslateXPercent = textAnchor === 'middle' ? 50 : 0;

		return {
			x,
			y,
			value,
			label,
			labelPosition,
			labelTranslateXPercent,
			segmentIsOnLeftChartSide: isLeft,
		};
	});
}
