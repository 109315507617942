import { useService, usePaginatedInfiniteQuery, useRef, useTranslation, useMemo } from '@hooks';
import { useDealsSortSearchParams } from './useDealsSortSearchParams';
import { compact, getDealSortOptions, populateDealsPaginatedListWithReactions } from '@utils';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import type { TCommunity, TDealWithReactions } from '@typings';
import { useDealFilterViaCommunityId } from './useDealFilterViaCommunityIdQuery';

export const useNetworkDealsQuery = (communityId: TCommunity['id'], excludeNotInterested = false) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const feed = useService('FeedService');
	const reactQuery = useService('ReactQueryService');
	const { isMessageShown, isLoading: isWaiverLoading } = useSolicitationWaiver();
	const {
		data: exactDealFilter,
		isLoading: isExactDealFilterLoading,
		dataUpdatedAt: filterUpdatedAt,
		isFetched: isDealFilterFetched,
		refetch: refetchDealFilters,
	} = useDealFilterViaCommunityId(communityId);
	const customFilterId = exactDealFilter?.[0]?.id as string;
	const enabled = !isWaiverLoading && !isMessageShown && !!communityId && !!customFilterId;

	const [selectedSortOption, setSelectedSortOption] = useDealsSortSearchParams({
		field: 'createdAt',
		order: 'desc',
	});
	const sortOptions = useRef(getDealSortOptions('all')(t)).current;
	const filterIdWithUpdatedDate = customFilterId + filterUpdatedAt;

	const query = usePaginatedInfiniteQuery<TDealWithReactions>(
		reactQuery.queryKeys.getNetworkDeals(communityId, filterIdWithUpdatedDate, selectedSortOption),
		async ({ pageParam }) => {
			const deals = await api.invest.getNetworkDeals({
				page: pageParam,
				communityId,
				filterId: customFilterId,
				excludeNotInterested,
				sort: selectedSortOption,
			});
			const dealsActivityIds = compact(deals.data.map((deal) => deal.streamActivityId)); // This is a fix to avoid having torn arrays. On the backend, there is a floating bug with the creation of an activity in the stream after creating a deal. Fixes T21C-5533
			const dealActivities = await feed.getActivities(dealsActivityIds);
			const dealsWithReactions = populateDealsPaginatedListWithReactions(deals, dealActivities);
			return dealsWithReactions;
		},
		{
			enabled,
		},
	);

	const hasDataByFilter = useMemo(
		() =>
			reactQuery.hasAnyDataInInfiniteQueryCache(
				reactQuery.queryKeys.getNetworkDeals(communityId, filterIdWithUpdatedDate),
			),
		[communityId, filterIdWithUpdatedDate, query.isFetching],
	);

	return {
		sortOptions,
		selectedSortOption,
		setSelectedSortOption,
		filterIdWithUpdatedDate,
		exactDealFilter,
		isExactDealFilterLoading,
		refetchDealFilters,
		isDealFilterFetched,
		hasDataByFilter,
		...query,
	};
};
