import { GroupedVirtuoso } from 'react-virtuoso';
import SectionListRow from '../SectionListRow';
import ActivityIndicator from '../ActivityIndicator';
import EmptyStateMessage from '../EmptyStateMessage';
import { useMemo, useCallback } from '@hooks';
import { getGroupCounts, getItemIndexWithinGroup } from './SectionList.utils';
import { clsx } from '@utils';
import styles from './SectionList.module.css';
import type { FC, MutableRefObject, ReactNode } from 'react';
import type { IEmptyStateMessageProps } from '../EmptyStateMessage';
import type { GroupedVirtuosoHandle, GroupedVirtuosoProps } from 'react-virtuoso';
import FixedWidthContainer from '../FixedWidthContainer';
import { isSafari } from 'react-device-detect';

const SectionList = <TItemData, TContext = unknown>({
	data,
	emptyMessage,
	emptyMessageColorMode,
	isLoading,
	isFetching,
	hideSectionHeaders = false,
	headerPadded = false,
	ListHeaderComponent = null,
	ListFooterComponent = null,
	className,
	headerClassName,
	innerRef,
	initialTopMostItemIndex = 0,
	rangeChanged,
	renderItem,
	renderGroupFooterLink = (Component, footerLink) => <Component footerLink={footerLink} />,
	onEndReached,
	onScroll = () => undefined,
}: ISectionListProps<TItemData, TContext>) => {
	const groupCounts = useMemo(() => getGroupCounts(data), [data]);

	const renderItemContent = (index: number, groupIndex: number) => {
		try {
			/** Get index of the item within a particular group. */
			const groupItemIndex = getItemIndexWithinGroup(groupCounts, index, groupIndex);
			const currentItem = data[groupIndex].data[groupItemIndex];
			const groupTitle = data[groupIndex].title;
			const isLastInGroup = groupItemIndex === groupCounts[groupIndex] - 1;
			const footerLink = data[groupIndex]?.footerLink;

			if (isLastInGroup && footerLink) {
				return (
					<>
						{renderItem(currentItem, groupItemIndex, groupTitle, isLastInGroup, index)}
						{renderGroupFooterLink(GroupFooterLink, footerLink)}
					</>
				);
			}
			return renderItem(currentItem, groupItemIndex, groupTitle, isLastInGroup, index);
		} catch (error) {
			return <ItemPlaceholder />;
		}
	};
	const renderGroupContent = useCallback(
		(index: number) => (
			<SectionListRow
				headerStyles={headerClassName}
				hidden={hideSectionHeaders}
				link={data[index].link}
				padded={headerPadded}
				subtitle={data[index]?.subTitle}
				title={data[index].title}
			/>
		),
		[data, hideSectionHeaders],
	);
	const renderEmptyPlaceholder = useCallback(
		() =>
			isLoading ? (
				<ActivityIndicator size="medium" type="fit" />
			) : (
				<FixedWidthContainer className={styles.sectionList__emptyMessageContainer}>
					<EmptyStateMessage colorMode={emptyMessageColorMode} text={emptyMessage} />
				</FixedWidthContainer>
			),
		[isLoading],
	);
	const renderFooter = useCallback(
		() =>
			isLoading ? null : (
				<>
					{ListFooterComponent}
					{isFetching === undefined ? null : (
						<ActivityIndicator hidden={!isFetching || isLoading} size="medium" type="fit" />
					)}
				</>
			),
		[isFetching, isLoading, ListFooterComponent],
	);
	const renderHeader = useCallback(() => <>{ListHeaderComponent}</>, [ListHeaderComponent]);

	return (
		<div className={clsx(styles.sectionList__wrapper, className)}>
			<GroupedVirtuoso<TItemData, TContext>
				className={styles.sectionList}
				components={{
					EmptyPlaceholder: renderEmptyPlaceholder,
					Footer: renderFooter,
					Header: renderHeader,
				}}
				endReached={onEndReached}
				groupContent={renderGroupContent}
				groupCounts={groupCounts}
				increaseViewportBy={isSafari ? { top: 400, bottom: 400 } : { top: 0, bottom: 0 }} // fixes blank areas on scroll on Safari (@see T21C-5553) [@DmitriyNikolenko]
				initialTopMostItemIndex={initialTopMostItemIndex}
				itemContent={renderItemContent}
				rangeChanged={rangeChanged}
				ref={(ref) => {
					if (ref && innerRef && 'current' in innerRef)
						innerRef.current = Object.assign(ref, {
							scrollToSection: (sectionIndex: number) => {
								const groupsToScrollThrough = groupCounts.slice(0, sectionIndex);
								const indexToScroll = groupsToScrollThrough.reduce(
									(accumulator, groupCount) => accumulator + groupCount,
									groupsToScrollThrough.length,
								);
								ref?.scrollToIndex({ index: indexToScroll, behavior: 'smooth', align: 'start' });
							},
						});
				}}
				onScroll={(e) => {
					const scrollTop = (e.target as HTMLElement).scrollTop;
					onScroll(scrollTop, e.currentTarget.scrollHeight);
				}}
			/>
		</div>
	);
};

// Used a height 1px element to resolve Virtuose list (see https://virtuoso.dev/troubleshooting/#i-get-error-zero-sized-element-this-should-not-happen) [@dmitriy.nikolenko]
const ItemPlaceholder = () => <div className={styles.sectionList__groupHeader_placeholder} />;

const GroupFooterLink = ({ footerLink, className }: IGroupFooterLinkComponentProps) => (
	<div className={clsx(styles.sectionList__groupFooter, className)}>
		<button className={styles.sectionList__groupFooterButton} onClick={footerLink.onClick}>
			{footerLink.title}
		</button>
	</div>
);

export interface ISectionListHandle extends GroupedVirtuosoHandle {
	scrollToSection: (sectionIndex: number) => void;
}

export type TSectionListGroupFooterLink = {
	title: string;
	onClick: () => void;
};

/** Type of the list data item. */
export type TSectionListItem<TData> = {
	/** Title text for a section. */
	title: string;
	/** Text described below the title as a description. */
	subTitle?: string;
	/** Renders link button right after title */
	link?: {
		title: string;
		onClick: () => void;
	};
	/** Data to render. */
	data: TData[];
	/** Renders link button on the bottom of the section. */
	footerLink?: TSectionListGroupFooterLink;
};

export interface IGroupFooterLinkComponentProps {
	footerLink: TSectionListGroupFooterLink;
	className?: string;
}
export interface ISectionListProps<TItemData, TContext = unknown>
	extends Pick<GroupedVirtuosoProps<TItemData, TContext>, 'initialTopMostItemIndex' | 'rangeChanged'> {
	/** The array of objects with data to render. */
	data: TSectionListItem<TItemData>[];
	/** The message displayed when nothing found. */
	emptyMessage: string;
	/** Dark or white style for an empty message. Default is 'dark' (black text). */
	emptyMessageColorMode?: IEmptyStateMessageProps['colorMode'];
	/** Should show an activity indicator during initial loading. Default false. */
	isLoading?: boolean;
	/** Should show an activity indicator during loading new items to the end of the list. Default false. */
	isFetching?: boolean;
	/** Should headers be hidden. Default false. */
	hideSectionHeaders?: boolean;
	/** Component which will be rendered on the top of the list. */
	ListHeaderComponent?: ReactNode;
	/** Component which will be rendered on the bottom of the list. */
	ListFooterComponent?: ReactNode;
	/** CSS class passed to list container. */
	className?: string;
	/** Whether section header should have a horizontal padding. Default 'false'. */
	headerPadded?: boolean;
	/** Section header style */
	headerClassName?: string;
	/**	React ref passed to GroupedVirtuoso to call scroll methods (for example). */
	innerRef?: MutableRefObject<ISectionListHandle | null>; // using forwardRe breaks generic typing.
	/** The function which renders passed data as a list row. */
	renderItem: (
		item: TItemData,
		indexInGroup: number,
		groupTitle: string,
		isLastInGroup: boolean,
		index: number,
	) => ReactNode;
	/** The function which customizes rendering of the group footer link button. */
	renderGroupFooterLink?: (
		GroupFooterLinkComponent: FC<IGroupFooterLinkComponentProps>,
		footerLink: TSectionListGroupFooterLink,
	) => ReactNode;
	/** Calls when a user has scrolled to the end of the list. Helps to build pagination. */
	onEndReached?: (index: number) => void;
	/** Calls when a user scrolling the list*/
	onScroll?: (position: number, scrollHeight: number) => void;
}

export default SectionList;
