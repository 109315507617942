import { extractFileExtensionFromUrl, extractFileNameFromStreamUrl, getFileSize } from '@utils';
import { lookupMimeType } from '@utils';

export const extractFileStats = (url: string) => {
	const fileName = extractFileNameFromStreamUrl(url);
	const mediaType = extractFileExtensionFromUrl(url);
	const mimeType = lookupMimeType(mediaType);

	const isFile = !url.includes('image') && !mimeType.includes('image') && !mimeType.includes('video');

	return {
		id: `${fileName}/${mediaType}/${mimeType}`,
		url,
		mimeType: isFile ? mimeType : undefined,
		uri: undefined,
		isFile,
		name: fileName,
		mediaType: mimeType,
		size: Number(getFileSize(url)),
	};
};
