export function separateSegmentsBySides<T extends { segmentIsOnLeftChartSide: boolean }>(
	labels: T[],
): [leftSideLabels: T[], rightSideLabels: T[]] {
	const [leftSideLabels, rightSideLabels] = labels.reduce<[T[], T[]]>(
		(acc, labels) => {
			if (labels.segmentIsOnLeftChartSide) {
				acc[0].unshift(labels);
			} else {
				acc[1].push(labels);
			}

			return acc;
		},
		[[], []],
	);

	return [leftSideLabels, rightSideLabels];
}
