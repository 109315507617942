import { useService, useState, useToggle, useTranslation } from '@hooks';
import { TextArea } from '@ui-kit';
import ContactCard from '../ContactCard';
import styles from './ContactsNote.module.css';

const ContactsNote = ({ note, onChangeNote }: IContactsNoteProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const [noteText, setNoteText] = useState(note);
	const [editable, toggleEditable] = useToggle(false);

	const submitNote = () => {
		onChangeNote(noteText);
		toggleEditable();
		analytics.trackEvent('cta_pressed', { '#cta_name': 'contacts--edit-notes' });
	};

	return (
		<ContactCard
			actionDisabled={editable && note === noteText}
			actionTitle={editable ? t('Save') : t('Edit')}
			title={t('Note')}
			onAction={editable ? submitNote : toggleEditable}
		>
			<div className={styles.note}>
				<TextArea
					minRows={1}
					placeholder={t('Enter notes here')}
					readOnly={!editable}
					value={noteText}
					onChange={(e) => setNoteText(e.target.value)}
				/>
			</div>
		</ContactCard>
	);
};

export interface IContactsNoteProps {
	note: string;
	onChangeNote: (newNote: string) => void;
}

export default ContactsNote;
