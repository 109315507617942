import { useService } from '@services';
import { usePaginatedInfiniteQuery } from '@hooks';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import { dayjs } from '@utils';
import type { AxiosError } from 'axios';
import type { TDeal, TPaginatedList } from '@tiger21-llc/connect-shared/src/typings';

export const useGetNotInterestedDeals = () => {
	const api = useService('ApiService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();
	const reactQuery = useService('ReactQueryService');

	return usePaginatedInfiniteQuery<TDeal, TPaginatedList<TDeal>, AxiosError>(
		reactQuery.queryKeys.getNotInterestedDeals(),
		async ({ pageParam }) => api.invest.getNotInterestedDeals({ page: pageParam }),
		{
			enabled: !isWaiverLoading && !isMessageShown,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
			staleTime: dayjs.duration(1, 'minute').asMilliseconds(),
		},
	);
};
