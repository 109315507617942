import { clsx } from '@utils';
import styles from './SpacedGroup.module.css';
import type { HTMLAttributes, PropsWithChildren } from 'react';

/** UI-less element which takes a couple of elements and renders it with the gap between. */
const SpacedGroup = ({ gap = 12, className, direction = 'horizontal', children, fullWidth }: ISpacedGroupProps) => {
	return (
		<div
			className={clsx(styles.spacedGroup, className, fullWidth && styles.spacedGroup_fullWidth)}
			data-direction={direction}
			style={{ gap }}
		>
			{children}
		</div>
	);
};

export type TSpacedGroupDirection = 'horizontal' | 'vertical';
export interface ISpacedGroupProps extends PropsWithChildren {
	/** The number of pixels to shift between child elements. Default 12. */
	gap?: number;
	/** The direction of child elements, may be "horizontal" or "vertical". Default horizontal. */
	direction?: TSpacedGroupDirection;
	/** Custom class name to apply for the container <div /> element. */
	className?: HTMLAttributes<HTMLDivElement>['className'];
	/** Set full width of group */
	fullWidth?: boolean;
}

export default SpacedGroup;
