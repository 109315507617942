import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TUid } from '@typings';

export const useDietaryRestrictionTypesQuery = <T = TUseDietaryRestrictionTypesQueryData>(
	queryOptions?: UseQueryOptions<TUseDietaryRestrictionTypesQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseDietaryRestrictionTypesQueryData, Error, T>(
		['dietaryRestrictionTypes'],
		async () => {
			return await api.profile.getDietaryRestrictionTypes();
		},
		{
			staleTime: Infinity,
			cacheTime: Infinity,
			...queryOptions,
		},
	);
};

type TUseDietaryRestrictionTypesQueryData = Array<{
	id: TUid;
	name: string;
}>;
