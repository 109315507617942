import {
	useService,
	useMutation,
	useQueryClient,
	useNotification,
	useTranslation,
	useCallback,
	useMe,
	useMemo,
	useCurrentLocation,
} from '@hooks';
import { compact, pick, partition, transformContactHiddenProperties, toString } from '@utils';
import { makeBillingAddressContact } from '../utils/makeBillingAddressContact';
import type { TFullUser, THidableProfileProperties, TPhoneContact, TProfile, TProfileWithUser, TUser } from '@typings';
import type { TEditContactCard } from '@schemas';

export const useUpdateMyContactCardMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const queryClient = useQueryClient();
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();
	const { user, profile } = useMe();
	const { currentLocation } = useCurrentLocation();

	// API requests.
	const updateUser = useMutation<TFullUser, Error, Partial<TUser>>(
		['user.updateUser'],
		async (updatingUser) => api.user.updateUser(user.id, updatingUser),
		{
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
	const updateProfile = useMutation<TProfileWithUser, Error, Partial<TProfile>>(
		['profile.updateProfile'],
		async (patchedProfile) => {
			const fullProfile = {
				...(profile as TProfile),
				...patchedProfile,
			};
			return await api.profile.updateProfile(profile.id, {
				...fullProfile,
				hideProperties: transformContactHiddenProperties(fullProfile),
			});
		},
		{
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	// Form props.
	const initialValues: TEditContactCard = useMemo(() => {
		const values = {
			...jointBillingAddressToProfileFields(extractProfileFields(profile), profile.billingAddress),
		};
		Object.keys(values).forEach((key) => {
			values[key as keyof typeof values] = (values[key as keyof typeof values]?.map((el) => {
				const telProps: { tel?: string } = {};

				// make manipulation with phone fields.
				if ((el as TPhoneContact).tel) {
					const phoneField = el as TPhoneContact;
					const phoneTelNumbers = toString(phoneField.tel).replace(/\D/g, '');
					// [T21C-6809] Check if previously saved phone number can be the state code only and phone
					// Number don't start from + and numbers length is less than 10 symbols.
					if (!!phoneField.tel && phoneField.tel?.[0] !== '+' && phoneTelNumbers?.length <= 10) {
						telProps.tel = `${currentLocation.callingCode}${phoneTelNumbers}`;
					} else {
						telProps.tel = (el as TPhoneContact).tel;
					}
				}

				return {
					...el,
					...telProps,
					visibleToAll: !profile.hideProperties.includes(`${key}.type=${el?.type}` as THidableProfileProperties),
				};
			}) || []) as any;
		});
		return {
			...extractUserFields(user),
			...values,
		};
	}, [user, profile, currentLocation]);
	const submit = useCallback(
		async (values: TEditContactCard) => {
			const updatingUser = extractUserFields(values);
			const updatingProfile = separateBillingAddressFromOtherAddresses(extractProfileFields(values));

			const { user: profileUser, ...updatedProfile } = await updateProfile.mutateAsync(updatingProfile);
			const newUser = await updateUser.mutateAsync(updatingUser);
			showSuccess({
				title: t('Success'),
				subtitle: t('Your contact card has been updated.'),
			});
			queryClient.setQueryData<TFullUser>(reactQuery.queryKeys.getMe(), {
				...newUser,
				profile: updatedProfile as TProfile,
			});
		},
		[initialValues],
	);

	return { initialValues, submit, user, profile };
};

const extractUserFields = (user: TUser | TEditContactCard) => pick(user, ['firstName', 'lastName']);
const extractProfileFields = (profile: TProfile | TEditContactCard) => pick(profile, ['emails', 'phones', 'address']);

const jointBillingAddressToProfileFields = (
	profileFields: Pick<TProfile, 'phones' | 'emails' | 'address'>,
	billingAddress: TProfile['billingAddress'],
) => {
	profileFields.address = profileFields.address ?? [];
	profileFields.address = profileFields.address.filter((address) => address.type !== 'billing');
	profileFields.address.push(makeBillingAddressContact(billingAddress));
	profileFields.address = compact(profileFields.address);

	return profileFields;
};
const separateBillingAddressFromOtherAddresses = (
	profileFields: Pick<TEditContactCard, 'phones' | 'emails' | 'address'>,
): Partial<TProfile> => {
	const allProfileAddress = profileFields.address;
	const [billingAddressArray, address] = partition(allProfileAddress, (addressItem) => addressItem.type === 'billing');

	if (!billingAddressArray.length) return profileFields;

	return {
		...profileFields,
		address,
		billingAddress: billingAddressArray[0].address,
	};
};
