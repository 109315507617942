import { MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE, MAX_ITEMS_FOR_UPLOAD } from '@constants';
import { useContext, useEffect, useMemo, useRef, useState, useTranslation } from '@hooks';
import type { TActivity, TCommunity } from '@typings';
import { ActivityIndicator, FileAttachmentIcon, SendButtonIcon } from '@ui-kit';
import AttachmentTextArea from '../AttachmentTextArea';
import DiscardPageChangesModal from '@ui-modules/forms/components/DiscardPageChangesModal';
import { useTextAreaResize } from '../AttachmentTextArea/useTextAreaResize';
import { usePostAttachmentsState } from '@ui-modules/feed/hooks/usePostAttachmentsState';
import { useCommentControlsState } from './useCommentControlsState';
import { CommentControlsEditingContext, CommentControlsEditingContextProvider } from './CommentControlsEditingContext';
import { clsx, onClipboardPaste } from '@utils';
import styles from './CommentControls.module.css';

const CommentControls = ({ activity, onCommentSent, customClass, community }: ICommentControlsProps) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLTextAreaElement>(null);
	const [comment, setComment] = useState('');

	const [editingReaction, setEditingReaction] = useContext(CommentControlsEditingContext);
	const isReply = !!editingReaction?.reply;
	const reaction = editingReaction?.comment;
	const isEditing = !!reaction?.id && !isReply;
	const onDiscardReply = () => {
		setComment('');
		setAttachments([]);
		setEditingReaction(null);
	};

	const { resize, delayedResize } = useTextAreaResize({ ref, maxRows: 6, minRows: 1 });
	const {
		attachments,
		filesSize,
		removeAttachment,
		uploadAttachments,
		setAttachments,
		setInitialAttachments,
		getInputProps,
		onAddAttachmentsSuccess,
	} = usePostAttachmentsState({ onlyMediaAccepted: false });
	const filesExceedingSize = filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE;
	const itemsExceedingCount = attachments.length > MAX_ITEMS_FOR_UPLOAD;
	const { loading, placeholder, onSendPressed } = useCommentControlsState({
		isReply,
		activity,
		reaction,
		community,
	});

	const resetData = (commentId: string) => {
		onDiscardReply();
		onCommentSent?.(isEditing, commentId);
	};

	useEffect(() => {
		resize(() => ref?.current?.scrollIntoView?.());
	}, [comment]);

	useEffect(() => {
		if (reaction?.id && !isReply) {
			setComment(reaction?.data?.text);
			setInitialAttachments({
				images: reaction?.data.images,
				videos: reaction?.data.videos,
				files: reaction?.data?.files,
			});
		} else if (isReply) {
			setComment('');
		} else {
			setComment('');
		}
		ref?.current?.focus();
	}, [reaction, isReply]);

	const sendDisabled = useMemo(() => {
		return (!comment.trim().length && !attachments.length) || filesExceedingSize || itemsExceedingCount;
	}, [comment, attachments, filesExceedingSize]);

	return (
		<div className={clsx(styles.comment, customClass)}>
			{isReply || isEditing ? (
				<div className={styles.replyBlock}>
					<div>
						<span className={styles.replyBlock__label}>{`${
							isEditing
								? t('Editing my {{isReply}}', { isReply: reaction?.parent ? t('reply') : t('comment') })
								: t('Reply to')
						} `}</span>
						<span className={styles.replyBlock__userName}>{reaction?.user?.data.name}</span>
					</div>
					<button className={styles.commentButton} onClick={onDiscardReply}>
						<span className={styles.replyBlock__cancel}>{t('Cancel')}</span>
					</button>
				</div>
			) : null}
			<div className={styles.commentBlock}>
				<div className={styles.comment__attachmentButtons}>
					<button className={clsx(styles.commentButton, styles.comment__attachmentButton)} onClick={uploadAttachments}>
						<FileAttachmentIcon height={16} width={16} />
						<input {...getInputProps()} /> {/* hidden input to make file upload working */}
					</button>
				</div>
				<div className={styles.comment__input}>
					<AttachmentTextArea
						attachmentEditingDisabled={false}
						attachments={attachments}
						attachmentsError={
							filesExceedingSize
								? t('Size should be limited up to {{ maxLimit }} /{{ limit }}MB', {
										maxLimit: MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
										limit: String(filesSize).slice(0, 5),
								  })
								: itemsExceedingCount
								? t("You can't attach more then {{count}} items", { count: MAX_ITEMS_FOR_UPLOAD })
								: ''
						}
						delayedResize={delayedResize}
						networkId={activity?.collectionCommunityReference?.id}
						placeholder={placeholder}
						ref={ref}
						resize={resize}
						value={comment}
						onChange={(event) => setComment(event)}
						onPaste={(event) => onClipboardPaste(event, (files) => onAddAttachmentsSuccess?.(files))}
						onRemoveAttachment={removeAttachment}
					/>
				</div>
				<div className={styles.comment__buttonSendBlock}>
					{loading ? (
						<ActivityIndicator />
					) : (
						<button
							className={styles.commentButton}
							disabled={sendDisabled}
							onClick={() => {
								onSendPressed({ attachments, comment, onSuccess: resetData });
							}}
						>
							<SendButtonIcon
								className={clsx(styles.comment__buttonSend, sendDisabled && styles.comment__buttonSend_disabled)}
								width={24}
							/>
						</button>
					)}
				</div>
			</div>

			<DiscardPageChangesModal condition={!sendDisabled} />
		</div>
	);
};

export interface ICommentControlsProps {
	activity: TActivity;
	onCommentSent?: (wasEditing: boolean, commentId?: string) => void;
	customClass?: string;
	community?: TCommunity;
}

CommentControls.EditingContext = CommentControlsEditingContext;
CommentControls.EditingContextProvider = CommentControlsEditingContextProvider;
export default CommentControls;
