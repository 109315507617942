export const mapByFoundOrCreated = async <TProperty, TObject>(
	sequence: TProperty[],
	array: TObject[],
	property: keyof TObject,
	create: (sequenceItem: TProperty) => Promise<TObject>,
): Promise<TObject[]> => {
	const mapped: TObject[] = [];
	for (const sequenceItem of sequence) {
		const found = array.find((arrayItem) => arrayItem[property] === sequenceItem);
		mapped.push(found ?? (await create(sequenceItem)));
	}

	return mapped;
};
