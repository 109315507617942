import { IdxStatus } from '@okta/okta-auth-js';
import { SUPPORT_LINK } from '@constants';
import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaAccountLockedError extends OktaError {
	constructor() {
		super();
	}

	status!: 'LOCKED_OUT';
	getMessage(t: TFunction) {
		return {
			title: t('Locked out'),
			subtitle: t('Your Account has been locked out, please contact {{supportEmail}} for assistance', {
				supportEmail: SUPPORT_LINK,
			}),
		};
	}

	static shouldThrowError(transaction: IdxTransaction) {
		return transaction.status === IdxStatus.PENDING && transaction.nextStep?.name === 'unlock-account';
	}
}
