import { DeleteIcon, EyeClosedIcon, EyeIcon } from '@ui-kit';
import { useCallback, useState, useTranslation } from '@hooks';
import { compact } from '@utils';
import type { TFile } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import type { IFilesWidgetApiCallbacks } from './FilesWidgetApiCallbacks.interface';

export const useFileWidgetMeatballMenu = ({
	canMakeFileViewOnly,
	canMakeFileDownloadable,
	canDeleteFile,
	onSetViewOnlyFileStatus,
}: TUseFileWidgetMeatballMenuArgs) => {
	const { t } = useTranslation();

	const [deletingFileId, setDeletingFileId] = useState<string | null>(null);

	const makeFileMenuOptions = useCallback(
		(file: TFile): TMeatballMenuOption[] =>
			compact([
				canMakeFileViewOnly(file) && {
					text: t('Make Read Only'),
					icon: <EyeClosedIcon fill="#000000" height={16} width={14} />,
					onClick: () => onSetViewOnlyFileStatus({ fileId: file.mediaObject.id, isViewerOnly: true }),
					type: 'solid',
				},

				canMakeFileDownloadable(file) && {
					text: t('Make Downloadable'),
					icon: <EyeIcon fill="#000000" height={16} width={14} />,
					onClick: () => onSetViewOnlyFileStatus({ fileId: file.mediaObject.id, isViewerOnly: false }),
					type: 'solid',
				},
				canDeleteFile(file) && {
					text: t('Delete File'),
					onClick: () => setDeletingFileId(file.id),
					type: 'destructive',
					icon: <DeleteIcon height={16} width={14} />,
				},
			]),
		[],
	);

	return [makeFileMenuOptions, { deletingFileId, setDeletingFileId }] as const;
};

type TUseFileWidgetMeatballMenuArgs = Pick<IFilesWidgetApiCallbacks, 'onSetViewOnlyFileStatus'> & {
	canMakeFileViewOnly: (file: TFile) => boolean;
	canMakeFileDownloadable: (file: TFile) => boolean;
	canDeleteFile: (file: TFile) => boolean;
};
