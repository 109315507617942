import { DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH } from '@constants';
import type { TCommunity, TProfileDeal } from '@typings';
import styles from './ProfileDeals.module.css';
import { Button, Portal, RightArrowIcon } from '@ui-kit';
import { useTranslation } from '@hooks';
import NetworkAttendanceButton from '@ui-modules/networks/components/NetworkAttendanceButton';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';

const JoinDealNetworkModal = ({ deal, onClose, onJoinSuccess, onRequestSuccess }: IJoinDealNetworkModalProps) => {
	const { t } = useTranslation();

	const { joinNetwork, requestAccess } = useNetworkMutations({
		onJoinSuccess,
		onRequestSuccess,
		joinSuccessMessage: t('Joined {{networkName}}', { networkName: deal?.community?.name }),
		requestSuccessMessage: t('Request Sent'),
	});
	const { data: community } = useCommunityQuery(deal.community.id);

	return (
		<Portal>
			<div className={styles.modal__container}>
				<div className={styles.modal__content}>
					<div
						className={styles.modal__header}
						style={{
							backgroundImage: `url("${
								deal.community?.backgroundUrl || deal.community?.avatarUrl || DEFAULT_NETWORK_BACKGROUND_IMAGE_PATH
							}")`,
						}}
					>
						<span className={styles.modal__headerTitle}>{deal.community.name}</span>
					</div>
					<div className={styles.modal__body}>
						<span className={styles.modal__bodyTitle}>
							{t('To view this Deal, you must join the {{networkName}} Network.', {
								networkName: deal.community.name,
							})}
						</span>
						<span className={styles.modal__bodySubtitle}>{deal.community.description}</span>
						<div className={styles.modal__bodyButtons}>
							<Button title={t('Close')} type="solid" variant="medium" onClick={onClose} />
							{community ? (
								<NetworkAttendanceButton
									icon={<RightArrowIcon height={14} width={16} />}
									iconPosition="right"
									joinTitle={t('Join and View Deal')}
									network={community}
									pendingTitle={t('Request Sent')}
									requestTitle={t('Request to Join')}
									showRejectButton
									onJoin={() => joinNetwork(community)}
									onRequestAccess={() => requestAccess(community)}
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};

interface IJoinDealNetworkModalProps {
	deal: TProfileDeal;
	onClose: () => void;
	onJoinSuccess: () => void;
	onRequestSuccess: () => void;
}

export default JoinDealNetworkModal;
