import { useMe } from '@hooks';
import { isUserFeatureDisabledByAdmin } from '@utils';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';

export const useDealsEnabled = () => {
	const { user } = useMe();
	const isInvestor = user.roles.includes('ROLE_INVESTOR');
	const isFeatureEnabled = ProfileEntity.isFeatureEnabled(user, 'invest');
	const isEnabledByUser = isFeatureEnabled && isInvestor;
	const isDisabledByAdmin = isUserFeatureDisabledByAdmin(user, 'invest');
	return { enabled: isEnabledByUser, isSettingsAvailable: isInvestor && !isDisabledByAdmin };
};
