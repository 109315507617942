import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';

export class OktaMfaRequiredError extends OktaError {
	status = 'MFA_REQUIRED';
	getMessage() {
		return {
			title: 'MFA_REQUIRED',
			subtitle: 'MFA_REQUIRED',
		};
	}

	static shouldThrow(transaction: IdxTransaction) {
		return (
			transaction.nextStep?.name === 'select-authenticator-authenticate' ||
			transaction.nextStep?.name === 'challenge-authenticator' ||
			transaction.nextStep?.name === 'authenticator-verification-data'
		);
	}
}
