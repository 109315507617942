import { useState, useClickAway, useRef } from '@hooks';
import { clsx } from '@utils';
import type { MutableRefObject, ReactNode } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import styles from './MessageMenu.module.css';
import type { TMeatBallPosition } from './MessageMenu.utils';
import { getMenuPosition } from './MessageMenu.utils';
import { ActionsIcon as DefaultActionsIcon } from 'stream-chat-react';
import { Portal } from '@ui-kit';
import type { MessageResponse } from 'stream-chat';

const MENU_WIDTH = 300;

const getDisplayPosition = (menuButtonRect: DOMRect, isMy: boolean): TMeatBallPosition => {
	const displayTop = menuButtonRect.top > window.innerHeight / 2;
	const displayLeft = window.innerWidth - menuButtonRect.right < MENU_WIDTH || isMy;
	if (displayTop) {
		return displayLeft ? 'top-left' : 'top-right';
	}
	return displayLeft ? 'bottom-left' : 'bottom-right';
};

interface IMessageMenuCors {
	left?: number;
	top?: number;
	position: TMeatBallPosition;
}

const MessageMenu = ({
	children,
	options,
	isMyMessage,
	testID,
	disabled = false,
	type = 'default',
	onMenuVisible,
	message,
	CustomMenuContainer,
}: IMeatballMenuProps) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [coords, setCoords] = useState<IMessageMenuCors>({
		left: 0,
		top: 0,
		position: 'center',
	});
	const meatballMenuRef = useRef(null);

	useClickAway(meatballMenuRef, () => setMenuVisible(false));

	useEffect(() => {
		onMenuVisible?.(menuVisible);
	}, [menuVisible, onMenuVisible]);
	const menuOptions = useMemo(() => {
		return options?.map(({ text, onClick, icon: IconComponent, type = 'default', customOption }) => {
			return (
				<button
					className={clsx(styles.messageMenu__menuOption, styles.messageMenu__button)}
					key={`${text}`}
					onClick={() => {
						setMenuVisible(false);
						onClick();
					}}
				>
					{customOption ? (
						customOption
					) : (
						<div className={styles.messageMenu__menuOptionItem}>
							{IconComponent ? <span className={styles.messageMenu__menuOptionIcon}>{IconComponent}</span> : null}
							<span
								className={clsx(
									styles.messageMenu__menuOptionLabel,
									type === 'destructive' && styles.messageMenu__menuOptionLabel_destructive,
								)}
							>
								{text}
							</span>
						</div>
					)}
				</button>
			);
		});
	}, [options]);

	return (
		<div
			className={styles.meatball}
			onClick={(event) => event.stopPropagation()}
			onKeyPress={(event) => event.stopPropagation()}
		>
			<button
				className={styles.messageMenu__button}
				data-testid={testID}
				data-type={type}
				disabled={disabled}
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					const rect = e?.currentTarget?.getBoundingClientRect();
					const position = getDisplayPosition(rect, isMyMessage);
					setCoords({ ...getMenuPosition(rect, position), position });
					setMenuVisible(true);
				}}
			>
				{children ? children : <DefaultActionsIcon className="str-chat__message-action-icon" />}
			</button>
			{menuVisible && menuOptions ? (
				<>
					<Portal>
						<div className={styles.messageMenu__backdrop} /> {/* To prevent clicking on elements under menu. */}
					</Portal>
					{CustomMenuContainer ? (
						<CustomMenuContainer menuOptions={menuOptions} menuRef={meatballMenuRef} />
					) : (
						<Portal>
							<div
								className={styles.messageMenu__menuContainer}
								data-position={coords.position}
								ref={meatballMenuRef}
								style={{ top: coords.top, left: coords.left }}
							>
								{menuOptions}
							</div>
						</Portal>
					)}
				</>
			) : null}
		</div>
	);
};

type ButtonType = 'default' | 'solid';

interface IMeatballMenuProps {
	/** Meatball custom button */
	children?: ReactNode;
	/** Options that will be displayed inside Meatball menu list */
	options: TMeatballMenuOption[];
	/** ID for test queries. */
	testID?: string;
	/** Should it be able to be clicked. Default=false. */
	disabled?: boolean;
	/** Button type */
	type?: ButtonType;
	/** Menu visible handler */
	onMenuVisible?: (visible: boolean) => void;
	/** Custom menu container */
	CustomMenuContainer?: React.FC<TCustomMenuContainerProps>;
	/**stream message */
	message: MessageResponse;
	/** Message of current user */
	isMyMessage: boolean;
}

export type TMeatballMenuOption = {
	text: string | JSX.Element;
	onClick: () => void;
	icon?: ReactNode;
	type?: 'default' | 'destructive' | 'solid';
	customOption?: ReactNode;
};

export type TCustomMenuContainerProps = {
	menuOptions: JSX.Element[];
	menuRef: MutableRefObject<null>;
};

export default MessageMenu;
