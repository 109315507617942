import { useTranslation } from '@hooks';
import dealsImage from '@ui-modules/deals/assets/deals.png';
import styles from '../MyDealsZeroState/Deals.module.css';

const NotInterestedZeroState = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.dealTab__zeroWrapper}>
			<img alt="" src={dealsImage} />
			<p className={styles.dealTab__zeroTitle}>{t`You haven’t marked any Deals as “Not Interested”`}</p>
			<span className={styles.dealTab__zeroSubTitle}>{t(`Deals you mark as “Not Interested” are listed here.`)}</span>
		</div>
	);
};

export default NotInterestedZeroState;
