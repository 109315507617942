import { ASSET_ALLOCATION_TIGER21_BG_IMAGE_PATH } from '@constants';
import type { TCommunityListAssetAllocation, TTiger21AssetAllocation } from '@typings';
import type { TFunction } from 'i18next';

export function getAssetAllocationInfoContent(t: TFunction) {
	return (
		comparingAssetAllocation: TTiger21AssetAllocation | TCommunityListAssetAllocation | undefined | null,
		isTiger21: boolean,
	) => {
		if (!comparingAssetAllocation) {
			return { comparativeFullName: '', heroBackgroundImageUrl: '', comparativeTitle: '' };
		} else if (isTiger21) {
			return {
				comparativeFullName: t('Tiger 21').toUpperCase(),
				heroBackgroundImageUrl: ASSET_ALLOCATION_TIGER21_BG_IMAGE_PATH,
				comparativeTitle: t('All Tiger 21 members'),
			};
		} else {
			const comparativeName = String(comparingAssetAllocation.community?.name);
			const isNetwork = comparingAssetAllocation.community?.definition === 'network';
			return {
				comparativeFullName: String(comparingAssetAllocation.community?.name),
				heroBackgroundImageUrl: String(comparingAssetAllocation.community?.backgroundUrl),
				comparativeTitle: isNetwork ? t('{{scope}} network members', { scope: comparativeName }) : comparativeName,
			};
		}
	};
}
