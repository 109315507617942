import { Post } from '@ui-kit';
import { useTranslation } from '@hooks';
import { ActivityEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TActivity } from '@typings';

/** The minimum post card to show within other UI features. */
const FeedActivityPreview = ({ activity }: IFeedActivityPreviewProps) => {
	const { t } = useTranslation();
	const activityEntity = new ActivityEntity(activity);
	const profileLink = activityEntity.isInactive ? undefined : ROUTES.memberProfile(activityEntity.profileId);
	const hasFiles = !!activityEntity.files?.length;
	const hasImages = !!activityEntity.images?.length;
	const hasVideos = !!activityEntity.videos?.length;

	return (
		<Post
			headerElement={
				<Post.Header
					avatarProps={{
						imageUri: activityEntity.avatarUri,
						outline: activityEntity.isChair,
						size: 40,
						isInactive: activityEntity.isInactive,
					}}
					createdAt={activityEntity.createdAt}
					profileLink={profileLink}
					showFileAttachments={hasFiles}
					showImageAttachments={hasImages}
					showVideoAttachments={hasVideos}
					sourceName={activityEntity.determineSourceName(t)}
					userId={activity?.actor?.data?.userId}
					userName={activityEntity.getDisplayingUserName(t)}
				/>
			}
			isCard
		>
			<>
				{activityEntity.title ? <Post.Title>{activityEntity.title}</Post.Title> : null}
				{activityEntity.content ? (
					<Post.Content collapsedTitle={t('Read more')} expandedTitle={t('Show less')}>
						{activityEntity.htmlContent}
					</Post.Content>
				) : null}
			</>
		</Post>
	);
};

export interface IFeedActivityPreviewProps {
	activity: TActivity;
}

export default FeedActivityPreview;
