import type { TAddressContact, TProfile } from '@typings';

/** Creates TAddressContact with billing address which is stored within another Profile property
 *  to use it in the same way as other profile addresses.
 */
export const makeBillingAddressContact = (profileBillingAddress: TProfile['billingAddress']): TAddressContact => {
	const addressContact: TAddressContact = {
		address: profileBillingAddress || {
			country: null,
			state: null,
			postalCode: null,
			city: null,
			streetAddress1: null,
			streetAddress2: null,
		},
		type: 'billing',
		primary: false,
		visibleToAll: true,
	};

	return addressContact;
};
