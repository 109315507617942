import { useService } from '@services';
import type { TEvent } from '@typings';
import type { MessageResponse, Channel } from 'stream-chat';

export const useChat = () => {
	const { useAppSelector, chat, useAppDispatch } = useService('ReduxService');

	const dispatch = useAppDispatch();

	const {
		editMessage,
		quotedMessageId,
		selectedChannel,
		shouldUpdateChannel,
		selectedUsersIds,
		channelHasPauseMembers,
		removedChannelIds,
		chatName,
		selectedEvent,
	} = useAppSelector((store) => store.chat);

	const setEditMessage = (editingMessage: MessageResponse | null) => {
		dispatch(chat.setChatEvent(editingMessage));
	};

	const setSelectedEvent = (event: TEvent | null) => {
		dispatch(chat.setSelectedEvent(event));
	};

	/**Note : Rather then storing entire Channel just store id and get channel whenever needed by using Id */
	const setSelectedChannel = (selectedChannel: Channel | null) => {
		dispatch(chat.setSelectedChannel(selectedChannel));
	};
	const setShouldUpdateChannel = (update: boolean) => {
		dispatch(chat.setShouldUpdateChannel(update));
	};
	const setQuotedMessageId = (quotedMessageId: string) => {
		dispatch(chat.setQuotedMessageId(quotedMessageId));
	};
	const setSelectedUserIds = (selectedUsersId: string[] | null) => {
		dispatch(chat.setSelectedUsersIds(selectedUsersId));
	};
	const setChatName = (selectedUsersId: string) => {
		dispatch(chat.setChatName(selectedUsersId));
	};
	const setRemovedChannelsId = (removedChannelIds: string[] | null) => {
		dispatch(chat.setRemovedChannelsId(removedChannelIds));
	};
	const setChannelHasPausedMembers = (pause: boolean) => {
		dispatch(chat.setChannelHasPausedMembers(pause));
	};
	const reset = () => {
		dispatch(chat.resetState());
	};

	return {
		editMessage,
		setEditMessage,
		reset,
		quotedMessageId,
		setQuotedMessageId,
		selectedChannel,
		setSelectedChannel,
		setShouldUpdateChannel,
		shouldUpdateChannel,
		selectedUsersIds,
		setSelectedUserIds,
		channelHasPauseMembers,
		setChannelHasPausedMembers,
		removedChannelIds,
		setRemovedChannelsId,
		chatName,
		setChatName,
		selectedEvent,
		setSelectedEvent,
	};
};
