import { useMemo, useEvent, useSearchParams } from '@hooks';
import { yup } from '@utils';
import type { TAssetAllocationPeriod } from '@typings';

const transformStringToNumber = (value: unknown) => Number(value) || undefined;
const periodSchema = yup.object().shape({
	year: yup.number().transform(transformStringToNumber).required(),
	quarter: yup.number().transform(transformStringToNumber).required(),
});

export const useAssetAllocationComparePeriodSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const selectedComparePeriod: TAssetAllocationPeriod | undefined = useMemo(
		function parseSearchParams() {
			try {
				return periodSchema.validateSync({
					year: searchParams.get('year'),
					quarter: searchParams.get('quarter'),
				});
			} catch (error) {
				return undefined;
			}
		},
		[searchParams],
	);

	const setSelectedComparePeriod = useEvent((period: TAssetAllocationPeriod | undefined, replace?: boolean) => {
		if (!period) {
			searchParams.delete('year');
			searchParams.delete('quarter');
			return setSearchParams(searchParams); // to trigger update of the useSearchParams() hook to get a new searchParams, because the call of searchParams.delete just mutates without re-render [@DmitriyNikolenko].
		}
		setSearchParams(
			{ year: period.year.toString(), quarter: period.quarter.toString() },
			{
				replace,
			},
		);
	});

	return [selectedComparePeriod, setSelectedComparePeriod] as const;
};
