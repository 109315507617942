import { Button, MeatballMenu, Post } from '@ui-kit';
import ApiVideo from '@ui-modules/media/components/ApiVideo';
import { useTranslation } from '@hooks';
import { clsx, compact } from '@utils';
import styles from './FeedAdVideo.module.css';
import type { TAdVideo } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import type { IFeedActivityProps } from '../FeedActivity';

const FeedAdVideo = ({ title, adVideo, canDeletePost, onClick, onDelete, onVideoPlayed }: IFeedAdVideoProps) => {
	const { t } = useTranslation();

	const isPostVideoOnly = !title && !adVideo.link;
	const videoPlayerWidth = isPostVideoOnly ? 700 : 700 - 32; // horizontal paddings added if post is not compact

	const menuOptions: TMeatballMenuOption[] = compact([
		canDeletePost && {
			text: t('Delete post'),
			onClick: () => onDelete(),
			type: 'destructive',
		},
	]);

	return (
		<article className={clsx(styles.feedAdVideo__box, isPostVideoOnly && styles.feedAdVideo__box_compact)}>
			{title ? <Post.Title>{title}</Post.Title> : null}
			<ApiVideo playerWidth={videoPlayerWidth} videoUrl={adVideo.videoUrl} onVideoPlayed={onVideoPlayed} />
			{adVideo.link ? <Button title={adVideo.text} type="outline" onClick={() => onClick(adVideo.link)} /> : null}

			{menuOptions?.length ? (
				<div
					className={clsx(
						styles.feedAdVideo__meatballMenu,
						isPostVideoOnly && styles.feedAdVideo__meatballMenu_postVideoOnly,
					)}
				>
					<MeatballMenu options={menuOptions} position="left" type="solid" />
				</div>
			) : null}
		</article>
	);
};

export interface IFeedAdVideoProps extends Pick<IFeedActivityProps, 'onVideoPlayed'> {
	title?: string;
	adVideo: TAdVideo;
	canDeletePost: boolean;
	onClick: (link: string) => void;
	onDelete: () => void;
}

export default FeedAdVideo;
