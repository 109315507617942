import { EmptyStateMessage } from '@ui-kit';
import { ErrorBoundary } from 'react-error-boundary';
import DeleteActivityModal from '../DeleteActivityModal';
import type { TActivity } from '@typings';
import type { PropsWithChildren } from 'react';

/** Wrapper around FeedActivity which keeps ErrorBoundary & modal windows. */
const FeedActivityWrapper = ({
	children,
	activity,
	isDeleteActivityDialogVisible,
	toggleDeleteActivityDialog,
	onActivityDeleted,
}: IFeedActivityWrapperProps) => {
	return (
		<ErrorBoundary fallback={<EmptyStateMessage text="Failed to load the post." />} key={activity.id}>
			{children}
			<DeleteActivityModal
				activity={activity}
				visible={isDeleteActivityDialogVisible}
				onClose={toggleDeleteActivityDialog}
				onSuccess={onActivityDeleted}
			/>
		</ErrorBoundary>
	);
};

export interface IFeedActivityWrapperProps extends PropsWithChildren {
	/** Activity to render. */
	activity: TActivity;
	/** SHould show delete dialog. */
	isDeleteActivityDialogVisible: boolean;
	/** Switch visibility of Delete dialog. */
	toggleDeleteActivityDialog: (visible?: boolean) => void;
	/** Called when activity was deleted. */
	onActivityDeleted?: () => void;
}

export default FeedActivityWrapper;
