import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';
import type { GroupedVirtuosoHandle } from 'react-virtuoso';

/**	To implement scroll to an item in a list which has changed own position (because of add/delete item) use this hook.
 * 	Updating of the list is very asynchronous, so we need to
 * 	   1) save changed item;
 *     2) observe when the list of items is changed;
 *     3) fire scroll after timeout (to allow the list to update itself)
 * 	   4) reset changed item.
 *  Initially implemented to resolve T21C-5136.
 * 	@author DmitriyNikolenko
 */
export const useScrollToChangedItem = <T extends TIdentified, R extends RefObject<GroupedVirtuosoHandle>>(
	listRef: R,
	items: T[],
) => {
	const lastAddedNetworkIdRef = useRef<TMaybeId>(null);

	const setLastChangedItemId = (itemId: TMaybeId) => {
		lastAddedNetworkIdRef.current = itemId;
	};
	const scrollToLastChangedItemId = (itemId: TId, networks: T[]) => {
		const index = networks.findIndex((item) => item.id === itemId);
		setTimeout(() => listRef.current?.scrollToIndex({ index }), 500);
	};

	useEffect(() => {
		if (lastAddedNetworkIdRef.current) {
			scrollToLastChangedItemId(lastAddedNetworkIdRef.current, items);
			setLastChangedItemId(null);
		}
	}, [items]);

	return { listRef, setLastChangedItemId };
};

type TId = string;
type TMaybeId = TId | null;
type TIdentified = { id: TId };
