import { Button, ExternalLinkIcon, Gap, When } from '@ui-kit';
import { DownloadMobileAppOverlay } from '../DownloadMobileAppOverlay';
import { isMobileOnly, isTablet, isMacOs } from 'react-device-detect';
import {
	useTranslation,
	useEffect,
	useIsWindowTooSmall,
	useShouldPersistShowingAppOnTablet,
	useShowPageInMobile,
} from '@hooks';
import type { PropsWithChildren } from 'react';

/** The DownloadMobileAppOverlay showed when a user uses mobile browser. */
const MobileScreenWrapper = ({ children }: PropsWithChildren) => {
	const { t } = useTranslation();

	const { shouldPersistShowingAppOnTablet, setPersistShowingAppOnTablet } = useShouldPersistShowingAppOnTablet();
	const { showMobilePage } = useShowPageInMobile();
	const isWindowSmall = useIsWindowTooSmall();
	const isAppBlocked = isMobileOnly || (isTablet && !shouldPersistShowingAppOnTablet);
	const shouldShowPersistShowingAppOnTabletButton = isTablet && !isWindowSmall;

	useEffect(() => {
		if (!isMacOs) document.body.classList.add('custom-scrollbar'); // uses to customize scrollbar through styles/scrollbar.css [@dmitriy.nikolenko]
	}, [isMacOs]);

	if (!showMobilePage && isAppBlocked)
		return (
			<DownloadMobileAppOverlay
				subtitle={t("Don't have T21 Connect installed? Use the links below to download T21 Connect")}
				title={t('Opening "T21 Connect"')}
			>
				<When condition={shouldShowPersistShowingAppOnTabletButton}>
					<Gap gap={16} />
					<Button
						icon={<ExternalLinkIcon height={14} width={16} />}
						iconPosition="right"
						title={t('Launch The "T21 Connect" App')}
						onClick={() => setPersistShowingAppOnTablet()}
					/>
					<Gap gap={8} />
				</When>
			</DownloadMobileAppOverlay>
		);
	return <>{children}</>;
};

export default MobileScreenWrapper;
