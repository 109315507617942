import { Button, DoubleCheckIcon } from '@ui-kit';
import { useTranslation } from '@hooks';

import styles from './NotificationsList.module.css';

const NotificationListHeader = ({ isMarkAsReadDisabled, onMarkAsRead }: INotificationListHeaderProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.notificationsList__header}>
			<h3>{t('Notifications')}</h3>
			<Button
				disabled={isMarkAsReadDisabled}
				icon={<DoubleCheckIcon height={12} width={11} />}
				iconPosition="left"
				title={t('Mark All As Read')}
				variant="small"
				onClick={onMarkAsRead}
			/>
		</div>
	);
};

interface INotificationListHeaderProps {
	isMarkAsReadDisabled: boolean;
	onMarkAsRead?: () => void;
}

export default NotificationListHeader;
