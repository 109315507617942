import type { TUserProfileRecord } from '@typings';
import type { TFunction } from 'i18next';

export const getAvailableContacts = (
	members: { id?: string; role?: string; banned?: boolean }[],
	allContacts?: TUserProfileRecord[],
) => {
	const contacts = allContacts?.filter(
		(contact) => !members.some((member) => member.id === `${contact.firstName} ${contact.lastName}`),
	);
	return contacts;
};

// This will be called when we pressed on save after adding or removing users
export const onSavePressed = (
	selectedContacts: TUserProfileRecord[],
	initialSelectedUserIds: string[],
	initialSelectedUsersName: string[],
	userId: string,
	t: TFunction,
) => {
	// Suppose we have 4 already added user and we removed 2 user from chat
	// in that case to remove user from stream channel we are using this logic
	const selectedContactsId = selectedContacts.map((contact) => contact.slug) as string[];
	const staffAndChairIds = selectedContacts
		.filter((contact) => contact.roles.includes('ROLE_CHAIR') || contact.roles.includes('ROLE_STAFF'))
		.map((contact) => contact.slug) as string[];

	selectedContactsId.push(userId);
	const selectedContactsName = selectedContacts.map((contact) => `${contact.firstName} ${contact.lastName}`);
	// This will give us newly added users name which will not include initial users that where selected at the time of group creation
	const newlyAddedUserName = selectedContactsName?.filter((item) => !initialSelectedUsersName?.includes(item));
	const addUsersMessage = ''; // T21C-5484 Temporary disabled    // newlyAddedUserName ? addUserMessage(newlyAddedUserName, t) : '';
	// build message for channel when single and multiple users get added
	//In selected contact we are now not showing current user so we need to add its id manually
	const removedUsersId = initialSelectedUserIds?.filter((item) => !selectedContactsId?.includes(item));
	const removedUsersName = initialSelectedUsersName?.filter((item) => !selectedContactsName?.includes(item));
	const removeUsersMessage = ''; // T21C-5484 Temporary disabled    //  removedUsersName ? removedUserMessage(removedUsersName, t) : '';
	return { removedUsersId, removeUsersMessage, addUsersMessage, selectedContactsId, staffAndChairIds };
};

// build message for channel when single and multiple users get added
const addUserMessage = (addedUsersName: string[], t: TFunction) => {
	const message =
		addedUsersName && addedUsersName?.length > 1
			? `${addedUsersName.join(',')}  ${t('users are added to the group chat')}`
			: addedUsersName
			? `${addedUsersName.join(',')} ${t('user is added to the group chat')}`
			: '';

	return message;
};

// build message for channel when single and multiple users get removed
const removedUserMessage = (removedUsersName: string[], t: TFunction) => {
	const message =
		removedUsersName && removedUsersName?.length > 1
			? `${removedUsersName.join(',')}  ${t('users are removed from the group chat')}`
			: removedUsersName
			? `${removedUsersName.join(',')} ${t('user is removed from the group chat')}`
			: '';
	return message;
};

export const makeChannelName = (selectedUser: string[]) => {
	const usersName = selectedUser
		.map((i) => i)
		.sort()
		.join(', ');
	return usersName;
};
