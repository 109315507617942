import { useService, useDebounce, useInfiniteQuery, useMemo, useEffect } from '@hooks';
import { dayjs, noop, adaptSearchNcToNc } from '@utils';
import type { TAlgoliaSearchResponse, TCommunity, TNetworkConnection, TSearchNetworkConnection } from '@typings';

export const useNetworkConnectionsSearchQuery = ({
	searchQuery,
	communityId,
	onSearch,
}: IUseNetworkConnectionsSearchQuery) => {
	const algoliaSearch = useService('AlgoliaSearchService');
	const reactQuery = useService('ReactQueryService');
	const [preparedSearchQuery] = useDebounce(searchQuery.trim(), 300);
	const isSearchEnabled = preparedSearchQuery.length >= 3;
	useEffect(() => {
		const searchedQuery = isSearchEnabled ? preparedSearchQuery : '';
		onSearch(searchedQuery);
	}, [preparedSearchQuery]);

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse<TSearchNetworkConnection>, Error>(
		reactQuery.queryKeys.searchByFeedNCActivities(communityId, preparedSearchQuery),
		async ({ pageParam = 0 }) =>
			await algoliaSearch.searchByNetworkConnections({
				searchQuery: preparedSearchQuery,
				page: pageParam,
				communityId,
			}),
		{
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			enabled: isSearchEnabled,
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) return maybeNextPage;
				return undefined;
			},
		},
	);
	const data = useMemo<TNetworkConnection[]>(
		() =>
			(infiniteQuery.data?.pages.flatMap((page) =>
				page.hits.map((searchNetworkConnections) => {
					return adaptSearchNcToNc(searchNetworkConnections);
				}),
			) ?? EMPTY_RESPONSE) as TNetworkConnection[],
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		preparedSearchQuery,
		isSearchEnabled,
		...infiniteQuery,
		data,
		fetchMore,
	};
};

interface IUseNetworkConnectionsSearchQuery {
	searchQuery: string;
	communityId: TCommunity['id'] | null;
	onSearch: (searchQuery: string) => void;
}

const EMPTY_RESPONSE = undefined;
