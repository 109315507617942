import { createContext } from 'react';
import { noop } from '@utils';
import type { TMixpanelEvent } from '@typings';

export const UikitContext = createContext<IUikitContextValue>({
	avatarBaseUri: 'https://dev.api.tiger21.com/api', // placeholder url
	trackClickEvent: noop,
});

export interface IUikitContextValue {
	avatarBaseUri: string;
	trackClickEvent: (ctaName: TMixpanelEvent) => void;
}
