import { useService, useQuery, useMemo } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity } from '@typings';

export const useAllChaptersQuery = (searchQuery?: string) => {
	const api = useService('ApiService');
	const query = useQuery(['community.getChapters'], async () => await api.community.getChapters(), {
		staleTime: dayjs.duration(30, 'minute').asMilliseconds(),
	});
	const filteredChapters = useMemo(() => filterChapters(query.data ?? [], searchQuery), [query.data, searchQuery]);

	return {
		...query,
		filteredChapters,
	};
};

function filterChapters(groups: TCommunity[], query = '') {
	if (!query.length) return groups;
	return groups.filter((group) => group.name.toLowerCase().includes(query.toLowerCase()));
}
