import { useEffect, useNavigate, useTranslation } from '@hooks';

import styles from './EditDealFiltersForm.module.css';
import { ActivityIndicator, CheckboxNestedInput, Form, TabSelect, AmountInput, ResetFilters } from '@ui-kit';
import type { TTab } from '@ui-kit/components/TabSelect/TabSelect';
import RadioButtonsFilters from '@ui-modules/deals/components/RadioButtonsFilters';

import { dealStatusTabs as tabs, ROUTES } from '@constants';
import type { FormikProps } from 'formik';
import type { TEditDealFilters } from '@schemas';
import CheckBoxChild from '../CheckBoxChild';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { updateCheckMarksBooleanValues } from '@ui-modules/deals/hooks/updateCheckMarksBooleanValues';
import {
	useParseAllDealFilters,
	useSelectedDealTypes,
	useSelectedSubClasses,
} from '@ui-modules/deals/hooks/useParseAllDealFilters';
import type { TDealAssetClass } from '@typings';
import useAllDealFilterConfig from '@ui-modules/deals/hooks/useAllDealFilterConfigQuery';
import { arrangeNetworksForFilter } from '@utils';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTrackDealFiltersChanged } from '@ui-modules/deals/hooks/useTrackDealFiltersChanged';

const EditDealFiltersForm = ({
	formProps,
	optionIndex,
	setOptionIndex,
	setAvailableDealTypes,
	onReset,
}: IEditDealFiltersFormProps) => {
	const { t } = useTranslation();
	const dealStatusTabs = tabs(t);
	const { setFieldValue, values, errors, initialValues, submitCount, dirty } = formProps;

	const navigate = useNavigate();
	const trackDealFiltersChanged = useTrackDealFiltersChanged();

	const [checkMarks, setCheckMarks] = useState<ICheckmarkState[] | []>([]);
	const [types, setTypes] = useState<ICheckmarkState[] | []>([]);
	const [activeStatusIndex, setActiveStatusIndex] = useState<number>(Number(values.status));

	const selectedSubClasses = useSelectedSubClasses(checkMarks) as string[];
	const selectedDealTypes = useSelectedDealTypes(types) as string[];

	const { initialCheckMarks, dealTypes, isLoading, isDealTypeLoading, allDealTypes, readyToRender } =
		useParseAllDealFilters({
			initialDealTypes: dirty ? types.map((item) => `/api/deal_types/${item.id}`) : initialValues.dealTypes,
			initialDealSubClasses: initialValues.dealSubClasses,
			optionIndex,
			selectedSubClasses,
		});

	const { data: allDealFilterConfig } = useAllDealFilterConfig();

	useEffect(
		function trackFilterChanged() {
			if (submitCount > 0) {
				trackDealFiltersChanged([...initialCheckMarks, ...dealTypes], [...checkMarks, ...types]);
			}
		},
		[submitCount],
	);

	const onShowAllChange = () => {
		setOptionIndex(0);
		setTimeout(() => {
			setTypes(updateCheckMarksBooleanValues(allDealTypes, false));
		}, 0);
		setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
	};

	const onLimitSelectionChange = () => {
		setOptionIndex(1);
		setTimeout(() => {
			setTypes(updateCheckMarksBooleanValues(allDealTypes, false));
		}, 0);
		setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
	};

	useEffect(() => {
		if (optionIndex === 0) {
			setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
		} else {
			setCheckMarks(initialCheckMarks);
		}
	}, [initialCheckMarks]);

	useEffect(() => {
		if (dealTypes) {
			if (optionIndex === 0) {
				setTypes(updateCheckMarksBooleanValues(dealTypes, false));
			} else {
				setTypes(dealTypes);
			}
		}
	}, [dealTypes.length]);

	useEffect(() => {
		if (isDealTypeLoading) return;
		setFieldValue('dealSubClasses', selectedSubClasses);
		setFieldValue('dealTypes', selectedDealTypes);
	}, [checkMarks, types]);

	useEffect(() => {
		setAvailableDealTypes(dealTypes);
	}, [dealTypes.length]);

	const resetFormCheckMarks = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setCheckMarks(
			initialCheckMarks.map((item) => ({
				...item,
				value: false,
				children: item.children?.map((child) => ({
					...child,
					value: false,
				})),
			})),
		);

		setTypes(
			dealTypes.map((item) => {
				return { ...item, value: false };
			}),
		);
		setFieldValue('status', String(1));
		setActiveStatusIndex(1);
		setOptionIndex(0);
		setFieldValue('minInvestment', 0);
		onReset();
	};
	if (!readyToRender) return null;

	return (
		<>
			<div className={styles.wrapper}>
				<Form className={styles.form}>
					<div className={styles.header}>
						<span className={styles.title}>{t('Filtering All Deals')}</span>
						<ResetFilters title={t('Reset Filters')} onClick={resetFormCheckMarks} />
					</div>
					<span className={styles.status}>{t('Deal Status')}</span>
					<TabSelect
						tabs={dealStatusTabs as TTab[]}
						value={activeStatusIndex}
						onChange={(item) => {
							setActiveStatusIndex(item.id);
							setFieldValue('status', String(item.id));
						}}
					/>
					<div className={styles.subClassWrapper}>
						<span className={styles.subClassTitle}>{t('Asset Classes')}</span>
						<span className={styles.subClassInfo}>{t('(select all that apply)')}</span>
					</div>
					<span className={styles.myInvestments}>{t('My Investment Networks')}</span>

					{isLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : (
						<div className={styles.checkboxesWrapper}>
							<RadioButtonsFilters
								optionIndex={optionIndex}
								onLimitSelectionChange={onLimitSelectionChange}
								onShowAllChange={onShowAllChange}
							/>
							<div className={styles.checkboxOptionsWrapper}>
								<CheckboxNestedInput
									checkMarks={checkMarks}
									childrenDisabled={optionIndex === 0}
									receiveChanges={setCheckMarks}
									renderChild={(props) => <CheckBoxChild {...props} filterCheckbox />}
								/>
							</div>
						</div>
					)}

					{!!allDealFilterConfig?.investNetworks?.length && (
						<div className={styles.checkboxOptionsWrapper}>
							<span className={styles.allNetworksTitle}>{t('All Investment Networks')}</span>
							<span className={styles.showDeals}>{t('To Show these Deals, first join the Network')}</span>
							<CheckboxNestedInput
								checkMarks={arrangeNetworksForFilter?.(allDealFilterConfig?.investNetworks as TDealAssetClass[])}
								childrenDefaultValue={false}
								childrenDisabled
								handleLinkPress={(id) => navigate(ROUTES.networkTab(id, 'about', true))}
								isCheckMarkLink
							/>
						</div>
					)}

					{isDealTypeLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : (
						!!types.length && (
							<div className={styles.checkboxOptionsWrapper}>
								<span className={styles.type}>{t('Type(s)')}</span>
								<CheckboxNestedInput
									checkMarks={types.sort((a, b) => (a.label || '').localeCompare(b.label || ''))}
									childrenDefaultValue={false}
									childrenDisabled={optionIndex === 0}
									receiveChanges={setTypes}
								/>
							</div>
						)
					)}
					<div className={styles.minimumInputWrapper}>
						<AmountInput
							errorMessage={errors.minInvestment}
							label={t('Minimum')}
							name="maxValue"
							placeholder={t('e.g 50,000')}
							value={Number(values.minInvestment) === 0 ? '' : String(values.minInvestment)}
							onChange={(e) => setFieldValue('minInvestment', String(e.target.value).replace(/,/g, ''))}
						/>
					</div>
				</Form>
			</div>
		</>
	);
};

export interface IEditDealFiltersFormProps {
	activeStatus: number;
	formProps: FormikProps<TEditDealFilters>;
	optionIndex: number;
	setOptionIndex: Dispatch<SetStateAction<number>>;
	onReset: () => void;
	setAvailableDealTypes: React.Dispatch<React.SetStateAction<ICheckmarkState[] | null>>;
}

export default EditDealFiltersForm;
