import { FullScreenPageLayout, Page } from '../../components';
import {
	useNavigate,
	useParams,
	useTranslation,
	useState,
	useEffect,
	useService,
	useMe,
	useDebouncedCallback,
	useChat,
} from '@hooks';
import { UserListItem, Virtuoso } from '@ui-kit';
import styles from './CreateGroupChatPage.module.css';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';
import { makeChannelName } from '@ui-modules/chat/utils/makeChannelName';
import type { TUserProfileRecord } from '@typings';
import { formatUserSubTitle } from '@utils';
import { ROUTES } from '@constants';

const CreateGroupChatPage = () => {
	const { t } = useTranslation();
	const { setSelectedUserIds, chatName, setChatName } = useChat();

	const navigate = useNavigate();
	const chat = useService('ChatService');
	const { user } = useMe();

	const [selectedUsers, setSelectedUsers] = useState<TUserProfileRecord[]>([]);
	const [groupName, setGroupName] = useState(chatName);
	const isGroupNameInvalid =
		String(groupName).length !== String(groupName).trim().length && String(groupName).trim().length === 0; // check if it is spaces only
	// Data fetching.
	const { data: userProfileRecords = [] } = useAddressBookQuery();
	const selectedUserIds = useParams().selectedUserIds;

	useEffect(() => {
		if (selectedUserIds) {
			const users = userProfileRecords?.filter((item) => selectedUserIds?.includes(item.slug));
			setSelectedUsers(users);
		}
	}, [userProfileRecords]);

	const renderUserItem = (index: number, selectedUser: TUserProfileRecord) => {
		return <UserListItem renderUserSubTitle={formatUserSubTitle(t)} size="small" userProfileRecord={selectedUser} />;
	};
	const onNextPressed = useDebouncedCallback(
		() => {
			const usersSlugs = [...selectedUsers.map((i) => i.slug as string), user.slug];
			const channelName =
				groupName === ''
					? makeChannelName([
							...selectedUsers,
							{ firstName: user.firstName, lastName: user.lastName } as TUserProfileRecord,
					  ])
					: groupName;
			chat.openChannel(Date.now().toString(), channelName, usersSlugs).then((channel) => {
				setTimeout(() => {
					setSelectedUserIds(null);
					setChatName('');
					navigate(ROUTES.chatChannel(channel.id), { replace: true });
				}, 1500);
			});
		},
		1200,
		{ leading: true, trailing: false },
	);

	return (
		<Page title={t('Create Group')}>
			<FullScreenPageLayout
				headerTitle={t('Group Chat')}
				rightButtonDisabled={isGroupNameInvalid}
				rightButtonText={t('Next')}
				onLeftButtonPress={() => navigate(-1)}
				onRightButtonPress={onNextPressed}
			>
				<div className={styles.createGroupChat__wrapper}>
					<div className={styles.createGroupChat__inputContainer}>
						<div className={styles.createGroupChat__nameText}>{t('Name')}</div>
						<input
							className={styles.createGroupChat__input}
							data-invalid={isGroupNameInvalid}
							placeholder={'Choose a group chat name'}
							type="text"
							value={groupName}
							onChange={(e) => {
								setGroupName(e.target.value);
								setChatName(e.target.value);
							}}
						/>
					</div>
					<Virtuoso<TUserProfileRecord>
						className={styles.chatMembersResultList}
						data={selectedUsers}
						itemContent={renderUserItem}
						totalCount={selectedUsers.length}
					/>
				</div>
			</FullScreenPageLayout>
		</Page>
	);
};
export type TCommunityFilesPageParams = {
	selectedUserIds: TUserProfileRecord['slug'][];
};
export default CreateGroupChatPage;
