import { useDropzone, useNotification, useTranslation } from '@hooks';
import { COMMUNITY_DOCUMENTS } from '@constants';

/** Opens native file picker screen to choose one file from a user computer. */
export const usePickUpFile = (uploadFile: (file: File) => void) => {
	const { t } = useTranslation();
	const { showError } = useNotification();

	const { open, getInputProps } = useDropzone({
		multiple: false,
		onDropRejected: () => showError({ title: t('Rejected'), subtitle: t('Not allowed file type') }),
		onDrop: (acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles[0]) {
				uploadFile(acceptedFiles[0]);
			}
		},
		accept: acceptedFileTypes,
	});

	return { openFilePicker: open, getInputProps };
};

const acceptedFileTypes = COMMUNITY_DOCUMENTS.availableMimeTypes.reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});
