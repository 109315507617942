import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMe, useMemo, useEffect, useNavigate, useParams, useLocation } from '@hooks';
import styles from './PostCreationPage.module.css';
import { ActivityIndicator, CloseIcon, Formik } from '@ui-kit';
import { transformCommunityId } from '@utils';
import { useMyLifestyleNetworksQuery } from '@ui-modules/networks/hooks/useMyLifestyleNetworksQuery';
import { MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE, MAX_ITEMS_FOR_UPLOAD, ROUTES } from '@constants';
import { usePostCreation } from '@ui-modules/feed/hooks/usePostCreation';
import { transformAttachments } from '@ui-modules/feed/utils/transformAttachments';
import type { TPostCreation } from '@schemas';
import { getPostEditingSchema, getPostCreationSchema } from '@schemas';
import { usePostEditing } from '@ui-modules/feed/hooks/usePostEditing';
import { usePostAttachmentsState } from '@ui-modules/feed/hooks/usePostAttachmentsState';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';
import PostCreationForm from '@ui-modules/feed/components/PostCreationForm/PostCreationForm';
import PostAttachments from '@ui-modules/feed/components/PostAttachments';
import PostFormInfoBar from '@ui-modules/feed/components/PostFormInfoBar';
import { useMyInvestNetworksQuery } from '@ui-modules/networks/hooks/useMyInvestNetworksQuery';
import { sortBy, xor } from 'lodash';
import {
	checkInitialAttachmentsChanged,
	checkInitialAttachmentsItemsExceed,
	customDisabled,
	customEditFormDisabled,
} from './utils';
import type { TCommunity } from '@typings';

const PostCreationPage = () => {
	const { t } = useTranslation();
	const { state = {} }: IPostCreationLocationState = useLocation();
	const { networkId, definition: communityDefinition } = state || {};
	const activityId = useParams().activityId as IPostCreationPageParams['activityId'];
	const navigate = useNavigate();
	const { user } = useMe();
	const { data: activity, isLoading, refetch: refetchActivity, isRefetching } = useFeedActivityQuery(activityId);
	const communityId = activity?.collectionCommunityReference?.data?.id || activity?.community?.id; // migration to use "collectionCommunityReference" (T21C-2485)
	const communitySlug = activity?.collectionCommunityReference?.data?.slug || activity?.community?.slug || 'LEARNING';
	const ifEditingAndLoading = activityId && (isLoading || isRefetching);

	const isOnEditMode = !!activity?.id;
	const isLearnPost = useMemo(() => {
		const communityName = activity?.collectionCommunityReference?.data?.name || activity?.community?.name; // migration to use "collectionCommunityReference" (T21C-2485)
		return activity?.id && activity?.origin !== 'user:GLOBAL' && !communityName;
	}, [activity]);

	const { onCommunityPostAdd } = usePostCreation();
	const { onCommunityEditPostAction } = usePostEditing(communitySlug);
	const {
		attachments,
		filesSize,
		removeAttachment,
		uploadAttachments,
		setInitialAttachments,
		getInputProps,
		onAddAttachmentsSuccess,
	} = usePostAttachmentsState({
		onlyMediaAccepted: false,
	});

	const filesExceedingSize = filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE;
	const itemsExceedingCount = attachments.length > MAX_ITEMS_FOR_UPLOAD;

	// Check if initial attachments count > MAX_ITEMS_FOR_UPLOAD then don't show error and enable editing.
	// Like for cases when staff upload 10 files and 1 api video. T21C-5535 [@julia]
	const isInitialItemsExceedingCount = useMemo(() => {
		return checkInitialAttachmentsItemsExceed(activity?.attachments, attachments, isOnEditMode);
	}, [activity?.attachments, attachments, isOnEditMode]);

	const isAttachmentsChanged = useMemo(() => {
		return checkInitialAttachmentsChanged(activity?.attachments, attachments);
	}, [activity?.attachments, attachments]);

	const { data: networks = [] } = useMyLifestyleNetworksQuery(undefined);
	const { data: investNetworks = [] } = useMyInvestNetworksQuery();
	const networkOptions = useMemo(() => {
		const networkOptions = sortBy([...networks, ...investNetworks], ['name']).map((network) => ({
			value: network['@id'],
			title: network.name,
			label: network.name,
		}));
		return [{ title: `– ${t('select a Network')} –`, value: '' }, ...networkOptions];
	}, [networks, investNetworks]);

	const getCommunityName = (networkId: string) => {
		return [...networks, ...investNetworks].find((network) => network['@id'] === networkId)?.name || '';
	};

	// Form initial values
	const initialValues = useMemo(() => {
		const communityId = activity?.collectionCommunityReference?.data?.id || activity?.community?.id; // migration to use "collectionCommunityReference" (T21C-2485)
		return {
			title: activity?.subject || '',
			mind: activity?.body.trim() || '',
			network: communityId || networkId || '',
		};
	}, [activity, networkId]);

	const submit = async (values: TPostCreation) => {
		const data = {
			networkSlug: values.network,
			body: values.mind || ' ',
			subject: values.title,
			owner: user['@id'],
		};

		if (isOnEditMode) {
			await onCommunityEditPostAction({
				...data,
				id: activity?.foreign_id,
				postType: activity?.settings?.postType,
				...transformAttachments(attachments),
			});
		} else {
			const network = [...networks, ...investNetworks].find((community) => community['@id'] === values.network);
			await onCommunityPostAdd({ ...data, ...transformAttachments(attachments), network });
		}
	};

	const onSuccessSubmit = (network: string) => {
		if (!isOnEditMode && networkId) {
			communityDefinition === 'chapter'
				? navigate(ROUTES.chapterTab(transformCommunityId(network), 'discussions'), { replace: true })
				: navigate(ROUTES.networkTab(transformCommunityId(network), 'discussions'), { replace: true });
		} else {
			navigate(-1);
		}
	};

	useEffect(() => {
		if (isOnEditMode) {
			refetchActivity();
		}
	}, []);

	useEffect(() => {
		if (isOnEditMode) {
			setInitialAttachments({
				images: activity?.attachments?.images,
				files: activity?.attachments?.files,
				videos: activity?.attachments?.videos,
			});
		}
	}, [activity]);

	const isCustomFormDirty = useMemo(() => {
		// Check if the custom form is dirty, considering form attachments.
		if (!isOnEditMode) {
			return !!attachments?.length;
		}

		const initialAttachments = [
			...(activity?.attachments?.images ?? []),
			...(activity?.attachments?.videos ?? []),
			...(activity?.attachments?.files ?? []),
		];

		// If the lengths differ, the form is dirty.
		if (initialAttachments.length !== attachments?.length) return true;

		// Check for differences in attachment URLs. If there are differences, the form is dirty.
		const attachmentsUrlsArray = attachments.map((attachment) => attachment.url);
		const arraysDifference = xor(initialAttachments, attachmentsUrlsArray);
		return !!arraysDifference.length;
	}, [isOnEditMode, attachments, activity]);
	return (
		<Page title={isOnEditMode ? t('Edit Post') : t('New Post')}>
			{ifEditingAndLoading ? (
				<ActivityIndicator />
			) : (
				<Formik
					enableReinitialize
					initialValues={initialValues as TPostCreation}
					validateOnBlur
					validateOnChange={false}
					validationSchema={isOnEditMode ? getPostEditingSchema(t, attachments) : getPostCreationSchema(t, attachments)}
					onSubmit={submit}
				>
					{(formProps) => {
						const { values, setFieldValue } = formProps;
						const postSourceName = isLearnPost
							? t('Learn')
							: values.network
							? getCommunityName(values.network)
							: undefined;

						return (
							<FormPageLayout
								allowedNextPaths={[ROUTES.editPost(), ROUTES.addPost()]}
								buttonBordered={false}
								customDisabled={
									isOnEditMode
										? customEditFormDisabled(
												filesExceedingSize,
												itemsExceedingCount,
												isInitialItemsExceedingCount,
												!!attachments?.length,
												isAttachmentsChanged,
												formProps,
										  )
										: customDisabled(
												filesExceedingSize,
												itemsExceedingCount,
												isInitialItemsExceedingCount,
												formProps.dirty,
										  )
								}
								customLeftIcon={<CloseIcon className={styles.post__iconClose} height={20} width={13} />}
								formProps={formProps}
								headerTitle={isOnEditMode ? t('Edit Post') : t('New Post')}
								ignoreDirty={isCustomFormDirty}
								saveButtonTitle={isOnEditMode ? t('Save') : t('Post')}
								onSuccessSubmit={() => onSuccessSubmit(values.network)}
							>
								<div className={styles.post}>
									{isOnEditMode ? (
										activity ? (
											<PostFormInfoBar
												avatarUrl={activity.actor.data.avatar}
												sourceName={postSourceName}
												time={activity?.time || ''}
												userName={activity.actor.data.name}
												userRoles={activity.actor.data.roles}
											/>
										) : (
											<ActivityIndicator type="fit" />
										)
									) : (
										<PostFormInfoBar
											avatarUrl={user.avatar?.contentUrl}
											sourceName={postSourceName}
											time={''}
											userName={`${user.firstName} ${user.lastName}`}
											userRoles={user.roles}
										/>
									)}
									<PostCreationForm
										formProps={formProps}
										isOnEditMode={isOnEditMode}
										networkId={communityId || networkId}
										networkOptions={networkOptions}
									/>
									<PostAttachments
										attachments={attachments}
										editable={true}
										errorMessage={formProps.errors.mind}
										fieldName="mind"
										filesSize={filesSize}
										isInitialItemsExceedingCount={isInitialItemsExceedingCount}
										removeAttachment={removeAttachment}
										setFieldValue={setFieldValue}
										uploadAttachments={uploadAttachments}
										value={values.mind!}
										onAddAttachmentsSuccess={onAddAttachmentsSuccess}
										onBlur={formProps.handleBlur('mind')}
									/>
									<input {...getInputProps()} /> {/* hidden input to make file upload working */}
								</div>
							</FormPageLayout>
						);
					}}
				</Formik>
			)}
		</Page>
	);
};

interface IPostCreationPageParams {
	activityId: string;
}

export interface IPostCreationLocationState {
	state: {
		networkId?: string;
		definition?: TCommunity['definition'];
	};
}

export default PostCreationPage;
