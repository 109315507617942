import { useService, usePaginatedInfiniteQuery, useTranslation, useRef, useMemo } from '@hooks';
import { useDealsSortSearchParams } from './useDealsSortSearchParams';
import { compact, dayjs, getDealSortOptions, populateDealsPaginatedListWithReactions } from '@utils';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import type { TDealWithReactions, TPaginatedList } from '@tiger21-llc/connect-shared/src/typings';
import type { AxiosError } from 'axios';
import useAllDealFilters from './useAllDealFiltersQuery';

export const useAllDealsQuery = (excludeNotInterested = false) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const feed = useService('FeedService');
	const { isMessageShown, isLoading: isWaiverLoading } = useSolicitationWaiver();
	const {
		data: allDealFilters,
		isLoading: isAllDealFilterLoading,
		isFetching: isAllDealFilterFetching,
		dataUpdatedAt: filterUpdatedAt,
		refetch: refetchAllDealFilters,
	} = useAllDealFilters();

	const [selectedSortOption, setSelectedSortOption] = useDealsSortSearchParams({
		field: 'createdAt',
		order: 'desc',
	});
	const sortOptions = useRef(getDealSortOptions('all')(t)).current;
	const allDealsFilterId = allDealFilters?.[0]?.id;
	const filterIdWithUpdatedDate = String(allDealsFilterId) + filterUpdatedAt;

	const query = usePaginatedInfiniteQuery<TDealWithReactions, TPaginatedList<TDealWithReactions>, AxiosError>(
		reactQuery.queryKeys.getAllDeals(filterIdWithUpdatedDate, selectedSortOption),
		async ({ pageParam }) => {
			const deals = await api.invest.getAllDeals({
				page: pageParam,
				dealFilterId: allDealsFilterId,
				excludeNotInterested,
				sort: selectedSortOption,
			});
			const dealsActivityIds = compact(deals.data.map((deal) => deal.streamActivityId)); // This is a fix to avoid having torn arrays. On the backend, there is a floating bug with the creation of an activity in the stream after creating a deal. Fixes T21C-5533
			const dealActivities = await feed.getActivities(dealsActivityIds);
			const dealsWithReactions = populateDealsPaginatedListWithReactions(deals, dealActivities);
			return dealsWithReactions;
		},
		{
			enabled: !isWaiverLoading && !isMessageShown && !!allDealsFilterId,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
			staleTime: dayjs.duration(1, 'minute').asMilliseconds(),
		},
	);

	const hasDataByFilter = useMemo(
		() => reactQuery.hasAnyDataInInfiniteQueryCache(reactQuery.queryKeys.getAllDeals(filterIdWithUpdatedDate)),
		[filterIdWithUpdatedDate, query.isFetching],
	);

	return {
		sortOptions,
		allDealsFilterId,
		allDealFilters,
		isAllDealFilterLoading,
		isAllDealFilterFetching,
		selectedSortOption,
		filterIdWithUpdatedDate,
		refetchAllDealFilters,
		setSelectedSortOption,
		hasDataByFilter,
		...query,
	};
};
