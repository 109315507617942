import { useCalendar, usePress } from 'react-aria';
import { useCalendarState } from 'react-stately';
import type { CalendarStateOptions } from 'react-stately';
import { createCalendar, parseDate } from '@internationalized/date';
import { CalendarGrid } from './CalendarGrid';
import styles from './Calendar.module.css';
import { clsx } from '@utils';
import { ChevronRightIcon } from '@ui-kit';
import type { TCellPropsMapper } from './Calendar.types';
import { useEffect } from 'react';

const Calendar = (props: ICalendarProps) => {
	const calendarState = props.state || {};
	const state = useCalendarState({
		...calendarState,
		locale: 'en-US',
		createCalendar,
		value: parseDate(props.value),
		onChange: (value) => props.onChange(value.toString()),
	});
	const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(calendarState, state);

	const { pressProps: prevPressProps } = usePress({
		onPress: (e) => prevButtonProps?.onPress && prevButtonProps?.onPress(e),
	});

	const { pressProps: nextPressProps } = usePress({
		onPress: (e) => nextButtonProps?.onPress && nextButtonProps?.onPress(e),
	});

	useEffect(() => {
		if (props.onRangeChanged) {
			props.onRangeChanged(state.visibleRange.start.toString(), state.visibleRange.end.toString());
		}
	}, [state.visibleRange, props]);

	return (
		<div {...calendarProps} className={styles.calendar__wrapper}>
			<div className={styles.calendar__header}>
				<span className={styles.calendar__navBtn} role="button" tabIndex={0} {...prevPressProps}>
					<ChevronRightIcon
						className={clsx(styles.calendar__iconChevron, styles.calendar__iconChevronLeft)}
						width={16}
					/>
				</span>
				<p className={styles.calendar__title}>{title}</p>
				<span className={styles.calendar__navBtn} role="button" tabIndex={0} {...nextPressProps}>
					<ChevronRightIcon className={styles.calendar__iconChevron} width={16} />
				</span>
			</div>
			<CalendarGrid daysLabels={props.daysLabels} getCellProps={props.getCellProps} state={state} title={title} />
		</div>
	);
};

export interface ICalendarProps {
	state?: Omit<CalendarStateOptions, 'onChange' | 'value' | 'defaultValue'>;
	getCellProps?: TCellPropsMapper;
	onChange: (value: string) => void;
	value: string;
	onRangeChanged?: (startDate: string, endDate: string) => void;
	daysLabels: string[];
}

export default Calendar;
