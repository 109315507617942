import { useService, useTranslation } from '@hooks';
import { Button, Card, CollapsibleText, Gap } from '@ui-kit';
import styles from './ProfileBiography.module.css';

const ProfileBiography = ({
	isInvisible,
	isHidden,
	biography,
	canEdit,
	title,
	onEdit,
	onContactPress,
}: IProfileBiographyProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const sectionName = title || t('About me') || '';

	return isInvisible ? null : (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			title={sectionName}
		>
			{isHidden ? (
				<span>{t('This section is currently hidden from others')}</span>
			) : biography ? (
				<div>
					<div className={styles.biography__content}>
						<CollapsibleText
							collapsedNumberOfLines={3}
							collapsedTitle={t('Read more')}
							expandedTitle={t('Show less')}
							onExpand={() => {
								analytics.trackEvent('ProfileInteractions', {
									interaction_type: 'Read More clicked',
									action_value: 'Biography',
								});
							}}
						>
							{biography}
						</CollapsibleText>
					</div>
				</div>
			) : (
				<span>
					{canEdit
						? t('Let others know a little bit about you by completing your {{sectionName}} section.', {
								sectionName: sectionName.toLowerCase(),
						  })
						: t('Start a conversation to learn more about me.')}
				</span>
			)}
			{onContactPress ? (
				<>
					<Gap gap={16} />
					<Button block="fit" title={t('Contact')} onClick={onContactPress} />
				</>
			) : null}
		</Card>
	);
};

export interface IProfileBiographyProps {
	isInvisible?: boolean;
	isHidden: boolean;
	biography: string;
	canEdit: boolean;
	title?: string;
	onEdit: () => void;
	onContactPress?: undefined | (() => void);
}

export default ProfileBiography;
