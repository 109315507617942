import { useMemo } from '@hooks';
import { FileEntity } from '@utils';
import type { TFile, TFolder } from '@typings';

/** Applies filtering by searchQuery for folders and files to return only items matching search. */
export const useFilterFilesAndFolders = (searchQuery: string, files: TFile[] = [], folders: TFolder[] = []) => {
	const preparedSearchQuery = searchQuery.trim().toLowerCase();

	const filteredFiles = useMemo(() => {
		if (!files || !preparedSearchQuery.length) return files;
		else return files.filter((file) => FileEntity.getFileNameV2(file).toLowerCase().includes(preparedSearchQuery));
	}, [files, preparedSearchQuery]);

	const filteredFolders = useMemo(() => {
		if (!folders || !preparedSearchQuery.length) return folders;
		else return folders.filter((folder) => folder.name.toLowerCase().includes(preparedSearchQuery));
	}, [folders, preparedSearchQuery]);

	return { filteredFiles, filteredFolders };
};
