import { Button, MeatballMenu, NotificationIcon, NotificationOffIcon, SpacedGroup, When, MembersIcon } from '@ui-kit';
import LeaveChatModal from '../LeaveChatModal';
import { useChat, useNavigate, useService, useToggle, useTranslation, useState } from '@hooks';
import { useMutateChatMeatballMenuOptions } from '@ui-modules/chat/hooks/useMutateChatMeatballMenuOptions';
import { ROUTES } from '@constants';
import styles from './ChatChannelHeader.module.css';
import type { Channel } from 'stream-chat';
import ChatParentButton from '../ChatParentButton';
import { useToggleChatAlerts } from '@ui-modules/chat/hooks/useToggleChatAlerts';

const ChatChannelHeader = ({ channel, channelName, isLoading, isChannelMemberAdmin }: IChatChannelHeaderProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const chatService = useService('ChatService');
	const { channelHasPauseMembers } = useChat();
	const analytics = useService('AnalyticsService');

	const [alerts, setAlerts] = useState(() => channel.muteStatus().muted);
	const [isLeaveChatDialogVisible, toggleLeaveChatDialog] = useToggle(false);
	const [meatballMenuOptions, MutateChatConfirmationModals] = useMutateChatMeatballMenuOptions(
		channelHasPauseMembers,
		isChannelMemberAdmin,
	);
	const showMeatballMenu = meatballMenuOptions && !chatService.isOneToOne(channel);
	const showMembers = chatService.isGroupChat(channel) && !channel?.data?.community && !channel?.data?.deal;
	channel?.data?.deal;

	const handleChatLeft = () => {
		navigate(ROUTES.chat());
		toggleLeaveChatDialog(false);
	};
	const viewChannelMembers = () => {
		navigate(ROUTES.chatViewMembers(channel.id));
	};
	const { mutate: toggleMuteChat, isLoading: isAlertsLoading } = useToggleChatAlerts(channel, {
		onSuccess: (_, mute) => setAlerts(!mute),
	});

	if (isLoading) return <div className={styles.header__plug} />;
	return (
		<>
			<div className={styles.chatChannelHeader}>
				<div className={styles.chatChannelHeader__actionBar_left} />
				<div className={styles.chatChannelHeader__title}>{t('{{channelName}} chat', { channelName })}</div>

				<SpacedGroup className={styles.chatChannelHeader__actionBar_right} gap={8}>
					<Button
						icon={
							!alerts ? (
								<NotificationOffIcon fill={'black'} height={12} width={12} />
							) : (
								<NotificationIcon fill={'black'} height={12} width={12} />
							)
						}
						iconPosition="left"
						loading={isAlertsLoading}
						title={alerts ? t('Unmute') : t('Mute')}
						variant="small"
						onClick={() => {
							toggleMuteChat(alerts);
							analytics.trackEvent('ChatInteractions', {
								page_name: String(channelName),
								action_taken: alerts ? 'alerts on' : 'alerts off',
							});
						}}
					/>

					<ChatParentButton channel={channel} />

					<When condition={showMembers}>
						<Button
							icon={<MembersIcon color="black" height={16} width={18} />}
							iconPosition="left"
							title={t('Members')}
							variant="small"
							onClick={viewChannelMembers}
						/>
					</When>

					<When condition={chatService.canLeaveChat(channel)}>
						<Button title={t('Leave')} variant="small" onClick={() => toggleLeaveChatDialog()} />
					</When>

					{showMeatballMenu && meatballMenuOptions.length ? (
						<MeatballMenu options={meatballMenuOptions} position="bottom-left" testID="Post__MeatballMenu" />
					) : null}
				</SpacedGroup>
			</div>

			<LeaveChatModal
				channel={channel}
				visible={isLeaveChatDialogVisible}
				onClose={toggleLeaveChatDialog}
				onSuccess={handleChatLeft}
			/>
			{MutateChatConfirmationModals}
		</>
	);
};

export interface IChatChannelHeaderProps {
	channel: Channel;
	channelName: string | undefined;
	isLoading: boolean;
	isChannelMemberAdmin: boolean;
}

export default ChatChannelHeader;
