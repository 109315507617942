import { useTranslation } from '@hooks';
import { DateSeparator } from 'stream-chat-react';
import { formatLastMessagePreviewDate } from '@ui-modules/chat/utils/formatLastMessagePreviewDate';

const CustomDateSeparator = ({ date }: IDateSeparator) => {
	const { t } = useTranslation();

	function formatDate(d: Date) {
		return `${formatLastMessagePreviewDate(t)(d.toDateString())}`;
	}

	return <DateSeparator date={date} formatDate={formatDate} position={'center'} />;
};

export default CustomDateSeparator;

export interface IDateSeparator {
	date: Date;
}
