import { ActivityIndicator } from '@ui-kit';
import ReviewSolicitationWaiverMessage from '@ui-modules/deals/components/ReviewSolicitationWaiverMessage';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import styles from './ReviewSolicitationWaiverWrapper.module.css';
import type { PropsWithChildren } from 'react';

const ReviewSolicitationWaiverWrapper = ({
	children,
	loading,
	activityIndicatorColor = 'black',
}: IReviewSolicitationWaiverWrapperProps) => {
	const { isMessageShown, isLoading: isWaiverLoading, onSubmit } = useSolicitationWaiver();
	const showMessage = isMessageShown && !isWaiverLoading;
	const showActivityIndicator = isWaiverLoading;

	if (showActivityIndicator || loading) {
		return <ActivityIndicator color={activityIndicatorColor} size="medium" type="fit" />;
	}
	if (showMessage) {
		return (
			<div className={styles.reviewSolicitationWaiverWrapper}>
				<ReviewSolicitationWaiverMessage onSubmit={onSubmit} />
			</div>
		);
	}

	return <>{children}</>;
};

export interface IReviewSolicitationWaiverWrapperProps extends PropsWithChildren {
	loading?: boolean;
	activityIndicatorColor?: string;
}

export default ReviewSolicitationWaiverWrapper;
