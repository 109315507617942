import { useService, useMutation } from '@hooks';
import type { Channel } from 'stream-chat';

export const useUnbanChatMemberMutation = () => {
	const chat = useService('ChatService');

	return useMutation<IChannelMembers[], Error, Channel>(
		['chat.queryPauseMembers'],
		async (channel) => await chat.queryPauseMembers(channel),
		{
			cacheTime: 0,
		},
	);
};

type IChannelMembers = {
	id?: string;
	role?: string;
	banned?: boolean;
};
