import { MARKETS_TOP_PRIORITY } from '@constants';
import { useService } from '@services';
import { useQuery } from '@tanstack/react-query';
import type { TMarket } from '@typings';

const filterMarkets = (markets: TMarket[]): TMarket[] => {
	return markets.filter((market) => !MARKETS_TOP_PRIORITY.includes(market.title.trim().toLowerCase()));
};

export const useMarkets = () => {
	const api = useService('ApiService');
	return useQuery<TMarket[], Error>(['markets.getMarkets'], async () => await api.market.getMarkets(), {
		select: (markets) => filterMarkets(markets),
	});
};
