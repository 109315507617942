import { EmailIcon, PhoneIcon } from '@ui-kit/icons';
import { clsx } from '@utils';
import styles from './ContactBlock.module.css';

const ContactBlock = ({
	title,
	contactName,
	contactEmail,
	contactPhone,
	contactNameHighlighted,
	contactEmailHighlighted,
	contactPhoneHighlighted,
	onEmailOpened,
onPhoneOpened,
}: IContactBlockProps) => {
	return (
		<div className={styles.contactBlock__infoWrapper}>
			<p
				className={clsx(
					styles.contactBlock__detailsContactName,
					contactNameHighlighted && styles.contactText_highlighted,
				)}
			>{`${title}: ${contactName}`}</p>
			<div
				className={clsx(
					styles.contactBlock__contentInfoWrapper,
					contactEmailHighlighted && styles.contactText_highlighted,
				)}
			>
				<EmailIcon className={styles.contactBlock__icon} height={14} width={14} />
				<a href={`mailto:${contactEmail}`} rel="noreferrer" target="_blank" onClick={onEmailOpened}>
					{contactEmail}
				</a>
			</div>
			<div
				className={clsx(
					styles.contactBlock__contentInfoWrapper,
					contactPhoneHighlighted && styles.contactText_highlighted,
				)}
			>
				<PhoneIcon className={styles.contactBlock__icon} height={14} width={14} />
				<a href={`tel:${contactPhone}`} rel="noreferrer" onClick={onPhoneOpened}>
					{contactPhone}
				</a>
			</div>
		</div>
	);
};

export interface IContactBlockProps {
	title: string;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	contactNameHighlighted?: boolean;
	contactEmailHighlighted?: boolean;
	contactPhoneHighlighted?: boolean;
	onEmailOpened?: () => void
onPhoneOpened?: () => void
}
export default ContactBlock;
