import type { IDealStatus, TDealMemberRelationsStatus } from '@typings';

export const dealRibbonColor = (status: TDealMemberRelationsStatus | IDealStatus) => {
	switch (status) {
		case 'invested':
			return '#BC9D58';
		case 'interested':
			return '#00C193';
		case 'not interested':
			return '#858585';
		case 'following':
			return '#7D90D1';
		case 'draft':
		case 'review':
			return '#FA2F53';
		default:
			return undefined;
	}
};
