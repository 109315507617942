import type { IPostBarAlertsButtonProps } from '@ui-kit';
import { BookmarkToggleButton } from '@ui-kit';
import LikesButton from '../../LikesButton';
import CommentsButton from '../../CommentsButton';
import ReplyButton from '../../ReplyButton';
import styles from './PostActionBar.module.css';
import type { ILikesButtonProps } from '../../LikesButton';
import type { ICommentsButtonProps } from '../../CommentsButton';
import type { IReplyButtonProps } from '../../ReplyButton';
import type { IBookmarkToggleButtonProps } from '@ui-kit/components/BookmarkToggleButton';
import PostBarAlertsButton from '../PostBarAlertsButton';
import type { ReactNode } from 'react';

const PostActionBar = ({
	hasMyLike,
	likes,
	likeTitle,
	comments,
	replyTitle,
	likingDisabled = false,
	showBookmark = false,
	bookmarked,
	onBookmark,
	onLike,
	onClickComment,
	onClickReply,
	alerts,
	alertsTitle,
	onClickAlerts,
	CustomButtonElement,
}: IPostActionBarProps) => {
	return (
		<div className={styles.postActionBar}>
			<div className={styles.postActionBar__bar}>
				<LikesButton
					disabled={likingDisabled}
					hasMyLike={hasMyLike}
					likes={likes}
					likeTitle={likeTitle}
					size="medium"
					onLike={onLike}
				/>
				<CommentsButton comments={comments} onClickComment={onClickComment} />
				{/* Temporary hidden following T21C-5342 */}
				{/* {showBookmark && onBookmark ? (
					<BookmarkToggleButton bookmarked={Boolean(bookmarked)} onBookmark={onBookmark} />
				) : null} */}
			</div>
			<div className={styles.postActionBar__bar}>
				{CustomButtonElement}
				{!!alertsTitle && (
					<PostBarAlertsButton alerts={alerts} alertsTitle={alertsTitle} onClickAlerts={onClickAlerts} />
				)}
				<ReplyButton replyTitle={replyTitle} size="medium" onClickReply={onClickReply} />
			</div>
		</div>
	);
};

export interface IPostActionBarProps
	extends Omit<ILikesButtonProps, 'size'>,
		Omit<IReplyButtonProps, 'size'>,
		Partial<IBookmarkToggleButtonProps>,
		Partial<IPostBarAlertsButtonProps>,
		ICommentsButtonProps {
	likingDisabled?: boolean;
	showBookmark?: boolean;
	CustomButtonElement?: ReactNode;
}

export default PostActionBar;
