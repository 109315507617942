import { Gap, Button, Toast } from '@ui-kit';
import ResetPasswordForm from '@ui-modules/auth/components/ResetPasswordForm';
import { Page } from '../../components';
import { useTranslation, useNavigate, useService } from '@hooks';
import { useOktaResetPasswordMutation } from '@ui-modules/auth/hooks/useOktaResetPasswordMutation';
import { noop } from '@utils';
import { ROUTES } from '@constants';
import styles from './ResetPasswordPage.module.css';

const ResetPasswordPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const okta = useService('OktaService');

	const { mutateAsync: resetPassword, submitError } = useOktaResetPasswordMutation({
		onVerifyRequired: () => navigate(ROUTES.verifySignIn('reset-password')),
		onSuccess: () => navigate(ROUTES.signIn(), { replace: true }),
	});
	const goBack = () => {
		okta.cancelTransaction().finally(() => navigate(ROUTES.signIn(), { replace: true }));
	};

	return (
		<Page title={t('Reset password')}>
			<div className={styles.resetPasswordPage}>
				<header className={styles.resetPasswordPage__header}>
					<h4>{t('Reset password')}</h4>
				</header>
				<Gap gap={16} />
				{submitError ? (
					<Toast subtitle={submitError?.subtitle} title={submitError?.title} type="error" onClose={noop} />
				) : null}
				<Gap gap={16} />
				<section>
					<ResetPasswordForm onSubmit={resetPassword} />
					<Gap gap={32} />
					<Button block="fit" title={t('Back to Sign in')} type="solid" variant="medium" onClick={goBack} />
					<Gap gap={32} />
				</section>
			</div>
		</Page>
	);
};

export interface IResetPasswordPageParams extends Record<string, string | undefined> {
	id: string;
}

export default ResetPasswordPage;
