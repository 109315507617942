import { useEffect, useMemo, useTranslation } from '@hooks';
import { removeEmptySectionListItems, toString } from '@utils';
import type { TCommunity } from '@typings';
import { useNetworkLifestyle } from './useNetworkLifestyle';
import { useInvestNetworks } from './useInvestNetworks';
import { INVEST_NETWORKS_TAB_SLUG } from '@constants';

const useNetworksHook = (type: 'invest' | 'lifestyle') => {
	switch (type) {
		case 'invest':
			return useInvestNetworks;
		case 'lifestyle':
			return useNetworkLifestyle;
	}
};

/** Performs API requests to retrieve all networks and compose to render via SectionList. */
export const useNetworkQueriesBreakdown = (
	networkTab: string,
	searchQuery: string,
	onFirstNetworkLoaded?: (firstLoadedNetwork: TCommunity | undefined) => void,
) => {
	const { t } = useTranslation();
	const useNetworkHook = useNetworksHook(networkTab === INVEST_NETWORKS_TAB_SLUG ? 'invest' : 'lifestyle');
	const { myNetworks, foreignNetworks, isLoading, isSuccess, refetch } = useNetworkHook(networkTab);
	const isAnyNetworkLoaded = !!myNetworks.length || !!foreignNetworks.length;

	/** Build a composition of all networks data to pass to SectionList.item property. */
	const sectionListItems = useMemo(() => {
		if (!isSuccess) return [];
		return removeEmptySectionListItems([
			{ title: t('My networks'), data: filterNetwork(myNetworks, searchQuery) },
			{
				title: networkTab === INVEST_NETWORKS_TAB_SLUG ? t('All Investment Networks') : t('All networks'),
				data: filterNetwork(foreignNetworks, searchQuery),
			},
		]);
	}, [searchQuery, myNetworks, foreignNetworks, t, isSuccess]);

	useEffect(() => {
		if (isSuccess && onFirstNetworkLoaded) {
			const firstLoadedNetwork = sectionListItems?.[0]?.data?.[0];
			onFirstNetworkLoaded?.(firstLoadedNetwork);
		}
	}, [isSuccess, sectionListItems]);

	return {
		myNetworks,
		foreignNetworks,
		sectionListItems,
		isLoading,
		refetch,
		isAnyNetworkLoaded,
	};
};

/** To filter networks with passed search query. */
function filterNetwork(networks: TCommunity[], searchQuery = '') {
	if (!searchQuery.length) return networks;
	return networks.filter(
		(network) =>
			network.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			toString(network.description).toLowerCase().includes(searchQuery.toLowerCase()),
	);
}
