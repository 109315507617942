import { useTranslation } from '@hooks';
import { ActivityIndicator, EmptyStateMessage, Gap, When } from '@ui-kit';
import styles from './NetworkDealFooter.module.css';
import type { TDeal } from '@typings';

const NetworkDealFooter = ({
	isFetching,
	isSearchFetching,
	searchResult,
	isSearchFetched,
	isLoading,
	isRefetching,
}: INetworkDealFooter) => {
	const { t } = useTranslation();

	const loading = isLoading || isFetching || isSearchFetching || isRefetching;
	return (
		<>
			<ActivityIndicator
				className={styles.networkDealFooter__activityIndicator}
				color="white"
				invisible={!loading} // not hide but make invisible to prevent calling Virtuoso.onScroll when list is fetching which causes issues with floating header (@see T21C-6860) [@DmitriyNikolenko]
				size="medium"
				type="fit"
			/>
			<When condition={!searchResult.length && isSearchFetched}>
				<EmptyStateMessage colorMode="white" hidden={false} text={t('Sorry, no deals were found.')} />
			</When>
			<Gap gap={8} />
		</>
	);
};

export interface INetworkDealFooter {
	isLoading: boolean;
	isRefetching: boolean;
	isFetching: boolean;
	searchResult: TDeal[];
	isSearchFetched: boolean;
	isSearchFetching: boolean;
}
export default NetworkDealFooter;
