import { useEffect } from 'react';
import { useService } from '@hooks';
import type { TAlgoliaAnalyticsEvents } from '@typings';

/** Wraps useEffect which sends described event to Algolia Insights analytics when all dependencies are truthy. */
export const useTrackAlgoliaEventEffect = <TEventName extends keyof TAlgoliaAnalyticsEvents>(
	eventName: TEventName,
	props: TAlgoliaAnalyticsEvents[TEventName],
	dependencies: unknown[],
) => {
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');

	useEffect(() => {
		const isAllDependenciesTruthy = dependencies.every((dep) => !!dep);
		if (isAllDependenciesTruthy) {
			algoliaAnalytics.trackEvent(eventName, props);
		}
	}, dependencies);
};
