import { clsx } from '@utils';
import InputLabel from '../InputLabel';
import styles from './SegmentedControl.module.css';

/** Two horizontal iOS-style switch buttons. */
const SegmentedControl = ({
	size = 'medium',
	label,
	fit = false,
	isLeftOptionSelected,
	leftOptionTitle,
	rightOptionTitle,
	onLeftOptionClick,
	onRightOptionClick,
	disabled,
}: ISegmentedControlProps) => {
	const isLeftSelected = isLeftOptionSelected === true;
	const isRightSelected = isLeftOptionSelected === false;

	return (
		<>
			{label ? <InputLabel text={label} /> : null}
			<div
				className={styles.segmentedControl}
				data-fit={String(fit)}
				data-size={size}
				onClick={(event) => event.stopPropagation()}
				onKeyPress={(event) => event.stopPropagation()}
			>
				<label
					className={clsx(
						styles.segmentedControl__label,
						isLeftSelected && styles.segmentedControl__label_selected,
						disabled && styles.segmentedControl__disabled,
					)}
				>
					{leftOptionTitle}
					<input
						aria-label={leftOptionTitle}
						checked={isLeftOptionSelected === true}
						className={styles.segmentedControl__input}
						disabled={disabled || isLeftOptionSelected === true}
						type="radio"
						onChange={() => onLeftOptionClick()}
						onKeyPress={() => onLeftOptionClick()}
					/>
				</label>
				<div className={styles.segmentedControl__separator}></div>
				<label
					className={clsx(
						styles.segmentedControl__label,
						isRightSelected && styles.segmentedControl__label_selected,
						disabled && styles.segmentedControl__disabled,
					)}
				>
					{rightOptionTitle}
					<input
						aria-label={rightOptionTitle}
						checked={isLeftOptionSelected === false}
						className={styles.segmentedControl__input}
						disabled={disabled || isLeftOptionSelected === false}
						type="radio"
						onChange={() => onRightOptionClick()}
						onKeyPress={() => onRightOptionClick()}
					/>
				</label>
			</div>
		</>
	);
};

export type TISegmentedControlSize = 'small' | 'medium' | 'large';
export interface ISegmentedControlProps {
	/** If "true" - left button selected, if "false" - the right button selected, if "nil" - no button selected. */
	isLeftOptionSelected: boolean | undefined;
	/** A title of the left button. */
	leftOptionTitle: string;
	/** A title of the right button. */
	rightOptionTitle: string;
	/** Can be 'large', 'small' or  'medium' (by default). When 'large' the height is 42px, when 'medium' - 32px, , when 'small' - 26px */
	size?: TISegmentedControlSize;
	/** A label of the whole control (optional). */
	label?: string;
	/** If "true" - the control will fit the width of the parent container. */
	fit?: boolean;
	/** Triggers when the left button was non-selected and clicked. */
	onLeftOptionClick: () => void;
	/** Triggers when the right button was non-selected and clicked. */
	onRightOptionClick: () => void;
	/** Disable full interaction with segment control */
	disabled?: boolean;
}

export default SegmentedControl;
