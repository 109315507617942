import { Gap } from '@ui-kit';
import type { ReactNode } from 'react';
import { clsx } from '@utils';

import styles from './sections.module.css';

export const DealFormSection = ({ children, bordered = true, title, className }: IDealFormSectionProps) => {
	return (
		<div className={clsx(styles.section, bordered && styles.borderBottom, className)}>
			{title && (
				<>
					<h3>{title}</h3>
					<Gap gap={24} />
				</>
			)}
			{children}
		</div>
	);
};

interface IDealFormSectionProps {
	title?: string;
	children: ReactNode;
	bordered?: boolean;
	className?: string;
}
