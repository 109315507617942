import { useTranslation } from '@hooks';

import styles from './NetworkConnectionsZeroState.module.css';

const NetworkConnectionsZeroState = ({ networkConnectionsName }: INetworkConnectionsZeroStateProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.networkConnectionTab__zeroWrapper}>
			<div className={styles.networkConnectionTab__container}>
				<span className={styles.networkConnectionTab__zeroTitle}>
					{t('No {{networkConnectionsName}} matching your Filter', { networkConnectionsName })}
				</span>
				<span className={styles.networkConnectionTab__subTitle}>
					{t('...or no {{networkConnectionsName}} exists. Try filtering again.', { networkConnectionsName })}
				</span>
			</div>
		</div>
	);
};

export interface INetworkConnectionsZeroStateProps {
	networkConnectionsName: string;
}
export default NetworkConnectionsZeroState;
