import { useService, useMutation, useNotification, useMe, useTranslation } from '@hooks';
import type { TUser } from '@typings';

export const useImpersonate = () => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { showUnknownError, showSuccess } = useNotification();
	const api = useService('ApiService');
	const redux = useService('ReduxService');
	const { setImpersonateToken, removeImpersonateToken } = redux.useBindActionCreators(redux.me);

	const hasImpersonation = redux.useAppSelector((state) => !!state.me.impersonateToken);
	const impersonated = hasImpersonation ? `${user?.firstName} ${user?.lastName}` : undefined;

	const canImpersonate = (targetUser: TUser) =>
		!hasImpersonation &&
		user.roles.includes('ROLE_ADMIN') &&
		targetUser.id !== user.id &&
		!targetUser.roles.includes('ROLE_FORMER') &&
		!targetUser.roles.includes('ROLE_INACTIVE');
	const { mutate: impersonate } = useMutation<string, Error, TUser['id']>(
		['impersonate'],
		async (userId) => await api.auth.getImpersonateToken(userId),
		{
			onSuccess: async (impersonateToken) => {
				setImpersonateToken(impersonateToken);
				window.location.reload();
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	const { mutate: personateBack } = useMutation<void, Error, void>(
		['personateBack'],
		async () => await Promise.resolve(), // TODO here will be a logic to invalidate impersonate token.
		{
			onSuccess: async () => {
				showSuccess({ title: t('Ended "viewing as"') });
				await new Promise((resolve) => setTimeout(resolve, 1000)); // wait until notification shown, otherwise it will be shown above the top of the screen.
				removeImpersonateToken();
				window.location.reload();
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	return { canImpersonate, impersonated, impersonate, personateBack };
};
