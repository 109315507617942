import { CheckboxInput, RadioGroupInput, TextArea, Gap } from '@ui-kit';
import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { DealFormSection } from './DealFormSection';
import { useTranslation, useMe, useCallback } from '@hooks';
import { dealFormFields } from '../DealForm.schema';
import styles from './sections.module.css';
import { DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT } from '@constants';

export const RelationSection = ({ formProps, isEdit }: IDealFormSectionSectionProps) => {
	const { fullName, user } = useMe();
	const { t } = useTranslation();
	const { setFieldValue, setFieldError, values, errors } = formProps;

	const onRadioChange = useCallback(
		(value: string) => {
			setFieldValue(dealFormFields.CREATOR_TYPE, value);
			setFieldError(dealFormFields.CREATOR_TYPE, undefined);
			if (value === 'owner' || value === 'investor') {
				setFieldValue(dealFormFields.COMPENSATION_DISCLOSURE, true);
			}
		},
		[setFieldValue, setFieldError],
	);

	return (
		<DealFormSection>
			<RadioGroupInput
				backgroundColor="white"
				errorMessage={errors.creatorType}
				label={
					user['@id'] === values.creator?.iriId
						? t('Your ({{fullName}}) relation to this Deal', { fullName })
						: t("{{name}}'s relation to this Deal", { name: values.creator?.name || t('User') })
				}
				labelType="bold"
				options={[
					{ label: t('Owner'), value: 'owner', subLabel: t('Invested and the owner'), disabled: !!isEdit },
					{ label: t('Investor'), value: 'investor', subLabel: t('Invested, but not the owner'), disabled: !!isEdit },
					{
						label: t('Referrer'),
						value: 'referrer',
						subLabel: t('Not invested yet, not the owner. Looking for input or sharing a Deal.'),
						disabled: !!isEdit,
					},
				]}
				value={values.creatorType}
				onChange={onRadioChange}
			/>
			<span className={styles.checkboxInput_label}>{t('Compensation Disclosure')}</span>
			<CheckboxInput
				className={styles.checkboxInput_enabled}
				disabled={values.creatorType === 'owner'}
				label={t('I am receiving compensation for this Deal')}
				size="medium"
				value={!!values.compensationDisclosure}
				onChange={(isSelected) => {
					setFieldValue(dealFormFields.COMPENSATION_DISCLOSURE, isSelected);
				}}
			/>
			<Gap gap={20} />
			<TextArea
				errorMessage={
					values.priorExperience && values.priorExperience?.length > DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT
						? `${values.priorExperience?.length ?? 0}/${DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT}`
						: !values.priorExperience?.trim?.()?.length && errors.priorExperience
						? errors.priorExperience
						: ''
				}
				errorMessagePosition={
					(values.priorExperience?.length || 0) > DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT ? 'right' : 'left'
				}
				label={t('Prior experience / track record with similar investments (optional)')}
				minRows={5}
				rounded
				value={values.priorExperience}
				onChange={(e) => {
					setFieldValue(dealFormFields.PRIOR_EXPERIENCE, e.target.value);
					setFieldError(dealFormFields.PRIOR_EXPERIENCE, undefined);
				}}
			/>
			{(values.priorExperience?.length ?? 0) <= DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT && (
				<p className={styles.dealDescription__counter}>
					{`${values.priorExperience?.length ?? 0}/${DEAL_PRIOR_EXPERIENCE_CHARACTER_LIMIT}`}
				</p>
			)}
		</DealFormSection>
	);
};
