import { ActivityIndicator, Tabs } from '@ui-kit';
import NetworkConnectionPageHeader from '@ui-modules/connections/components/NetworkConnectionPageHeader';
import NetworkConnectionSubHeader from '@ui-modules/connections/components/NetworkConnectionSubHeader';
import { NetworkConnectionDetails } from '../NetworkConnectionDetails';
import NetworkConnectionMembersPage from '../NetworkConnectionMembersPage';
import { NetworkConnectionFilesPage } from '../NetworkConnectionFilesPage';
import { NetworkConnectionDiscussionsPage } from '../NetworkConnectionDiscussionsPage';
import { Page, TabWrapper, NotFound404 } from '../../components';
import { useTranslation, useParams, useNavigate, useRef } from '@hooks';
import { useNetworkConnectionTabs } from './useNetworkConnectionTabs';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useValidateDisplayingNetworkConnection } from '@ui-modules/connections/hooks/useValidateDisplayingNetworkConnection';
import { ROUTES } from '@constants';
import styles from './NetworkConnectionTabsPage.module.css';
import type { TNetworkConnectionTabName, TProfile } from '@typings';

const NetworkConnectionTabsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id, tab } = useParams<INetworkConnectionTabsPageParams>() as INetworkConnectionTabsPageParams;
	const discussionsWrapperRef = useRef<HTMLDivElement>(null);

	const { data: networkConnection, isLoading, isError } = useNetworkConnectionQuery(id);
	const { data: network } = useCommunityQuery(networkConnection?.community?.id);
	const { tabs, currentTabIndex, onChangeTab } = useNetworkConnectionTabs({ id, tab });
	const networkConnectionName = network?.networkConnectionsName as string;

	const goToProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	useValidateDisplayingNetworkConnection(networkConnection, networkConnectionName, function onError() {
		navigate(ROUTES.home(), { replace: true });
	});

	if (isLoading) {
		return (
			<div className={styles.networkConnection__activityIndicator}>
				<ActivityIndicator size="large" />
			</div>
		);
	} else if (isError || !networkConnection) {
		return <NotFound404 />;
	}
	return (
		<Page title={networkConnection.title}>
			<div className={styles.networkConnection__tabWrapper}>
				<div className={styles.networkConnection__tabHeader}>
					<NetworkConnectionPageHeader
						networkConnection={networkConnection}
						networkConnectionName={networkConnectionName}
					/>
				</div>
				<div className={styles.networkConnection__tabContainer}>
					<NetworkConnectionSubHeader
						networkConnection={networkConnection}
						networkConnectionName={networkConnectionName}
						networkId={networkConnection?.community?.id}
					/>
					<Tabs selectedIndex={currentTabIndex} onSelect={onChangeTab}>
						<Tabs.List>
							{tabs.map((tab) => {
								switch (tab) {
									case 'details':
										return (
											<Tabs.Tab
												key="details"
												nonSelectedClassName={styles.networkConnection__tab}
												selectedClassName={styles.networkConnection__tab_selected}
											>
												{t('Details')}
											</Tabs.Tab>
										);
									case 'discussions':
										return (
											<Tabs.Tab
												key="discussions"
												nonSelectedClassName={styles.networkConnection__tab}
												selectedClassName={styles.networkConnection__tab_selected}
											>
												{t('Discussions')}
											</Tabs.Tab>
										);
									case 'members':
										return (
											<Tabs.Tab
												key="members"
												nonSelectedClassName={styles.networkConnection__tab}
												selectedClassName={styles.networkConnection__tab_selected}
											>
												{t('Members')}
											</Tabs.Tab>
										);
									case 'files':
										return (
											<Tabs.Tab
												key="files"
												nonSelectedClassName={styles.networkConnection__tab}
												selectedClassName={styles.networkConnection__tab_selected}
											>
												{t('Files')}
											</Tabs.Tab>
										);
								}
							})}
						</Tabs.List>
						{tabs.map((tab) => {
							switch (tab) {
								case 'details':
									return (
										<Tabs.Panel key="details">
											<TabWrapper>
												<NetworkConnectionDetails
													networkConnection={networkConnection}
													networkConnectionName={networkConnectionName}
													onGoToProfile={goToProfile}
												/>
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'discussions':
									return (
										<Tabs.Panel key="discussions">
											<TabWrapper ref={discussionsWrapperRef}>
												<NetworkConnectionDiscussionsPage
													networkConnection={networkConnection}
													networkConnectionName={networkConnectionName}
													wrapperRef={discussionsWrapperRef}
												/>
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'members':
									return (
										<Tabs.Panel key="members">
											<TabWrapper>
												<NetworkConnectionMembersPage
													networkConnection={networkConnection}
													networkConnectionName={networkConnectionName}
													onClick={(userProfileRecord) => goToProfile(userProfileRecord.profile.id)}
												/>
											</TabWrapper>
										</Tabs.Panel>
									);
								case 'files':
									return (
										<Tabs.Panel key="files">
											<TabWrapper>
												<NetworkConnectionFilesPage />
											</TabWrapper>
										</Tabs.Panel>
									);
							}
						})}
					</Tabs>
				</div>
			</div>
		</Page>
	);
};

export interface INetworkConnectionTabsPageParams extends Record<string, string | undefined> {
	id: string;
	tab?: TNetworkConnectionTabName;
}

export default NetworkConnectionTabsPage;
