import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import { useAppRouterContext } from '../../../../app/AppRouterContext';
import type { TVideoDetails } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useVideoDetailsQuery = (videoId?: string, options?: UseQueryOptions<TVideoDetails, Error>) => {
	const api = useService('ApiVideoService');

	const trigger = useAppRouterContext().pathnameChangedAt; // using trigger when the URL changed helps to avoid infinite loop because  of cached stale token for private videos. (@see T21C-5563) [@DmitriyNikolenko]

	return useQuery<TVideoDetails, Error>(['videoDetails', videoId, trigger], async () => api.getVideoDetails(videoId), {
		cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
		...options,
	});
};
