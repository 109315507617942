import { useTranslation, useChat } from '@hooks';
import { CommentIcon, FillChatIcon, IconButton } from '@ui-kit';
import { ROUTES } from '../../routes';
import styles from './ChatHeaderButton.module.css';
import { useChatUnreadCount } from '@ui-modules/chat/hooks/useChatUnreadCount';

const ChatHeaderButton = () => {
	const { t } = useTranslation();
	const { unreadCount } = useChatUnreadCount();
	const { setShouldUpdateChannel } = useChat();
	return (
		<>
			<IconButton
				icon={<CommentIcon className={styles.button__icon} height={20} width={18} />}
				iconActive={<FillChatIcon className={styles.button__icon_active} height={20} width={18} />}
				label={t('Chat')}
				linkTo={ROUTES.chat()}
				showBadge={!!(unreadCount && unreadCount > 0)}
				onClick={() => setShouldUpdateChannel(true)}
			/>
		</>
	);
};

export default ChatHeaderButton;
