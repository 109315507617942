import { useService } from '@hooks';

/** Allows to get value of user decision about using app on Tablet from persistent storage and set to 'true'. */
export const useShouldPersistShowingAppOnTablet = () => {
	const redux = useService('ReduxService');
	const shouldPersistShowingAppOnTablet = redux.useAppSelector(
		(store) => store.userPreferences.shouldPersistShowingAppOnTablet,
	);
	const { setPersistShowingAppOnTablet } = redux.useBindActionCreators(redux.userPreferences);

	return { shouldPersistShowingAppOnTablet, setPersistShowingAppOnTablet };
};
