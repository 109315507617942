import { Modal } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useDeletePinnedBannerMutation } from '@ui-modules/feed/hooks/useDeletePinnedBannerMutation';
import { noop } from '@utils';
import type { IModalProps } from '@ui-kit';
import type { TPinnedBanner } from '@typings';

/** Confirms deleting of a pinned banner and performs it. */
const DeletePinnedBannerModal = ({
	visible,
	pinnedBanner,
	onClose,
	onSuccess = noop,
}: IDeletePinnedBannerModalProps) => {
	const { t } = useTranslation();

	const { mutate: deletePinnedBanner, isLoading } = useDeletePinnedBannerMutation({
		onSuccess: () => {
			onClose();
			onSuccess?.();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('Deleting this pinned banner will permanently remove it.')}
			title={t('Delete Pinned Banner?')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => deletePinnedBanner(pinnedBanner)}
		/>
	);
};

export interface IDeletePinnedBannerModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Activity to delete. */
	pinnedBanner: TPinnedBanner;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeletePinnedBannerModal;
