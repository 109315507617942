import { useService, usePaginatedInfiniteQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunityMember, TCommunity, TPaginatedList } from '@typings';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';

export const useCommunityRegularMembersInfiniteQuery = (
	communityId: TCommunity['id'],
	queryOptions?: UseInfiniteQueryOptions<TPaginatedList<TCommunityMember>, Error>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');
	return usePaginatedInfiniteQuery<TCommunityMember>(
		queryKeys.getCommunityRegularMemberList(communityId),
		async ({ pageParam }) => {
			return await api.membership.getCommunityRegularMemberList(communityId, pageParam ?? 1);
		},
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			...queryOptions,
		},
	);
};
