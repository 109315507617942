import { CloseIcon } from '@ui-kit/icons';
import styles from './ChipSet.module.css';

const Chip = ({ title, onRemove }: IChipProps) => {
	return (
		<div className={styles.chip}>
			<button className={styles.chip__button} onClick={onRemove}>
				<CloseIcon height={6} width={6} />
			</button>
			<span className={styles.chip__title}>{title}</span>
		</div>
	);
};

const ChipSet = ({ value = [], onRemove }: IChipSetProps) => {
	return (
		<div className={styles.chips__container}>
			{value.map((item, index) => (
				<Chip key={item} title={item} onRemove={() => onRemove(index)} />
			))}
		</div>
	);
};

export interface IChipSetProps {
	value: string[];
	onRemove: (index: number) => void;
}

export interface IChipProps {
	title: string;
	onRemove: () => void;
}

export { ChipSet, Chip };
