import { clsx } from '@utils';
import { LikeIcon, LikeSolidIcon } from '../../icons';
import styles from './LikesButton.module.css';

/** Button with counter of likes.
 * 	Highlighted when hasMyLike passed.
 *  The likeTitle is showed when number of likes is zero
 */
const LikesButton = ({ likeTitle, likes, hasMyLike = false, size, disabled = false, onLike }: ILikesButtonProps) => {
	return (
		<div className={styles.likesButtonWrapper}>
			<button
				className={styles.likesButton}
				data-size={size}
				disabled={!onLike || disabled}
				onClick={(event) => {
					onLike(!hasMyLike);
					event.stopPropagation();
				}}
			>
				<div className={styles.likesButton__iconWrapper}>
					{hasMyLike ? (
						<LikeSolidIcon className={styles.likesButton__icon_liked} height={16} width={16} />
					) : (
						<LikeIcon className={styles.likesButton__icon_unliked} height={14} width={14} />
					)}
				</div>
				<span
					className={clsx(
						styles.likesButton__title,
						hasMyLike ? styles.likesButton__title_liked : styles.likesButton__title_unliked,
					)}
				>
					{likes ? likes : likeTitle}
				</span>
			</button>
		</div>
	);
};

export interface ILikesButtonProps {
	/** Title showing when number of likes is zero. */
	likeTitle: string;
	/** Number of like. */
	likes: number;
	/** Affects the font size of the text title & icon size. */
	size: 'medium' | 'small';
	/** Shows highlighting style if the current user has liked it. */
	hasMyLike: boolean;
	/** Disables the button. */
	disabled?: boolean;
	/** Calls when the button pressed. */
	onLike: (newHasMyLike: boolean) => void;
}

export default LikesButton;
