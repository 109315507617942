import { useMutation, useService } from '@hooks';
import type { TFile } from '@typings';

type TUpdateDealFilesData = {
	fileId: TFile['id'];
};

export const useDeleteDealPreviewFilesMutation = () => {
	const api = useService('ApiService');
	return useMutation<void, Error, TUpdateDealFilesData>(
		['deal.deleteDealPreviewFile'],
		async ({ fileId }: TUpdateDealFilesData) => await api.dealFile.deleteDealPreviewFile(fileId),
	);
};
