import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ISignInState = {
	/** Default email for Sign In if user agreed to save it between sessions */
	defaultLogin: string | null;
};

const initialSignInState: ISignInState = {
	defaultLogin: null,
};

export const signInSlice = createSlice({
	name: 'signIn',
	initialState: initialSignInState,
	reducers: {
		setDefaultLogin: (state, { payload: login }: PayloadAction<ISignInState['defaultLogin']>) => {
			state.defaultLogin = login;
		},
		resetDefaultLogin: (state) => {
			state.defaultLogin = null;
		},
	},
});
