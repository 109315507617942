import FractionForm from '@ui-modules/forms/components/FractionForm';
import { Page } from '../../components';
import { PieChart, Gap, ActivityIndicator } from '@ui-kit';
import { useEffect, useEvent, useMemo, useRef, useService, useTranslation } from '@hooks';
import { useMyAssetAllocationQuery } from '@ui-modules/assetAllocation/hooks/useMyAssetAllocationQuery';
import { usePostAssetAllocationMutation } from '@ui-modules/assetAllocation/hooks/usePostAssetAllocationMutation';
import { dayjs } from '@utils';
import { formatAssetAllocationToAssetAllocationExpandedList } from '@ui-modules/assetAllocation/utils/formatAssetAllocationToAssetAllocationExpandedList';
import { formatAssetAllocationToAssetAllocationList } from '@ui-modules/assetAllocation/utils/formatAssetAllocationToAssetAllocationList';
import { formatAssetAllocationListToAssetAllocationValues } from '@ui-modules/assetAllocation/utils/formatAssetAllocationListToAssetAllocationValues';
import styles from './AssetAllocationMePage.module.css';
import type { TAssetAllocation, TAssetAllocationExpanded } from '@typings';
import { useAssetAllocationCommunitiesQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationCommunitiesQuery';

const AssetAllocationMePage = () => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { data: assetAllocation, isFetched } = useMyAssetAllocationQuery();
	const { data: useAssetAllocation, isFetched: commonAssetAllocationFetched } = useAssetAllocationCommunitiesQuery({
		select: (assetAllocationData) => assetAllocationData?.user,
	});
	const { mutateAsync: postAssetAllocation } = usePostAssetAllocationMutation({
		onMutate: () => {
			if (assetAllocation) {
				analytics.trackEvent('AssetAllocationInteractions', { interaction_type: 'asset_allocation_updated' });
			} else {
				analytics.trackEvent('AssetAllocationInteractions', { interaction_type: 'asset_allocation_created' });
			}
		},
	});

	const assetAllocationList = useMemo(() => {
		const EMPTY_ASSET_ALLOCATION = {} as TAssetAllocationExpanded; // it used for generating initial values if the user asset allocation is not created yet.
		return formatAssetAllocationToAssetAllocationExpandedList(t)(assetAllocation ?? EMPTY_ASSET_ALLOCATION);
	}, [assetAllocation]);

	const assetAllocationCommonList = useMemo(() => {
		const EMPTY_ASSET_ALLOCATION = {} as TAssetAllocation; // it used for generating initial values if the user asset allocation is not created yet.
		return formatAssetAllocationToAssetAllocationList(t)(useAssetAllocation ?? EMPTY_ASSET_ALLOCATION);
	}, [useAssetAllocation]);

	const translateCurrentTotalTitle = useRef((value: number) => t('TOTAL: {{value}}%', { value })).current;
	const translateShouldTotalTitle = useRef((value: number) => t('Should total {{value}}%', { value })).current;
	const onSubmit = useEvent(async (values) => {
		const assetAllocationValues = formatAssetAllocationListToAssetAllocationValues(values);
		await postAssetAllocation(assetAllocationValues);
	});

	useEffect(function trackPageViewed() {
		analytics.trackEvent('AssetAllocationInteractions', {
			interaction_type: 'tab_viewed',
			tab_name: 'me',
		});
	}, []);

	return (
		<Page title={t('Asset Allocation | {{tabName}}', { tabName: t('Me') })}>
			<div className={styles.assetAllocationMePage}>
				{isFetched && commonAssetAllocationFetched ? (
					<>
						{assetAllocation ? (
							<>
								<PieChart
									height={279}
									items={assetAllocationCommonList}
									subtitle={t('Last updated {{date}}', {
										date: dayjs(assetAllocation.updatedAt).format('D MMM YYYY'),
									})}
									title={t('My Asset Allocation')}
								/>
								<Gap gap={16} />
							</>
						) : null}
						<FractionForm
							cancelButtonTitle={t('Cancel')}
							editButtonTitle={t('Edit')}
							items={assetAllocationList}
							submitButtonTitle={t('Save Changes')}
							targetTotal={100}
							title={t('Update My Allocation')}
							translateCurrentTotalTitle={translateCurrentTotalTitle}
							translateShouldTotalTitle={translateShouldTotalTitle}
							onSubmit={onSubmit}
						/>
					</>
				) : (
					<ActivityIndicator size="small" type="fit" />
				)}
			</div>
		</Page>
	);
};

export default AssetAllocationMePage;
