import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface IUsersGroupsSliceState {
	groups: {
		[key: string]: string;
	};
}

const initialState: IUsersGroupsSliceState = {
	groups: {},
};

export const usersGroupsSlice = createSlice({
	name: 'usersGroups',
	initialState,
	reducers: {
		setUsersGroups: (state, { payload: groups }: PayloadAction<{ [key: string]: string }>) => {
			state.groups = { ...state.groups, ...groups };
		},
	},
});
