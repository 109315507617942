import React from 'react';
import { Formik } from '@ui-kit';
import { useEffect, useRef } from '@hooks';
import type { FormikProps } from 'formik';

const NotificationsSettingsForm = ({ children, initialValue, visible }: ISettingsFormProps) => {
	const innerRef = useRef<FormikProps<TSettingsFormValue> | null>(null);
	useEffect(() => {
		if (!visible) innerRef.current?.setValues({ ...initialValue, options: [] });
	}, [visible, initialValue]);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValue}
			innerRef={(ref) => (innerRef.current = ref)}
			onSubmit={() => undefined}
		>
			{children}
		</Formik>
	);
};

export type TSettingsFormValue = {
	id: string | null;
	notificationId: string;
	options: string[];
};

interface ISettingsFormProps {
	initialValue: TSettingsFormValue;
	children: React.ReactNode;
	visible: boolean;
}

export default NotificationsSettingsForm;
