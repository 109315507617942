import { useMutation } from '@hooks';
import type { UseMutationOptions } from '@tanstack/react-query';

export const useResendTrustedDeviceNotification = (options?: UseMutationOptions<void, Error>) => {
	return useMutation<void, Error>(
		['auth.setNewPassword'],
		async () => {
			// Update with real backend request
		},
		{
			...options,
		},
	);
};
