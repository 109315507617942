import { useService, useQuery } from '@hooks';
import type { TFile, TFolder } from '@typings';

export const useGetEventFilesQuery = (eventId?: string, folderId?: TFolder['id'] | undefined) => {
	const api = useService('ApiService');

	return useQuery<TFile[], Error>(
		['calendarItemFile.getMeetingFiles', eventId, folderId],
		async () => api.calendarItemFile.getMeetingFiles({ calendarItemId: eventId as string }),
		{ enabled: !!eventId },
	);
};
