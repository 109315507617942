import { useMemo, useCallback } from '@hooks';
import { Checkbox, FixedWidthContainer, RightArrowIcon, Virtuoso } from '@ui-kit';
import type { ReactNode } from 'react';
import { filterTagsByQuery, sanitizeFilterQuery, sortByAlphabet } from './TagList.utils';
import styles from './TagList.module.css';

const TagList = ({
	tags,
	selectedTags,
	filterQuery,
	createButtonTitle,
	ListHeaderComponent,
	onAddTag,
	onRemoveTag,
	onCreateTag,
}: ITagListProps) => {
	const currentTag = sanitizeFilterQuery(filterQuery);
	const filteredTags = useMemo(() => filterTagsByQuery(tags, currentTag), [tags, currentTag]);
	const sortedTags = useMemo(() => sortByAlphabet(filteredTags), [filteredTags, selectedTags]);
	const isAnyTagMatch = !!sortedTags.length;

	const renderItem = useCallback(
		(index: number, tag: string) => {
			if (index === 0) {
				return <> {ListHeaderComponent}</>;
			}
			const isSelected = selectedTags.includes(tag);
			const handleSelect = (isNowSelected: boolean) => {
				isNowSelected ? onAddTag(tag) : onRemoveTag(tag);
			};
			return (
				<div className={styles.tag__container}>
					<button className={styles.tag} onClick={() => handleSelect(!isSelected)}>
						<span>{tag}</span>
					</button>
					<Checkbox value={isSelected} onChange={handleSelect} />
				</div>
			);
		},
		[sortedTags, filterQuery],
	);

	return (
		<div className={styles.tags__container}>
			<Virtuoso<string>
				className={styles.tags__list}
				components={{
					Footer: () => (
						<FixedWidthContainer>
							{isAnyTagMatch || !currentTag ? null : (
								<button className={styles.tag__buttonCreate} onClick={() => onCreateTag(currentTag)}>
									<span>{`${createButtonTitle} ‘${currentTag}’`}</span>
									<RightArrowIcon height={13} width={14} />
								</button>
							)}
						</FixedWidthContainer>
					),
				}}
				computeItemKey={(index, item) => `${index}_${item}`}
				data={['header_element', ...sortedTags]}
				defaultItemHeight={48}
				itemContent={renderItem}
				totalCount={sortedTags?.length}
			/>
		</div>
	);
};

export interface ITagListProps {
	tags: string[];
	selectedTags: string[];
	filterQuery: string;
	createButtonTitle: string;
	onAddTag: (tag: string) => void;
	onRemoveTag: (tag: string) => void;
	onCreateTag: (tag: string) => void;
	ListHeaderComponent: ReactNode;
}

export default TagList;
