import type { TEvent } from '@typings';
import { dayjs, getCompiledEventType, clsx, getEventTypeColors } from '@utils';
import styles from './EventAvatar.module.css';

const EventAvatar = ({ event }: IEventAvatarProps) => {
	const compiledEventType = getCompiledEventType(event);
	const startDate = dayjs(event.startDate);
	const endDate = dayjs(event.endDate);
	const startDateDay = startDate.date();
	const endDateDay = endDate.date();
	const dateRange = startDateDay === endDateDay ? startDateDay : `${startDateDay}-${endDateDay}`;
	const month = startDate.format('MMM');

	const { primaryColor, secondaryColor } = getEventTypeColors(compiledEventType);

	return (
		<div className={styles.eventAvatar}>
			<div className={styles.eventAvatar__content}>
				<div className={styles.eventAvatar__date}>
					<span
						className={clsx(styles.eventAvatar__text, styles.eventAvatar__dateItem, styles.eventAvatar__day)}
						style={{ color: primaryColor, backgroundColor: secondaryColor }}
					>
						{dateRange}
					</span>
					<span
						className={clsx(styles.eventAvatar__text, styles.eventAvatar__dateItem, styles.eventAvatar__month)}
						style={{ backgroundColor: primaryColor }}
					>
						{month}
					</span>
				</div>
			</div>
		</div>
	);
};

export interface IEventAvatarProps {
	event: TEvent;
}

export default EventAvatar;
