import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TCommunityCompact, TProfile } from '@typings';

export const useProfileCommunitiesQuery = <T = TUseProfileCommunitiesQueryData>(
	profileId: TProfile['id'],
	queryOptions?: UseQueryOptions<TUseProfileCommunitiesQueryData, Error, T>,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TUseProfileCommunitiesQueryData, Error, T>(
		queryKeys.getProfileCommunities(profileId),
		() => api.community.getProfileCommunities(profileId),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};

type TUseProfileCommunitiesQueryData = TCommunityCompact[];
