import { Gap, Input, Modal } from '@ui-kit';
import { useTranslation, useFormik } from '@hooks';
import { useRenameFolderMutation } from '@ui-modules/files/hooks/useRenameFolderMutation';
import { editFolderSchema } from '@schemas';
import type { TEditFolderForm } from '@schemas';
import type { TCommunity, TFolder } from '@typings';
import type { IModalProps } from '@ui-kit';

/** Asks for a new name of the folder in a modal window and renames it. */
const RenameFolderModal = ({ communityId, folderId, folderName, visible, onClose }: IRenameFolderModalProps) => {
	const { t } = useTranslation();

	const releaseDialog = () => {
		resetForm();
		onClose();
	};
	const { mutate: renameFolder, isLoading } = useRenameFolderMutation(communityId, folderId, {
		onSuccess: () => releaseDialog(),
	});
	const { values, errors, handleChange, handleBlur, handleSubmit, submitForm, resetForm } = useFormik<TEditFolderForm>({
		validationSchema: editFolderSchema,
		initialValues: { name: folderName },
		onSubmit: (formValues) => renameFolder(formValues),
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Save')}
			confirmTrackingName="files--rename-folder"
			isConfirmLoading={isLoading}
			modalContent={
				<form onSubmit={handleSubmit}>
					<Gap gap={8} />
					<Input
						errorMessage={errors.name}
						label={t('Folder Name')}
						placeholder={t('Folder name')}
						value={values.name}
						onBlur={handleBlur('name')}
						onChange={handleChange('name')}
					/>
				</form>
			}
			title={t('Rename Folder')}
			variant="medium"
			visible={visible}
			onCancel={releaseDialog}
			onConfirm={submitForm}
		/>
	);
};

export interface IRenameFolderModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Community ID. */
	communityId: TCommunity['id'];
	/** Target Folder ID. */
	folderId: TFolder['id'];
	/** Target Folder name to use as initial value for the rename form. */
	folderName: TFolder['name'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
}

export default RenameFolderModal;
