import { ActivityIndicator } from '@ui-kit';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import FilesWidget from '@ui-modules/files/components/FilesWidget';
import { useEffect, useEvent, useNotification, useNavigate, useMe, useMemo, useService, useCallback } from '@hooks';
import { useDealFilesAndFoldersQuery } from '@ui-modules/files/hooks/useDealFilesAndFoldersQuery';
import { useDealFolderQuery } from '@ui-modules/files/hooks/useDealFolderQuery';
import { usePickUpFile } from '@ui-modules/files/hooks/usePickUpFile';
import { useUploadDealFileMutation } from '@ui-modules/files/hooks/useUploadDealFileMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import { useDeleteDealFileMutation } from '@ui-modules/files/hooks/useDeleteDealFileMutation';
import { useCreateDealFolderMutation } from '@ui-modules/files/hooks/useCreateDealFolderMutation';
import { useDeleteDealFolderMutation } from '@ui-modules/files/hooks/useDeleteDealFolderMutation';
import { useRenameDealFolderMutation } from '@ui-modules/files/hooks/useRenameDealFolderMutation';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { DealFilesEntity } from '@utils';
import { ROUTES } from '@constants';
import type { TDeal, TFile, TFolder } from '@typings';

/** Combines tools to display list of files and folders for a certain deal & toolbar to make changes with them or search. */
const DealFilesWidget = ({ dealId, folderId }: IDealFilesWidgetProps) => {
	// Dependencies.
	const navigate = useNavigate();
	const { showInfo } = useNotification();
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const { user } = useMe();

	// Fetch related data.
	const isRootFolder = !folderId;
	const { files = [], folders = [], isFetching } = useDealFilesAndFoldersQuery(dealId, folderId, isRootFolder);
	const { data: deal, isLoading: isDealLoading } = useGetDealQuery(dealId);
	const { data: folder } = useDealFolderQuery(dealId, folderId);
	const pdfViewer = usePdfViewer();

	// Determine data & permissions.
	const hasOwnFolderCreated = useMemo(
		() => !!folders.find((folder) => DealFilesEntity.isMyFolder(folder, user)),
		[folders],
	);
	const canMakeFileViewOnly = useCallback(
		(file: TFile) => Boolean(deal && DealFilesEntity.canMakeViewOnly(file, user, deal)),
		[deal],
	);
	const canMakeFileDownloadable = useCallback(
		(file: TFile) => Boolean(deal && DealFilesEntity.canMakeDownloadable(file, user, deal)),
		[deal],
	);
	const canDeleteFile = useCallback((file: TFile) => DealFilesEntity.canDeleteFile(file, user), []);

	// User actions.
	const onOpenFile = useEvent(async (file: TFile) => {
		if (file.mediaObject.mimeType === 'application/pdf') {
			pdfViewer.open(file);
		} else {
			api.mediaObject.downloadNonEncryptedFileUrl(file);
		}
		deal &&
			analytics.trackEvent('DealInteractions', {
				deal_id: dealId,
				deal_name: deal?.name,
				interaction_type: 'investor_files',
				interaction_action: 'files viewed',
			});
	});
	const onOpenFolder = useEvent((openFolderId: TFolder['id'] | null | undefined, replace = true) => {
		if (folderId === openFolderId) return; // do not open the same folder twice that breaks the back button.
		navigate(ROUTES.dealFolder(dealId, openFolderId), { replace });
	});
	const makeFolderLink = useCallback((folderId: TFolder['id'] | null) => ROUTES.dealFolder(dealId, folderId), []);

	// User actions.
	const { uploadFile, isLoading: isFileUploading } = useUploadDealFileMutation(deal as TDeal, folderId, {
		onSuccess: (file) => {
			onOpenFolder(file.folder?.id ?? null);
			deal &&
				analytics.trackEvent('DealInteractions', {
					deal_id: dealId,
					deal_name: deal?.name,
					interaction_type: 'investor_files',
					interaction_action: 'files attached',
				});
		},
	});
	const { openFilePicker: pickAndUploadFile, getInputProps } = usePickUpFile((file) => uploadFile({ file }));
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		dealId: dealId,
		folderId,
	});
	const { mutateAsync: deleteFile } = useDeleteDealFileMutation(dealId, folderId);
	const { mutateAsync: createFolder } = useCreateDealFolderMutation(dealId, folderId, String(deal?.name));
	const { mutateAsync: deleteFolder } = useDeleteDealFolderMutation(dealId, String(deal?.name), {
		onSuccess: () => onOpenFolder(folder?.folder?.id ?? null),
	});
	const { mutateAsync: renameFolder } = useRenameDealFolderMutation(
		dealId,
		folder?.id as TFolder['id'],
		String(deal?.name),
	);

	// Effects.
	useEffect(
		function onSeeFilesForbidden() {
			if (deal && !isRootFolder && !DealFilesEntity.canSeeOwnUserFolderV2(deal, user)) {
				showInfo({ title: "You can't see this folder" });
				navigate(ROUTES.dealFolder(dealId));
			}
		},
		[deal, isRootFolder],
	);

	useEffect(() => {
		if (deal)
			analytics.trackEvent('DealInteractions', {
				deal_id: deal.id,
				deal_name: deal.name,
				interaction_type: 'investor_files',
				interaction_action: 'folder viewed',
			});
	}, [folderId, deal]);

	if (!deal || isFetching || isDealLoading) {
		return <ActivityIndicator size="medium" type="fit" />;
	}
	return (
		<>
			<FilesWidget
				canCreateFolders={DealFilesEntity.canCreateFolderV2(deal, user, folder ?? null, hasOwnFolderCreated)}
				canDeleteFile={canDeleteFile}
				canDeleteFolders={DealFilesEntity.canDeleteFolderV2(folder, user, deal, isRootFolder)}
				canEditFolders={DealFilesEntity.canEditFolderV2(folder, user, deal, isRootFolder)}
				canMakeFileDownloadable={canMakeFileDownloadable}
				canMakeFileViewOnly={canMakeFileViewOnly}
				canUploadFiles={DealFilesEntity.canUploadFile(deal, user, folder)}
				currentFolder={folder}
				files={files}
				folders={folders}
				isFileUploading={isFileUploading}
				isLoading={isFetching || isDealLoading}
				isRootFolder={isRootFolder}
				makeFolderLink={makeFolderLink}
				onCreateFolder={createFolder}
				onDeleteFile={deleteFile}
				onDeleteFolder={deleteFolder}
				onOpenFile={onOpenFile}
				onOpenFolder={onOpenFolder}
				onPickAndUploadFile={pickAndUploadFile}
				onRenameFolder={renameFolder}
				onSetViewOnlyFileStatus={updateMediaObjectSetViewerOnly}
			/>
			<input {...getInputProps()} /> {/* hidden input to make file upload working */}
		</>
	);
};

export interface IDealFilesWidgetProps {
	/** Target Deal ID which files and folders should be shown. */
	dealId: TDeal['id'];
	/** Target Deal ID which files and folders should be shown. Pass 'null' to show the root folder. */
	folderId: TFolder['id'] | null;
}

export default DealFilesWidget;
