import { Modal } from '@ui-kit';
import { useRef, useToggle, useTranslation } from '@hooks';
import { useFormikContext } from '@utils';
import { useBlocker } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import type { FormikContextType } from 'formik';

/** Asks the user if they want to discard changes when navigating away from a page.
 * 	Should be used within a form because depends on Formik form context.
 */
const DiscardFormChangesModal = ({
	allowedNextPaths,
	ignoreDirty = false,
	customFormikContext,
	onDiscard,
	onKeepEditing,
}: IDiscardFormChangesModalProps) => {
	const { t } = useTranslation();
	const formikContext = useFormikContext() ?? customFormikContext;

	const [showDialog, toggleShowDialog] = useToggle(false);

	const nextLocationRef = useRef<Location | null>(null);
	const discardChangesApprovedRef = useRef<boolean>(false);

	const blocker = useBlocker(({ nextLocation, currentLocation }) => {
		const shouldBlock =
			(formikContext.dirty || ignoreDirty) &&
			!formikContext.submitCount &&
			!discardChangesApprovedRef.current &&
			currentLocation.pathname !== nextLocation.pathname;

		if (shouldBlock) {
			const nextLocationAllowed = allowedNextPaths?.some((path) => nextLocation.pathname.startsWith(path));
			if (nextLocationAllowed) {
				return false;
			}

			nextLocationRef.current = nextLocation;
			toggleShowDialog();
		}
		return shouldBlock;
	});

	return (
		<Modal
			cancelTitle={t('Discard changes')}
			confirmTitle={t('Keep editing')}
			subTitle={t('Are you sure you want to discard your changes?')}
			title={t('Discard Changes')}
			variant="medium"
			visible={showDialog}
			onCancel={() => {
				onDiscard?.();
				discardChangesApprovedRef.current = true;
				blocker.proceed?.(); // it potentially may be empty in case if you navigate with replace and new state (in case if you encounter an issue) [@DmitriyNikolenko]
			}}
			onConfirm={() => {
				toggleShowDialog();
				onKeepEditing?.();
			}}
		/>
	);
};

export interface IDiscardFormChangesModalProps {
	ignoreDirty?: boolean;
	/** List of routes to which you can navigate without triggering showing DiscardChanges modal dialog.
	 * 	It's important to add at least "current" path, because sometimes it may be redirect to itself with new state or props that should trigger redundant dialog
	 */
	allowedNextPaths?: string[];
	/** In case to use out of Formik component or with useFormik hook
	 * 	you can manually pass part of the state required to work properly.
	 *  By default context is given from Formik.useFormikContext() hook
	 */
	customFormikContext?: Pick<FormikContextType<unknown>, 'dirty' | 'submitCount'>;
	onDiscard?: () => void;
	onKeepEditing?: () => void;
}

export default DiscardFormChangesModal;
