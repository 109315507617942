import { Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import { useTranslation, useNavigate, useParams, useBlockedAndBlockingUsers } from '@hooks';
import { useUnblockUserMutation } from '@ui-modules/contacts/hooks/useUnblockUserMutation';
import BlockedUser from '@ui-modules/settings/components/BlockedUser';
import type { TProfile } from '@typings';
import styles from './BlockUsersSettingsPage.module.css';
import { ROUTES } from '@constants';

const BlockUsersSettingsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams<IBlockUsersSettingsPageParams>();

	const { blockedUserRelations, isBlockedUsersLoading } = useBlockedAndBlockingUsers();
	const { mutate: unblockUser } = useUnblockUserMutation();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));

	return (
		<Page title={t('Blocked Users')}>
			<div className={styles.blockUsersSettingsPage}>
				<h3 className={styles.blockUsersSettingsPage__title}>{t('Blocked users')}</h3>
				{blockedUserRelations.length > 0 ? (
					<>
						{blockedUserRelations.map((blockedUserRelation) => (
							<BlockedUser
								item={blockedUserRelation.blockedUser}
								key={blockedUserRelation['@id']}
								onOpenProfile={openProfile}
								onUnblockPressed={() => unblockUser(blockedUserRelation.id)}
							/>
						))}
					</>
				) : isBlockedUsersLoading ? (
					<ActivityIndicator type="fit" />
				) : (
					<EmptyStateMessage text={t('There are no blocked users.')} />
				)}
			</div>
		</Page>
	);
};

export interface IBlockUsersSettingsPageParams extends Record<string, string | undefined> {
	id: string;
}

export default BlockUsersSettingsPage;
