import { Button, RightArrowIcon } from '@ui-kit';
import { useTranslation } from '@hooks';

import styles from './sections.module.css';
import clsx from 'clsx';

export const JoinNetworkError = ({ onClick, networkName }: IJoinNetworkErrorProps) => {
	const { t } = useTranslation();
	return (
		<div className={clsx(styles.joinNetworkError)}>
			<span className={styles.joinNetworkErrorText}>{t('To post a Deal in this Network, you must first')}</span>
			<Button
				className={styles.joinNetworkError__button}
				icon={<RightArrowIcon className={styles.joinNetworkError__icon} height={14} width={14} />}
				iconPosition="right"
				title={t('join the {{networkName}}', { networkName })}
				type="clear-error"
				variant="small"
				onClick={onClick}
			/>
		</div>
	);
};

interface IJoinNetworkErrorProps {
	onClick: () => void;
	networkName: string;
}
