import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { TPieChartItem, TLabelProps } from '../PieChart';

/** It collects PieChart labels data from label() callback
 * to prepare the full array of labelProps to render them in custom way (to fix labels overflow issue) via HTML but not SVG.
 * To avoid setState infinite loop issue and it updates the own state in a debounced way.
 *
 */
export function usePieChartLabelPropsState(items: TPieChartItem[]) {
	const [labelPropsSet, setLabelPropsSet] = useDebouncedState<TLabelProps[]>([], 50);
	const appendLabelProps = useCallback(
		(labelProps: TLabelProps) => {
			setLabelPropsSet((currentLabelPropsSet) => {
				const setFulfilled = currentLabelPropsSet.length === items.length;
				if (setFulfilled) return currentLabelPropsSet; // skip update if the array is fulfilled to avoid infinite loop

				const newLabelPropsSet = [...currentLabelPropsSet];
				newLabelPropsSet[labelProps.dataIndex] = labelProps;
				return newLabelPropsSet;
			});
		},
		[items.length],
	);

	useLayoutEffect(
		function resetLabelPropsSetOnItemsChanged() {
			setLabelPropsSet([]); // to allow appendLabelProps() to fill the array with new values
		},
		[items],
	);

	return [labelPropsSet, appendLabelProps] as const;
}

/** Sets state with delay collecting the final value form multiple setState calls. */
function useDebouncedState<T>(initialValue: T, delayMs: number) {
	const [state, setState] = useState<T>(initialValue);
	const timerRef = useRef<NodeJS.Timeout>();
	const nextValueRef = useRef<T>(state);

	const debounceSetState = useCallback<Dispatch<SetStateAction<T>>>(
		(valueOrSetValueFunction) => {
			if (typeof valueOrSetValueFunction === 'function') {
				const setValueFunction = valueOrSetValueFunction as (prevState: T) => T;
				const lastStateValue = nextValueRef.current;
				nextValueRef.current = setValueFunction(lastStateValue);
			} else {
				const newValue = valueOrSetValueFunction;
				nextValueRef.current = newValue;
			}

			clearTimeout(timerRef.current);
			timerRef.current = setTimeout(() => {
				setState(nextValueRef.current);
			}, delayMs);
		},
		[delayMs],
	);

	return [state, debounceSetState] as const;
}
