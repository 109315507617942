import { useQuery, useService } from '@hooks';
import type { TDeal, TFolder } from '@typings';

export const useDealFilesAndFoldersQuery = (
	dealId: TDeal['id'],
	folderId: TFolder['id'] | null,
	isRootFolder: boolean,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const filesQuery = useQuery(
		reactQuery.queryKeys.getDealFiles(dealId, folderId),
		async () => await api.dealFile.getDealFiles({ dealId, folderId: folderId ?? undefined }),
		{
			enabled: !isRootFolder,
		},
	);
	const foldersQuery = useQuery(
		reactQuery.queryKeys.getDealFolders(dealId, folderId),
		async () => await api.dealFolder.getDealFolders({ dealId, folderId: folderId ?? undefined }),
		{
			onSuccess(folders) {
				folders.forEach((folder) =>
					reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getDealFolder(dealId, folder.id), folder),
				);
			},
		},
	);

	return {
		files: isRootFolder ? undefined : filesQuery.data,
		folders: foldersQuery.data,
		isLoading: filesQuery.isLoading || foldersQuery.isLoading,
		isFetching: filesQuery.isFetching || foldersQuery.isFetching,
	};
};
