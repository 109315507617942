import { useCountDown, useEffect, useTranslation } from '@hooks';
import { Button } from '@ui-kit';
import { dayjs } from '@utils';
import type { IButtonProps } from '@ui-kit';

const ONE_SECOND = 1000;

const ResendCodeButton = ({
	startTimerOnMount = false,
	expirationDate,
	interval = 1,
	...buttonProps
}: IResendCodeButtonProps) => {
	const { t } = useTranslation();

	const intervalMs = interval * ONE_SECOND;
	const [millisecondsLeft, { start }] = useCountDown(getMillisecondsToExpirationDate(expirationDate), intervalMs);
	const secondsLeft = Math.round(millisecondsLeft / 1000);

	useEffect(() => {
		if (startTimerOnMount) start();
	}, []);

	useEffect(() => {
		const timeToCountMs = getMillisecondsToExpirationDate(expirationDate);
		start(timeToCountMs);
	}, [expirationDate]);

	return (
		<Button
			type="outline"
			variant="medium"
			{...buttonProps}
			block="fit"
			disabled={!!secondsLeft || buttonProps.disabled}
			title={secondsLeft ? t('Resend Code In [{{secondsLeft}}sec]', { secondsLeft }) : t('Resend Code')}
			onClick={(event) => {
				buttonProps?.onClick?.(event);
				start();
			}}
		/>
	);
};

const getMillisecondsToExpirationDate = (expirationDate: Date | undefined) => {
	if (!expirationDate) return 0;

	const milliseconds = Math.max(dayjs(expirationDate).diff(dayjs(), 'milliseconds'), 0);
	return milliseconds;
};

export interface IResendCodeButtonProps extends Omit<IButtonProps, 'title'> {
	startTimerOnMount: boolean;
	expirationDate: Date | undefined;
	interval?: number;
}

export default ResendCodeButton;
