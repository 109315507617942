import { useService, useChat, useMe, useCheckTextOnMaliciousUrls, useNotification, useTranslation } from '@hooks';
import { useTrackMessageSent } from './useTrackMessageSentQuery';
import type { TAttachments, TEvent } from '@typings';
import type { MessageResponse, SendMessageAPIResponse } from 'stream-chat';
import { useChannelActionContext, useChannelStateContext, useMessageInputContext } from 'stream-chat-react';
import { STREAM_CHAT_BASE_URL, POST_MESSAGE_CHAR_LIMIT } from '@constants';
import { uniq, toString, mapByFoundOrCreated, compact } from '@utils';
import { parseChatMessageUrls } from '../utils/parseChatMessageUrls';

export const useSendMessage = (editMessage: MessageResponse | null, quotedMessageId: string) => {
	const chat = useService('ChatService');
	const feed = useService('FeedService');
	const analytics = useService('AnalyticsService');
	const { channel } = useChannelStateContext();
	const { reset } = useChat();
	const { user } = useMe();
	const trackMessageSent = useTrackMessageSent(channel);
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ showMessage: true });
	const { mentioned_users } = useMessageInputContext();
	const { showError } = useNotification();
	const { t } = useTranslation();

	const getMentionUsers = (messageText = '') => {
		const mentionedUsersIds = [...mentioned_users, ...(editMessage?.mentioned_users || [])]
			.filter((el) => !!messageText.includes(String(el.name)))
			.map((el) => el.id);
		return uniq(mentionedUsersIds);
	};
	const { jumpToMessage } = useChannelActionContext();

	/** this is the type of attechment object accepted by stream */
	const uploadAttachments = (attachments: TAttachments[]) => {
		const streamAttachments = attachments.map((attachment) => {
			if (attachment.fileType === 'image') {
				return {
					image_url: attachment.url,
					fallback: attachment.name,
					type: attachment.fileType,
					original_height: attachment?.height,
					original_width: attachment?.width,
					file_size: attachment?.size,
				};
			} else if (attachment.type === 'giphy') {
				return attachment;
			} else {
				return {
					asset_url: attachment.url,
					type: attachment.fileType,
					title: attachment.name,
					mime_type: attachment.mimeType,
					thumb_url: attachment.thumb_url,
					original_height: attachment?.height,
					original_width: attachment?.width,
					file_size: attachment?.size,
				};
			}
		});
		return streamAttachments;
	};

	const enhanceUrlPreviewImages = async (editMessage: MessageResponse, text?: string) => {
		const previousAttachments = (editMessage.attachments ?? []) as TAttachments[];
		const previewUrlImages = previousAttachments.filter((item) => 'title_link' in item || 'og_scrape_url' in item);

		const links = parseChatMessageUrls(text);
		const reducedLinks = uniq(links).slice(0, 3);

		const newPreviewUrlImages = await mapByFoundOrCreated(
			reducedLinks,
			previewUrlImages,
			'og_scrape_url' as never,
			async (link) => {
				try {
					const ogInfo = await feed.getOpenGraphInfo(link);
					if (ogInfo)
						return {
							id: String(Date.now() + Math.random()),
							name: toString(ogInfo.title),
							isFile: false,
							og_scrape_url: 'original_url' in ogInfo ? ogInfo.original_url : ogInfo.url,
							title_link: 'original_url' in ogInfo ? ogInfo.original_url : ogInfo.url,
							author_name: ogInfo.site_name,
							title: ogInfo.title,
							image_url: ogInfo.images?.[0]?.url,
							text: ogInfo.description,
						};
				} catch (error) {
					return null;
				}
			},
		);

		return compact(newPreviewUrlImages);
	};

	/** used to send direct or qouted  message */
	/** cannot be used for sending GIFs because they need to be first added to a virtual message list */
	const sendAndReplyMessage = async (attachments: TAttachments[], text?: string, selectedEvent?: TEvent | null) => {
		// stream counting / as command
		while (text?.charAt(0) === '/') {
			text = text.substring(1);
		}

		if (String(text)?.length > POST_MESSAGE_CHAR_LIMIT) {
			throw new Error(
				t('The message you are trying to send exceeds {{limit}} characters limit', { limit: POST_MESSAGE_CHAR_LIMIT }),
			);
		}

		let sendMessageResponse: SendMessageAPIResponse | undefined;
		if (text && attachments.length > 0) {
			sendMessageResponse = await channel.sendMessage({
				text: text ? text : '',
				quoted_message_id: quotedMessageId,
				attachments: uploadAttachments(attachments),
				mentioned_users: getMentionUsers(text),
			});
		} else if (text && selectedEvent) {
			sendMessageResponse = await channel.sendMessage({
				text: text ? text : '',
				quoted_message_id: quotedMessageId,
				eventId: selectedEvent.id,
				mentioned_users: getMentionUsers(text),
			});
		} else if (selectedEvent) {
			sendMessageResponse = await channel.sendMessage({
				quoted_message_id: quotedMessageId,
				eventId: selectedEvent.id,
				text: text ? text : ' ', // Do not remove it. this is weird bug from get stream react native so even if we don't have text then also send blank
				mentioned_users: getMentionUsers(text),
			});
		} else if (text) {
			sendMessageResponse = await channel.sendMessage({
				text: text,
				quoted_message_id: quotedMessageId,
				mentioned_users: getMentionUsers(text),
			});
		} else if (attachments) {
			sendMessageResponse = await channel.sendMessage({
				attachments: uploadAttachments(attachments),
				quoted_message_id: quotedMessageId,
			});
		}

		analytics.trackEvent('ChatInteractions', {
			chat_type: `${chat.getChatType(channel)}-chat`,
			channel_id: String(channel.id),
			contact_id: chat.getOneToOneChatAnotherUserId(channel, user.id),
			chat_message_id: String(sendMessageResponse?.message?.id),
			media_type: selectedEvent
				? 'event'
				: attachments?.[0]?.fileType === 'image' || attachments?.[0]?.fileType === 'video'
				? 'media'
				: attachments?.[0]?.fileType,
		});
		trackMessageSent();
	};

	/** used to  send edited message */
	const sendEditedMessage = async (attachments: TAttachments[], text?: string, selectedEvent?: TEvent | null) => {
		if (editMessage) {
			const filterPreviewUrlImages = attachments?.filter((item) => item.url?.includes(STREAM_CHAT_BASE_URL));
			const newUniquePreviewUrlImages = await enhanceUrlPreviewImages(editMessage, text);

			if (String(text).length > POST_MESSAGE_CHAR_LIMIT) {
				throw new Error(
					t('The message you are trying to send exceeds {{limit}} characters limit', {
						limit: POST_MESSAGE_CHAR_LIMIT,
					}),
				);
			}

			if (text && filterPreviewUrlImages.length > 0) {
				await chat.chatClient.updateMessage({
					id: editMessage.id,
					text,
					attachments: [...uploadAttachments(filterPreviewUrlImages)],
					eventId: null,
					mentioned_users: getMentionUsers(text),
				});
			} else if (selectedEvent || editMessage.eventId) {
				await chat.chatClient.partialUpdateMessage(editMessage.id, {
					set: {
						// T21C-7491 event message doesn't displayed in mobile if no text present.
						text: text || ' ',
						eventId: selectedEvent?.id || null,
						attachments: [...newUniquePreviewUrlImages],
						mentioned_users: getMentionUsers(text),
					},
				});
			} else if (text) {
				await chat.chatClient.partialUpdateMessage(editMessage.id, {
					set: {
						text,
						attachments: [...newUniquePreviewUrlImages],
						eventId: null,
						mentioned_users: getMentionUsers(text),
					},
				});
			} else if (filterPreviewUrlImages) {
				await chat.chatClient.partialUpdateMessage(editMessage.id, {
					set: {
						text: '',
						eventId: null,
						attachments: [...uploadAttachments(filterPreviewUrlImages)],
					},
				});
			}
		}
	};

	const onSendPressed = async ({
		attachments,
		onSuccess,
		text,
		selectedEvent,
	}: {
		attachments: TAttachments[];
		onSuccess: () => void;
		text?: string;
		selectedEvent: TEvent | null;
	}): Promise<boolean> => {
		try {
			if (await checkTextOnMaliciousUrls(String(text))) {
				return false;
			}
			if (chat.isOneToOneWithOneMember(channel)) {
				const contactUserId = chat.extractOtherOneToOneMemberId(channel, user.slug);
				if (contactUserId) {
					await chat.getOrMarkOneToOneChatWithUser(contactUserId, user.slug);
				}
			}
			if (editMessage) {
				await sendEditedMessage(attachments, text, selectedEvent);
				jumpToMessage(editMessage.id);
			} else {
				await sendAndReplyMessage(attachments, text, selectedEvent);
			}
			reset();
			onSuccess();
			return true;
		} catch (error) {
			if (error instanceof Error) {
				showError({
					title: t('Error'),
					subtitle: error.message,
				});
			}
			return false;
		}
	};

	const onGiphySendPressed = async () => {
		analytics.trackEvent('ChatInteractions', {
			chat_type: `${chat.getChatType(channel)}-chat`,
			channel_id: String(channel.id),
			contact_id: chat.getOneToOneChatAnotherUserId(channel, user.id),
			chat_message_id: 'unknown',
			media_type: 'giphy',
		});
		trackMessageSent();
	};

	return {
		onSendPressed,
		onGiphySendPressed,
	};
};
