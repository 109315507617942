import { useTranslation } from '@hooks';
import { resetPasswordFormSchema } from '@schemas';
import { Button, Formik, Input, Form, Gap } from '@ui-kit';
import { UserIcon } from '@ui-kit/icons';
import styles from './ResetPasswordForm.module.css';
import type { TResetPasswordForm } from '@schemas';

const ResetPasswordForm = ({ initialValues = { login: '' }, onSubmit }: IResetPasswordFormProps) => {
	const { t } = useTranslation();

	return (
		<Formik<TResetPasswordForm>
			initialValues={resetPasswordFormSchema.cast(initialValues) as TResetPasswordForm}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={resetPasswordFormSchema}
			onSubmit={async (values) => await onSubmit(values)}
		>
			{({ values, errors, isSubmitting, handleChange, handleBlur, submitForm }) => {
				return (
					<Form>
						<Input
							ariaLabel="Username"
							autoComplete="email"
							disabled={isSubmitting}
							errorMessage={errors.login}
							explanation={t('Please use the provided Username.')}
							icon={<UserIcon className={styles.input__icon} height={12} width={12} />}
							iconPosition="left"
							label={<b>{t('Username')}</b>}
							placeholder={t('Email or Username')}
							value={values.login}
							onBlur={handleBlur('login')}
							onChange={handleChange('login')}
						/>
						<Gap gap={16} />
						<Button
							block="fit"
							loading={isSubmitting}
							title={t('Reset')}
							trackingName="auth--sign-in"
							type="outline"
							onClick={submitForm}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export interface IResetPasswordFormProps {
	initialValues?: TResetPasswordForm;
	onSubmit: (values: TResetPasswordForm) => void;
}

export default ResetPasswordForm;
