import styles from './PostBarAlertsButton.module.css';
import { NotificationOffIcon, NotificationIcon } from '@ui-kit/icons';
import { Button } from '@ui-kit/components';

const PostBarAlertsButton = ({ alerts, alertsTitle, onClickAlerts }: IPostBarAlertsButtonProps) => {
	return (
		<Button
			className={styles.postBar__container}
			icon={
				alerts ? (
					<div className={styles.postBar__alertsIconWrapper}>
						<NotificationOffIcon className={styles.postBar__alertsIcon} height={14} width={22} />
					</div>
				) : (
					<div className={styles.postBar__alertsIconWrapper}>
						<NotificationIcon className={styles.postBar__alertsIcon} height={17} width={12} />
					</div>
				)
			}
			iconPosition="left"
			title={alertsTitle}
			titleClassName={styles.postBar__text}
			type="transparent"
			onClick={() => onClickAlerts?.(!alerts)}
		/>
	);
};

export interface IPostBarAlertsButtonProps {
	alertsTitle?: string;
	alerts?: boolean;
	onClickAlerts?: (alerts: boolean) => void;
}

export default PostBarAlertsButton;
