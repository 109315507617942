import { useService, useMutation, useNotification, useTranslation, useNavigate, useChat } from '@hooks';
import type { TUser } from '@typings';
import { useMessageContext, useChannelStateContext } from 'stream-chat-react';
import { ROUTES } from '@constants';

export const usePauseUserMutation = () => {
	const { showSuccess } = useNotification();
	const { message } = useMessageContext();
	const { channel } = useChannelStateContext();
	const { setChannelHasPausedMembers } = useChat();

	const { t } = useTranslation();
	const chat = useService('ChatService');
	const reactQuery = useService('ReactQueryService');
	const userName = message?.user?.name;
	const navigate = useNavigate();

	const refreshPauseMembers = async () => {
		const pauseMembers = await chat.queryPauseMembers(channel);
		setChannelHasPausedMembers(pauseMembers.length > 0);
	};
	return useMutation<void, Error, TUser['id']>(['chat.pauseUser'], async (userId) => await chat.pauseUser(userId), {
		cacheTime: 0,
		async onSuccess(data, userSlug) {
			await reactQuery.queryClient.setQueriesData(reactQuery.queryKeys.isChatUserPaused(userSlug), true);
			showSuccess({
				title: t('You have paused {{userName}}', { userName }),
				subtitle: t('{{userName}} cannot now resume chat', { userName }),
			});
			refreshPauseMembers();
			navigate(ROUTES.chatChannel(channel.id));
		},
	});
};
