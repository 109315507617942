import { memo } from 'react';
import styles from './PieChartLabel.module.css';
import type { TPieChartLabelRenderProps } from '../../PieChart';

const PieChartLabel = ({ x, y, value, label, labelPosition, labelTranslateXPercent }: IPieChartLabelProps) => {
	return (
		<div className={styles.pieChartLabel} style={{ left: x, top: y }}>
			<div
				className={styles.pieChartLabel__titleBox}
				style={{
					[labelPosition]: 0,
					transform: `translateX(${labelTranslateXPercent}%) translateY(-50%)`,
				}}
			>
				<span className={styles.pieChartLabel__title}>
					<b>{`${value}%`}</b> {label}
				</span>
			</div>
		</div>
	);
};

export interface IPieChartLabelProps extends TPieChartLabelRenderProps {}

export default memo(PieChartLabel);
