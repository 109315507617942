import { useIsMutating, useService } from '@hooks';
import type { TCommunity } from '@typings';

/** Returns true if a create membership mutation is happening. */
export const useIsJoiningNetwork = (networkId: TCommunity['id']): boolean => {
	const { queryKeys } = useService('ReactQueryService');

	return !!useIsMutating(queryKeys.createMembership(), {
		predicate: (mutation) => {
			const mutatingNetworkId = mutation.state.variables as TCommunity['id'];
			return mutatingNetworkId === networkId;
		},
	});
};
