import { useEffect, useLayoutEffect, useUserSessionStorageValue } from '@hooks';
import { useAssetAllocationComparePeriodSearchParams } from '@ui-modules/assetAllocation/hooks/useAssetAllocationComparePeriodSearchParams';
import { useAssetAllocationComparePeriodsQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationComparePeriodsQuery';
import { convertAssetAllocationPeriodToYearValue } from '../utils/convertAssetAllocationPeriodToYearValue';
import { convertAssetAllocationYearValueToPeriod } from '../utils/convertAssetAllocationYearValueToPeriod';

export const useAssetAllocationComparePeriod = ({ enabled }: { enabled: boolean }) => {
	const [selectedComparePeriod, setSelectedComparePeriod] = useAssetAllocationComparePeriodSearchParams();

	const persistedPeriod = useUserSessionStorageValue('asset-allocation-tiger21-period');
	useEffect(
		function persistSelectedComparePeriodWhenChanged() {
			if (selectedComparePeriod) {
				const persistingPeriodValue = String(convertAssetAllocationPeriodToYearValue(selectedComparePeriod));
				persistedPeriod.setStorageSavedValue(persistingPeriodValue);
			} else {
				persistedPeriod.setStorageSavedValue('');
			}
		},
		[selectedComparePeriod],
	);

	const query = useAssetAllocationComparePeriodsQuery(
		{ type: 't21' },
		{
			enabled: enabled,
		},
	);
	const comparePeriods = query.data;

	useLayoutEffect(
		function setInitialTiger21ComparePeriod() {
			if (enabled && !selectedComparePeriod && comparePeriods?.length) {
				try {
					const initialPeriod = persistedPeriod.storageSavedValue
						? convertAssetAllocationYearValueToPeriod(Number(persistedPeriod.storageSavedValue))
						: comparePeriods[0];
					setSelectedComparePeriod(initialPeriod, true);
				} catch (error) {
					setSelectedComparePeriod(comparePeriods[0], true);
				}
			}
		},
		[selectedComparePeriod, comparePeriods, enabled],
	);

	return { ...query, selectedComparePeriod, setSelectedComparePeriod };
};
