import { NavLink } from '@ui-kit';
import { clsx } from '@utils';
import type { ForwardedRef, MouseEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';
import styles from './IconButton.module.css';

const IconButton = (
	{
		linkTo,
		icon,
		iconActive,
		label,
		extraMatch = false,
		forcedActive = false,
		disableHighlight = false,
		showBadge = false,
		onClick,
	}: IIconButtonProps,
	ref: ForwardedRef<HTMLAnchorElement>,
) => {
	return (
		<NavLink
			className={({ isActive }) =>
				clsx(styles.iconButton__link, (isActive || forcedActive) && !disableHighlight && styles.iconButton__link_active)
			}
			end={extraMatch}
			ref={ref}
			to={linkTo}
			onClick={onClick}
		>
			{({ isActive }) => (
				<div className={styles.iconButton__container}>
					<span className={styles.iconButton__iconContainer}>
						{(isActive || forcedActive) && iconActive ? iconActive : icon}
						{showBadge ? <span className={styles.iconButton__unreadBadge} /> : null}
					</span>
					<p
						className={clsx(
							styles.iconButton__label,
							(isActive || forcedActive) && !disableHighlight && styles.iconButton__label_active,
						)}
						title={label}
					>
						{label}
					</p>
				</div>
			)}
		</NavLink>
	);
};

export interface IIconButtonProps {
	linkTo: string;
	label: string;
	icon: ReactNode;
	iconActive?: ReactNode;
	extraMatch?: boolean;
	forcedActive?: boolean;
	disableHighlight?: boolean;
	showBadge?: boolean;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default forwardRef(IconButton);
