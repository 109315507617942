import { useCallback, useTranslation, useMemo, useRef } from '@hooks';
import { FixedWidthContainer, SectionList, UserListItem } from '@ui-kit';
import { useNetworkConnectionMembersQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMembersQuery';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { formatUserSubTitle, pluralizeUtils, lowerCase } from '@utils';
import { mapNetworkConnectionMembersResponseToUserProfileRecordSectionListItems } from '@ui-modules/connections/utils/mapNetworkConnectionMembersResponseToUserProfileRecordSectionListItems';
import styles from './NetworkConnectionMembersPage.module.css';
import type { TNetworkConnection, TUserProfileRecord } from '@typings';
import type { ISectionListHandle, TSectionListGroupFooterLink } from '@ui-kit/components/SectionList/SectionList';
import type { FC } from 'react';

const NetworkConnectionMembersPage = ({
	networkConnection,
	networkConnectionName,
	onClick,
}: INetworkConnectionMembersPageProps) => {
	const { t } = useTranslation();

	const virtuosoListRef = useRef<ISectionListHandle>(null);

	// Fetch data.
	const {
		isInitialLoading,
		data: networkConnectionMembersResponse,
		loadMore,
		removeMore,
	} = useNetworkConnectionMembersQuery(networkConnection.id);
	const { data: memberRelations = [] } = useNetworkConnectionMemberRelationsQuery(networkConnection.community.id);
	const emptyMessage = useMemo(() => {
		const commaSeparatedStatusList = memberRelations
			.filter((relation) => !relation.restriction || relation.restriction === 'all')
			.map((relation) => `“${relation.title}“`)
			.join(', ');
		return t(
			"When a Member indicates they're an {{commaSeparatedStatusList}} on this {{networkConnectionTypeName}}, they'll be listed here.",
			{
				commaSeparatedStatusList,
				networkConnectionTypeName: networkConnectionName,
			},
		);
	}, [memberRelations]);

	// Render list logic.
	const members = useMemo(() => {
		if (!networkConnectionMembersResponse || !memberRelations) return [];
		const result = mapNetworkConnectionMembersResponseToUserProfileRecordSectionListItems(
			networkConnectionMembersResponse,
			(name, totalItems) => {
				const relation = memberRelations.find((relation) => relation.title === name);
				if (relation?.restriction === 'owner') {
					return name;
				} else {
					return `${name} (${totalItems})`;
				}
			},
			loadMore,
			(name) => `Show more ${pluralizeUtils.plural(lowerCase(name))}`,
			(networkConnectionRelationStatus) => {
				removeMore(networkConnectionRelationStatus);
				const sectionIndexToScrollTo = result.findIndex((item) =>
					item.title.startsWith(networkConnectionRelationStatus),
				);
				if (sectionIndexToScrollTo !== -1) {
					setTimeout(() => virtuosoListRef.current?.scrollToSection(sectionIndexToScrollTo), 300);
				}
			},
			(name) => `Show fewer ${pluralizeUtils.plural(lowerCase(name))}`,
		);
		return result;
	}, [networkConnectionMembersResponse, memberRelations]);
	const renderUserItem = useCallback((member: TUserProfileRecord) => {
		const isDisabled = member.roles.includes('ROLE_INACTIVE');
		return (
			<FixedWidthContainer>
				<UserListItem
					avatarSize={40}
					className={styles.networkConnectionMembersPage__userListItem}
					renderUserSubTitle={formatUserSubTitle(t)}
					size="medium"
					userProfileRecord={member}
					onClick={isDisabled ? undefined : onClick}
				/>
			</FixedWidthContainer>
		);
	}, []);
	const renderGroupFooterLink = useCallback(
		(
			GroupFooterLinkComponent: FC<{ footerLink: TSectionListGroupFooterLink; className?: string }>,
			footerLink: TSectionListGroupFooterLink,
		) => (
			<FixedWidthContainer>
				<GroupFooterLinkComponent
					className={styles.networkConnectionMembersPage__userListItem}
					footerLink={footerLink}
				/>
			</FixedWidthContainer>
		),
		[],
	);

	return (
		<SectionList<TUserProfileRecord>
			className={styles.networkConnectionMembersPage__sectionList}
			data={members}
			emptyMessage={emptyMessage}
			headerClassName={styles.networkConnectionMembersPage__header}
			innerRef={virtuosoListRef}
			isLoading={isInitialLoading}
			renderGroupFooterLink={renderGroupFooterLink}
			renderItem={renderUserItem}
		/>
	);
};

export interface INetworkConnectionMembersPageProps {
	networkConnection: TNetworkConnection;
	onClick: (userProfile: TUserProfileRecord) => void;
	networkConnectionName: string;
}

export default NetworkConnectionMembersPage;
