import type { IToastProps } from '../../services/interfaces/ToastService.interface';

export abstract class AbstractApplicationError extends Error {
	constructor(message: string) {
		super(message);
	}

	abstract name: string;
	abstract toastProps?: Omit<IToastProps, 'title' | 'subtitle'>;
}
