import { Button, ExternalLinkIcon } from '@ui-kit';
import { useNavigate, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import styles from './ChatParentButton.module.css';
import type { Channel } from 'stream-chat';
import type { TChannelCommunity, TChannelDeal } from '@typings';

const ChatParentButton = ({ channel }: IChatParentButtonProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { id: communityId, definition } = (channel?.data?.community ?? {}) as TChannelCommunity;
	const isNetworkChannel = definition === 'network';
	const isChapterChannel = definition === 'chapter';
	const isGroupChannel = definition === 'group';
	const { id: dealId } = (channel?.data?.deal ?? {}) as TChannelDeal;
	const isDealChannel = !!dealId;

	if (isNetworkChannel)
		return (
			<Button
				icon={<ExternalLinkIcon className={styles.chatParentButton__icon} />}
				iconPosition="right"
				title={t('Discussions')}
				variant="small"
				onClick={() => {
					navigate(ROUTES.networkTab(communityId, 'discussions'));
				}}
			/>
		);
	if (isChapterChannel)
		return (
			<Button
				icon={<ExternalLinkIcon className={styles.chatParentButton__icon} />}
				iconPosition="right"
				title={t('Chapter')}
				variant="small"
				onClick={() => {
					navigate(ROUTES.chapterTab(communityId));
				}}
			/>
		);
	if (isGroupChannel)
		return (
			<Button
				icon={<ExternalLinkIcon className={styles.chatParentButton__icon} />}
				iconPosition="right"
				title={t('Group')}
				variant="small"
				onClick={() => {
					navigate(ROUTES.groupTab(communityId, 'members'));
				}}
			/>
		);
	else if (isDealChannel) {
		return (
			<Button
				icon={<ExternalLinkIcon className={styles.chatParentButton__icon} />}
				iconPosition="right"
				title={t('Deal')}
				variant="small"
				onClick={() => {
					navigate(ROUTES.dealTabs(dealId));
				}}
			/>
		);
	} else return null;
};

export interface IChatParentButtonProps {
	channel: Channel;
}

export default ChatParentButton;
