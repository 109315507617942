import { useTranslation } from '@hooks';
import { Button, Card, Gap } from '@ui-kit';
import { parseLocationFromGroupName } from '@utils';
import styles from './UpcomingGroupCard.module.css';
import type { TCommunity } from '@typings';

/** Card displaying that upcoming group is still under development. */
const UpcomingGroupCard = ({ groupName, onNominateMember }: IUpcomingGroupCardProps) => {
	const { t } = useTranslation();
	const location = parseLocationFromGroupName(groupName);

	return (
		<Card
			articleMode="transparent"
			backgroundPlacement="article"
			className={styles.card}
			title={t('We’re still building {{groupName}}', { groupName })}
			titleAs="h4"
		>
			<div>
				<span>
					{t(
						'Know someone in {{location}} who would make a great Member? Help complete this group with your nomination.',
						{
							location,
						},
					)}
				</span>
			</div>
			<Gap gap={40} />
			<div className={styles.upcomingGroupCard__buttonBox}>
				<Button
					block="fit"
					title={t('Nominate a new member')}
					type="outline"
					variant="medium"
					onClick={onNominateMember}
				/>
			</div>
		</Card>
	);
};

export interface IUpcomingGroupCardProps {
	/** The name of the group. */
	groupName: TCommunity['name'];
	/** Is invoked when "nominate a new member" button is pressed. */
	onNominateMember: () => void;
}

export default UpcomingGroupCard;
