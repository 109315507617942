import MeatballMenu from '../MeatballMenu';
import FileTypeAvatar from '../FileTypeAvatar';
import ActivityIndicator from '../ActivityIndicator';
import { DownloadIcon, RightArrowIcon, CloseIcon } from '@ui-kit/icons';
import { clsx, dayjs, FileEntity } from '@utils';
import styles from './FileListItem.module.css';
import type { TFile, TMediaObject } from '@typings';
import type { TMeatballMenuOption } from '../MeatballMenu';
import type { FC, SVGProps } from 'react';
import RibbonFlag from '../RibbonFlag';

export type TFileItemMeta = {
	createdAt: string;
	owner: { firstName: string; lastName: string } | null;
	mimeType: string;
	encrypted?: boolean;
	fileName: string;
};

export const extractMetaFromFile = (file: TFile): TFileItemMeta => {
	return {
		createdAt: file.createdAt,
		owner: file.owner ? { firstName: file.owner.firstName, lastName: file.owner.lastName } : null,
		mimeType: file.mediaObject.mimeType,
		encrypted: file.mediaObject.encrypted,
		fileName: FileEntity.getFileNameV2(file),
	};
};

export const extractMetaFromMediaObject = (mediaObject: TMediaObject): TFileItemMeta => {
	return {
		createdAt: `${mediaObject.createdAt}`,
		owner: mediaObject.owner,
		mimeType: mediaObject.mimeType,
		encrypted: mediaObject.encrypted,
		fileName: mediaObject.originalName,
	};
};

const FileListItemControlPanel = ({
	openInViewerOnly,
	miniDesignMode,
	isLoading,
	showDownloadIcon,
	showOpenIcon,
	fileMenuOptions,
	OpenIconComponent = RightArrowIcon,
	viewerModeText,
}: IFileListItemControlPanelProps) => {
	return (
		<>
			<div className={styles.fileListItem__controlPanel}>
				{openInViewerOnly && miniDesignMode && viewerModeText ? (
					<span className={styles.ribbonText}>{`(${viewerModeText})`}</span>
				) : null}
				{isLoading ? (
					<ActivityIndicator size="small" />
				) : (
					<>
						{showDownloadIcon ? <DownloadIcon fill="#000000" /> : null}
						{showOpenIcon ? <OpenIconComponent fill="#000000" /> : null}
						{fileMenuOptions?.length ? (
							<MeatballMenu options={fileMenuOptions} position="bottom" testID="MeatballMenu" />
						) : null}
					</>
				)}
			</div>
			{openInViewerOnly && !miniDesignMode && viewerModeText ? (
				<div className={styles.ribbonContainer}>
					<RibbonFlag
						className={styles.ribbon}
						color={'#F2F2F2'}
						direction="left"
						size="small"
						title={viewerModeText}
					/>
				</div>
			) : null}
		</>
	);
};

/** List component for displaying file information. */
const FileListItem = ({
	meta,
	type = 'listItem',
	fileMenuOptions,
	showDownloadIcon = false,
	showOpenIcon = false,
	OpenIconComponent,
	isLoading = false,
	withSeparator = false,
	onClick,
	className,
	miniDesignMode = false,
	openInViewerOnly = false,
	viewerModeText,
	iconWidth,
	iconHeight,
	onRemove,
}: IFileListItemProps) => {
	const ownerInfo =
		meta.owner?.firstName?.length || meta.owner?.lastName?.length
			? `by ${meta.owner.firstName} ${meta.owner.lastName}`
			: '';
	const fileInfo = meta.createdAt ? `${dayjs(meta.createdAt).format('MMM DD YYYY, h:mmA')} ${ownerInfo}` : '';
	const handleClick = () => !isLoading && onClick?.();

	return (
		<div
			className={clsx(
				className,
				styles.fileListItem,
				miniDesignMode && styles.fileListMiniItem,
				withSeparator && styles.fileListItem_withSeparator,
				!!onClick && !isLoading && styles.fileListItem_clickable,
			)}
			data-type={type}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			{onRemove && (
				<button className={styles.fileListItem__removeButton} onClick={onRemove}>
					<CloseIcon height={8} width={8} />
				</button>
			)}
			<div className={styles.fileListItem__header}>
				<FileTypeAvatar
					height={iconHeight}
					mimeType={meta.mimeType}
					secure={Boolean(meta.encrypted)}
					width={iconWidth}
				/>
			</div>
			<div className={styles.fileListItem__body}>
				<span className={clsx(styles.fileListItem__title, !miniDesignMode && styles.fileListItem__boldTitle)}>
					{meta.fileName}
				</span>
				{fileInfo && !miniDesignMode ? <small className={styles.fileListItem__subTitle}>{fileInfo}</small> : null}
			</div>
			<FileListItemControlPanel
				fileMenuOptions={fileMenuOptions}
				isLoading={isLoading}
				miniDesignMode={miniDesignMode}
				OpenIconComponent={OpenIconComponent}
				openInViewerOnly={openInViewerOnly}
				showDownloadIcon={showDownloadIcon}
				showOpenIcon={showOpenIcon}
				viewerModeText={viewerModeText}
			/>
		</div>
	);
};
export type TFileListItemType = 'listItem' | 'card' | 'fit-card';
export interface IFileListItemProps extends IFileListItemControlPanelProps {
	/** How to show it: 'listItem' - as row in a list, 'card' - as independent card item, 'fit-card' - the same as card but with full width. Default 'listItem'. */
	type?: TFileListItemType;
	/** If 'true' border bottom will be shown as a separator. Default 'false'. */
	withSeparator?: boolean;
	/** Called when the item clicked. */
	onClick?: () => void;
	/** Class name of the file item wrapper */
	className?: string;
	/** File meta data */
	meta: TFileItemMeta;
	/** Remove item handler*/
	onRemove?: () => void;
	/** Custom width for the FileTypeAvatar icon */
	iconWidth?: number;
	/** Custom height for the FileTypeAvatar icon */
	iconHeight?: number;
}

interface IFileListItemControlPanelProps {
	/** Is file should be visible in app viewer instead of downloading */
	openInViewerOnly?: boolean;
	/** Text shat should be displayed for file that allowed to view only */
	viewerModeText?: string;
	/** For some places (Deal Files for example) we need a new Chris design for File items */
	miniDesignMode?: boolean;
	/** Should the activity indicator be shown. If "true" the item can't be clicked. Default false. */
	isLoading?: boolean;
	/** If present will show the MeatballMenu. */
	fileMenuOptions?: TMeatballMenuOption[];
	/** Should the download icon be showed on the right side of the item. Default false. */
	showDownloadIcon?: boolean;
	/** Should the open icon be showed on the right side of the item. Default false. */
	showOpenIcon?: boolean;
	/** SVG icon showed on the right side when the "showOpenIcon" prop is 'true'. */
	OpenIconComponent?: FC<SVGProps<SVGSVGElement>>;
}

export default FileListItem;
