import type { TDynamicField } from '@typings';
import { omit } from '@utils';

export function collectNetworkConnectionContentWhichMayHasUrls<T extends Record<string, unknown>>(
	record: T,
	omitFields: Array<keyof T> = ['dynamicFields', 'categories'],
): string {
	try {
		// Static filed array of values
		const pickedFields = omit(record, omitFields);
		const pickedFieldValues = Object.values(pickedFields);

		// Dynamic filed array of values
		const dynamicFields = record?.dynamicFields as TDynamicField[];
		const dynamicFieldValues =
			dynamicFields?.filter((el) => el.type === 'string' || el.type === 'link').map((filed) => filed.value) || [];

		// Sting with all values
		const joinedFieldValues = [...pickedFieldValues, ...dynamicFieldValues].join('   ');
		return joinedFieldValues;
	} catch (error) {
		console.error(error);
		return ' ';
	}
}
