import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TDeal } from '@typings';
import type { Channel } from 'stream-chat';

export const useJoinDealChatMutation = (options?: MutationOptions<Channel, Error, TPartialDeal>) => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const analytics = useService('AnalyticsService');

	const chat = useService('ChatService');

	return useMutation<Channel, Error, TPartialDeal>(
		['joinDealChat'],
		async (deal) => {
			await api.invest.joinDealChat(deal.id);
			const channel = await chat.getChannel(`${deal?.slug}`);
			if (!channel) throw Error('Channel not found');
			return channel;
		},
		{
			onSuccess: (response, deal, context) => {
				options?.onSuccess?.(response, deal, context);
				analytics.trackEvent('DealInteractions', {
					deal_id: deal.id,
					deal_name: deal.name,
					interaction_type: 'details',
					interaction_action: 'chat opened',
				});
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TPartialDeal = Pick<TDeal, 'slug' | 'id' | 'name'>;
