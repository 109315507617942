import { useService, useMutation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TDeal, TFile, TFolder } from '@typings';

export const useDeleteDealFileMutation = (
	dealId: TDeal['id'],
	folderId: TFolder['id'] | null,
	options?: MutationOptions<void, Error, TFile['id']>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFile['id']>(
		['dealFile.deleteDealFile'],
		async (fileId) => await api.dealFile.deleteDealFile(fileId),
		{
			...options,
			async onSuccess(data, variables, context) {
				await reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getDealFiles(dealId, folderId));
				options?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
