import type { TCommunityMember, TUserProfileRecord, TChapterMember } from '@typings';
import {
	adaptChapterMemberToUserProfileRecord,
	adaptCommunityMemberToUserProfileRecord,
	breakdownListForVirtuosoSections,
} from '@utils';
import type { TFunction } from 'i18next';

export const formatParticipantsForVirtuosoSectionList =
	(t: TFunction) =>
	(moderators: TCommunityMember[] = [], chapterMembers: TChapterMember[] = []) => {
		const participants = [...moderators, ...chapterMembers];
		const { groups, groupCounts } = breakdownListForVirtuosoSections(participants, (participant) => {
			// duck type is participant moderator
			if ('userSlug' in participant) {
				return t('Chairs and support');
			} else if (!('userSlug' in participant)) {
				return participant.communityName;
			} else return 'unknown'; // to satisfy typescript
		});
		const userProfileRecords = participants.map((participant) => {
			// duck type is participant moderator
			if ('userSlug' in participant) {
				return adaptCommunityMemberToUserProfileRecord(participant);
			} else if (!('userSlug' in participant)) {
				return adaptChapterMemberToUserProfileRecord(participant);
			} else return null as unknown as TUserProfileRecord; // to satisfy typescript
		});

		return {
			userProfileRecords,
			groupCounts,
			groups,
		};
	};
