import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TNetworkConnection } from '@typings';

export const useDeleteNetworkConnectionMutation = (
	networkConnectionName?: string,
	options?: MutationOptions<void, Error, TNetworkConnection>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<void, Error, TNetworkConnection>(
		reactQuery.queryKeys.deleteNetworkConnection(),
		async (networkConnection) => {
			await api.networkConnection.deleteNetworkConnection(networkConnection.id);
		},
		{
			...options,
			onSuccess: async (response, networkConnection, context) => {
				await reactQuery.removeNetworkConnectionFromCache(networkConnection.id, networkConnection.community.id);
				await reactQuery.filterEntityFromAlgoliaSearchCache(
					reactQuery.queryKeys.searchByFeedNCActivities(networkConnection.community.id),
					networkConnection.id,
				);
				showSuccess({ title: t('{{networkConnectionName}} Deleted', { networkConnectionName }) });
				options?.onSuccess?.(response, networkConnection, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
