// NOTE: We created this custom component from Stream's default MessageOptions. Please refer
// the original component when editing this component or adding new features to it. Because
// we can learn things like "how to support threaded replies" from the original component.
// SEE: https://github.com/GetStream/stream-chat-react/blob/v10.4.1/src/components/Message/MessageOptions.tsx

import React from 'react';
import {
	ReactionIcon as DefaultReactionIcon,
	ThreadIcon as DefaultThreadIcon,
	useMessageContext,
} from 'stream-chat-react';

import type { MessageContextValue } from 'stream-chat-react';

import type { DefaultStreamChatGenerics, IconProps } from 'stream-chat-react/dist/types/types';
import type { MessageResponse } from 'stream-chat/dist/types/types';

import MessageMenu from '@ui-modules/chat/components/MessageMenu';
import { useMutateMessageMeatballMenuOptions } from '@ui-modules/chat/hooks/useMutateMessageMeatballMenuOptions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type MessageOptionsProps<StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics> =
	Partial<Pick<MessageContextValue<StreamChatGenerics>, 'handleOpenThread'>> & {
		/* Custom component rendering the icon used in message actions button. This button invokes the message actions menu. */
		ActionsIcon?: React.ComponentType<IconProps>;
		/* If true, show the `ThreadIcon` and enable navigation into a `Thread` component. */
		displayReplies?: boolean;
		/* React mutable ref that can be placed on the message root `div` of MessageActions component */
		messageWrapperRef?: React.RefObject<HTMLDivElement>;
		/* Custom component rendering the icon used in a button invoking reactions selector for a given message. */
		ReactionIcon?: React.ComponentType<IconProps>;
		/* Theme string to be added to CSS class names. */
		theme?: string;
		/* Custom component rendering the icon used in a message options button opening thread */
		ThreadIcon?: React.ComponentType<IconProps>;
		shouldHideReactions?: boolean | null;
	};

// eslint-disable-next-line @typescript-eslint/naming-convention
const UnMemoizedMessageOptions = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
	props: MessageOptionsProps<StreamChatGenerics>,
) => {
	const {
		handleOpenThread: propHandleOpenThread,
		ReactionIcon = DefaultReactionIcon,
		theme = 'simple',
		ThreadIcon = DefaultThreadIcon,
	} = props;

	const {
		handleOpenThread: contextHandleOpenThread,
		initialMessage,
		message,
		onReactionListClick,
		isMyMessage,
	} = useMessageContext<StreamChatGenerics>('MessageOptions');

	const handleOpenThread = propHandleOpenThread || contextHandleOpenThread;

	const shouldShowReactions = props.shouldHideReactions ? !props.shouldHideReactions : true;
	const shouldShowReplies = false;

	const [meatballMenuOptions, ConfirmationModals] = useMutateMessageMeatballMenuOptions(message as MessageResponse);

	if (
		!message.type ||
		message.type === 'error' ||
		message.type === 'system' ||
		message.type === 'ephemeral' ||
		message.status === 'failed' ||
		message.status === 'sending' ||
		initialMessage
	) {
		return null;
	}

	const rootClassName = `str-chat__message-${theme}__actions str-chat__message-options`;

	return (
		<div className={rootClassName} data-testid="message-options">
			{/* TODO: In a future UI improvement jira, make the message menu use a nicer looking `...` icon,
			because Stream's `ActionsIcon` looks better than our custom `...` icon. We cannot do this
			as part of T21C-3021 because current `...` look of our MeatballMenu affects many other places.*/}
			{meatballMenuOptions && (
				<MessageMenu
					isMyMessage={isMyMessage()}
					message={message as MessageResponse}
					options={meatballMenuOptions}
					testID="Message__MeatballMenu"
				/>
			)}
			{shouldShowReplies && (
				<button
					aria-label="Open Thread"
					className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--thread str-chat__message-reply-in-thread-button`}
					data-testid="thread-action"
					onClick={handleOpenThread}
				>
					<ThreadIcon className="str-chat__message-action-icon" />
				</button>
			)}
			{shouldShowReactions && (
				<button
					aria-label="Open Reaction Selector"
					className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--reactions str-chat__message-reactions-button`}
					data-testid="message-reaction-action"
					onClick={onReactionListClick}
				>
					<ReactionIcon className="str-chat__message-action-icon" />
				</button>
			)}
			{ConfirmationModals}
		</div>
	);
};

export const MessageOptions = React.memo(UnMemoizedMessageOptions) as typeof UnMemoizedMessageOptions;
