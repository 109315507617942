import { useMemo, useQuery, useService } from '@hooks';

export const useNetworksNotificationSettings = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { data: communitiesNotificationSettings, isLoading } = useQuery(
		reactQuery.queryKeys.getCommunitiesNotificationSettings(),
		async () => await api.notificationSettings.getCommunitiesNotificationSettings(),
	);

	const networkNotificationSettings = useMemo(() => {
		if (!communitiesNotificationSettings) return [];
		// Response includes all type of communities. Need to show only networks with discussions enabled.
		return communitiesNotificationSettings.filter(
			(community) =>
				community.communityDefinition === 'network' && community.communityTypeFeatures.includes('discussions'),
		);
	}, [communitiesNotificationSettings]);

	return {
		networkNotificationSettings,
		isNetworkNotificationSettingsLoading: isLoading,
	};
};
