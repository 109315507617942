import { IdxStatus } from '@okta/okta-auth-js';
import { OktaError } from './AbstractOktaError';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaSetNewPasswordRequiredError extends OktaError {
	status = 'NEW_PASSWORD_REQUIRED';
	getMessage(t: TFunction) {
		return {
			title: t('New password'),
			subtitle: t('Input new password'),
		};
	}

	static shouldThrow(transaction: IdxTransaction) {
		return transaction.status === IdxStatus.PENDING && transaction.nextStep?.name === 'reset-authenticator';
	}
}
