import { AuthenticatorKey } from '@okta/okta-auth-js';
import type { IdxAuthenticator } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export const translateFactorSentResult = (t: TFunction) => (authenticator: IdxAuthenticator | undefined) => {
	if (!authenticator) return '';
	switch (authenticator.key) {
		case AuthenticatorKey.OKTA_EMAIL:
			return t('Login Code sent to {{target}}', { target: authenticator.profile?.email ?? t('your email address') });
		case AuthenticatorKey.PHONE_NUMBER:
			return t('Login Code sent to {{target}}', {
				target: authenticator.profile?.phoneNumber ?? t('your phone number'),
			});
	}
};
