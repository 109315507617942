/** Helps to figure out group counts to pass into react-virtuoso list and letters to render group headers. */
export const breakdownListForVirtuosoSections = <T extends Record<string, unknown>>(
	sortedRecords: T[] = [],
	getSection: (record: T) => string,
) => {
	const groups: string[] = [];
	const groupCounts: number[] = [];

	for (const record of sortedRecords) {
		const currentGroup = getSection(record);

		if (groups[groups.length - 1] === currentGroup) {
			groupCounts[groupCounts.length - 1]++;
		} else {
			groups.push(currentGroup);
			groupCounts.push(1);
		}
	}

	return { groupCounts, groups };
};
