import { useTranslation } from '@hooks';
import { Modal } from '@ui-kit';
import { useDeleteFileMutation } from '@ui-modules/files/hooks/useDeleteFileMutation';
import { noop } from '@utils';
import type { IModalProps } from '@ui-kit';
import type { TFile, TFolder } from '@typings';
import type { TCommunity } from '@typings';

/** Confirms deleting of an activity and performs it. */
const DeleteFileModal = ({
	visible,
	fileId,
	folderId,
	communityId,
	onClose,
	onSuccess = noop,
}: IDeleteFileModalProps) => {
	const { t } = useTranslation();

	const { mutate: deleteFile, isLoading } = useDeleteFileMutation(communityId, folderId, {
		onSuccess: () => {
			onSuccess?.();
			onClose();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('This file will be permanently deleted.')}
			title={t('Are you sure you want to delete?')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				deleteFile(fileId);
			}}
		/>
	);
};

export interface IDeleteFileModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Community ID. */
	communityId: TCommunity['id'];
	/** Target File ID. */
	fileId: TFile['id'];
	/** Target Folder ID. */
	folderId: TFolder['id'] | null;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeleteFileModal;
