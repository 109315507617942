import { Button, RightArrowIcon } from '@ui-kit';
import { useCallback, useService, useState, useTranslation } from '@hooks';
import dealsImage from '@ui-modules/deals/assets/deals-white.png';
import { useNavigate } from '@hooks';

import styles from './ReviewSolicitationWaiverMessage.module.css';
import { useSolicitationWaiver } from '@ui-modules/deals/hooks/useSolicitationWaiver';
import SolicitationWaiverModal from '../SolicitationWaiverModal';
import { ROUTES } from '@constants';

const ReviewSolicitationWaiverMessage = ({ onSubmit }: IReviewSolicitationWaiverMessageProps) => {
	const { t } = useTranslation();
	const { isFirstTimeRequest, latestWaiver } = useSolicitationWaiver();
	const [isModalShown, setIsModalShown] = useState(false);
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const onReview = () => {
		setIsModalShown(true);
		analytics.trackEvent('DealsSolicitationWaiverViewed', {
			action: 'waiver_reviewed',
		});
	};
	const onAgree = useCallback(() => {
		setIsModalShown(false);
		onSubmit();
		analytics.trackEvent('DealsSolicitationWaiverViewed', {
			action: 'waiver_agreed',
		});
	}, [onSubmit]);

	const onDecline = useCallback(() => {
		setIsModalShown(false);
		analytics.trackEvent('DealsSolicitationWaiverViewed', {
			action: 'waiver_declined',
		});
	}, []);

	return (
		<>
			{isModalShown && latestWaiver && (
				<SolicitationWaiverModal
					content={latestWaiver.body}
					version={latestWaiver?.version}
					onAgree={onAgree}
					onClose={onDecline}
				/>
			)}
			<div className={styles.waiverMessage}>
				<img alt="" src={dealsImage} />
				<h2 className={styles.waiverMessage__title}>
					{isFirstTimeRequest ? t(`Deals are Waiting`) : t(`Review Updated Solicitation Waiver`)}
				</h2>
				<span>
					{isFirstTimeRequest
						? t('To see Deals, read and confirm the Solicitation Waiver.')
						: t('To see Deals, read and confirm the updated Solicitation Waiver.')}
				</span>
				<Button
					className={styles.waiverMessage__button}
					title={t('Review Solicitation Waiver')}
					variant="medium"
					onClick={onReview}
				/>
				{isFirstTimeRequest && (
					<Button
						className={styles.waiverMessage__linkBtn}
						icon={<RightArrowIcon className={styles.arrowIcon} height={14} width={14} />}
						iconPosition="right"
						title={t('or, disable Invest in Settings')}
						type="clear-light"
						variant="medium"
						onClick={() => navigate(ROUTES.dealSettings())}
					/>
				)}
			</div>
		</>
	);
};

interface IReviewSolicitationWaiverMessageProps {
	onSubmit: () => void;
}

export default ReviewSolicitationWaiverMessage;
