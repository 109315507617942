import { useService } from '@services';
import { useQuery } from '@hooks';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';

export const useGetDealTypesWithIdsQuery = (dealTypeIds: string[]) => {
	const api = useService('ApiService');
	const { enabled } = useDealsEnabled();

	const { data, isLoading, isFetched, isRefetching } = useQuery(
		['getDealTypesWithIdsQuery', dealTypeIds],
		async () => await Promise.all(dealTypeIds.map((item) => api.dealType.getType(item))),
		{ enabled },
	);

	return { data, isLoading, isFetched, isRefetching };
};
