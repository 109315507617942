import { useState } from '@hooks';
import type { TPhoneContactType } from '@typings';
import { AddIcon, ChevronRightIcon, ModalContainer, RemoveIcon, When } from '@ui-kit';
import clsx from 'clsx';
import type { FieldArrayRenderProps } from 'formik';
import type { ReactNode } from 'react';
import styles from './LabeledArrayField.module.css';
import { useAvailableTypes } from './useAvailableTypes';

const DEFAULT_TRANSLATE_TYPE: ILabeledArrayFieldProps['translateType'] = (type) => type;

const LabeledArrayField = ({
	options = [],
	types = [],
	addButtonTitle,
	addButtonHidden = false,
	arrayHelpers,
	emptyValue,
	children,
	translateType = DEFAULT_TRANSLATE_TYPE,
	canRemoveOption = () => true,
	modalTitle,
	getFieldError = () => '',
	onOptionRemoved = () => null,
}: ILabeledArrayFieldProps) => {
	const removeItemByIndex = (index: number) => {
		arrayHelpers.remove(index);
		onOptionRemoved();
	};
	const availableTypes = useAvailableTypes(options, types);
	const [showModal, setShowModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState<{ option: TBaseOption; index: number } | null>(null);

	const replaceLabel = (option: TBaseOption, index: number) => {
		setShowModal(true);
		setSelectedOption({ option, index });
	};

	const addNewItemWithSelectedLabel = () => {
		setShowModal(true);
	};

	const onOptionSelect = (type: string) => {
		selectedOption
			? arrayHelpers.replace(selectedOption.index, { ...selectedOption.option, type })
			: arrayHelpers.push({ type, ...emptyValue });
		setSelectedOption(null);
		setShowModal(false);
	};

	return (
		<div className={styles.fields}>
			<div className={styles.fields__wrapper}>
				{options.map((option, index) => {
					const error = getFieldError(index);
					return (
						<div
							className={clsx(!!error && styles.field_err, !!error && 'fieldErrorMessage__active')}
							key={option.type}
						>
							<div className={clsx(styles.field, !!error && styles.field_borderless)}>
								<div className={styles.field__leftContent}>
									<div className={styles.field__fieldButtons}>
										{canRemoveOption(option) && (
											<button className={styles.field__button_remove} onClick={() => removeItemByIndex(index)}>
												<RemoveIcon height={18} width={18} />
											</button>
										)}
										<button
											className={styles.field__button_type}
											disabled={!canRemoveOption(option)}
											onClick={() => replaceLabel(option, index)}
										>
											<span className={styles.field__type}>{translateType(option.type)}</span>
										</button>
									</div>
									<ChevronRightIcon className={styles.field__chevronIcon} height={18} width={7} />
								</div>
								{children(option, index)}
							</div>
							<When condition={!!error}>
								<span className={styles.field__error}>{error}</span>
							</When>
						</div>
					);
				})}
			</div>
			<When condition={!addButtonHidden}>
				<button
					className={styles.field__button_add}
					disabled={!availableTypes.length}
					onClick={addNewItemWithSelectedLabel}
				>
					<AddIcon height={18} width={18} />
					<span className={styles.field__buttonTitle_add}>{addButtonTitle}</span>
				</button>
			</When>
			<ModalContainer
				position="top"
				style="transparent"
				title={modalTitle}
				variant="small"
				visible={showModal}
				onClose={() => setShowModal(false)}
			>
				<div className={styles.modal}>
					{availableTypes.map((label) => (
						<button className={styles.modal__button} key={label} onClick={() => onOptionSelect(label)}>
							{translateType(label as TPhoneContactType)}
						</button>
					))}
				</div>
			</ModalContainer>
		</div>
	);
};

export type TBaseOption = { type: string };

export interface ILabeledArrayFieldProps {
	addButtonTitle: string;
	addButtonHidden?: boolean;
	modalTitle?: string;
	options: Array<TBaseOption>;
	types: string[];
	arrayHelpers: FieldArrayRenderProps;
	emptyValue?: object;
	children: (option: TBaseOption, index: number) => ReactNode;
	translateType?: (type: string) => string;
	canRemoveOption?: (option: TBaseOption) => boolean;
	getFieldError?: (index: number) => string;
	onOptionRemoved?: () => void;
}

export default LabeledArrayField;
