import { useService, useQuery } from '@hooks';
import type { TLatestActivity, TUser } from '@typings';

export const useLatestActivities = (userId: TUser['id'] | undefined) => {
	const api = useService('ApiService');

	return useQuery<TLatestActivity[], Error>(
		['event.useLatestActivities', userId],
		async () => {
			return api.streamFeeds.getLatestActivities({ owner: userId as TUser['id'] });
		},
		{
			cacheTime: 0,
			staleTime: 0,
			enabled: !!userId,
		},
	);
};
