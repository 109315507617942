import type { ComponentType, SVGProps } from 'react';
import React from 'react';
import styles from './AllocationsList.module.css';

const AllocationsList = ({ allocations }: IAllocationsListProps) => {
	return (
		<div className={styles.wrapper}>
			{allocations.map(({ Icon, title, color }, index) => {
				return (
					<div className={styles.wrapper_allocation} key={index}>
						<Icon fill={color} height={32} width={40} />
						<p style={{ color }}>{title}</p>
					</div>
				);
			})}
		</div>
	);
};

export interface IAllocationsListProps {
	allocations: {
		Icon: ComponentType<SVGProps<SVGSVGElement>>;
		title: string;
		color: string;
	}[];
}

export default AllocationsList;
