import { useEffect, useTranslation, useRef } from '@hooks';
import { useImpersonate } from '@ui-modules/auth/hooks/useImpersonate';
import { APP_HEADER_HEIGHT_PX } from '@constants';
import styles from './ImpersonateBanner.module.css';

const ImpersonateBanner = () => {
	const { t } = useTranslation();
	const { impersonated, personateBack } = useImpersonate();
	const initiallyImpersonated = useRef(impersonated).current; // to avoid banner flickering on enable impersonation

	useEffect(
		function syncBannerHeightWithAppHeaderHeight() {
			const usualHeaderHeight = APP_HEADER_HEIGHT_PX;
			const bannerHeight = impersonated ? 34 : 0;
			const headerHeight = usualHeaderHeight + bannerHeight;
			const rootElement = document.querySelector(':root') as HTMLElement;
			rootElement.style.setProperty('--header-height', `${headerHeight}px`);
		},
		[initiallyImpersonated],
	);

	if (!initiallyImpersonated) return null;
	return (
		<div className={styles.impersonateBanner}>
			<span className={styles.impersonateBanner__text}>
				{t('Viewing as {{userName}}', { userName: initiallyImpersonated })}
			</span>
			<button className={styles.impersonateBanner__endButton} type="button" onClick={() => personateBack()}>
				{t('End')}
			</button>
		</div>
	);
};

export default ImpersonateBanner;
