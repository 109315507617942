import { useService, useQuery, useMe, useNotification, useMemo, useEvent, useMutation } from '@hooks';
import { dayjs } from '@utils';
import type { TCommunity, TNotificationRequest } from '@typings';

export const useNotifyWhenAssetAllocationReady = (communityId: TCommunity['id']) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();
	const analytics = useService('AnalyticsService');

	const { data: notificationRequests = [], isLoading } = useQuery<TNotificationRequest[], Error>(
		['notificationRequests'],
		async () => await api.notificationRequest.getNotificationRequests(),
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
	const notificationRequestId = useMemo(
		() =>
			notificationRequests.find(
				(notificationRequest) =>
					notificationRequest.verb === 'aa_comparison_available' &&
					notificationRequest.meta.targetEntityId === communityId,
			)?.id,
		[notificationRequests],
	);

	const { mutate: addNotificationRequest, isLoading: isAdding } = useMutation<TNotificationRequest, Error>(
		['notificationRequests', 'create'],
		async () =>
			await api.notificationRequest.createAssetAllocationNotificationRequest({ userId: user.id, communityId }),
		{
			onSuccess: (notificationRequest) => {
				reactQuery.queryClient.setQueryData(['notificationRequests'], (data: TNotificationRequest[] = []) => [
					...data,
					notificationRequest,
				]);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	const { mutate: deleteNotificationRequest, isLoading: isDeleting } = useMutation<void, Error>(
		['notificationRequests', 'delete'],
		async () => {
			if (!notificationRequestId) throw new Error('Notification request ID not found');
			await api.notificationRequest.deleteNotificationRequest(notificationRequestId);
		},

		{
			onSuccess: () => {
				reactQuery.queryClient.setQueryData(['notificationRequests'], (data: TNotificationRequest[] = []) =>
					data.filter((notificationRequest) => notificationRequest.id !== notificationRequestId),
				);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	const notificationEnabled = !!notificationRequestId;
	const setNotificationEnabled = useEvent((notificationShouldBeEnabled: boolean) => {
		if (notificationShouldBeEnabled) {
			addNotificationRequest();
			analytics.trackEvent('AssetAllocationInteractions', {
				interaction_type: 'notify_me_enabled',
				tab_name: communityId,
			});
		} else {
			deleteNotificationRequest();
			analytics.trackEvent('AssetAllocationInteractions', {
				interaction_type: 'notify_me_disabled',
				tab_name: communityId,
			});
		}
	});

	return {
		notificationEnabled,
		setNotificationEnabled,
		isLoading,
		isMutating: isAdding || isDeleting,
	};
};
