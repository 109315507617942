import { useMe, useMutation, useService } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import type { TActivity, TComment } from '@typings';

/** Executes toggle of your like on the certain activity.
 *  The hook should be placed within FeedContext!
 */
export const useToggleCommentLikeMutation = (activity: TActivity) => {
	const { user } = useMe();
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { onAddChildReaction, onRemoveChildReaction } = useFeedContext();
	const { queryClient, queryKeys } = useService('ReactQueryService');

	return useMutation(
		async (comment: TComment) => {
			// Using built-in "onToggleChildReaction('like', comment)" causes issue with ability to provide unlimited amount of likes to the one comment if go back and open the post again (@see T21C-7824) [@DmitriyNikolenko].
			const likeId = comment.latest_children?.like?.find((like) => like.user?.data?.userId === user.id)?.id;
			if (likeId) {
				await onRemoveChildReaction('like', comment, likeId);
			} else {
				await onAddChildReaction('like', comment);
			}
		},
		{
			onSuccess(data, comment) {
				queryClient.refetchQueries(queryKeys.getPostActivityReactions(activity.id));

				algoliaAnalytics.trackEvent('User Interacted', {
					replyOwnerId: user.id,
					discussionOwnerId: activity.actor.data.userId,
				});
				algoliaAnalytics.trackEvent('Reply Liked', { discussionId: comment.id });
			},
		},
	);
};
