import { useMe, useMutation, useNotification, useService } from '@hooks';
import { createMailToLinkV2 } from '@utils';
import type { TCommunity } from '@typings';

export const useSendEmailsToCommunityMutation = () => {
	const { user } = useMe();
	const api = useService('ApiService');
	const { showInfo, showUnknownError } = useNotification();

	return useMutation<string[], Error, TCommunity['id']>(
		async (communityId) => await api.membership.getCommunityMembersEmails(communityId),
		{
			onSuccess(emails) {
				if (emails.length) {
					const mailtoLink = createMailToLinkV2({
						targetEmails: [user.email],
						bccEmails: emails,
					});
					window.open(mailtoLink);
				} else {
					showInfo({ title: 'There are no available emails' });
				}
			},
			onError(error) {
				error instanceof Error && showUnknownError(error);
			},
		},
	);
};
