import { useService, useQuery } from '@hooks';

export const useNominationSectionsQuery = () => {
	const micrositeApi = useService('MicrositeApiService');

	return useQuery(['micrositeApi.getNominationSections'], async () => await micrositeApi.getNominationSections(), {
		staleTime: Infinity,
		cacheTime: Infinity,
	});
};
