import { dayjs, formatAmount, formatCurrencySymbol } from '@utils';

export const getDynamicFieldSubtitle = (
	value: string | string[] | undefined,
	slug: string,
	currency: string,
	type: string,
) => {
	if (!value) return;
	if (Array.isArray(value)) {
		// check if it array of values
		return value?.join(', ');
	}
	if (type === 'date') {
		return value ? dayjs.tz(value, 'UTC').format('DD MMM YYYY') : undefined;
	}
	const numberConverting = Number(value?.replaceAll(',', '')); // check if it number string *convert "1,000" to 1000.
	if (slug === 'financial-details' && numberConverting) {
		return `${formatCurrencySymbol(currency)}${formatAmount(numberConverting)}`;
	}
	return value;
};
