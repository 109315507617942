import type { TDeal, TDealTemplateSection } from '@typings';
import { dealTemplateSectionSlug } from '@typings';
import type { TDealTemplateQueryResult } from '@ui-modules/deals/hooks/useGetDealTemplateQuery';
import type { TDealFormValues } from '../DealForm.schema';
import type { TDealThirdPartyFormValues } from '../ThirdPartyDealForm.schema';

const mapDynamicValues = (section?: TDealTemplateSection, templateSection?: TDealTemplateSection) => {
	if (!templateSection) return {};
	return templateSection.fields.reduce((acc, field) => {
		const isSectionFilled = section?.fields?.find((sectionField) => {
			return sectionField?.slug === field?.slug;
		});
		return { ...acc, [field.slug]: isSectionFilled || field };
	}, {});
};

export const getEditInitialValues = (
	deal?: TDeal,
	dealTemplate?: TDealTemplateQueryResult,
	callingCode?: string,
): TDealFormValues | undefined => {
	if (deal) {
		const dealDetails = deal.data.body.sections.find(
			({ section_slug }) => section_slug === dealTemplateSectionSlug.DEAL_DETAILS,
		);
		const financialDetails = deal.data.body.sections.find(
			({ section_slug }) => section_slug === dealTemplateSectionSlug.FINANCIAL_DETAILS,
		);

		let contactPhone = deal.contactPhone;
		// [T21C-6809] Check if previously saved phone number can be the state code only and phone
		// Number don't start from + and numbers length is less than 10 symbols.
		if (contactPhone?.[0] !== '+' && contactPhone.replace(/\D/g, '')?.length <= 10) {
			contactPhone = `${callingCode}${contactPhone.replace(/\D/g, '')}`;
		}

		return {
			creator: {
				iriId: deal.creator['@id'],
				name: deal.creator.fullName,
			},
			community: deal.community['@id'],
			creatorType: deal.creatorType,
			dealAssetClass: deal.dealAssetClass['@id'],
			dealSubClass: deal.dealSubClass['@id'],
			dealType: deal.dealType['@id'],
			contactName: deal.contactName,
			contactPhone,
			contactEmail: deal.contactEmail,
			title: deal.name,
			description: deal.description,
			closingDate: deal.closingDate,
			currency: deal.currency,
			minInvestment: String(deal.minInvestment),
			detailsTemplate: mapDynamicValues(dealDetails as TDealTemplateSection, dealTemplate?.dealDetails),
			financialDetailsTemplate: mapDynamicValues(
				financialDetails as TDealTemplateSection,
				dealTemplate?.financialDetails,
			),
			webLink1: deal.webUrls[0],
			webLink2: deal.webUrls[1],
			webLink3: deal.webUrls[2],
			videoUrl: deal.videoUrl,
			files: [],
			creatorOption: 'me',
			compensationDisclosure: deal.compensationDisclosure,
			priorExperience: deal.priorExperience,
		};
	}
};

export const getThirdPartyInitialValues = (
	deal?: TDeal,
	dealTemplate?: TDealTemplateQueryResult,
): TDealThirdPartyFormValues | undefined => {
	if (deal) {
		const dealDetails = deal.data.body.sections.find(
			({ section_slug }) => section_slug === dealTemplateSectionSlug.DEAL_DETAILS,
		);
		const financialDetails = deal.data.body.sections.find(
			({ section_slug }) => section_slug === dealTemplateSectionSlug.FINANCIAL_DETAILS,
		);

		return {
			creator: {
				iriId: deal.creator['@id'],
				name: deal.creator.fullName,
			},
			creatorType: deal.creatorType,
			contactName: deal.contactName,
			contactPhone: deal.contactPhone,
			contactEmail: deal.contactEmail,
			title: deal.name,
			description: deal.description,
			closingDate: deal.closingDate || null,
			currency: deal.currency,
			minInvestment: String(deal.minInvestment),
			detailsTemplate: mapDynamicValues(dealDetails as TDealTemplateSection, dealTemplate?.dealDetails),
			financialDetailsTemplate: mapDynamicValues(
				financialDetails as TDealTemplateSection,
				dealTemplate?.financialDetails,
			),
			webLink1: deal.webUrls[0] || '',
			webLink2: deal.webUrls[1] || '',
			webLink3: deal.webUrls[2] || '',
			videoUrl: deal.videoUrl || '',
			files: [],
		};
	}
};
