import { memo } from 'react';
import { ActivityIndicator, SeeMoreButton, UserListItem } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useProfilesByIdsInfiniteQuery } from '@ui-modules/contacts/hooks/useProfilesByIdsInfiniteQuery';
import { formatUserSubTitle } from '@utils';
import styles from './SpecificUserList.module.css';
import type { TProfileWithUser } from '@typings';

/** Short list of user profiles gotten by an array of profile ids. */
const SpecificUserList = ({ profileIds, onClick }: ISpecificUserListProps) => {
	const { t } = useTranslation();
	const {
		data: profiles = [],
		hasNextPage,
		isFetching,
		isInitialLoading,
		fetchMore,
	} = useProfilesByIdsInfiniteQuery(profileIds);

	if (isInitialLoading) return <ActivityIndicator size="small" type="fit" />;
	if (!profiles.length) return null;
	return (
		<div className={styles.specificUserList__wrapper}>
			<div className={styles.specificUserList}>
				{profiles.map((profile) => {
					const userProfileRecord = { ...profile.user, profile };
					return (
						<UserListItem
							key={profile.id}
							renderUserSubTitle={formatUserSubTitle(t)}
							size="medium"
							userProfileRecord={userProfileRecord}
							onClick={() => onClick(profile)}
						/>
					);
				})}
			</div>
			<SeeMoreButton hasNext={!!hasNextPage} isFetching={isFetching} title={t('See more')} onClick={fetchMore} />
		</div>
	);
};

export interface ISpecificUserListProps {
	profileIds: TProfileWithUser['id'][];
	onClick: (profileWithUser: TProfileWithUser) => void;
}

export default memo(SpecificUserList);
