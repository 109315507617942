import { useService, useMe, useQuery, useEffect, useQueryClient, useLogger } from '@hooks';
import { dayjs } from '@utils';
import type { Channel } from 'stream-chat';

export const useTrackMessageSent = (channel: Channel) => {
	const chat = useService('ChatService');
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { user } = useMe();
	const queryClient = useQueryClient();
	const logger = useLogger('useTrackMessageSent');

	const { data: memberSlugs = [] } = useQuery(
		['chat.getChannelOtherMemberSlugs', channel.id],
		async () => await chat.getChannelOtherMemberIds(channel, user.slug),
		{
			staleTime: dayjs.duration(1, 'minute').asMilliseconds(),
			cacheTime: Infinity,
			enabled: chat.isOneToOne(channel), // skip group chats to avoid error "Cannot process more than 20 objects in an event".
		},
	);

	useEffect(
		() =>
			function clearCacheOnLeave() {
				queryClient.removeQueries(['chat.getChannelOtherMemberSlugs', channel.id]);
			},
		[],
	);

	const trackMessageSent = () => {
		if (memberSlugs.length) {
			algoliaAnalytics.trackEvent('Message Sent', {
				messagersSlugs: memberSlugs.slice(0, 20), // double ensure to avoid error "Cannot process more than 20 objects in an event".
			});
		} else {
			logger.error('There is no member slugs');
		}
	};

	return trackMessageSent;
};
