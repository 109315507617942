import { MeetingEntity } from '@utils';
import type { TEvent } from '@typings';
import { Gap } from '@ui-kit';
import EventCancelledToast from '@ui-modules/events/components/EventCancelledToast';
import TakeEventSurveyToast from '@ui-modules/events/components/TakeEventSurveyToast';

export const MeetingPageToast = ({ meeting }: IMeetingPageToastProps) => {
	const surveyUrl = meeting.currentUserAttendance?.surveyUrl;
	return (
		<>
			{MeetingEntity.isCancelled(meeting) ? (
				<>
					<EventCancelledToast canceledReason={meeting.canceledReason} />
					<Gap gap={28} />
				</>
			) : null}
			{surveyUrl ? (
				<>
					<TakeEventSurveyToast surveyUrl={surveyUrl} />
					<Gap gap={28} />
				</>
			) : null}
		</>
	);
};

interface IMeetingPageToastProps {
	meeting: TEvent;
}
