import { useMe, useNavigate, useTranslation } from '@hooks';
import { CommentEntity, compact } from '@utils';
import { ROUTES } from '@constants';
import type { TComment } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';

/** Prepared meatball menu options following permission restrictions.
 *  Depends on the chosen option performs navigation by itself or calls external onDelete callback.
 */

export const useMutateReactionMeatballMenuOptions = (
	comment: TComment,
	feedSlug: string | undefined,
	onDelete: (comment: TComment) => void,
	onEdit: (comment: TComment) => void,
) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const navigate = useNavigate();

	const commentEntity = new CommentEntity(comment);

	const meatballMenuOptions: TMeatballMenuOption[] = compact([
		commentEntity.canEdit(user)
			? {
					text: commentEntity.isReply() ? t('Edit reply') : t('Edit comment'),
					onClick: () => onEdit(comment),
			  }
			: null,
		commentEntity.canDelete(user)
			? {
					text: commentEntity.isReply() ? t('Delete reply') : t('Delete comment'),
					onClick: () => onDelete(comment),
					type: 'destructive',
			  }
			: null,
		commentEntity.canReportContent(user)
			? {
					text: t('Report this content'),
					onClick: () => navigate(ROUTES.reportContent('comment', comment.id, feedSlug)),
					type: 'destructive',
			  }
			: null,
	]);

	return meatballMenuOptions.length ? meatballMenuOptions : undefined;
};
