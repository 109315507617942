import { useChat, useEffect, useReducer, useState } from '@hooks';
import type { TAttachments, TFileType } from '@typings';
import { uploadAttachmentsInStream } from '@ui-modules/chat/components/ChatInputBar/ChatInputBar.utils';
import { byteToMb, uniqBy } from '@utils';
import { useChannelStateContext } from 'stream-chat-react';
import { extractFileStats } from '../utils/extractFileStats';
import { usePickUpAttachments } from './usePickUpAttachments';
import { filter } from 'lodash';
import { FILE, IMAGE, MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE } from '@constants';
import { useNotification } from '../../../common/hooks/useNotification';
import { handleVideoSizeViaUrls } from '../../../common/utils/handleVideoSizeViaUrls';

export const usePostAttachmentsState = ({
	onlyMediaAccepted = false,
	onlyFileAccepted = false,
	savedAttachments,
	shouldUploadToStream = false,
	isChat = false,
}: IUsePostAttachmentsStateProps) => {
	const { channel } = useChannelStateContext();
	const { showError } = useNotification();
	const [{ attachments, filesSize }, setAttachments] = useReducer<TAttachmentsReducer>(
		(_, newAttachments) => ({ attachments: newAttachments, filesSize: calculateFileSize(newAttachments) }),
		{ attachments: [], filesSize: 0 },
	);
	const [loadingStateAttachments, setLoadingStateAttachments] = useState<TAttachments[]>([]);
	const [chatVideosSize, setChatVideosSize] = useState(0);

	useEffect(() => {
		setAttachments(attachments);
	}, [attachments]);

	const getType = (file: File): TFileType => {
		return file.type.includes('image') ? 'image' : file.type.includes('video') ? 'video' : 'file';
	};

	const updateStreamAttachments = async (transformedFiles: TAttachments[]) => {
		if (shouldUploadToStream) {
			setLoadingStateAttachments(transformedFiles);
			const uploadedAttachments = await uploadAttachmentsInStream(transformedFiles, channel, showError);
			setAttachments(uploadedAttachments);
			setLoadingStateAttachments(uploadedAttachments);
		} else {
			setAttachments(transformedFiles);
		}
	};

	const videoUrls = attachments.map((item) => {
		if (item.fileType === 'video') {
			return item.url;
		}
	});

	const onAddAttachmentsSuccess = (files: File[]) => {
		let filesImagesSize = 0;
		const transformedFiles = files
			.filter((file) => {
				const isAttachmentPresent = attachments.find((attachment) => attachment.name === file.name);
				if (isAttachmentPresent) return false;
				return file;
			})
			.map((file) => {
				const fileType = getType(file);

				if (fileType !== 'video') {
					filesImagesSize = filesImagesSize + file.size;
				}

				const fileConverted = {
					id: file.name,
					name: file.name,
					isFile: fileType === 'file',
					size: file.size,
					url: URL.createObjectURL(file),
					mimeType: file.type,
					mediaType: file.type,
					file: file,
					fileType,
				} as TAttachments;
				return fileConverted;
			});
		updateStreamAttachments([...transformedFiles, ...attachments]);
	};

	const { openFilePicker: uploadAttachments, getInputProps } = usePickUpAttachments(
		(files) => {
			onAddAttachmentsSuccess(files);
		},
		onlyMediaAccepted,
		onlyFileAccepted,
		MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE * 1024 * 1024,
	);
	const { editMessage } = useChat();

	const removeAttachment = async (file: TAttachments) => {
		const filteredState = filter(attachments, (prevFile) => {
			return `${prevFile.name}-${prevFile.url}` !== `${file.name}-${file.url}`;
		});

		try {
			if (!editMessage) {
				if (file?.fileType?.includes(IMAGE) && file.url) {
					await channel?.deleteImage(file.url);
				}
				if (file?.fileType === FILE && file.url) {
					await channel?.deleteFile(file.url);
				}
			}
		} finally {
			setAttachments(filteredState);
			setLoadingStateAttachments(filteredState);
		}
	};

	const setInitialAttachments = ({ images = [], files = [], videos = [] }: IInitialAttachments) => {
		const convertedImages = images.map((image) => ({
			...extractFileStats(image),
			fileType: 'image' as TFileType,
		}));

		const convertedFiles = files.map((file) => ({
			...extractFileStats(file),
			fileType: 'file' as TFileType,
		}));

		const convertedVideos = videos.map((video) => ({
			...extractFileStats(video),
			fileType: 'video' as TFileType,
		}));

		const initialAttachments = [...convertedFiles, ...convertedImages, ...convertedVideos];
		updateStreamAttachments(initialAttachments);
	};

	useEffect(() => {
		if (savedAttachments === undefined) {
			// Handle the case when savedAttachments is undefined
			// You can set default values or perform any other required action
			return;
		}

		updateStreamAttachments(savedAttachments);
	}, [savedAttachments]);

	useEffect(() => {
		if (chatVideosSize < 0) {
			setChatVideosSize(0);
		}
	}, [filesSize, chatVideosSize]);

	useEffect(() => {
		if (videoUrls.length && isChat) {
			const videosSize = videoUrls.length && handleVideoSizeViaUrls(videoUrls as string[]);
			if (videosSize < 0) setChatVideosSize(0);
			setChatVideosSize(videosSize);
		}
	}, [videoUrls.length, isChat]);

	return {
		attachments,
		filesSize,
		chatVideosSize,
		removeAttachment,
		uploadAttachments,
		setInitialAttachments,
		setAttachments,
		setLoadingStateAttachments,
		getInputProps,
		loadingStateAttachments,
		onAddAttachmentsSuccess,
	};
};

// Helpers

const calculateFileSize = (attachments: TAttachments[]) => {
	const totalSize = attachments.reduce((totalSize, attachment) => {
		if (attachment.fileType === 'file') {
			return (totalSize += attachment.size || 0);
		}
		return totalSize;
	}, 0);

	return byteToMb(Math.max(totalSize, 0));
};

//  Typings

interface IInitialAttachments {
	images?: string[];
	files?: string[];
	videos?: string[];
}

interface IUsePostAttachmentsStateProps {
	onlyMediaAccepted?: boolean;
	onlyFileAccepted?: boolean;
	savedAttachments?: TAttachments[];
	shouldUploadToStream?: boolean;
	isChat?: boolean;
}

type TAttachmentsReducer<
	State = {
		attachments: TAttachments[];
		filesSize: number;
	},
> = (state: State, newAttachments: TAttachments[]) => State;
