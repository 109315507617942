import { useService, usePaginatedInfiniteQuery } from '@hooks';
import type { TEvent, TEventAttendeeListItem } from '@typings';

export const useMeetingAttendeesQuery = ({ meetingId, guests, going }: IMeetingAttendeesQuery) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return usePaginatedInfiniteQuery<TEventAttendeeListItem>(
		queryKeys.getMeetingAttendances(String(meetingId), { going, guests }),
		async ({ pageParam: page }) =>
			await api.attendance.getMeetingAttendancesPaginated(meetingId as TEvent['id'], guests, going, page),
		{ enabled: !!meetingId },
	);
};

interface IMeetingAttendeesQuery {
	meetingId?: TEvent['id'];
	guests: boolean;
	going: boolean;
}
