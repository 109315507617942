import { useMemo } from '@hooks';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import type { TUserInitialSetting } from './NotificationSettingsGroupPage.types';

export const useDefaultExpandedState = (groupName: string, userInitialSettings: TUserInitialSetting[]) => {
	const { collapsedNotificationsSettings } = useCollapsedNotificationsSettings();
	return useMemo(() => {
		const isNew = userInitialSettings.every((setting) => setting.id === null);
		const isSomeEnabled = userInitialSettings.some((setting) => !!setting.options.length);
		return isNew || isSomeEnabled || collapsedNotificationsSettings.includes(groupName);
	}, [userInitialSettings, collapsedNotificationsSettings, groupName]);
};
