import { useService, useQuery, useMe } from '@hooks';
import type { TUserMembership } from '@typings';
import { dayjs } from '@utils';

export const useMemberMembershipQuery = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TUserMembership[], Error>(
		reactQuery.queryKeys.getMemberRelation(),
		async () => {
			const meMemberships = await api.user.getUser(user?.id as string);
			return meMemberships.memberships || [];
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};
