import { Button } from '@ui-kit';
import { useBlockedAndBlockingUsers, useMemo, useTranslation } from '@hooks';
import { useBlockUserMutation } from '@ui-modules/contacts/hooks/useBlockUserMutation';
import { useUnblockUserMutation } from '@ui-modules/contacts/hooks/useUnblockUserMutation';
import type { TUser } from '@typings';

const BlockUserToggleButton = ({ hidden = false, userId }: IBlockUserToggleButtonProps) => {
	const { t } = useTranslation();

	const { blockedUserRelations } = useBlockedAndBlockingUsers();
	const blockUserRelation = useMemo(
		() => blockedUserRelations.find((relation) => relation.blockedUser.id === userId),
		[blockedUserRelations],
	);

	const { mutate: blockUser, isLoading: isBlocking } = useBlockUserMutation();
	const { mutate: unblockUser, isLoading: isUnblocking } = useUnblockUserMutation();

	const toggleBlockingUser = () => {
		if (blockUserRelation) unblockUser(blockUserRelation.id);
		else blockUser(userId);
	};

	if (hidden) return null;
	return (
		<Button
			loading={isBlocking || isUnblocking}
			title={blockUserRelation ? t('Unblock') : t('Block')}
			trackingName={blockUserRelation ? 'profile--unblock-user' : 'profile--block-user'}
			type="solid"
			variant="medium"
			onClick={toggleBlockingUser}
		/>
	);
};

export interface IBlockUserToggleButtonProps {
	userId: TUser['id'];
	hidden?: boolean;
}

export default BlockUserToggleButton;
