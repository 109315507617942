import { useRef, useLayoutEffect, useEvent } from '@hooks';
import { camelCase } from 'lodash';
import type { VirtuosoHandle } from 'react-virtuoso';

/** Saves a scrollTop of a some div in a session storage and restores scroll position by request. */
export const useRestoreVirtuosoScroll = (id: string) => {
	const listRef = useRef<VirtuosoHandle | null>(null);
	const scrollerRef = useRef<HTMLElement | null>(null);

	/* Calls "scroll to" until getting scroll position OR running out of retries count.
	 * We have to use it because Stream Feed doesn't allow to scroll to target scroll shift from 1 time if Y-scroll is big (if you scrolled down a lot)
	 * [@dmitriy.nikolenko]
	 */
	const repeatableScroll = (scrollTop: number, retries = 7) => {
		scrollerRef.current?.scrollTo?.(0, scrollTop);

		const scrollDiff = Math.abs((scrollerRef.current?.scrollTop ?? 0) - scrollTop);
		if (retries > 0 && scrollDiff > 100) {
			setTimeout(() => repeatableScroll(scrollTop, retries - 1), 300);
		}
	};

	const restoreScroll = useEvent(() => {
		const scrollTop = getScrollTop(id);
		if (scrollTop && scrollerRef?.current) {
			repeatableScroll(scrollTop);
		}
	});

	useLayoutEffect(() => {
		return function persistScrollPosition() {
			const scrollTop = scrollerRef.current?.scrollTop ?? 0;
			setScrollTop(id, scrollTop);
		};
	}, []);

	return { scrollerRef, listRef, restoreScroll, initialScrollTo: getScrollTop(id) };
};

// Session storage helpers.

const prepareStorageKey = (id: string): string => `useRestoreScroll__${camelCase(id)}`;

const getScrollTop = (id: string): number => {
	const persistKey = prepareStorageKey(id);
	const persistedValue = sessionStorage.getItem(persistKey);
	const scrollTop = Number.parseInt(String(persistedValue)) || 0;

	return scrollTop;
};

const setScrollTop = (id: string, scrollTop: number) => {
	const persistingValue = String(scrollTop);
	const persistKey = prepareStorageKey(id);

	sessionStorage.setItem(persistKey, persistingValue);
};
