import type { IAxiosService } from '../interfaces/AxiosService.interface';

import { CommunityApiService } from '@tiger21-llc/connect-shared/src/apiServices/CommunityApiService';
import { AuthApiService } from '@tiger21-llc/connect-shared/src/apiServices/AuthApiService';
import { StreamChatApiService } from '@tiger21-llc/connect-shared/src/apiServices/StreamChatApiService';
import { UserApiService } from '@tiger21-llc/connect-shared/src/apiServices/UserApiService';
import { ProfileApiService } from '@tiger21-llc/connect-shared/src/apiServices/ProfileApiService';
import { ProfileStaffApiService } from '@tiger21-llc/connect-shared/src/apiServices/ProfileStaffApiService';
import { ContactApiService } from '@tiger21-llc/connect-shared/src/apiServices/ContactApiService';
import { ExpertiseApiService } from '@tiger21-llc/connect-shared/src/apiServices/ExpertiseApiService';
import { InterestApiService } from '@tiger21-llc/connect-shared/src/apiServices/InterestApiService';
import { InvestmentApiService } from '@tiger21-llc/connect-shared/src/apiServices/InvestmentApiService';
import { EventApiService } from '@tiger21-llc/connect-shared/src/apiServices/EventApiService';
import { StreamFeedApiService } from '@tiger21-llc/connect-shared/src/apiServices/StreamFeedApiService';
import { PushTokenApiService } from '@tiger21-llc/connect-shared/src/apiServices/PushTokenApiService';
import { CalendarItemApiService } from '@tiger21-llc/connect-shared/src/apiServices/CalendarItemApiService';
import { MeetingApiService } from '@tiger21-llc/connect-shared/src/apiServices/MeetingApiService';
import { AttendanceApiService } from '@tiger21-llc/connect-shared/src/apiServices/AttendanceApiService';
import { MembershipApiService } from '@tiger21-llc/connect-shared/src/apiServices/MembershipApiService';
import { AlgoliaApiService } from '@tiger21-llc/connect-shared/src/apiServices/AlgoliaApiService';
import { CommunityFileApiService } from '@tiger21-llc/connect-shared/src/apiServices/CommunityFileApiService';
import { CommunityFolderApiService } from '@tiger21-llc/connect-shared/src/apiServices/CommunityFolderApiService';
import { DealFileApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealFileApiService';
import { DealFolderApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealFolderApiService';
import { CalendarItemFileApiService } from '@tiger21-llc/connect-shared/src/apiServices/CalendarItemFileApiService';
import { NetworkCreationRequestApiService } from '@tiger21-llc/connect-shared/src/apiServices/NetworkCreationRequestApiService';
import { MarketApiService } from '@tiger21-llc/connect-shared/src/apiServices/MarketApiService';
import { NotificationsSettingsApiService } from '@tiger21-llc/connect-shared/src/apiServices/NotificationsSettingsApiService';
import { NominationApiService } from '@tiger21-llc/connect-shared/src/apiServices/NominationApiService';
import { UserBlockingApiService } from '@tiger21-llc/connect-shared/src/apiServices/UserBlockingApiService';
import { WaiverApiService } from '@tiger21-llc/connect-shared/src/apiServices/WaiverApiService';
import { InvestService } from '@tiger21-llc/connect-shared/src/apiServices/InvestService';
import { DealAssetClassApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealAssetClassApiService';
import { DealSubClassApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealSubClassApiService';
import { DealTypeApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealTypeApiService';
import { DealTemplateApiService } from '@tiger21-llc/connect-shared/src/apiServices/DealTemplateApiService';
import { NetworkConnectionApiService } from '@tiger21-llc/connect-shared/src/apiServices/NetworkConnectionApiService';
import { NetworkConnectionFileApiService } from '@tiger21-llc/connect-shared/src/apiServices/NetworkConnectionFileApiService';
import { NetworkConnectionFolderApiService } from '@tiger21-llc/connect-shared/src/apiServices/NetworkConnectionFolderApiService';
import { BookmarksApiService } from '@tiger21-llc/connect-shared/src/apiServices/BookmarksApiService';
import { NetworkConnectionFiltersApiService } from '@tiger21-llc/connect-shared/src/apiServices/NetworkConnectionFiltersApiService';
import { AssetAllocationApiService } from '@tiger21-llc/connect-shared/src/apiServices/AssetAllocationApiService';
import { FlyoutApiService } from '@tiger21-llc/connect-shared/src/apiServices/FlyoutApiService';
import { NotificationRequestApiService } from '@tiger21-llc/connect-shared/src/apiServices/NotificationRequestApiService';
import { MediaObjectApiService } from './MediaObjectApiService';
import { ThirdPartyDealApiService } from './ThirdPartyDealApiService';

/** Service collects methods to perform http-request to backend API. */
export class ApiService {
	static inject = ['AxiosService'] as const;
	constructor(private readonly axios: IAxiosService) {
		this.streamChat = new StreamChatApiService(this.axios);
		this.community = new CommunityApiService(this.axios);
		this.auth = new AuthApiService(this.axios);
		this.user = new UserApiService(this.axios);
		this.contact = new ContactApiService(this.axios);
		this.profile = new ProfileApiService(this.axios);
		this.profileStaff = new ProfileStaffApiService(this.axios);
		this.expertise = new ExpertiseApiService(this.axios);
		this.interest = new InterestApiService(this.axios);
		this.investment = new InvestmentApiService(this.axios);
		this.pushToken = new PushTokenApiService(this.axios);
		this.event = new EventApiService(this.axios);
		this.streamFeeds = new StreamFeedApiService(this.axios);
		this.calendarItem = new CalendarItemApiService(this.axios);
		this.meeting = new MeetingApiService(this.axios);
		this.attendance = new AttendanceApiService(this.axios);
		this.membership = new MembershipApiService(this.axios);
		this.algolia = new AlgoliaApiService(this.axios);
		this.communityFile = new CommunityFileApiService(this.axios);
		this.communityFolder = new CommunityFolderApiService(this.axios);
		this.dealFile = new DealFileApiService(this.axios);
		this.dealFolder = new DealFolderApiService(this.axios);
		this.calendarItemFile = new CalendarItemFileApiService(this.axios);
		this.networkCreationRequest = new NetworkCreationRequestApiService(this.axios);
		this.market = new MarketApiService(this.axios);
		this.mediaObject = new MediaObjectApiService(this.axios);
		this.notificationSettings = new NotificationsSettingsApiService(this.axios);
		this.userBlocking = new UserBlockingApiService(this.axios);
		this.nomination = new NominationApiService(this.axios);
		this.waiver = new WaiverApiService(this.axios);
		this.invest = new InvestService(this.axios);
		this.dealAssetClass = new DealAssetClassApiService(this.axios);
		this.dealSubClass = new DealSubClassApiService(this.axios);
		this.dealType = new DealTypeApiService(this.axios);
		this.dealTemplate = new DealTemplateApiService(this.axios);
		this.thirdPartyDeal = new ThirdPartyDealApiService(this.axios);
		this.networkConnection = new NetworkConnectionApiService(this.axios);
		this.networkConnectionFile = new NetworkConnectionFileApiService(this.axios);
		this.networkConnectionFolder = new NetworkConnectionFolderApiService(this.axios);
		this.bookmarks = new BookmarksApiService(this.axios);
		this.notificationRequest = new NotificationRequestApiService(this.axios);
		this.networkConnectionUserFilter = new NetworkConnectionFiltersApiService(this.axios);
		this.assetAllocation = new AssetAllocationApiService(this.axios);
		this.flyout = new FlyoutApiService(this.axios);
	}

	readonly streamChat: StreamChatApiService;
	readonly streamFeeds: StreamFeedApiService;
	readonly community: CommunityApiService;
	readonly auth: AuthApiService;
	readonly user: UserApiService;
	readonly profile: ProfileApiService;
	readonly profileStaff: ProfileStaffApiService;
	readonly contact: ContactApiService;
	readonly expertise: ExpertiseApiService;
	readonly interest: InterestApiService;
	readonly investment: InvestmentApiService;
	readonly invest: InvestService;
	readonly pushToken: PushTokenApiService;
	readonly event: EventApiService;
	readonly calendarItem: CalendarItemApiService;
	readonly meeting: MeetingApiService;
	readonly attendance: AttendanceApiService;
	readonly membership: MembershipApiService;
	readonly algolia: AlgoliaApiService;
	readonly communityFile: CommunityFileApiService;
	readonly communityFolder: CommunityFolderApiService;
	readonly dealFile: DealFileApiService;
	readonly dealFolder: DealFolderApiService;
	readonly calendarItemFile: CalendarItemFileApiService;
	readonly networkCreationRequest: NetworkCreationRequestApiService;
	readonly market: MarketApiService;
	readonly mediaObject: MediaObjectApiService;
	readonly notificationSettings: NotificationsSettingsApiService;
	readonly userBlocking: UserBlockingApiService;
	readonly nomination: NominationApiService;
	readonly waiver: WaiverApiService;
	readonly dealAssetClass: DealAssetClassApiService;
	readonly dealSubClass: DealSubClassApiService;
	readonly dealType: DealTypeApiService;
	readonly dealTemplate: DealTemplateApiService;
	readonly thirdPartyDeal: ThirdPartyDealApiService;
	readonly networkConnection: NetworkConnectionApiService;
	readonly networkConnectionFile: NetworkConnectionFileApiService;
	readonly networkConnectionFolder: NetworkConnectionFolderApiService;
	readonly networkConnectionUserFilter: NetworkConnectionFiltersApiService;
	readonly notificationRequest: NotificationRequestApiService;
	readonly bookmarks: BookmarksApiService;
	readonly assetAllocation: AssetAllocationApiService;
	readonly flyout: FlyoutApiService;
}
