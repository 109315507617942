import * as codes from 'currency-codes';
import { default as currencySymbol } from 'currency-symbol-map';

export type TMappedCurrency = {
	value: string;
	title: string;
};

export const useCurrencyCodes = (): TMappedCurrency[] => {
	return codes.data.map(({ code, currency }) => {
		const symbol = currencySymbol(code);
		return {
			value: code,
			title: symbol ? `${code} - ${currency} (${symbol})` : `${code} - ${currency}`,
		};
	});
};
