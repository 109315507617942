import { useEffect, useMemo, useService } from '@hooks';
import { useUploadCalendarItems } from './useUploadCalendarItems';
import { dayjs } from '@utils';
import { DEFAULT_CALENDAR_END_DATE } from '@constants';

export const useCalendarState = (startDate: string, endDate: string = DEFAULT_CALENDAR_END_DATE) => {
	const { useAppSelector } = useService('ReduxService');
	const { uploadCalendarItems } = useUploadCalendarItems();

	const calendarItems = useAppSelector((store) => store.calendar.calendarItems);

	useEffect(() => {
		uploadCalendarItems(startDate, endDate);
	}, [startDate, endDate, uploadCalendarItems]);

	const selectedCalendarItems = useMemo(() => {
		const filteredCalendarItems = calendarItems.filter((calendarItem) =>
			dayjs(calendarItem.endDate).isBetween(dayjs(startDate), dayjs(endDate)),
		);

		return filteredCalendarItems;
	}, [startDate, endDate, calendarItems]);

	return { selectedCalendarItems };
};
