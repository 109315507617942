import axios from 'axios';
import { Base64 } from 'js-base64';
import { VideoUploader } from '@api.video/video-uploader';
import type { VideoUploadResponse } from '@api.video/video-uploader';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type { IApiVideoConfig, IEnvironmentConfig } from '../interfaces/AppConfig.interface';
import type { TAttachments } from '@typings';

export class ApiVideoService {
	static inject = ['AppConfigService', 'logger', 'SentryService'] as const;
	constructor(
		private readonly appConfig: IApiVideoConfig & IEnvironmentConfig,
		private readonly logger: IDevLogger,
		private readonly sentry: IBugTracker,
	) {
		this.#VIDEO_API_URL = 'ws';
	}
	readonly #VIDEO_API_URL: string;

	async uploadVideo(videoId: string, videoInfo: TAttachments): Promise<VideoUploadResponse> {
		if (!videoInfo.file) {
			throw new Error('videoInfo.file is empty');
		}

		try {
			const uploader = new VideoUploader({
				// Authentication data.
				accessToken: this.appConfig.API_VIDEO_API_KEY,
				videoId: videoId,
				// Upload data.
				file: videoInfo.file,
				chunkSize: 1024 * 1024 * 50, // 50MB
				retries: 2,
				apiHost: `${this.#VIDEO_API_URL}.api.video`,
			});

			uploader.onProgress((event) => {
				this.logger.debug(
					`Chunk ${event.currentChunk}/${event.chunksCount}. Uploaded ${event.uploadedBytes}/${event.totalBytes}`,
				);
			});
			const uploadResult = await uploader.upload(); // using api-video uploader allow to upload any size videos (T21C-3637).
			this.logger.debug('upload result', uploadResult);

			return uploadResult;
		} catch (error) {
			this.sentry.captureException(error as Error);
			this.logger.error(error);
			throw error;
		}
	}

	async createVideo(videoInfo: TAttachments, description?: string | undefined) {
		try {
			const authorizationToken = Base64.btoa(this.appConfig.API_VIDEO_API_KEY);
			const { data } = await axios.post(
				`https://${this.#VIDEO_API_URL}.api.video/videos`,
				{
					public: false,
					panoramic: false,
					mp4Support: true,
					metadata: [{ key: 'T21ConnectApp', value: this.appConfig.APP_ENV }],
					title: videoInfo.name,
					description,
				},
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Basic ${authorizationToken}`,
					},
				},
			);

			await this.uploadVideo(data.videoId, videoInfo);
			const videoUrl = data.assets.mp4;

			return videoUrl;
		} catch (error) {
			this.sentry.captureException(error as Error);
			this.logger.error(error);
			throw error;
		}
	}
	async getVideoDetails(videoId?: string) {
		const base64 = Base64.btoa(this.appConfig.API_VIDEO_API_KEY);
		const { data } = await axios.get(`https://${this.#VIDEO_API_URL}.api.video/videos/${videoId}`, {
			headers: {
				Accept: 'application/json',
				Authorization: `Basic ${base64}`,
			},
		});
		return data;
	}

	async getListOfVideos(query: string) {
		const base64 = window.btoa(this.appConfig.API_VIDEO_API_KEY);
		const { data } = await axios.get(`https://${this.#VIDEO_API_URL}.api.video/videos?title=${query}`, {
			headers: {
				Accept: 'application/json',
				Authorization: `Basic ${base64}`,
			},
		});
		return data;
	}
}
