import { useEffect, useTranslation } from '@hooks';

import styles from './EditNetworkDealFiltersForm.module.css';
import { ActivityIndicator, CheckboxNestedInput, Form, TabSelect, AmountInput, ResetFilters } from '@ui-kit';
import type { TTab } from '@ui-kit/components/TabSelect/TabSelect';
import RadioButtonsFilters from '@ui-modules/deals/components/RadioButtonsFilters';

import { dealStatusTabs as tabs } from '@constants';
import type { FormikProps } from 'formik';
import type { TEditDealFilters } from '@schemas';
import { useState } from 'reinspect';
import CheckBoxChild from '../CheckBoxChild';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { updateCheckMarksBooleanValues } from '@ui-modules/deals/hooks/updateCheckMarksBooleanValues';

import type { Dispatch, SetStateAction } from 'react';
import type { TCommunity } from '@typings';
import {
	useParseNetworkDealFilters,
	useSelectedNetworkDealTypes,
	useSelectedNetworkSubClasses,
} from '@ui-modules/deals/hooks/useParseNetworkDealFilters';

import { useTrackDealFiltersChanged } from '@ui-modules/deals/hooks/useTrackDealFiltersChanged';

const EditNetworkDealFiltersForm = ({
	formProps,
	activeStatus,
	optionIndex,
	setOptionIndex,
	community,
	setAvailableDealTypes,
}: IEditNetworkDealFiltersFormProps) => {
	const { t } = useTranslation();
	const dealStatusTabs = tabs(t);
	const { setFieldValue, values, errors, initialValues, resetForm, dirty, submitCount } = formProps;

	const [checkMarks, setCheckMarks] = useState<ICheckmarkState[] | []>([]);
	const [types, setTypes] = useState<ICheckmarkState[] | []>([]);
	const [activeStatusIndex, setActiveStatusIndex] = useState<number>(Number(values.status));

	const selectedSubClasses = useSelectedNetworkSubClasses(checkMarks) as string[];
	const selectedDealTypes = useSelectedNetworkDealTypes(types) as string[];

	const { initialCheckMarks, dealTypes, isLoading, isDealTypeLoading, readyToRender } = useParseNetworkDealFilters({
		initialDealTypes: initialValues.dealTypes,
		initialDealSubClasses: initialValues.dealSubClasses,
		communityId: community.id,
	});
	const trackDealFiltersChanged = useTrackDealFiltersChanged();

	useEffect(
		function trackFilterChanged() {
			if (submitCount > 0) {
				trackDealFiltersChanged([...initialCheckMarks, ...dealTypes], [...checkMarks, ...types]);
			}
		},
		[submitCount],
	);

	// These are for setting initial values
	useEffect(() => {
		if (readyToRender) {
			if (optionIndex === 0) {
				setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
			} else {
				setCheckMarks(initialCheckMarks);
			}
		}
	}, [initialCheckMarks, readyToRender]);

	// These are for setting initial values
	useEffect(() => {
		if (readyToRender) {
			if (optionIndex === 0) {
				setTypes(updateCheckMarksBooleanValues(dealTypes, false));
			} else {
				setTypes(dealTypes);
			}
		}
	}, [dealTypes, readyToRender]);

	useEffect(() => {
		if (isDealTypeLoading) return;
		setFieldValue('dealSubClasses', selectedSubClasses);
		setFieldValue('dealTypes', selectedDealTypes);
	}, [checkMarks, types]);

	const onShowAllChange = () => {
		setOptionIndex(0);

		setTimeout(() => {
			setTypes(updateCheckMarksBooleanValues(dealTypes, false));
		}, 0);
		setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
	};

	const onLimitSelectionChange = () => {
		setOptionIndex(1);

		setCheckMarks(updateCheckMarksBooleanValues(initialCheckMarks, false));
	};

	useEffect(() => {
		setAvailableDealTypes(dealTypes);
	}, [dealTypes.length]);

	const resetFormCheckMarks = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setCheckMarks(
			initialCheckMarks.map((item) => {
				return {
					...item,
					value: false,
					children: item.children?.map((child) => {
						return {
							...child,
							value: false,
						};
					}),
				};
			}),
		);

		setTypes(
			dealTypes.map((item) => {
				return { ...item, value: false };
			}),
		);
		setFieldValue('status', String(1));
		setActiveStatusIndex(1);
		setOptionIndex(0);
		setFieldValue('minInvestment', 0);
	};

	if (!readyToRender) return null;

	return (
		<>
			<div className={styles.wrapper}>
				<Form className={styles.form}>
					<div className={styles.header}>
						<span className={styles.title}>
							<span> {t('Filtering')}</span>
							<i>&nbsp;{community?.name}&nbsp;</i>
							<span>{t('Deals')}</span>
						</span>
						<ResetFilters title={t('Reset Filters')} onClick={resetFormCheckMarks} />
					</div>

					<span className={styles.status}>{t('Deal Status')}</span>
					<TabSelect
						tabs={dealStatusTabs as TTab[]}
						value={activeStatusIndex}
						onChange={(item) => {
							setActiveStatusIndex(item.id);
							setFieldValue('status', String(item.id));
						}}
					/>
					<div className={styles.subClassWrapper}>
						<span className={styles.subClassTitle}>{t('Sub-classes')}</span>
						<span className={styles.subClassInfo}>{t('(select all that apply)')}</span>
					</div>
					{isLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : (
						<div className={styles.checkboxesWrapper}>
							<RadioButtonsFilters
								optionIndex={optionIndex}
								onLimitSelectionChange={onLimitSelectionChange}
								onShowAllChange={onShowAllChange}
							/>
							<div className={styles.checkboxOptionsWrapper}>
								<span className={styles.myInvestments}>{community?.name}</span>
								<CheckboxNestedInput
									checkMarks={checkMarks}
									childrenDisabled={optionIndex === 0}
									receiveChanges={setCheckMarks}
									renderChild={(props) => <CheckBoxChild {...props} filterCheckbox />}
								/>
							</div>
						</div>
					)}
					{isDealTypeLoading ? (
						<ActivityIndicator size="medium" type="fit" />
					) : (
						!!types.length && (
							<div className={styles.checkboxOptionsWrapper}>
								<span className={styles.type}>{t('Type(s)')}</span>
								<CheckboxNestedInput
									checkMarks={types.sort((a, b) => (a.label || '').localeCompare(b.label || ''))}
									childrenDefaultValue={false}
									childrenDisabled={optionIndex === 0}
									receiveChanges={setTypes}
								/>
							</div>
						)
					)}
					<div className={styles.minimumInputWrapper}>
						<AmountInput
							errorMessage={errors.minInvestment}
							label={t('Minimum')}
							placeholder={t('e.g 50,000')}
							value={Number(values.minInvestment) === 0 ? '' : String(values.minInvestment)}
							onChange={(e) => setFieldValue('minInvestment', String(e.target.value).replace(/,/g, ''))}
						/>
					</div>
				</Form>
			</div>
		</>
	);
};

export interface IEditNetworkDealFiltersFormProps {
	activeStatus: number;
	formProps: FormikProps<TEditDealFilters>;
	optionIndex: number;
	setOptionIndex: Dispatch<SetStateAction<number>>;
	community: TCommunity;
	setAvailableDealTypes: React.Dispatch<React.SetStateAction<ICheckmarkState[] | null>>;
}

export default EditNetworkDealFiltersForm;
