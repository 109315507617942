import { Anchor } from '@ui-kit';
import styles from './DisplayContact.module.css';
import type { TMixpanelEvent } from '@typings';

const DisplayContact = ({ label, value, link, linkBlank = false, trackingName, onClick }: IDisplayContactProps) => {
	return (
		<div className={styles.contact}>
			<span className={styles.contact__label}>{label}</span>
			<Anchor
				className={styles.contact__value}
				href={link}
				{...(linkBlank && { target: 'blank' })}
				trackingName={trackingName}
				onClick={onClick}
			>
				{value}
			</Anchor>
		</div>
	);
};

export interface IDisplayContactProps {
	label: string;
	value: string;
	link?: string;
	linkBlank?: boolean;
	trackingName?: TMixpanelEvent;
	onClick?: () => void;
}

export default DisplayContact;
