import { PROFILE_AVATAR_IMAGE_QUALITY, PROFILE_AVATAR_MAX_WIDTH } from '@constants';
import Compressor from 'compressorjs';

export const resizeFile = (file: File): Promise<File> =>
	new Promise((resolve) => {
		new Compressor(file, {
			quality: PROFILE_AVATAR_IMAGE_QUALITY,
			maxWidth: PROFILE_AVATAR_MAX_WIDTH,
			maxHeight: PROFILE_AVATAR_MAX_WIDTH,
			success: (file) => resolve(file as File),
		});
	});
