import { UikitContext } from '@ui-kit/contexts/UikitContext';
import { useService, useMemo } from '@hooks';
import type { PropsWithChildren } from 'react';
import type { IUikitContextValue } from '@ui-kit/contexts/UikitContext';
import type { TMixpanelEvent } from '@typings';

export const UikitContextProvider = ({ children }: PropsWithChildren) => {
	const appConfig = useService('AppConfigService');
	const analytics = useService('AnalyticsService');

	const contextValue: IUikitContextValue = useMemo(
		() => ({
			avatarBaseUri: appConfig.AVATAR_BASE_URI,
			trackClickEvent: (ctaName: TMixpanelEvent) => analytics.trackEvent('cta_pressed', { '#cta_name': ctaName }),
		}),
		[appConfig.AVATAR_BASE_URI],
	);

	return <UikitContext.Provider value={contextValue}>{children}</UikitContext.Provider>;
};
