import React, { useState } from 'react';
import { useTranslation } from '@hooks';
import { AmountInput } from '@ui-kit';
import { getCurrencySymbol, toString } from '@utils';
import styles from './DynamicMoney.module.css';
import type { TDynamicFilter, TNetworkConnectionTemplate } from '@typings';
import type {
	TDataType,
	TUpdateFiltersFunction,
} from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';

const DynamicMoney = ({ fieldData, currency, onFilterUpdate, errors }: IDynamicMoneyProps) => {
	const { t } = useTranslation();

	const getDefaultFromValue = (): string => {
		const { gte, eq, gt } = fieldData?.filtersApplied || {};
		return String(gte ?? eq ?? gt ?? '');
	};

	const getDefaultToValue = (): string => {
		const { lte, lt } = fieldData?.filtersApplied || {};
		return String(lte ?? lt ?? '');
	};

	const [fromValue, setFromValue] = useState<string>(getDefaultFromValue());
	const [toValue, setToValue] = useState<string>(getDefaultToValue());

	const handleChange = (newValue: string, op: 'eq' | 'from' | 'to') => {
		const parsedValue = newValue?.replace('.', '');
		if (op === 'from') {
			setFromValue(parsedValue);
		} else {
			setToValue(parsedValue);
		}
		const value = parsedValue === '0' ? '' : parsedValue;
		onFilterUpdate(fieldData.name, { op, value }, fieldData.type as TDataType, fieldData.filterOperators);
	};

	const eqExists = fieldData.filterOperators.some((item: string) => item.includes('eq') || item === 'like');
	const fromExists = fieldData.filterOperators.some((item: string) => item.includes('gt'));
	const toExists = fieldData.filterOperators.some((item: string) => item.includes('lt'));
	const subLabel = fieldData.type === 'money' && !!currency ? toString(getCurrencySymbol(currency)) : '';

	return (
		<div>
			<span className={styles.networkConnectionFilters__title}>
				<span>{fieldData.label}</span>
				{currency && subLabel ? <span>{` (${subLabel} ${currency})`}</span> : null}
			</span>
			<div className={styles.networkConnectionFilters__amountInputsWrapper}>
				{(fromExists || eqExists) && (
					<div className={styles.networkConnectionFilters__amountInputWrapper}>
						<AmountInput
							allowLeadingZeros={false}
							autoComplete="off"
							errorMessage={errors?.gt || errors?.gte || errors?.eq}
							errorMessagePosition="none"
							placeholder={t('e.g. 50k')}
							value={String(fromValue)}
							onChange={(e) => handleChange(e.target.value?.replaceAll(',', ''), 'from')}
						/>
						{fromExists && !(errors?.gt || errors?.gte || errors?.eq) && <p>{t('From')}</p>}
					</div>
				)}
				{toExists && (
					<div className={styles.networkConnectionFilters__amountInputWrapper}>
						<AmountInput
							allowLeadingZeros={false}
							autoComplete="off"
							errorMessage={errors?.lte || errors?.lt}
							placeholder={t('e.g. 150m')}
							value={String(toValue)}
							onChange={(e) => handleChange(e.target.value?.replaceAll(',', ''), 'to')}
						/>
						{!(errors?.lte || errors?.lt) && <p>{t('To')}</p>}
					</div>
				)}
			</div>
		</div>
	);
};

export default DynamicMoney;

export interface IDynamicMoneyProps {
	fieldData: TDynamicFilter;
	currency: TNetworkConnectionTemplate['currency'] | undefined;
	onFilterUpdate: TUpdateFiltersFunction;
	errors?: {
		lt?: string;
		gt?: string;
		gte?: string;
		lte?: string;
		eq?: string;
		lik?: string;
	};
}
