import { useTranslation } from '@hooks';
import { Button } from '@ui-kit';
import { CommunityEntity } from '@utils';
import { useIsRequestingNetworkAccess } from '@ui-modules/networks/hooks/useIsRequestingNetworkAccess';
import { useIsJoiningNetwork } from '@ui-modules/networks/hooks/useIsJoiningNetwork';
import type { TCommunity } from '@typings';
import type { IButtonProps } from '@ui-kit';

const NetworkAttendanceButton = ({
	network,
	joinTitle,
	requestTitle,
	pendingTitle,
	showRejectButton,
	icon,
	iconPosition,
	onRequestAccess,
	onJoin,
}: INetworkAttendanceButtonProps) => {
	const { t } = useTranslation();

	const IsJoiningNetwork = useIsJoiningNetwork(network.id);
	const IsRequestingNetworkAccess = useIsRequestingNetworkAccess(network.id);

	if (showRejectButton && CommunityEntity.hasRejectJoinRequest(network)) {
		return <Button disabled title={t('Request Denied')} type="blurred" variant="medium" />;
	} else if (CommunityEntity.hasPendingJoinRequest(network)) {
		return <Button disabled title={pendingTitle || t('Pending')} type="blurred" variant="medium" />;
	} else if (CommunityEntity.canSendJoinRequest(network)) {
		return (
			<Button
				icon={icon}
				iconPosition={iconPosition}
				loading={IsRequestingNetworkAccess}
				title={requestTitle || t('Request Access')}
				type="outline"
				variant="medium"
				onClick={onRequestAccess}
			/>
		);
	} else if (CommunityEntity.canJoin(network)) {
		return (
			<Button
				icon={icon}
				iconPosition={iconPosition}
				loading={IsJoiningNetwork}
				title={joinTitle ?? `${t('Join')} +`}
				type="outline"
				variant="medium"
				onClick={onJoin}
			/>
		);
	} else return null;
};

export interface INetworkAttendanceButtonProps extends Pick<IButtonProps, 'icon' | 'iconPosition'> {
	network: TCommunity;
	joinTitle?: string;
	requestTitle?: string;
	pendingTitle?: string;
	showRejectButton?: boolean;
	onRequestAccess: () => void;
	onJoin: () => void;
}

export default NetworkAttendanceButton;
