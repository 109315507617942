import { useService } from '@services';
import { useQuery } from '@tanstack/react-query';
import type { TDealFilter } from '@typings';
import type { AxiosError } from 'axios';
import { useSolicitationWaiver } from './useSolicitationWaiver';
import { dayjs } from '@utils';

export const useAllDealFilters = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { isLoading: isWaiverLoading, isMessageShown } = useSolicitationWaiver();

	const query = useQuery<TDealFilter[], AxiosError>(
		['invest.getAllDealFilters'],
		async () => api.invest.getAllDealFilters(),
		{
			enabled: !isWaiverLoading && !isMessageShown,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
			staleTime: dayjs.duration(5, 'minute').asMilliseconds(),
		},
	);

	// Ensure the data is properly typed
	const data = query.data as Record<string, TDealFilter[]> | undefined;

	return { ...query, data: Array.isArray(data) ? data : data?.['hydra:member'] }; // temporary check two different response types: T21C-5585 [@juliayojji]
};

export default useAllDealFilters;
