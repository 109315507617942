import { useMemo } from '@hooks';
import { NOTIFICATION_SETTING_CHANNEL } from '@constants';
import { without } from '@utils';
import type { TDefaultNotificationSetting } from '@typings';

export const useDefaults = (groupName: string, settings: TDefaultNotificationSetting[]) => {
	return useMemo(() => {
		return settings
			.filter((setting) => setting.notificationGroup === groupName)
			.map(({ id, supportedChannels, notificationName, notificationGroup }) => ({
				// creation of title for render by nominationId in snake_case
				// !unfortunately, sometime we have null field instead of notificationName
				name: notificationName || id.charAt(0).toUpperCase() + id.slice(1).split('_').join(' '),
				// back-end team have very "non-comfortable way" to hide/remove extra channels, and we need to control it
				// !!! by actual requirements only PUSH and EMAIL channels, but if we will have extra channels, we also need to CONTROL it
				options: without(supportedChannels, NOTIFICATION_SETTING_CHANNEL.chat),
				notificationId: id,
				group: notificationGroup,
			}));
	}, [groupName, settings]);
};
