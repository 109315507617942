import { useService, usePaginatedInfiniteQuery } from '@hooks';
import type { TCommunity } from '@typings';
import { dayjs } from '@utils';

export const useForeignGroupsQuery = () => {
	const api = useService('ApiService');

	return usePaginatedInfiniteQuery<TCommunity>(
		['community.getForeignGroups'],
		async ({ pageParam }) =>
			await api.community.getForeignGroups({
				page: pageParam,
				pagination: false, // pagination breaks search by groups which is implemented on FE side [@DmitriyNikolenko]
			}),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);
};
