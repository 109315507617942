import { useState } from '@hooks';

export const useImageView = (attachmentsString: string[]) => {
	const [index, setIndex] = useState<number>(-1);

	const currentImage = attachmentsString[index];
	const nextIndex = (index + 1) % attachmentsString.length;
	const nextImage = attachmentsString[nextIndex] || currentImage;
	const prevIndex = (index + attachmentsString.length - 1) % attachmentsString.length;
	const prevImage = attachmentsString[prevIndex] || currentImage;

	// Gallery action handlers.
	const handleClick = (index: number) => setIndex(index);
	const handleClose = () => setIndex(-1);
	const handleMovePrev = () => setIndex(prevIndex);
	const handleMoveNext = () => setIndex(nextIndex);

	return {
		currentImage,
		nextImage,
		prevImage,
		handleClick,
		handleClose,
		handleMovePrev,
		handleMoveNext,
	};
};
