import { useService, useQuery } from '@hooks';
import type { TEvent } from '@typings';

export const useGetMeetingQuery = (
	meetingId?: TEvent['id'],
	callback?: (data: TEvent | null | undefined, error: Error | null) => void,
) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery<TEvent | null, Error>(
		queryKeys.getMeeting(String(meetingId)),
		async () => await api.meeting.getMeeting(meetingId as TEvent['id']),
		{ onSettled: callback, retry: false },
	);
};
