import { useTranslation } from '@hooks';
import { ResetFilters } from '@ui-kit';
import { textRegisterPluralize } from '@utils';

import styles from './NetworkConnectionsFilterTitle.module.css';

const NetworkConnectionsFilterTitle = ({
	networkConnectionsName,
	resetFilters,
}: INetworkConnectionsFilterTitleProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<h3>
				{t('Filtering All {{networkConnectionsName}}', {
					networkConnectionsName: textRegisterPluralize(networkConnectionsName),
				})}
			</h3>
			<ResetFilters title={t('Reset Filters')} onClick={resetFilters} />
		</div>
	);
};

export interface INetworkConnectionsFilterTitleProps {
	networkConnectionsName?: string;
	resetFilters: () => void;
}

export default NetworkConnectionsFilterTitle;
