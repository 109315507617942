import { Page } from '../../components';
import { useSearchParams, useTranslation } from '@hooks';
import { DealForm } from '@ui-modules/deals/components/DealForm';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';
import { Navigate } from '@ui-kit';
import { ROUTES } from '@constants';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';

const NewDealPage = () => {
	const { t } = useTranslation();
	const communityId = useSearchParams()[0].get('communityId');
	useDealsDisabledEffect();
	const { enabled } = useDealsEnabled();

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;

	return (
		<Page scrollDisabled tabName={communityId ? 'networks' : 'invest'} title={t('New Deal Page')}>
			<DealForm />
		</Page>
	);
};

export default NewDealPage;
