import { DropdownMenu, Modal } from '@ui-kit';
import { useMemo, useState, useToggle, useTranslation } from '@hooks';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import { filterTemplateByVersion } from '@ui-modules/connections/utils/filterTemplateByVersion';
import styles from './VersionSection.module.css';
import type { FormikProps } from 'formik';
import type { TNetworkConnectionTemplate } from '@typings';

const VersionSection = ({ isEdit, formProps, formTemplate }: IVersionSectionProps) => {
	const { t } = useTranslation();
	const { values, errors, setFieldError, setFieldValue } = formProps;

	const [preselectedValue, setPreselectedValue] = useState<string | undefined>(undefined);
	const [isModalShown, toggleModalShow] = useToggle(false);

	const handleChange = (selectedVersion: string) => {
		if (selectedVersion === formProps.values.selectedTemplateVersion) return;
		const initialDynamicFieldsValues = Object.values(formProps.values.dynamicFields).map((initialField) => {
			const value = initialField.type === 'boolean' && initialField.required ? 'false' : '';
			return { ...initialField, value };
		});

		const isDynamicFormDirty = Object.values(formProps.values.dynamicFields).find((initialFiled, index) => {
			return initialFiled.value !== initialDynamicFieldsValues[index].value;
		});

		if (isDynamicFormDirty) {
			setPreselectedValue(selectedVersion);
			toggleModalShow(true);
		} else {
			onChange(selectedVersion);
		}
	};

	const onChange = (selectedVersion: string) => {
		setFieldValue(connectionFormFields.SELECTED_TEMPLATE_VERSION, selectedVersion);
		setFieldError(connectionFormFields.SELECTED_TEMPLATE_VERSION, undefined);
		setFieldError(connectionFormFields.DYNAMIC_FIELDS, undefined);

		const filteredFieldsByVersion = filterTemplateByVersion(formTemplate?.dynamicFields, selectedVersion);
		let initialDynamicValues = {};
		filteredFieldsByVersion.forEach((field) => {
			if (field.type === 'heading') return;
			const value = field.type === 'boolean' && field.required ? 'false' : '';
			initialDynamicValues = {
				...initialDynamicValues,
				[field.name]: { ...field, value },
			};
		});

		setFieldValue(connectionFormFields.DYNAMIC_FIELDS, initialDynamicValues);
	};

	const options = useMemo(() => {
		return (
			formTemplate?.availableTemplateVersions?.map((version) => ({
				value: version?.name,
				title: version.label,
			})) || []
		);
	}, [formTemplate?.availableTemplateVersions]);

	// No need to show selection if only one or less version is present
	if (options?.length < 2) {
		return null;
	}

	return (
		<div className={styles.section__container}>
			<DropdownMenu
				disabled={isEdit}
				errorMessage={errors.selectedTemplateVersion}
				iconType={'select'}
				label={formTemplate.labelForVersionSelect}
				labelClassName={styles.dropdown__label}
				options={options}
				placeholder={t('- select -')}
				value={values.selectedTemplateVersion}
				variant="medium"
				onChange={handleChange}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Continue')}
				subTitle={t('This change will clear some of the entered data. Do you wish to continue?')}
				title={t('Are you sure?')}
				visible={isModalShown}
				onCancel={() => toggleModalShow(false)}
				onConfirm={() => {
					if (preselectedValue) {
						onChange(preselectedValue);
					}
					toggleModalShow(false);
					setPreselectedValue(undefined);
				}}
			/>
		</div>
	);
};

export interface IVersionSectionProps {
	isEdit: boolean;
	formProps: FormikProps<TNetworkConnectionFormValues>;
	formTemplate: TNetworkConnectionTemplate;
}

export default VersionSection;
