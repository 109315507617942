import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TNetworkConnection, TNetworkConnectionMemberRelation } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useNetworkConnectionMemberRelationQuery = <T = TResponse>(
	networkConnectionId: TNetworkConnection['id'],
	options?: UseQueryOptions<TResponse, Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TResponse, Error, T>(
		reactQuery.queryKeys.getNetworkConnectionMemberRelation(networkConnectionId),
		async () => (await api.networkConnection.getNetworkConnectionUserRelation(networkConnectionId)) ?? null,
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...options,
		},
	);
};

type TResponse = TNetworkConnectionMemberRelation | null;
