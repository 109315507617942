import { useService } from '../../../services/setup/ServicesContext';
import { saveFile } from '@utils';
import { useNotification } from '../../../common/hooks/useNotification';
import { useMutation } from '@tanstack/react-query';

export const useAddEventToCalendar = (eventId: string) => {
	const axios = useService('AxiosService');
	const { showUnknownError } = useNotification();

	return useMutation(
		['downloadEventCalendarFile'],
		() => axios.get(`/calendar_items/${eventId}/export-ics`, { responseType: 'blob' }),
		{
			onSuccess: (res: unknown) => {
				saveFile(res as Blob, `event-${eventId}.ics`);
			},
			onError: showUnknownError,
		},
	);
};
