import { SearchableCompactScreenHeader, EditIcon, Button, When, Gap, VisuallyHidden } from '@ui-kit';
import { HomePageLayout, Page } from '../../components';
import Feed from '@ui-modules/feed/components/Feed';
import FeedSearchResultList from '@ui-modules/feed/components/FeedSearchResultList';
import PinnedBanners from '@ui-modules/feed/components/PinnedBanners';
import NextGroupMeetingsWidget from '@ui-modules/events/components/NextGroupMeetingsWidget';
import QuickLinksWidget from '@ui-modules/account/components/QuickLinksWidget';
import AdImageGalleyWidget from '@ui-modules/feed/components/AdImageGalleyWidget';
import { useMe, useTranslation, useState, useEvent, useNavigate, useRouteSearchQueryState } from '@hooks';
import { ROUTES } from '@constants';
import styles from './HomePage.module.css';
import { clsx } from '@utils';

const HomePage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	// Route data.
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();
	const [searchQuery, setSearchQuery] = useState(routeSearchQuery, 'HomePage:searchQuery');
	const showSearchResults = !!searchQuery.length;

	const openAddPost = useEvent(() => navigate(ROUTES.addPost()));

	return (
		<Page title={t('Home')}>
			<HomePageLayout
				sidebarContent={
					<>
						<NextGroupMeetingsWidget />
						<QuickLinksWidget />
						<AdImageGalleyWidget />
					</>
				}
			>
				<div>
					<div className={clsx(styles.homePage__container)}>
						<SearchableCompactScreenHeader
							customButtonsElement={
								<>
									<Button
										icon={<EditIcon fill="#000000" height={14} width={14} />}
										iconPosition="left"
										title={t('New Post')}
										variant="small"
										onClick={openAddPost}
									/>
								</>
							}
							placeholder={t('Search posts')}
							searchQuery={searchQuery}
							searchTitle={t('Search')}
							title={t('Home')}
							titleAs="h2"
							onChangeSearchQuery={setSearchQuery}
							onSearchCanceled={() => setRouteSearchQuery('')}
						/>
					</div>
				</div>
				<Gap gap={12} />
				<When condition={!!showSearchResults}>
					<FeedSearchResultList
						communityName=""
						containerClassName={styles.homePage__searchFeed}
						searchQuery={searchQuery}
						onSearch={setRouteSearchQuery}
					/>
				</When>
				<VisuallyHidden hidden={showSearchResults}>
					<PinnedBanners
						containerClassName={styles.homePage__pinnedBanner}
						predicateToFilter={(banner) => banner?.globalFeedName?.includes('GLOBAL')}
					/>
				</VisuallyHidden>
				<Feed
					containerClassName={styles.homePage__feed}
					hidden={showSearchResults}
					isActivitySourceHidden={false}
					userId={user.slug}
				/>
			</HomePageLayout>
		</Page>
	);
};

export default HomePage;
