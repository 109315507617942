import { useRestoreVirtuosoInitialIndex, useTranslation } from '@hooks';
import styles from './ChatMembers.module.css';
import type { TUserProfileRecord } from '@typings';
import { Virtuoso, UserListItem, ActivityIndicator } from '@ui-kit';
import { formatUserSubTitle } from '@utils';

const ChatMembers = ({
	listId,
	isLoading,
	channelMembers,
	onClick,
	showBlockIcon,
	onBlockUnBlockPressed,
}: IChatMembersParams) => {
	const { t } = useTranslation();
	const { initialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(`ChatMembers-${listId}`);

	const renderUserItem = (index: number, data: TUserProfileRecord) => {
		return (
			<div className={styles.membersContainer}>
				<UserListItem
					renderUserSubTitle={formatUserSubTitle(t)}
					showBlockIcon={showBlockIcon}
					size="small"
					userProfileRecord={data}
					withSeparator={false}
					onBlockUnBlockPressed={onBlockUnBlockPressed}
					onClick={onClick}
				/>
			</div>
		);
	};
	return (
		<Virtuoso<TUserProfileRecord>
			className={styles.chatMembersResultList}
			components={{
				EmptyPlaceholder: () => (isLoading ? <ActivityIndicator size="medium" type="fit" /> : null),
			}}
			data={channelMembers}
			initialTopMostItemIndex={initialTopMostItemIndex}
			itemContent={renderUserItem}
			rangeChanged={onRangeChanged}
			totalCount={channelMembers.length}
		/>
	);
};

export default ChatMembers;

export interface IChatMembersParams {
	isLoading: boolean;
	channelMembers: TUserProfileRecord[];
	onClick?: (user: TUserProfileRecord) => void;
	showBlockIcon?: boolean;
	/** Is used to persist scroll position. Should be very unique. */
	listId: string;
	onBlockUnBlockPressed?: (user: TUserProfileRecord) => void;
}
