import FileTypeAvatar from '../FileTypeAvatar';
import { FileDownloadIcon } from '@ui-kit/icons';
import { clsx, formatBytes } from '@utils';
import styles from './FileDownloadCard.module.css';

/** List component for displaying file information. */
const FileDownloadCard = ({ file, onClick }: IFileListItemProps) => {
	const handleClick = () => onClick?.(file);
	return (
		<div className={clsx(styles.fileListItem)} onClick={handleClick} onKeyPress={handleClick}>
			<div className={styles.fileListItem__header}>
				<FileTypeAvatar mimeType={`${file?.attachment?.mime_type}`} />
			</div>
			<div className={styles.fileListItem__body}>
				<h4 className={styles.fileListItem__title}>{file?.attachment?.title}</h4>
				{file.attachment?.file_size ? (
					<span className={styles.fileListItem__subTitle}>
						{formatBytes(parseFloat(`${file.attachment?.file_size}`))}
					</span>
				) : (
					''
				)}
			</div>
			<div className={styles.fileListItem__iconContainer}>
				<FileDownloadIcon fill="black" />
			</div>
		</div>
	);
};

export type TChatAttachment = {
	asset_url?: string;
	file_size?: string | number | undefined;
	mime_type?: string;
	title?: string;
};
export type TChatFile = {
	attachment?: TChatAttachment;
};
export interface IFileListItemProps {
	/** The TFile entity object. */
	file: TChatFile;
	/** Called when the item clicked. */
	onClick?: (file: TChatFile) => void;
}

export default FileDownloadCard;
