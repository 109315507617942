import { useService, useMutation, useQueryClient, useNotification, useTranslation, useMe } from '@hooks';
import type { TBlockedUserRelation, TUser } from '@typings';

export const useBlockUserMutation = () => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { user } = useMe();
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	return useMutation<TBlockedUserRelation, Error, TUser['id']>(
		['blockUser'],
		async (userId) => await api.userBlocking.blockUser(userId, user.id),
		{
			async onSuccess(blockedUserRelation) {
				const userName = `${blockedUserRelation.blockedUser.firstName} ${blockedUserRelation.blockedUser.lastName}`;
				showSuccess({ title: t('{{userName}} blocked', { userName }) });
				await queryClient.refetchQueries(['userBlocking.getBlockedUsers']);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
