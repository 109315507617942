import { useService, useMutation, useNotification, useMe, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TAssetAllocation, TAssetAllocationValues } from '@typings';

export const usePostAssetAllocationMutation = (
	options?: MutationOptions<TAssetAllocation, Error, TAssetAllocationValues>,
) => {
	const api = useService('ApiService');
	const { user } = useMe();
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess } = useNotification();
	const { t } = useTranslation();

	return useMutation<TAssetAllocation, Error, TAssetAllocationValues>(
		reactQuery.queryKeys.postMyAssetAllocation(),
		async (assetAllocationValues) => await api.assetAllocation.postAssetAllocation(user.id, assetAllocationValues),
		{
			...options,
			onSuccess: (assetAllocation, variables, context) => {
				reactQuery.queryClient.setQueriesData(reactQuery.queryKeys.getMyAssetAllocation(), assetAllocation);
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getAssetAllocationCommunities());
				showSuccess({ title: t('Asset Allocation Updated') });
				options?.onSuccess?.(assetAllocation, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
