import { Card } from '@ui-kit';
import { useMyTimezone, useTranslation } from '@hooks';
import { dayjs } from '@utils';
import type { TEvent } from '@typings';

const EventDateAndTimeCard = ({ eventDatesInfo: { startDate, endDate } }: IEventDateAndTimeCardProps) => {
	const { t } = useTranslation();
	const { timezone, formattedTimezone } = useMyTimezone();
	const StartDate = dayjs.utc(startDate).tz(timezone);
	const EndDate = dayjs.utc(endDate).tz(timezone);
	const datesBelongsToTheSameDay = StartDate.diff(EndDate, 'days') === 0;

	const formattedDate = datesBelongsToTheSameDay
		? `${StartDate.format('dddd, D MMM YYYY. h:mma')} - ${EndDate.format('h:mma')} ${formattedTimezone}`
		: `${StartDate.format('dddd, D MMM. h:mma')} - ${EndDate.format('dddd, D MMM YYYY. h:mma')} ${formattedTimezone}`;

	return (
		<Card backgroundPlacement="none" title={t('Date & time')}>
			<span>{formattedDate}</span>
		</Card>
	);
};

export interface IEventDateAndTimeCardProps {
	eventDatesInfo: Pick<TEvent, 'startDate' | 'endDate' | 'timezone'>;
}

export default EventDateAndTimeCard;
