import { useRef, useState } from '@hooks';
import styles from './SearchableCompactScreenHeader.module.css';
import Button from '../Button';
import HeaderSearchInput from '../HeaderSearchInput';
import { SearchIcon } from '@ui-kit';
import type { ReactNode } from 'react';
import { clsx } from '@utils';

const SearchableCompactScreenHeader = ({
	title,
	titleAs: TitleComponent,
	searchTitle,
	searchQuery,
	placeholder,
	clearMode = true,
	spaceMode = true,
	onChangeSearchQuery,
	onFocus,
	onBlur,
	customButtonsElement,
	onSearchOpened,
	onSearchCanceled,
}: ISearchableCompactScreenHeaderProps) => {
	const [isExpanded, setIsExpanded] = useState(!!searchQuery.length);
	const inputRef = useRef<HTMLInputElement>(null);

	const expandSearch = () => {
		setIsExpanded(true);
		if (onSearchOpened) onSearchOpened(true);
		setTimeout(() => {
			inputRef.current && inputRef.current.focus();
		}, 0);
	};

	const onSearchCleared = () => {
		onChangeSearchQuery('');
	};

	const handleSearchCanceled = () => {
		inputRef.current?.blur();
		onBlur?.();
		setIsExpanded(false);
		if (onSearchOpened) onSearchOpened(false);
		onChangeSearchQuery('');
		onSearchCanceled?.();
	};

	return (
		<div className={styles.searchHeader}>
			<div className={styles.searchHeader__controller}>
				<TitleComponent className={styles.searchHeader__title}>{title}</TitleComponent>
				{isExpanded ? (
					<div className={clsx(styles.searchHeader__input, spaceMode && styles.searchHeader__spaced)}>
						<HeaderSearchInput
							placeholder={placeholder}
							ref={inputRef}
							type="cleared"
							value={searchQuery}
							onBlur={() => {
								onBlur?.();
							}}
							onChange={(e) => onChangeSearchQuery(e.target.value)}
							onFocus={() => {
								onFocus?.();
							}}
							onReset={() => onChangeSearchQuery('')}
							onSearchCanceled={handleSearchCanceled}
							onSearchCleared={clearMode ? onSearchCleared : undefined}
						/>
					</div>
				) : (
					<div className={styles.searchHeader__buttons}>
						<Button
							icon={<SearchIcon className={styles.searchHeader__buttonIcon} height={12} width={12} />}
							title={searchTitle}
							variant="small"
							onClick={expandSearch}
						/>
						{customButtonsElement}
					</div>
				)}
			</div>
		</div>
	);
};

export interface ISearchableCompactScreenHeaderProps {
	title: string;
	titleAs: 'h2' | 'h3';
	searchTitle: string;
	searchQuery: string;
	placeholder: string;
	clearMode?: boolean;
	spaceMode?: boolean;
	onChangeSearchQuery: (searchQuery: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	customButtonsElement?: ReactNode;
	onSearchOpened?: (open: boolean) => void;
	onSearchCanceled?: () => void;
}

export default SearchableCompactScreenHeader;
