import { useMutation, useService } from '@hooks';
import { useUploadCalendarItems } from '@ui-modules/events/hooks/useUploadCalendarItems';
import { dayjs } from '@utils';
import type { TEvent } from '@typings';

export const useUpdateCalendarItemCacheMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { calendar, useBindActionCreators } = useService('ReduxService');

	const { updateCalendarItem } = useBindActionCreators(calendar);
	const { refreshCalendarItems } = useUploadCalendarItems();

	return useMutation<TEvent, Error, TEvent['id']>(
		['updateCalendarItemCache'],
		async (calendarItemId) => api.calendarItem.getCalendarItem(calendarItemId),
		{
			onSuccess: (calendarItem, calendarItemId) => {
				reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getCalendarItem(calendarItemId), calendarItem);
				reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getMeeting(calendarItemId), calendarItem);
				calendarItem.groups?.forEach?.((group) => {
					const communityId = group?.community?.id;
					reactQuery.updateGroupMeetingsCache(communityId, calendarItemId, () => calendarItem);
				});
				updateCalendarItem({ calendarItemId, callback: () => calendarItem });
				refreshCalendarItems(calendarItem.startDate, dayjs(calendarItem.startDate).add(1, 'day').toISOString());
			},
		},
	);
};
