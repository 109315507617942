import { Outlet, Page, PageLayout } from '../../components';
import GroupSectionList from '@ui-modules/groups/components/GroupSectionList';
import { SearchableScreenHeader, Backdrop } from '@ui-kit';
import {
	useTranslation,
	useState,
	useParams,
	useEvent,
	useNavigate,
	useLocation,
	useRestoreVirtuosoInitialIndex,
} from '@hooks';
import { ROUTES } from '@constants';
import type { TCommunityCompact, TUid } from '@typings';

const GroupsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const [isSearchActive, setIsSearchActive] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } =
		useRestoreVirtuosoInitialIndex('GroupsPage__GroupSectionList');

	const params = useParams<IGroupsPageParams>();
	const selectedGroupId = (params.groupId ?? params.chapterId) as string;
	const handleSelectGroup = useEvent((group: TCommunityCompact, index: number) => {
		if (group.definition === 'group') {
			navigate(ROUTES.groupTab(group.id));
		} else if (group.definition === 'chapter') {
			navigate(ROUTES.chapterTab(group.id));
		}
		if (!isSearchActive) setInitialTopMostItemIndex(index);
	});
	const handleFirstGroupLoaded = useEvent((firstLoadedGroup: TCommunityCompact | undefined) => {
		if (!!firstLoadedGroup && !selectedGroupId && location.pathname === ROUTES.groups()) {
			setTimeout(() => navigate(ROUTES.groupTab(firstLoadedGroup.id), { replace: true }), 0); // To resolve "You should call navigate() in a React.useEffect(), not when your component is first rendered." [@dmitriy.nikolenko]
		}
	});

	return (
		<Page title={t('Groups')}>
			<PageLayout
				containerMode={false}
				sidebarContent={
					<>
						<SearchableScreenHeader
							placeholder={t('Search groups')}
							searchQuery={searchQuery}
							searchTitle={t('Search')}
							title={t('Groups')}
							onBlur={() => setIsSearchActive(false)}
							onChangeSearchQuery={setSearchQuery}
							onFocus={() => setIsSearchActive(true)}
						/>
						<GroupSectionList
							initialTopMostItemIndex={initialTopMostItemIndex}
							rangeChanged={onRangeChanged}
							searchQuery={searchQuery}
							selectedGroupId={selectedGroupId}
							onFirstGroupLoaded={handleFirstGroupLoaded}
							onSelectGroup={handleSelectGroup}
						/>
					</>
				}
			>
				<Outlet /> {/* GroupPage is rendered here */}
				<Backdrop hidden={!isSearchActive} />
			</PageLayout>
		</Page>
	);
};

type IGroupsPageParams = Record<string, string | undefined> & {
	groupId?: TUid | undefined;
	chapterId?: TUid | undefined;
};

export default GroupsPage;
