import { useEffect, useRef, useState, useCounter } from '@hooks';
import { dayjs } from '@utils';

const UPDATE_TRIGGER_INTERVAL = dayjs.duration(3, 'seconds').asMilliseconds();
const CHECK_STUCK_TO_REFETCH_TIMEOUT = dayjs.duration(10, 'seconds').asMilliseconds();

/** Helps to handle when the video should be showed.
 * 	One of the reason is hide internal ERROR_PROCESSING message when video is indeed valid, but is not compiled on the server yet.
 *  @author dmitriy.nikolenko
 */
export const useApiVideoState = (id?: string) => {
	const [updateTrigger, { inc: incrementUpdateTrigger }] = useCounter(0);
	const [readiness, setReadiness] = useState<'error' | 'ready' | 'undefined'>('undefined');

	const onErrorIntervalRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const onStuckTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const onStuckHandledRef = useRef<boolean>(false);
	useEffect(() => {
		return () => {
			clearTimeout(onStuckTimeoutRef.current);
			clearInterval(onErrorIntervalRef.current);
		};
	}, []);

	/** Set the 'error' state to hide ERROR_PROCESSING message and show some activity indicator instead.
	 *  You will use "trigger" to re-render a video component to wait until the video is ready to be showed.
	 */
	const setError = () => {
		setReadiness('error');
		onErrorIntervalRef.current = setTimeout(incrementUpdateTrigger, UPDATE_TRIGGER_INTERVAL);
	};

	/** Set the 'ready' state to allow showing of the video. */
	const setReady = () => {
		setReadiness('ready');
		clearInterval(onErrorIntervalRef.current);
	};

	/** Sometimes for some reason Player duplicates requests to https://vod.api.video/vod/XXX/token/YYY/player.json
	 * 	and after second call due to token which became stale the Player is stuck.
	 *  We are calling refetch to get new token once trying to resolve this issue.
	 * 	Fixes T21C-5563 @DmitriyNikolenko
	 */
	const setDataPrepared = (prepared: boolean, refetchCallback: () => void) => {
		if (prepared) {
			if (!onStuckHandledRef.current && readiness === 'undefined') {
				onStuckTimeoutRef.current = setTimeout(() => {
					if (readiness === 'undefined') {
						refetchCallback();
						onStuckHandledRef.current = true;
					}
				}, CHECK_STUCK_TO_REFETCH_TIMEOUT);
			}
		} else {
			clearTimeout(onStuckTimeoutRef.current);
		}
	};

	return { readiness, updateTrigger, setReady, setError, setDataPrepared };
};
