import { useService } from '@services';
import { useQuery } from '@hooks';
import type { TDealAssetClass } from '@typings';

export const useGetDealAssetClassesQuery = (userId?: string) => {
	const api = useService('ApiService');
	return useQuery<TDealAssetClass[], Error>(['dealAssetClass.getAssetClasses', userId], () =>
		api.dealAssetClass.getAssetClasses(userId),
	);
};
