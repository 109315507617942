import type { TDealAssetClass, TDealSubClass } from '@typings';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

export const arrangeNetworksForFilter = (arr: TDealAssetClass[], defaultValue = false, hasChild = false) => {
	const result: ICheckmarkState[] = [];
	arr?.forEach((item) => {
		const checkmarkState: ICheckmarkState = {
			label: item?.name,
			value: defaultValue,
			id: item?.id,
			children:
				hasChild && item?.dealSubClasses?.length
					? item.dealSubClasses.map((value: string | TDealSubClass) => {
							const { name, id } = typeof value === 'string' ? { name: value, id: '' } : value;
							return {
								label: name,
								value: defaultValue,
								id: id,
							};
					  })
					: null,
		};

		result.push(checkmarkState);
	});

	return result;
};
