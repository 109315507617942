import { CheckboxInput } from '@ui-kit';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';

const CheckBoxChild = ({ child, handleChildPress, filterCheckbox = false }: TChildRender) => {
	return (
		<CheckboxInput
			filterCheckbox={filterCheckbox}
			label={child.label}
			value={child.value}
			onChange={() => handleChildPress(child.id)}
		/>
	);
};

export type TChildRender = {
	child: ICheckmarkState;
	parent: ICheckmarkState;
	handleChildPress: (id: string) => void;
	filterCheckbox?: boolean;
};

export default CheckBoxChild;
