import { useQuery } from '@hooks';
import { usePlacesService } from '../contexts/PlacesServiceContext';
import { dayjs, openMapUrl } from '@utils';
import type { TCoordinatesLocation } from '@typings';

export const useFindAddressLocation = (defaultLocation: TCoordinatesLocation | undefined, address: string) => {
	const placesService = usePlacesService();

	const { data: location, isFetching } = useQuery<TCoordinatesLocation | undefined>(
		['placesService.textSearch', address],
		async () =>
			await new Promise((resolve, reject) => {
				placesService?.textSearch({ query: address }, (results, status: string) => {
					if (status === 'OK' && results?.length) {
						const result = results[0];
						const location = result?.geometry?.location;

						if (location) {
							resolve({ lat: location.lat(), lng: location.lng() });
						} else {
							resolve(defaultLocation);
						}
					} else {
						resolve(defaultLocation);
					}
				});
			}),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
			enabled: !!placesService && !!address?.length,
		},
	);

	const openLocation = () => location && openMapUrl(location.lat, location.lng);

	return { location, openLocation, isFetching };
};
