import { useRef, useState } from '@hooks';
import styles from './SearchableScreenHeader.module.css';
import Button from '../Button';
import HeaderSearchInput from '../HeaderSearchInput';
import { SearchIcon } from '@ui-kit';
import type { ReactNode } from 'react';
import { clsx } from '@utils';

const SearchableScreenHeader = ({
	title,
	searchTitle,
	searchQuery,
	placeholder,
	clearMode = true,
	spaceMode = true,
	onChangeSearchQuery,
	onFocus,
	onBlur,
	customButtonsElement,
	customLeftButtonsElement,
	onSearchOpened,
	noSearch,
	autoSizeDimensions = { horizontal: true, vertical: true },
	onSearchCanceled,
}: ISearchableScreenHeaderProps) => {
	const [isExpanded, setIsExpanded] = useState(!!searchQuery.length);
	const inputRef = useRef<HTMLInputElement>(null);

	const expandSearch = () => {
		setIsExpanded(true);
		if (onSearchOpened) onSearchOpened(true);
		setTimeout(() => {
			inputRef.current && inputRef.current.focus();
		}, 0);
	};

	const onSearchCleared = () => {
		inputRef.current?.focus();
		onChangeSearchQuery('');
	};

	const handleSearchCanceled = () => {
		inputRef.current?.blur();
		setIsExpanded(false);
		if (onSearchOpened) onSearchOpened(false);
		onChangeSearchQuery('');
		onSearchCanceled?.();
	};

	return (
		<div
			className={clsx(
				styles.searchHeader,
				autoSizeDimensions.horizontal && styles.searchHeaderHorizontal,
				autoSizeDimensions.vertical && styles.searchHeaderVertical,
			)}
		>
			{isExpanded ? (
				<div className={clsx(styles.searchHeader__input, spaceMode && styles.searchHeader__spaced)}>
					<HeaderSearchInput
						placeholder={placeholder}
						ref={inputRef}
						type="cleared"
						value={searchQuery}
						onBlur={onBlur}
						onChange={(e) => onChangeSearchQuery(e.target.value)}
						onFocus={onFocus}
						onSearchCanceled={handleSearchCanceled}
						onSearchCleared={clearMode ? onSearchCleared : undefined}
					/>
				</div>
			) : (
				<div className={styles.searchHeader__controller}>
					<div>
						<span className={styles.searchHeader__title}>{title}</span>
						{customLeftButtonsElement}
					</div>
					<div className={styles.searchHeader__buttons}>
						{noSearch ? null : (
							<Button
								icon={<SearchIcon className={styles.searchHeader__buttonIcon} height={12} width={12} />}
								title={searchTitle}
								variant="small"
								onClick={expandSearch}
							/>
						)}

						{customButtonsElement}
					</div>
				</div>
			)}
		</div>
	);
};

export interface ISearchableScreenHeaderProps {
	title: string;
	searchTitle: string;
	searchQuery: string;
	placeholder: string;
	clearMode?: boolean;
	spaceMode?: boolean;
	onChangeSearchQuery: (searchQuery: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	customButtonsElement?: ReactNode;
	customLeftButtonsElement?: ReactNode;
	onSearchOpened?: (open: boolean) => void;
	onSearchCanceled?: () => void;
	noSearch?: boolean;
	autoSizeDimensions?: TAutoSizeDimensions;
}

type TAutoSizeDimensions = {
	horizontal?: boolean;
	vertical?: boolean;
};

export default SearchableScreenHeader;
