import { useMemo, useTranslation } from '@hooks';
import type { TDeal, TDealMemberRelationsStatus } from '@typings';
import { RibbonFlag } from '@ui-kit';
import styles from './DealRibbon.module.css';
import { dealRibbonColor } from '@ui-modules/deals/utils/dealRibbonColor';

const DealRibbon = ({ hasPreview, canSeePreview, dealStatus, status, isDraftDeal }: IDealRibbonProps) => {
	const { t } = useTranslation();
	const ribbonParams = useMemo(() => {
		let params: { title: string; color?: string } = { title: '' };
		if (hasPreview && canSeePreview) {
			params = { color: dealRibbonColor('review'), title: t('Review new Updates') };
		} else if (isDraftDeal && status) {
			params = { color: dealRibbonColor(status), title: status };
		} else if (dealStatus) {
			params = { color: dealRibbonColor(dealStatus), title: dealStatus };
		}
		return params;
	}, [hasPreview, isDraftDeal, status, dealStatus]);

	if (!ribbonParams.title) return null;
	return (
		<RibbonFlag
			className={styles.dealCard__ribbon}
			color={ribbonParams.color}
			size="small"
			title={ribbonParams.title}
		/>
	);
};

export interface IDealRibbonProps {
	hasPreview?: boolean;
	canSeePreview: boolean;
	dealStatus?: TDealMemberRelationsStatus;
	status: TDeal['status'];
	isDraftDeal?: boolean;
}

export default DealRibbon;
