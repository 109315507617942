import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';

export const useForeignLifestyleNetworksQuery = (networkTab: string) => {
	const api = useService('ApiService');
	const { queryKeys } = useService('ReactQueryService');

	return useQuery(
		queryKeys.getForeignLifestyleNetworksList(networkTab),
		async () => await api.community.getForeignLifestyleNetworksList(networkTab),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);
};
