import { useTranslation, useNavigate, useToggle, useMe } from '@hooks';
import { compact, DealEntity } from '@utils';
import { BlockIcon, DeleteIcon, Modal, PencilBoldIcon, UserCheckIcon, UserSlashIcon, AlertIcon } from '@ui-kit';
import type { TMeatballMenuOption } from '@ui-kit';
import type { TCommunity, TDeal } from '@typings';
import { useChangeDealStatusMutation } from './useChangeDealStatusMutation';
import { useManageThirdPartyAssesTokenMutation } from './useManageThirdPartyAssesTokenMutation';
import { ROUTES } from '@constants';

export const useMutateDealMeatballMenuOptions = (
	deal: TDeal,
	communityId: TCommunity['id'],
	onCancelDealSuccess?: () => void,
	currentNetworkId?: TCommunity['id'],
	restoreScrollRoute?: string,
	onClick?: () => void,
) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const navigate = useNavigate();
	const { onCloseDeal, onCancelDeal } = useChangeDealStatusMutation(deal.id, communityId, onCancelDealSuccess);
	const { revokeAccess, restoreAccess } = useManageThirdPartyAssesTokenMutation(deal.id);

	const [showCloseDealDialog, toggleShowCloseDealDialog] = useToggle(false);
	const [showCancelDealDialog, toggleShowCancelDealDialog] = useToggle(false);
	const [showRestoreAccessDialog, toggleShowRestoreAccessDialog] = useToggle(false);
	const [showRevokeAccessDialog, toggleShowRevokeAccessDialog] = useToggle(false);

	const meatballMenuOptions: TMeatballMenuOption[] = compact([
		(DealEntity.canEdit(deal, user) || DealEntity.canChairEdit(deal, user)) && {
			text: t('Edit Deal'),
			onClick: () => {
				onClick && onClick();
				navigate(ROUTES.editDeal(deal.id, currentNetworkId), { state: { restoreScrollRoute } });
			},
			icon: <PencilBoldIcon fill="black" height={16} strokeWidth={4} width={16} />,
			description: t('Change the information on this Deal.'),
			type: 'solid',
		},
		DealEntity.canRestoreThirdPartyAssesToken(deal, user) && {
			text: t('Restore Third Party Access'),
			onClick: () => toggleShowRestoreAccessDialog(),
			icon: <UserCheckIcon />,
			description: t('Restores collaborator’s ability to suggest changes to deal details'),
			type: 'solid',
		},
		DealEntity.canRevokeThirdPartyAssesToken(deal, user) && {
			text: t('Revoke Third Party Access'),
			onClick: () => toggleShowRevokeAccessDialog(),
			icon: <UserSlashIcon />,
			description: t('Prevents collaborator from making further suggestions to deal details'),
			type: 'solid',
		},
		!DealEntity.userIsDealOwner(deal, user) && {
			text: t('Report This Deal'),
			onClick: () => navigate(ROUTES.reportContent('deal', deal.id)),
			icon: <AlertIcon fill="#000000" />,
			description: t('Flag this Deal for further review.'),
			type: 'solid',
		},
		DealEntity.canClose(deal, user) && {
			text: t('Close Deal'),
			onClick: () => toggleShowCloseDealDialog(),
			icon: <BlockIcon />,
			description: t('Notifies Members of closure and disables further Deal notifications.'),
			type: 'solid',
		},
		DealEntity.canCancel(deal, user) && {
			text: t('Cancel Deal'),
			onClick: () => toggleShowCancelDealDialog(),
			type: 'destructive',
			icon: <DeleteIcon />,
			description: t('Permanently removes Deal for all TIGER 21 Members.'),
		},
	]);

	const ConfirmationModals = (
		<>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Close Deal')}
				subTitle={t('Are you sure you want to close this Deal? This cannot be undone.')}
				title={t('Close this Deal?')}
				variant="medium"
				visible={showCloseDealDialog}
				onCancel={toggleShowCloseDealDialog}
				onConfirm={() => {
					onCloseDeal();
					toggleShowCloseDealDialog();
				}}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Cancel Deal')}
				subTitle={t('Are you sure you want to cancel this Deal? This cannot be undone.')}
				title={t('Cancel this Deal?')}
				variant="medium"
				visible={showCancelDealDialog}
				onCancel={toggleShowCancelDealDialog}
				onConfirm={() => {
					onCancelDeal();
					toggleShowCancelDealDialog();
				}}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Restore Access')}
				subTitle={t('Are you sure you want to restore third party access?')}
				title={t('Restore Access?')}
				variant="medium"
				visible={showRestoreAccessDialog}
				onCancel={toggleShowRestoreAccessDialog}
				onConfirm={() => {
					restoreAccess();
					toggleShowRestoreAccessDialog();
				}}
			/>
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('Revoke Access')}
				subTitle={t(' Are you sure you want to revoke third party access?')}
				title={t('Revoke Access?')}
				variant="medium"
				visible={showRevokeAccessDialog}
				onCancel={toggleShowRevokeAccessDialog}
				onConfirm={() => {
					revokeAccess();
					toggleShowRevokeAccessDialog();
				}}
			/>
		</>
	);

	return [meatballMenuOptions, ConfirmationModals] as const;
};
