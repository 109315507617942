import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import { useEffect, useNavigate, useParams, useState } from '@hooks';
import type { TNetworksTabName, TUid } from '@typings';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkTabsQuery } from '@ui-modules/communities/hooks/useNetworkTabsQuery';

export const useNetworksTabsState = (isSearchActive?: boolean) => {
	const navigate = useNavigate();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const routeParams = useParams<INetworksPageParams>();
	const { data: communityTabs = [] } = useNetworkTabsQuery({ suspense: true });
	const { isTabRoute, networkId, tabName } = useTransformNetworkParams(routeParams);
	const { data: community } = useCommunityQuery(networkId);

	const onTabChanged = (index: number) => {
		setCurrentTabIndex(index);
		if (!isSearchActive) {
			navigate(`${ROUTES.networks()}/${communityTabs[index]['slug']}`);
		}
	};

	useEffect(
		function openProperTabByDirectUrl() {
			if (community && community.id === networkId && !isTabRoute) {
				const tabIndex = communityTabs.findIndex((communityTab) => community.tab === communityTab.slug);
				if (tabIndex === -1) {
					// If no tab preset let's suppose it is an invest network.
					const investTabIndex = communityTabs.findIndex(
						(communityTab) => communityTab.slug === INVEST_NETWORKS_TAB_SLUG,
					);
					setCurrentTabIndex(investTabIndex);
				} else {
					setCurrentTabIndex(tabIndex);
				}
			} else if (isTabRoute) {
				const tabIndex = communityTabs.findIndex((tab) => tab['slug'] === tabName);
				setCurrentTabIndex(Math.max(0, tabIndex));
			}
		},
		[networkId, tabName, isTabRoute, community],
	);

	return {
		currentTabIndex,
		onTabChanged,
		isTabRoute,
		networkId,
		communityTabs,
	};
};

export const useTransformNetworkParams = (params: Readonly<Partial<INetworksPageParams>>) => {
	const { data: communityTabs = [] } = useNetworkTabsQuery({ suspense: true });
	const isTabRoute = communityTabs.find(
		(communityTab) => communityTab['slug'] === (params?.networkId as TNetworksTabName),
	);

	return {
		networkId: isTabRoute ? undefined : params?.networkId,
		tabName: isTabRoute ? params?.networkId : undefined,
		isTabRoute,
	};
};

interface INetworksPageParams extends Record<string, string | undefined> {
	networkId: TUid | undefined;
}
