import { FormPageLayout, Page } from '../../components';
import { useTranslation } from '@hooks';
import styles from './EditProfileInformationPage.module.css';
import { Formik, Input } from '@ui-kit';
import type { TEditProfileInfo } from '@schemas';
import { getEditProfileInfoSchema } from '@schemas';
import { useUpdateUserMutation } from '@ui-modules/profile/hooks/useUpdateUserMutation';
import ImagePickerInput from '@ui-modules/profile/components/ImagePickerInput';
import { ROUTES } from '@constants';

const EditProfileInformationPage = () => {
	const { t } = useTranslation();
	const { initialValues, uploadAvatar, submit, isUploadingAvatar } = useUpdateUserMutation();
	const editProfileInfoSchema = getEditProfileInfoSchema(t);

	return (
		<Page title={t('Edit Profile Information')}>
			<Formik<TEditProfileInfo>
				enableReinitialize
				initialValues={editProfileInfoSchema.cast(initialValues) as TEditProfileInfo}
				validateOnMount
				validationSchema={editProfileInfoSchema}
				onSubmit={(values) => submit(editProfileInfoSchema.cast(values) as TEditProfileInfo)}
			>
				{(formProps) => {
					const { values, handleChange, setFieldValue, handleBlur, errors } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileInformation()]}
							formProps={formProps}
							headerTitle={t('Profile Information')}
							saveTrackingName="profile--edit-primary-info"
						>
							<div className={styles.information}>
								<div className={styles.information__container}>
									<span className={styles.information__title}>{t('This is only changing content on profile')}</span>
									<Input
										errorMessage={errors.firstName}
										label={t('First Name')}
										value={values.firstName}
										onBlur={handleBlur('firstName')}
										onChange={handleChange('firstName')}
									/>
									<Input
										errorMessage={errors.lastName}
										label={t('Last Name')}
										value={values.lastName}
										onBlur={handleBlur('lastName')}
										onChange={handleChange('lastName')}
									/>
									<Input
										errorMessage={errors.location}
										label={t('Location')}
										value={values.location || ''}
										onBlur={handleBlur('location')}
										onChange={handleChange('location')}
									/>
									<ImagePickerInput
										buttonTitle={t('Edit Image')}
										label={t('Profile Image')}
										loading={isUploadingAvatar}
										value={values.avatarMediaObject}
										onChange={(value) => setFieldValue('avatarMediaObject', value)}
										onUpload={uploadAvatar}
									/>
								</div>
							</div>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileInformationPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileInformationPage;
