import type { MessageContextValue } from 'stream-chat-react';
import { clsx } from '@utils';

export const getRootClassName = (
	{
		endOfGroup,
		firstOfGroup,
		groupedByUser,
		highlighted,
		isMyMessage,
		message,
	}: Pick<
		MessageContextValue,
		'endOfGroup' | 'firstOfGroup' | 'groupedByUser' | 'highlighted' | 'isMyMessage' | 'message'
	>,
	hasReactions: boolean,
) =>
	clsx(
		'str-chat__message str-chat__message-simple',
		`str-chat__message--${message.type}`,
		`str-chat__message--${message.status}`,
		isMyMessage() ? 'str-chat__message--me str-chat__message-simple--me' : 'str-chat__message--other',
		message.text ? 'str-chat__message--has-text' : 'has-no-text',
		{
			'pinned-message': message.pinned,
			'str-chat__message--highlighted': highlighted,
			'str-chat__message--with-reactions str-chat__message-with-thread-link': hasReactions,
			'str-chat__message-send-can-be-retried': message?.status === 'failed' && message?.errorStatusCode !== 403,
			'str-chat__virtual-message__wrapper--end': endOfGroup,
			'str-chat__virtual-message__wrapper--first': firstOfGroup,
			'str-chat__virtual-message__wrapper--group': groupedByUser,
		},
	);
