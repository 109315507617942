import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import type { TDeal, TCommunity } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useRejectDealReviewMutation = (
	dealId: TDeal['id'],
	communityId: TCommunity['id'],
	options?: MutationOptions<void, Error, string | undefined>,
) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showInfo } = useNotification();

	return useMutation<void, Error, string | undefined>(
		['deal.rejectDealPreview'],
		(reason: string | undefined) => api.invest.rejectDealPreview(dealId, reason),
		{
			...options,
			onSuccess: async (data, variables, context) => {
				await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
				reactQuery.refetchDealsQueries(communityId);
				showInfo({ title: t('Deal review changes was rejected') });
				options?.onSuccess?.(data, variables, context);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
