import { memo } from 'react';
import { RibbonFlag } from '@ui-kit';
import { useTranslation } from '@hooks';
import { translateEventAttendance } from '@utils';
import type { TEvent } from '@typings';

const EventLocationFlag = ({
	eventLocationInfo: { isAllowedInPersonAttendance, isAllowedVirtualAttendance },
}: IEventLocationFlagProps) => {
	const { t } = useTranslation();

	const title = translateEventAttendance(t)(isAllowedInPersonAttendance, isAllowedVirtualAttendance);

	return <RibbonFlag title={title} />;
};

export interface IEventLocationFlagProps {
	eventLocationInfo: Pick<TEvent, 'isAllowedInPersonAttendance' | 'isAllowedVirtualAttendance'>;
}

export default memo(EventLocationFlag);
