import { IdxStatus } from '@okta/okta-auth-js';
import { OktaError } from './AbstractOktaError';
import { SUPPORT_LINK } from '@constants';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaWrongPasswordError extends OktaError {
	constructor(private readonly transaction: IdxTransaction) {
		super();
	}

	status!: 'WRONG_PASSWORD';

	static shouldThrowError(transaction: IdxTransaction) {
		return (
			transaction.status === IdxStatus.PENDING &&
			transaction.nextStep?.name === 'reset-authenticator' &&
			transaction.messages?.some?.((message) => message.class === 'ERROR')
		);
	}

	getMessage(t: TFunction) {
		const message = this.transaction.messages
			?.reduce?.<string[]>((errorMessage, message) => {
				if (message.class === 'ERROR') {
					errorMessage.push(message.message);
				}
				return errorMessage;
			}, [])
			.join('. ');

		const defaultMessage = t(
			'Something went wrong. Please try again. You can also reset your password, or email {{supportEmail}} for help signing in.',
			{ supportEmail: SUPPORT_LINK },
		);
		return {
			title: t('Error'),
			subtitle: message ?? defaultMessage,
		};
	}
}
