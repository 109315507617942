import { useMarkets } from '@ui-modules/nominations/hooks/useMarketsQuery';
import { useMemo } from 'react';
import { useTranslation } from '@hooks';
import type { TDropdownOption } from '@ui-kit/components/DropdownMenu';

export const useMarketsOptions = (): TDropdownOption[] => {
	const { data: markets = [] } = useMarkets();
	const { t } = useTranslation();
	return useMemo(() => {
		const mappedMarkets = markets?.map(({ title }) => ({ title, value: title }));
		return [
			{ value: 'Other', title: t('Other') },
			{ value: 'Global', title: t('Global') },
			{ value: null, title: '----------', disabled: true },
			...mappedMarkets,
		];
	}, [t, markets]);
};
