export const onClipboardPaste = (
	event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
	onPasteFromClipboard?: (files: Array<File>) => void,
) => {
	const clipboardItems = event.clipboardData.items;
	const clipboardItemsArray = Array.from(clipboardItems);

	// Filter out non-image items and convert to File objects
	const files = clipboardItemsArray
		.filter((item) => item.type.indexOf('image') !== -1)
		.map((item) => {
			const originalFile = item.getAsFile();
			if (!originalFile) return null;
			// Need to set custom name as all attachments have the same name.
			return new File([originalFile], `${originalFile?.name}_${new Date().getTime()}`, {
				type: originalFile.type,
				lastModified: originalFile.lastModified,
			});
		})
		.filter((item) => !!item);

	if (files.length) {
		event.preventDefault();
		onPasteFromClipboard?.(files as File[]);
	}
};
