import React from 'react';
import { useTranslation } from '@hooks';
import styles from './SecondPageBody.module.css';
import type { TFunction } from 'i18next';
import { Input } from '@ui-kit';

const allocations = (
	t: TFunction,
): {
	title: string;
	value: string;
}[] => [
	{
		title: t('Cash'),
		value: '5',
	},
	{
		title: t('Cryptocurrency'),
		value: '15',
	},
	{
		title: t('Fixed Income'),
		value: '20',
	},
	{
		title: t('Funds'),
		value: '0',
	},
];

const SecondPageBody = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Update your own  allocation any time')}</p>
			<div className={styles.wrapper__gradient}></div>
			<div className={styles.wrapper__allocationsWrapper}>
				{allocations(t).map((item, index) => {
					return (
						<div className={styles.wrapper__allocationsWrapperContent} key={index}>
							<p>{item.title}</p>
							<div className={styles.wrapper__allocationsWrapperContentInputWrapper}>
								<Input errorMessagePosition="none" value={item.value} />
								<p>%</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SecondPageBody;
