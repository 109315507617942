import { useNavigate, useEffect } from '@hooks';
import { ROUTES } from '@constants';
import type { TCommunity, TGroupTabName } from '@typings';

/** Calculates current tab and handles behavior of navigation between tabs.
 *  It exists specifically for the GroupTabsPage.
 */
export const useGroupTabsHandler = (
	groupId: TCommunity['id'] | undefined,
	tabNames: TGroupTabName[],
	currentTabName: string,
) => {
	const navigate = useNavigate();

	const currentTabIndex = currentTabName ? tabNames.findIndex((tab) => tab === currentTabName) : 0;
	const onChangeTab = (index: number) => {
		const tabName: TGroupTabName = tabNames[index] ?? tabNames[0];
		groupId && navigate(ROUTES.groupTab(groupId, tabName));
	};

	useEffect(
		function redirectToDefaultTabIfItUnknown() {
			if (currentTabIndex === -1) {
				const tabName: TGroupTabName = tabNames.find((tabName) => tabName === 'meetings') ?? tabNames[0];
				groupId && navigate(ROUTES.groupTab(groupId, tabName), { replace: true });
			}
		},
		[currentTabIndex, groupId],
	);

	return { currentTabIndex, onChangeTab };
};
