import { useService, useMutation, useNotification } from '@hooks';
import type { TDeal, TFolder } from '@typings';
import type { TEditFolderForm } from '@schemas';
import type { MutationOptions } from '@tanstack/react-query';

export const useRenameDealFolderMutation = (
	dealId: TDeal['id'],
	folderId: TFolder['id'],
	dealName: TFolder['name'],
	options?: MutationOptions<TFolder, Error, TEditFolderForm>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();

	return useMutation<TFolder, Error, TEditFolderForm>(
		['dealFolder.renameDealFolder', folderId],
		async ({ name }) => await api.dealFolder.renameDealFolder({ folderId, name }),
		{
			async onSuccess(folder, variables, context) {
				await reactQuery.queryClient.setQueriesData(reactQuery.queryKeys.getDealFolder(dealId, folderId), folder);
				reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getDealFolders(dealId));
				analytics.trackEvent('DealInteractions', {
					deal_id: dealId,
					deal_name: dealName,
					interaction_type: 'investor_files',
					interaction_action: 'folder renamed',
				});
				options?.onSuccess?.(folder, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
