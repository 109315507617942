import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IProfileTagsState {
	tags: string[] | null;
}

const initialState: IProfileTagsState = {
	tags: null,
};

export const profileTagsSlice = createSlice({
	name: 'profileTags',
	initialState,
	reducers: {
		saveTags: (state, { payload: tags }: PayloadAction<string[]>) => {
			state.tags = tags;
		},
		removeTags: (state) => {
			state.tags = initialState.tags;
		},
	},
});
