import { FormPageLayout, Page } from '../../components';
import { useTranslation, useMemo, useEffect, useState, useRef } from '@hooks';
import styles from './EditProfileInvestmentExpertisePage.module.css';
import type { TEditInvestmentExpertise } from '@schemas';
import { editProfileInvestmentExpertiseSchema } from '@schemas';
import { FieldArray, Formik } from 'formik';
import { INVESTMENT_LEVELS } from '@tiger21-llc/connect-shared/src/constants';
import { clsx, pick, translateInvestmentLevel } from '@utils';
import { ROUTES } from '@constants';
import { Button, ChipSet, DropdownMenu, FormikPersistValues } from '@ui-kit';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import type { TProfile } from '@typings';
import { useProfileTags } from '@ui-modules/profile/hooks/useProfileTags';
import type { IPersistFormikValuesApi } from '@ui-kit';

const EditProfileInvestmentExpertisePage = () => {
	const { t } = useTranslation();
	const { profile, mutateAsync } = useUpdateProfileMutation();
	const formikPersistRef = useRef<IPersistFormikValuesApi>(null);

	const [isFormDirtyFromStart, setIsFormDirtyFromStart] = useState(false);
	const { selectedTags, clearSelectedTags, openTagsSelector } = useProfileTags('investments');

	const onSubmit = async (values: TEditInvestmentExpertise) => {
		await mutateAsync(values);
		clearSelectedTags();
		formikPersistRef.current?.clear();
	};

	const initialValues: Partial<TProfile> = useMemo(
		() => ({
			investmentExpertise: selectedTags ? selectedTags : profile.investmentExpertise,
			investmentLevel: profile.investmentLevel,
		}),
		[profile, selectedTags],
	);

	const expertiseLevelOptions = useMemo(
		() =>
			INVESTMENT_LEVELS.map((expertise) => ({
				value: expertise,
				title: translateInvestmentLevel(expertise, t),
			})),
		[],
	);

	useEffect(() => {
		setIsFormDirtyFromStart(!!selectedTags);
	}, [selectedTags]);

	return (
		<Page title={t('Edit Profile Investment Expertise')}>
			<Formik<TEditInvestmentExpertise>
				enableReinitialize
				initialValues={editProfileInvestmentExpertiseSchema.cast(initialValues) as TEditInvestmentExpertise}
				validateOnMount
				validationSchema={editProfileInvestmentExpertiseSchema}
				onSubmit={onSubmit}
			>
				{(formProps) => {
					const { values, setFieldValue } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileInvestmentExpertise(), ROUTES.editProfileTags()]}
							formProps={formProps}
							headerTitle={t('Investment Expertise')}
							ignoreDirty={isFormDirtyFromStart}
							saveTrackingName="profile--edit-investment-expertise"
							onDiscarded={() => formikPersistRef.current?.clear()}
						>
							<div className={styles.investment}>
								<div className={styles.investment__container}>
									<div>
										<span className={styles.investment__label}>{t('As an investor, I’m')}</span>
										<DropdownMenu
											options={expertiseLevelOptions}
											placeholder={t('Select a level of investment')}
											value={values.investmentLevel}
											variant="medium"
											onChange={(item) => setFieldValue('investmentLevel', item)}
										/>
										<span className={clsx(styles.investment__label, styles.investment__label_types)}>
											{t('My investment types')}
										</span>
										<FieldArray
											name="investmentExpertise"
											render={({ remove }) => <ChipSet value={values.investmentExpertise} onRemove={remove} />}
										/>
									</div>
									<div className={styles.investment__buttonContainer}>
										<Button
											block="fit"
											title={t('Add Investment Types')}
											type="outline"
											variant="small"
											onClick={() => openTagsSelector(values.investmentExpertise)}
										/>
									</div>
								</div>
							</div>
							<FormikPersistValues
								mergingValues={pick(initialValues, ['investmentExpertise'])}
								name="ProfileInvestmentExpertiseForm"
								persistInvalid
								ref={formikPersistRef}
								storage={window.sessionStorage}
							/>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export interface IEditProfileInvestmentExpertisePageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditProfileInvestmentExpertisePage;
