import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';
import FileListItem from '../../FileListItem';
import { makeFileFromUrl } from '../Post.utils';
import styles from './PostAttachments.module.css';

const PostAttachments = ({ attachmentUrls, onClick }: IPostAttachmentsProps) => {
	return (
		<div
			className={styles.postAttachments}
			onClick={(event) => event.stopPropagation()}
			onKeyPress={(event) => event.stopPropagation()}
		>
			{attachmentUrls.map((url, index) => {
				const file = makeFileFromUrl(url);
				return (
					<FileListItem
						key={index}
						meta={extractMetaFromFile(file)}
						showDownloadIcon
						type="fit-card"
						onClick={() => onClick(url)}
					/>
				);
			})}
		</div>
	);
};

export interface IPostAttachmentsProps {
	attachmentUrls: string[];
	onClick: (url: string) => void;
}

export default PostAttachments;
