import { memo, useRef } from 'react';
import {
	ActivityIndicator,
	DeleteIcon,
	EmptyStateMessage,
	FixedWidthContainer,
	FolderListItem,
	SectionListRow,
	When,
	EyeIcon,
	EyeClosedIcon,
	FileListItem,
} from '@ui-kit';
import DeleteFileModal from '../DeleteFileModal';
import { useCallback, useMe, useState, useTranslation } from '@hooks';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useCommunityFilesAndFoldersQuery } from '@ui-modules/files/hooks/useCommunityFilesQuery';
import { useFilterFilesAndFolders } from '@ui-modules/files/hooks/useFilterFilesAndFolders';
import { compact, FileEntity } from '@utils';
import styles from './CommunityFilesSectionList.module.css';
import type { TCommunity, TFile, TFolder } from '@typings';
import type { TMeatballMenuOption } from '@ui-kit';
import { extractMetaFromFile } from '@ui-kit/components/FileListItem/FileListItem';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';

/** List of folders & files separated into sections.
 * 	We haven't manage to use SectionList because I found it hard to make height of the list floating (following the UI design) [@dmitriy.nikolenko].
 */
const CommunityFilesSectionList = ({
	communityId,
	folderId,
	searchQuery = '',
	onClickFile,
	onClickFolder,
}: ICommunityFilesSectionListProps) => {
	// Dependencies.
	const { t } = useTranslation();
	const { user } = useMe();

	// File & folder data.
	const { files, folders, isLoading } = useCommunityFilesAndFoldersQuery(communityId, folderId);
	const { filteredFiles, filteredFolders } = useFilterFilesAndFolders(searchQuery, files, folders);
	const { data: community, isLoading: isCommunityLoading } = useCommunityQuery(communityId);
	const { mutate: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({ communityId, folderId });

	// Page local states.
	const [deletingFileId, setDeletingFileId] = useState<string | null>(null);

	// Render list helpers.
	const renderFileItem = useCallback(
		(file: TFile, isLastInGroup: boolean) => {
			const fileMenuOptions: TMeatballMenuOption[] = compact([
				community &&
					FileEntity.canMakeViewOnly(file, user, community) && {
						text: t('Make Read Only'),
						icon: <EyeClosedIcon className={styles.communityFilesSectionList__actionIcon} height={16} width={14} />,
						onClick: () =>
							updateMediaObjectSetViewerOnly({
								fileId: file.mediaObject.id,
								isViewerOnly: true,
							}),
						type: 'solid',
					},

				community &&
					FileEntity.canMakeDownloadable(file, user, community) && {
						text: t('Make Downloadable'),
						icon: <EyeIcon className={styles.communityFilesSectionList__actionIcon} height={16} width={14} />,
						onClick: () =>
							updateMediaObjectSetViewerOnly({
								fileId: file.mediaObject.id,
								isViewerOnly: false,
							}),
						type: 'solid',
					},
				community &&
					FileEntity.canDeleteFile(file, user, community) && {
						text: t('Delete File'),
						icon: <DeleteIcon className={styles.communityFilesSectionList__deleteIcon} height={16} width={14} />,
						onClick: () => setDeletingFileId(file.id),
						type: 'destructive',
					},
			]);
			return (
				<FileListItem
					fileMenuOptions={fileMenuOptions}
					iconHeight={40}
					iconWidth={34}
					key={file.id}
					meta={extractMetaFromFile(file)}
					openInViewerOnly={file.mediaObject.openInViewerOnly}
					viewerModeText={t('read only')}
					withSeparator={!isLastInGroup}
					onClick={() => onClickFile(file)}
				/>
			);
		},
		[community],
	);
	const renderFolderItem = useCallback(
		(folder: TFolder, isLastInGroup: boolean) => {
			return <FolderListItem folder={folder} key={folder.id} withSeparator={!isLastInGroup} onClick={onClickFolder} />;
		},
		[community],
	);

	if (isLoading || isCommunityLoading) return <ActivityIndicator size="medium" type="fit" />;
	if (!filteredFolders.length && !filteredFiles.length) {
		if (searchQuery.length) {
			return <EmptyStateMessage text={t('Sorry, we couldn’t find any matching results')} />;
		} else {
			return <EmptyStateMessage text={t('There are currently no files uploaded')} />;
		}
	}
	return (
		<>
			<div className={styles.communityFilesSectionList}>
				<FixedWidthContainer>
					<When condition={!!filteredFolders.length}>
						<SectionListRow hidden={!!searchQuery.length} title={t('Folders')} />
						{filteredFolders.map((file, index, allFolders) =>
							renderFolderItem(file, isLastItemInGroup(index, allFolders)),
						)}
					</When>
					<When condition={!!filteredFiles.length}>
						<SectionListRow hidden={!!searchQuery.length} title={t('Files')} />
						{filteredFiles.map((file, index, allFiles) => renderFileItem(file, isLastItemInGroup(index, allFiles)))}
					</When>
				</FixedWidthContainer>
			</div>

			{deletingFileId && community ? (
				<DeleteFileModal
					communityId={community.id}
					fileId={deletingFileId}
					folderId={folderId}
					visible={!!deletingFileId}
					onClose={() => setDeletingFileId(null)}
				/>
			) : null}
		</>
	);
};

/** Helps to determine whether the item is last within a group to define if we need to render bottom separator. */
const isLastItemInGroup = (currentIndex: number, arrayOfItems: unknown[]) => arrayOfItems.length - 1 === currentIndex;

export interface ICommunityFilesSectionListProps {
	/** Target Community ID which files should be shown. */
	communityId: TCommunity['id'];
	/** Target Folder ID which files should be shown. Set 'null' for the root folder. */
	folderId: TFolder['id'] | null;
	/** If passed list of folders and files will be reduced to items matching 'searchQuery' value.  */
	searchQuery: string;
	/** Invokes when a file clicked (to open it and perform some action). */
	onClickFile: (file: TFile) => void;
	/** Invokes when a folder clicked (to open it and perform some action). */
	onClickFolder: (folder: TFolder) => void;
}

export default memo(CommunityFilesSectionList);
