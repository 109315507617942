import { translateProfileLinkLabelToTitle } from '@utils';
import { PROFILE_DETAILS_CUSTOM_LINK_LABELS } from '@constants';
import type { TProfileLink, TProfileCustomLinkLabel } from '@typings';
import type { TFunction } from 'react-i18next';

const cutProtocolFromUrl = (link: string) => link.replace(/(^\w+:|^)\/\//, '');

export const makeTitle = (link: TProfileLink, t: TFunction): string => {
	const label = translateProfileLinkLabelToTitle(link.label, t);
	const title = PROFILE_DETAILS_CUSTOM_LINK_LABELS.includes(link.label as TProfileCustomLinkLabel)
		? `${cutProtocolFromUrl(String(link.link))} (${label})`
		: label;

	return title;
};
