import { Modal } from '@ui-kit';
import FilePasswordForm from '@ui-modules/files/components/FilePasswordForm';
import { useTranslation } from '@hooks';

const FilePasswordFormModal = ({
	onCancel,
	onUpload,
	file,
	visible,
	subTitle,
	onClose,
	isViewOnly,
	setIsViewOnly,
	isUploading,
}: IFilePasswordFormProps) => {
	const { t } = useTranslation();
	return (
		<Modal
			modalContent={
				<FilePasswordForm
					file={file}
					isUploading={isUploading}
					isViewOnly={isViewOnly}
					setIsViewOnly={setIsViewOnly}
					onCancel={onCancel}
					onUpload={onUpload}
				/>
			}
			subTitle={subTitle}
			title={t('Password')}
			visible={visible}
			onClose={onClose}
		/>
	);
};

export interface IFilePasswordFormProps {
	onUpload: (args: { secretKey?: string; isViewOnly?: boolean }) => void;
	onCancel: () => void;
	onClose: () => void;
	visible: boolean;
	file: File;
	subTitle: string;
	isViewOnly: boolean;
	setIsViewOnly: () => void;
	isUploading: boolean;
}

export default FilePasswordFormModal;
