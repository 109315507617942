import { useTranslation } from '@hooks';
import type { THidableProfileProperties, TProfileLink, TUser } from '@typings';
import { Card, Button, ExternalLinkIcon, Anchor } from '@ui-kit';
import { dayjs, translateUserRole } from '@utils';
import styles from './ProfileDetails.module.css';
import { makeTitle } from './ProfileDetails.utils';

const ProfileDetails = ({
	isInvisible,
	registeredAt,
	links = [],
	fullName,
	roles,
	canEdit,
	hideProperties,
	onEdit,
}: IProfileDetailsProps) => {
	const { t } = useTranslation();
	const googleSearchLink = encodeURI(`https://www.google.com/search?q=${fullName}`);
	const visibleLinks = links.filter((link) => !hideProperties.includes(`links.label=${link.label}`));
	const formattedRegisteredAt = dayjs(registeredAt).format('YYYY');

	return isInvisible ? null : (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			title={t('More about me')}
		>
			<div className={styles.details__container}>
				<span className={styles.details__role}>
					{t('{{role}} since {{date}}', { role: translateUserRole(roles, t), date: formattedRegisteredAt })}
				</span>
				{visibleLinks.length ? (
					<>
						<span className={styles.details__linkTitle}>{t('External Links')}</span>
						<div className={styles.details__linksContainer}>
							{visibleLinks.map((link) => (
								<Anchor
									className={styles.details__link}
									href={link.label === 'google' ? googleSearchLink : link.link || ''}
									key={link.link}
									target="blank"
									trackingName="profile--open-external-url"
								>
									<span>{makeTitle(link, t)}</span>
									<div>
										<ExternalLinkIcon className={styles.details__linkIcon} />
									</div>
								</Anchor>
							))}
						</div>
					</>
				) : null}
			</div>
		</Card>
	);
};

export interface IProfileDetailsProps {
	isInvisible?: boolean;
	registeredAt: string;
	links: Array<TProfileLink>;
	fullName: string;
	roles: TUser['roles'];
	canEdit: boolean;
	hideProperties: THidableProfileProperties[];
	onEdit: () => void;
}

export default ProfileDetails;
