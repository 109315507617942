import React, { useState } from 'react';
import { RadioGroupInput } from '@ui-kit';

import styles from './SingleSelect.module.css';
import { capitalize } from '@utils';
import type { TDynamicFilter } from '@typings';
import type { TRadioGroupInputOption } from '@ui-kit';
import type {
	TDataType,
	TFieldValue,
	TUpdateFiltersFunction,
} from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';

const SingleSelect = ({ fieldData, onFilterUpdate }: ISingleSelectProps) => {
	const [selectedValue, setSelectedValue] = useState<TFieldValue | string>(fieldData?.filtersApplied?.eq || '');
	const handleChange = (newValue: TFieldValue) => {
		setSelectedValue(newValue);
		onFilterUpdate(fieldData.name, newValue, fieldData.type as TDataType);
	};

	const options = fieldData?.meta?.choices?.map(({ choice, infoText }) => ({
		label: fieldData.type === 'boolean' ? capitalize(choice) : infoText ? `${choice} - ${infoText}` : choice,
		value: choice,
	}));

	return (
		<div className={styles.networkConnectionFilters__radioGroupWrapper}>
			<RadioGroupInput
				backgroundColor={'white'}
				label={fieldData.label}
				options={options as TRadioGroupInputOption<string>[]}
				value={selectedValue as string}
				onChange={(selected) => {
					handleChange((selected === selectedValue ? '' : selected) as unknown as TFieldValue);
				}}
			/>
		</div>
	);
};

export default SingleSelect;

export interface ISingleSelectProps {
	fieldData: TDynamicFilter;
	onFilterUpdate: TUpdateFiltersFunction;
}
