import { useTranslation } from '@hooks';
import { Modal } from '@ui-kit';
import { useDeleteFolderMutation } from '@ui-modules/files/hooks/useDeleteFolderMutation';
import type { IModalProps } from '@ui-kit';
import type { TFolder, TCommunity } from '@typings';

/** Confirms deleting of a folder and performs it. */
const DeleteFolderModal = ({
	visible,
	folderId,
	folderName,
	communityId,
	onClose,
	onSuccess,
}: IDeleteFolderModalProps) => {
	const { t } = useTranslation();

	const { mutate: deleteFolder, isLoading } = useDeleteFolderMutation(communityId, {
		onSuccess: () => {
			onSuccess?.();
			onClose();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('This action will also permanently delete any files in this folder.')}
			title={t('Delete folder “{{folderName}}”?', { folderName })}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				deleteFolder(folderId);
			}}
		/>
	);
};

export interface IDeleteFolderModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Community ID. */
	communityId: TCommunity['id'];
	/** Target Folder ID. */
	folderId: TFolder['id'];
	/** Target Folder name to display in confirmation dialog. */
	folderName: TFolder['name'];
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeleteFolderModal;
