import { useMutation, useService } from '@hooks';
import type { TNetworkConnection } from '@typings';

export const useCreateNetworkConnectionMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	// TODO ADD TYPING FOR SENDING DATA
	return useMutation<TNetworkConnection, Error, any>(
		reactQuery.queryKeys.createNetworkConnection(),
		async (data: any) => await api.networkConnection.createNetworkConnection(data),
	);
};
