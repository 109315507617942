import { useMutation, useService } from '@hooks';

export const usePostCommunitiesNotificationSettingsMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	return useMutation(
		async ({ membershipId, isMassEmailAllowed }: { membershipId: string; isMassEmailAllowed: boolean }) => {
			return await api.notificationSettings.postCommunitiesNotificationSettings(membershipId, isMassEmailAllowed);
		},
		{
			onSuccess: async () => {
				await reactQuery.queryClient.refetchQueries({ queryKey: reactQuery.queryKeys.chaptersNotificationSettings() });
			},
		},
	);
};
