import { useService, useMutation, useLogger } from '@hooks';

/** Retrieves new algolia access key WITH UP-TO-DATE PERMISSIONS.
 * 	Needs to be called on every app launch and after effects which changed visibility permissions (like join/leave network).
 *  Caused by T21C-4797
 *  @author DmitriyNikolenko
 */
export const useInitAlgoliaSearchMutation = () => {
	const api = useService('ApiService');
	const algoliaSearch = useService('AlgoliaSearchService');
	const logger = useLogger('useInitAlgoliaSearchMutation');

	return useMutation(['algolia.getSecuredSearchKey'], async () => await api.algolia.getSecuredSearchKey(), {
		onSuccess(algoliaSearchKey) {
			algoliaSearch.init(algoliaSearchKey);
			logger.debug('Algolia search key has been updated');
		},
	});
};
