import { toRelativeUrl } from '@okta/okta-auth-js';
import { useService } from '@services';
import { Security } from '@okta/okta-react';
import { ROUTES } from '@constants';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import type { PropsWithChildren } from 'react';

/** Provides context for OKTA auth service. */
const OktaProvider = ({ children }: PropsWithChildren) => {
	const okta = useService('OktaService');
	const appRouter = useService('RouterService');

	const customAuthHandler = () => {
		appRouter.navigate(ROUTES.signIn());
	};
	const restoreOriginalUri: RestoreOriginalUriFunction = async (_oktaAuth, originalUri) => {
		appRouter.navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
	};

	return (
		<Security oktaAuth={okta.oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
			{children}
		</Security>
	);
};

export default OktaProvider;
