import {
	useTranslation,
	useMe,
	useNavigate,
	useLocation,
	useRestoreVirtuosoInitialIndex,
	useMemo,
	useDebouncedCallback,
	useSubmitOrScrollToError,
	useCurrentLocation,
} from '@hooks';
import { ActivityIndicator, Formik, FormSection, WebLinksSection } from '@ui-kit';
import type { TDealFormValues } from '../DealForm.schema';
import { dealFormFields } from '../DealForm.schema';
import { getValidateHandler } from '../DealForm.schema';
import { getDealFormSchema } from '../DealForm.schema';
import { FormPageLayout } from '../../../../../app/components';
import { AssetClassesSection } from './AssetClassesSection';
import { RelationSection } from '../sections/RelationSection';
import { DealInitiatorSection } from '../sections/DealInitiatorSection';
import { DealContactSection } from '../sections/DealContactSection';
import { DealDetails } from '../sections/DealDetails';
import { AttachmentsSection } from '../sections/AttachmentsSection';
import { FinancialDetails } from '../sections/FinancialDetails';
import { useGetDealAssetClassesQuery } from '@ui-modules/deals/hooks/useGetDealAssetClassesQuery';
import { DealEntity } from '@utils';
import { ROUTES } from '@constants';
import styles from './BaseForm.module.css';
import { useSubmitDeal } from './BaseForm.useSubmitDeal';
import type { FormikErrors } from 'formik';
import type { IDealStatus } from '@typings';

interface INewDealPageQueryParams {
	communityId?: string;
	formState?: string;
}

type TParsedInitialValues = {
	values: TDealFormValues;
	errors: FormikErrors<TDealFormValues>;
};

const extractDefaultValuesFromQuery = (formState?: string): TParsedInitialValues | null => {
	if (!formState) {
		return null;
	}
	let parsed = null;
	try {
		parsed = JSON.parse(formState) || null;
	} catch (error) {
		return null;
	}
	return parsed;
};

export const DealForm = ({ editValues, dealId, isLoading, status }: IDealFormProps) => {
	const { t } = useTranslation();
	const { user, fullName } = useMe();
	const validationSchema = getDealFormSchema(t);
	const navigate = useNavigate();
	const { currentLocation } = useCurrentLocation();

	const location = useLocation();
	const { setInitialTopMostItemIndex } = useRestoreVirtuosoInitialIndex(location?.state?.restoreScrollRoute);

	const onSubmit = useSubmitDeal(
		() => {
			navigate(-1);
			setInitialTopMostItemIndex(0);
		},
		dealId,
		status,
	);
	const debouncedSubmit = useDebouncedCallback(onSubmit, 5000, { leading: true, trailing: false });

	const queryEntries = new URLSearchParams(location.search);
	const queryParams: INewDealPageQueryParams = Object.fromEntries(queryEntries);
	const parsedFormValues = extractDefaultValuesFromQuery(queryParams.formState);

	const { data: assetClasses = [] } = useGetDealAssetClassesQuery();

	const preselectedDealAssetClass = useMemo(
		() => assetClasses.find((i) => i.community === queryParams.communityId),
		[assetClasses],
	);

	const defaultValues = {
		...validationSchema.getDefault(),
		creator: DealEntity.canCreateOnBehalfOf(user) ? undefined : { iriId: user['@id'], name: fullName },
		community: queryParams.communityId,
		dealAssetClass: preselectedDealAssetClass?.['@id'],
		currency: 'USD',
		compensationDisclosure: false,
		contactPhone: currentLocation.callingCode,
	};

	const initialValues = editValues || parsedFormValues?.values || defaultValues;
	const initialErrors = parsedFormValues?.errors;

	const submitOrScrollToErrorField = useSubmitOrScrollToError();

	const getSaveButtonTitle = (creatorType: string, creatorOption?: string) => {
		if (editValues || status === 'draft') {
			return t('Save');
		}
		if (creatorOption === 'third-party' || creatorType === 'referrer' || creatorType === 'investor') {
			return t('Send');
		}
		return t('Post');
	};

	return (
		<Formik
			enableReinitialize={true}
			initialErrors={initialErrors}
			initialValues={initialValues as TDealFormValues}
			validate={getValidateHandler(t)}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={debouncedSubmit}
		>
			{(formProps) => {
				const { setFieldError, setFieldValue, values, errors } = formProps;
				return (
					<FormPageLayout
						allowedNextPaths={[ROUTES.newDeal(), ROUTES.editDeal()]}
						buttonBordered={false}
						customDisabled={!formProps.dirty}
						customLeftIcon={<span className={styles.backLink}>{t('Cancel')}</span>}
						formProps={formProps}
						headerTitle={editValues ? t('Edit Deal') : t('Create Deal')}
						saveButtonTitle={getSaveButtonTitle(formProps.values.creatorType, formProps.values.creatorOption)}
						scrollEnabled
						onSaveHandler={() =>
							formProps.validateForm().then(() => {
								submitOrScrollToErrorField(formProps.submitForm);
							})
						}
					>
						<div className={styles.content}>
							{isLoading ? (
								<ActivityIndicator size="medium" type="fit" />
							) : (
								<>
									<p className={styles.optionalHint}>{t('All fields required unless marked “optional”')}</p>
									<DealInitiatorSection
										communityId={queryParams.communityId}
										formProps={formProps}
										isEdit={!!editValues}
										isUserSearchShown={DealEntity.canCreateOnBehalfOf(user)}
									/>
									<RelationSection formProps={formProps} isEdit={!!editValues} />
									<AssetClassesSection
										communityId={queryParams.communityId}
										formProps={formProps}
										isEdit={!!editValues}
									/>
									<DealContactSection formProps={formProps} />
									<DealDetails formProps={formProps} isEdit={!!editValues} />
									<FinancialDetails formProps={formProps} isEdit={!!editValues} />
									<FormSection className={styles.sectionDetails} title={t('Web Links (optional)')}>
										<WebLinksSection
											options={[
												{
													filed: dealFormFields.WEB_LINK_1,
													label: t('Web link 1'),
													value: values.webLink1,
													error: errors.webLink1,
												},
												{
													filed: dealFormFields.WEB_LINK_2,
													label: t('Web link 2'),
													value: values.webLink2,
													error: errors.webLink2,
												},
												{
													filed: dealFormFields.WEB_LINK_3,
													label: t('Web link 3'),
													value: values.webLink3,
													error: errors.webLink3,
												},
											]}
											placeholder={t('e.g. {{example}}', { example: 'https://www.financialreports.com/...' })}
											onChange={(field: string, value: string) => {
												setFieldValue(field, value);
												setFieldError(field, undefined);
											}}
										/>
									</FormSection>
									<AttachmentsSection dealId={dealId} formProps={formProps} isEdit={!!editValues} />
								</>
							)}
						</div>
					</FormPageLayout>
				);
			}}
		</Formik>
	);
};

interface IDealFormProps {
	editValues?: TDealFormValues;
	dealId?: string;
	isLoading?: boolean;
	status?: IDealStatus;
}
