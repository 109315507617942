import { compact } from 'lodash';

// Function that sorts object specific values by provided sequence
export const sortBySequence = <T>(array: T[], property: keyof T, sequence: string[]) => {
	const sorted: T[] = [];

	sequence.forEach((order, index) => {
		const foundItem = array.find((item) => item[property] === order);
		if (foundItem) {
			sorted[index] = foundItem;
		}
	});

	return compact(sorted);
};
