import { clsx } from '@utils';
import { ROUTES } from '@constants';
import { Tabs, When, ActivityIndicator } from '@ui-kit';
import { useParams, useTranslation, useNavigate, useMe, useLayoutEffect } from '@hooks';
import { useAssetAllocationTabsQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationTabsQuery';
import { useAssetAllocationTabsState } from '@ui-modules/assetAllocation/hooks/useAssetAllocationTabsState';
import AssetAllocationIntroModal from '@ui-modules/assetAllocation/components/AssetAllocationIntroModal';
import { AssetAllocationMePage } from '../AssetAllocationMePage';
import { AssetAllocationInfoPage } from '../AssetAllocationInfoPage';

import styles from './AssetAllocationPage.module.css';

import { Outlet, Page, TabWrapper } from '../../components';

export const AssetAllocationPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Navigation.
	const currentTabName = useParams()?.tabName;
	const navigateToTab = (nextTabName: string) => {
		navigate(ROUTES.assetAllocation(nextTabName));
	};

	// Data.
	const { data: assetAllocationTabs = [], isInitialLoading } = useAssetAllocationTabsQuery();

	// Tabs.
	const [currentTabIndex, handleChangeTab] = useAssetAllocationTabsState(
		assetAllocationTabs,
		currentTabName,
		navigateToTab,
	);

	

	return (
		<AssetAllocationIntroModal>
			<Page title={t('Asset Allocation')}>
				<div className={styles.learnPage}>
					<div className={clsx(styles.assetAllocationPage__header)}>
						<div className={styles.assetAllocationPage__headerTitle}>
							<h2>{t('Asset Allocation')}</h2>
						</div>
					</div>
					<ActivityIndicator hidden={!isInitialLoading} size="large" type="fit" />
					<When condition={!!assetAllocationTabs.length && !isInitialLoading}>
						<Tabs selectedIndex={currentTabIndex} onSelect={handleChangeTab}>
							<Tabs.List>
								{assetAllocationTabs.map((tab) => (
									<Tabs.Tab key={tab.slug}>{tab.title}</Tabs.Tab>
								))}
							</Tabs.List>
							{assetAllocationTabs.map((tab) => (
								<Tabs.Panel key={tab.title}>
									{tab.slug === 'networks' ? (
										<TabWrapper>
											<Outlet />
										</TabWrapper>
									) : tab.slug === 'tiger21' ? (
										<TabWrapper>
											<AssetAllocationInfoPage hideBackButton tabName={tab.title} />
										</TabWrapper>
									) : tab.slug === 'me' ? (
										<TabWrapper>
											<AssetAllocationMePage />
										</TabWrapper>
									) : (
										<AssetAllocationInfoPage communityId={tab.communityId} hideBackButton tabName={tab.title} />
									)}
								</Tabs.Panel>
							))}
						</Tabs>
					</When>
				</div>
			</Page>
		</AssetAllocationIntroModal>
	);
};
