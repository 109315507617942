import { clsx, formatAmount } from '@utils';
import type { IInputProps } from '@ui-kit';
import { InputErrorMessage, InputLabel } from '@ui-kit';

import styles from './AmountInput.module.css';
import { NumericFormat } from 'react-number-format';
import { useId } from '@hooks';

const AmountInput = ({
	value,
	placeholder,
	disabled,
	label,
	errorMessage,
	ariaLabel,
	keepMarginBottom,
	subInputElement = null,
	onChange,
	onBlur,
	allowLeadingZeros = true,
}: IAmountInputProps) => {
	const inputId = useId();

	const handleLeadingZeros = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!allowLeadingZeros && /^0|-0/.test(event.target.value)) {
			event.target.value = event.target.value.replace(/^0|-0/, '');
		}
	};

	return (
		<div className={styles.input__wrapper}>
			<InputLabel htmlFor={inputId} text={label} />
			<div className={clsx(styles.input__container, styles.input__wrapper)}>
				{value && value !== '0' ? (
					<span className={styles.input__icon}>
						<p className={styles.inputPreview}>{formatAmount(Number(value.replaceAll(',', '')))}</p>
					</span>
				) : null}
				<NumericFormat
					allowLeadingZeros={allowLeadingZeros}
					aria-label={ariaLabel}
					autoComplete="off"
					className={clsx(styles.input, !!errorMessage && styles.input_error)}
					disabled={disabled}
					id={inputId}
					placeholder={placeholder}
					thousandSeparator=","
					value={value}
					onBlur={onBlur}
					onChange={onChange}
					onInput={handleLeadingZeros}
					decimalScale={0}
				/>
			</div>
			<div className={clsx(styles.input__errorMessageBox)}>
				{errorMessage && <InputErrorMessage keepMarginBottom={keepMarginBottom} text={errorMessage} />}
				<div>{subInputElement}</div>
			</div>
		</div>
	);
};

export default AmountInput;

interface IAmountInputProps extends IInputProps {
	allowLeadingZeros?: boolean;
}
