import { ROUTES } from '@constants';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import AssetAllocationCommunityEmptyState from '@ui-modules/assetAllocation/components/AssetAllocationCommunityEmptyState';
import AssetAllocationStaticEmptyState from '@ui-modules/assetAllocation/components/AssetAllocationStaticEmptyState';
import { useMe, useNavigate, useTranslation } from '@hooks';
import { isMyAssetAllocationAllowed } from '@ui-modules/assetAllocation/utils/isMyAssetAllocationAllowed';
import { Navigate } from 'react-router-dom';

const AssetAllocationFacade = ({
	isLoading,
	communityId,
	userAssetAllocationExists,
	assetAllocationExists,
	children,
}: IAssetAllocationFacadeProps) => {
	const navigate = useNavigate();
	const { user } = useMe();
	const { t } = useTranslation();

	const isCommunity = !!communityId;
	const isTiger21 = !isCommunity;
	const myAssetAllocationAllowed = isMyAssetAllocationAllowed(user);

	const isLoadingState = isLoading;
	const isCommunityEmptyState = isCommunity && !assetAllocationExists;
	const isUserEmptyState = communityId && !userAssetAllocationExists && myAssetAllocationAllowed;
	const isRedirectToDefault = isTiger21 && !assetAllocationExists;

	switch (true) {
		case isLoadingState:
			return <ActivityIndicator size="medium" type="fit" />;
		case isUserEmptyState:
			return <AssetAllocationStaticEmptyState onOpenUserAllocation={() => navigate(ROUTES.assetAllocation('me'))} />;
		case isCommunityEmptyState:
			return <AssetAllocationCommunityEmptyState communityId={String(communityId)} />;
		case isRedirectToDefault: {
			const redirectRoute = isTiger21 ? ROUTES.assetAllocation('tiger21') : ROUTES.assetAllocation('default');
			return (
				<>
					<Navigate replace to={redirectRoute} />
					<p>
						<EmptyStateMessage text={t('Not Enough Data Yet')} />
					</p>
				</>
			);
		}
		default:
			return <>{children}</>;
	}
};

export interface IAssetAllocationFacadeProps {
	isLoading: boolean;
	communityId: string | undefined;
	userAssetAllocationExists: boolean;
	assetAllocationExists: boolean;
	children?: React.ReactNode;
}

export default AssetAllocationFacade;
