import { EntityPageHeader } from '@ui-kit';
import { ROUTES } from '@constants';
import { useHandleBackButton, useLocation, useNavigate } from '@hooks';
import { sanitizeProfileId } from '@tiger21-llc/connect-shared/src/utils';
import type { TDeal } from '@typings';
import { useMutateDealMeatballMenuOptions } from '@ui-modules/deals/hooks/useMutateDealMeatballMenuOptions';
import { dayjs } from '@utils';

const DealHeader = ({ subtitleLink, deal, onBackPress }: IDealHeaderProps) => {
	const { onBackButtonPress } = useHandleBackButton();
	const goBack = () => onBackButtonPress();
	const navigate = useNavigate();
	const { state = {} } = useLocation();

	const networkId = deal?.community.id as string;

	const [meatballMenuOptions, ConfirmationModals] = useMutateDealMeatballMenuOptions(
		deal,
		networkId,
		goBack,
		undefined,
		state?.restoreScrollRoute,
	);
	const goToProfile = () => navigate(ROUTES.memberProfile(sanitizeProfileId(deal.creator.profileIri)));

	return (
		<>
			<EntityPageHeader
				avatarUri={deal?.creator.avatarUrl}
				date={dayjs(deal?.createdAt).fromNow()}
				fullName={deal?.creator.fullName}
				isChair={deal.creator.profileIri.includes('profile_chair')}
				location={deal?.creator.communitiesForBadge?.shortNames.join(' • ')}
				meatballMenuOptions={meatballMenuOptions}
				network={deal?.community.name}
				networkLink={subtitleLink}
				theme="dark"
				onBackPress={onBackPress}
				onProfilePress={goToProfile}
			/>
			{ConfirmationModals}
		</>
	);
};

export interface IDealHeaderProps {
	subtitleLink?: string;
	deal: TDeal;
	onBackPress?: () => void;
}

export default DealHeader;
