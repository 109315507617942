import { useEvent, useMe, useMutation, useNotification, useService, useTranslation } from '@hooks';
import { useUpdateCalendarItemCacheMutation } from '@ui-modules/groups/hooks/useUpdateCalendarItemCacheMutation';
import { useInitAlgoliaSearchMutation } from '@ui-modules/globalSearch/hooks/useInitAlgoliaSearchMutation';
import { cloneDeep, dayjs, MeetingEntity, set } from '@utils';
import type {
	TCalendarItemRequest,
	TEvent,
	TEventAttendanceCanceling,
	TEventAttendanceChanging,
	TEventAttendanceRequesting,
	TEventAttendee,
	TEventAttendeeStatus,
	TMeetingAttendance,
} from '@typings';
import type { Mutation } from '@tanstack/react-query';
import { without } from '@utils';

export const useEventAttendanceMutations = () => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showInfo } = useNotification();
	const { user } = useMe();

	const { mutate: updateCalendarItemCache } = useUpdateCalendarItemCacheMutation();
	const { calendar, useBindActionCreators } = useService('ReduxService');
	const { updateCalendarItem } = useBindActionCreators(calendar);
	const { mutate: refetchAlgoliaSearchToken } = useInitAlgoliaSearchMutation();

	const changeEventAttendanceMutation = useMutation<TEventAttendee, Error, TEventAttendanceChanging>(
		['attendance.changeMeetingAttendance'],
		async ({ event, going }) =>
			MeetingEntity.canCreateAttendance(event)
				? await api.attendance.createMeetingAttendance(event.id, going, event.isAllowedInPersonAttendance)
				: await api.attendance.changeMeetingAttendance(String(event.currentUserAttendance?.['@id']), going),
		{
			onMutate({ event, going }) {
				// If it will be PATCH apply cache update.
				if (event.currentUserAttendance) {
					reactQuery.updateEventInGetMeetingCache(event.id, setGoingStatus(going));
					updateCalendarItem({ calendarItemId: event.id, callback: setGoingStatus(going) });

					event.groups?.forEach?.((group) => {
						const communityId = group?.community?.id;
						reactQuery.updateGroupMeetingsCache(communityId, event.id, setGoingStatus(going));
					});
				}
			},
			onSuccess(currentUserAttendance, { event }) {
				reactQuery.refetchAffectedCalendarDateStatuses(event.startDate);

				// If it was POST, invalidate cache.
				if (!event.currentUserAttendance) {
					reactQuery.updateEventInGetMeetingCache(event.id, setCurrentUserAttendance(currentUserAttendance));
					updateCalendarItem({ calendarItemId: event.id, callback: setCurrentUserAttendance(currentUserAttendance) });
					event.groups?.forEach?.((group) => {
						const communityId = group?.community?.id;
						reactQuery.updateGroupMeetingsCache(communityId, event.id, setCurrentUserAttendance(currentUserAttendance));
					});
				}

				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getMeetingAttendances(event.id));
				refetchAlgoliaSearchToken();
			},
			onError(error, { event, going }) {
				if (event.currentUserAttendance) {
					reactQuery.updateEventInGetMeetingCache(event.id, setGoingStatus(!going));
					updateCalendarItem({ calendarItemId: event.id, callback: setGoingStatus(!going) });
					event.groups?.forEach?.((group) => {
						const communityId = group?.community?.id;
						reactQuery.updateGroupMeetingsCache(communityId, event.id, setGoingStatus(!going));
					});
					showUnknownError(error);
				}
			},
		},
	);

	const updateAttendanceRequestCache = (event: TEvent, calendarItemRequest: TCalendarItemRequest) => {
		reactQuery.updateEventInGetMeetingCache(event.id, setCalendarItemRequest(calendarItemRequest));
		updateCalendarItem({ calendarItemId: event.id, callback: setCalendarItemRequest(calendarItemRequest) });
		event.groups?.forEach?.((group) => {
			const communityId = group?.community?.id;
			reactQuery.updateGroupMeetingsCache(communityId, event.id, setCalendarItemRequest(calendarItemRequest));
		});

		updateCalendarItemCache(event.id);
	};

	const requestEventAttendanceMutation = useMutation<TCalendarItemRequest, Error, TEventAttendanceRequesting>(
		['attendance.requestMeetingAttendance'],
		async ({ event }) => await api.attendance.requestMeetingAttendance(event.id, user.id),
		{
			async onSuccess(calendarItemRequest, { event }) {
				updateAttendanceRequestCache(event, calendarItemRequest);
				showInfo({
					title: t('Request sent'),
					subtitle: t('You requested to attend “{{eventName}}” on {{date}}.', {
						eventName: event.title,
						date: dayjs(event.startDate).format('MMMM Do'),
					}),
				});
			},
			onError(error) {
				showUnknownError(error);
			},
		},
	);

	const cancelEventAttendanceMutation = useMutation<TCalendarItemRequest, Error, TEventAttendanceCanceling>(
		['attendance.cancelMeetingAttendance'],
		async ({ event }) => {
			const meetingAttendanceId = String(event.meetingRequest?.id);
			return await api.attendance.cancelMeetingAttendance(meetingAttendanceId);
		},
		{
			async onSuccess(calendarItemRequest, { event }) {
				updateAttendanceRequestCache(event, calendarItemRequest);
				showInfo({
					title: t('Request cancelled'),
					subtitle: t('You requested to attend “{{eventName}}” on {{date}} is cancelled.', {
						eventName: event.title,
						date: dayjs(event.startDate).format('MMMM Do'),
					}),
				});
			},
			onError(error) {
				showUnknownError(error);
			},
		},
	);

	const launchVirtualMeeting = useEvent((event: TEvent) => window.open(MeetingEntity.getUserConferenceLink(event)));

	return {
		changeEventAttendanceMutation,
		requestEventAttendanceMutation,
		cancelEventAttendanceMutation,
		launchVirtualMeeting,
	};
};

export const getEventAttendanceMutationPredicate =
	(meetingId: TEvent['id']) => (mutation: Mutation<TCalendarItemRequest, Error, TEventAttendanceRequesting>) =>
		mutation.state.variables?.event?.id === meetingId;

/* Event manual updaters */

const setGoingStatus = (goingStatus: boolean) => (event: TEvent) => {
	const updatedEvent = cloneDeep(event);
	const eventAttendeeStatus: TEventAttendeeStatus = goingStatus ? 'going' : 'not-going';
	set(updatedEvent, ['currentUserAttendance', 'status'], eventAttendeeStatus);

	return updatedEvent;
};

const setCalendarItemRequest = (calendarItemRequest: TCalendarItemRequest) => (event: TEvent) => {
	const updatedEvent = cloneDeep(event);
	set(updatedEvent, 'meetingRequest', calendarItemRequest);
	switch (calendarItemRequest.status) {
		case 'canceled': {
			updatedEvent.actions = [];
			break;
		}
		case 'new': {
			updatedEvent.actions = ['cancel_meeting_request'];
			break;
		}
	}

	return updatedEvent;
};

const setCurrentUserAttendance = (currentUserAttendance: TMeetingAttendance) => (event: TEvent) => {
	const updatedEvent: TEvent = {
		...event,
		currentUserAttendance,
		actions: without(event.actions, 'create_attendance').concat('update_attendance'),
	};

	return updatedEvent;
};
