import dayjs from 'dayjs';
import type { TCalendarDaysStatuses, TCalendarItemDayStatusData } from '@typings';

export const buildCalendarDaysStatuses = (calendarItems: TCalendarItemDayStatusData[]): TCalendarDaysStatuses => {
	const daysStatuses: TCalendarDaysStatuses = {};

	calendarItems.forEach((item) => {
		const startDate = dayjs(item.startDate).format('YYYY-MM-DD');
		const todayDate = dayjs().format('YYYY-MM-DD');

		if (!item.isCanceled) {
			if (item.currentUserAttendance?.status === 'going') {
				daysStatuses[startDate] = 'with-attending';
			} else if (daysStatuses[startDate] !== 'with-attending') {
				daysStatuses[startDate] = 'with-available-events';
			}
		}

		daysStatuses[todayDate] = 'today';
	});

	return daysStatuses;
};
