import { memo } from 'react';
import { Modal } from '@ui-kit';
import { useTranslation } from '@hooks';
import { CommunityEntity, noop } from '@utils';
import type { TCommunity } from '@typings';
import type { IModalProps } from '@ui-kit';

const LeaveNetworkModal = ({
	visible: isVisible,
	network,
	onLeaveNetwork,
	onClose,
	onOpenDeals,
}: ILeaveNetworkModalProps) => {
	const { t } = useTranslation();

	const canLeaveNetwork = CommunityEntity.canLeaveNetwork(network);
	if (!canLeaveNetwork) {
		return (
			<Modal
				cancelTitle={t('Cancel')}
				confirmTitle={t('My Deals')}
				subTitle={t("Deals you've posted to this Network are still open. To leave the Network, close them first.")}
				title={t('First, Close Your Deals')}
				variant="medium"
				visible={isVisible}
				onCancel={onClose}
				onConfirm={() => {
					onOpenDeals();
					onClose();
				}}
			/>
		);
	}
	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Leave Network')}
			subTitle={t('Are you sure you want to leave this network?')}
			title={t('Leave Network')}
			variant="medium"
			visible={isVisible}
			onCancel={onClose}
			onConfirm={() => {
				network && onLeaveNetwork(network);
				onClose();
			}}
		/>
	);
};

export interface ILeaveNetworkModalProps extends Pick<IModalProps, 'visible'> {
	network: TCommunity;
	onClose: () => void;
	onLeaveNetwork: (network: TCommunity) => void;
	onOpenDeals: () => void;
}

export default memo(LeaveNetworkModal);
