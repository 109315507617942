import { Page } from '../../components';
import { ROUTES } from '@constants';
import { ActivityIndicator } from '@ui-kit';
import { useTranslation, useNavigate } from '@hooks';
import { uniq, capitalize } from '@utils';
import { useNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationsSettings';
import NavigationCard from '@ui-modules/settings/components/NavigationCard';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useLatestWaiverQuery } from '@ui-modules/deals/hooks/useLatestWaiverQuery';
import styles from './NotificationsSettingsPage.module.css';
import type { TChapterItem } from '@typings';
import { useNotificationsSettingsWithChaptersQuery } from '@ui-modules/settings/hooks/useNotificationsSettingsWithChapters';
import { useMemo } from 'react';

const NotificationsSettingsPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		defaultsSettings = [],
		isLoadingOfDefaultsSettings,
		isLoadingNotificationsSettings,
	} = useNotificationsSettings();
	const { data: chaptersSettings = [], isLoading: isLoadingOfChaptersSettings } =
		useNotificationsSettingsWithChaptersQuery();
	const { enabled } = useDealsEnabled();
	const { data: latestWaiver, isLoading: isLoadingOfWaiver } = useLatestWaiverQuery(enabled);

	const isLoadingOfDefaultOrNotificationsSettings = isLoadingOfDefaultsSettings || isLoadingNotificationsSettings;
	const conditionForActivityIndicator = enabled
		? isLoadingOfDefaultOrNotificationsSettings || isLoadingOfWaiver || isLoadingOfChaptersSettings
		: isLoadingOfDefaultOrNotificationsSettings;

	const conditionOfDealsRender = enabled && !!latestWaiver;
	const settingsItems = useMemo(() => {
		const combinedSettings = [
			...defaultsSettings,
			...chaptersSettings.filter((setting) => setting.communityDefinition === 'chapter'),
		] as TChapterItem[];

		return uniq(
			combinedSettings
				?.reduce<string[]>(
					(acc, setting) => [
						...acc,
						setting.communityDefinition?.toUpperCase() as string,
						setting.notificationGroup?.toUpperCase() as string,
					],
					// If there is no approved Waiver, then hide Deal Notifications
					// If there is ANY approved Waiver (even an old version), then we show the Deal Notifications settings
					// if the Deals feature is disabled, we will not be able to request a Waiver and will get a 403
					// but if the feature is enabled, we need to make sure that at least one waiver is available
					conditionOfDealsRender ? ['Invest'] : [],
				)
				.sort(),
		);
	}, [defaultsSettings, chaptersSettings, conditionOfDealsRender]);

	return (
		<Page title={t('Notifications')}>
			{conditionForActivityIndicator ? (
				<ActivityIndicator size="medium" type="fit" />
			) : (
				<div className={styles.notificationsSettings}>
					<h3 className={styles.notificationsSettings__title}>{t('Notifications')}</h3>
					<div className={styles.navigation__items}>
						{settingsItems.map(
							(group) =>
								group && (
									<div key={group}>
										<NavigationCard
											title={capitalize(group)}
											onClick={() => navigate(ROUTES.notificationSettingsGroup(capitalize(group)))}
										/>
										<div className={styles.divider} />
									</div>
								),
						)}
					</div>
				</div>
			)}
		</Page>
	);
};

export default NotificationsSettingsPage;
