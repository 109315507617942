import { Modal } from '@ui-kit';
import { useTranslation, useToggle } from '@hooks';
import { useBlocker } from 'react-router-dom';

/** Asks the user if they want to discard changes when navigating away from a page.
 * 	Applicable to any pages.
 */
const DiscardPageChangesModal = ({ condition = false, originPathname = location.pathname }: IDiscardModalProps) => {
	const { t } = useTranslation();

	const [showDialog, toggleShowDialog] = useToggle(false);

	const blocker = useBlocker(({ nextLocation }) => {
		const isNotOriginPathname = nextLocation.pathname !== originPathname;
		const shouldBlock = condition && isNotOriginPathname;
		if (shouldBlock) {
			toggleShowDialog();
		}

		return shouldBlock;
	});

	return (
		<Modal
			cancelTitle={t('Discard changes')}
			confirmTitle={t('Keep editing')}
			subTitle={t('Are you sure you want to discard your changes?')}
			title={t('Discard Changes')}
			variant="medium"
			visible={showDialog}
			onCancel={() => {
				blocker.proceed?.();
			}}
			onConfirm={() => {
				toggleShowDialog();
			}}
		/>
	);
};

export interface IDiscardModalProps {
	/** Whether to show the modal. Default 'false'. */
	condition?: boolean;
	/** The pathname of the page where the modal is shown. Default 'location.pathname'. */
	originPathname?: string;
}

export default DiscardPageChangesModal;
