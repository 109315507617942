import { useBlockedAndBlockingUsers, useMe, useQuery, useService, useUserGroups } from '@hooks';
import type { TCommunity, TFullUser } from '@typings';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const useSearchUsersQuery = ({
	searchFilter,
	communityId,
	options,
}: IUseGetUsersQueryProps): TUseSearchUsersQuery => {
	const api = useService('ApiService');
	const { user: myUser } = useMe();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	const userGroups = useUserGroups();

	return useQuery(
		['user.getUsers', searchFilter, communityId],
		() => {
			return api.user.getUsers(searchFilter, communityId);
		},
		{
			cacheTime: 0,
			staleTime: 0,
			...options,
			select: (data) =>
				data
					?.filter((user) => {
						const blockUserRelation = blockedAndBlockingUsers.find((relation) => relation.id === user.id);
						// Filter out blocked users, users with specific roles, and the current user
						return (
							!blockUserRelation &&
							!user.roles?.includes('ROLE_FORMER') &&
							!user.roles?.includes('ROLE_INACTIVE') &&
							user.id !== myUser.id
						);
					})
					.map((user) => {
						return {
							...user,
							userSubtitle: userGroups[user.id],
						};
					}),
		},
	);
};

type TUseSearchUsersQuery = UseQueryResult<TFullUser[], unknown>;

interface IUseGetUsersQueryProps {
	searchFilter?: string;
	communityId?: TCommunity['id'];
	options?: UseQueryOptions<TFullUser[], Error, TFullUser[], any[]>;
}
