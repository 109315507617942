import { ROUTES } from '@constants';
import { useEffect, useNavigate } from '@hooks';
import type { TNetworkConnectionTabName } from '@typings';
import type { INetworkConnectionTabsPageParams } from './NetworkConnectionTabsPage';

const connectionTabs: TNetworkConnectionTabName[] = ['details', 'discussions', 'members', 'files'];

/** Handles behavior of NetworkConnection item tabs. */
export const useNetworkConnectionTabs = ({ id, tab }: INetworkConnectionTabsPageParams) => {
	const navigate = useNavigate();

	const currentTabIndex = connectionTabs.findIndex((connectionTab) => connectionTab === tab) ?? 0;
	const onChangeTab = (index: number) => {
		const tabName: TNetworkConnectionTabName = connectionTabs[index] ?? connectionTabs[0];
		navigate(ROUTES.networkConnection(id, tabName), { replace: true /*state*/ });
	};

	useEffect(
		function redirectFormMissingTab() {
			if (currentTabIndex === -1) {
				onChangeTab(0);
			}
		},
		[currentTabIndex],
	);

	return { tabs: connectionTabs, currentTabIndex, onChangeTab };
};
