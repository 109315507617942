import type { ReactElement } from 'react';
import { useHandleBackButton } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import { BackIcon, MeatballMenu } from '@ui-kit';
import { clsx } from '@utils';
import styles from './FullScreenPageLayout.module.css';

const FullScreenPageLayout = ({
	headerTitle,
	children,
	leftButtonText,
	onLeftButtonPress,
	rightButtonText,
	rightButtonDisabled = false,
	isFixed = false,
	hideBackButton = false,
	meatballMenuOptions,
	onRightButtonPress,
}: IFullScreenPageLayoutProps) => {
	const { onBackButtonPress } = useHandleBackButton();

	return (
		<div className={clsx(styles.layout, isFixed && styles.layout_fixed)}>
			{headerTitle ? (
				<div className={styles.layout__header}>
					<div className={styles.layout__headerButtonBlock}>
						{hideBackButton ? null : (
							<button
								className={styles.layout__headerButton}
								onClick={onLeftButtonPress ? onLeftButtonPress : onBackButtonPress}
							>
								{leftButtonText ? (
									<span className={styles.layout__headerLabel}>{leftButtonText}</span>
								) : (
									<BackIcon className={styles.layout__headerBackIcon} height={16} width={16} />
								)}
							</button>
						)}
					</div>
					<span className={styles.layout__headerTitle}>{headerTitle}</span>
					<div className={clsx(styles.layout__headerButtonBlock, styles.layout__headerButtonBlock_right)}>
						{rightButtonText && onRightButtonPress ? (
							<button
								className={styles.layout__headerButton}
								disabled={rightButtonDisabled}
								onClick={onRightButtonPress}
							>
								<span className={styles.layout__headerLabel}>{rightButtonText}</span>
							</button>
						) : null}
						{meatballMenuOptions ? <MeatballMenu options={meatballMenuOptions} position="bottom-left" /> : null}
					</div>
				</div>
			) : null}
			<div className={clsx(styles.layout__content, isFixed && styles.layout__content_fixed)}>{children}</div>
		</div>
	);
};

export interface IFullScreenPageLayoutProps {
	headerTitle?: string;
	children: ReactElement;
	leftButtonText?: string;
	onLeftButtonPress?: () => void;
	rightButtonText?: string;
	rightButtonDisabled?: boolean;
	onRightButtonPress?: () => void;
	/** Set to "true" if you want page to not grow out of the visible part and prevent appearing of the scrollbar. Default "false". */
	isFixed?: boolean;
	/** IF passed on the right side the MeatballMenu will be displayed. Conflicts with rightButtonText. */
	meatballMenuOptions?: TMeatballMenuOption[];
	hideBackButton?: boolean;
}

export default FullScreenPageLayout;
