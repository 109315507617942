import { extractIdFromIri } from '@utils';
import type { TDealMember, TUserProfileRecord, TMediaObjectCompact } from '@tiger21-llc/connect-shared/src/typings';

export const adaptDealMemberToUserProfileRecord = (member: TDealMember): TUserProfileRecord => ({
	...member,
	avatar: {
		contentUrl: member?.avatarUrl,
	} as TMediaObjectCompact,
	profile: {
		id: extractIdFromIri(member?.profileIri),
		'@type': member.profileType,
		title: member.profileTitle,
	},
	roles: member.roles,
});
