import { useService } from '@hooks';
import type { TActivity } from '@typings';
import type { FeedAPIResponse } from 'getstream';

export const useRemoveActivityFromFeedsCache = () => {
	const reactQuery = useService('ReactQueryService');

	/** Using mergingPartialActivity makes sense because GetStream often returns stale activity for getActivity(activityId) call after update through API. */
	return async function removeActivityFromFeedsCache(activityId: TActivity['id']) {
		const queriesData = reactQuery.queryClient.getQueriesData<FeedAPIResponse>(reactQuery.queryKeys.getUserFeed());

		for (const [queryKey, cache] of queriesData) {
			if (!cache) continue;

			const activityFound = !!(cache.results as any as TActivity[]).find((activity) => activity.id === activityId);

			if (activityFound) {
				const invalidatingQueryKey = queryKey.slice(0, 2); // remove page from key. For example ["client.feed","U--t21c_staff1-1", 2] to ["client.feed","U--t21c_staff1-1"]
				reactQuery.queryClient.removeQueries(invalidatingQueryKey);
			}
		}

		reactQuery.queryClient.removeQueries(reactQuery.queryKeys.getSingleActivity(activityId));
	};
};
