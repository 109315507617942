import mitt from 'mitt';
import type { EventType } from 'mitt';

/** Implementation of EventEmitter
 * 	that can be used as base class for services
 * 	to allow React hooks listen service events.
 */
export class EventEmitter<TEvents extends Record<EventType, unknown>> {
	private emitter = mitt<TEvents>();

	public on = this.emitter.on;
	public off = this.emitter.off;
	public all = this.emitter.all;
	protected emit = this.emitter.emit;
}
export type { EventType } from 'mitt';
