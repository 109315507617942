import LightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import type { ILightBoxProps } from 'react-image-lightbox';
import { useLongPress } from '@hooks';
import { memo } from 'react';
import When from '../When';
import { noop } from '@utils';
import { useState } from '@hooks';
import styles from './ImageGridGallery.module.css';

/** Displays grid of images with ability to open it full screen image viewer (LightBox).
 *  No more than 4 images are shown. If there are more than 4 ones, over the last image the overlay with +X text is displayed.
 */
const ImageGridGallery = ({ images, imageTitle, onImageOpened }: IImageGridGalleryProps) => {
	const [index, setIndex] = useState<number>(-1);

	// Calculate images states.
	const currentImage = images[index];
	const nextIndex = (index + 1) % images.length;
	const nextImage = images[nextIndex] || currentImage;
	const prevIndex = (index + images.length - 1) % images.length;
	const prevImage = images[prevIndex] || currentImage;
	const moreImagesCount = Math.max(0, images.length - GALLERY_SIZE);
	const longPressProps = useLongPress({
		onClick: (event) => {
			const imgElement = event.currentTarget as HTMLImageElement;
			const index = Number(imgElement.dataset.galleryImageIndex) || 0;
			handleClick(index);
		},
		onLongPress: noop,
	});

	// Gallery action handlers.
	const handleClick = (index: number) => {
		setIndex(index);
		onImageOpened?.(currentImage);
	};
	const handleClose = () => setIndex(-1);
	const handleMovePrev = () => {
		setIndex(prevIndex);
		onImageOpened?.(prevImage);
	};
	const handleMoveNext = () => {
		setIndex(nextIndex);
		onImageOpened?.(nextImage);
	};

	return (
		<div
			className={styles.imageGridGallery}
			onClick={(event) => event.stopPropagation()}
			onKeyPress={(event) => event.stopPropagation()}
		>
			{images.slice(0, GALLERY_SIZE).map((image, index) => {
				const isLast = index === GALLERY_SIZE - 1;
				const shouldShowMore = isLast && !!moreImagesCount;

				return (
					<div
						className={styles.imageGridGallery__item}
						data-gallery-image-index={index}
						key={index}
						{...longPressProps}
					>
						<img alt={String(index)} className={styles.imageGridGallery__itemImage} key={index} src={image} />
						<When condition={shouldShowMore}>
							<div className={styles.imageGridGallery_showMoreOverlay}>{`+${moreImagesCount}`}</div>
						</When>
					</div>
				);
			})}

			{/* Fullscreen image viewer. */}
			{!!currentImage && (
				<LightBox
					enableZoom={false}
					imageTitle={imageTitle}
					mainSrc={currentImage}
					mainSrcThumbnail={currentImage}
					nextSrc={nextImage}
					nextSrcThumbnail={nextImage}
					prevSrc={prevImage}
					prevSrcThumbnail={prevImage}
					reactModalStyle={{
						overlay: {
							zIndex: 1050 /* equals to var(--zindex-modal) (fixes T21C-4012) */,
						},
					}}
					onCloseRequest={handleClose}
					onMoveNextRequest={handleMoveNext}
					onMovePrevRequest={handleMovePrev}
				/>
			)}
		</div>
	);
};

/** How many images show in image gallery.
 *  If there are more images than in this number, the "+1" or "+X" text will be shown over the last image.
 *  You can't change this value without updating CSS styles.
 */
const GALLERY_SIZE = 4;

/** Image source URL. */
export type TImageGridGalleryItem = string;

export interface IImageGridGalleryProps extends Pick<ILightBoxProps, 'imageTitle'> {
	/** Array of image URLs. */
	images: TImageGridGalleryItem[];
	/** Callback to handle image click. */
	onImageOpened?: (imageUrl: string) => void;
}

export default memo(ImageGridGallery);
