import DealMemberBlock from './DealMemberBlock';
import { useTranslation } from '@hooks';
import { useGetLatestMembersForDealQuery } from '@ui-modules/deals/hooks/useDealMemberRelationsQuery';
import { translateStatusOfDealMember } from '@utils';
import styles from './DealBlocks.module.css';

const DealMembers = ({ dealId }: { dealId: string }) => {
	const { t } = useTranslation();

	const { data: latestMembers } = useGetLatestMembersForDealQuery(dealId);
	const zeroMembers =
		latestMembers &&
		!latestMembers?.invested?.items?.length &&
		!latestMembers?.interested?.items?.length &&
		!latestMembers?.['not interested']?.items?.length;

	const investedItems = latestMembers?.invested.totalItems || 0;
	const interestedItems = latestMembers?.interested.totalItems || 0;
	const notInterestedItems = latestMembers?.['not interested'].totalItems || 0;

	return (
		<div className={!zeroMembers ? styles.collapsible__members : styles.collapsible__noMembers}>
			{!zeroMembers ? (
				<>
					{latestMembers?.invested && latestMembers.invested.items.length ? (
						<DealMemberBlock
							dealId={dealId}
							members={latestMembers?.invested.items}
							status={translateStatusOfDealMember('invested', t)}
							total={investedItems}
						/>
					) : null}
					{latestMembers?.interested && latestMembers?.interested.items.length ? (
						<DealMemberBlock
							dealId={dealId}
							members={latestMembers?.interested.items}
							status={translateStatusOfDealMember('interested', t)}
							total={interestedItems}
						/>
					) : null}
					{latestMembers?.['not interested'] && latestMembers['not interested'].items.length ? (
						<DealMemberBlock
							dealId={dealId}
							members={latestMembers?.['not interested'].items}
							status={translateStatusOfDealMember('not interested', t)}
							total={notInterestedItems}
						/>
					) : null}
				</>
			) : (
				<div className={styles.collapsible__noMembersTitle}>
					{t(
						`When a Member indicates they're "Invested", "Interested" or "Not Interested" on this Deal, they'll be listed here.`,
					)}
				</div>
			)}
		</div>
	);
};

export default DealMembers;
