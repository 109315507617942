import ReactDOM from 'react-dom';
import type { ReactNode } from 'react';

const Portal = ({ children }: IPortalProps) => {
	return ReactDOM.createPortal(children, document.body);
};

interface IPortalProps {
	children: ReactNode;
}

export default Portal;
