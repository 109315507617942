import { IdxStatus } from '@okta/okta-auth-js';
import { OktaError } from './AbstractOktaError';
import { SUPPORT_LINK } from '@constants';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TFunction } from 'i18next';

export class OktaUnknownError extends OktaError {
	constructor(private readonly transaction: IdxTransaction) {
		super();
	}

	status!: 'UNKNOWN';

	static shouldThrowError(transaction: IdxTransaction) {
		return transaction.status === IdxStatus.PENDING;
	}

	getMessage(t: TFunction) {
		const message = this.transaction.messages?.map((messageItem) => messageItem.message).join('. ');
		const defaultMessage = t(
			'Something went wrong. Please try again. You can also reset your password, or email {{supportEmail}} for help signing in.',
			{ supportEmail: SUPPORT_LINK },
		);
		return {
			title: t('Error'),
			subtitle: message ?? defaultMessage,
		};
	}
}
