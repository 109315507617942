import { Modal } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useDeleteReactionMutation } from '@ui-modules/feed/hooks/useDeleteReactionMutation';
import { noop } from '@utils';
import type { IModalProps } from '@ui-kit';
import type { TActivity, TComment } from '@typings';

/** Confirms deleting of a file and performs it. */
const DeleteReactionModal = ({
	visible,
	activity,
	comment,
	feedSlug,
	onClose,
	onSuccess = noop,
}: IDeleteReactionModalProps) => {
	const { t } = useTranslation();

	const isReply = !!comment.parent;
	const subTitle = isReply
		? t('Deleting this reply will permanently remove it from this Network.')
		: t('Deleting this comment will permanently remove it from this Network.');
	const title = isReply ? t('Delete Reply?') : t('Delete Comment?');

	const { mutate: deleteReaction, isLoading } = useDeleteReactionMutation(feedSlug, {
		onSuccess: () => {
			onClose();
			onSuccess?.();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={subTitle}
			title={title}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				deleteReaction({ activity, comment });
			}}
		/>
	);
};

export interface IDeleteReactionModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Activity to delete. */
	activity: TActivity;
	/** Target Activity to delete. */
	comment: TComment;
	/** Stream feed slug (userId) */
	feedSlug: string;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeleteReactionModal;
