import { RightArrowIcon } from '@ui-kit/icons';
import { FolderFileIcon } from '@ui-kit/fileIcons';
import { clsx, dayjs } from '@utils';
import styles from './FolderListItem.module.css';
import type { TFolder } from '@tiger21-llc/connect-shared/src/typings';

/** Renders list row of folder entity (see TFolder). */
const FolderListItem = ({ folder, withSeparator = false, onClick }: IFolderListItemProps) => {
	const folderInfo = `${dayjs(folder.createdAt).format('MMM DD YYYY, h:mmA')} by ${folder.owner.firstName} ${
		folder.owner.lastName
	}`;

	const handleClick = () => onClick(folder);

	return (
		<div
			className={clsx(styles.folderListItem, withSeparator && styles.folderListItem_withSeparator)}
			onClick={handleClick}
			onKeyPress={handleClick}
		>
			<div className={styles.folderListItem__itemHeader}>
				<FolderFileIcon fill="#000000" height={24} width={24} />
			</div>
			<div className={styles.folderListItem__itemBody}>
				<h4 className={styles.folderListItem__header}>{folder.name}</h4>
				<small className={styles.folderListItem__fileInfo}>{folderInfo}</small>
			</div>
			<div className={styles.folderListItem__itemFooter}>
				<RightArrowIcon fill="#000000" height={16} width={14} />
			</div>
		</div>
	);
};

export interface IFolderListItemProps {
	/** Folder data to render. */
	folder: TFolder;
	/** If 'true' border bottom will be shown as a separator. Default 'false'. */
	withSeparator?: boolean;
	/** Called when folder list item clicked.. */
	onClick: (folder: TFolder) => void;
}

export default FolderListItem;
