import { useTranslation } from '@hooks';
import type { TProfileEvent, TProfile } from '@typings';
import { ActivityIndicator, Button, Card, When } from '@ui-kit';
import { useProfileEventsQuery } from '@ui-modules/profile/hooks/useProfileEventsQuery';
import EventCard from '../EventCard';
import styles from './ProfileEvents.module.css';

const ProfileEvents = ({
	isInvisible = false,
	isHidden = false,
	profileId,
	canEdit,
	isMe,
	onEdit,
	onPressEvent,
	onSeeAll,
}: IProfileEventsProps) => {
	const { t } = useTranslation();

	const { data: events = [], isLoading } = useProfileEventsQuery(profileId, {
		enabled: !isHidden && !isInvisible,
	});
	const isOtherEventsEmpty = !events?.length && !isMe;

	return isInvisible || isOtherEventsEmpty ? null : (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			articleMode="transparent"
			backgroundPlacement="article"
			title={t('My Upcoming Events')}
		>
			{isHidden ? (
				<span>{t('This section is currently hidden from others')}</span>
			) : isLoading ? (
				<ActivityIndicator type="fit" />
			) : events?.length ? (
				<>
					<div className={styles.events__container}>
						{events?.slice(0, 3).map((event) => (
							<EventCard event={event} key={event['@id']} onPress={onPressEvent} />
						))}
					</div>
					<When condition={events.length > 3}>
						<div className={styles.events__button}>
							<Button aria-label="toggle" title={t('See All')} type="clear" onClick={onSeeAll} />
						</div>
					</When>
				</>
			) : (
				<span>{t('No upcoming events')}</span>
			)}
		</Card>
	);
};

export interface IProfileEventsProps {
	isInvisible?: boolean;
	canEdit: boolean;
	isHidden?: boolean;
	isMe?: boolean;
	profileId: TProfile['id'];
	onPressEvent: (event: TProfileEvent) => void;
	onEdit: () => void;
	onSeeAll: () => void;
}

export default ProfileEvents;
