import { useService, useMutation, useNotification, useMe } from '@hooks';
import { useUploadMediaObjectMutation } from './useUploadMediaObjectMutation';
import type { TNetworkConnection, TMediaObject, TFile, TFolder } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useUploadNetworkConnectionFileMutation = (
	networkConnection: TNetworkConnection,
	folderId: TFolder['id'] | null,
	options?: MutationOptions<TFile, Error, TMediaObject>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	const uploadFileMutation = useMutation<TFile, Error, TMediaObject>(
		['networkConnectionFile.postNetworkConnectionFile'],
		async (mediaObject) => {
			return await api.networkConnectionFile.postNetworkConnectionFile({
				networkConnectionId: networkConnection.id,
				folderId: folderId ?? undefined,
				mediaObjectId: mediaObject.id,
				name: mediaObject.originalName,
				ownerIri: user['@id'],
				isNetworkConnectionInformationFile: networkConnection?.owner?.id === user.id && !folderId,
			});
		},
		{
			...options,
			async onSuccess(data, variables, context) {
				await reactQuery.queryClient.refetchQueries(
					reactQuery.queryKeys.getNetworkConnectionFiles(networkConnection.id, folderId),
				);
				options?.onSuccess?.(data, variables, context);
			},
			async onError(error, variables, context) {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);

	const uploadMediaObjectMutation = useUploadMediaObjectMutation({
		async onSuccess(mediaObject) {
			await uploadFileMutation.mutateAsync(mediaObject);
		},
	});

	return {
		uploadFile: uploadMediaObjectMutation.mutateAsync,
		isLoading: uploadMediaObjectMutation.isLoading || uploadFileMutation.isLoading,
	};
};
