import { useService, useQuery, useMe } from '@hooks';
import { dayjs } from '@utils';
import { AxiosError } from 'axios';
import type { TAssetAllocationPeriod, TCommunitiesAssetAllocation } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useAssetAllocationCommunitiesQuery = <T = TCommunitiesAssetAllocation | null>(
	queryOptions?: UseQueryOptions<TCommunitiesAssetAllocation | null, AxiosError, T>,
	comparePeriod?: TAssetAllocationPeriod | undefined,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TCommunitiesAssetAllocation | null, AxiosError, T>(
		reactQuery.queryKeys.getAssetAllocationCommunities(comparePeriod),
		async () => {
			try {
				return await api.assetAllocation.getUserAssetAllocationCommunities(user.id, comparePeriod);
			} catch (error) {
				if (error instanceof AxiosError && error.response?.status === 404) {
					return null;
				} else {
					throw error;
				}
			}
		},
		{
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			cacheTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
