import { createContext, useContext, useRef } from 'react';
import type { ServicesContainer } from './ServicesContainer';

const ServicesContext = createContext<ServicesContainer | null>(null);
export const ServicesProvider = ServicesContext.Provider;
export const useService: ServicesContainer['resolve'] = (token) => {
	const servicesContext = useContext(ServicesContext);
	if (!servicesContext) {
		if (import.meta.env.NODE_ENV === 'test')
			return new Proxy(
				{},
				{
					get: () =>
						function () {
							/* stub */
						},
				},
			) as any;
		else throw new Error('wrap app to the ServicesContext');
	}

	return servicesContext.resolve(token);
};

export const useLogger = (contextName: string) => {
	const logger = useService('logger');
	const specificLogger = useRef(logger.child(contextName)).current;

	return specificLogger;
};
