import DealFiltersSettingsForm from '../DealFiltersSettingsForm';
import DealFiltersOptionsBlock from '../DealFiltersOptionsBlock';
import { Gap, SpacedGroup, SwitchButton } from '@ui-kit';
import { useCallback, useCollapse, useEffect, useRef, useService, useTranslation } from '@hooks';
import { clsx } from '@utils';
import { useResetDealFiltersNotificationsMutation } from '@ui-modules/deals/hooks/useResetDealFiltersNotificationsMutation';
import type { TSupportedChannel } from '@typings';
import styles from '../SettingsCard/SettingsCard.module.css';
import useAllDealFilterConfig from '@ui-modules/deals/hooks/useAllDealFilterConfigQuery';

const DealSettingsCardContent = ({
	settingsArray,
	isDefaultExpanded = false,
	onChangeCollapsed,
}: IDealSettingsCardContentProps) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement | null>(null);
	const { data: allDealFilterConfig } = useAllDealFilterConfig();

	const { mutate: resetDealFiltersNotifications, isLoading: isResetDealFiltersNotificationsLoading } =
		useResetDealFiltersNotificationsMutation();
	const { queryClient, queryKeys } = useService('ReactQueryService');

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries(queryKeys.getDealSettings());
			queryClient.refetchQueries(queryKeys.getDealSettings());
		};
	}, []);

	const { getCollapseProps, isExpanded, setExpanded } = useCollapse({
		defaultExpanded: isDefaultExpanded,
	});

	const onSwitchChange = useCallback(() => {
		setExpanded(!isExpanded);
		onChangeCollapsed(!isExpanded);
		if (isExpanded) resetDealFiltersNotifications(settingsArray);
		if (!isExpanded)
			setTimeout(() => {
				ref.current?.scrollIntoView({ behavior: 'smooth' });
			}, 200);
	}, [isExpanded, settingsArray]);

	useEffect(() => {
		if (!isDefaultExpanded && isDefaultExpanded !== isExpanded) {
			setExpanded(false);
			return;
		}
		setExpanded(isDefaultExpanded);
	}, [isDefaultExpanded]);

	return (
		<div className={styles.settingsCard} ref={ref}>
			<div className={styles.settingsCard__header}>
				<h3>{t('New Deals')}</h3>
				<SwitchButton
					disabled={isResetDealFiltersNotificationsLoading || !allDealFilterConfig?.assetClasses.length}
					loading={isResetDealFiltersNotificationsLoading}
					value={isExpanded}
					onChange={onSwitchChange}
				/>
			</div>
			{!allDealFilterConfig?.assetClasses.length ? (
				<span className={styles.settingsCard__hint}>
					{t(
						'Controls for Deal Notifications will show here for each of your Investment Networks. You haven’t joined any Investment Networks.',
					)}
				</span>
			) : null}
			{settingsArray.length > 1 ? (
				<div
					data-testid="CollapsibleSettingsCard__content"
					{...getCollapseProps()}
					className={styles.settingsCard__content}
				>
					<div
						className={clsx(styles.settingsCard__content_visible, !isExpanded && styles.settingsCard__content_visible)}
					>
						<div className={styles.settingsCard__labels}>
							<>
								<span>{t('email').toUpperCase()}</span>
								<Gap gap={12} />
								<span>{t('push').toUpperCase()}</span>
							</>
						</div>
						<SpacedGroup direction="vertical" fullWidth>
							{settingsArray.map((setting) => {
								const settingFormValue = {
									id: setting.id,
									options: setting.options,
								};

								return (
									<DealFiltersSettingsForm initialValue={settingFormValue} key={setting.id} visible={isExpanded}>
										<DealFiltersOptionsBlock
											id={setting.id}
											isOpened={isExpanded}
											status={setting.status}
											subTitle={setting.subtitle}
											title={setting.name}
										/>
									</DealFiltersSettingsForm>
								);
							})}
						</SpacedGroup>
					</div>
				</div>
			) : null}
		</div>
	);
};

type TDealSettingsItem = {
	name: string;
	options: TSupportedChannel[];
	id: string;
	subtitle: string;
	status: string | null;
};

interface IDealSettingsCardContentProps {
	isDefaultExpanded: boolean;
	onChangeCollapsed: (isCollapsed: boolean) => void;
	settingsArray: TDealSettingsItem[];
}

export default DealSettingsCardContent;
