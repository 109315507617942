import { Button, ListIcon, EditIcon } from '@ui-kit';
import { useNavigate, useTranslation } from '@hooks';
import { ROUTES } from '@constants';
import type { TProfileType } from '@typings';
import styles from './DrawerActionBox.module.css';

const DrawerActionBox = ({ profileType, isAdmin }: IDrawerActionBoxProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	if (isAdmin) {
		return (
			<div className={styles.menu__attendance}>
				{/* Will be added in a future release */}
				{/* <Button
				block="fit"
				icon={<ClockIcon height={16} width={16} />}
				iconPosition="right"
				title={t('Availability')}
				onClick={() => null}
			/> */}
				<Button
					block="fit"
					icon={<EditIcon height={16} width={16} />}
					iconPosition="right"
					title={t('New Post')}
					onClick={() => navigate(ROUTES.newPost())}
				/>
			</div>
		);
	} else if (profileType === 'ProfileChair') {
		return (
			<div className={styles.menu__attendance}>
				<Button
					icon={<ListIcon height={14} width={14} />}
					iconPosition="right"
					title={t('Attendance Requests')}
					onClick={() => navigate(ROUTES.meetingsRequests())}
				/>
			</div>
		);
	}

	return null;
};

export interface IDrawerActionBoxProps {
	profileType: TProfileType;
	isAdmin: boolean;
}

export default DrawerActionBox;
