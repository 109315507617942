import type { IAppConfig } from '../interfaces/AppConfig.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';

/** The wrapper for logger automatically set context uses native console. */
export class LoggerService implements IDevLogger {
	static inject = ['AppConfigService'] as const;
	constructor(private readonly appConfig: IAppConfig) {
		this.loggerInstance = console;
	}

	private loggerInstance;

	get debug() {
		return this.loggerInstance.debug;
	}
	get log() {
		return this.loggerInstance.log;
	}
	get info() {
		return this.loggerInstance.info;
	}
	get warn() {
		return this.loggerInstance.warn;
	}
	get error() {
		return this.loggerInstance.error;
	}

	child(contextName: string) {
		const loggerExtension: IDevLogger = new Proxy(
			{
				...this.loggerInstance,
				child: this.child,
			},
			{
				get(target, property) {
					if (
						property === 'log' ||
						property === 'debug' ||
						property === 'info' ||
						property === 'warn' ||
						property === 'error'
					)
						return (...args: unknown[]) => target[property](`[${contextName}] `, ...args);
					else return property;
				},
			},
		);

		return loggerExtension;
	}
}
