import { PieChart as ReactMinimalPieChart } from 'react-minimal-pie-chart';
import { memo, useMemo } from 'react';
import styles from './PieChart.module.css';
import When from '../When';
import Gap from '../Gap';
import PieChartLabel from './components/PieChartLabel';
import { usePieChartLabelPropsState } from './hooks/usePieChartLabelPropsState';
import { adjustLabelsRenderDataToAvoidOverflows } from './utils/adjustLabelsRenderDataToAvoidOverflows';
import { mapLabelPropsToRenderProps } from './utils/mapLabelPropsToRenderProps';
import { separateSegmentsBySides } from './utils/separateSegmentsBySides';
import type { LabelRenderProps } from 'react-minimal-pie-chart/types/Label';

const DEFAULT_COLORS = [
	'#E8E8E8',
	'#DCDCDC',
	'#C8C8C8',
	'#858585',
	'#797979',
	'#666666',
	'#4C4C4C',
	'#333333',
	'#000000',
];
const LABEL_ANCHOR_DEGREE_SHIFT = 30;
const MIN_DISTANCE_BETWEEN_ADJACENT_LABELS = 24;

const PieChart = ({ items, height = 300, colors = DEFAULT_COLORS, title, subtitle }: IPieChartProps) => {
	const pieChartRenderItems = useMemo<TPieChartRenderItem[]>(() => {
		const notEmptyItemsWithColors = items
			.filter((item) => !!item.value)
			.map((item, index) => ({
				...item,
				color: colors[index % colors.length],
			}));
		return notEmptyItemsWithColors;
	}, [items]);

	const [labelPropsSet, appendLabelProps] = usePieChartLabelPropsState(pieChartRenderItems);

	const [leftSideLabelsRenderData, rightSideLabelsRenderData] = useMemo(() => {
		if (labelPropsSet.length !== pieChartRenderItems.length) return [[], []];

		const labelsRenderData = mapLabelPropsToRenderProps(labelPropsSet, LABEL_ANCHOR_DEGREE_SHIFT);
		const [leftLabelsRenderData, rightLabelsRenderData] = separateSegmentsBySides(labelsRenderData);
		const adjustedLeftLabelsRenderData = adjustLabelsRenderDataToAvoidOverflows(
			leftLabelsRenderData,
			height,
			MIN_DISTANCE_BETWEEN_ADJACENT_LABELS,
		);
		const adjustedRightLabelsRenderData = adjustLabelsRenderDataToAvoidOverflows(
			rightLabelsRenderData,
			height,
			MIN_DISTANCE_BETWEEN_ADJACENT_LABELS,
		);
		return [adjustedLeftLabelsRenderData, adjustedRightLabelsRenderData];
	}, [labelPropsSet, height, items.length]);

	return (
		<div>
			<div className={styles.pieChart__header}>
				<When condition={!!title}>
					<h2>{title}</h2>
				</When>
				<When condition={!!subtitle}>
					<div>
						<span className={styles.pieChart__subheader}>{subtitle}</span>
					</div>
				</When>
			</div>
			<When condition={!!title || !!subtitle}>
				<Gap gap={16} />
			</When>
			<div className={styles.pieChart__chartBox}>
				<ReactMinimalPieChart
					data={pieChartRenderItems}
					label={(labelProps) => {
						appendLabelProps(labelProps);
						return null;
					}}
					labelPosition={90}
					startAngle={-90}
					style={{
						width: '100%',
						height: height,
					}}
					totalValue={100}
				/>
				<div className={styles.pieChart__chartDropShadow} />
				{leftSideLabelsRenderData.map((labelRenderProps, index) => (
					<PieChartLabel key={labelRenderProps.label + index} {...labelRenderProps} />
				))}
				{rightSideLabelsRenderData.map((labelRenderProps, index) => (
					<PieChartLabel key={labelRenderProps.label + index} {...labelRenderProps} />
				))}
			</div>
		</div>
	);
};

export type TPieChartLabelRenderProps = {
	x: number;
	y: number;
	value: number;
	label: string;
	labelPosition: 'left' | 'right';
	labelTranslateXPercent: number;
	segmentIsOnLeftChartSide: boolean;
};

export type TPieChartItem = {
	id: string;
	title: string;
	description?: string;
	value: number;
};

type TPieChartRenderItem = TPieChartItem & {
	color: string;
};

export type TLabelProps = LabelRenderProps<TPieChartRenderItem>;

export interface IPieChartProps {
	/** The array of items for the pie chart. */
	items: TPieChartItem[];
	/** The height of the pie chart. */
	height: number;
	/** The array of colors for the pie chart which will be picked one after another. */
	colors?: string[];
	/** The title of the pie chart. */
	title: string;
	/** The subtitle of the pie chart. */
	subtitle?: string;
}

export default memo(PieChart);
