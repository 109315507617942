import { useService, useMutation, useTranslation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TReportNetworkConnectionReplyDto, TReportNetworkConnectionReplyResponseDto } from '@typings';

export const useReportNetworkConnectionReplyMutation = (
	options?: MutationOptions<TReportNetworkConnectionReplyResponseDto, Error, TReportNetworkConnectionReplyDto>,
) => {
	const api = useService('ApiService');
	const { t } = useTranslation();
	const { showUnknownError, showSuccess } = useNotification();
	const feedService = useService('FeedService');
	const { queryClient, queryKeys } = useService('ReactQueryService');

	return useMutation<TReportNetworkConnectionReplyResponseDto, Error, TReportNetworkConnectionReplyDto>(
		['streamFeeds.postReportFeedReply'],
		async (report) => {
			const reportData = { reason: report.reason, explanation: report.explanation };
			await feedService.addCommentReportReaction(report.commentId, reportData);
			return await api.networkConnection.postReportNetworkConnectionReply(report);
		},
		{
			...options,
			async onSuccess(data, reportDto, context) {
				await queryClient.refetchQueries(queryKeys.getPostActivityReactions());

				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(data, reportDto, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
