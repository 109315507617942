import { useHandleBackButton } from '@hooks';
import { Avatar, BackIcon, Link, MeatballMenu, When } from '@ui-kit';
import { clsx } from '@utils';
import styles from './EntityPageHeader.module.css';
import type { TMeatballMenuOption } from '@ui-kit';

const EntityPageHeader = ({
	theme = 'light',
	avatarUri,
	isChair,
	fullName,
	location,
	networkLink: subtitleLink,
	date,
	network,
	meatballMenuOptions,
	onProfilePress,
	onBackPress,
}: IEntityPageHeaderProps) => {
	const { onBackButtonPress } = useHandleBackButton();
	const goBack = () => onBackButtonPress();

	return (
		<div className={styles.header} data-theme={theme}>
			<div className={styles.header__body}>
				<button className={styles.header__backButton} onClick={onBackPress ?? goBack}>
					<BackIcon className={styles.header__backButtonIcon} height={16} width={16} />
				</button>
				<Avatar imageUri={avatarUri} outline={isChair} size={40} title={fullName} onClick={() => onProfilePress()} />
				<div className={styles.header__info} onClick={() => onProfilePress()} onKeyPress={() => onProfilePress()}>
					<div className={styles.header__fullName}>
						<p>{fullName}</p>
					</div>
					<div className={styles.header__location}>
						<p>{location}</p>
					</div>
					<p className={styles.header__secondaryInfo}>
						{date}
						<When condition={!!network}>
							<span> • </span>
							<Link
								className={clsx(styles.header__subtitle, subtitleLink && styles.header__subtitleLink)}
								to={subtitleLink || '#'}
								onClick={(event) => event.stopPropagation()}
							>
								{network}
							</Link>
						</When>
					</p>
				</div>
			</div>
			{meatballMenuOptions.length ? (
				<MeatballMenu
					meatBallColor={theme === 'dark' ? 'white' : 'black'}
					options={meatballMenuOptions}
					position="left"
				/>
			) : null}
		</div>
	);
};

export interface IEntityPageHeaderProps {
	theme: 'light' | 'dark';
	avatarUri: string | undefined;
	isChair: boolean;
	fullName: string;
	location: string | undefined;
	networkLink?: string;
	date: string;
	network: string;
	meatballMenuOptions: TMeatballMenuOption[];
	onProfilePress: () => void;
	onBackPress?: () => void;
}

export default EntityPageHeader;
