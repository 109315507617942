import styles from '../ChatInputBar/ChatInputBar.module.css';
import { CloseIcon } from '@ui-kit';
import { useTranslation } from '@hooks';

const EditMessageContainer = ({
	editMessage,
	quotedMessageId,
	showGifInput,
	resetData,
}: IEditMessageContainerProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.chatInputBar__sendButtonSection}>
			{editMessage || quotedMessageId || showGifInput ? (
				<div className={styles.chatInputBar__editOrReplyContainer}>
					<span className={styles.chatInputBar__editAndReply}>
						{editMessage && t('Editing Message')}
						{quotedMessageId && t('Reply to Message')}
						{showGifInput && t('Search GIFs...')}
					</span>
					<div className={styles.chatInputBar__closeIconContainer} onClick={resetData} onKeyPress={resetData}>
						<CloseIcon fill="#7a7a7a" height={12} width={13} />
					</div>
				</div>
			) : null}
		</div>
	);
};

interface IEditMessageContainerProps {
	editMessage: boolean;
	showGifInput: boolean;
	quotedMessageId: string;
	resetData: () => void;
}
export default EditMessageContainer;
