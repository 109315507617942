import { forwardRef, useImperativeHandle } from 'react';
import { CheckboxInput, EmptyFileCard, Gap, Modal } from '@ui-kit';
import { useCallback, useState, useTranslation } from '@hooks';
import FilePasswordFormModal from '@ui-modules/files/components/FilePasswordFormModal';
import type { ForwardedRef } from 'react';

const FileUploadDialogues = (
	{ onUploadFile, isUploading, passwordFormSubTitle }: IFileUploadDialoguesProps,
	ref: ForwardedRef<IFileUploadDialoguesApi>,
) => {
	const { t } = useTranslation();

	const [activeModal, setActiveModal] = useState<TActiveModal | undefined>(undefined);
	const [isFileViewOnly, setIsFileViewOnly] = useState(false);
	const [fileToUpload, setFileToUpload] = useState<File | undefined>();

	const onCloseUploadModal = useCallback(() => {
		setActiveModal(undefined);
		setIsFileViewOnly(false);
	}, [setActiveModal]);

	const emptyFilePlaceholder = fileToUpload && (
		<>
			<EmptyFileCard name={fileToUpload.name} type={fileToUpload.type} />
			<Gap gap={16} />
			{activeModal !== 'is_pd_file' && fileToUpload.type === 'application/pdf' ? (
				<>
					<CheckboxInput
						label={t('Make this file read-only (Members can’t download)')}
						size="medium"
						value={isFileViewOnly}
						onChange={() => setIsFileViewOnly((prev) => !prev)}
					/>
				</>
			) : null}
			<Gap gap={16} />
		</>
	);

	useImperativeHandle(ref, () => ({
		openDialogue: (fileToUpload: File) => {
			setFileToUpload(fileToUpload);
			setActiveModal('confirm_set_password');
		},
		closeDialogues: () => {
			setActiveModal(undefined);
			setFileToUpload(undefined);
		},
	}));

	return (
		<>
			<>
				<Modal
					cancelMode="skip"
					cancelTitle={t('Skip')}
					confirmTitle={t('Add A Password')}
					modalContent={emptyFilePlaceholder}
					subTitle={t('If this file is a Qualitative PD, you must add a password.')}
					title={t('Password')}
					visible={activeModal === 'confirm_set_password'}
					onCancel={() => setActiveModal('is_pd_file')}
					onClose={onCloseUploadModal}
					onConfirm={() => setActiveModal('password_form')}
				/>
				<Modal
					cancelTitle={t('Go back')}
					confirmTitle={t('No, this is not a PD file')}
					isConfirmLoading={isUploading}
					modalContent={emptyFilePlaceholder}
					subTitle={t('If this file is a Qualitative PD, you must add a password.')}
					title={t('Is this a PD file?')}
					visible={activeModal === 'is_pd_file'}
					onCancel={() => setActiveModal('confirm_set_password')}
					onClose={onCloseUploadModal}
					onConfirm={() =>
						onUploadFile({ file: fileToUpload as File, secretKey: undefined, isViewOnly: isFileViewOnly })
					}
				/>
				<FilePasswordFormModal
					file={fileToUpload as File}
					isUploading={isUploading}
					isViewOnly={isFileViewOnly}
					setIsViewOnly={() => setIsFileViewOnly((prev) => !prev)}
					subTitle={passwordFormSubTitle}
					visible={activeModal === 'password_form'}
					onCancel={() => setActiveModal('confirm_set_password')}
					onClose={onCloseUploadModal}
					onUpload={({ secretKey, isViewOnly }) => onUploadFile({ file: fileToUpload as File, secretKey, isViewOnly })}
				/>
			</>
		</>
	);
};

type TActiveModal = 'is_pd_file' | 'confirm_set_password' | 'password_form';

export interface IFileUploadDialoguesProps {
	isUploading: boolean;
	passwordFormSubTitle: string;
	onUploadFile: (args: { file: File; secretKey?: string; isViewOnly?: boolean }) => void;
}

export interface IFileUploadDialoguesApi {
	openDialogue: (fileToUpload: File) => void;
	closeDialogues: () => void;
}

export default forwardRef(FileUploadDialogues);
