import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ROUTES } from '@constants';
import type { TDealsTabName } from '@typings';

const DealsRootPage = () => {
	const dealsTab = useParams()?.dealsTab;
	const initDealTab: TDealsTabName = 'all-deals';

	if (!dealsTab) return <Navigate replace to={ROUTES.dealsTabs(initDealTab)} />;
	return <Outlet />;
};

export default DealsRootPage;
