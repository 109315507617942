import { useMutation, useNotification, useService, useTranslation } from '@hooks';
import type { TDeal, TCommunity } from '@typings';
import type { MutationOptions } from '@tanstack/react-query';

export const useApproveDealReviewMutation = (
	dealId: TDeal['id'],
	communityId: TCommunity['id'],
	options?: MutationOptions<void, Error>,
) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess } = useNotification();

	return useMutation<void, Error>(['deal.approveDealReview'], () => api.invest.applyDealPreview(dealId), {
		...options,
		onSuccess: async (data, variables, context) => {
			await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
			await reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealFiles(dealId as TDeal['id'], null));
			reactQuery.refetchDealsQueries(communityId);
			showSuccess({ title: t('Changes Successfully Approved') });
			options?.onSuccess?.(data, variables, context);
		},
		onError: (error) => {
			showUnknownError(error);
		},
	});
};
