import { useService } from '@hooks';
import { NetworkConnectionUserFilterEntity } from '@utils';
import type { TCategory } from '@ui-modules/connections/components/ConnectionForm/ConnectionForm.schema';
import type { TDynamicFilter } from '@typings';

export const useConnectionFiltersChanged = (networkConnectionName: string) => {
	const analytics = useService('AnalyticsService');

	const trackFiltersChange = (initialFilters: TInitialFilters, latestFilters: { categories: string[] }) => {
		const { filter_values_off, filter_values_on } =
			NetworkConnectionUserFilterEntity.processNetworkConnectionFilterChanges(initialFilters, latestFilters);

		analytics.trackGenericEvent(
			'{{connectionName}}FilterChanged',
			{ filter_values_off, filter_values_on },
			{
				connectionName: networkConnectionName,
			},
		);
	};

	return { trackFiltersChange };
};

export type TInitialFilters = {
	categories: TCategory[];
	dynamicFilters: TDynamicFilter[];
};
