import { OktaVerifyState } from './OktaVerifyState';
import { useRef, useSyncExternalStore } from '@hooks';

export const useOktaVerifyState = () => {
	const oktaVerifyState = useRef(new OktaVerifyState()).current;
	const state = useSyncExternalStore(oktaVerifyState.subscribe, oktaVerifyState.getState);

	const currentExpirationDate =
		state.currentFactorType && state.currentFactorType in state.authenticators
			? state.authenticators[state.currentFactorType].expirationDate
			: undefined;

	return [
		{ ...state, currentExpirationDate },
		oktaVerifyState.actions,
		oktaVerifyState.selectors,
		OktaVerifyState.helpers,
	] as const;
};
