import { useService } from '@services';

export const useChannelFlaggedMessages = () => {
	const { useAppSelector, chatChannelFlaggedMessages, useAppDispatch } = useService('ReduxService');

	const dispatch = useAppDispatch();

	const { flagMessageIds } = useAppSelector((store) => store.chatChannelFlaggedMessages);

	const setFlaggedMessageIds = (messageIds: Record<string, boolean> | null | undefined) => {
		dispatch(chatChannelFlaggedMessages.setFlaggedMessageIds(messageIds));
	};

	return {
		flagMessageIds: flagMessageIds,
		setFlaggedMessageIds,
	};
};
