import { useIsFetching } from '@tanstack/react-query';

const OBSERVING_QUERY_KEYS = [
	'getDealTypesWithIdsQuery',
	'dealType.getTypes',
	'dealSubClass.getSubClasses',
	'getSubClassesWithIdsQuery',
	'invest.getAllDealFilters',
	'invest.getAllDealFilterConfig',
];

export const useIsDealFiltersFetching = () => {
	const isFetching = useIsFetching([], {
		exact: false,
		stale: true,
		type: 'all',
		predicate: (query) => {
			return OBSERVING_QUERY_KEYS.includes(String(query.queryKey[0]));
		},
	});

	return Boolean(isFetching);
};
