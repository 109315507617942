import type { MutationOptions } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useService, useMutation, useNotification } from '@hooks';
import type { TFile } from '@typings';

export const useDeleteFileForCalendarMutation = (
	fileId: string,
	eventId: string,
	options?: MutationOptions<void, Error, TFile['id']>,
) => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFile['id']>(
		['calendarItemFile.deleteMeetingFile'],
		async () => await api.calendarItemFile.deleteMeetingFile(fileId),
		{
			...options,
			async onSuccess(data, variables, context) {
				await queryClient.refetchQueries(['calendarItemFile.getMeetingFiles', eventId, undefined]);
				await options?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
