import type { TUser } from '@typings';
import type { TFunction } from 'i18next';
import type { TRadioGroupInputOption } from '@ui-kit';

export const getPostingOwnershipOptions = (t: TFunction): Array<TRadioGroupInputOption<TPostingOwnership>> => [
	{
		label: t('Post as an owner'),
		value: 'as_an_owner',
	},
	{
		label: t('Post on behalf of other member'),
		value: 'on_behalf_of',
	},
];

export const getInitialCreatorFormFieldValue = (
	postingOwnershipType: TPostingOwnership,
	user: Pick<TUser, '@id' | 'firstName' | 'lastName'>,
) => {
	if (postingOwnershipType === 'as_an_owner') {
		return { iriId: user['@id'], name: `${user.firstName} ${user.lastName}` };
	}
	if (postingOwnershipType === 'on_behalf_of') {
		return undefined;
	}
};

export const getInitialPostingOwnership = (user: TUser): TPostingOwnership => 'as_an_owner';

export type TPostingOwnership = 'as_an_owner' | 'on_behalf_of';
