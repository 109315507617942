import Button from '../../Button';
import type { IButtonProps } from '../../Button';

const PostButton = (buttonProps: IPostButtonProps) => (
	<Button block="fit" type="outline" variant="medium" {...buttonProps} />
);

export interface IPostButtonProps extends Pick<IButtonProps, 'onClick' | 'title'> {}

export default PostButton;
