import type { PropsWithChildren } from 'react';

/** Renders children when the passed condition is true. */
const When = ({ condition, children }: IWhenProps) => {
	return <>{condition ? children : null}</>;
};

export interface IWhenProps extends PropsWithChildren {
	/** Should the children be rendered. */
	condition: boolean | null | undefined;
}

export default When;
