import Categories from '../FilterFormComponents/Categories';
import MultiSelect from '../FilterFormComponents/MultiSelect';
import DynamicMoney from '../FilterFormComponents/DynamicMoney';
import SingleSelect from '../FilterFormComponents/SingleSelect';
import DateRangePicker from '../FilterFormComponents/DateRangePicker';

import type { FormikErrors, FormikProps } from 'formik';
import type { TDynamicFilter, TMetaChoice, TNetworkConnectionTemplate, TNetworkConnectionUserFilters } from '@typings';
import { useEffect, useState } from '@hooks';
import { NetworkConnectionEntity, NetworkConnectionUserFilterEntity } from '@utils';
import styles from './NetworkConnectionsFilterForm.module.css';
import type { IDynamicMoneyProps } from '../FilterFormComponents/DynamicMoney';
import type { IRangePickerProps } from '../FilterFormComponents/DateRangePicker';
import type { INCFilter } from '@tiger21-llc/connect-shared/src/utils/NetworkConnectionUserFilterEntity';

const getCorrectOperator = (op: string, filterOperators: TFilterOperators) => {
	const opMapping: Record<string, string[]> = {
		to: ['lte', 'lt'],
		from: ['gte', 'gt'],
		eq: ['eq', 'like'],
	};

	const potentialOps: string[] = opMapping[op] || [];

	const correctOp = potentialOps.find((potentialOp) => filterOperators?.includes(potentialOp));
	return correctOp || filterOperators[0];
};

export function updateCategoryValues(categories: INCFilterWithChildren[], initialValues: Record<string, string[]>) {
	const updateValue = (item: INCFilter) => {
		if (initialValues?.in?.includes(item.id)) {
			item.value = true;
		}
		if (item.children && item.children.length > 0) {
			if (item.children?.every((item: { value: boolean }) => item.value)) {
				item.value = true;
			}
			item.children = item.children.map(updateValue);
		}
		return item;
	};

	return categories.map(updateValue);
}

const NetworkConnectionsFilterForm = ({ formProps, filters, currency }: INetworkConnectionsFilterFormProps) => {
	const [categoriesState, setCategoriesState] = useState(
		() => NetworkConnectionEntity.sortCategoriesByLabel(filters.categories) as any, // TODO Hrant should type 'filters' typings
	);

	const [appliedFilters, setAppliedFilters] = useState(formProps.values || {});

	useEffect(() => {
		const updatedCategories =
			NetworkConnectionUserFilterEntity.transformSelectedCategoriesToSelectedIds(categoriesState);

		setAppliedFilters({
			...appliedFilters,
			categories: updatedCategories,
		});
	}, [categoriesState, filters.categories]);

	useEffect(() => {
		formProps.setValues(appliedFilters, true);
	}, [appliedFilters]);

	const updateFilters: TUpdateFiltersFunction = (
		fieldName: string,
		fieldValue: TFieldValue,
		dataType: TDataType,
		filterOperators?: TFilterOperators,
	) => {
		setAppliedFilters((prevFilters) => {
			let operator;
			let value = fieldValue;

			switch (dataType) {
				case 'numeric':
				case 'date':
				case 'money':
					operator = getCorrectOperator(fieldValue?.op, filterOperators as TFilterOperators);
					value = fieldValue?.value === 0 ? '' : fieldValue?.value;
					break;
				case 'boolean':
					operator = 'eq';
					break;
				case 'single-select':
					operator = 'eq';
					break;
				case 'multi-select':
					operator = 'in';
					break;
				default:
					return prevFilters;
			}

			const dynamicFieldName = fieldName as keyof typeof prevFilters.dynamicFields;
			const prevDynamicFields = prevFilters?.dynamicFields?.[dynamicFieldName];

			return {
				...prevFilters,
				dynamicFields: {
					...prevFilters.dynamicFields,
					[fieldName]: {
						...(prevDynamicFields as TDynamicFilter),
						[operator]: value,
					},
				},
			};
		});
	};

	return (
		<div className={styles.networkConnectionsFilterForm}>
			{!!categoriesState?.length && <Categories categories={categoriesState} onChange={setCategoriesState} />}
			{filters.dynamicFilters?.map((item: TDynamicFilter) => {
				// @TODO fixme
				let options: any;
				switch (item.type) {
					case 'multi-select':
						return (
							<div key={item.id}>
								<MultiSelect fieldData={item} onFilterUpdate={updateFilters} />
							</div>
						);
					case 'single-select':
					case 'boolean':
						if (item.type === 'boolean') {
							options = [
								{
									choice: 'true',
								},
								{
									choice: 'false',
								},
							];
						} else {
							options = item.meta.choices as TMetaChoice[];
						}
						return (
							<div key={item.id}>
								<SingleSelect
									fieldData={{
										...item,
										meta: {
											choices: options,
										},
									}}
									onFilterUpdate={updateFilters}
								/>
							</div>
						);
					case 'money':
					case 'numeric':
						return (
							<div key={item.id}>
								<DynamicMoney
									currency={currency}
									errors={
										(formProps.errors as IExtendedErrors).dynamicFields?.[item.name] as IDynamicMoneyProps['errors']
									}
									fieldData={item}
									onFilterUpdate={updateFilters}
								/>
							</div>
						);

					case 'date':
						return (
							<div key={item.id}>
								<DateRangePicker
									errors={
										(formProps.errors as IExtendedErrors).dynamicFields?.[item.name] as IRangePickerProps['errors']
									}
									fieldData={item}
									onFilterUpdate={updateFilters}
								/>
							</div>
						);
				}
			})}
		</div>
	);
};

export interface INCFilterWithChildren {
	id: string;
	value: boolean;
	children: INCFilter[];
}

export interface IExtendedErrors extends FormikErrors<TDynamicFilter> {
	dynamicFields?: {
		[key: string]: string | string[] | undefined;
	};
}
export interface INetworkConnectionsFilterFormProps {
	formProps: FormikProps<TNetworkConnectionUserFilters>;
	// @TODO Implement when back end integrates validation
	filters: any;
	currency: TNetworkConnectionTemplate['currency'] | undefined;
}

export type TDataType = 'numeric' | 'date' | 'money' | 'boolean' | 'single-select' | 'multi-select';

export type TFieldValue = { op: string; value: any };

export type TFilterOperators = string[];

export type TUpdateFiltersFunction = (
	fieldName: string,
	fieldValue: TFieldValue,
	dataType: TDataType,
	filterOperators?: TFilterOperators,
) => void;
export default NetworkConnectionsFilterForm;
