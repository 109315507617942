import { Modal } from '@ui-kit';
import { useTranslation } from '@hooks';
import { useDeleteActivityMutation } from '@ui-modules/feed/hooks/useDeleteActivityMutation';
import { noop } from '@utils';
import type { IModalProps } from '@ui-kit';
import type { TActivity } from '@typings';

/** Confirms deleting of an activity and performs it. */
const DeleteActivityModal = ({ visible, activity, onClose, onSuccess = noop }: IDeleteActivityModalProps) => {
	const { t } = useTranslation();

	const { mutate: deleteActivity, isLoading } = useDeleteActivityMutation({
		onSuccess: () => {
			onClose();
			onSuccess?.();
		},
	});

	return (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('Deleting this post will permanently remove it from this Network.')}
			title={t('Delete Post?')}
			variant="medium"
			visible={visible}
			onCancel={() => onClose()}
			onConfirm={() => {
				deleteActivity(activity);
			}}
		/>
	);
};

export interface IDeleteActivityModalProps extends Pick<IModalProps, 'visible'> {
	/** Target Activity to delete. */
	activity: TActivity;
	/** Calls when the modal should be closed to allow to toggle 'visible' property value. */
	onClose: () => void;
	/** Calls when mutation is completed. */
	onSuccess?: () => void;
}

export default DeleteActivityModal;
