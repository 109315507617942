import { pick } from '@utils';

export function collectDealContentWhichMayHasUrls<T extends Record<string, unknown>>(
	record: T,
	fields: Array<keyof T> = ['name', 'description', 'priorExperience', 'contactName', 'videoUrl', 'webUrls'],
): string {
	try {
		const pickedFields = pick(record, fields);
		const pickedFieldValues = Object.values(pickedFields);
		const flattedFieldValues = pickedFieldValues.flat(); // if field is array of strings like set of urls
		const joinedFieldValues = flattedFieldValues.join('   ');

		return joinedFieldValues;
	} catch (error) {
		console.error(error);
		return ' ';
	}
}
