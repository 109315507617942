import { AddFolderIcon, Button, UploadFileIcon, DeleteIcon } from '@ui-kit';
import AddFolderModal from './modals/AddFolderModal';
import DeleteFolderModal from './modals/DeleteFolderModal';
import { useToggle, useTranslation } from '@hooks';
import styles from './FilesWidget.module.css';
import type { TFolder } from '@typings';
import type { IFilesWidgetApiCallbacks } from './FilesWidgetApiCallbacks.interface';

const FilesWidgetControls = ({
	folder,
	hideUploadButton,
	canUploadFiles,
	canCreateFolders,
	canDeleteFolders,
	onCreateFolder,
	onDeleteFolder,
	onPickAndUploadFile,
	isFileUploading,
}: IFilesWidgetControlProps) => {
	const { t } = useTranslation();

	// Page states.
	const folderId = folder?.id ?? null;
	const [isAddFolderDialogVisible, toggleAddFolderDialog] = useToggle(false);
	const [isDeleteFolderDialogVisible, toggleDeleteFolderDialog] = useToggle(false);

	return (
		<>
			<div className={styles.filesWidget__controlsBar}>
				{canDeleteFolders ? (
					<Button
						icon={<DeleteIcon fill="#000000" height={14} width={12} />}
						iconPosition="right"
						title={t('Delete')}
						variant="small"
						onClick={() => toggleDeleteFolderDialog()}
					/>
				) : null}
				{canUploadFiles && !hideUploadButton ? (
					<Button
						className={styles.filesWidget__controlsBarUploadButton}
						icon={<UploadFileIcon fill="#000000" height={12} width={14} />}
						iconPosition="right"
						loading={isFileUploading}
						title={t('Upload')}
						variant="small"
						onClick={onPickAndUploadFile}
					/>
				) : null}
				{canCreateFolders ? (
					<Button
						icon={<AddFolderIcon fill="#000000" height={16} width={16} />}
						iconPosition="right"
						title={t('New Folder')}
						variant="small"
						onClick={() => toggleAddFolderDialog()}
					/>
				) : null}
			</div>
			<AddFolderModal
				folderId={folderId}
				visible={isAddFolderDialogVisible}
				onClose={() => toggleAddFolderDialog()}
				onCreateFolder={onCreateFolder}
			/>
			{folderId && folder && isDeleteFolderDialogVisible ? (
				<DeleteFolderModal
					folderId={folderId}
					folderName={folder.name}
					visible={isDeleteFolderDialogVisible}
					onClose={toggleDeleteFolderDialog}
					onDeleteFolder={onDeleteFolder}
				/>
			) : null}
		</>
	);
};

export interface IFilesWidgetControlProps
	extends Pick<IFilesWidgetApiCallbacks, 'onCreateFolder' | 'onDeleteFolder' | 'onPickAndUploadFile'> {
	folder: TFolder | undefined;
	canUploadFiles: boolean;
	canCreateFolders: boolean;
	canDeleteFolders: boolean;
	isFileUploading: boolean;
	hideUploadButton?: boolean;
}

export default FilesWidgetControls;
