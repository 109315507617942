import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TProfile, TProfileEvent } from '@typings';

export const useProfileEventsQuery = <T = TUseProfileEventsQueryData>(
	profileId: TProfile['id'],
	queryOptions?: UseQueryOptions<TUseProfileEventsQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseProfileEventsQueryData, Error, T>(
		['profile.getProfileEvents', profileId],
		async () => await api.profile.getProfileEvents(profileId),
		{
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};

type TUseProfileEventsQueryData = TProfileEvent[];
