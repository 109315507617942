import { useService, useQuery } from '@hooks';
import type { TCommunity, TNetworkConnectionMemberRelationStatus } from '@typings';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useNetworkConnectionMemberRelationsQuery = <T = TNetworkConnectionMemberRelationStatus[]>(
	communityId: TCommunity['id'],
	options?: UseQueryOptions<TNetworkConnectionMemberRelationStatus[], Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TNetworkConnectionMemberRelationStatus[], Error, T>(
		['getNetworkConnectionRelations', communityId],
		async () => await api.networkConnection.getNetworkConnectionRelations(communityId),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
			...options,
		},
	);
};
