import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TReportNetworkConnectionDto } from '@typings';

export const useReportNetworkConnectionMutation = (
	options?: MutationOptions<TReportNetworkConnectionDto, Error, TReportNetworkConnectionDto>,
) => {
	const api = useService('ApiService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');

	return useMutation<TReportNetworkConnectionDto, Error, TReportNetworkConnectionDto>(
		reactQuery.queryKeys.reportNetworkConnection(),
		async (reportData) => await api.networkConnection.postReportNetworkConnection(reportData),
		{
			...options,
			async onSuccess(data, reportDto, context) {
				showSuccess({
					title: t('Your report was sent'),
					subtitle: t(
						'TIGER 21 staff will review your report and contact you with followup information typically within 72 hours.',
					),
				});
				options?.onSuccess?.(data, reportDto, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
