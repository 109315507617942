import FileTypeAvatar from '../FileTypeAvatar';
import styles from './EmptyFileCard.module.css';

const EmptyFileCard = ({ type, name }: IEmptyFileCard) => {
	return (
		<div className={styles.fileCard}>
			<FileTypeAvatar mimeType={type} />
			<h4 className={styles.fileCard__name}>{name}</h4>
		</div>
	);
};

interface IEmptyFileCard {
	type: string;
	name: string;
}

export default EmptyFileCard;
