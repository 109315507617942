import { ROUTES } from '@constants';
import { useMe, useNavigate, useNotification, useTranslation } from '@hooks';
import type { TUser, TProfileWithUser, TMemberAdvisor } from '@typings';
import { Gap } from '@ui-kit';
import { useChatEvents } from '@ui-modules/chat/hooks/useChatEvents';
import StaffCard from '@ui-modules/account/components/StaffCard';
import styles from './MemberAdvisorSection.module.css';

const MemberAdvisorSection = ({ profile, hidden }: IMemberAdvisorSectionProps) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const navigate = useNavigate();
	const { isUserPaused } = useChatEvents();
	const { profile: myProfile } = useMe();

	const onOpenChat = (slug: TUser['slug']) => {
		if (isUserPaused) {
			showInfo({ title: t('Chat has been temporarily disabled') });
		} else {
			navigate(ROUTES.chatChannel(slug));
		}
	};

	const onViewProfile = (profileId: TProfileWithUser['id']) => navigate(ROUTES.memberProfile(profileId));

	if (!profile || hidden) return null;
	if (profile.profileId === myProfile.id) return null; // HIDE section for MEA users.

	return (
		<div className={styles.coloredContainer}>
			<div className={styles.layout}>
				<p className={styles.title}>{t('My Member Experience Advisor')}</p>
				{profile.description ? <p className={styles.simpleText}>{profile.description}</p> : null}
				<p className={styles.simpleText}>{t('Normal Response Time: Up to 1 business day')}</p>
				<Gap gap={16} />
				<StaffCard
					key={profile.id}
					profile={{ ...profile, id: profile.profileId }}
					subtitle={t('MEA')}
					onOpenChat={onOpenChat}
					onViewProfile={onViewProfile}
				/>
			</div>
		</div>
	);
};

interface IMemberAdvisorSectionProps {
	profile?: TMemberAdvisor;
	hidden?: boolean;
}

export default MemberAdvisorSection;
