import { useEffect } from '@hooks';
import { useUpdateCalendarItemCacheMutation } from '@ui-modules/groups/hooks/useUpdateCalendarItemCacheMutation';
import type { INotificationFeedActivity } from '@typings';
import type { NewActivitiesNotificationProps } from 'react-activity-feed';

const NotificationFeedNotifier = ({ adds }: NewActivitiesNotificationProps) => {
	const { mutate: updateCalendarItemCache } = useUpdateCalendarItemCacheMutation();

	useEffect(() => {
		adds?.forEach((notification) => {
			const typedNotification = notification as unknown as INotificationFeedActivity;
			reactToReceivedNotification(typedNotification);
		});
	}, [adds]);

	async function reactToReceivedNotification(notification: INotificationFeedActivity) {
		switch (notification.verb) {
			case 'meeting_request_approved':
			case 'meeting_request_declined': {
				const meetingId = notification.data.tapAction.destination;
				updateCalendarItemCache(meetingId);
				break;
			}
		}
	}

	return null;
};

export default NotificationFeedNotifier;
