import { useMemo } from 'react';
import { flattenDeep } from 'lodash';

import { arrangeNetworksForFilter, checkIdInArray } from '@utils';

import type { TCommunity, TDealAssetClass, TDealFilter } from '@typings';

import useAllDealFilterConfig from './useAllDealFilterConfigQuery';

import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { useGetDealAssetClassesWithCommunityIdsQuery } from '@ui-modules/deals/hooks/useGetDealAssetClassesWithCommunityIdsQuery';
import { useGetDealSubClassesQuery } from '@ui-modules/deals/hooks/useGetDealSubClassesQuery';
import { useGetDealTypesQuery } from '@ui-modules/deals/hooks/useGetDealTypesQuery';
import { getUniqueDealTypes } from '@ui-modules/deals/utils/getUniqueDealTypes';

export const useSelectedNetworkSubClasses = (checkMarks: ICheckmarkState[]) => {
	return useMemo(
		() =>
			flattenDeep(checkMarks?.map((item) => item.value && `/api/deal_sub_classes/${item.id}`)).filter((item) => !!item),
		[checkMarks],
	);
};

export const useSelectedNetworkDealTypes = (types: ICheckmarkState[]) => {
	return useMemo(
		() => flattenDeep(types?.map((item) => item.value && `/api/deal_types/${item.id}`)).filter((item) => !!item),
		[types],
	);
};

export const useParseNetworkDealFilters = ({
	initialDealSubClasses,
	initialDealTypes,
	communityId,
}: IUseParseDealFilters) => {
	const { data: allDealFilterConfig, isLoading: isAllDealFilterConfigLoading } = useAllDealFilterConfig();

	const initialCheckMarks = useMemo(() => {
		return checkIdInArray(
			(initialDealSubClasses as string[]) || [],
			arrangeNetworksForFilter(
				allDealFilterConfig?.assetClasses?.find(
					(item) => item.community.replace('/api/communities/', '') === communityId,
				)?.dealSubClasses as unknown as TDealAssetClass[],
				!initialDealSubClasses,
				false,
			),
			'/api/deal_sub_classes/',
		);
	}, [allDealFilterConfig?.assetClasses, initialDealSubClasses, communityId]);

	const {
		data: dealAssetClasses,
		isLoading: isDealAssetClassLoading,
		isFetched: isDealAssetClassFetched,
	} = useGetDealAssetClassesWithCommunityIdsQuery([communityId] as string[]);

	const {
		data: dealSubClasses,
		isLoading: isDealSubClassLoading,
		isFetched: isDealSubClassFetched,
	} = useGetDealSubClassesQuery(dealAssetClasses?.[0]?.id as string);

	const {
		data: queryDealTypes,
		isLoading: isDealTypeClassLoading,
		isFetched: isDealTypeClassFetched,
	} = useGetDealTypesQuery(dealSubClasses?.map((item) => item.id));

	const dealTypes = useMemo(() => {
		return checkIdInArray(
			initialDealTypes as string[],
			arrangeNetworksForFilter(getUniqueDealTypes(queryDealTypes as TDealAssetClass[]), false, false),
			'/api/deal_types/',
		);
	}, [queryDealTypes, initialDealTypes]);

	return {
		initialCheckMarks,
		dealTypes,
		isLoading: isAllDealFilterConfigLoading,
		isDealTypeLoading: isDealAssetClassLoading || isDealSubClassLoading || isDealTypeClassLoading,
		readyToRender: isDealAssetClassFetched && isDealSubClassFetched && isDealTypeClassFetched,
	};
};

export interface IUseParseDealFilters {
	initialDealTypes: TDealFilter['dealTypes'];
	initialDealSubClasses: TDealFilter['dealSubClasses'];
	communityId: TCommunity['id'];
}
