import { useQuery, useService } from '@hooks';

export const useNotificationsSettings = () => {
	const api = useService('ApiService');

	const { data: defaultsSettings, isLoading: isLoadingOfDefaultsSettings } = useQuery(
		['notifications.defaultSettings'],
		async () => await api.notificationSettings.getDefaultsNotificationsSettings(),
		{
			staleTime: Infinity,
		},
	);

	const { data: notificationsSettings, isLoading: isLoadingNotificationsSettings } = useQuery(
		['notifications.getSettings'],
		async () => await api.notificationSettings.getNotificationsSettings(),
		{
			staleTime: Infinity,
		},
	);

	return {
		defaultsSettings,
		isLoadingOfDefaultsSettings,
		notificationsSettings,
		isLoadingNotificationsSettings,
	};
};
