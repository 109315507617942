import { Outlet, Page, PageLayout, TabWrapper } from '../../components';
import { SearchableScreenHeader, Backdrop, Tabs, When } from '@ui-kit';
import {
	useTranslation,
	useState,
	useEvent,
	useNavigate,
	useLocation,
	useService,
	useRestoreVirtuosoInitialIndex,
} from '@hooks';
import { INVEST_NETWORKS_TAB_SLUG, ROUTES } from '@constants';
import { clsx, CommunityEntity } from '@utils';
import styles from './NetworksPage.module.css';
import type { TCommunityCompact, TCommunity } from '@typings';
import { useNetworksTabsState } from '@ui-modules/networks/hooks/useNetworksTabsState';
import NetworkSectionList from '@ui-modules/networks/components/NetworkSectionList/NetworkSectionList';
import { useNetworkQueriesBreakdown } from '@ui-modules/networks/hooks/useNetworkQueriesBreakdown';

const NetworksPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const { communityTabs, currentTabIndex, onTabChanged, isTabRoute, networkId } = useNetworksTabsState(!!searchQuery);
	const { queryClient, queryKeys } = useService('ReactQueryService');

	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } = useRestoreVirtuosoInitialIndex(
		'NetworksPage__NetworkSectionList',
	);

	const handleSelectNetwork = useEvent((network: TCommunity | TCommunityCompact, networkTab: string, index: number) => {
		// re-fetch all the queries that have been created for latest deal filter details [@hrant]
		if (networkTab === INVEST_NETWORKS_TAB_SLUG && network.id) {
			queryClient.invalidateQueries(queryKeys.getDealFilterViaCommunityId(network.id));
			queryClient.refetchQueries(queryKeys.getAllDealFilters());
		}
		navigate(ROUTES.networkTab(network.id));
		if (!isSearchActive) setInitialTopMostItemIndex(index);
	});
	const handleFirstNetworkLoaded = useEvent((firstLoadedNetwork: TCommunityCompact | undefined) => {
		if (firstLoadedNetwork && (isTabRoute || location.pathname === ROUTES.networks())) {
			setTimeout(() => navigate(ROUTES.networkTab(firstLoadedNetwork.id), { replace: true }), 10); // To resolve "You should call navigate() in a React.useEffect(), not when your component is first rendered." [@dmitriy.nikolenko]
		}
	});

	const { isAnyNetworkLoaded } = useNetworkQueriesBreakdown('invest', '');

	return (
		<Page title={t('Networks')}>
			<PageLayout
				containerMode={false}
				sidebarContent={
					<>
						<SearchableScreenHeader
							placeholder={t('Search networks')}
							searchQuery={searchQuery}
							searchTitle={t('Search')}
							title={t('Networks')}
							onBlur={() => setIsSearchActive(false)}
							onChangeSearchQuery={setSearchQuery}
							onFocus={() => setIsSearchActive(true)}
						/>
						<Tabs selectedIndex={currentTabIndex} onSelect={onTabChanged}>
							<When condition={isAnyNetworkLoaded}>
								<Tabs.List backgroundColor="#f9f9f9" className={styles.tabsList}>
									{communityTabs.map((communityTab, index) => {
										const isInvestNetwork = communityTab.slug === INVEST_NETWORKS_TAB_SLUG;
										return (
											<Tabs.Tab
												disabled={currentTabIndex === index}
												key={communityTab.slug}
												selectedClassName={clsx(isInvestNetwork && styles.tab)}
											>
												{communityTab.label}
											</Tabs.Tab>
										);
									})}
								</Tabs.List>
							</When>
							{communityTabs.map((communityTab) => {
								const isInvestNetwork = communityTab.slug === INVEST_NETWORKS_TAB_SLUG;
								const showTabPanel = isInvestNetwork ? isAnyNetworkLoaded : true;
								return (
									<When condition={showTabPanel} key={communityTab.slug}>
										<Tabs.Panel>
											<TabWrapper>
												<NetworkSectionList
													initialTopMostItemIndex={initialTopMostItemIndex}
													networkTab={communityTab.slug}
													rangeChanged={onRangeChanged}
													searchQuery={searchQuery}
													selectedNetworkId={networkId}
													onFirstNetworkLoaded={handleFirstNetworkLoaded}
													onSelectNetwork={(network, index) => handleSelectNetwork(network, communityTab.slug, index)}
												/>
											</TabWrapper>
										</Tabs.Panel>
									</When>
								);
							})}
						</Tabs>
					</>
				}
			>
				<Outlet />
				<Backdrop hidden={!isSearchActive} />
			</PageLayout>
		</Page>
	);
};

export default NetworksPage;
