import { useService } from '@services';
import { useTranslation } from 'react-i18next';
import { useMemo, usePaginatedInfiniteQuery } from '@hooks';
import { compact, populateNetworkConnectionsPaginatedListWithReactions } from '@utils';
import { useNetworkConnectionSortSearchParams } from '@ui-modules/connections/hooks/useNetworkConnectionSortSearchParams';

import type { AxiosError } from 'axios';
import type { TCommunity, TNetworkConnectionWithReactions, TPaginatedList } from '@typings';
import { useNetworkConnectionUserFiltersQuery } from './useNetworkConnectionUserFiltersQuery';

export const useGetNetworkConnectionListQuery = (communityId: TCommunity['id']) => {
	const { t } = useTranslation();

	const api = useService('ApiService');
	const feed = useService('FeedService');
	const reactQuery = useService('ReactQueryService');

	const { data: userFilters } = useNetworkConnectionUserFiltersQuery({
		communityId: communityId,
	});

	const [selectedSortOption, setSelectedSortOption] = useNetworkConnectionSortSearchParams({
		field: 'createdAt',
		order: 'desc',
	});

	const sortOptions = [
		{ title: t('Post Date'), value: 'createdAt' },
		{ title: t('Activity'), value: 'updatedAt' },
	];
	const query = usePaginatedInfiniteQuery<
		TNetworkConnectionWithReactions,
		TPaginatedList<TNetworkConnectionWithReactions>,
		AxiosError
	>(
		reactQuery.queryKeys.getNetworkConnections(communityId, userFilters?.filters, selectedSortOption),
		async ({ pageParam = 1 }) => {
			const networkConnections = await api.networkConnection.getNetworkConnections({
				page: pageParam,
				community: communityId,
				sort: selectedSortOption,
				userFilters: userFilters?.filters,
			});
			const networkConnectionsActivityIds = compact(networkConnections.data.map((nc) => nc.streamActivityId));
			const networkConnectionsActivities = await feed.getActivities(networkConnectionsActivityIds);
			return populateNetworkConnectionsPaginatedListWithReactions(networkConnections, networkConnectionsActivities);
		},
	);

	const hasDataByFilter = useMemo(
		() =>
			reactQuery.hasAnyDataInInfiniteQueryCache(
				reactQuery.queryKeys.getNetworkConnections(communityId, userFilters?.filters),
			),
		[communityId, userFilters?.filters, query.isFetching],
	);

	return {
		...query,
		sortOptions,
		setSelectedSortOption,
		selectedSortOption,
		hasDataByFilter,
	};
};
