import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TActivity } from '@typings';

export const useActivitiesQuery = <T = TActivity[]>(
	articlesIds: string[],
	queryOptions?: UseQueryOptions<TActivity[], Error, T>,
) => {
	const feedService = useService('FeedService');

	return useQuery<TActivity[], Error, T>(['articles', articlesIds], () => feedService.getActivities(articlesIds), {
		staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		...queryOptions,
	});
};
