import {
	CollapsibleTextSection,
	MemberBlock,
	DynamicLinksSection,
	ContactBlock,
	DynamicFieldDataGrid,
	DynamicLongTextSection,
	When,
} from '@ui-kit';
import FilesSection from '@ui-modules/files/components/FilesSection';
import UniversalVideo from '@ui-modules/media/components/UniversalVideo';
import { useMe, useMemo, useService, useTranslation } from '@hooks';
import { useGetNetworkConnectionFilesQuery } from '@ui-modules/connections/hooks/useGetNetworkConnectionFilesQuery';
import { useNetworkConnectionMemberRelationQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationQuery';
import { compact, NetworkConnectionEntity } from '@utils';
import styles from './NetworkConnectionDetails.module.css';
import type { TNetworkConnection, TProfile } from '@typings';
import type { IDealMemberBlockProps } from '@ui-modules/deals/components/DealMemberBlock';

const NetworkConnectionDetails = ({
	networkConnection,
	networkConnectionName,
	onGoToProfile,
}: INetworkConnectionDetailsProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const analytics = useService('AnalyticsService');

	const { data: files = [] } = useGetNetworkConnectionFilesQuery(networkConnection.id);
	const { data: memberRelation } = useNetworkConnectionMemberRelationQuery(networkConnection.id);
	const memberBlockDescription = useMemo(() => {
		const expertise = networkConnection?.owner?.relevantExpertise;
		const ownerRelationStatus =
			networkConnection.owner?.id === user.id
				? memberRelation?.networkConnectionStatus?.title ?? undefined
				: networkConnection.ownerRelationStatus?.title;
		return compact([expertise ? t('Expertise in {{expertise}}', { expertise }) : null, ownerRelationStatus]);
	}, [
		networkConnection,
		memberRelation /* in order to refresh ownerRelationStatus if a user is owner and a status is changed */,
	]);

	const dynamicFields = useMemo(
		() =>
			NetworkConnectionEntity.extractDynamicFieldsDataV2(networkConnection, {
				currency: t('Currency'),
			}),
		[networkConnection],
	);

	const [categories, subcategories] = useMemo(() => {
		const { categories, subcategories } = NetworkConnectionEntity.extractCategoriesAndSubcategories(
			networkConnection.categories,
		);
		return [categories.join(', '), subcategories.join(', ')];
	}, [networkConnection.categories]);

	const networkConnectionInfoLinks = useMemo(
		() => NetworkConnectionEntity.extractInfoLinks(networkConnection),
		[networkConnection],
	);

	const longTextFields = useMemo(
		() => NetworkConnectionEntity.extractLongTextFields(networkConnection),
		[networkConnection],
	);

	const openOwnerProfile = () => {
		if (networkConnection.owner.roles.includes('ROLE_INACTIVE')) return;
		onGoToProfile(networkConnection.owner.profileId);
	};
	const trackNetworkConnectionDetailsInteraction = (
		interactionAction: 'info link opened' | 'contact email opened' | 'contact phone opened',
	) => {
		analytics.trackGenericEvent(
			'{{connectionName}}Interactions',
			{
				interaction_type: 'details',
				interaction_action: interactionAction,
				'{{connectionName}}_id': networkConnection.id,
				'{{connectionName}}_name': networkConnection.title,
			},
			{
				connectionName: networkConnectionName,
			},
		);
	};

	return (
		<div className={styles.networkConnectionDetails}>
			<MemberBlock
				avatarUrl={networkConnection.owner.avatarUrl}
				descriptions={memberBlockDescription}
				fullName={networkConnection.owner.fullName}
				isChair={networkConnection.owner.profileType === 'ProfileChair'}
				title={t('Posted by {{name}}', {
					name: networkConnection.owner.fullName,
				})}
				onClick={openOwnerProfile}
			/>
			<div>
				{networkConnection?.description ? (
					<CollapsibleTextSection
						collapsedTitle={t('Read more')}
						expandedTitle={t('Show less')}
						text={networkConnection.description}
						title={t('Description')}
					/>
				) : null}
				<DynamicLongTextSection
					collapsedNumberOfLines={3}
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					textFields={longTextFields}
				/>
			</div>
			{networkConnection.videoUrl ? <UniversalVideo videoUrl={networkConnection.videoUrl} /> : null}

			<When condition={!!categories}>
				<div>
					<span className={styles.networkConnectionDetails__textTitle}>{t('Category')}</span>
					<span>{categories}</span>
				</div>
			</When>

			<When condition={!!subcategories}>
				<div>
					<span className={styles.networkConnectionDetails__textTitle}>{t('Subcat')}</span>
					<span>{subcategories}</span>
				</div>
			</When>
			<DynamicFieldDataGrid
				booleanFieldFalseLabel={t('No')}
				booleanFieldTrueLabel={t('Yes')}
				currency={networkConnection.currency}
				dynamicFields={dynamicFields}
			/>
			<DynamicLinksSection
				links={networkConnectionInfoLinks}
				title={t('Info link')}
				onLinkOpened={() => trackNetworkConnectionDetailsInteraction('info link opened')}
			/>
			<FilesSection
				files={files}
				title={t('{{owner}} files', { owner: networkConnectionName })}
				onFileOpened={() => {
					analytics.trackGenericEvent(
						'{{connectionName}}Interactions',
						{
							'{{connectionName}}_id': networkConnection.id,
							'{{connectionName}}_name': networkConnection.title,
							interaction_type: '{{connectionName}}_information_files',
							interaction_action: 'files viewed',
						},
						{
							connectionName: networkConnectionName,
						},
					);
				}}
			/>
			<ContactBlock
				contactEmail={networkConnection?.contactEmail}
				contactName={networkConnection.contactName}
				contactPhone={networkConnection?.contactPhone}
				title={t('Contact')}
				onEmailOpened={() => trackNetworkConnectionDetailsInteraction('contact email opened')}
				onPhoneOpened={() => trackNetworkConnectionDetailsInteraction('contact phone opened')}
			/>
		</div>
	);
};

interface INetworkConnectionDetailsProps extends Pick<IDealMemberBlockProps, 'onGoToProfile'> {
	networkConnection: TNetworkConnection;
	onGoToProfile: (profileId: TProfile['id']) => void;
	networkConnectionName: string;
}

export default NetworkConnectionDetails;
