import { ROUTES } from '@constants';
import { useMe, useNavigate, useTranslation } from '@hooks';
import type { TDeal } from '@typings';
import { Button, ExternalLinkIcon, PencilBoldIcon, RibbonFlag } from '@ui-kit';
import { usePublishDeal } from '@ui-modules/deals/hooks/usePublishDeal';
import { useJoinDealChatMutation } from '@ui-modules/chat/hooks/useJoinDealChatMutation';
import { dealRibbonColor } from '@ui-modules/deals/utils/dealRibbonColor';
import { clsx, DealEntity } from '@utils';
import DealTabsPageMeatballMenu from '../../../../app/pages/DealTabsPage/DealTabsPage.MeatballMenu';
import styles from './DealSubHeader.module.css';
import DealReviewWidget from './DealReviewWidget';

const DealSubHeader = ({
	deal,
	dealPreview,
	isReviewMode,
	onEditDeal,
	isMessageShown,
	onReviewChanges,
	onReviewAccept,
}: IDealSubHeaderProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	const { publishDeal, isPublishing } = usePublishDeal();
	const isDraftDeal = deal?.status === 'draft' || deal?.status === 'review';

	const { mutate: openDealChat, isLoading: joining } = useJoinDealChatMutation({
		onSuccess: (channel) => navigate(ROUTES.chatChannel(`${channel?.id}`)),
	});

	return (
		<div className={styles.dealTab__subHeader}>
			<h1
				className={clsx(
					styles.dealTab__subHeaderTitle,
					dealPreview && dealPreview.name !== deal.name && styles.text_highlighted,
				)}
			>
				{deal?.status === 'closed' ? (
					<span className={styles.dealTab__subHeader_status}>{`${t('Closed')}:`}&nbsp;</span>
				) : null}
				{deal?.status === 'cancelled' ? (
					<span className={styles.dealTab__subHeader_status}>{`${t('Cancelled')}:`}&nbsp;</span>
				) : null}
				{dealPreview?.name ?? deal?.name}
			</h1>

			{deal.status && isDraftDeal ? (
				<div className={styles.dealPost__ribbonWrapper}>
					<RibbonFlag
						className={styles.dealPost__ribbon}
						color={dealRibbonColor(deal.status)}
						size="small"
						title={deal.status}
					/>
				</div>
			) : null}
			<div className={styles.dealTab__subHeaderButtons}>
				{isReviewMode ? (
					<DealReviewWidget
						deal={deal}
						visible={DealEntity.canManageReviewUpdates(deal, user)}
						onReviewAccept={onReviewAccept}
					/>
				) : (
					<>
						{DealEntity.canReview(deal, user) ? (
							<Button title={t('Review Changes')} variant="small" onClick={onReviewChanges} />
						) : null}
						{DealEntity.canEditRecommended(deal, user) ? (
							<Button
								icon={<PencilBoldIcon fill="#000000" height={14} width={14} />}
								iconPosition="right"
								title={t('Edit')}
								variant="small"
								onClick={onEditDeal}
							/>
						) : null}
						{DealEntity.canPublish(deal, user) ? (
							<Button
								iconPosition="right"
								loading={isPublishing}
								title={t('Publish')}
								variant="small"
								onClick={() => publishDeal(deal?.id)}
							/>
						) : null}
						{DealEntity.canChangeMemberRelationStatus(deal) ? (
							<DealTabsPageMeatballMenu
								dealContactName={deal.contactName}
								dealCreatorId={deal.creator.id}
								dealId={deal?.id}
								dealOwnerName={`${deal.creator.firstName} ${deal.creator.lastName}`}
							/>
						) : null}
						{DealEntity.canSeeChat(deal, user) ? (
							<Button
								disabled={isMessageShown}
								icon={<ExternalLinkIcon fill="#000000" height={14} width={14} />}
								iconPosition="right"
								loading={joining}
								title={t('Investor Chat')}
								variant="small"
								onClick={() => openDealChat(deal)}
							/>
						) : null}
					</>
				)}
			</div>
		</div>
	);
};

interface IDealSubHeaderProps {
	deal: TDeal;
	dealPreview?: TDeal;
	isMessageShown?: boolean;
	isReviewMode?: boolean;
	onEditDeal: () => void;
	onReviewChanges: () => void;
	onReviewAccept: () => void;
}

export default DealSubHeader;
