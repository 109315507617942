import { AccountMenu, Page, PageLayout } from '../../components';
import { useMe, useTranslation } from '@hooks';
import { POLICIES_LINK } from '@constants';
import BaseStaticIFrame from '@ui-modules/account/components/BaseStaticIFrame';

const PoliciesPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();

	return (
		<Page title={t('Policies')}>
			<PageLayout containerMode={false} sidebarContent={<AccountMenu />}>
				<BaseStaticIFrame title={t('Policies')} url={POLICIES_LINK} userId={user.id} />
			</PageLayout>
		</Page>
	);
};

export interface IChatPageParams {
	id: string;
}

export default PoliciesPage;
