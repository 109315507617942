import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TDeal, TFolder } from '@typings';
import type { TEditFolderForm } from '@schemas';
import type { MutationOptions } from '@tanstack/react-query';

/* Perform mutations with deal files folders.
 * Includes get query & create, rename, delete mutations.
 */
export const useCreateDealFolderMutation = (
	dealId: TDeal['id'],
	folderId: TFolder['id'] | null,
	dealName: TDeal['name'],
	options?: MutationOptions<TFolder, Error, TEditFolderForm>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();
	const { user } = useMe();

	return useMutation<TFolder, Error, TEditFolderForm>(
		['dealFolder.postDealFolder'],
		async ({ name }) =>
			await api.dealFolder.postDealFolder({ folderId: folderId ?? undefined, name, dealId, ownerIri: user['@id'] }),
		{
			...options,
			async onSuccess(folder, variables, context) {
				await reactQuery.queryClient.setQueryData(reactQuery.queryKeys.getDealFolder(dealId, folder.id), folder);
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDealFolders(dealId));
				analytics.trackEvent('DealInteractions', {
					deal_id: dealId,
					deal_name: dealName,
					interaction_type: 'investor_files',
					interaction_action: 'folder created',
				});
				options?.onSuccess?.(folder, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
