import React, { useState, useEffect } from 'react';
import { useTranslation } from '@hooks';
import { ClosingDateInput, RadioGroupInput } from '@ui-kit';
import styles from './DateRangePicker.module.css';
import { dayjs } from '@utils';
import type { TDynamicFilter } from '@typings';
import type { TUpdateFiltersFunction } from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DateRangePicker = ({ fieldData, onFilterUpdate, errors }: IRangePickerProps) => {
	const { t } = useTranslation();
	const initialSelectionMode =
		fieldData.filtersApplied?.eq || fieldData.filtersApplied?.like
			? 'eq'
			: fieldData.filtersApplied?.gte ||
			  fieldData.filtersApplied?.gt ||
			  fieldData.filtersApplied?.lte ||
			  fieldData.filtersApplied?.lt
			? 'range'
			: '';

	const [selectionMode, setSelectionMode] = useState<string>(initialSelectionMode);

	const getDefaultFromValue = (): string => {
		const { gte, eq, gt, like } = fieldData?.filtersApplied || {};
		return String(gte ?? eq ?? gt ?? like ?? '');
	};

	const getDefaultToValue = (): string => {
		const { lte, lt } = fieldData?.filtersApplied || {};
		return String(lte ?? lt ?? '');
	};

	const [fromValue, setFromValue] = useState<string>(getDefaultFromValue());
	const [toValue, setToValue] = useState<string>(getDefaultToValue());

	useEffect(() => {
		setSelectionMode(initialSelectionMode);
	}, [fieldData.filtersApplied]);

	const handleChange = (newValue: string, op: TFilterOperators) => {
		if (op === 'from' || op === 'eq') {
			setFromValue(newValue);
		} else {
			setToValue(newValue);
		}
		onFilterUpdate(fieldData.name, { op, value: newValue }, 'date', fieldData.filterOperators);
	};

	const eqExists = fieldData.filterOperators.some((item: string) => item.includes('eq') || item === 'like');
	const fromExists = fieldData.filterOperators.some((item: string) => item.includes('gt'));
	const toExists = fieldData.filterOperators.some((item: string) => item.includes('lt'));

	const options = [];

	if (eqExists) {
		options.push({
			label: t('Year'),
			value: 'eq',
			children: selectionMode === 'eq' && (
				<div className={styles.datePicker__yearWrapper}>
					<ClosingDateInput
						dateFormat={fieldData?.meta.dateFormat}
						key="eq"
						maxDate={fieldData?.meta?.maxDate ? dayjs.unix(Number(fieldData.meta.maxDate)).toDate() : undefined}
						minDate={fieldData?.meta?.minDate ? dayjs.unix(Number(fieldData.meta.minDate)).toDate() : undefined}
						placeholder={t('- select year -')}
						value={fromValue ? new Date(fromValue) : null}
						onChange={(value) => {
							if (value) {
								handleChange(String(dayjs(value).format('YYYY-MM-DD')), 'eq');
							}
						}}
						onClear={() => {
							handleChange('', 'eq');
						}}
					/>
				</div>
			),
		});
	}

	if (fromExists || toExists) {
		const rangeChildren = [];
		if (fromExists) {
			rangeChildren.push(
				<ClosingDateInput
					dateFormat={fieldData?.meta.dateFormat}
					errorMessage={errors?.gte || errors?.gt}
					key="from"
					label={t('From')}
					labelPosition="bottom"
					labelStyles={styles.datePicker__rangeLabel}
					maxDate={fieldData?.meta?.maxDate ? dayjs.unix(Number(fieldData.meta.maxDate)).toDate() : undefined}
					minDate={fieldData?.meta?.minDate ? dayjs.unix(Number(fieldData.meta.minDate)).toDate() : undefined}
					placeholder={t('- select year -')}
					value={fromValue ? new Date(fromValue) : null}
					onChange={(value) => {
						if (value) {
							handleChange(String(dayjs(value).format('YYYY-MM-DD')), 'from');
						}
					}}
					onClear={() => {
						handleChange('', 'from');
					}}
				/>,
			);
		}
		if (toExists) {
			rangeChildren.push(
				<ClosingDateInput
					dateFormat={fieldData?.meta.dateFormat}
					errorMessage={errors?.lte || errors?.lt}
					key="to"
					label={t('To')}
					labelPosition="bottom"
					labelStyles={styles.datePicker__rangeLabel}
					maxDate={fieldData?.meta?.maxDate ? dayjs.unix(Number(fieldData.meta.maxDate)).toDate() : undefined}
					minDate={fieldData?.meta?.minDate ? dayjs.unix(Number(fieldData.meta.minDate)).toDate() : undefined}
					placeholder={t('- select year -')}
					value={toValue ? new Date(toValue) : null}
					onChange={(value) => {
						if (value) {
							handleChange(String(dayjs(value).format('YYYY-MM-DD')), 'to');
						}
					}}
					onClear={() => {
						handleChange('', 'to');
					}}
				/>,
			);
		}
		options.push({
			label: t('Range'),
			value: 'range',
			children: selectionMode === 'range' && <div className={styles.datePicker__rangeWrapper}>{rangeChildren}</div>,
		});
	}

	return (
		<RadioGroupInput
			backgroundColor="white"
			label={fieldData.label}
			options={options}
			value={selectionMode}
			onChange={(mode) => {
				['eq', 'from', 'to'].forEach((item: string) => {
					handleChange('', item as TFilterOperators);
				});
				setSelectionMode(mode === selectionMode ? '' : mode);
			}}
		/>
	);
};

export default DateRangePicker;

export interface IRangePickerProps {
	fieldData: TDynamicFilter;
	onFilterUpdate: TUpdateFiltersFunction;
	errors?: {
		lt?: string;
		gt?: string;
		gte?: string;
		lte?: string;
	};
}

type TFilterOperators = 'eq' | 'from' | 'to';
