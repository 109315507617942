import { useTranslation } from '@hooks';
import type { TEditContactCard } from '@schemas';
import { When } from '@ui-kit';
import { clsx } from '@utils';
import type { FormikProps } from 'formik';
import ContactCard from '../ContactCard';
import styles from './UserNameFields.module.css';

const UserNameFields = ({ formProps }: IUserNameFieldsProps) => {
	const { t } = useTranslation();

	const { values, handleBlur, errors, setFieldValue, setFieldError } = formProps;

	return (
		<ContactCard title={t('Name')}>
			<div className={styles.inputs__container}>
				<div
					className={clsx(
						styles.input__wrapper,
						errors.firstName && styles.input__wrapper_err,
						errors.firstName && 'fieldErrorMessage__active',
					)}
				>
					<input
						placeholder={t('First Name')}
						value={values.firstName}
						onBlur={handleBlur('firstName')}
						onChange={(e) => {
							setFieldValue('firstName', e.target.value);
							setFieldError('firstName', undefined);
						}}
					/>
					<When condition={!!errors.firstName}>
						<span className={styles.input__error}>
							{t('First name field is empty or invalid. Please check your input.')}
						</span>
					</When>
				</div>
				<div
					className={clsx(
						styles.input__wrapper,
						errors.lastName && styles.input__wrapper_err,
						errors.lastName && 'fieldErrorMessage__active',
					)}
				>
					<input
						placeholder={t('Last Name')}
						value={values.lastName}
						onBlur={handleBlur('lastName')}
						onChange={(e) => {
							setFieldValue('lastName', e.target.value);
							setFieldError('lastName', undefined);
						}}
					/>
					<When condition={!!errors.lastName}>
						<span className={styles.input__error}>
							{t('Last name field is empty or invalid. Please check your input.')}
						</span>
					</When>
				</div>
			</div>
		</ContactCard>
	);
};

export interface IUserNameFieldsProps {
	formProps: FormikProps<TEditContactCard>;
}

export default UserNameFields;
