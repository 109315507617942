import { useMemo, useDebounce } from '@hooks';
import { useAddressBookQuery } from '@ui-modules/contacts/hooks/useAddressBookQuery';
import { filterUserProfileRecords } from '@utils';

export const useFilterUsersProfilesRecords = (searchQuery: string) => {
	const { data: userProfileRecords = [], isLoading } = useAddressBookQuery();
	const [debouncedSearchQuery] = useDebounce(searchQuery, 400);
	const filteredProfiles = useMemo(
		() => filterUserProfileRecords(userProfileRecords, debouncedSearchQuery),
		[userProfileRecords, debouncedSearchQuery],
	);
	return { filteredProfiles, isLoading, userProfileRecords };
};
