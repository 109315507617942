import type { IDealFormSectionSectionProps } from '../DealForm.types';
import { useCallback, useEffect, useMe, useMemo, useNavigate, useState } from '@hooks';
import { useGetDealAssetClassesQuery } from '@ui-modules/deals/hooks/useGetDealAssetClassesQuery';
import { useGetDealSubClassesQuery } from '@ui-modules/deals/hooks/useGetDealSubClassesQuery';
import { useGetDealTypesQuery } from '@ui-modules/deals/hooks/useGetDealTypesQuery';
import { useInvestNetworks } from '@ui-modules/networks/hooks/useInvestNetworks';
import { dealFormFields } from '../DealForm.schema';
import { ROUTES } from '../../../../../app/routes';
import { createSearchParams } from 'react-router-dom';
import { extractIdFromIri } from '@utils';
import { JoinNetworkError } from '../sections/JoinNetworkError';
import { AssetClassesSectionTemplate } from '../sections/AssetClassesSection';

export type TJoinNetworkError = { network: string; communityId: string };

export const AssetClassesSection = ({ formProps, communityId, isEdit }: IAssetClassesSectionProps) => {
	const [joinNetworkError, setJoinNetworkError] = useState<TJoinNetworkError | null>(null);
	const navigate = useNavigate();
	const { user } = useMe();

	const { values, errors, setFieldError } = formProps;
	const isCreatedOnBehalf = user['@id'] !== values.creator?.iriId;
	const userIdParam = isCreatedOnBehalf ? extractIdFromIri(values.creator?.iriId || '') : undefined;
	const { data: assetClasses = [], isLoading } = useGetDealAssetClassesQuery(userIdParam);
	const { data: subClasses = [] } = useGetDealSubClassesQuery(values.dealAssetClass);
	const { data: dealTypes = [] } = useGetDealTypesQuery(values.dealSubClass);
	const { myNetworks, foreignNetworks } = useInvestNetworks();

	useEffect(() => {
		// Detect whether user is joined certain network. If not set error
		if (values.dealAssetClass) {
			const selectedAssetClass = assetClasses.find((i) => i['@id'] === values.dealAssetClass);
			const isJoined = myNetworks?.some((myNetwork) => myNetwork['@id'] === selectedAssetClass?.community);
			const communityToJoin = foreignNetworks?.find((network) => network['@id'] === selectedAssetClass?.community);
			if (!isJoined && communityToJoin) {
				setJoinNetworkError({ network: communityToJoin?.name, communityId: communityToJoin.id });
				return setFieldError(dealFormFields.ASSET_CLASS, 'join_network');
			}
			setFieldError(dealFormFields.ASSET_CLASS, undefined);
			setJoinNetworkError(null);
		}
	}, [values.dealAssetClass, assetClasses, myNetworks, foreignNetworks, setFieldError]);

	const assetClassOptions = useMemo(() => {
		return assetClasses.map((assetClass) => ({ value: assetClass['@id'], title: assetClass.name }));
	}, [assetClasses]);

	const subClassOptions = useMemo(() => {
		return subClasses.map((subClass) => ({ value: subClass['@id'], label: subClass.name }));
	}, [subClasses]);

	const typesOptions = useMemo(() => {
		return dealTypes.map((type) => ({ value: type['@id'], label: type.name }));
	}, [dealTypes]);

	const onJoinNetworkErrorPress = useCallback(() => {
		if (joinNetworkError) {
			const formState = JSON.stringify({ errors, values });
			let params: Record<string, string> = { formState };
			if (communityId) params = { ...params, communityId };
			const search = createSearchParams(params);
			navigate({ search: `?${search}` }, { replace: true });
			navigate(ROUTES.networkTab(joinNetworkError.communityId, 'about'));
		}
	}, [joinNetworkError, navigate, errors, values, communityId]);

	return (
		<AssetClassesSectionTemplate
			assetClasses={assetClasses}
			assetClassOptions={assetClassOptions}
			formProps={formProps}
			isClassSelectorDisabled={isLoading || !!communityId || isEdit}
			isEdit={isEdit}
			joinNetworkError={
				errors.dealAssetClass === 'join_network' && joinNetworkError ? (
					<JoinNetworkError networkName={joinNetworkError?.network} onClick={onJoinNetworkErrorPress} />
				) : null
			}
			subClassOptions={subClassOptions}
			typesOptions={typesOptions}
		/>
	);
};

interface IAssetClassesSectionProps extends IDealFormSectionSectionProps {
	communityId?: string;
}
