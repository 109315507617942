import { useService, useMutation, useNotification } from '@hooks';
import type { TNetworkConnection, TFolder } from '@typings';
import type { UseMutationOptions } from '@tanstack/react-query';

export const useDeleteNetworkConnectionFolderMutation = (
	networkConnectionId: TNetworkConnection['id'],
	networkConnectionTitle: TNetworkConnection['title'],
	networkConnectionsName: string,
	options?: UseMutationOptions<void, Error, TFolder['id']>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFolder['id']>(
		['networkConnectionFolder.deleteNetworkConnectionFolder'],
		async (folderId) => await api.networkConnectionFolder.deleteNetworkConnectionFolder(folderId),
		{
			...options,
			async onSuccess(response, variables, context) {
				await reactQuery.queryClient.refetchQueries(
					reactQuery.queryKeys.getNetworkConnectionFolders(networkConnectionId),
				);
				analytics.trackGenericEvent(
					'{{connectionName}}Interactions',
					{
						interaction_type: 'investor_files',
						interaction_action: 'folder deleted',
						'{{connectionName}}_id': networkConnectionId,
						'{{connectionName}}_name': networkConnectionTitle,
					},
					{
						connectionName: networkConnectionsName,
					},
				);
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
