import { useQuery, useService } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TDeal } from '@typings';
import type { AxiosError } from 'axios';

export const useGetDealPreviewQuery = (dealId: TDeal['id'], options?: UseQueryOptions<TDeal, AxiosError>) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TDeal, AxiosError>(
		reactQuery.queryKeys.getDealPreview(dealId),
		async () => await api.invest.getDealPreview(dealId),
		{
			enabled: options?.enabled,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
			},
		},
	);
};
