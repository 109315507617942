import { FixedWidthContainer, SectionList } from '@ui-kit';
import EventItem from '@ui-modules/events/components/EventItem';
import { useCallback, useMemo } from '@hooks';
import { useCommunityCalendarItemsQuery } from '@ui-modules/communities/hooks/useCommunityCalendarItemsQuery';
import { removeEmptySectionListItems, clsx } from '@utils';
import styles from './CommunityCalendarItemsSectionList.module.css';
import type { TCommunity, TEvent, TMediaObject } from '@typings';
import type { ISectionListProps, TSectionListItem } from '@ui-kit';
import { useEventAttendanceMutations } from '@ui-modules/events/hooks/useEventAttendanceMutations';

const CommunityCalendarItemsSectionList = ({
	communityId,
	nextItemsTitle,
	heroImageUrl,
	onClick,
	...sectionListProps
}: ICommunityCalendarItemsSectionListProps) => {
	const { isLoading, data: calendarItems = [] } = useCommunityCalendarItemsQuery(communityId, {
		suspense: true,
	});
	const {
		changeEventAttendanceMutation,
		requestEventAttendanceMutation,
		cancelEventAttendanceMutation,
		launchVirtualMeeting,
	} = useEventAttendanceMutations();
	const sectionListItems: TSectionListItem<TEvent>[] = useMemo(
		function buildSectionListItems() {
			if (isLoading) return [];
			return removeEmptySectionListItems([
				{
					title: nextItemsTitle ?? '',
					data: calendarItems,
				},
			]);
		},
		[isLoading, calendarItems],
	);

	const renderCalendarItem = useCallback<ISectionListProps<TEvent>['renderItem']>(
		(calendarItem, index, groupTitle, isLastInGroup) => (
			<FixedWidthContainer>
				<div className={clsx(!isLastInGroup && styles.communityCalendarItemsSectionList__item_nonLast)}>
					<EventItem
						event={calendarItem}
						isAttending={calendarItem?.currentUserAttendance?.status === 'going' && calendarItem['@type'] !== 'Meeting'}
						isCancelled={calendarItem.isCanceled}
						shouldShowMeetingAction={calendarItem['@type'] === 'Meeting'}
						titleVariant="large"
						onCancelRequestAttendance={cancelEventAttendanceMutation.mutateAsync}
						onChangeAttendance={changeEventAttendanceMutation.mutateAsync}
						onClick={() => onClick(calendarItem)}
						onLaunchVirtualMeeting={launchVirtualMeeting}
						onRequestAttendance={requestEventAttendanceMutation.mutateAsync}
					/>
				</div>
			</FixedWidthContainer>
		),
		[],
	);

	return (
		<SectionList<TEvent>
			className={styles.communityCalendarItemsSectionList__sectionList}
			data={sectionListItems}
			headerClassName={styles.communityCalendarItemsSectionList__header}
			isLoading={isLoading}
			renderItem={renderCalendarItem}
			{...sectionListProps}
			hideSectionHeaders={!nextItemsTitle}
			ListHeaderComponent={useMemo(
				() =>
					heroImageUrl ? (
						<FixedWidthContainer>
							<img
								alt="community background"
								className={styles.communityCalendarItemsSectionList__heroImage}
								src={heroImageUrl}
							/>
						</FixedWidthContainer>
					) : null,
				[heroImageUrl],
			)}
		/>
	);
};

export interface ICommunityCalendarItemsSectionListProps extends Pick<ISectionListProps<TEvent>, 'emptyMessage'> {
	communityId: TCommunity['id'];
	nextItemsTitle?: string;
	heroImageUrl?: TMediaObject['contentUrl'] | undefined;
	onClick: (calendarItem: TEvent) => void;
}

export default CommunityCalendarItemsSectionList;
