import { useEffect, useNavigate, useSearchParams, useTranslation } from '@hooks';
import { ActivityIndicator, Gap, Link, Modal } from '@ui-kit';
import ResendCodeButton from '@ui-modules/auth/components/ResendCodeButton';
import VerifyFactorTypeSelector from '@ui-modules/auth/components/VerifyFactorTypeSelector';
import VerifySignInForm from '@ui-modules/auth/components/VerifySignInForm';
import { translateFactorSentResult } from '@ui-modules/auth/utils/translateFactorSentResult';
import { Page } from '../../components';
import styles from './VerifySignInPage.module.css';
import { useOktaVerify } from '@ui-modules/auth/hooks/useOktaVerify';
import { ROUTES } from '@constants';
import type { TOktaVerificationFlow } from '../../../services/interfaces/OktaService.interface';

const VerifySignInPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const flow = useSearchParams()[0].get('flow') as TOktaVerificationFlow | undefined;

	const {
		currentFactor,
		currentAuthenticator,
		expirationDate,
		availableFactorTypes,
		isStarting,
		isRestarting,
		isSubmitting,
		currentAuthenticatorStartedWhenExpired,
		initializeVerification,
		beginVerification,
		restartVerification,
		cancelVerification,
		finishVerification,
		modalProps: verificationFeedbackModalProps,
	} = useOktaVerify(flow, {
		onError: () => navigate(ROUTES.signIn(), { replace: true }),
		onCancelVerification: () => navigate(ROUTES.signIn(), { replace: true }),
		onSetNewPasswordRequired: () => navigate(ROUTES.setNewPassword(), { replace: true }),
		onNextVerificationRequired: () => initializeVerification(),
	});

	useEffect(() => {
		initializeVerification();
	}, []);

	return (
		<Page title={t('Verify sign in')}>
			<div className={styles.verifySignInPage}>
				{currentFactor ? (
					<header className={styles.verifySignInPage__header}>
						<h4>
							{currentAuthenticatorStartedWhenExpired
								? t('Login code (click Resend Code)')
								: translateFactorSentResult(t)(currentAuthenticator)}
						</h4>
					</header>
				) : null}
				{currentFactor && !isStarting ? (
					<>
						<Gap gap={32} />
						<section>
							<VerifySignInForm
								BottomContentComponent={
									<>
										<VerifyFactorTypeSelector
											availableFactorTypes={availableFactorTypes}
											currentFactorType={currentFactor}
											onChangeCurrentFactorType={beginVerification}
										/>
										<Gap />
									</>
								}
								onSubmit={finishVerification}
							/>
						</section>
						<Gap />
						<ResendCodeButton
							disabled={isSubmitting}
							expirationDate={expirationDate}
							loading={isStarting || isRestarting}
							startTimerOnMount
							onClick={() => restartVerification()}
						/>
						<Gap />
						<div>
							<Link className={styles.verifySignInPage__goBackLink} replace to="/sign-in" onClick={cancelVerification}>
								{t('Go back to sign in')}
							</Link>
						</div>

						<Modal {...verificationFeedbackModalProps} />
					</>
				) : (
					<ActivityIndicator size="medium" type="fit" />
				)}
			</div>
		</Page>
	);
};

export default VerifySignInPage;
