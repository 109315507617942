import Input from '../Input';
import { LockIcon, EyeIcon, EyeClosedIcon } from '@ui-kit/icons';
import { useToggle } from '@hooks';
import styles from './PasswordInput.module.css';
import type { IInputProps } from '../Input';

const PasswordInput = ({ value, showPasswordLabel, hidePasswordLabel, ...inputProps }: IPasswordInputProps) => {
	const [isPasswordVisible, toggleIsPasswordVisible] = useToggle(false);
	const handleClick = () => toggleIsPasswordVisible();

	return (
		<Input
			{...inputProps}
			autoComplete="password"
			icon={<LockIcon className={styles.passwordInput__icon} height={12} width={12} />}
			iconPosition="left"
			secureTextEntry={!isPasswordVisible}
			subInputElement={
				<div className={styles.passwordInput__passwordVisibilityTogglerWrapper}>
					<div
						className={styles.passwordInput__passwordVisibilityToggler}
						role="button"
						tabIndex={0}
						onClick={handleClick}
						onKeyPress={handleClick}
					>
						<span>{isPasswordVisible ? hidePasswordLabel : showPasswordLabel}</span>
						<span className={styles.passwordInput__eyeIconsBox}>
							{isPasswordVisible ? <EyeClosedIcon /> : <EyeIcon />}
						</span>
					</div>
				</div>
			}
			value={value}
		/>
	);
};

export interface IPasswordInputProps
	extends Omit<IInputProps, 'type' | 'iconPosition' | 'icon' | 'autoComplete' | 'secureTextEntry'> {
	showPasswordLabel: string;
	hidePasswordLabel: string;
}

export default PasswordInput;
