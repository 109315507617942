import { useMemo } from '@hooks';
import { NetworkConnectionEntity, NetworkConnectionUserFilterEntity, textRegisterPluralize } from '@utils';

import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useNetworkConnectionUserFiltersQuery } from '@ui-modules/connections/hooks/useNetworkConnectionUserFiltersQuery';
import { useGetNetworkConnectionTemplateQuery } from '@ui-modules/connections/hooks/useGetNetworkConnectionTemplateQuery';

import type { TCommunity } from '@typings';

import { useNetworkConnectionFilterTitle } from './useNetworkConnectionFilterTitle';
import type { INCFilterWithChildren } from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';
import { updateCategoryValues } from '@ui-modules/connections/components/NetworkConnectionsFilterForm/NetworkConnectionsFilterForm';

export const useNetworkConnectionFilterFormRenderer = (communityId: TCommunity['id']) => {
	const {
		data: userFilters,
		isLoading: isUserFiltersLoading,
		refetch,
		isFetched: isUserFiltersFetched,
		isRefetching,
		dataUpdatedAt,
	} = useNetworkConnectionUserFiltersQuery({
		communityId: communityId,
	});
	const { data: community, isLoading, isFetched, isFetching } = useCommunityQuery(communityId);
	const { updateFilter: updateFilterTitle, filterTitle } = useNetworkConnectionFilterTitle();
	const networkConnectionNamePlural = textRegisterPluralize(community?.networkConnectionsName);

	const {
		data: formTemplate,
		isLoading: isFormTemplateLoading,
		isFetched: isFormTemplateFetched,
	} = useGetNetworkConnectionTemplateQuery(communityId);

	const canRender =
		isFetched &&
		!isLoading &&
		!isFetching &&
		!isUserFiltersLoading &&
		!isFormTemplateLoading &&
		isFormTemplateFetched &&
		isUserFiltersFetched &&
		!isRefetching;

	const { appliedFilters, filters } = useMemo(() => {
		const validDynamicLinks = { ...userFilters?.filters };

		delete (validDynamicLinks as Partial<typeof validDynamicLinks>)?.categories;

		const dynamicFilters = formTemplate?.dynamicFields?.length
			? Object.values(
					NetworkConnectionUserFilterEntity.transformNetworkConnectionFormTemplate(
						NetworkConnectionUserFilterEntity.filterNetworkConnectionTemplate(formTemplate.dynamicFields),
						validDynamicLinks.dynamicFields,
					),
			  )
			: [];
		const results = {
			categories: formTemplate?.categories
				? (NetworkConnectionEntity.sortCategoriesByLabel(
						NetworkConnectionUserFilterEntity.transformCategories(
							formTemplate?.categories,
							userFilters?.filters?.categories,
						),
				  ) as INCFilterWithChildren[]) // TODO Hrant should type 'filters' typings
				: [],
			dynamicFilters,
		};

		updateFilterTitle({
			filters: {
				categories: updateCategoryValues(
					results.categories as INCFilterWithChildren[],
					userFilters?.filters.categories,
				),
				dynamicFilters: validDynamicLinks.dynamicFields,
			},
			networkConnectionName: networkConnectionNamePlural,
		});

		return {
			filters: results,
			appliedFilters: validDynamicLinks.dynamicFields,
		};
	}, [formTemplate, userFilters]);

	return {
		filters,
		appliedFilters,
		community,
		canRender,
		refetch,
		networkConnectionsName: networkConnectionNamePlural,
		filterTitle,
		latestAppliedFilters: {
			categories: [],
			...userFilters?.filters,
		},
		currency: formTemplate?.currency,
		dataUpdatedAt,
	};
};
