import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useGetEventFilesQuery } from '@ui-modules/events/hooks/useGetEventFilesQuery';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const useMeetingData = (meetingId?: string) => {
	const navigate = useNavigate();
	const { data: meeting, isSuccess } = useGetMeetingQuery(meetingId, (data, error) => {
		if (!data || error) {
			navigate(ROUTES.calendar(), { replace: true });
		}
	});

	const { data: community } = useCommunityQuery(meeting?.groups?.[0]?.community?.id);

	const { data: eventFiles = [] } = useGetEventFilesQuery(meetingId);

	return { isSuccess, meeting, community, eventFiles };
};
