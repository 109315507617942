import { CheckboxInput } from '@ui-kit';
import clsx from 'clsx';
import InputDescription from '../InputDescription';
import InputErrorMessage from '../InputErrorMessage';
import InputLabel from '../InputLabel';
import styles from './CheckboxGroupInput.module.css';

const CheckboxGroupInput = <TValue extends string = string>({
	label,
	disabled,
	errorMessage,
	options,
	description,
	value = [],
	onChange,
	containerClassName,
}: ICheckboxGroupInputProps<TValue>) => {
	return (
		<div className={clsx(styles.container, errorMessage && styles.input_error, containerClassName)}>
			<InputLabel text={label} />
			<div className={styles.checkboxItems__wrapper}>
				{options.map((option) => {
					const isSelected = value?.includes(option.value);
					return (
						<CheckboxInput
							className={clsx(!disabled && styles.checkboxInput_enabled)}
							disabled={disabled}
							errored={!!errorMessage}
							key={option.value}
							label={option.label}
							size="medium"
							value={isSelected}
							onChange={() => onChange(option.value)}
						/>
					);
				})}
			</div>
			<InputDescription text={description} />
			{errorMessage ? <InputErrorMessage text={errorMessage} /> : null}
		</div>
	);
};

export type TRadioGroupInputOption<TValue extends string = string> = {
	label: string;
	value: TValue;
	disabled?: boolean;
	subLabel?: string;
};
export interface ICheckboxGroupInputProps<TValue extends string = string> {
	/** Specify the name to group radio buttons into one value */
	options: TRadioGroupInputOption<TValue>[];
	value: TValue[];
	/** Title of the whole input. */
	label?: string | undefined;
	/** If true forbids change value.*/
	disabled?: boolean;
	/** Help text displayed between label and error message. */
	description?: string;
	/** If exists displays red error message given from form validation. */
	errorMessage?: string;
	/** Returns a new checked value */
	onChange: (newValue: TValue) => void;
	/** Custom style for container */
	containerClassName?: string;
}

export default CheckboxGroupInput;
