import { useMutation, useNotification, useService } from '@hooks';
import type { TDeal, TDealMemberRelation, TProfileDeal } from '@typings';
import type { AxiosError } from 'axios';

export const useDealAlertsMutation = () => {
	const api = useService('ApiService');
	const { showUnknownError } = useNotification();
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');

	return useMutation<
		void,
		AxiosError,
		{ dealId: TDeal['id']; relationId: string; newPostAlerts: TDealMemberRelation['newPostAlerts'] }
	>(
		['invest.patchDealMemberAlert'],
		async ({ relationId: id, newPostAlerts }) => await api.invest.patchDealMemberAlert(id, newPostAlerts),
		{
			onSuccess(data, { newPostAlerts }, context) {
				analytics.trackEvent('DealInteractions', {
					page_name: 'Deal Discussions',
					action_taken: newPostAlerts ? 'alerts on' : 'alerts off',
					interaction_type: 'alerts toggled',
				});
			},
			onMutate: ({ dealId, newPostAlerts }) => {
				reactQuery.updateDealsCache(dealId, setDealAlerts(newPostAlerts)); // optimistic update
			},
			onError: (error, { dealId, newPostAlerts }) => {
				showUnknownError(error);
				reactQuery.updateDealsCache(dealId, setDealAlerts(!newPostAlerts)); // revert optimistic update
			},
		},
	);
};

const setDealAlerts = (newPostAlerts: boolean) => (deal: TDeal | TProfileDeal) => {
	if (deal.currentDealMemberRelation) {
		deal.currentDealMemberRelation.newPostAlerts = newPostAlerts;
	}
};
