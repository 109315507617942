import { useService, useQuery } from '@hooks';
import type { TDeal, TFolder } from '@typings';

export const useDealFolderQuery = (dealId: TDeal['id'], folderId: TFolder['id'] | null) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useQuery<TFolder, Error>(
		reactQuery.queryKeys.getDealFolder(dealId, folderId),
		async () => await api.dealFolder.getDealFolder(String(folderId)),
		{
			enabled: !!folderId,
		},
	);
};
