import { Button, EmptyFileCard, Gap, Input, CheckboxInput, Formik } from '@ui-kit';
import { useTranslation } from '@hooks';
import { filePasswordFormSchema } from '@schemas';
import styles from './FilePasswordForm.module.css';
import type { TFilePasswordForm } from '@schemas';

type FormValues = { password: string; confirmPassword: string };

const initialValues: TFilePasswordForm = { password: '', confirmPassword: '' };

const FilePasswordForm = ({
	file,
	isViewOnly,
	isUploading,
	setIsViewOnly,
	onUpload,
	onCancel,
}: IFilePasswordFormProps) => {
	const { t } = useTranslation();
	return (
		<Formik<FormValues>
			initialValues={initialValues}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={filePasswordFormSchema(t)}
			onSubmit={async ({ password }) => onUpload({ secretKey: password, isViewOnly })}
		>
			{(formProps) => {
				const { values, handleChange, handleBlur, errors, isSubmitting, submitForm } = formProps;
				return (
					<>
						<EmptyFileCard name={file.name} type={file.type} />
						<Gap gap={16} />
						{file.type === 'application/pdf' ? (
							<>
								<CheckboxInput
									label={t('Make this file read-only (Members can’t download)')}
									size="medium"
									value={isViewOnly}
									onChange={setIsViewOnly}
								/>
								<Gap gap={16} />
							</>
						) : null}
						<Input
							autoCapitalize="none"
							disabled={isSubmitting || isUploading}
							errorMessage={errors.password}
							label={t('Password')}
							placeholder={t('Password')}
							secureTextEntry
							value={values.password}
							onBlur={handleBlur('password')}
							onChange={handleChange('password')}
						/>
						<Input
							autoCapitalize="none"
							disabled={isSubmitting || isUploading}
							errorMessage={errors.confirmPassword}
							label={t('Confirm password')}
							placeholder={t('Confirm password')}
							secureTextEntry
							value={values.confirmPassword}
							onBlur={handleBlur('confirmPassword')}
							onChange={handleChange('confirmPassword')}
						/>
						<div className={styles.filePasswordForm__button}>
							<Button title={t('Cancel')} type={'clear'} onClick={onCancel} />
							<Button
								disabled={isUploading}
								iconPosition={'right'}
								loading={isUploading}
								title={t('Save')}
								onClick={submitForm}
							/>
						</div>
					</>
				);
			}}
		</Formik>
	);
};

interface IFilePasswordFormProps {
	onUpload: (args: { secretKey?: string; isViewOnly?: boolean }) => void;
	onCancel: () => void;
	file: File;
	isViewOnly: boolean;
	isUploading: boolean;
	setIsViewOnly: () => void;
}

export default FilePasswordForm;
