import { useCollapse, useTranslation, useService } from '@hooks';
import styles from './ProfileExpertise.module.css';
import { EXPERTISE } from '@constants';
import { Button, Card, ColoredItemNumber, TagRows } from '@ui-kit';

const populateExpertiseList = (expertiseList: string[]) => {
	const missingEmptyValuesCount = Math.max(0, EXPERTISE.LIMIT - expertiseList.length);
	if (!missingEmptyValuesCount) return expertiseList;

	const emptyValues = Array(missingEmptyValuesCount).fill('');
	return [...expertiseList, ...emptyValues];
};

const ProfileExpertise = ({ isInvisible = false, expertiseSpheres = [], canEdit, onEdit }: IProfileExpertiseProps) => {
	const { t } = useTranslation();
	const analytics = useService('AnalyticsService');

	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
		defaultExpanded: false,
		onExpandEnd: () => {
			analytics.trackEvent('ProfileInteractions', {
				interaction_type: 'Read More clicked',
				action_value: 'Expertise',
			});
		},
	});
	const expertiseList = populateExpertiseList(expertiseSpheres);
	const canExpand = expertiseList.length > 3;

	return isInvisible ? null : (
		<Card
			actionElement={
				canEdit ? <Button className={styles.editButton} title={t('Edit')} variant="small" onClick={onEdit} /> : null
			}
			title={t('I have expertise in')}
		>
			<div className={styles.expertise__container}>
				{expertiseList.slice(0, 3).map((expertise, index) => (
					<div className={styles.expertise__item} key={index + expertise}>
						<ColoredItemNumber colors={EXPERTISE.COLORS} index={index} />
						<span className={styles.expertise__title}>{expertise}</span>
					</div>
				))}
				<div data-testid="CollapsibleProfileExpertise__content" {...getCollapseProps()}>
					<TagRows mode="row" tags={expertiseList.slice(3, expertiseList.length)} title={t('Additional Expertise')} />
				</div>
				{canExpand && (
					<button className={styles.expertise__button} {...getToggleProps()} aria-label="toggle">
						<span>{isExpanded ? t('See less') : t('See more')}</span>
					</button>
				)}
			</div>
		</Card>
	);
};

export interface IProfileExpertiseProps {
	isInvisible?: boolean;
	expertiseSpheres: string[];
	canEdit: boolean;
	onEdit: () => void;
}

export default ProfileExpertise;
