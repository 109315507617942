import type { TAttachments } from '@typings';

export const transformAttachments = (attachments: TAttachments[]) => {
	let files: TAttachments[] = [];
	let images: TAttachments[] = [];
	let videos: TAttachments[] = [];

	files = attachments.filter((item) => item.isFile);
	images = attachments.filter((item) => {
		return item.mediaType?.includes('image') || item.mediaType?.includes('photo') || item.url?.includes('image');
	});
	videos = attachments.filter((item) => {
		return item.mediaType?.includes('video');
	});
	return { files, images, videos };
};
