import emojiRegex from 'emoji-regex';

import type { StreamMessage } from 'stream-chat-react';
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export const isOnlyEmoji = (message: StreamMessage<DefaultStreamChatGenerics>) => {
	const str = message.text;
	if (!str || typeof str !== 'string' || message?.attachments?.length || message?.eventId) {
		return false;
	}

	// Extract all emoji matches from the string
	const matchedEmojis = [...str.matchAll(emojiRegex())].map((match) => match[0]);

	const combinedEmojis = matchedEmojis.join('');

	// Check if the string contains any alphabetic or numeric characters
	const hasTextOrNumbers = /[a-zA-Z0-9]/.test(str);

	// If the combined emojis match the original string and there's no text or numbers, return true
	return combinedEmojis === str && !hasTextOrNumbers;
};
