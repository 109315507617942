import { FullScreenPageLayout, Page } from '../../components';
import {
	SearchableScreenHeader,
	Gap,
	FixedWidthContainer,
	CommunityCard,
	Virtuoso,
	ActivityIndicator,
	EmptyStateMessage,
} from '@ui-kit';
import {
	useTranslation,
	useState,
	useCallback,
	useRestoreVirtuosoInitialIndex,
	useNavigate,
	useRouteSearchQueryState,
} from '@hooks';
import { useAllChaptersQuery } from '@ui-modules/groups/hooks/useAllChaptersQuery';
import { ROUTES } from '@constants';
import { startTransition } from 'react';
import { translateMembers, translateGroups } from '@utils';
import type { TCommunity } from '@typings';
import type { ItemContent } from 'react-virtuoso';

const ChaptersPage = () => {
	// Dependencies.
	const { t } = useTranslation();
	const navigate = useNavigate();

	// State.
	const [routeSearchQuery, setRouteSearchQuery] = useRouteSearchQueryState();
	const [searchQuery, setSearchQuery] = useState(routeSearchQuery);
	const { filteredChapters, isLoading } = useAllChaptersQuery(searchQuery);
	const { initialTopMostItemIndex, setInitialTopMostItemIndex, onRangeChanged } =
		useRestoreVirtuosoInitialIndex(`allChapters`);

	// Render logic.
	const renderItem = useCallback<ItemContent<TCommunity, unknown>>(
		(index, chapter) => (
			<CommunityCard
				community={chapter}
				formatGroupsCount={translateGroups(t)}
				formatMembersCount={translateMembers(t)}
				onClick={() => {
					navigate(ROUTES.chapterTab(chapter.id));
					setInitialTopMostItemIndex(index);
				}}
			/>
		),
		[],
	);

	return (
		<Page title={t('Groups')}>
			<FullScreenPageLayout headerTitle={t('Chapters')}>
				<FixedWidthContainer>
					<SearchableScreenHeader
						placeholder={t('Search chapters')}
						searchQuery={searchQuery}
						searchTitle={t('Search')}
						title={t('Chapters')}
						onChangeSearchQuery={(nextSearchQuery) => {
							setSearchQuery(nextSearchQuery);
							startTransition(() => setRouteSearchQuery(nextSearchQuery));
						}}
					/>
					<Virtuoso<TCommunity>
						components={{
							EmptyPlaceholder: () =>
								isLoading ? (
									<ActivityIndicator size="medium" type="fit" />
								) : (
									<EmptyStateMessage text={t('Sorry, we couldn’t find any matching results')} />
								),
							Footer: () => <Gap gap={8} />,
						}}
						data={filteredChapters}
						defaultItemHeight={137}
						initialTopMostItemIndex={initialTopMostItemIndex}
						itemContent={renderItem}
						rangeChanged={onRangeChanged}
						totalCount={filteredChapters?.length}
					/>
				</FixedWidthContainer>
			</FullScreenPageLayout>
		</Page>
	);
};

export default ChaptersPage;
