import { useMe, useMutation, useNotification, useService, useTranslation } from '@hooks';
import type { TCommunity, TDeal } from '@typings';

export const useChangeDealStatusMutation = (
	dealId: TDeal['id'],
	communityId: TCommunity['id'],
	onCancelDealSuccess?: () => void,
) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const { showSuccess, showError } = useNotification();
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	const closeDeal = useMutation<void, Error>(['deal.closeDeal'], () => api.invest.changeDealStatus(dealId, 'closed'), {
		onSuccess: () => {
			showSuccess({ title: t('Deal Closed') });
			reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getDeal(dealId));
			reactQuery.updateDealsCache(dealId, (deal) => {
				deal.status = 'closed';
			});
		},
		onError: () => {
			showError({ title: t('Error close deal') });
		},
	});

	const cancelDeal = useMutation<void, Error>(
		['deal.cancelDeal'],
		() => api.invest.changeDealStatus(dealId, 'cancelled'),
		{
			onSuccess: () => {
				showSuccess({ title: t('Deal Cancelled') });
				onCancelDealSuccess?.();

				if (user.roles.includes('ROLE_INVEST_ADMIN')) {
					reactQuery.updateDealsCache(dealId, (deal) => {
						deal.status = 'cancelled';
					});
				} else {
					reactQuery.removeDealFromCache(dealId);
				}
			},
			onError: () => {
				showError({ title: t('Error cancel deal') });
			},
		},
	);

	return {
		onCloseDeal: closeDeal.mutateAsync,
		onCancelDeal: cancelDeal.mutateAsync,
	};
};
