import { FormPageLayout, Page } from '../../components';
import { useTranslation } from '@hooks';
import { Formik } from '@ui-kit';
import { useUpdateProfileHidePropertyMutation } from '@ui-modules/profile/hooks/useUpdateProfileHiddenessMutation';
import { ROUTES } from '@constants';
import type { TEditProfileActivities } from '@schemas';
import { editProfileActivitiesSchema } from '@schemas';
import EditProfileSwitchForm from '@ui-modules/profile/components/EditProfileSwitchForm';

const EditProfileActivitiesPage = () => {
	const { t } = useTranslation();
	const { initialValues, submit } = useUpdateProfileHidePropertyMutation('activities');

	return (
		<Page title={t('Edit Profile Activities')}>
			<Formik<TEditProfileActivities>
				enableReinitialize
				initialValues={initialValues as TEditProfileActivities}
				validationSchema={editProfileActivitiesSchema}
				onSubmit={submit}
			>
				{(formProps) => {
					const { values, setFieldValue } = formProps;
					return (
						<FormPageLayout
							allowedNextPaths={[ROUTES.editProfileActivities()]}
							formProps={formProps}
							headerTitle={t('Activity')}
							saveTrackingName="profile--edit-activities"
						>
							<EditProfileSwitchForm
								activeDescription={t('Members can see how you’re engaging with the community.')}
								activeTitle={t('Show my recent activity')}
								inactiveDescription={t('Members won’t be able to see how you’re engaging with the community.')}
								inactiveTitle={t('Hide my recent activity')}
								value={values.showOnProfile}
								onChange={(value) => setFieldValue('showOnProfile', value)}
							/>
						</FormPageLayout>
					);
				}}
			</Formik>
		</Page>
	);
};

export default EditProfileActivitiesPage;
