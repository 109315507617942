import { useEvent } from '@hooks';
import type { TCommunity } from '@typings';
import { useMyInvestNetworksQuery } from './useMyInvestNetworksQuery';
import { useForeignInvestNetworksQuery } from './useForeignInvestNetworksQuery';

export const useInvestNetworks = () => {
	// queries
	const myNetworksQuery = useMyInvestNetworksQuery();
	const foreignInvestNetworksQuery = useForeignInvestNetworksQuery();

	// data
	const myNetworks = myNetworksQuery.data ?? EMPTY_DATA;
	const foreignNetworks = foreignInvestNetworksQuery.data ?? EMPTY_DATA;

	// states
	const isLoading = foreignInvestNetworksQuery.isLoading || myNetworksQuery.isLoading;
	const isSuccess = foreignInvestNetworksQuery.isSuccess && myNetworksQuery.isSuccess;

	const refetch = useEvent(() => {
		foreignInvestNetworksQuery.refetch();
		myNetworksQuery.refetch();
	});

	return {
		myNetworks,
		foreignNetworks,
		isLoading,
		isSuccess,
		refetch,
	};
};

const EMPTY_DATA: TCommunity[] = [];
