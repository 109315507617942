import { Navigate, useParams } from 'react-router-dom';
import { ROUTES } from '@constants';

/** Just redirects to "/calendar/meetings/:id" or "/calendar/events/:id"
 * 	to follow the same URL schema as a mobile app.
 */
const RedirectToDealTab = () => {
	const params = useParams<{ dealId: string }>();

	return <Navigate replace={true} to={ROUTES.dealTabs(params.dealId)} />;
};

export default RedirectToDealTab;
