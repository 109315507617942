import { Fragment } from 'react';
import { EditIcon, Link } from '@ui-kit';
import RenameFolderModal from './modals/RenameFolderModal';
import { useToggle, useTranslation } from '@hooks';
import { toString } from '@utils';
import styles from './FilesWidget.module.css';
import type { TFolder } from '@typings';
import type { IFilesWidgetApiCallbacks } from './FilesWidgetApiCallbacks.interface';

/** Combines tools to display list of files and folders for a certain deal & toolbar to make changes with them or search. */
const FilesWidgetHeader = ({
	isRootFolder,
	folder,
	canEditFolders,
	makeFolderLink,
	onRenameFolder,
}: IDealFilesHeaderWidgetProps) => {
	const { t } = useTranslation();

	const folderId = folder?.id ?? null;
	const [isRenameFolderDialogVisible, toggleRenameFolderDialog] = useToggle(false);

	return (
		<div className={styles.filesWidget__breadcrumbsContainer}>
			{isRootFolder ? null : (
				<Link className={styles.filesWidget__folderName} replace to={makeFolderLink(null)}>
					{t('Files')}
				</Link>
			)}
			{folder?.breadcrumbs?.map((folder) => (
				<Fragment key={folder.id}>
					<span className={styles.filesWidget__folderNameSeparator}>&nbsp;{`/ `}</span>
					<Link className={styles.filesWidget__folderName} replace to={makeFolderLink(folder.id)}>
						{folder.name}
					</Link>
				</Fragment>
			))}
			{isRootFolder ? null : (
				<>
					<span className={styles.filesWidget__folderNameSeparator}>&nbsp;{`/ `}</span>
					<button
						className={styles.filesWidget__folderName}
						disabled={!canEditFolders}
						onClick={toggleRenameFolderDialog}
					>
						{toString(folder?.name || '...')}
						&nbsp;
						{canEditFolders ? <EditIcon className={styles.filesWidget__editFolderIcon} /> : ''}
					</button>
				</>
			)}
			{isRenameFolderDialogVisible && folderId && folder ? (
				<RenameFolderModal
					folderName={folder.name}
					visible={isRenameFolderDialogVisible}
					onClose={toggleRenameFolderDialog}
					onRenameFolder={onRenameFolder}
				/>
			) : null}
		</div>
	);
};

export interface IDealFilesHeaderWidgetProps extends Pick<IFilesWidgetApiCallbacks, 'onRenameFolder'> {
	isRootFolder: boolean;
	folder: TFolder | undefined;
	canEditFolders: boolean;
	makeFolderLink: (folderId: TFolder['id'] | null) => string;
}

export default FilesWidgetHeader;
