import { useNavigate } from '@hooks';
import { ROUTES } from '@constants';
import type { TDealsTabName } from '@typings';

export const useDealsTabsHandler = (tabNames: TDealsTabName[], currentTabName: string | undefined) => {
	const navigate = useNavigate();
	const currentTabIndex = currentTabName ? tabNames.findIndex((tab) => tab === currentTabName) : 1;

	const onChangeTab = (index: number) => {
		const tabName: TDealsTabName = tabNames[index] ?? tabNames[0];

		navigate(ROUTES.dealsTabs(tabName));
	};

	return { currentTabIndex, onChangeTab };
};
