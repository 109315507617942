import type { TAvailableTemplateVersion, TDynamicField } from '@typings';

export const getDynamicFieldsByVersion = (
	fields?: TDynamicField[],
	availableVersions: TAvailableTemplateVersion[] = [],
	selectedVersion?: string,
) => {
	const filteredFieldsByVersion =
		availableVersions?.length > 1
			? selectedVersion && !!availableVersions?.length
				? filterTemplateByVersion(fields, selectedVersion)
				: []
			: fields;
	return filteredFieldsByVersion;
};

export const filterTemplateByVersion = (fields: TDynamicField[] = [], selectedVersion: string) => {
	return fields?.filter((field) => {
		return (
			field?.meta?.dynamicFieldTemplateVersions?.includes(selectedVersion) ||
			!field?.meta?.dynamicFieldTemplateVersions?.length
		);
	});
};
