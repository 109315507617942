import { useService, useMutation } from '@hooks';

import type { TCommunity } from '@typings';

export const useDeleteNetworkConnectionUserFilterMutation = () => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	return useMutation<void, Error, TCommunity['id']>(
		reactQuery.queryKeys.deleteNetworkConnectionUserFilters(),
		async (communityId) => {
			await api.networkConnectionUserFilter.deleteUserFilter(communityId);
		},
	);
};
