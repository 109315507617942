import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import { NetworkConnectionEntity } from '@tiger21-llc/connect-shared/src/utils/NetworkConnectionEntity';
import type {
	TNetworkConnection,
	TNetworkConnectionMemberRelation,
	TNetworkConnectionMemberRelationStatus,
	TUser,
} from '@typings';

export const useNetworkConnectionMemberRelationStatusMutation = (
	networkConnectionId: TNetworkConnection['id'],
	networkConnectionsName?: string,
	options?: MutationOptions<TNetworkConnectionMemberRelation | undefined, Error, TMutationArgs>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { user } = useMe();
	const { showUnknownError } = useNotification();

	const userId = user.id;

	return useMutation<TNetworkConnectionMemberRelation | undefined, Error, TMutationArgs>(
		['networkConnectionUserRelations'],
		async (args) => {
			if (args?.networkConnectionRelationId) {
				if (args?.networkConnectionRelationStatusId) {
					return await api.networkConnection.putNetworkConnectionUserRelations({
						userId: args.userId || userId,
						networkConnectionId,
						networkConnectionRelationStatusId: args.networkConnectionRelationStatusId,
						networkConnectionRelationId: args.networkConnectionRelationId,
					});
				} else {
					await api.networkConnection.deleteNetworkConnectionUserRelations(args.networkConnectionRelationId);
				}
			} else {
				return await api.networkConnection.postNetworkConnectionUserRelations({
					userId: args.userId || userId,
					networkConnectionRelationStatusId: args.networkConnectionRelationStatusId as string,
					networkConnectionId: networkConnectionId || (args.networkConnectionId as string),
				});
			}
		},
		{
			onSuccess: (response, variables, context) => {
				reactQuery.queryClient.invalidateQueries(
					reactQuery.queryKeys.getNetworkConnectionMemberRelation(networkConnectionId),
				);
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getNetworkConnectionMembers(networkConnectionId));
				if (networkConnectionsName)
					analytics.trackGenericEvent(
						'{{connectionName}}StatusChanged',
						{
							'{{connectionName}}_status_change':
								response?.networkConnectionStatus?.title ??
								NetworkConnectionEntity.DEFAULT_MEMBER_RELATION_STATUS.title,
						},
						{
							connectionName: networkConnectionsName,
						},
					);
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};

type TMutationArgs =
	| {
			networkConnectionRelationId: undefined;
			networkConnectionId?: TNetworkConnection['id'];
			networkConnectionRelationStatusId: TNetworkConnectionMemberRelationStatus['id'];
			userId?: TUser['id'];
	  }
	| {
			networkConnectionRelationId: TNetworkConnectionMemberRelationStatus['id'];
			networkConnectionRelationStatusId: undefined;
			networkConnectionId?: undefined;
			userId?: TUser['id'];
	  }
	| {
			networkConnectionRelationId: TNetworkConnectionMemberRelation['id'];
			networkConnectionRelationStatusId: TNetworkConnectionMemberRelationStatus['id'];
			networkConnectionId?: undefined;
			userId?: TUser['id'];
	  };
