import { GLOBAL_SEARCH, ROUTES } from '@constants';
import { useNavigate, useState, useTranslation } from '@hooks';
import { HomeIcon } from '@ui-kit';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import styles from './NotFound404.module.css';

function NotFound404() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
	const { goToResultsScreen } = useGlobalSearchState();

	return (
		<div className={styles.notFoundPage}>
			<h1 className={styles.notFoundPage__title}>{t('Sorry, we lost this page.')}</h1>
			<div className={styles.notFoundPage__buttons}>
				<button className={styles.notFoundPage__goHomeButton} onClick={() => navigate(ROUTES.home())}>
					<HomeIcon className={styles.notFoundPage__goHomeIcon} />
					{t('Go Home')}
				</button>
				<span className={styles.notFoundPage__goHomeSeparator}>{t('or')}</span>
				<div>
					<input
						className={styles.notFoundPage__searchInput}
						placeholder={t('Try Searching')}
						type="text"
						onChange={(value) => setSearchQuery(value.target.value.trim())}
						onKeyPress={(key) =>
							key.charCode === 13 &&
							searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength &&
							goToResultsScreen(searchQuery)
						}
					/>
					<button
						className={styles.notFoundPage__searchButton}
						onClick={() => searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength && goToResultsScreen(searchQuery)}
					>
						{t('Search')}
					</button>
				</div>
			</div>
			<p className={styles.notFoundPage__subTitle}>{t('Error 404: Page not found.')}</p>
		</div>
	);
}

export default NotFound404;
