import type { FormikFormProps } from 'formik';
import { Form as FormikForm } from 'formik';

/** Extended Formik Form component contains ability to be submitted by clicking Enter to improve UX. */
const Form = ({ children, className }: FormikFormProps) => {
	return (
		<FormikForm className={className}>
			{children}
			{/* Submitting a form by pressing enter without a submit button */}
			<input hidden type="submit" />
		</FormikForm>
	);
};

export default Form;
