import { useState, useEffect } from 'react';

// Custom hook to track ref changes and batch updates
export const useDebouncedMentionSearchQuery = (mentionInputRef: any | null /** FIXME */) => {
	const [searchQuery, setSearchQuery] = useState(undefined);

	useEffect(() => {
		// Function to update search query in batches
		const updateQuery = () => {
			const query = mentionInputRef?.current?.suggestions?.[0]?.queryInfo?.query;
			if (query !== undefined && query !== searchQuery) {
				setSearchQuery(query);
			}
		};

		const intervalId = setInterval(updateQuery, 5);

		return () => clearInterval(intervalId);
	}, [mentionInputRef, searchQuery]);

	return searchQuery;
};
