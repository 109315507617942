import { DealMadeByBlockedUserError } from '@errors';
import { useBlockedAndBlockingUsers, useEffect, useNotification, useTranslation } from '@hooks';
import type { TDeal } from '@typings';

/** Validates whether certain NetworkConnection can be viewed and calling 'onError' if not.
 *  Checks if NetworkConnection is reported, made by blocked user, etc.
 *  Note; didn't manage to put it into useFeedActivityQuery because of significant delay in displaying error message and calling onError callback.
 *  @author dmitriy.nikolenko
 */
export const useValidateDisplayingDeal = (deal: TDeal | undefined, onError: () => void) => {
	const { t } = useTranslation();
	const { showInfo } = useNotification();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();

	useEffect(() => {
		if (!deal) return;

		try {
			DealMadeByBlockedUserError.throwUnlessValid(deal, blockedAndBlockingUsers);
		} catch (error) {
			if (error instanceof DealMadeByBlockedUserError) {
				showInfo(DealMadeByBlockedUserError.getNotificationMessage(t));
			}
			onError();
		}
	}, [deal, blockedAndBlockingUsers]);
};
