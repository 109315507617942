import { memo } from 'react';
import { useMe } from '@hooks';
import { BASE_STATIC_WEB_PAGE_PASSWORD } from '@constants';
import styles from './CommunityResource.module.css';
import clsx from 'clsx';
import { testSiteIsMicroSite } from '@utils';

/** Iframe displays a web content for a group resource url. */
const CommunityResource = ({ resourceUrl }: ICommunityResourceProps) => {
	const { user } = useMe();
	const isMicroSiteUrl = testSiteIsMicroSite(resourceUrl);

	const preparedUrl = new URL(resourceUrl);
	preparedUrl.searchParams.append('user_id', user.id);
	if (!preparedUrl.searchParams.get('pwd')) {
		preparedUrl.searchParams.append('pwd', BASE_STATIC_WEB_PAGE_PASSWORD);
	}

	return (
		<div className={clsx(isMicroSiteUrl ? styles.communityResource__microSiteIframe : styles.container__iframe)}>
			<iframe
				allowFullScreen={false}
				className={styles.communityResource__iframe}
				sandbox="allow-scripts allow-modal allow-downloads allow-popups allow-same-origin"
				src={resourceUrl}
				title="Community resources"
			/>
		</div>
	);
};

export interface ICommunityResourceProps {
	/** External url of a community resource. TCommunity['resourceUrl'] */
	resourceUrl: string;
}

export default memo(CommunityResource);
