import { Page } from '../../components';
import { useTranslation, useMe } from '@hooks';
import BaseStaticIFrame from '@ui-modules/account/components/BaseStaticIFrame';
import { SUPPORT_APP_TUTORIALS_LINK } from '@constants';
import styles from './SupportAppTutorialsPage.module.css';
import { BackLinkPageWrapper } from '../../components/BackLinkPageWrapper';

const SupportAppTutorialsPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();

	return (
		<Page title={t('Support App Tutorials')}>
			<div className={styles.container}>
				<BackLinkPageWrapper>
					<BaseStaticIFrame title={t('Support App Tutorials')} url={SUPPORT_APP_TUTORIALS_LINK} userId={user.id} />
				</BackLinkPageWrapper>
			</div>
		</Page>
	);
};

export default SupportAppTutorialsPage;
