import { GLOBAL_SEARCH_DEFINITION } from '@constants';
import { pluralizeUtils, startCase } from '@utils';

export const transformSearchDefinition = (definition?: string, isPlural?: boolean, titleMod?: boolean) => {
	const learnOrCategory =
		definition === GLOBAL_SEARCH_DEFINITION.learn || definition === GLOBAL_SEARCH_DEFINITION.calendar;
	if (learnOrCategory && !titleMod) return `${definition} items`;
	if (learnOrCategory) return definition;

	const title = String(startCase(definition));
	return isPlural ? pluralizeUtils.plural(title) : title;
};
