import { useService, useMutation, useMe, useNotification, useTranslation, useCheckTextOnMaliciousUrls } from '@hooks';
import type { TProfile, TProfileWithUser, TFullUser } from '@typings';
import { difference, map, uniq } from '@utils';

export const useUpdateProfileMutation = () => {
	const api = useService('ApiService');
	const analytics = useService('AnalyticsService');
	const reactQuery = useService('ReactQueryService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();
	const { profile, user } = useMe();

	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });

	const mutationResult = useMutation<TProfileWithUser, Error, Partial<TProfile>, { initialProfile: TProfile }>(
		['profile.updateProfile'],
		async function (patchedProfile) {
			if (patchedProfile.links?.length) {
				await checkTextOnMaliciousUrls(map(patchedProfile.links, 'link').join(' '));
			}
			return await api.profile.updateProfile(profile.id, {
				...(profile as TProfile),
				...patchedProfile,
				hideProperties: patchedProfile?.hideProperties
					? uniq(patchedProfile.hideProperties)
					: uniq(profile.hideProperties),
			});
		},
		{
			onMutate: () => {
				return { initialProfile: profile };
			},
			onSuccess: (newProfile, patchedProfile, context) => {
				showSuccess({ title: t('Success'), subtitle: t('Your profile has been updated.') });

				reactQuery.queryClient.setQueryData<TFullUser | undefined>(reactQuery.queryKeys.getMe(), (oldData) => {
					if (oldData) {
						const newUser: TFullUser = {
							...oldData,
							profile: {
								...oldData.profile,
								...newProfile,
								user: oldData.profile.user,
							},
						};
						return newUser;
					} else reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getMe());
				});

				for (const patchedValue in patchedProfile) {
					if (patchedValue === 'hideProperties') {
						const initialHideProperties = context?.initialProfile?.hideProperties ?? [];
						const newHideProperties = patchedProfile.hideProperties ?? [];
						const hiddenProperties = difference(newHideProperties, initialHideProperties);
						const shownProperties = difference(initialHideProperties, newHideProperties);

						if (hiddenProperties.length) {
							analytics.trackEvent('AccountInteractions', {
								interaction_type: 'Profile',
								action: `${JSON.stringify(hiddenProperties)} properties were hidden`,
							});
						}
						if (shownProperties.length) {
							analytics.trackEvent('AccountInteractions', {
								interaction_type: 'Profile',
								action: `${JSON.stringify(shownProperties)} properties were shown`,
							});
						}
					} else {
						analytics.trackEvent('AccountInteractions', {
							interaction_type: 'Profile',
							action: `value ${patchedValue} changed to ${JSON.stringify(
								patchedProfile[patchedValue as keyof typeof patchedProfile],
							)}`,
						});
					}
				}
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	return { ...mutationResult, profile, user };
};
