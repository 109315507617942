import { useCallback, useNavigate, useService } from '@hooks';
import { buildNotificationUrl } from '@ui-modules/notifications/utils/buildNotificationUrl';
import { FeedNotifierEmitter } from '@ui-modules/feed/components/FeedNotifier/FeedNotifierEmitter';
import type { INotificationFeedActivity } from '@typings';

export const useOpenNotification = () => {
	const navigate = useNavigate();
	const reactQuery = useService('ReactQueryService');
	const feedNotifierEmitter = FeedNotifierEmitter.useEmitter();

	return useCallback((notificationData: INotificationFeedActivity['data'], callback?: () => void) => {
		const notificationUrl = buildNotificationUrl(notificationData);
		if (notificationUrl) {
			const { url, external } = notificationUrl;
			if (external) {
				return window.open(url, 'blank');
			}

			if (notificationUrl.type === 'post' && notificationUrl.destination) {
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getSingleActivity(notificationUrl.destination));
				reactQuery.queryClient.removeQueries(
					reactQuery.queryKeys.getPostActivityReactions(notificationUrl.destination),
				);
				feedNotifierEmitter.refreshActivity(notificationUrl.destination);
			} else if (
				notificationUrl.type === 'asset_allocation_aggregation_group' ||
				notificationUrl.type === 'asset_allocation_aggregation_chapter' ||
				notificationUrl.type === 'asset_allocation_aggregation_network'
			) {
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getAssetAllocationCommunities());
			}

			setTimeout(() => {
				navigate(url);
				callback?.();
			}, 500);
		}
	}, []);
};
