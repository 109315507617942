import { FixedWidthContainer, ActivityIndicator, Gap } from '@ui-kit';
import FilesWidgetControls from './FilesWidget.Controls';
import FilesWidgetHeader from './FilesWidget.Header';
import FilesWidgetEmptyState from './FilesWidget.EmptyState';
import FilesSectionList from '../FilesSectionList';
import DeleteFileModal from './modals/DeleteFileModal';
import { useFileWidgetMeatballMenu } from './useFileWidgetMeatballMenu';
import type { TFile, TFolder } from '@typings';
import type { IFilesWidgetApiCallbacks } from './FilesWidgetApiCallbacks.interface';

/** Combines tools to display list of files and folders for a certain deal & toolbar to make changes with them or search. */
const FilesWidget = ({
	files = [],
	folders = [],
	currentFolder,
	isLoading,
	isRootFolder,
	isFileUploading,
	canUploadFiles,
	canCreateFolders,
	canEditFolders,
	canDeleteFolders,
	canDeleteFile,
	canMakeFileViewOnly,
	canMakeFileDownloadable,
	onPickAndUploadFile,
	onOpenFile,
	onSetViewOnlyFileStatus,
	onDeleteFile,
	onCreateFolder,
	onOpenFolder,
	onRenameFolder,
	onDeleteFolder,
	makeFolderLink,
}: IFilesWidgetProps) => {
	const [makeFileMenuOptions, { deletingFileId, setDeletingFileId }] = useFileWidgetMeatballMenu({
		canMakeFileViewOnly,
		canMakeFileDownloadable,
		canDeleteFile,
		onSetViewOnlyFileStatus,
	});

	if (isLoading) {
		return <ActivityIndicator size="medium" type="fit" />;
	} else if (isRootFolder && !isLoading && !files.length && !folders.length) {
		return (
			<>
				<FixedWidthContainer autoSize>
					<Gap gap={16} />
					<FilesWidgetControls
						canCreateFolders={canCreateFolders}
						canDeleteFolders={canDeleteFolders}
						canUploadFiles={canUploadFiles}
						folder={currentFolder}
						hideUploadButton
						isFileUploading={isFileUploading}
						onCreateFolder={onCreateFolder}
						onDeleteFolder={onDeleteFolder}
						onPickAndUploadFile={onPickAndUploadFile}
					/>
				</FixedWidthContainer>
				<FilesWidgetEmptyState
					canUploadFiles={canUploadFiles}
					isFileUploading={isFileUploading}
					onPickAndUploadFile={onPickAndUploadFile}
				/>
				;
			</>
		);
	}
	return (
		<>
			<FixedWidthContainer autoSize>
				<FilesWidgetHeader
					canEditFolders={canEditFolders}
					folder={currentFolder}
					isRootFolder={isRootFolder}
					makeFolderLink={makeFolderLink}
					onRenameFolder={onRenameFolder}
				/>
				<FilesWidgetControls
					canCreateFolders={canCreateFolders}
					canDeleteFolders={canDeleteFolders}
					canUploadFiles={canUploadFiles}
					folder={currentFolder}
					isFileUploading={isFileUploading}
					onCreateFolder={onCreateFolder}
					onDeleteFolder={onDeleteFolder}
					onPickAndUploadFile={onPickAndUploadFile}
				/>
			</FixedWidthContainer>
			<FilesSectionList
				files={files}
				folderId={currentFolder?.id ?? null}
				folders={folders}
				isLoading={isLoading}
				makeFileMenuOptions={makeFileMenuOptions}
				onOpenFile={onOpenFile}
				onOpenFolder={onOpenFolder}
			/>
			{deletingFileId ? (
				<DeleteFileModal
					fileId={deletingFileId}
					visible={!!deletingFileId}
					onClose={() => setDeletingFileId(null)}
					onDeleteFile={onDeleteFile}
				/>
			) : null}
		</>
	);
};

export interface IFilesWidgetProps extends IFilesWidgetApiCallbacks {
	/** Files list. */
	files: TFile[];
	/** Folders list. */
	folders: TFolder[];
	/** Current folder. */
	currentFolder: TFolder | undefined;
	/** Is current folder root. */
	isRootFolder: boolean;

	/** Is data loading. */
	isLoading: boolean;
	/** Whether some file is uploading or not. */
	isFileUploading: boolean;

	/** Whether the current user can upload a file to the current folder or not. */
	canUploadFiles: boolean;
	/** Whether the current user can create a folder within the current one or not. */
	canCreateFolders: boolean;
	/** Whether the current user can rename current folder or not. */
	canEditFolders: boolean;
	/** Whether the current user can delete current folder or not. */
	canDeleteFolders: boolean;

	/** Whether the current user can make a file view only or not. */
	canMakeFileViewOnly: (file: TFile) => boolean;
	/** Whether the current user can make a file downloadable or not. */
	canMakeFileDownloadable: (file: TFile) => boolean;
	/** Whether the current user can delete a file or not. */
	canDeleteFile: (file: TFile) => boolean;

	/** Creates an URL for a folder to navigate via anchor link. */
	makeFolderLink: (folderId: TFolder['id'] | null) => string;
}

export default FilesWidget;
