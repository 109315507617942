import { Page, FormPageLayout } from '../../components';
import { ActivityIndicator, EmptyStateMessage } from '@ui-kit';
import EditDietaryRestrictionsForm from '@ui-modules/profile/components/EditDietaryRestrictionsForm';
import { useTranslation, useCallback } from '@hooks';
import { useUpdateProfileMutation } from '@ui-modules/profile/hooks/useUpdateProfileMutation';
import { useDietaryRestrictionTypesQuery } from '@ui-modules/profile/hooks/useDietaryRestrictionTypesQuery';
import { ROUTES } from '@constants';
import { pick } from '@utils';
import styles from './EditDietaryRestrictionsPage.module.css';
import type { IEditDietaryRestrictionsFormProps } from '@ui-modules/profile/components/EditDietaryRestrictionsForm';
import type { TDietaryRestrictions } from '@typings';

const EditDietaryRestrictionsPage = () => {
	const { t } = useTranslation();

	const { profile, mutateAsync } = useUpdateProfileMutation();
	const dietaryRestrictionTypesQuery = useDietaryRestrictionTypesQuery({
		select: useCallback(
			(data: Array<{ name: string }>) =>
				data.sort((a, b) => a.name.localeCompare(b.name)).map(({ name }) => ({ label: name, value: name })),
			[],
		),
	});

	const initialDietaryRestrictions = pick(profile, ['dietaryRestrictions', 'dietaryRestrictionsCustom']);
	const onSubmit = async (dietaryRestrictions: TDietaryRestrictions) => {
		return await mutateAsync({ ...dietaryRestrictions });
	};

	return (
		<Page title={t('Edit dietary restrictions')}>
			{dietaryRestrictionTypesQuery.isLoading ? (
				<ActivityIndicator />
			) : dietaryRestrictionTypesQuery.isError ? (
				<EmptyStateMessage text={t('Something went wrong')} />
			) : (
				<EditDietaryRestrictionsForm
					dietaryRestrictionTypes={dietaryRestrictionTypesQuery.data ?? []}
					initialValues={initialDietaryRestrictions}
					Wrapper={EditDietaryRestrictionsFormWrapper}
					onSubmit={onSubmit}
				/>
			)}
		</Page>
	);
};

/** To display FormPageLayout which handles Discard Changes.
 * Should be separate component to avoid breaking of Discard Changes useBlocker hook because of rerenders.
 */
const EditDietaryRestrictionsFormWrapper: Required<IEditDietaryRestrictionsFormProps>['Wrapper'] = ({
	formProps,
	children,
}) => {
	const { t } = useTranslation();

	return (
		<FormPageLayout
			allowedNextPaths={[ROUTES.editDietaryRestrictions()]}
			contentContainerStyle={styles.editDietaryRestrictionsPage}
			formProps={formProps}
			headerTitle={t('Edit Dietary Restrictions')}
			saveTrackingName="profile--edit-dietary-restrictions"
			scrollEnabled
		>
			<div className={styles.editDietaryRestrictionsPage__body}>{children}</div>
		</FormPageLayout>
	);
};

export interface IEditDietaryRestrictionsPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditDietaryRestrictionsPage;
