import { BackIcon, Button } from '@ui-kit';
import { useHandleBackButton, useTranslation } from '@hooks';
import styles from './BackLinkPageWrapper.module.css';
import type { PropsWithChildren } from 'react';
import { clsx } from '@utils';

/** Wraps page and shows button with "Back" link. */
const BackLinkPageWrapper = ({ children, backButtonType, limitedWidth = false }: IBackLinkPageWrapperProps) => {
	const { t } = useTranslation();
	const { onBackButtonPress } = useHandleBackButton();

	const arrowButton = (
		<button className={styles.backLinkPageWrapper__arrowButton} onClick={onBackButtonPress}>
			<BackIcon className={styles.backLinkPageWrapper__buttonIcon} height={16} width={16} />
		</button>
	);

	return (
		<div className={styles.backLinkPageWrapper__wrapper}>
			<div
				className={clsx(styles.backLinkPageWrapper__content, limitedWidth && styles.backLinkPageWrapper__contentWidth)}
			>
				{children}
				<div className={styles.buttonContainer}>
					<div className={styles.buttonContent}>
						{backButtonType === 'arrow' ? (
							arrowButton
						) : (
							<Button
								icon={<BackIcon fill="#333333" height={14} width={13} />}
								iconPosition="left"
								title={t('Back')}
								type="outline"
								onClick={onBackButtonPress}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

interface IBackLinkPageWrapperProps extends PropsWithChildren {
	backButtonType?: 'default' | 'arrow';
	limitedWidth?: boolean;
}

export default BackLinkPageWrapper;
