import { useService } from '@hooks';

export const useDefaultSignInLogin = () => {
	const { signIn, useBindActionCreators, useAppSelector } = useService('ReduxService');

	const defaultLogin = useAppSelector((state) => state.signIn.defaultLogin);
	const { setDefaultLogin, resetDefaultLogin } = useBindActionCreators(signIn);

	return { defaultLogin, setDefaultLogin, resetDefaultLogin };
};
