import { ROUTES } from '@constants';
import { useNavigate } from '@hooks';

export const useHandleBackButton = () => {
	const navigate = useNavigate();

	// Check if first route in stack then navigate to Home screen in other case navigate back.
	const onBackButtonPress = () => {
		if (window.history.length > 1) {
			navigate(-1);
		} else {
			navigate(ROUTES.home(), { replace: true });
		}
	};

	return { onBackButtonPress };
};
