import React, { useState } from 'react';
import type { ReactNode } from 'react';

import { useEffect } from '@hooks';

import CheckBoxParent from './CheckBoxParent';
import styles from './CheckboxNestedInput.module.css';

import type { TChildRender } from '@ui-modules/deals/components/CheckBoxChild/CheckBoxChild';

const CheckboxNestedInput = ({
	checkMarks,
	childrenDisabled: disabled,
	receiveChanges,
	isCheckMarkLink = false,
	handleLinkPress,
	renderChild,
	shouldHaveOneSelected = false,
	childrenDefaultValue,
	showHorizontalRule = false,
}: ICheckboxNestedInput) => {
	const [checkMarksState, setCheckMarksState] = useState<ICheckmarkState[]>(checkMarks);

	useEffect(() => {
		setCheckMarksState(checkMarks);
	}, [checkMarks]);

	const selectedCount = checkMarks?.filter((obj) => obj.value).length;
	const countTrueChildren = (nodes: ICheckmarkState[]): number => {
		let count = 0;
		for (const node of nodes) {
			if (node.children) {
				count += countTrueChildren(node.children);
			}
			if (node.value) {
				count += node.children?.filter((child) => child.value)?.length ?? 0;
			}
		}
		return count;
	};

	const handleParentPress = (id: string) => {
		// Map over each item in prevState and update the item that corresponds to the pressed id
		const updatedCheckmarkList = checkMarks.map((item) => {
			if (item.id === id) {
				// Suppressing sonarjs warning, because it takes the default value | undefined, but it's affected through props.
				// eslint-disable-next-line sonarjs/no-all-duplicated-branches
				const newValue = shouldHaveOneSelected && selectedCount === 1 ? !item.value : !item.value;
				const updatedChildren = item?.children?.map((child) => {
					// Determine the new value for children based on childrenDefaultValue or the parent's new value
					let childNewValue;
					if (childrenDefaultValue !== undefined) {
						// Use childrenDefaultValue if provided
						childNewValue = childrenDefaultValue;
					} else {
						// Otherwise, toggle based on the parent's new state or special conditions
						childNewValue = shouldHaveOneSelected && selectedCount === 1 ? true : newValue;
					}

					return {
						...child,
						value: childNewValue,
					};
				});

				return {
					...item,
					value: newValue,
					children: updatedChildren,
				};
			}
			// Otherwise, return the item unchanged
			return item;
		});

		receiveChanges?.(updatedCheckmarkList);
		setCheckMarksState(updatedCheckmarkList);
	};

	const handleChildPress = (id: string) => {
		// Map over each item in prevState and update the item that corresponds to the parent id
		const updatedCheckmarkList = checkMarks.map((item) => {
			const updatedChildren = item?.children?.map((child) => {
				if (child.id === id) {
					// If the child is the pressed child, update its value
					return {
						...child,
						value: shouldHaveOneSelected && countTrueChildren(checkMarks) === 1 ? true : !child.value,
					};
				}
				// Otherwise, return the child unchanged
				return child;
			});

			if (item?.children?.find((child) => child.id === id)) {
				const allNotMarked = updatedChildren?.every((childItem) => !childItem.value);

				item.value = !allNotMarked;
			}

			return {
				...item,
				children: updatedChildren,
			};
		});
		// When a child checkbox is pressed, update the state of checkMarks
		receiveChanges?.(updatedCheckmarkList);
		setCheckMarksState(updatedCheckmarkList);
	};

	return (
		<div className={styles.container}>
			{!!checkMarksState?.length &&
				checkMarksState.map((item) => {
					const anyChildSelected = item.children?.some((child) => child.value) ?? false;
					const allChildSelected = item.children?.length ? item.children?.every((child) => child.value) : false;

					return (
						<div className={styles.parentCheckbox} key={item.label}>
							<CheckBoxParent
								{...item}
								allChildSelected={allChildSelected}
								checkMarksState={checkMarksState}
								disabled={disabled}
								handleLinkPress={handleLinkPress}
								handleParentPress={handleParentPress}
								isCheckMarkLink={isCheckMarkLink}
								setCheckMarksState={setCheckMarksState}
								showHorizontalRule={showHorizontalRule && anyChildSelected}
							/>

							{(item.value || item.children?.some((child) => child.value)) && !!item.children?.length && (
								<div className={styles.parentCheckbox__children}>
									{item.children?.map((child) => (
										<div className={styles.checkBoxChild} key={child.id}>
											{renderChild?.({ parent: item, child, handleChildPress })}
										</div>
									))}
								</div>
							)}
						</div>
					);
				})}
		</div>
	);
};

export interface ICheckmarkState {
	label: string;
	id: string;
	value: boolean;
	joined?: boolean;
	linkPressPayload?: string;
	children?: ICheckmarkState[] | null;
}

interface ICheckboxNestedInput {
	checkMarks: ICheckmarkState[];
	isCheckMarkLink?: boolean;
	shouldHaveOneSelected?: boolean;
	childrenDisabled?: boolean;
	renderChild?: ({ child, parent, handleChildPress }: TChildRender) => ReactNode;
	receiveChanges?: (checkMarksState: ICheckmarkState[]) => void;
	handleLinkPress?: (networkId: string) => void;
	childrenDefaultValue?: boolean;
	showHorizontalRule?: boolean;
}

export default CheckboxNestedInput;
