import { translateUserRole } from '@utils';
import type { TUser } from '@typings';
import type { TFunction } from 'i18next';
import type { StreamMessage } from 'stream-chat-react';

export const formatChatUserName = (t: TFunction) => (message: StreamMessage) => {
	if (message.user) {
		const userRoles = message.user.roles as TUser['roles'];
		if (userRoles.includes('ROLE_INACTIVE')) {
			return translateUserRole(userRoles, t);
		}
		return message.user.name || message.user.id;
	} else return '';
};
