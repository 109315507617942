import type { TNetworkConnectionMemberResponseItem, TUserProfileRecord } from '@typings';

export const mapNetworkConnectionMemberResponseItemToProfileRecords = (
	memberResponseItem: TNetworkConnectionMemberResponseItem,
): TUserProfileRecord => {
	const owner = memberResponseItem.owner;
	const [firstName, ...lastNames] = owner.fullName.split(' ');
	return {
		id: owner.id,
		roles: owner.roles,
		firstName: firstName,
		lastName: lastNames.join(' '),
		communitiesForBadge: owner.communitiesForBadge,
		slug: 'MOCK_SLUG',
		avatar: owner.avatarUrl
			? {
					'@id': 'MOCK_AVATAR_ID',
					'@type': 'MOCK_AVATAR_TYPE',
					id: 'MOCK_AVATAR_ID',
					contentUrl: owner.avatarUrl,
			  }
			: null,
		profile: {
			id: owner.profileId,
			'@type': owner.profileType,
			title: owner.profileTitle,
		},
	};
};
