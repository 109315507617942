import { useService, useMutation, useNotification, useTranslation } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TPostNetworkConnectionFilterDTO } from '@tiger21-llc/connect-shared/src/typings/api/PostNetworkConnectionUserFilterDto.type';

export const useUpdateNetworkConnectionUserFilterMutation = (
	options?: MutationOptions<TPostNetworkConnectionFilterDTO, Error, TPostNetworkConnectionFilterDTO>,
) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { showSuccess } = useNotification();

	return useMutation<TPostNetworkConnectionFilterDTO, Error, TPostNetworkConnectionFilterDTO>(
		['updateNetworkConnectionUserFilter'],
		async (params) =>
			await api.networkConnectionUserFilter.putUserFilter(params.community, params.owner, params.filters),
		{
			onSuccess: async (response, variables, context) => {
				const networkConnectionId = variables?.community?.replace('/api/communities/', '');
				reactQuery.queryClient.removeQueries(reactQuery.queryKeys.getNetworkConnections(networkConnectionId));
				await reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getNetworkConnections(networkConnectionId));
				await reactQuery.queryClient.refetchQueries(
					reactQuery.queryKeys.getNetworkConnectionUserFilters(networkConnectionId),
				);
				showSuccess({ title: t('Success'), subtitle: t('Successfully updated the filters') });
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				options?.onError?.(error, variables, context);
			},
		},
	);
};
