import { useMyTimezone, useTranslation } from '@hooks';
import type { TEventCompact, TProfileEvent } from '@typings';
import { dayjs } from '@utils';
import styles from './EventCard.module.css';
import defaultBanner from '/assets/images/default-event-banner.jpeg';

const EventCard = <T extends TEventCompact | TProfileEvent = TEventCompact>({ event, onPress }: IEventCardProps<T>) => {
	const { t } = useTranslation();
	const { formattedTimezone } = useMyTimezone();
	const range = t('from {{from}} to {{to}}', {
		from: dayjs(event.startDate).format('MMM D YYYY, h:mmA'),
		to: dayjs(event.endDate).format('MMM D YYYY, h:mmA') + ' ' + formattedTimezone,
	});
	const bannerImage = ('banner' in event ? event.banner?.contentUrl : event.bannerUrl) || defaultBanner;

	return (
		<button className={styles.eventCard} onClick={() => onPress(event)}>
			<img alt={`${event.title}_logo`} className={styles.eventCard__image} src={String(bannerImage)} />
			<div className={styles.eventCard__textContainer}>
				<span className={styles.eventCard__title}>{event.title}</span>
				<span className={styles.eventCard__range}>{range}</span>
			</div>
		</button>
	);
};

export interface IEventCardProps<T extends TEventCompact | TProfileEvent = TEventCompact> {
	event: T;
	onPress: (event: T) => void;
}

export default EventCard;
