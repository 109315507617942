import AsyncSelect from 'react-select/async';
import type { InputActionMeta } from 'react-select';
import { components } from 'react-select';
import { useService } from '@services';
import { useEffect, useMe, useState, useTranslation } from '@hooks';
import { clsx, translateUserRole } from '@utils';
import { ProfileEntity } from '@ui-modules/profile/utils/ProfileEntity';
import { Avatar, CloseIcon, InputLabel, SearchIcon } from '@ui-kit';
import { MIN_SEARCH_LIMIT } from '@constants';
import styles from './UserSearchAutocomplete.module.css';
import type { TCommunity, TFullUser, TCompactUser } from '@typings';
import type { FocusEventHandler } from 'react';

const UserSearchAutocomplete = ({
	defaultValue = null,
	placeholder,
	disabled,
	onSelect,
	label,
	isClearable,
	indicateDisabledDealsUsers,
	showFormerAndInactiveUsers = false,
	showSearchIcon,
	communityId,
	isErrored,
	indicateNetworkUsers,
	onBlur,
}: IUserSearchAutocomplete) => {
	const { t } = useTranslation();
	const api = useService('ApiService');
	const { user: meUser } = useMe();
	const [input, setInput] = useState('');

	const [selectedValue, setSelectedValue] = useState<IAutocompleteSearchUser | null>(defaultValue);

	useEffect(() => {
		setSelectedValue(defaultValue);
	}, [defaultValue]);

	const loadOptions = async (inputValue: string) => {
		if (inputValue.length >= MIN_SEARCH_LIMIT) {
			let data: Array<TCompactUser | TFullUser> = [];
			if (indicateDisabledDealsUsers) {
				data = await api.user.getPostBehalfDealUsers(inputValue, communityId);
			} else {
				data = await api.user.getUsers(inputValue, communityId);
			}
			if (!showFormerAndInactiveUsers) {
				data = data.filter((user) => !user.roles.includes('ROLE_FORMER') && !user.roles.includes('ROLE_INACTIVE'));
			}
			const users = data.map((user) => {
				const dealsEnabled = ProfileEntity.isInvestFeatureAvailable(user);
				const userRole = translateUserRole(user.roles, t);
				return {
					id: user['@id'],
					fullName:
						(user as TCompactUser)?.fullName || `${(user as TFullUser)?.firstName} ${(user as TFullUser)?.lastName}`,
					avatar: user.avatar?.contentUrl,
					role:
						indicateDisabledDealsUsers && !dealsEnabled && !indicateNetworkUsers
							? t('{{role}} (INELIGIBLE - DEALS DISABLED)', { role: userRole })
							: userRole,
					enabled: !!indicateNetworkUsers || dealsEnabled,
				};
			});
			const displayUsers = indicateDisabledDealsUsers ? users.filter((user) => user.id !== meUser['@id']) : users;
			return displayUsers as IAutocompleteSearchUser[];
		}
		return [];
	};

	return (
		<div>
			{!!label && <InputLabel text={label} />}
			<AsyncSelect
				cacheOptions
				components={{
					DropdownIndicator: (props) =>
						!selectedValue && showSearchIcon ? (
							<components.DropdownIndicator {...props}>
								<SearchIcon className={styles.input__iconSvg_search} height={12} width={12} />
							</components.DropdownIndicator>
						) : null,
					IndicatorSeparator: undefined,
					LoadingIndicator: undefined,
					Option: (props) => {
						const isDisabled = !!indicateDisabledDealsUsers && !props.data?.enabled;
						return (
							<components.Option {...props}>
								<div className={styles.userSearch} key={props.data?.id}>
									<Avatar
										className={clsx(isDisabled && styles.userSearch__avatar_disabled)}
										imageUri={props.data?.avatar}
										outline={props.data?.role === 'Chair'}
										size={30}
										title={props.data?.fullName}
									/>
									<div className={styles.userSearch__info}>
										<span
											className={clsx(styles.userSearch__fullName, isDisabled && styles.userSearch__fullName_disabled)}
										>
											{props.data?.fullName}
										</span>
										<span className={styles.userSearch__role}>{props.data?.role}</span>
									</div>
								</div>
							</components.Option>
						);
					},
					ClearIndicator: (props) =>
						isClearable ? (
							<components.ClearIndicator {...props}>
								<CloseIcon className="react-select__close-icon" fill="black" height={10} width={10} />
							</components.ClearIndicator>
						) : null,
				}}
				defaultOptions
				getOptionLabel={(option) => option?.fullName || defaultValue?.fullName || ''}
				getOptionValue={(option) => option?.id || defaultValue?.id || ''}
				inputValue={input}
				isClearable={isClearable}
				isDisabled={disabled}
				isOptionDisabled={(option) => !!indicateDisabledDealsUsers && !option?.enabled}
				loadOptions={loadOptions}
				openMenuOnClick={false}
				placeholder={placeholder}
				styles={{
					container: (base) => ({
						...base,
						// border: '1px solid var(--color-primaryGrey)',
						borderRadius: 2,
						'&:focus-visible': {
							outline: 'none',
						},
						'&:hover': {
							borderColor: 'var(--color-lightGold)',
						},
					}),
					input: (base) => ({
						...base,
						'&:focus-visible': {
							outline: 'none',
						},
					}),
					control: (base) => ({
						...base,
						minHeight: 42,
						border: isErrored ? '2px solid var(--color-error)' : '1px solid var(--color-primaryGrey)',
						backgroundColor: isErrored ? 'var(--color-errorLight)' : ' var(--color-white)',
						// border: 'none',
						outline: 'none',
						'&:hover': {
							borderColor: 'var(--color-lightGold)',
						},
					}),
					option: (base) => ({
						...base,
						border: 'none',
						backgroundColor: 'var(--color-white)',
						borderBottom: '1px solid var(--color-divider)',
						'&:hover': {
							border: 'none',

							backgroundColor: 'var(--color-lighterGrey)',
						},
					}),
					menu: () => ({
						margin: 0,
						boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.12)',
					}),
					menuList: () => ({
						margin: 0,
						backgroundColor: 'var(--color-white)',
					}),
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 2,
					border: 'none',
					colors: {
						...theme.colors,
						primary: 'var(--color-lightGold)',
					},
				})}
				value={selectedValue}
				onBlur={onBlur}
				onChange={(value: IAutocompleteSearchUser | null) => {
					setSelectedValue(value);
					onSelect?.(value as IAutocompleteSearchUser);
				}}
				onInputChange={(value: string, actionMeta: InputActionMeta) => {
					if (actionMeta?.action !== 'menu-close') setInput(value);
				}}
			/>
		</div>
	);
};

export interface IAutocompleteSearchUser {
	fullName: string;
	role: string;
	avatar: string | undefined;
	id: string;
	enabled: boolean;
}

interface IUserSearchAutocomplete {
	defaultValue?: IAutocompleteSearchUser | null;
	placeholder?: string;
	disabled?: boolean;
	onSelect?: (value: IAutocompleteSearchUser) => void;
	isClearable?: boolean;
	label?: string;
	showSearchIcon?: boolean;
	indicateDisabledDealsUsers?: boolean;
	showFormerAndInactiveUsers?: boolean;
	communityId?: TCommunity['id'];
	isErrored?: boolean;
	indicateNetworkUsers?: boolean;
	onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
}

export default UserSearchAutocomplete;
