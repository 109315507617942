import { clsx } from '@utils';
import styles from './AssetAllocationEmptyState.module.css';
import type { FC, PropsWithChildren } from 'react';

const AssetAllocationEmptyState = ({
	title,
	description,
	children,
	footerMessage = '',
	IconComponent,
}: IAssetAllocationEmptyStateProps) => {
	return (
		<div className={styles.assetAllocationEmptyState}>
			<IconComponent className={styles.assetAllocationEmptyState__icon} />
			<h3>{title}</h3>
			<span>{description}</span>
			{children}
			<span className={clsx(styles.assetAllocationEmptyState__notificationEnabledMessage)}>{footerMessage}</span>
		</div>
	);
};

export interface IAssetAllocationEmptyStateProps extends PropsWithChildren {
	IconComponent: FC<React.SVGProps<SVGSVGElement>>;
	/** Bold title of the empty state */
	title: string;
	/** Description of the empty state */
	description: string;
	/** Italic footer message under the children */
	footerMessage?: string;
}

export default AssetAllocationEmptyState;
