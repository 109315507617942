import { useService, useMutation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TNetworkConnection, TFile, TFolder } from '@typings';

export const useDeleteNetworkConnectionFileMutation = (
	networkConnectionId: TNetworkConnection['id'],
	folderId: TFolder['id'] | null,
	networkConnectionTitle?: TNetworkConnection['title'],
	networkConnectionsName?: string,
	options?: MutationOptions<void, Error, TFile['id']>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');
	const { showUnknownError } = useNotification();

	return useMutation<void, Error, TFile['id']>(
		['networkConnectionFile.deleteNetworkConnectionFile'],
		async (fileId) => await api.networkConnectionFile.deleteNetworkConnectionFile(fileId),
		{
			...options,
			async onSuccess(data, variables, context) {
				await reactQuery.queryClient.refetchQueries(
					reactQuery.queryKeys.getNetworkConnectionFiles(networkConnectionId, folderId),
				);
				if (networkConnectionTitle && networkConnectionsName)
					analytics.trackGenericEvent(
						'{{connectionName}}Interactions',
						{
							interaction_type: 'investor_files',
							interaction_action: 'files deleted',
							'{{connectionName}}_id': networkConnectionId,
							'{{connectionName}}_name': networkConnectionTitle,
						},
						{
							connectionName: networkConnectionsName,
						},
					);
				options?.onSuccess?.(data, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
