import { PROFILE_DETAILS_CUSTOM_LINK_LABELS } from '@constants';
import { useMemo, useTranslation } from '@hooks';
import { CheckboxInput, DropdownMenu, Input } from '@ui-kit';
import { clsx, translateProfileLinkLabelToTitle, useField } from '@utils';
import styles from './CustomLinkInputField.module.css';

const CustomLinkInputField = ({ title, name, withOptions = false }: ICustomLinkInputFieldProps) => {
	const { t } = useTranslation();

	const [{ value, onChange, onBlur }, { error }, { setValue }] = useField<TField>({ name, type: 'object' });
	const errorMessage = (error as any as TField)?.link;
	const linkLabelDropdownItems = useMemo(
		() =>
			PROFILE_DETAILS_CUSTOM_LINK_LABELS.map((label) => ({
				value: label,
				title: translateProfileLinkLabelToTitle(label, t),
			})),
		[],
	);

	return (
		<>
			<div className={clsx(styles.linkInput, withOptions && styles.linkInput__row)}>
				<Input
					autoCapitalize="none"
					errorMessage={errorMessage}
					label={<b>{title}</b>}
					value={value.link}
					onBlur={onBlur(`${name}].link`)}
					onChange={onChange(`${name}].link`)}
				/>
				{withOptions ? (
					<div className={styles.linkInput__dropMenu}>
						<DropdownMenu
							options={linkLabelDropdownItems}
							value={value.label}
							variant="medium"
							onChange={(option) => setValue({ ...value, label: String(option) })}
						/>
					</div>
				) : null}
			</div>
			<CheckboxInput
				label={t('Hide this')}
				size="medium"
				value={!value.showOnProfile}
				onChange={(bool) => setValue({ ...value, showOnProfile: !bool })}
			/>
		</>
	);
};

type TField = {
	link: string;
	label: string;
	showOnProfile: boolean;
};

export interface ICustomLinkInputFieldProps {
	name: string;
	title: string;
	withOptions?: boolean;
}

export default CustomLinkInputField;
