import { DealFormSection } from '../sections/DealFormSection';
import { useTranslation } from '@hooks';

import styles from './ThirdPartyDealForm.module.css';
import { Gap, Button } from '@ui-kit';

export const CreatorSection = ({ name, phone, email, published }: ICreatorSectionProps) => {
	const { t } = useTranslation();
	return (
		<DealFormSection>
			<h1 className={styles.formTitle}>
				{published
					? t('Please complete this form to update this Deal')
					: t('Please complete this form to add this Deal to the TIGER 21 Member community')}
			</h1>
			<Gap gap={24} />
			<div className={styles.creatorInfo}>
				<div>
					<strong>{t('From')}: </strong>
					<span>{t('{{name}}, TIGER 21 member', { name })}</span>
				</div>
				{phone && (
					<div>
						<Gap gap={16} />
						<strong>{t('Phone')}: </strong>
						<span>{phone}</span>
					</div>
				)}
				{email && (
					<>
						<Gap gap={16} />
						<div className={styles.emailButtonWrapper}>
							<strong>{t('Email')}: </strong>
							<Button
								className={styles.emailButton}
								title={email}
								titleClassName={styles.emailButtonTitle}
								type="clear"
								variant="small"
								onClick={() => window.open(`mailto:${email}`)}
							/>
						</div>
					</>
				)}
			</div>
			<Gap gap={24} />
			<p className={styles.optionalHint}>{t('All fields required unless marked “optional”')}</p>
		</DealFormSection>
	);
};

interface ICreatorSectionProps {
	name: string;
	phone?: string;
	email?: string;
	published: boolean;
}
