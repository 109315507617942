import { useQuery, useService, useLogger } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TAuthData } from '@tiger21-llc/connect-shared/src/typings/Auth.types';

const MINUTES_TO_REFETCH_BEFORE_EXPIRED_AT = 3;
const MS_TO_REFETCH_BEFORE_EXPIRED_AT = dayjs
	.duration(MINUTES_TO_REFETCH_BEFORE_EXPIRED_AT, 'minutes')
	.asMilliseconds();

export const useRefreshTokenRefetchingQuery = (options?: UseQueryOptions<TAuthData, Error, TAuthData>) => {
	const redux = useService('ReduxService');
	const okta = useService('OktaService');
	const logger = useLogger('useRefreshTokenRefetchingQuery');

	return useQuery<TAuthData, Error, TAuthData>(
		['auth.refreshToken'],
		async () => {
			const cachedAuthData = redux.store.getState().auth as TAuthData;
			if (!cachedAuthData || !cachedAuthData?.expiresAt || !cachedAuthData?.refreshToken) {
				logger.debug('No cached auth data found in the store. Will sign out.');
				throw new Error('No cached auth data found in the store.');
			}

			const nextRefreshIntervalMs = getNextRefreshTokenIntervalMs(cachedAuthData.expiresAt);
			const shouldRefresh = nextRefreshIntervalMs <= MS_TO_REFETCH_BEFORE_EXPIRED_AT;
			if (!shouldRefresh) {
				logger.debug(`Will not refresh token because it is too earlier.`);
				return cachedAuthData; // return cached data if it's too early to refresh (@see T21C-7353).
			}

			logger.debug('Refreshing token...');
			return await okta.refreshAccessToken();
		},
		{
			cacheTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(5, 'minutes').asMilliseconds(),
			retry: 1, // should overwrite default QueryClient retry which is responsible for running ['auth.refreshToken'] query when the 403 error occurs.
			retryDelay: dayjs.duration(3, 'seconds').asMilliseconds(),
			enabled: !!redux.store.getState().auth.refreshToken,
			refetchIntervalInBackground: true,
			refetchInterval(authData) {
				if ('SharedWorker' in window) {
					logger.debug('SharedWorker is supported. Will delegate refetching to SharedWorker.');
					return false;
				}
				if (!authData?.expiresAt) return false; // do not refetch if there is no token.

				logger.debug('SharedWorker is not supported. Will refetch token in the main thread as a fallback.');
				const nextRefreshIntervalMs = getNextRefreshTokenIntervalMs(authData.expiresAt);
				return nextRefreshIntervalMs;
			},
			...options,
			onError: async (error) => {
				options?.onError?.(error);
				await okta.signOut();
			},
		},
	);
};

function getNextRefreshTokenIntervalMs(tokenExpiresAtIso: TAuthData['expiresAt']) {
	const tokenExpiresAt = dayjs.unix(tokenExpiresAtIso);
	const refreshTokenAt = tokenExpiresAt.subtract(MINUTES_TO_REFETCH_BEFORE_EXPIRED_AT, 'minutes');
	const now = dayjs();
	const intervalMs = refreshTokenAt.diff(now, 'milliseconds');
	return intervalMs;
}
