import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from '@utils';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TUser, TProfile, TFullUser, TImpersonateTokenPayload, TUserMembership } from '@typings';

export interface IMeState {
	deviceId: string;
	user: TUser | null;
	profile: TProfile | null;
	impersonateToken: string | undefined;
	impersonatorEmail: string | undefined;
	memberships: TUserMembership[] | undefined;
}

const initialState: IMeState = {
	user: null,
	profile: null,
	impersonateToken: undefined,
	impersonatorEmail: undefined,
	memberships: [],
	deviceId: crypto.randomUUID().replace('-', ''), // restrict to 32 symbols because of OKTA issue E0000001 "length of deviceToken greater than 32 characters."
};

export const meSlice = createSlice({
	name: 'me',
	initialState,
	reducers: {
		saveAccount: (state, { payload: fullUser }: PayloadAction<TFullUser>) => {
			const { profile, memberships, ...user } = fullUser;
			state.user = user;
			state.profile = profile;
		},
		removeAccount: (state) => {
			state.user = initialState.user;
			state.profile = initialState.profile;
		},
		setImpersonateToken: (state, { payload: impersonateToken }: PayloadAction<string>) => {
			let impersonatorEmail: string | undefined;
			try {
				impersonatorEmail = jwtDecode<TImpersonateTokenPayload>(impersonateToken)?.impersonated_by?.email;
			} catch (error) {
				console.error('Failed to parse impersonate token', error);
			}

			state.impersonateToken = impersonateToken;
			state.impersonatorEmail = impersonatorEmail ?? 'unknown email';
		},
		removeImpersonateToken: (state) => {
			state.impersonateToken = initialState.impersonateToken;
			state.impersonatorEmail = initialState.impersonatorEmail;
		},
	},
});
