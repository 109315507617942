import { useDropzone, useNotification, useTranslation } from '@hooks';
import {
	COMMUNITY_DOCUMENTS,
	IMAGES_MIME_TYPES,
	VIDEOS_MIME_TYPE,
	COMMUNITY_DOCUMENTS_EXCLUDE_IMAGE,
	MAX_SIZE_FOR_UPLOAD,
} from '@constants';
import { fromEvent } from 'file-selector';
import { isHeic, heicTo } from 'heic-to';
import type { FileWithPath } from 'react-dropzone';

const MAX_FILE_SIZE_LIMIT = MAX_SIZE_FOR_UPLOAD * 1024 * 1024; // 100MB => If one file (not video) is more then 100MB stream return 413 error;

export const usePickUpAttachments = (
	uploadFile: (files: File[]) => void,
	onlyMediaAccepted?: boolean,
	onlyFilesAccepted?: boolean,
	maxSize?: number,
) => {
	const { t } = useTranslation();
	const { showError } = useNotification();

	const { open, getInputProps } = useDropzone({
		multiple: true,
		onDropRejected: (err) => {
			const errorIsRelatedToSize = err
				.map((item) => item.errors.map((err) => err.code === 'file-too-large'))
				.some((item, index) => !!item?.[index]);

			if (errorIsRelatedToSize) {
				showError({
					title: t('File size is larger than {{limit}}', { limit: '100MB' }),
					subtitle: t('Please use a post in the Discussions area instead'),
				});
			} else {
				showError({ title: t('Rejected'), subtitle: t('Not allowed file type') });
			}
		},
		async getFilesFromEvent(event) {
			const files = (await fromEvent(event)) as FileWithPath[];
			for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
				const file = files[fileIndex];
				if (await isHeic(file)) {
					const jpegFileBlob = await heicTo({ blob: file, type: 'image/jpeg', quality: 0.75 });
					files[fileIndex] = new File([jpegFileBlob], file.name, {
						lastModified: new Date().getTime(),
						type: 'image/jpeg',
					});
				}
			}
			return files;
		},
		onDrop: (acceptedFiles: File[]) => {
			const hasEmptyFiles = acceptedFiles.some((item) => item.size <= 0);
			if (hasEmptyFiles) {
				showError({
					title: t('The uploaded file appears to be empty.'),
					subtitle: t('Please ensure that the file you are uploading contains data.'),
				});
			}

			const hasLongNameFiles = acceptedFiles.some((item) => (item.name || '')?.length > 100);
			if (hasLongNameFiles) {
				showError({
					title: t('The file name is too long.'),
					subtitle: t('Please reduce the size of file name.'),
				});
			}

			const fileSizeLimit = acceptedFiles.some(
				(item) => item.size > MAX_FILE_SIZE_LIMIT && !item.type.includes('video'),
			);
			if (fileSizeLimit) {
				showError({
					title: t('File size is larger than {{limit}}', { limit: '100MB' }),
					subtitle: t('Please use a post in the Discussions area instead'),
				});
			}

			const availableFiles = acceptedFiles.filter(getIsFileAccepted);
			if (availableFiles?.length) {
				uploadFile(availableFiles);
			}
		},
		accept: getAcceptedFileTypes(onlyFilesAccepted, onlyMediaAccepted),
		maxSize,
	});

	return { openFilePicker: open, getInputProps };
};

const getIsFileAccepted = (item: File) =>
	item.size > 0 && item.name?.length < 100 && (item.type.includes('video') ? true : item.size < MAX_FILE_SIZE_LIMIT);

const acceptedFileTypes = [...COMMUNITY_DOCUMENTS_EXCLUDE_IMAGE.availableMimeTypes].reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});
const acceptedAllFileTypes = [...COMMUNITY_DOCUMENTS.availableMimeTypes, ...IMAGES_MIME_TYPES, VIDEOS_MIME_TYPE].reduce(
	(acc, type) => {
		return { ...acc, [type]: [] };
	},
	{},
);

const acceptedMediaTypes = [...IMAGES_MIME_TYPES, VIDEOS_MIME_TYPE].reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});

const getAcceptedFileTypes = (onlyFilesAccepted?: boolean, onlyMediaAccepted?: boolean) =>
	onlyFilesAccepted ? acceptedFileTypes : onlyMediaAccepted ? acceptedMediaTypes : acceptedAllFileTypes;
