import { useTranslation } from '@hooks';
import styles from './FourthPageBody.module.css';
import { ArrowRightIcon, Button, GlobeIcon, LocationIcon, MemberGroupIcon } from '@ui-kit';
import { AllocationsList } from '../AllocationsList';

const FourthPageBody = ({ onDismiss, onOpenMyAssetAllocation }: IFourthPageBodyProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<p>{t('Get personalized recommendations based on your allocation for...')}</p>
			<AllocationsList
				allocations={[
					{
						Icon: MemberGroupIcon,
						title: t('Members'),
						color: '#007D8A',
					},
					{
						Icon: LocationIcon,
						title: t('Networks'),
						color: '#8E723B',
					},
					{
						Icon: GlobeIcon,
						title: t('Articles'),
						color: '#333333',
					},
				]}
			/>

			<div className={styles.wrapper__ctaWrapper}>
				<Button
					className={styles.wrapper__ctaWrapperClose}
					title={t('Close')}
					titleClassName={styles.wrapper__ctaWrapperCloseTitle}
					type={'blurred'}
					onClick={() => {
						onDismiss();
					}}
				/>
				<Button
					icon={<ArrowRightIcon fill={'white'} height={14} width={14} />}
					iconPosition="right"
					title={t('Update My Allocation')}
					type={'dark'}
					onClick={() => {
						onOpenMyAssetAllocation();
					}}
				/>
			</div>
		</div>
	);
};

interface IFourthPageBodyProps {
	onDismiss: () => void;
	onOpenMyAssetAllocation: () => void;
}

export default FourthPageBody;
