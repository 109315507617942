import { useService, useQuery } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { StreamMessage } from 'stream-chat-react';

export const useChatMessageQuery = (
	chatMessageId: StreamMessage['id'],
	queryOptions?: UseQueryOptions<StreamMessage, Error>,
) => {
	const chatService = useService('ChatService');

	return useQuery<StreamMessage, Error>(
		['chatClient.getMessage', chatMessageId],
		async () => await chatService.getMessage(chatMessageId),
		{
			...queryOptions,
			enabled: !!chatMessageId,
		},
	);
};
