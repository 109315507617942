import { useService, useMutation, useNotification } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';
import type { TFlyoutResponse } from '@typings';

export const useFlyoutMutation = (
	options?: MutationOptions<TFlyoutResponse, Error, TFlyoutResponse['id']>,
	shouldRefetch = false,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');

	const { showUnknownError } = useNotification();

	return useMutation<TFlyoutResponse, Error, TFlyoutResponse['id']>(
		reactQuery.queryKeys.setFlyoutMessageAsRead(),
		async (id: TFlyoutResponse['id']) => await api.flyout.setFlyoutMessageAsRead(id),
		{
			onSuccess: (response, variables, context) => {
				options?.onSuccess?.(response, variables, context);
				shouldRefetch && reactQuery.queryClient.refetchQueries(reactQuery.queryKeys.getFlyoutMessageAsRead());
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);
};
