import { DEFAULT_CALENDAR_END_DATE } from '@constants';
import { useQueryClient, useService } from '@hooks';
import { dayjs } from '@utils';

export const useUploadCalendarItems = () => {
	const api = useService('ApiService');
	const queryClient = useQueryClient();
	const { calendar, useAppSelector, useBindActionCreators } = useService('ReduxService');
	const calendarItemsRange = useAppSelector((store) => store.calendar.calendarItemsRange);
	const { addCalendarItems } = useBindActionCreators(calendar);

	const performUploadCalendarItemsRequest = async (startDate: string, endDate: string) => {
		const startOfMonthDate = dayjs(startDate, 'YYYY-MM');
		const startUploadDate = startOfMonthDate.toISOString();

		const uploadedCalendarItems = await queryClient.fetchQuery(
			['calendarItem.getCalendarItems', startUploadDate, endDate],
			async () => await api.calendarItem.getCalendarItemsInRange(startUploadDate, endDate),
			{
				staleTime: 5000,
				cacheTime: 0,
			},
		);

		return uploadedCalendarItems;
	};

	const uploadCalendarItems = async (startDate: string, endDate: string = DEFAULT_CALENDAR_END_DATE) => {
		const [firstDate, lastDate] = calendarItemsRange;

		if (firstDate === lastDate) {
			const uploadedCalendarItems = await performUploadCalendarItemsRequest(startDate, endDate);
			addCalendarItems({ calendarItems: uploadedCalendarItems, calendarItemsRange: [startDate, endDate] });
		} else {
			if (dayjs(startDate).isBefore(dayjs(firstDate))) {
				const uploadedCalendarItems = await performUploadCalendarItemsRequest(startDate, firstDate);
				addCalendarItems({ calendarItems: uploadedCalendarItems, calendarItemsRange: [startDate, endDate] });
			}
			if (dayjs(endDate).isAfter(dayjs(lastDate))) {
				const uploadedCalendarItems = await performUploadCalendarItemsRequest(lastDate, endDate);
				addCalendarItems({ calendarItems: uploadedCalendarItems, calendarItemsRange: [startDate, endDate] });
			}
		}
	};

	const refreshCalendarItems = async (startDate: string, endDate: string = DEFAULT_CALENDAR_END_DATE) => {
		const uploadedCalendarItems = await performUploadCalendarItemsRequest(startDate, endDate);
		addCalendarItems({ calendarItems: uploadedCalendarItems, calendarItemsRange: [startDate, endDate] });
	};

	return { uploadCalendarItems, refreshCalendarItems };
};
