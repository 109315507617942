import { useTranslation } from '@hooks';
import { MemberBlock } from '@ui-kit';
import { compact, extractIdFromIri } from '@utils';
import type { TDeal, TProfile } from '@typings';
import { getDealCreatorTypeDescription } from '@ui-modules/deals/utils/getDealCreatorTypeDescription';

const DealMemberBlock = ({ deal, onGoToProfile }: IDealMemberBlockProps) => {
	const { t } = useTranslation();

	return (
		<MemberBlock
			avatarUrl={deal.creator.avatarUrl}
			descriptions={compact([
				deal.creator.creatorInvestmentExpertise &&
					t('Expertise in {{expertiseList}}', { expertiseList: deal.creator.creatorInvestmentExpertise }),
				getDealCreatorTypeDescription(t)(deal.creatorType),
				deal.compensationDisclosure && t('May receive compensation'),
			])}
			fullName={deal.creator.fullName}
			isChair={deal.creator.profileIri.includes('profile_chair')}
			title={t('Posted by {{name}}', { name: deal.creator.fullName })}
			onClick={() => onGoToProfile(extractIdFromIri(deal.creator.profileIri))}
		/>
	);
};

export interface IDealMemberBlockProps {
	deal: TDeal;
	onGoToProfile: (profileId: TProfile['id']) => void;
}

export default DealMemberBlock;
