import { useCheckTextOnMaliciousUrls, useMe, useNavigate, useNotification, useService, useTranslation } from '@hooks';
import type { TMediaObject, TNetworkConnection, TNetworkConnectionTemplate } from '@typings';
import { useCreateNetworkConnectionMutation } from '@ui-modules/connections/hooks/useCreateNetworkConnectionMutation';
import { useEditNetworkConnectionMutation } from '@ui-modules/connections/hooks/useEditNetworkConnectionMutation';
import { useNetworkConnectionMemberRelationQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationQuery';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { useNetworkConnectionMemberRelationStatusMutation } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationStatusMutation';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { getSelectedIds } from '@ui-modules/connections/utils/transformConnectionCategories';
import { collectNetworkConnectionContentWhichMayHasUrls } from '@ui-modules/connections/utils/collectNetworkConnectionContentWhichMayHasUrls';
import { useCreateNetworkConnectionFilesMutation } from '@ui-modules/deals/hooks/useCreateNetworkConnectionFilesMutation';
import { useDeleteNetworkConnectionFileMutation } from '@ui-modules/files/hooks/useDeleteNetworkConnectionFileMutation';
import { useUploadMediaObjectSetViewerOnly } from '@ui-modules/files/hooks/useUploadMediaObjectSetViewerOnly';
import type { FormikHelpers } from 'formik';
import type { TNetworkConnectionFormValues } from './ConnectionForm.schema';
import { useNetworkConnectionActionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionActionQuery';
import { useNetworkConnectionDonationActionMutation } from '@ui-modules/connections/hooks/useNetworkConnectionDonationActionMutation';
import { formatNetworkConnectionDonationAction } from '@utils';
import { filterTemplateByVersion } from '@ui-modules/connections/utils/filterTemplateByVersion';

export const useSubmitNetworkConnection = (
	networkConnectionId?: string,
	networkConnectionName?: string,
	connectionTemplate?: TNetworkConnectionTemplate,
	communityId?: string,
) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const reactQuery = useService('ReactQueryService');
	const { showUnknownError, showSuccess } = useNotification();
	const { user } = useMe();
	const checkTextOnMaliciousUrls = useCheckTextOnMaliciousUrls({ throwError: true });

	const { data: memberRelations = [] } = useNetworkConnectionMemberRelationsQuery(communityId as string);

	const { mutateAsync: createNetworkConnection } = useCreateNetworkConnectionMutation();
	const { mutateAsync: editNetworkConnection } = useEditNetworkConnectionMutation();
	const { mutateAsync: deleteFile } = useDeleteNetworkConnectionFileMutation(networkConnectionId as string, null);
	const { mutateAsync: postFiles } = useCreateNetworkConnectionFilesMutation();
	const { mutateAsync: changeMemberRelation } = useNetworkConnectionMemberRelationStatusMutation(
		networkConnectionId as string,
	);
	const { mutateAsync: changeDonationAction } = useNetworkConnectionDonationActionMutation();
	const { mutateAsync: updateMediaObjectSetViewerOnly } = useUploadMediaObjectSetViewerOnly({
		networkConnectionId: networkConnectionId as string,
		folderId: null,
	});

	const { data: networkConnection } = useNetworkConnectionQuery(networkConnectionId as string, {
		enabled: !!networkConnectionId,
	});
	const { data: networkConnectionAction } = useNetworkConnectionActionQuery(networkConnection as TNetworkConnection, {
		select: (networkConnectionAction) => {
			if (!networkConnectionAction) return null;
			return formatNetworkConnectionDonationAction(networkConnectionAction, networkConnection as TNetworkConnection);
		},
	});

	const { data: networkConnectionMemberRelation } = useNetworkConnectionMemberRelationQuery(
		networkConnectionId as string,
		{ enabled: !!networkConnectionId },
	);

	const manageFilesHandler = async (
		networkConnection: TNetworkConnection,
		owner: string,
		files: TMediaObject[],
		deletedFiles: string[],
		makeFileReadOnlyActions: TMediaObject['id'][],
		makeFileDownloadableActions: TMediaObject['id'][],
	) => {
		for (const fileId of deletedFiles) {
			await deleteFile(fileId);
		}
		for (const fileId of makeFileReadOnlyActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: true });
		}
		for (const fileId of makeFileDownloadableActions) {
			await updateMediaObjectSetViewerOnly({ fileId, isViewerOnly: false });
		}
		await postFiles(
			{
				files,
				owner,
				networkConnection,
				isNetworkConnectionInformationFile: true,
			},
			{
				onSuccess: () => {
					showSuccess({
						title: networkConnectionId
							? t('{{networkConnectionName}} Edited', { networkConnectionName })
							: t('{{networkConnectionName}} Published', { networkConnectionName }),
					});
					navigate(-1);
					reactQuery.queryClient.invalidateQueries(
						reactQuery.queryKeys.getNetworkConnections(
							networkConnection?.community?.id ?? networkConnection.community.id,
						),
					);
					reactQuery.queryClient.invalidateQueries(
						reactQuery.queryKeys.getNetworkConnectionFiles(networkConnection.id as TNetworkConnection['id'], null),
					);
				},
				onError: (error) => showUnknownError(error),
			},
		);
	};

	return async (
		{
			creator,
			relation,
			community,
			videoUrl,
			dynamicFields,
			categories,
			files = [],
			deletedFiles = [],
			makeFileReadOnlyActions = [],
			makeFileDownloadableActions = [],
			donationButtonLink,
			...values
		}: TNetworkConnectionFormValues,
		formikHelpers: FormikHelpers<TNetworkConnectionFormValues>,
	) => {
		try {
			const headingFields = filterTemplateByVersion(
				connectionTemplate?.dynamicFields?.filter((field) => field.type === 'heading') || [],
				values.selectedTemplateVersion || '',
			);
			const dynamicFieldsData = [
				...headingFields, // T21C-6652 Always send the heading field.
				...Object.values(dynamicFields).filter((field) => !!field.value), //  T21C-6652 if field is empty do not send the field object at all.
			];
			const selectedCategories = getSelectedIds(categories);

			const data = {
				...values,
				videoUrl: videoUrl?.length ? videoUrl : undefined,
				dynamicFields: dynamicFieldsData,
				categories: selectedCategories,
			};

			await checkTextOnMaliciousUrls(
				collectNetworkConnectionContentWhichMayHasUrls({ ...data, donationLink: donationButtonLink }),
			);

			if (networkConnectionId && networkConnection) {
				const createdConnection = await editNetworkConnection(
					{
						...networkConnection,
						...data,
						community: `api/communities/${community}`,
						owner: `api/users/${creator.iriId}`,
					},
					{
						onError: (error) => {
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
				await manageFilesHandler(
					createdConnection,
					`api/users/${creator.iriId}`,
					files,
					deletedFiles,
					makeFileReadOnlyActions,
					makeFileDownloadableActions,
				);

				const selectedNetworkRelation = memberRelations.find((memberRelation) => memberRelation.id === relation);
				// If admin user use relation status from network connection item;
				const ownerRelationStatus =
					createdConnection.owner?.id === user?.id
						? { ...networkConnectionMemberRelation?.networkConnectionStatus, id: networkConnectionMemberRelation?.id }
						: {
								...createdConnection.ownerRelationStatus,
								id: createdConnection.ownerRelationStatus?.networkConnectionUserRelationId,
						  };
				if (ownerRelationStatus?.title !== selectedNetworkRelation?.title) {
					// backend doesn't provide status id so needed to check by relation title
					await changeMemberRelation({
						networkConnectionRelationId: ownerRelationStatus?.id,
						networkConnectionRelationStatusId: relation,
						userId: createdConnection.owner.id,
					});
				}
				await changeDonationAction({
					networkConnectionAction,
					networkConnectionId,
					donationLink: donationButtonLink as string,
				});
				reactQuery.queryClient.invalidateQueries(reactQuery.queryKeys.getNetworkConnection(networkConnectionId));
			} else {
				const createdConnection = await createNetworkConnection(
					{ ...data, owner: creator.iriId, community },
					{
						onError: (error) => {
							showUnknownError(error);
							formikHelpers.setSubmitting(false);
						},
					},
				);
				await changeMemberRelation({
					networkConnectionRelationId: undefined,
					networkConnectionRelationStatusId: relation,
					networkConnectionId: createdConnection?.id,
					userId: createdConnection.owner.id,
				});
				await manageFilesHandler(
					createdConnection,
					creator.iriId,
					files,
					deletedFiles,
					makeFileReadOnlyActions,
					makeFileDownloadableActions,
				);
				await changeDonationAction({
					networkConnectionId: createdConnection.id,
					donationLink: donationButtonLink as string,
				});
			}
		} catch (error) {
			showUnknownError(error as Error);
			formikHelpers.setSubmitting(false);
		}
	};
};
