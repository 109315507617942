import { useTranslation } from '@hooks';
import { ArrowDownIcon } from '@ui-kit';
import { clsx } from '@utils';
import styles from './DealSortWidget.module.css';
import type { TDealQuerySort, TDealSortOption } from '@typings';

const DealSortWidget = ({ sortOptions, selectedOption, sortDisabled = false, onSelect }: IDealSortWidgetProps) => {
	const { t } = useTranslation();

	const handleSelectOption = (option: TDealSortOption) => {
		onSelect({
			field: option.value,
			order: selectedOption.field === option.value && selectedOption.order === 'asc' ? 'desc' : 'asc',
		});
	};

	return (
		<div className={clsx(styles.dealSort__container, sortDisabled && styles.dealSort__container_disabled)}>
			<span className={styles.dealSort__title}>{t('Sort By')}</span>
			{sortOptions.map((option) => {
				const isSelected = selectedOption.field === option.value;
				return (
					<div
						className={clsx(styles.dealSort__option, isSelected && styles.dealSort__option_selected)}
						key={option.value}
						onClick={() => handleSelectOption(option)}
						onKeyDown={() => handleSelectOption(option)}
					>
						{isSelected ? (
							<div className={styles.dealSort__arrow} data-order={selectedOption.order}>
								<ArrowDownIcon fill="blue" />
							</div>
						) : null}
						<span>{option.title}</span>
					</div>
				);
			})}
		</div>
	);
};

export interface IDealSortWidgetProps {
	selectedOption: TDealQuerySort;
	sortOptions: TDealSortOption[];
	sortDisabled?: boolean;
	onSelect: (option: TDealQuerySort) => void;
}

export default DealSortWidget;
