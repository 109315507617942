import { Modal } from '@ui-kit';
import { useService, useMutation, useNotification, useTranslation, useToggle } from '@hooks';
import type { MutationOptions } from '@tanstack/react-query';

export const useDeleteChatChannelMutation = (channelCid: string, options?: MutationOptions<void, Error, string>) => {
	const chat = useService('ChatService');
	const { showSuccess, showUnknownError } = useNotification();
	const { t } = useTranslation();

	const [isDeleteChatDialogVisible, toggleDeleteChatDialogVisible] = useToggle(false);

	const { mutateAsync: deleteChannel, isLoading } = useMutation<void, Error, string>(
		['chat.deleteChannel'],
		async (channelId) => {
			await chat.deleteChannel(channelId);
		},
		{
			onSuccess: (response, variables, context) => {
				showSuccess({
					title: t('Channel deleted'),
				});
				options?.onSuccess?.(response, variables, context);
			},
			onError: (error, variables, context) => {
				showUnknownError(error);
				options?.onError?.(error, variables, context);
			},
		},
	);

	const ConfirmationModals = (
		<Modal
			cancelTitle={t('Cancel')}
			confirmTitle={t('Delete')}
			isConfirmLoading={isLoading}
			subTitle={t('Are you sure you want to permanently delete the chat?')}
			title={t('Delete Chat')}
			variant="medium"
			visible={isDeleteChatDialogVisible}
			onCancel={() => toggleDeleteChatDialogVisible()}
			onConfirm={() => {
				deleteChannel(channelCid)
					.catch()
					.finally(() => {
						toggleDeleteChatDialogVisible(false);
					});
			}}
		/>
	);

	return [toggleDeleteChatDialogVisible, ConfirmationModals] as const;
};
