import { useTranslation, useGetUserGroups } from '@hooks';
import { Avatar, Button } from '@ui-kit';
import { extractIdFromIri, noop } from '@utils';
import styles from './BlockedUser.module.css';
import type { TBlockedUser, TProfile } from '@typings';

const BlockedUser = ({ item, onUnblockPressed, onOpenProfile }: IBlockedUserProps) => {
	const { t } = useTranslation();
	const { userGroups } = useGetUserGroups(item.id);

	const userFullName = `${item.firstName} ${item.lastName}`;

	return (
		<div onClick={() => onOpenProfile(extractIdFromIri(item.profileId))} onKeyDown={noop}>
			<div className={styles.blockedUser__container}>
				<div>
					<Avatar imageUri={item.avatarUrl} outline={false} size={38} title={userFullName} />
				</div>
				<div className={styles.blockedUser__infoContainer}>
					<div className={styles.blockedUser__title}>{userFullName}</div>
					<div className={styles.blockedUser__subtitle}>{userGroups}</div>
				</div>
				<Button title={t('Unblock')} type="solid" variant="small" onClick={() => onUnblockPressed()} />
			</div>
		</div>
	);
};

export interface IBlockedUserProps {
	item: TBlockedUser;
	onUnblockPressed: () => void;
	onOpenProfile: (profileId: TProfile['id']) => void;
}

export default BlockedUser;
