import { clsx } from '@utils';
import styles from './ErrorFallback.module.css';

function ErrorFallback({ error, resetErrorBoundary }: IErrorFallbackProps) {
	return (
		<div className={styles.container}>
			<div className={clsx(styles.content)}>
				<p className={styles.title}>Oops!</p>
				<p className={styles.subtitle}>{`There's an error`}</p>
				<p className={styles.error}>{error.toString()}</p>
				<button className={styles.button} onClick={resetErrorBoundary}>
					Try again
				</button>
			</div>
		</div>
	);
}

export interface IErrorFallbackProps {
	error: Error;
	resetErrorBoundary: () => unknown;
}

export default ErrorFallback;
