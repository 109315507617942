import { useMutation, useService, useQueryClient, useNotification, useTranslation, useMe } from '@hooks';
import type { TContact, TProfile, TUser } from '@typings';
import { updateAddressBookContactId } from '../utils/updateAddressBookContactId';

export const useContact = () => {
	const api = useService('ApiService');
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { showUnknownError, showSuccess } = useNotification();
	const { user: myUser } = useMe();

	const { mutateAsync: addContact } = useMutation<TContact, Error, { userId: TUser['id']; profileId: TProfile['id'] }>(
		['contact.addContact'],
		async ({ userId }) => await api.contact.addContact(myUser['@id'], `/api/users/${userId}`),
		{
			onSuccess: (contact, { profileId, userId }) => {
				algoliaAnalytics.trackEvent('User Contact Added', { userSlug: userId });
				showSuccess({
					title: t('Success'),
					subtitle: t('Contact has been added'),
				});
				queryClient.refetchQueries(['profiles.contact_card', profileId]);
				queryClient.invalidateQueries(['profile.getProfile', profileId]);
				updateAddressBookContactId(queryClient, userId, contact.id);
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);

	const { mutateAsync: removeContact } = useMutation<
		void,
		Error,
		{ contactId: TContact['id']; userId: TUser['id']; profileId: TProfile['id'] }
	>(['contact.removeContact'], async ({ contactId }) => await api.contact.removeContact(contactId), {
		onSuccess: (_, { userId, profileId }) => {
			algoliaAnalytics.trackEvent('User Contact Removed', { userSlug: userId });
			showSuccess({
				title: t('Success'),
				subtitle: t('Contact has been removed'),
			});
			queryClient.refetchQueries(['profiles.contact_card', profileId]);

			queryClient.invalidateQueries(['profile.getProfile', profileId]);
			updateAddressBookContactId(queryClient, userId, null);
		},
		onError: (error) => {
			showUnknownError(error);
		},
	});

	const { mutateAsync: updateNote } = useMutation<
		TContact,
		Error,
		{ contactId: TContact['id']; note: string; profileId: TProfile['id'] }
	>(['contact.updateNote'], async ({ contactId, note }) => await api.contact.updateNote(contactId, note), {
		onSuccess: (_, { profileId }) => {
			showSuccess({
				title: t('Success'),
				subtitle: t('Note has been updated'),
			});
			queryClient.refetchQueries(['profiles.contact_card', profileId]);

			queryClient.invalidateQueries(['profile.getProfile', profileId]);
		},
		onError: (error) => {
			showUnknownError(error);
		},
	});

	return {
		addContact,
		updateNote,
		removeContact,
	};
};
