import { Page, TabWrapper } from '../../components';
import {
	useTranslation,
	useNavigate,
	useLocation,
	useParams,
	useMe,
	resetUserSessionStorageValue,
	useRestoreScroll,
	useEffect,
	useService,
} from '@hooks';
import { dayjs } from '@utils';
import { useBlocker } from 'react-router-dom';
import styles from './AssetAllocationInfoPage.module.css';
import AssetAllocationComparativeChart from '@ui-modules/assetAllocation/components/AssetAllocationComparativeChart';
import AssetAllocationHero from '@ui-modules/assetAllocation/components/AssetAllocationHero';
import AssetAllocationRecommendations from '@ui-modules/assetAllocation/components/AssetAllocationRecommendations';
import AssetAllocationComparePeriodSelector from '@ui-modules/assetAllocation/components/AssetAllocationComparePeriodSelector';
import AssetAllocationFacade from '@ui-modules/assetAllocation/components/AssetAllocationFacade';
import { formatAssetAllocationToAssetAllocationList } from '@ui-modules/assetAllocation/utils/formatAssetAllocationToAssetAllocationList';
import { BackIcon, Button, PieChart, When } from '@ui-kit';
import type { TAssetAllocationTabSlug } from '@typings';
import { useAssetAllocationCommunitiesQuery } from '@ui-modules/assetAllocation/hooks/useAssetAllocationCommunitiesQuery';
import { useAssetAllocationComparePeriod } from '@ui-modules/assetAllocation/hooks/useAssetAllocationComparePeriod';
import { getAssetAllocationInfoContent } from './AssetAllocationInfoPage.utils';
import { ROUTES } from '@constants';

const AssetAllocationInfoPage = ({ communityId, tabName, hideBackButton }: IAssetAllocationInfoPageProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();
	const analytics = useService('AnalyticsService');

	const { networkId } = useParams<IAssetAllocationInfoPageParams>();
	const finalCommunityId = communityId || networkId;
	const isTiger21 = !finalCommunityId;
	const { scrollerRef, restoreScroll, resetScroll } = useRestoreScroll(
		`assetAllocation-${isTiger21 ? 'tiger21' : finalCommunityId}`,
	);

	const {
		data: comparePeriods = [],
		isFetching: isComparePeriodsFetching,
		isFetched: isComparePeriodsFetched,
		selectedComparePeriod,
		setSelectedComparePeriod,
	} = useAssetAllocationComparePeriod({ enabled: isTiger21 });
	const { data: { userAssetAllocation, comparingAssetAllocation, assetAllocationList = [] } = {}, isLoading } =
		useAssetAllocationCommunitiesQuery(
			{
				enabled: (isTiger21 && isComparePeriodsFetched) || !isTiger21,
				select(data) {
					const userAssetAllocation = data?.user;
					const comparingAssetAllocation = isTiger21
						? data?.t21
						: data?.communities?.find?.((aa) => aa.community.id === finalCommunityId && aa.updatedAt);
					const assetAllocationList =
						(comparingAssetAllocation && formatAssetAllocationToAssetAllocationList(t)(comparingAssetAllocation)) ??
						undefined;
					return { userAssetAllocation, comparingAssetAllocation, assetAllocationList };
				},
			},
			selectedComparePeriod,
		);
	const { comparativeFullName, heroBackgroundImageUrl, comparativeTitle } = getAssetAllocationInfoContent(t)(
		comparingAssetAllocation,
		isTiger21,
	);

	useBlocker(({ nextLocation }) => {
		// Check if user navigating back then reset selected network for navigation restoring.
		if (nextLocation.pathname.startsWith('/asset-allocation/networks') && networkId) {
			resetUserSessionStorageValue(`assetAllocationNetworks-${user.slug}`);
		}
		return false;
	});

	useEffect(() => {
		if (!finalCommunityId && !isTiger21) return;
		restoreScroll();
	}, [finalCommunityId]);
	useEffect(
		function trackPageViewed() {
			if (communityId) {
				analytics.trackEvent('AssetAllocationInteractions', {
					interaction_type: 'tab_viewed',
					tab_name: communityId,
				});
			} else if (selectedComparePeriod) {
				analytics.trackEvent('AssetAllocationInteractions', {
					interaction_type: 'tab_viewed',
					tab_name: 'tiger21',
					time_period: `${selectedComparePeriod.year} Q${selectedComparePeriod.quarter}`,
				});
			}
		},
		[selectedComparePeriod],
	);

	const location = useLocation();
	const handleBackNavigate = () => {
		if (location.key === 'default') {
			navigate(ROUTES.assetAllocation('networks'), { replace: true });
		} else {
			navigate(-1);
		}
	};

	return (
		<TabWrapper ref={scrollerRef}>
			<Page title={t('Asset Allocation | {{tabName}}', { tabName })}>
				<When condition={!hideBackButton}>
					<div className={styles.buttonContainer}>
						<div className={styles.buttonContent}>
							<Button
								icon={<BackIcon fill="#333333" height={14} width={13} />}
								iconPosition="left"
								title={t('Networks')}
								type="outline"
								onClick={handleBackNavigate}
							/>
						</div>
					</div>
				</When>
				<AssetAllocationFacade
					assetAllocationExists={!!comparingAssetAllocation}
					communityId={finalCommunityId}
					isLoading={isLoading || isComparePeriodsFetching}
					userAssetAllocationExists={!!userAssetAllocation}
				>
					{comparingAssetAllocation ? (
						<>
							<AssetAllocationHero backgroundImageUrl={heroBackgroundImageUrl}>
								<PieChart
									height={279}
									items={assetAllocationList}
									subtitle={t('Average allocation last updated {{date}}', {
										date: dayjs(comparingAssetAllocation.updatedAt).format('D MMM YYYY'),
									})}
									title={comparativeFullName}
								/>
							</AssetAllocationHero>
							{userAssetAllocation ? (
								<div className={styles.content_container}>
									<AssetAllocationComparativeChart
										comparativeTitle={comparativeTitle}
										comparePeriod={selectedComparePeriod}
										comparingAssetAllocation={comparingAssetAllocation}
										userAssetAllocation={userAssetAllocation}
									/>
									<AssetAllocationRecommendations
										comparingAssetAllocation={comparingAssetAllocation}
										networkName={comparativeFullName}
										resetScroll={resetScroll}
										userAssetAllocation={userAssetAllocation}
									/>
								</div>
							) : null}
						</>
					) : null}
				</AssetAllocationFacade>

				<When condition={isTiger21 && !isLoading}>
					<div className={styles.assetAllocationInfoPage__periodSelectorBar}>
						<div className={styles.assetAllocationInfoPage__periodSelectorWrapper}>
							<AssetAllocationComparePeriodSelector
								comparePeriods={comparePeriods}
								selectedComparePeriod={selectedComparePeriod}
								onSelectComparePeriod={setSelectedComparePeriod}
							/>
						</div>
					</div>
				</When>
			</Page>
		</TabWrapper>
	);
};

interface IAssetAllocationInfoPageProps {
	tabName?: string;
	hideBackButton?: boolean;
	/** The empty 'communityId' is considered to be related to Tiger 21 tab */
	communityId?: string | undefined;
}

export interface IAssetAllocationInfoPageParams extends Record<string, string | undefined> {
	tabName?: TAssetAllocationTabSlug;
	networkId?: string;
}

export default AssetAllocationInfoPage;
