import { uniq } from '@utils';
import type { MessageResponse } from 'stream-chat';

export const getChatMessageAttachmentTypes = (lastMessage: MessageResponse | undefined) => {
	if (!lastMessage) return [];
	const attachments = lastMessage?.attachments ?? [];
	const userAttachments = attachments.filter(
		(attachment) =>
			// exclude preview images made by GetStream for links
			(attachment.type === 'image' && !attachment.title_link) ||
			// exclude all empty type attachments
			(attachment.type && attachment.type !== 'image'),
	);
	const userAttachmentTypes = userAttachments.map((attachment) => attachment.type);
	const uniqueUserAttachmentTypes = uniq(userAttachmentTypes);

	return uniqueUserAttachmentTypes;
};
